import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProfileNavigationConfigurationService } from '@ptg-member/services/profile-navigation-configuration.service';
import { GetMemberNavigationListResponse } from '@ptg-member/types/models';

import { ParticipantReportService } from '@ptg-member/services/participant-report.service';
import { LayoutActions } from '../actions';
import { BulkContentItem } from '../models/bulk-content-item.model';
import { AppContentService } from '../services/app-content.service';
import { BulkContentServiceService } from '../services/bulk-content-service.service';

@Injectable()
export class SidebarListEffects {
  constructor(
    private actions$: Actions,
    private bulkContentService: BulkContentServiceService,
    private appContentService: AppContentService,
    private profileNavigationService: ProfileNavigationConfigurationService,
    private participantReportService: ParticipantReportService,
  ) {}

  getBulkContentItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getBulkContentItemRequest),
      switchMap(() => {
        return this.bulkContentService.getMenuItems().pipe(
          map((items: BulkContentItem[]) => {
            const contactInformationKey =
              items.find((item) => item.name === 'Contact Information')?.key ||
              '';
            sessionStorage.setItem(
              'keyContactInformation',
              contactInformationKey,
            );
            return LayoutActions.getBulkContentItemRequestSuccess({ items });
          }),
          catchError((err) => {
            return of(
              LayoutActions.getBulkContentItemRequestFailure({
                errorMsg: err.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  getAppContentItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getAppContentItemRequest),
      switchMap(() => {
        return this.appContentService.getMenuItems().pipe(
          map((items: any[]) => {
            return LayoutActions.getAppContentItemRequestSuccess({ items });
          }),
          catchError((error) => {
            return of(LayoutActions.getAppContentItemRequestFailure({ error }));
          }),
        );
      }),
    ),
  );

  getReportCategorySidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getReportCategorySidebar),
      switchMap(() => {
        return this.participantReportService.getReportCategorySideBar().pipe(
          map((res) => {
            return LayoutActions.getReportCategorySidebarSuccess({ res: res });
          }),
          catchError((error) => {
            return of(LayoutActions.getReportCategorySidebarFailure({ error }));
          }),
        );
      }),
    ),
  );

  getProfileNavigationItemRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.getMemberNavigationListAction),
      switchMap(({ memberId }) =>
        this.profileNavigationService.getMemberNavigationList(memberId).pipe(
          map((response: GetMemberNavigationListResponse) =>
            LayoutActions.getMemberNavigationListSuccessAction({
              response: response,
              memberId,
            }),
          ),
          catchError((error) =>
            of(LayoutActions.getMemberNavigationListFailureAction({ error })),
          ),
        ),
      ),
    ),
  );
}
