import { createAction, props } from '@ngrx/store';

import {
  FilterByPropertyConfig,
  FilterInformation,
  MemberFilter,
  MemberFilterConfig,
  MemberFilterName,
  MemberList,
  MemberListQuery,
} from '../types/models';

export const MemberListRequest = '[MemberList/API] Send Request';
export const MemberListFailure = '[MemberList/API] Failure';
export const MemberListSuccess = '[MemberList/API] Success';
export const RemoveMemberRequest = '[RemoveMemberList/API] Send Request';
export const RemoveMemberSuccess = '[RemoveMemberList/API] Success';
export const RemoveMemberFailure = '[RemoveMemberList/API] Failure';
export const RemoveMemberClear = '[RemoveMemberList] Clear';
export const LockMemberRequest = '[LockMemberList/API] Send Request';
export const LockMemberSuccess = '[LockMemberList/API] Success';
export const LockMemberFailure = '[LockMemberList/API] Failure';
export const UnlockMemberRequest = '[UnlockMemberList/API] Send Request';
export const UnlockMemberSuccess = '[UnlockMemberList/API] Success';
export const UnlockMemberFailure = '[UnlockMemberList/API] Failure';
export const GetFilterByPropertyListRequest =
  '[GetFilterByPropertyList/API] Send Request';
export const GetFilterByPropertyListSuccess =
  '[GetFilterByPropertyList/API] Success';
export const GetFilterByPropertyListFailure =
  '[GetFilterByPropertyList/API] Failure';
export const SetMemberFilters = '[SetMemberFilters] Set';
export const CreateMemberFilterRequest =
  '[CreateMemberFilter/API] Send Request';
export const CreateMemberFilterSuccess = '[CreateMemberFilter/API] Success';
export const CreateMemberFilterFailure = '[CreateMemberFilter/API] Failure';
export const GetMemberFilterRequest = '[GetMemberFilter/API] Send Request';
export const GetMemberFilterSuccess = '[GetMemberFilter/API] Success';
export const GetMemberFilterFailure = '[GetMemberFilter/API] Failure';
export const UpdateFilterNameRequest = '[UpdateFilterName/API] Send Request';
export const UpdateFilterNameSuccess = '[UpdateFilterName/API] Success';
export const UpdateFilterNameFailure = '[UpdateFilterName/API] Failure';
export const RemoveMemberFilterRequest =
  '[RemoveMemberFilter/API] Send Request';
export const RemoveMemberFilterSuccess = '[RemoveMemberFilter/API] Success';
export const RemoveMemberFilterFailure = '[RemoveMemberFilter/API] Failure';

export const ExportMembersRequest = '[ExportMembersRequest/API] Send Request';
export const ExportMembersRequestSuccess = '[ExportMembersRequest/API] Success';
export const ExportMembersRequestFailure = '[ExportMembersRequest/API] Failure';

export const DownloadMembersRequest =
  '[DownloadMembersRequest/API] Send Request';
export const DownloadMembersRequestSuccess =
  '[DownloadMembersRequest/API] Success';
export const DownloadMembersRequestFailure =
  '[DownloadMembersRequest/API] Failure';

export const getMemberList = createAction(
  MemberListRequest,
  props<{ query: MemberListQuery; idClient: string }>()
);
export const getMemberListSuccess = createAction(
  MemberListSuccess,
  props<{ memberList: MemberList }>()
);
export const getMemberListFailure = createAction(
  MemberListFailure,
  props<{ error?: any }>()
);

export const lockMember = createAction(
  LockMemberRequest,
  props<{ id: string }>()
);
export const lockMemberSuccess = createAction(
  LockMemberSuccess,
  props<{ id: string }>()
);
export const lockMemberFailure = createAction(
  LockMemberFailure,
  props<{ id: string; errorMsg: string }>()
);

export const unlockMember = createAction(
  UnlockMemberRequest,
  props<{ id: string }>()
);
export const unlockMemberSuccess = createAction(
  UnlockMemberSuccess,
  props<{ id: string }>()
);
export const unlockMemberFailure = createAction(
  UnlockMemberFailure,
  props<{ id: string; errorMsg: string }>()
);

export const removeMember = createAction(
  RemoveMemberRequest,
  props<{ id: string; memberKey: string }>()
);
export const removeMemberSuccess = createAction(
  RemoveMemberSuccess,
  props<{ memberKey: string }>()
);
export const removeMemberFailure = createAction(
  RemoveMemberFailure,
  props<{ id: string; errorMsg: string }>()
);
export const removeMemberClear = createAction(RemoveMemberClear);
export const getFilterByPropertyListRequest = createAction(
  GetFilterByPropertyListRequest
);
export const getFilterByPropertyListSuccess = createAction(
  GetFilterByPropertyListSuccess,
  props<{ listSectionProperty: FilterByPropertyConfig[] }>()
);
export const getFilterByPropertyListFailure = createAction(
  GetFilterByPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const setMemberFilter = createAction(
  SetMemberFilters,
  props<{ filters: MemberFilter[] }>()
);
export const createMemberFilterRequest = createAction(
  CreateMemberFilterRequest,
  props<{ body: MemberFilterConfig }>()
);
export const createMemberFilterSuccess = createAction(
  CreateMemberFilterSuccess
);
export const createMemberFilterFailure = createAction(
  CreateMemberFilterFailure
);
export const getMemberFilterRequest = createAction(GetMemberFilterRequest);
export const getMemberFilterSuccess = createAction(
  GetMemberFilterSuccess,
  props<{ listFilterInfo: FilterInformation[] }>()
);
export const getMemberFilterFailure = createAction(GetMemberFilterFailure);
export const updateFilterNameRequest = createAction(
  UpdateFilterNameRequest,
  props<{ memberFilterName: MemberFilterName }>()
);
export const updateFilterNameSuccess = createAction(
  UpdateFilterNameSuccess,
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const updateFilterNameFailure = createAction(
  UpdateFilterNameFailure,
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const removeMemberFilterRequest = createAction(
  RemoveMemberFilterRequest,
  props<{ id: string }>()
);
export const removeMemberFilterSuccess = createAction(
  RemoveMemberFilterSuccess,
  props<{ id: string }>()
);
export const removeMemberFilterFailure = createAction(
  RemoveMemberFilterFailure
);

export const exportMembersRequest = createAction(
  ExportMembersRequest,
  props<{ request: any }>()
);
export const exportMembersRequestSuccess = createAction(
  ExportMembersRequestSuccess,
  props<{ data: any }>()
);
export const exportMembersRequestFailure = createAction(
  ExportMembersRequestFailure
);

export const downloadMembersRequest = createAction(
  DownloadMembersRequest,
  props<{ fileName: string }>()
);
export const downloadMembersRequestSuccess = createAction(
  DownloadMembersRequestSuccess
);
export const downloadMembersRequestFailure = createAction(
  DownloadMembersRequestFailure
);
