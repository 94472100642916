import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/models/common.model';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import * as fromMember from '../../reducers';
import { MemberDetailActions } from '../../actions';
import { EditMemberEventBody, MemberStatus } from '../../types/models';

interface EditMemberEventData {
  memberId: string;
  eventId: string;
  statusId: string;
  memberStatusList: MemberStatus[];
}

@Component({
  selector: 'ptg-edit-member-status-event',
  templateUrl: './edit-member-status-event.component.html',
  styleUrls: ['./edit-member-status-event.component.scss'],
})
export class EditMemberStatusEventComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();
  listEvent: Option[] = [];
  listStatus: Option[] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditMemberStatusEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditMemberEventData,
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {}

  ngOnInit(): void {
    this.listStatus = (this.data.memberStatusList || []).reduce(
      (result, status) => {
        if (status.id === this.data.statusId || status.active) {
          result.push({
            value: status.id,
            displayValue: status.name,
          });
        }
        return result;
      },
      [] as Option[]
    );
    this.getEventOptions(this.data?.statusId);
    this.initFormGroup(this.data);
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  getEventOptions(statusId: string) {
    this.listEvent = (
      this.data.memberStatusList.find((status) => status.id === statusId)
        ?.memberEvent || []
    ).reduce((result, event) => {
      if (event.id === this.data.eventId || event.active) {
        result.push({
          value: event.id,
          displayValue: event.name,
        });
      }
      return result;
    }, [] as Option[]);
  }

  initFormGroup(formData: EditMemberEventData) {
    this.editForm = this.fb.group({
      memberId: this.fb.control(formData?.memberId),
      statusId: this.fb.control(formData?.statusId, Validators.required),
      eventId: this.fb.control(formData?.eventId, Validators.required),
    });
    this.editForm.get('statusId')?.valueChanges.subscribe((statusId) => {
      this.editForm?.get('eventId')?.setValue('');
      this.getEventOptions(statusId);
    });
  }

  onSubmit() {
    const body = JSON.parse(
      JSON.stringify(this.editForm.value as EditMemberEventBody)
    );
    this.memberStore.dispatch(MemberDetailActions.setMemberEvent({ body }));
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
