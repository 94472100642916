import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParticipantReportService } from '@ptg-member/services/participant-report.service';
import { ParticipantReport } from '@ptg-member/types/models/participant-report.model';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { UploadComponent } from 'src/app/shared/controls/upload/upload.component';

export interface DialogBrandingData {
  fundName: any;
  listFieldColor: any[];
  appTitle: string;
  iconTitle: string;
  logoUri: string;
  iconUri: string;
  isFirst: number;
  reportCategoryName: any[];
  isEditForm: boolean;
  participantReport: ParticipantReport,
}

export interface IEditReportConfig {
  CategoryName: string,
  ReportCategoryId : string,  
  CreateType: number,
  ReportName: string,
  Description: string,
  Template?: File,
}

@Component({
  selector: 'ptg-report-config',
  templateUrl: './report-config.component.html',
  styleUrls: ['./report-config.component.scss']
})
export class ReportConfigComponent implements OnInit, AfterViewInit {
  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  reportConfigForm = new FormGroup({});
  chooseType = new FormControl({});
  category = new FormControl();
  reportName = new FormControl('');
  description = new FormControl('');

  body: IEditReportConfig = {
    CategoryName: '',
    ReportCategoryId: '',
    CreateType: 0,
    ReportName: '',
    Description: '',
  };
  checkPatternGraphic = new RegExp(/^[\x00-\x7F]+\.(trdp)$/, 'i');
  typeFileGraphic = 'trdp file format';
  acceptGraphic = '.trdp';
  isFileChange = false;
  title : string = 'Add Report';
  uploadFilePlaceholder: string = 'Upload File';

  listChooseType = [
    {
      label: 'Choose Category',
      value: 0
    },
    {
      label: 'New Category',
      value: 1
    }];
  listCategories:any = [];

  constructor(
    public dialogRef: MatDialogRef<ReportConfigComponent>,
    public participantReportService: ParticipantReportService,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogBrandingData,
  ) {

  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  submitForm() {
    if (this.reportConfigForm.valid 
      && !this.fileDocument.hasError 
      && !this.reportName.invalid
      && !this.category.invalid
      && !this.description.invalid
      && this.fileDocument.controlField.value
      && !this.fileDocument.hasError) {
      if (this.reportConfigForm.pending) {
        let sub = this.reportConfigForm.statusChanges.subscribe(() => {
          if (this.reportConfigForm.valid) {
            this.submit();
          }
          sub.unsubscribe();
        });
      } else if (this.reportConfigForm.valid) {
        this.submit();
      } 
    } else {
      this.fileDocument.firstTouch = false;
    }

  }

  initFormGroup() {
    this.chooseType.setValue(0);
    this.listCategories = this.data.reportCategoryName;

    if (this.data.isEditForm === true) {
      this.title = 'Edit Report';
      this.uploadFilePlaceholder = this.data.participantReport.fileName;
      this.category = new FormControl(this.data.participantReport.categoryId);
      this.reportName = new FormControl(this.data.participantReport.reportName);
      this.description = new FormControl(this.data.participantReport.description ?? '');
      this.participantReportService.currentRowId = this.data.participantReport.reportId;
    }
    else {
      this.title = 'Add Report';
      this.uploadFilePlaceholder = 'Upload File';
      this.category = new FormControl();
      this.reportName = new FormControl('');
      this.description = new FormControl('');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.fileDocument.controlField.setValue(this.data.isEditForm === true && this.data?.participantReport?.fileName ? this.data?.participantReport?.fileName : '');
    });
  }

  submit() {
    if (this.reportConfigForm.valid 
      && !this.fileDocument.hasError 
      && !this.reportName.invalid
      && !this.category.invalid
      && this.fileDocument.controlField.value
      && !this.fileDocument.hasError
      && this.reportConfigForm.status !== 'PENDING'
      && this.reportName.status !== 'PENDING'
      && this.category.status !== 'PENDING') {
        if(this.chooseType.value === 0)
        {
          this.body.ReportCategoryId = this.category.value;
        }else{
          this.body.CategoryName = this.category.value;
        }
        this.body.CreateType = this.chooseType.value;
        this.body.ReportName = this.reportName.value;
        this.body.Description = this.description.value;
        this.dialogRef.close(this.body);
      }
    else {
      this.fileDocument.firstTouch = false;
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  uploadFile(event: any) {
    this.isFileChange = true;
    if (event) {
      this.participantReportService.reportTemplateFile = event;
    }
  }

  removeFile() {
    this.isFileChange = true;
    this.participantReportService.reportTemplateFile = null;
  }

  changeChooseType(e: any) {
    this.category.setValue('');
    if(e === 1) {
      this.category?.setAsyncValidators(this.checkSectionNameValidator());
      this.category?.updateValueAndValidity();
    }
  }

  checkSectionNameValidator(): AsyncValidatorFn {
    return checkApiValidator(this.participantReportService.checkReportCategoryNameExist, 'category', undefined);
  }
}
