import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/models/common.model';

import { ProfileNavigationConfigurationService } from '../../services/profile-navigation-configuration.service';
import * as fromMember from '../../reducers';
import * as ProfileNavigationConfigurationActions from '../../actions/profile-navigation-configuration.action';
import {
  CreateMemberNavigationItem,
  MemberNavigation,
  MemberNavigationItem,
  Section,
  SetMemberNavigationItemRequest,
} from '../../types/models';
import { ProfileOverviewConfigurationService } from '../../services/profile-overview-configuration.service';

@Component({
  selector: 'ptg-edit-profile-navigation-item',
  templateUrl: './edit-profile-navigation-item.component.html',
  styleUrls: ['./edit-profile-navigation-item.component.scss'],
})
export class EditProfileNavigationItemComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();
  listOption!: Option[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberNavigation: MemberNavigation;
      memberNavigationItem: MemberNavigationItem;
      newOrder: number;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditProfileNavigationItemComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public profileNavigationService: ProfileNavigationConfigurationService,
    public profileOverviewService: ProfileOverviewConfigurationService,
    private memberStore: Store<fromMember.MemberState>
  ) {}

  ngOnInit(): void {
    this.memberStore.dispatch(
      ProfileNavigationConfigurationActions.getSectionList()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectMemberNavigationSectionListState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((listSection: Section[]) => {
        this.getListOption(listSection);
      });

    this.initFormGroup(
      this.data.memberNavigationItem || ({} as MemberNavigationItem)
    );
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup(formData: MemberNavigationItem) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData?.id),
      itemKey: this.fb.control(formData?.itemKey, [Validators.required]),
      name: this.fb.control(
        formData?.name,
        [Validators.required, Validators.maxLength(100)],
        checkApiValidator(
          this.profileNavigationService.checkMemberNavigationItemExist,
          'subMenuName',
          formData?.name,
          {
            params: {
              navigationItemId: formData?.id,
              navigationMenuId: this.data.memberNavigation?.id,
            },
          }
        )
      ),
    });
  }

  onSubmit() {
    const formData = JSON.parse(
      JSON.stringify(this.editForm.value as MemberNavigationItem)
    );
    if (formData.id) {
      const body: SetMemberNavigationItemRequest = {
        id: formData.id,
        name: formData.name,
        itemKey: formData.itemKey,
        memberNavigationId: this.data.memberNavigation?.id,
      };
      this.memberStore.dispatch(
        ProfileNavigationConfigurationActions.editMemberNavigationItem({ body })
      );
    } else {
      const body: CreateMemberNavigationItem = {
        name: formData.name,
        itemKey: formData.itemKey,
        order: this.data.newOrder,
      };
      this.memberStore.dispatch(
        ProfileNavigationConfigurationActions.createMemberNavigationItem({
          menuId: this.data.memberNavigation?.id,
          body,
        })
      );
    }
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  getListOption(listSection: Section[]) {
    this.listOption = listSection.map((item) => {
      return {
        value: item.sectionKey,
        displayValue: item.sectionName,
      } as Option;
    });
    this.listOption = this.listOption.filter(
      (x) =>
        x.value === this.data.memberNavigationItem?.itemKey ||
        !this.data.memberNavigation?.menuItems.some(
          (m) => x.value === m.itemKey
        )
    );
  }
}
