import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { ReportActions } from '../actions';
import {
  ReportCategoryResponse,
} from '../types/models';
import { ReportService } from '../services/report.service';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService
  ) {}
  getReportCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.getReportCategories),
      switchMap(() => {
        return this.reportService.getCategories().pipe(
          map((response: ReportCategoryResponse) => {
            return ReportActions.getReportCategoriesSuccess({ response });
          }),
          catchError((error) => {
            return of(ReportActions.getReportCategoriesFailure({ error }));
          })
        );
      })
    )
  );
  updateCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.updateReportCategories),
      switchMap(({ request }) => {
        return this.reportService.UpdateCategories(request).pipe(
          map(() => {
            return ReportActions.updateReportCategoriesSuccess();
          }),
          catchError((error) => {
            return of(ReportActions.updateReportCategoriesFailure({ error }));
          })
        );
      })
    )
  );
}
