import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberBenefitListActions } from '../actions';
import {
  MemberListQuery,
  MembersBenefitList,
  MembersBenefitType,
} from '../types/models';
import { MemberBenefitService } from '../services/member-benefit.service';

@Injectable()
export class MemberBenefitEffects {
  getMemberBenefitList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberBenefitListActions.getMemberBenefitEligible),
      switchMap(({ query, idClient }) => {
        return this.memberBenefitService
          .memberBenefitList(query as MemberListQuery, idClient)
          .pipe(
            map((memberBenefitList: MembersBenefitList) => {
              return MemberBenefitListActions.getMemberBenefitEligibleSuccess({
                memberBenefitList,
              });
            }),
            catchError((err) => {
              return of(
                MemberBenefitListActions.getMemberBenefitEligibleFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  establishBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberBenefitListActions.establishMemberBenefit),
      switchMap(({ body, memberId }) => {
        return this.memberBenefitService
          .establishMemberBenefit(body, memberId)
          .pipe(
            map(() => {
              return MemberBenefitListActions.establishMemberBenefitSuccess({
                memberId,
              });
            }),
            catchError((err) => {
              return of(
                MemberBenefitListActions.establishMemberBenefitFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  getMemberBenefitType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberBenefitListActions.getMemberBenefitType),
      switchMap((memberId) => {
        return this.memberBenefitService.getBenefitType(memberId).pipe(
          map((memberBenefitType: MembersBenefitType) => {
            return MemberBenefitListActions.getMemberBenefitTypeSuccess({
              memberBenefitType,
            });
          }),
          catchError((err) => {
            return of(
              MemberBenefitListActions.getMemberBenefitTypeFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private memberBenefitService: MemberBenefitService
  ) {}
}
