import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ViewRichTextDialogComponent
} from 'src/app/shared/controls/view-rich-text/view-rich-text-dialog/view-rich-text-dialog.component';

@Component({
  selector: 'ptg-view-rich-text',
  templateUrl: './view-rich-text.component.html',
  styleUrls: ['./view-rich-text.component.scss']
})
export class ViewRichTextComponent implements OnInit {
  @Input() title: string | undefined = '';
  @Input() content: string | undefined = '';
  @Input() defaultContent: string | undefined = '';

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  openViewRichTextDialog(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ViewRichTextDialogComponent, {
      width: '1000px',
      height: 'auto',
      maxHeight: '90vh',
      data: {
        title: this.title || '',
        content: this.content || ''
      }
    })
  }
}
