import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DropStatementReport,
  DropStatementReportQuery,
  DropStatementsData,
} from '@ptg-member/types/models/drop-statement.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import {
  Breadcrumb,
  FunctionButtonConfig,
  FunctionButtonData,
} from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { DropStatementPopupComponent } from '../../containers/drop-statement-popup/drop-statement-popup.component';
import { DropStatementService } from '../../services/drop-statement-service.service';
import { DateTime } from 'luxon';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
const PAGE_SIZE_CONST = '-ptg-drop-report-pageSize';

@Component({
  selector: 'ptg-drop-statement',
  templateUrl: './drop-statement.component.html',
  styleUrls: ['./drop-statement.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropStatementComponent implements OnInit {
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Drop Statement',
      url: '',
    },
  ];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New DROP Statement',
      icon: 'list_alt',
      classInput: 'add-button',
    },
  ];
  dropReport: DropStatementReport[] = [];
  notFoundMessage: string = 'No Report to Display.';
  errorMsg?: string = '';
  isLoading: boolean = false;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  totalRecords: number | any;
  sortInfo: any = {};
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'categoryName',
      header: {
        title: 'Category',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'reportName',
      header: {
        title: 'Report Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'generatedAt',
      header: {
        title: 'Generated at',
      },
      sortable: true,
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy hh:mm a',
      },
    },
    {
      name: 'generatedBy',
      header: {
        title: 'Generated by',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header:{
        title: 'Action'
      },
    },
  ];
  readonly ACTION_COLUMN = ACTION_COLUMN;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private statementService: DropStatementService,
  ) {}

  ngOnInit(): void {
    this.closeBanner();
    this.getData();
  }

  openDropPopup() {
    this.closeBanner();
    const dialogRef = this.dialog.open(DropStatementPopupComponent, {
      panelClass: '',
      disableClose: true,
      data: {
        title: 'Generate DROP',
      },
      height: 'auto',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const customMessage = 'Drop Statement added successfully';
          showBanner.call(this, BannerType.Success, '', '', {
            customMessage,
          });
        this.getData();
      }
    });
  }

  emitFunction(event: FunctionButtonData) {
    if (event.buttonName === 'New DROP Statement') {
      this.openDropPopup();
    }
  }

  loadStatements(statementQuery: DropStatementReportQuery): void {
    this.isLoading = true;
    this.statementService.getDropStatementsList(statementQuery).subscribe(
      (data: DropStatementsData) => {
        this.isLoading = false;
        this.dropReport = data.dropStatementList.map((e:DropStatementReport)=>{
          return {
            id : e.id,
            categoryName : e.categoryName,
            fileName : e.fileName,
            generatedBy : e.generatedBy,
            reportName : e.reportName,
            generatedAt : DateTime.fromISO(e.generatedAt, {zone: 'utc'}).toJSDate().toString()
          }
        });
        this.totalRecords = data.totalCount;
      },
      (error: any) => {
        this.isLoading = false;
        const errorMessage = error?.error?.errorMessage[0] || 'Error found';
        showBanner.call(this, BannerType.Fail, '', '', {
          customMessage: errorMessage,
        });
      },
    );
  }

  onClickHyperlink(rowStatement: DropStatementReport) {
    this.statementService
      .downloadStatementPdf(rowStatement?.fileName)
      .subscribe(
        (response: any) => {
          this.saveFile(
            response?.fileContents,
            'application/pdf',
            rowStatement?.fileName + '.pdf',
          );
        },
        (error: any) => {
          this.isLoading = false;
          const errorMessage =
            error?.error?.errorMessage[0] || 'Download error found';
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: errorMessage,
          });
        },
      );
  }

  saveFile(base64String: string, contentType: string, fileName: string = '') {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  onChangeSort(event: any) {
    this.sortInfo = event;
    this.isLoading = true;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageSize.toString(),
    );
    this.getData();
  }

  getData() {
    let sortType = 1;
    let sortField = 'generatedAt';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = this.sortInfo.active;
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    let dropStatementsQuery: DropStatementReportQuery = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
    };

    this.loadStatements(dropStatementsQuery);
  }

  closeBanner() {
    this.bannerType = BannerType.Hidden;
  }

  onRemoveClick(row:any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Warning,
        title: 'Confirmation',
        cancelButtonTitle: 'No',
        text: `Are you sure you want to remove this report ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeStatement(row?.id);
      }
    });
  }

  removeStatement(statementId: string) {
    this.statementService.deleteStatement(statementId).subscribe({
      next:(res) => {
        this.getData();
      }, error:(err) => { }
    })
  }
}
