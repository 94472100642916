import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../utils/string.util';

@Pipe({
  name: 'numberLocalDecimal',
})
export class NumberLocalDecimalPipe implements PipeTransform {
  transform(
    value: number | string,
    unit?: string,
    decimal: number = 2,
    locales: string = 'en' // Set default locale to 'en' (English)
  ): string {
    if (isEmpty(value) || value === '') return '';

    if (isNaN(Number(value))) return value.toString();

    return (
      (unit ? unit + ' ' : '') +
      BigInt(value.toString().split('.')[0]).toLocaleString(
        locales
      ) +
      (decimal ? '.' + Number(value).toFixed(decimal).split('.')[1] : '')
    );
  }
}
