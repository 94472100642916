import { PropertyType as PROPERTY_TYPE } from '@ptg-member/constance/metadataPropertyType.const';
import { FilterPropertyType } from "./member-list.const";

export const PROPERTY_TYPE_CONVERSION: any = {
  [FilterPropertyType.Text]: PROPERTY_TYPE.TYPE_TEXT,
  [FilterPropertyType.Email]: PROPERTY_TYPE.TYPE_EMAIL,
  [FilterPropertyType.Phone]: PROPERTY_TYPE.TYPE_PHONE,
  [FilterPropertyType['Whole Number']]: PROPERTY_TYPE.TYPE_WHOLE_NUMBER,
  [FilterPropertyType.Currency]: PROPERTY_TYPE.TYPE_CURRENCY,
  [FilterPropertyType.Decimal]: PROPERTY_TYPE.TYPE_DECIMAL,
  [FilterPropertyType.Date]: PROPERTY_TYPE.TYPE_DATE,
  [FilterPropertyType.DateTime]: PROPERTY_TYPE.TYPE_DATE_TIME,
  [FilterPropertyType.List]: PROPERTY_TYPE.TYPE_LIST,
  [FilterPropertyType.Address]: PROPERTY_TYPE.TYPE_ADDRESS,
  [FilterPropertyType['Person Name']]: PROPERTY_TYPE.TYPE_PERSON_NAME,
  [FilterPropertyType.Employer]: PROPERTY_TYPE.TYPE_EMPLOYER,
  [FilterPropertyType.Percentage]: PROPERTY_TYPE.TYPE_PERCENTAGE,
  [FilterPropertyType.SSN]: PROPERTY_TYPE.TYPE_SSN,
  [FilterPropertyType.Status]: PROPERTY_TYPE.TYPE_STATUS,
  [FilterPropertyType.Register]: PROPERTY_TYPE.TYPE_TEXT,
  [FilterPropertyType.RichText]: PROPERTY_TYPE.TYPE_RICH_TEXT
}