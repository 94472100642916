import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  Status,
  SetProfileOverviewRequest,
  ProfileOverviewDetail,
  ProfileOverviewList,
  SectionMetadataList,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class ProfileOverviewConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getSectionMetadataList(): Observable<SectionMetadataList> {
    return this.httpClient.get<SectionMetadataList>(
      `${environment.apiUrl}/Metadata/ProfileOverview/SectionMetadatas`
    );
  }

  getProfileOverviewConfiguration(
    profileOverviewId: string
  ): Observable<ProfileOverviewDetail> {
    return this.httpClient.get<ProfileOverviewDetail>(
      `${environment.apiUrl}/Metadata/ProfileOverview/${profileOverviewId}/GetProfileOverviewDetails`
    );
  }

  setProfileOverview(body: SetProfileOverviewRequest): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.apiUrl}/Metadata/ProfileOverview/ManageProfileOverviews`,
      body
    );
  }

  getListProfileOverviewConfiguration(query: {
    SortNames: string;
    SortType: number;
  }): Observable<ProfileOverviewList> {
    const params = new HttpParams({ fromObject: query });
    return this.httpClient.get<ProfileOverviewList>(
      `${environment.apiUrl}/Metadata/ProfileOverview/GetListProfileOverview`,
      { params }
    );
  }

  getStatusNotUsed(): Observable<{ listStatusUsing: Status[] }> {
    return this.httpClient.get<{ listStatusUsing: Status[] }>(
      `${environment.apiUrl}/Metadata/GetStatusNotUsed`
    );
  }

  deleteProfileOverviewConfiguration(profileOverviewId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Metadata/ProfileOverview/${profileOverviewId}/DeleteProfileOverview`
    );
  }

  checkExistProfileName = (body: {
    name: string;
    profileOverviewId?: string;
  }) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/ProfileOverview/ExistProfileOverview`,
      body
    );
  };
}
