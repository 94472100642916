import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  EditMunicipalityServiceHistoryRequest,
  GetListStatusHistoryQuery,
  MunicipalityNameList,
  MunicipalityServiceRecordList,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityServiceHistoryService {
  constructor(private httpClient: HttpClient) {}

  getMunicipalityServiceHistoryList(
    query: GetListStatusHistoryQuery
  ): Observable<MunicipalityServiceRecordList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MunicipalityServiceRecordList>(
      `${environment.apiUrl}/Members/ServiceRecord`,
      { params }
    );
  }

  getMunicipalityList(): Observable<MunicipalityNameList> {
    return this.httpClient.get<MunicipalityNameList>(
      `${environment.apiUrl}/municipality/get-employers`
    );
  }

  editMunicipalityServiceHistory(body: EditMunicipalityServiceHistoryRequest) {
    let bodyRequest = {
      id: body.id,
      memberId: body.memberId,
      municipalityId: body.municipalityId,
      beginDate: body.beginDate,
      endDate: body.endDate ? body.endDate : null,
    };
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/edit-service-history`,
      bodyRequest
    );
  }

  checkExits = (bodyCheck: any) => {
    return this.httpClient.post(
      `${environment.apiUrl}/municipality/check-exist-service-record`,
      bodyCheck
    );
  };
}
