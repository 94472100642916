import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { BaseComponent } from '@ptg-shared/components';

import { MemberListActions } from '../../actions';
import {
  FilterInfo,
  FilterInformation,
  MetadataPropertyType,
  PropertyEditData,
} from '../../types/models';
import * as fromMember from '../../reducers';
import { MemberListService } from '../../services/member-list.service';

@Component({
  selector: 'ptg-manage-member-filter',
  templateUrl: './manage-member-filter.component.html',
  styleUrls: ['./manage-member-filter.component.scss'],
})
export class ManageMemberFilterComponent extends BaseComponent {
  editForm: FormGroup = this.fb.group({ memberFilters: this.fb.array([]) });
  properties!: MetadataPropertyType[];
  formSubmit$ = new Subject<boolean>();
  @Output() closeDialog: EventEmitter<PropertyEditData> = new EventEmitter();
  listFilterInfo!: FilterInformation[];
  isDisabledApply: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterInfo[],
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageMemberFilterComponent>,
    public memberService: MemberListService,
    private memberStore: Store<fromMember.MemberState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.memberStore
      .pipe(select(fromMember.selectListFilterInfo))
      .subscribe((listFilterInfo) => {
        if (listFilterInfo) {
          this.editForm = this.initFormGroup(listFilterInfo);
          this.listFilterInfo = listFilterInfo;
        }
      });
    this.memberStore.dispatch(MemberListActions.getMemberFilterRequest());
    this.memberStore
      .pipe(select(fromMember.selectRemoveMemberFilterState))
      .subscribe((id) => {
        if (id) {
          this.memberStore.dispatch(MemberListActions.removeMemberClear());
          const removedIndex = (
            this.editForm.controls['memberFilters'] as FormArray
          ).controls.findIndex((control) => control.value.id === id);
          if (removedIndex > -1) {
            (this.editForm.controls['memberFilters'] as FormArray).removeAt(
              removedIndex
            );
          }
        }
      });
    this.memberStore
      .pipe(select(fromMember.selectUpdateFilterNameState))
      .subscribe((id) => {
        if (id) {
          this.memberStore.dispatch(MemberListActions.removeMemberClear());
          const updatedControl = (
            this.editForm.controls['memberFilters'] as FormArray
          ).controls.find((control) => control.value.id === id) as FormGroup;
          if (updatedControl) {
            updatedControl.setControl(
              'name',
              this.fb.control(
                updatedControl.value.name,
                Validators.required,
                checkApiValidator(
                  this.memberService.checkMemberFilterExist,
                  'name',
                  updatedControl.value.name
                )
              )
            );
            updatedControl.controls['editMode'].setValue(false);
            this.checkApplyDisabled();
          }
        }
      });
  }

  initFormGroup(memberFilters: FilterInformation[]) {
    const formData = this.fb.group({
      memberFilters: this.fb.array([]),
    });
    memberFilters.forEach((filter) => {
      (formData.controls['memberFilters'] as FormArray).push(
        this.fb.group({
          id: filter.id,
          clientId: filter.clientId,
          name: this.fb.control(
            filter.name,
            Validators.required,
            checkApiValidator(
              this.memberService.checkMemberFilterExist,
              'name',
              filter.name
            )
          ),
          listFilterInfo: this.fb.control(filter.listFilterInfo),
          editMode: false,
        })
      );
    });
    return formData;
  }

  onCancel() {
    this.dialogRef.close();
  }

  editFilterName(index: number) {
    (
      (this.editForm.controls['memberFilters'] as FormArray).controls[
        index
      ] as FormGroup
    ).controls['editMode'].setValue(true);
    this.checkApplyDisabled();
  }

  closeEditMode(index: number) {
    (
      (this.editForm.controls['memberFilters'] as FormArray).controls[
        index
      ] as FormGroup
    ).controls['editMode'].setValue(false);
    (
      (this.editForm.controls['memberFilters'] as FormArray).controls[
        index
      ] as FormGroup
    ).controls['name'].setValue(this.listFilterInfo[index].name);
    this.checkApplyDisabled();
  }

  saveName(element: FormGroup) {
    if (element.pending) {
      let sub = element.statusChanges.subscribe(() => {
        if (element.valid) {
          this.memberStore.dispatch(
            MemberListActions.updateFilterNameRequest({
              memberFilterName: {
                id: element.value.id,
                name: element.value.name,
                listFilterInfo: [],
              },
            })
          );
        }
        sub.unsubscribe();
      });
    } else if (element.valid) {
      this.memberStore.dispatch(
        MemberListActions.updateFilterNameRequest({
          memberFilterName: {
            id: element.value.id,
            name: element.value.name,
            listFilterInfo: [],
          },
        })
      );
    }
  }

  removeFilter(id: string) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Remove Item',
        type: ConfirmType.Delete,
        text: `Removing this Filter will clear the recipient lists and schedules of any In Review and Scheduled Notifications using it. <br>Are you sure you want to remove this Filter?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          MemberListActions.removeMemberFilterRequest({ id })
        );
      }
    });
  }

  applyFilter(listFilterInfo: FilterInfo[]) {
    this.dialogRef.close(listFilterInfo);
  }

  checkApplyDisabled() {
    this.isDisabledApply = this.editForm.controls['memberFilters'].value.some(
      (filter: any) => filter.editMode
    );
  }
}
