import { createAction, props } from '@ngrx/store';

import {
  GetBenefitTypesResponse,
  GetLookupTableOptionsResponse,
  GetStatusAndEventsResponse,
  GetBenefitRecordsResponse,
  GetBenefitResponse,
  GetQDROBenefitTypesResponse,
  GetLookupTablesReponse,
  UpdateBenefitRequest,
  AddBenefitTypeRequest,
  GetBenefitTypesRequest,
  GetQDROMetaDataReponse,
  SetBenefitTypeRequest,
} from '../types/models';

export const GetBenefitRequestAction = '[GetBenefit/API] Send Request';
export const GetBenefitFailureAction = '[GetBenefit/API] Failure';
export const GetBenefitSuccessAction = '[GetBenefit/API] Success';
export const ClearGetBenefitStateAction = '[ClearGetBenefitState] Clear';

export const GetBenefitTypesRequestAction =
  '[GetBenefitTypes/API] Send Request';
export const GetBenefitTypesFailureAction = '[GetBenefitTypes/API] Failure';
export const GetBenefitTypesSuccessAction = '[GetBenefitTypes/API] Success';
export const ClearGetBenefitTypesStateAction =
  '[ClearGetBenefitTypesState] Clear';

export const AddBenefitTypeRequestAction = '[AddBenefitType/API] Send Request';
export const AddBenefitTypeFailureAction = '[AddBenefitType/API] Failure';
export const AddBenefitTypeSuccessAction = '[AddBenefitType/API] Success';

export const GetLookupTableOptionsRequestAction =
  '[GetLookupTableOptions/API] Send Request';
export const GetLookupTableOptionsSuccessAction =
  '[GetLookupTableOptions/API] Success';
export const GetLookupTableOptionsFailureAction =
  '[GetLookupTableOptions/API] Failure';

export const GetStatusAndEventsRequestAction =
  '[GetStatusAndEvents/API] Send Request';
export const GetStatusAndEventsSuccessAction =
  '[GetStatusAndEvents/API] Success';
export const GetStatusAndEventsFailureAction =
  '[GetStatusAndEvents/API] Failure';

export const GetBenefitRecordsRequestAction =
  '[GetBenefitRecords/API] Send Request';
export const GetBenefitRecordsSuccessAction = '[GetBenefitRecords/API] Success';
export const GetBenefitRecordsFailureAction = '[GetBenefitRecords/API] Failure';

export const GetParentTypeRequest = '[GetParentType/API] Send Request';
export const GetParentTypeSuccess = '[GetParentType/API] Success';
export const GetParentTypeFailure = '[GetParentType/API] Failure';

export const GetBenefitRecordRequest = '[GetBenefitRecord/API] Send Request';
export const GetBenefitRecordSuccess = '[GetBenefitRecord/API] Success';
export const GetBenefitRecordFailure = '[GetBenefitRecord/API] Failure';

export const UpdateBenefitRequestAction = '[UpdateBenefit/API] Send Request';
export const UpdateBenefitSuccessAction = '[UpdateBenefit/API] Success';
export const UpdateBenefitFailureAction = '[UpdateBenefit/API] Failure';

export const GetQDROBenefitTypesRequestAction =
  '[GetQDROBenefitTypes/API] Send Request';
export const GetQDROBenefitTypesSuccessAction =
  '[GetQDROBenefitTypes/API] Success';
export const GetQDROBenefitTypesFailureAction =
  '[GetQDROBenefitTypes/API] Failure';

export const GetLookupTablesRequestAction =
  '[GetLookupTables/API] Send Request';
export const GetLookupTablesSuccessAction = '[GetLookupTables/API] Success';
export const GetLookupTablesFailureAction = '[GetLookupTables/API] Failure';

export const GetQDROMetadatasRequestAction =
  '[GetQDROMetadatas/API] Send Request';
export const GetQDROMetadatasSuccessAction = '[GetQDROMetadatas/API] Success';
export const GetQDROMetadatasFailureAction = '[GetQDROMetadatas/API] Failure';

export const RemoveBenefitTypeRequestAction =
  '[RemoveBenefitType/API] Send Request';
export const RemoveBenefitTypeSuccessAction = '[RemoveBenefitType/API] Success';
export const RemoveBenefitTypeFailureAction = '[RemoveBenefitType/API] Failure';

export const UpdateBenefitTypeRequestAction =
  '[UpdateBenefitType/API] Send Request';
export const UpdateBenefitTypeSuccessAction = '[UpdateBenefitType/API] Success';
export const UpdateBenefitTypeFailureAction = '[UpdateBenefitType/API] Failure';

export const GetAllBenefitTypesRequestAction =
  'GetAllBenefitTypes/API] Send Request';
export const GetAllBenefitTypesFailureAction =
  '[GetAllBenefitTypes/API] Failure';
export const GetAllBenefitTypesSuccessAction =
  '[GetAllBenefitTypes/API] Success';

export const getBenefit = createAction(GetBenefitRequestAction);
export const getBenefitSuccess = createAction(
  GetBenefitSuccessAction,
  props<{ response: GetBenefitResponse }>()
);
export const getBenefitFailure = createAction(
  GetBenefitFailureAction,
  props<{ error?: any }>()
);
export const clearGetBenefitState = createAction(ClearGetBenefitStateAction);

export const getBenefitTypes = createAction(
  GetBenefitTypesRequestAction,
  props<{ request: GetBenefitTypesRequest }>()
);
export const getBenefitTypesSuccess = createAction(
  GetBenefitTypesSuccessAction,
  props<{ response: GetBenefitTypesResponse }>()
);
export const getBenefitTypesFailure = createAction(
  GetBenefitTypesFailureAction,
  props<{ error?: any }>()
);
export const clearGetBenefitTypesState = createAction(
  ClearGetBenefitTypesStateAction
);
export const getAllBenefitTypes = createAction(
  GetAllBenefitTypesRequestAction,
  props<{ request?: GetBenefitTypesRequest }>()
);
export const getAllBenefitTypesSuccess = createAction(
  GetAllBenefitTypesSuccessAction,
  props<{ response: GetBenefitTypesResponse }>()
);
export const getAllBenefitTypesFailure = createAction(
  GetAllBenefitTypesFailureAction,
  props<{ error?: any }>()
);

export const getStatusAndEvents = createAction(GetStatusAndEventsRequestAction);
export const getStatusAndEventsSuccess = createAction(
  GetStatusAndEventsSuccessAction,
  props<{ response: GetStatusAndEventsResponse }>()
);
export const getStatusAndEventsFailure = createAction(
  GetStatusAndEventsFailureAction,
  props<{ error?: any }>()
);

export const getBenefitRecords = createAction(GetBenefitRecordsRequestAction);
export const getBenefitRecordsSuccess = createAction(
  GetBenefitRecordsSuccessAction,
  props<{ response: GetBenefitRecordsResponse }>()
);
export const getBenefitRecordsFailure = createAction(
  GetBenefitRecordsFailureAction,
  props<{ error?: any }>()
);

export const getLookupTableOptions = createAction(
  GetLookupTableOptionsRequestAction
);
export const getLookupTableOptionsSuccess = createAction(
  GetLookupTableOptionsSuccessAction,
  props<{ response: GetLookupTableOptionsResponse }>()
);
export const getLookupTableOptionsFailure = createAction(
  GetLookupTableOptionsFailureAction,
  props<{ error?: any }>()
);

export const addBenefitType = createAction(
  AddBenefitTypeRequestAction,
  props<{ request: AddBenefitTypeRequest }>()
);
export const addBenefitTypeSuccess = createAction(AddBenefitTypeSuccessAction);
export const addBenefitTypeFailure = createAction(
  AddBenefitTypeFailureAction,
  props<{ error?: any }>()
);

export const updateBenefit = createAction(
  UpdateBenefitRequestAction,
  props<{ request: UpdateBenefitRequest }>()
);
export const updateBenefitSuccess = createAction(UpdateBenefitSuccessAction);
export const updateBenefitFailure = createAction(
  UpdateBenefitFailureAction,
  props<{ error?: any }>()
);

export const getQDROBenefitTypesRequest = createAction(
  GetQDROBenefitTypesRequestAction
);
export const getQDROBenefitTypesSuccess = createAction(
  GetQDROBenefitTypesSuccessAction,
  props<{ response: GetQDROBenefitTypesResponse }>()
);
export const getQDROBenefitTypesFailure = createAction(
  GetQDROBenefitTypesFailureAction,
  props<{ error?: any }>()
);

export const getLookupTablesRequest = createAction(
  GetLookupTablesRequestAction
);
export const getLookupTablesSuccess = createAction(
  GetLookupTablesSuccessAction,
  props<{ response: GetLookupTablesReponse }>()
);
export const getLookupTablesFailure = createAction(
  GetLookupTablesFailureAction,
  props<{ error?: any }>()
);

export const getQDROMetadatasRequest = createAction(
  GetQDROMetadatasRequestAction
);
export const getQDROMetadatasSuccess = createAction(
  GetQDROMetadatasSuccessAction,
  props<{ response: GetQDROMetaDataReponse }>()
);
export const getQDROMetadatasFailure = createAction(
  GetQDROMetadatasFailureAction,
  props<{ error?: any }>()
);

export const removeBenefitType = createAction(
  RemoveBenefitTypeRequestAction,
  props<{ benefitTypeId: string }>()
);
export const removeBenefitTypeSuccess = createAction(
  RemoveBenefitTypeSuccessAction
);
export const removeBenefitTypeFailure = createAction(
  RemoveBenefitTypeFailureAction,
  props<{ error?: any }>()
);
export const updateBenefitType = createAction(
  UpdateBenefitTypeRequestAction,
  props<{ request: SetBenefitTypeRequest }>()
);
export const updateBenefitTypeSuccess = createAction(
  UpdateBenefitTypeSuccessAction
);
export const updateBenefitTypeFailure = createAction(
  UpdateBenefitTypeFailureAction,
  props<{ error?: any }>()
);
