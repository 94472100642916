import { Address, PersonName } from '@ptg-shared/models/common.model';

import { PayStatus } from '../../constance/member-list.const';
import {
  PaymentInfoType,
  OffCyclePaymentStatusType,
} from '../../constance/next-payment.const';

export interface NextPaymentData {
  offCyclePayment?: OffCyclePayment;
  notes?: any;
  deductions?: any;
  earnings?: any;
  payments?: any;
  payrollSettings?: any;
  settings?: any;
  isEstablishBenefit?: boolean;
  representativePayee?: any;
}
export interface PaymentHistoryQuery {
  pageIndex: number;
  totalPerPage?: number;
  sortField?: string;
  sortType?: number;
}

export interface ParticipantEarning {
  id?: string;
  earningItemId?: string;
  name?: string;
  value?: number;
  manualAdjustmented?: boolean;
  order?: number;
}

export interface RecalculateTotalDeductionsData {
  recalculateFederalTax: boolean;
  recalculateStateTax: boolean;
  earnings: ParticipantEarning[];
}

export interface UpdateEarningsData {
  recalculateFederalTax: boolean;
  recalculateStateTax: boolean;
  earnings: ParticipantEarning[];
}

export interface PaymentNotesQuery {
  pageIndex: number;
  totalPerPage: number;
  paymentSourceId?: string;
}

export interface PendingPayment {
  id: string;
  paymentSourceId: string;
  targetId: string;
  payStatus?: PayStatus | string;
  paymentInfoType: PaymentInfoType;
  depositDate: string;
  payee: string;
  gross: number;
  deds: number;
  dedsTotal: number;
  netPayment: number;
  hasNote: boolean;
  note: boolean;
  offCyclePaymentStatus?: OffCyclePaymentStatusType;
  dateCreated: string;
  type: PaymentInfoType;
  paymentType: PaymentInfoType;
  paymentTypeName: string;
}

export interface PendingPaymentQuery {
  participantId: string;
  pageIndex: number;
  totalPerPage?: number;
  sortField?: string;
  sortType?: number;
}

export interface IssueOffCyclePaymentRequest {
  participantId: string;
  offCyclePaymentId: string;
}

export interface MemberAddressItems {
  items: MemberAddressItem[];
}

export interface MemberAddressItem {
  memberId: string;
  clientKey: string;
  itemKey: string;
  itemName: string;
  propertyKey: string;
  propertyName: string;
  address: Address;
  index: number;
}

export interface RepresentativePayees {
  representativePayees: RepresentativePayee[];
}

export interface RepresentativePayee {
  clientKey: string;
  itemKey: string;
  namePropertyKey: string;
  addressPropertyKey: string;
  typePropertyKey: string;
  name: PersonName;
  address: Address;
  type: string;
  index: number;
}

export interface OffCyclesPayments {
  id: string;
  participantId: string;
  createdBy?: string;
  reason: string;
  withTaxes: boolean;
  paymentAddress: PaymentAddress;
  representativePayee: RepresentativePayee;
  earnings: ParticipantEarning[];
}

export interface PaymentAddress {
  clientKey: string;
  itemKey: string;
  propertyKey: string;
  index: number;
  address: Address;
}

export interface PaymentDeductionQuery {
  sortField?: string;
  sortType?: number;
}

export interface PaymentDeduction {
  id?: string;
  clientId: string;
  description: string;
  deductionCode: string;
  currentAmount: number;
  totalPayee: number;
  deductionType: number;
  deductionTypeTitle: string;
  effectiveStartDate: string;
  effectiveEndDate: string;
  deductionSetting: string;
  vendorId: string;
  vendorName: string;
  deductionParameterValues: any[];
}

export interface OffCyclePayment {
  id?: string;
  offCycleId?: string;
  paymentTargetId?: string;
  paymentStatus?: OffCyclePaymentStatusType;
  issuedDate?: string;
  paymentInfoType?: PaymentInfoType;
  isCompleted?: boolean;
  originalTransactionId: string;
}

export interface PaymentInforConfig {
  id: string;
  isDisplay: boolean;
  children?: PaymentInforConfig[];
}

export interface PaymentInforConfigData {
  id: string;
  name: string;
  code: string;
  isDisplay: boolean;
  order: number;
  dateCreated: string;
  createdBy: string;
  children: PaymentInforConfigData[];
}

export interface PaymentInforConfigObj {
  DEDUCTIONS: boolean;
  DEDUCTIONSYEARTODATE: boolean;
  EARNINGS: boolean;
  EARNINGSYEARTODATE: boolean;
  PAYMENTS: boolean;
  PENDINGPAYMENTS: boolean;
  PAYROLLSETTINGS: boolean;
  EARNINGITEMS: boolean;
  NOTE: boolean;
}

export interface PaymentHistory {
  clientId: string;
  dedsTotal: number;
  deds: number;
  depositDate: string;
  gross: number;
  hasNote: boolean;
  note: boolean;
  id: string;
  netPayment: number;
  participantId: string;
  payStatus: PayStatus;
  prevPayStatus: PayStatus;
  payee: string;
  paymentSourceId: string;
  paymentType: PaymentInfoType;
  paymentTypeName: string;
  targetId: string;
  type: PaymentInfoType;
}

export interface PayrollConfigurationInfo {
  configuredIdName: string;
  isEncryptedConfiguredId: boolean;
  isEncryptedConfiguredName: boolean;
}

export interface NextPaymentColumnConfig {
  columnName: string;
  orderColumn: number;
  orderRow: number;
  propertyKey: string;
  sortType: number;
}

export interface PaymentHistoryListResponse {
  total: number;
  paymentHistories: PaymentHistory[];
  columns: NextPaymentColumnConfig[];
}
export interface PendingPaymentListResponse {
  total: number;
  pendingPayments: PendingPayment[];
  columns: NextPaymentColumnConfig[];
}
