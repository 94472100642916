import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutActions } from 'src/app/shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import {
  ACTION_COLUMN,
  Align,
  Column,
  ColumnType,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import * as fromReducer from '@ptg-reducers/index';
import { AggregationGroupModalComponent } from '@ptg-member/components/aggregation-group-modal/aggregation-group-modal.component';
import { AggregationGroupActions } from '@ptg-member/actions';
import * as fromMember from '@ptg-member/reducers';
import * as MetadataSectionActions from '@ptg-member/actions/metadata-detail.actions';
import { AggregationGroupService } from '@ptg-member/services/aggregation-group.service';
import { AggregationConfigService } from '@ptg-member/services/aggregation-config.service';
import { AggregationGroup } from '@ptg-member/types/models/aggregation-group.model';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';

@Component({
  selector: 'ptg-aggregation-group-configuration',
  templateUrl: './aggregation-group-configuration.component.html',
  styleUrls: ['./aggregation-group-configuration.component.scss'],
})
export class AggregationGroupConfigurationComponent extends BaseComponent {
  columns: Column[] = [
    {
      name: 'groupLabel',
      header: {
        title: 'Label',
      },
      truncate: true,
    },
    {
      name: 'count',
      header: {
        title: 'Aggregation Count',
      },
      type: ColumnType.Decimal,
      align: Align.Right,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Actions',
      },
      width: '230px',
    },
  ];
  sectionKey: string = '';
  aggregationGroupsData: (AggregationGroup & Row)[] = [];
  errorMsg?: string;
  isLoading: boolean = true;
  currentFund: any = {};
  message = '';
  sectionName: string = '';
  bannerType: BannerType = BannerType.Hidden;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Member List',
      url: '/member',
    },
    {
      name: 'Member Metadata',
      url: '/member/member-metadata',
    },
    {
      name: this.sectionName,
      url: `/member/member-metadata/${this.sectionKey}`,
      canTruncate: true,
    },
    {
      name: 'Aggregation Configuration',
      url: `/member/member-metadata/${this.sectionKey}/aggregation`,
      preventTruncate: true,
    },
    {
      name: 'Aggregation Group Configuration',
      preventTruncate: true,
    },
  ];
  groupName: string = '';
  action: string = '';

  constructor(
    public dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private route: ActivatedRoute,
    private aggregationGroupService: AggregationGroupService,
    private aggregationConfigService: AggregationConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.route.params.subscribe((params) => {
      this.aggregationGroupService.sectionKey = params.key;
      this.aggregationConfigService.sectionKey = params.key;
      this.memberStore.dispatch(
        MetadataSectionActions.getMetadataSection({ key: params.key })
      );
      this.store.dispatch(AggregationGroupActions.getAggregationGroupList());
      this.memberStore
        .pipe(
          select(fromMember.selectMetadataSectionState),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((el) => {
          this.sectionName = el.metadataSection?.name as string;
          this.sectionKey = el.metadataSection?.key as string;
          this.listBreadcrumbs = [
            {
              name: 'Participant List',
              moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
              url: '/member',
            },
            {
              name: 'Participant Metadata',
              url: '/member/member-metadata',
            },
            {
              name: this.sectionName,
              url: `/member/member-metadata/${this.sectionKey}`,
              canTruncate: true,
            },
            {
              name: 'Aggregation Configuration',
              url: `/member/member-metadata/${this.sectionKey}/aggregation`,
              preventTruncate: true,
            },
            {
              name: 'Aggregation Group Configuration',
              preventTruncate: true,
            },
          ];
        });
    });
    this.memberStore
      .pipe(
        select(fromMember.selectAggregationGroupState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = state.isLoading;
        this.aggregationGroupsData = state.aggregationGroup;

        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }

        if (
          state.createState === STATE.SUCCESS ||
          state.updateState === STATE.SUCCESS ||
          state.removeState === STATE.SUCCESS
        ) {
          this.store.dispatch(AggregationGroupActions.clearAggregationGroup());
          showBanner.call(
            this,
            BannerType.Success,
            this.groupName,
            this.action
          );
          this.store.dispatch(
            AggregationGroupActions.getAggregationGroupList()
          );
        }
        if (
          state.createState === STATE.FAIL ||
          state.updateState === STATE.FAIL ||
          state.removeState === STATE.FAIL
        ) {
          this.store.dispatch(AggregationGroupActions.clearAggregationGroup());
          showBanner.call(this, BannerType.Fail, this.groupName, this.action);
        }
      });
  }

  onClickBreadcrumb(url: string) {
    if (url === '/member') {
      this.store.dispatch(LayoutActions.closeSideMenu());
    }
  }

  openForm(row?: AggregationGroup & Row) {
    let dialogRef = this.dialog.open(AggregationGroupModalComponent, {
      panelClass: 'aggregation-group-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: { aggregationGroup: row },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.groupName = result.groupName;
        this.action = row ? ACTION.EDIT : ACTION.ADD;
      }
    });
  }

  onRemoveClick(row: AggregationGroup & Row) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: 'Are you sure you want to remove this Aggregation Group?',
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          AggregationGroupActions.removeAggregationGroup({ id: row.id })
        );
        this.groupName = row.groupLabel;
        this.action = ACTION.REMOVE;
      }
    });
  }

  changeItem(event: ReorderInfo) {
    this.store.dispatch(
      AggregationGroupActions.orderTable({
        body: {
          reorderItemId: event.reorderItemKey,
          upperAdjacentId: event.upperAdjacentKey,
        },
      })
    );
  }
}
