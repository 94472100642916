import { FormControl } from '@angular/forms';

export class FieldData {
  key: string;
  name: any;
  label: any;
  type: any;
  required: boolean;
  maxLength: any;
  formControl?: FormControl;
  lstOption?: any[];
  className?: any;
  constructor(
    key: string,
    name: any,
    label: any,
    type: any,
    required: any,
    maxLength: any,
    className: any
  ) {
    this.key = key;
    this.name = name;
    this.label = label;
    this.type = type;
    this.required = required;
    this.maxLength = maxLength;
    this.className = className;
  }
}
