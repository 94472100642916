<div class="header-container">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="onEditMemberStatus()"
    [buttonAddLabel]="!isMetadataSection ? 'Add Status' : ''"
  >
  </ptg-breadcrumb>
  <div class="right-title">
    <!--TODO enhance in next sprint-->
    <!-- <div class="upload-btn" (click)="uploadFile()" *ngIf="sectionData?.hasAttachment">
      <mat-icon svgIcon="upload-icon" aria-hidden="false" aria-label="upload-icon" ></mat-icon>
      <div>Upload</div>
    </div> -->
    <div class="add-record-container" *ngIf="isMetadataSection">
      <ptg-select
        (changeOptionValue)="addSectionRecord()"
        [controlField]="addTypeSelectControl"
        [isOptionObj]="true"
        [listData]="addTypeOptions"
        placeholder="Add a Record"
        [isCheckChange]="true"
      >
      </ptg-select>
    </div>
  </div>
</div>

<div class="overview-section flex flex-col">
  <ng-container *ngIf="dataTable">
    <div *ngIf="bannerType" class="p-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>

    <div class="flex-grow">
      <ptg-grid
        [data]="dataTable"
        [columns]="columns"
        keyColumn="index"
        [errorMessage]="errorMsg"
        [isLoading]="isLoading"
        [paginable]="!isMetadataSection"
        [allowDragDrop]="isMetadataSection"
        [swapKeyValue]="true"
        [hideScrollbar]="false"
        [fitToParent]="!isMetadataSection"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="onChangePage($event)"
        (rowDrop)="changeItemIndex($event)"
        (sortChange)="!isMetadataSection ? onSortChange($event) : null"
        notFoundMessage="No Overview Section to Display"
      >
        <ng-template cellContent columnName="noOfAttachments" let-row>
          <a
            (click)="viewAttachment(row)"
            href="javascript:void(0)"
            class="link"
            >{{ row.noOfAttachments }}</a
          >
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="editRecord(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            *ngIf="isMetadataSection"
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="removeRecord(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </ng-container>
</div>
