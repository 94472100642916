<h2>Generate Drop Statement</h2>
<ptg-banner
  *ngIf="bannerType"
  [(bannerType)]="bannerType"
  [message]="message"
  [isHideBannerIcon]="isHideBannerIcon"
  [isHideCloseButton]="isHideCloseButton"
></ptg-banner>
<form [formGroup]="formGenerateStatement" class="add-employer-form">
  <ptg-textbox
    [controlField]="formGenerateStatement.get('fiscalYear')"
    [isRequired]="true"
    [inputType]="inputType"
    [placeholder]="'Fiscal Year'"
    [customError]="'fiscalError'"
    [hasLabel]="true"
  >
  </ptg-textbox>
  <ptg-select
    [controlField]="formGenerateStatement.get('month')"
    [isRequired]="true"
    [listData]="monthValues"
    [isSetMaxWidthSelect]="true"
    [placeholder]="'Month'"
  >
  </ptg-select>
</form>

<div class="wrap-btn">
  <button
    mat-raised-button
    type="submit"
    class="btn-save btn-statement-save"
    id="btn-save-employer"
    (click)="onSubmit()"
    [disabled]="!formGenerateStatement.valid || isBtnDisable"
  >
    Generate
  </button>
  <button
    mat-raised-button
    type="button"
    class="btn-cancel btn-statement-cancel"
    id="btn-cancel-employer"
    (click)="onCancel()"
    [disabled]="isBtnDisable"
  >
    Cancel
  </button>
</div>
