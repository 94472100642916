import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { BaseComponent } from '@ptg-shared/components';
import { getMemberNavigationListSelector } from '@ptg-shared/layout/reducers';
import { HistoryService } from '@ptg-shared/services/history.service';
import * as fromReducer from '@ptg-reducers';

import { SectionLayout } from '../../constance/metadata.const';
import { MemberState, getProfileHeadersSelector } from '../../reducers';
import { PaymentInformationConfigurationComponent } from '../../components/payment-information-configuration/payment-information-configuration.component';
import { PaymentListConfigurationComponent } from '../../components/payment-list-configuration/payment-list-configuration.component';
import { dispatchGetMemberProfileHeaders } from '../../helpers';
import { getMemberProfileHeadersDataAction } from '@ptg-member/actions/profile-header-configuration.actions';

@Component({
  selector: 'ptg-member-navigation',
  templateUrl: './member-navigation.component.html',
  styleUrls: ['./member-navigation.component.scss'],
})
export class MemberNavigationComponent extends BaseComponent {
  readonly SectionLayout = SectionLayout;

  listBreadcrumbs: Breadcrumb[] = [];
  settings: Breadcrumb[] = [];
  isDisabledGear: boolean = false;
  isList?: boolean;
  memberId: string = '';
  menuId: string = '';
  sectionType?: string;
  itemKey: string = '';
  menuItemName: string = '';

  currentSectionLayout?: SectionLayout;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private memberStore: Store<MemberState>,
    public historyService: HistoryService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Receive the parameters from the url
    this.route.params.subscribe((params) => {
      this.memberId = params.id;
      this.menuId = params.menuId;
      this.sectionType = params.sectionType;
      if (this.memberId)
        this.store.dispatch(
          getMemberProfileHeadersDataAction({ memberId: this.memberId })
        );
    });

    this.historyService.isBackNextPayroll =
      sessionStorage.getItem('isBackNextPayroll') === 'true';

    combineLatest([
      this.store.select(getMemberNavigationListSelector), // Get left menu navigation
      this.memberStore.select(getProfileHeadersSelector), // Get Profile Overview Headers information
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([state1, state2]) => {
        if (
          !!state1?.isLoading ||
          !state1?.success ||
          !state1?.payload ||
          !!state2?.isLoading ||
          !state2?.success ||
          !state2?.payload
        ) {
          return;
        }

        // Receive data from the states
        const memberNavMenus =
          state1.payload.memberNavigationList?.memberNavigationMenu ?? [];
        const memberStatusId = state2.payload.memberStatusId;

        // Get the current menu
        const currentNavigationMenu = memberNavMenus.find((menu) =>
          menu?.menuItem?.some((item) => item.id === this.menuId)
        );

        // Check permission
        const hasPermission =
          currentNavigationMenu?.statusList?.includes('All') ||
          currentNavigationMenu?.statusList?.some(
            (status) => status === memberStatusId
          );
        if (!hasPermission) {
          void this.router.navigateByUrl(`/member/detail/${this.memberId}`);
        }

        // Get the current menu item
        const memberNavigationItem = currentNavigationMenu?.menuItem?.find(
          (navigation) => navigation.id === this.menuId
        );
        this.currentSectionLayout = memberNavigationItem?.type;
        this.itemKey = memberNavigationItem?.itemKey ?? '';
        this.menuItemName = memberNavigationItem?.name ?? '';
        this.isList = memberNavigationItem?.type === SectionLayout.List;

        // Create breadcrumb data
        this.listBreadcrumbs = [
          {
            name: memberNavigationItem?.name ?? '',
          },
        ];

        // Create breadcrumb's settings data
        if (memberNavigationItem?.type === SectionLayout.List) {
          this.settings = [
            {
              name: memberNavigationItem?.name + ' Section',
              url: `/member/profile-navigation-configuration/${this.memberId}/detail/${currentNavigationMenu?.id}`,
            },
            {
              name: memberNavigationItem?.name + ' List',
              url: `/member/navigation/list-configuration/${this.sectionType}/${this.menuId}/${this.memberId}`,
            },
          ];
        } else if (memberNavigationItem?.type === SectionLayout.Payment) {
          this.settings = [
            {
              name: 'Payment Info Configuration',
            },
            {
              name: 'Payment List Configuration',
            },
          ];
        } else if (this._isSpecialPage()) {
          this.settings = [];
        } else {
          this.settings = [
            {
              name: memberNavigationItem?.name + ' Section',
              url: `/member/profile-navigation-configuration/${this.memberId}/detail/${currentNavigationMenu?.id}`,
            },
            {
              name: memberNavigationItem?.name + ' Configuration',
              url: `/member/navigation/nonlist-configuration/${this.sectionType}/${this.menuId}/${this.memberId}`,
            },
          ];

          if (
            memberNavigationItem?.type === SectionLayout.ParticipantRelationship
          ) {
            this.isDisabledGear = true;
          } else {
            this.isDisabledGear = false;
          }
        }
        const { annual1099Id } = this.route.snapshot.queryParams;
        if (memberNavigationItem?.type === SectionLayout.Info1099) {
          this.settings = [];
          if (annual1099Id) {
            this.listBreadcrumbs = [
              {
                name: '1099-R Processing',
                url: `/processing/annual1099/${annual1099Id}?keptState=true`
              },
              { name: '1099-R Info' }
            ];
          }
        }
      });
    if (this.memberId)
      dispatchGetMemberProfileHeaders(this.memberStore, this.memberId);
  }

  private _isSpecialPage(): boolean {
    return (
      this.currentSectionLayout !== undefined &&
      [
        SectionLayout.StatusHistory,
        SectionLayout.PaymentHistory,
        SectionLayout.MunicipalityPayment,
        SectionLayout.MunicipalityServiceRecord,
      ].includes(this.currentSectionLayout)
    );
  }

  onSelectSetting(settingName: string) {
    // TODO Move hard string to the constants after merge with the develop branch
    if (settingName === 'Payment Info Configuration') {
      this.dialog.open(PaymentInformationConfigurationComponent, {
        panelClass: 'dialog-full-screen',
        autoFocus: false,
        disableClose: true,
        data: {
          sectionType: this.sectionType,
          sectionId: this.menuId,
          memberId: this.memberId,
          menuItemName: this.menuItemName,
        },
      });
    } else if (settingName === 'Payment List Configuration') {
      this.dialog.open(PaymentListConfigurationComponent, {
        panelClass: 'dialog-full-screen',
        autoFocus: false,
        disableClose: true,
        data: {
          sectionType: this.sectionType,
          sectionId: this.menuId,
          memberId: this.memberId,
          menuItemName: this.menuItemName,
        },
      });
    }
  }
}
