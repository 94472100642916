<div class="edit-status-container" id="edit-status-dialog">
  <ptg-title-bar
    [name]="
      statusDetail?.id ? 'Edit Tier ' + statusDetail.tierName : 'Add New Tier'
    "
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm" *ngIf="!isLoading">
    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        (click)="submit()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-control-container">
      <div class="col-item flex">
        <ptg-textbox
          placeholder="Tier Code"
          errorAsync="Tier Code already exists."
          [controlField]="editForm.get('tierCode')"
          [hasLabel]="true"
          [maxLength]="20"
        ></ptg-textbox>
        <ptg-textbox
          placeholder="Tier Name"
          errorAsync="Tier Name already exists."
          [controlField]="editForm.get('tierName')"
          [hasLabel]="true"
          [maxLength]="255"
        ></ptg-textbox>
      </div>
      <ptg-textbox  [suffix]="'$'" (paste)="(false)" [controlField]="editForm.get('salaryCapping')" placeholder="Annual Salary Cap" [hasLabel]="true" [isRequired]="true" [class]="'text-box'"
      [prefix]="''"  [inputType] ="InputType.TYPE_PERCENTAGE"
      [allowNegativeNumbers] = false
      [min]="1"
      [max]="maxValueNumber"
      [maxLength]="16"></ptg-textbox>

      <div class="col-item flex">
        <div class="ptg-select-plan"> 
          <ptg-select
          (changeOptionValue)="onchangePlan()"
          [controlField]="editForm.get('plan')"
          [isMultipleLineOption]="true"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [listData]="listPlans"
          class="full-width"
          placeholder="Plan"
          >
          </ptg-select>
          <mat-error *ngIf="editForm.get('plan')?.touched && editForm.get('plan')?.errors" class="icon-name-status">
            Plan is required.
          </mat-error>
        </div>

        <div class="list-plan flex">
          <button
            mat-raised-button
            type="button"
            class="add-button"
            (click)="addPlan()"
            [class.disabled]= "!editForm.value['plan']"
          >
            <mat-icon>add</mat-icon>
          </button>
          <div class="view-current-filter">
            <div
              *ngFor="let item of currentPlans; let index = index"
              class="chip-item-container"
            >
              <div class="chip-item">
                <span
                  >{{ item.displayValue }} ({{ item.valueDescription }})</span
                >
              </div>
              <button
                (click)="removePlan(item, index)"
                class="remove-button"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <h3>Conditions</h3>
      <div class="list-conditions">
        <ng-container formArrayName="conditions">
          <ng-container
            *ngFor="let conditionForm of conditions.controls; let i = index"
          >
            <div class="list-conditions-info flex">
              <div class="col-condition flex" [formGroupName]="i" [class.show-remove]="conditions.controls.length > 1">
                <ptg-select
                  (changeOptionValue)="onChangeMetadata(conditionForm, i)"
                  [controlField]="conditionForm.get('propertyKey')"
                  [isMultipleLineOption]="true"
                  [isOptionObj]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="conditionForm.value['listMetadata']"
                  class="full-width"
                  placeholder="Metadata Property"
                >
                </ptg-select>
                <ptg-select
                  (changeOptionValue)="onChangeOperator(conditionForm)"
                  [controlField]="conditionForm.get('operatorType')"
                  [isMultipleLineOption]="true"
                  [isOptionObj]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="conditionForm.value['listOperator']"
                  class="full-width"
                  placeholder="Operator"
                >
                </ptg-select>
  
                <ng-container
                  *ngIf="
                    conditionForm.value['operatorType'] !== '' &&
                    conditionForm.value['operatorType'] ===
                      TierConditionOperatorType.IsBetween
                  "
                >
                  <div class="block-between">
                    <ng-container
                      [ngSwitch]="conditionForm.value['propertyType']"
                    >
                      <ng-container *ngSwitchCase="PropertyType.Whole_Number">
                        <ptg-textbox
                          placeholder="Value From"
                          [controlField]="conditionForm.get('firstValue')"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="false"
                          [isPositive]="false"
                          [allowZero]="true"
                          suffix=""
                          [checkMinMaxValue]="false"
                          [isFocusOut]="true"
                          (onBlur)="onChangeFirstValue(conditionForm)"
                        ></ptg-textbox>
                        <ptg-textbox
                          placeholder="Value To"
                          [controlField]="conditionForm.get('secondValue')"
                          [hasLabel]="true"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="false"
                          [isPositive]="false"
                          [allowZero]="true"
                          suffix=""
                          [checkMinMaxValue]="false"
                          [customError]="'fromToError'"
                        ></ptg-textbox>
                      </ng-container>
                      <ng-container *ngSwitchCase="PropertyType.Currency">
                        <ptg-textbox
                          [controlField]="conditionForm.get('firstValue')"
                          [hasLabel]="true"
                          placeholder="Value From"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="true"
                          [isPositive]="false"
                          [allowZero]="true"
                          suffix="$"
                          [checkMinMaxValue]="false"
                          [isRequired]="true"
                          (onBlur)="onChangeFirstValue(conditionForm)"
                        ></ptg-textbox>
                        <ptg-textbox
                          [controlField]="conditionForm.get('secondValue')"
                          [hasLabel]="true"
                          [isDecimal]="true"
                          placeholder="Value To"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isPositive]="false"
                          [allowZero]="true"
                          suffix="$"
                          [checkMinMaxValue]="false"
                          [customError]="'fromToError'"
                        ></ptg-textbox>
                      </ng-container>
                      <ng-container *ngSwitchCase="PropertyType.Decimal">
                        <ptg-textbox
                          placeholder="Value From "
                          [controlField]="conditionForm.get('firstValue')"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="true"
                          [isPositive]="false"
                          [allowZero]="true"
                          suffix=""
                          [checkMinMaxValue]="false"
                          [isFocusOut]="true"
                          (onBlur)="onChangeFirstValue(conditionForm)"
                        ></ptg-textbox>
                        <ptg-textbox
                          placeholder="Value To"
                          [controlField]="conditionForm.get('secondValue')"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="true"
                          [isPositive]="false"
                          [allowZero]="true"
                          suffix=""
                          [checkMinMaxValue]="false"
                          [customError]="'fromToError'"
                        ></ptg-textbox>
                      </ng-container>
                      <ng-container *ngSwitchCase="PropertyType.Percentage">
                        <ptg-textbox
                          placeholder="Value From"
                          [controlField]="conditionForm.get('firstValue')"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="true"
                          [isPositive]="true"
                          [allowZero]="true"
                          suffix="%"
                          [checkMinMaxValue]="false"
                          [isFocusOut]="true"
                          (onBlur)="onChangeFirstValue(conditionForm)"
                        ></ptg-textbox>
                        <ptg-textbox
                          placeholder="Value To"
                          [controlField]="conditionForm.get('secondValue')"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_NUMBER"
                          [isDecimal]="true"
                          [isPositive]="true"
                          [allowZero]="true"
                          suffix="%"
                          [checkMinMaxValue]="false"
                          [customError]="'fromToError'"
                        ></ptg-textbox>
                      </ng-container>
                      <ng-container *ngSwitchCase="PropertyType.Date">
                        <ptg-datepicker
                          [controlField]="conditionForm.get('firstValue')"
                          placeholder="Value From"
                          [isRequired]="true"
                          errorMinDate="Value From must be future date."
                          [asyncFn]="asyncFn"
                          errorAsync="Value From existed."
                          (changeValue)="onChangeFirstValue(conditionForm)"
                        ></ptg-datepicker>
                        <ptg-datepicker
                          [controlField]="conditionForm.get('secondValue')"
                          placeholder="Value To"
                          [isRequired]="true"
                          errorMinDate="Value To must be future date."
                          [asyncFn]="asyncFn"
                          errorAsync="Value To existed."
                          [customError]="'fromToError'"
                        ></ptg-datepicker>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    conditionForm.value['operatorType'] !== '' &&
                    conditionForm.value['operatorType'] !==
                      TierConditionOperatorType.IsBetween &&
                    conditionForm.value['operatorType'] !==
                      TierConditionOperatorType.IsIn &&
                    conditionForm.value['operatorType'] !==
                      TierConditionOperatorType.IsNotIn
                  "
                >
                  <ng-container [ngSwitch]="conditionForm.value['propertyType']">
                    <ptg-textbox
                      *ngSwitchCase="PropertyType.Whole_Number"
                      placeholder="Value"
                      [controlField]="conditionForm.get('firstValue')"
                      [hasLabel]="true"
                      [inputType]="InputType.TYPE_NUMBER"
                      [isDecimal]="false"
                      [isPositive]="false"
                      [allowZero]="true"
                      suffix=""
                      [checkMinMaxValue]="false"
                    ></ptg-textbox>
  
                    <ptg-textbox
                      *ngSwitchCase="PropertyType.Currency"
                      placeholder="Value"
                      [controlField]="conditionForm.get('firstValue')"
                      [hasLabel]="true"
                      [inputType]="InputType.TYPE_NUMBER"
                      [isDecimal]="true"
                      [isPositive]="false"
                      [allowZero]="true"
                      suffix="$"
                      [checkMinMaxValue]="false"
                    ></ptg-textbox>
  
                    <ptg-textbox
                      *ngSwitchCase="PropertyType.Decimal"
                      placeholder="Value"
                      [controlField]="conditionForm.get('firstValue')"
                      [hasLabel]="true"
                      [inputType]="InputType.TYPE_NUMBER"
                      [isDecimal]="true"
                      [isPositive]="false"
                      [allowZero]="true"
                      suffix=""
                      [checkMinMaxValue]="false"
                    ></ptg-textbox>
  
                    <ptg-datepicker
                      *ngSwitchCase="PropertyType.Date"
                      [controlField]="conditionForm.get('firstValue')"
                      placeholder="Value"
                      [isRequired]="true"
                      errorMinDate="Value must be future date."
                      [asyncFn]="asyncFn"
                      errorAsync="Value existed."
                    ></ptg-datepicker>
  
                    <ptg-select
                    *ngSwitchCase="PropertyType.Binary"
                      [controlField]="conditionForm.get('firstValue')"
                      [isMultipleLineOption]="true"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [listData]="conditionForm.value['listBinary']"
                      class="full-width"
                      placeholder="Value"
                    ></ptg-select>
  
                    <ptg-select
                      *ngSwitchCase="PropertyType.Lookup"
                      [controlField]="conditionForm.get('firstValue')"
                      [isMultipleLineOption]="true"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [listData]="conditionForm.value['listLookup']"
                      class="full-width"
                      placeholder="Value"
                    ></ptg-select>
                    <ptg-textbox
                      *ngSwitchCase="PropertyType.Percentage"
                      placeholder="Value"
                      [controlField]="conditionForm.get('firstValue')"
                      [hasLabel]="true"
                      [inputType]="InputType.TYPE_NUMBER"
                      [isDecimal]="true"
                      [isPositive]="true"
                      [allowZero]="true"
                      suffix="%"
                      [checkMinMaxValue]="false"
                    ></ptg-textbox>
  
                    <ptg-textbox
                      *ngSwitchCase="PropertyType.Text"
                      placeholder="Value"
                      [controlField]="conditionForm.get('firstValue')"
                      [hasLabel]="true"
                    ></ptg-textbox>
  
                    <ptg-select
                      *ngSwitchCase="PropertyType.Department"
                      placeholder="Value"
                      [controlField]="conditionForm.get('firstValue')"
                      [isOptionObj]="true"
                      [listData]="conditionForm.value['listDepartment']"
                      [isRequired]="true"
                      [isMultipleLineOption]="true"
                      [isSetMaxWidthSelect]="true"
                    ></ptg-select>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="
                    conditionForm.value['operatorType'] === '' ||
                    conditionForm.value['operatorType'] ===
                      TierConditionOperatorType.IsIn ||
                    conditionForm.value['operatorType'] ===
                      TierConditionOperatorType.IsNotIn
                  "
                >
                  <ptg-textbox
                    placeholder="Value"
                    [formatValue]="true"
                    [controlField]="conditionForm.get('firstValue')"
                    [hasLabel]="true"
                    errorAsync="Invalid format."
                    (onBlur)="onValidator(conditionForm)"
                  ></ptg-textbox>
                </ng-container>
              </div>
              <ptg-button
              *ngIf="conditions.controls.length > 1"
              (clickButton)="deleteCondition(conditionForm, i)"
              buttonName=""
              class="clear-style-btn"
              classInput="delete-button"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
            </div>

          </ng-container>
        </ng-container>
      </div>
      <button
        mat-raised-button
        type="button"
        [hidden]="true"
        class="add-condition"
        (click)="addCondition()"
        [class.hidden-button]="conditions.invalid"
      >
        <mat-icon>add</mat-icon>
        New Condition
      </button>
    </div>
  </form>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
    </div>
  </div>
</div>


