import { createAction, props } from '@ngrx/store';

import {
  GetNonListPropertiesResponse,
  GetNonListItemConfigsResponse,
  SaveNonListItemConfigsRequest,
} from '../types/models';

export const GetNonListPropertiesRequestAction =
  '[GetNonListProperties/API] Send Request';
export const GetNonListPropertiesSuccessAction =
  '[GetNonListProperties/API] Success';
export const GetNonListPropertiesFailureAction =
  '[GetNonListProperties/API] Failure';
export const ClearGetNonListPropertiesStateAction =
  '[GetNonListProperties] Clear';

export const GetNonListItemConfigsRequestAction =
  '[GetNonListItemConfigs/API] Send Request';
export const GetNonListItemConfigsSuccessAction =
  '[GetNonListItemConfigs/API] Success';
export const GetNonListItemConfigsFailureAction =
  '[GetNonListItemConfigs/API] Failure';

export const SaveNonListItemConfigsRequestAction =
  '[SaveNonListItemConfigs/API] Send Request';
export const SaveNonListItemConfigsSuccessAction =
  '[SaveNonListItemConfigs/API] Success';
export const SaveNonListItemConfigsFailureAction =
  '[SaveNonListItemConfigs/API] Failure';
export const ClearNonListItemConfigsAction =
  '[SaveNonListItemConfigs/API] Clear';

export const getNonListProperties = createAction(
  GetNonListPropertiesRequestAction,
  props<{ sectionKey: string }>()
);
export const getNonListPropertiesSuccess = createAction(
  GetNonListPropertiesSuccessAction,
  props<{ response: GetNonListPropertiesResponse }>()
);
export const getNonListPropertiesFailure = createAction(
  GetNonListPropertiesFailureAction,
  props<{ error?: any }>()
);
export const clearGetNonListPropertiesState = createAction(
  ClearGetNonListPropertiesStateAction
);

export const getNonListItemConfigs = createAction(
  GetNonListItemConfigsRequestAction,
  props<{ navigationItemId: string }>()
);
export const getNonListItemConfigsSuccess = createAction(
  GetNonListItemConfigsSuccessAction,
  props<{ response: GetNonListItemConfigsResponse }>()
);
export const getNonListItemConfigsFailure = createAction(
  GetNonListItemConfigsFailureAction,
  props<{ error?: any }>()
);
export const clearGetNonListItemConfigsStateAction = createAction(
  '[GetNonListItemConfigs] Clear'
);

export const saveNonListItemConfigs = createAction(
  SaveNonListItemConfigsRequestAction,
  props<{ request: SaveNonListItemConfigsRequest }>()
);
export const saveNonListItemConfigsSuccess = createAction(
  SaveNonListItemConfigsSuccessAction
);
export const saveNonListItemConfigsFailure = createAction(
  SaveNonListItemConfigsFailureAction,
  props<{ error?: any }>()
);
export const clearSaveNonListItemConfigs = createAction(
  ClearNonListItemConfigsAction
);
