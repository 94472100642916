import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import {
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
} from '../types/models';
import * as ParticipantSearchConfigurationAction from '../actions/participant-search-configuration.actions';

export const participantSearchConfigurationFeatureKey =
  'participantSearchConfiguration';

export interface State {
  isLoading: boolean;
  propertyConfigs: PropertyDisplayConfig[];
  propertyDisplayConfigurations: PropertyDisplayConfiguration[];
  updateState: string;
}

const initialState: State = {
  isLoading: true,
  propertyConfigs: [],
  propertyDisplayConfigurations: [],
  updateState: '',
};

export const reducer = createReducer(
  initialState,
  on(
    ParticipantSearchConfigurationAction.clearParticipantSearchConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchPropertyListSuccess,
    (state, { propertyConfigs }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchConfigurationSuccess,
    (state, { propertyDisplayConfigurations }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
    })
  ),
  on(
    ParticipantSearchConfigurationAction.setParticipantSearchConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.setParticipantSearchConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
      updateState: STATE.FAIL,
    })
  )
);
