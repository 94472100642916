import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { Column } from '@ptg-shared/controls/grid';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { FileService } from '@ptg-shared/services/file-service';
import { PageEvent } from '@ptg-shared/controls/pagination';
import { BaseComponent } from '@ptg-shared/components';

import { SectionLayout } from '../../constance/metadata.const';
import { MemberNavigationResponse, MetadataSection } from '../../types/models';
import * as fromMember from '../../reducers';
import { MemberSectionAttachmentComponent } from '../member-section-attachment/member-section-attachment.component';

export interface AttachmentData {
  memberId: string;
  itemKey: string;
  configSection: MetadataSection;
  rowId: string;
  menuName: string;
}
@Component({
  selector: 'ptg-manage-member-attachment',
  templateUrl: './manage-member-attachment.component.html',
  styleUrls: ['./manage-member-attachment.component.scss'],
})
export class ManageMemberAttachmentComponent
  extends BaseComponent
  implements OnInit
{
  sectionKey?: string = '';
  sectionId?: string = '';
  menuName?: string = '';
  sectionType?: SectionLayout;
  columns: Column[] = [
    {
      name: 'dateCreated',
      width: '205px',
    },
    {
      name: 'attachment',
      truncate: true,
    },
  ];

  @Input() memberId!: string;
  @Input() menuId!: string;
  @Input() rowId!: string;
  @Input() dataTable!: any[];
  @Input() isLoading: boolean = true;
  @Input() lengthPg!: number | any;
  @Input() pageSize!: number;
  @Input() pageNumber!: number;
  @Input() metadataSection?: MetadataSection;

  @Output() pageChange = new EventEmitter<PageEvent>();

  constructor(
    public dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
    private signatoryService: SignatoryService,
    private fileService: FileService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getMemberNavigationListState();
  }

  getMemberNavigationListState() {
    this.memberStore
      .pipe(
        select(fromLayoutReducer.getMemberNavigationListSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        const memberNavigationMenu: MemberNavigationResponse[] =
          state?.payload?.memberNavigationList?.memberNavigationMenu ?? [];
        const menuItems = memberNavigationMenu
          .map((item) => {
            return item.menuItem;
          })
          .reduce((a, b) => {
            return a.concat(b);
          }, []);

        const menu = menuItems.find((item) => item.id === this.menuId);
        if (menu) {
          this.sectionKey = menu.itemKey;
          this.sectionId = menu.id;
          this.menuName = menu.name;
          this.sectionType = menu.type;
        }
      });
  }

  openListAttachments() {
    this.dialog.open(MemberSectionAttachmentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        configSection: this.metadataSection,
        breadcrumbs: [
          {
            name: this.menuName,
            url: `/member/navigation/${this.sectionType}/${this.sectionId}/${this.memberId}`,
          },
          {
            name: 'Attachments',
          },
        ],
        isNavigationPage: true,
        rowId: this.rowId,
        menuName: this.menuName,
      },
    });
  }

  onChangePage(event: PageEvent) {
    this.pageChange.emit(event);
  }

  openPDF(document: any) {
    const pdfUrl = this.signatoryService.getUrl(document, (fileKey) => {
      this.fileService.signFile(fileKey).subscribe((sig) => {
        this.openPDF({
          ...document,
          signature: sig,
        });
      });
    });

    if (pdfUrl) window.open(pdfUrl, '_blank');
  }
}
