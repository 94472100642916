import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { ParametersQuery } from '@ptg-employer/models';
import { ParticipantReportAction } from '@ptg-member/actions';
import { ParticipantReport, ReportCategoryName } from '@ptg-member/types/models/participant-report.model';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { uploadFile } from '@ptg-shared/constance/listIcons.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Column, ColumnType, GridComponent } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb, FunctionButtonConfig } from '@ptg-shared/models/breadcrumb.model';
import { FileService } from '@ptg-shared/services/file-service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import * as fileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import * as fromMember from '../../../reducers';
import { IEditReportConfig, ReportConfigComponent } from '../report-config/report-config.component';
const PAGE_SIZE_CONST = '-ptg-report-pageSize';

@Component({
    selector: 'ptg-manage-report',
    templateUrl: './manage-report.component.html',
    styleUrls: ['./manage-report.component.scss']
})
export class ManageReportComponent extends BaseComponent {
  @ViewChild('gridManageReport') gridManageReport?: GridComponent<ParticipantReport>;
  
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly FILENAME_COLUMN = 'fileName';
    listBreadcrumbs: Breadcrumb[] = [
        {
            name: 'Report',
            url: '/member/report/generated'
        },
        {
            name: 'Manage Reports',
            url: ''
        }
    ];
    functionButtons: FunctionButtonConfig[] = [
        {
            buttonName: 'New Report',
            icon: 'upload-icon',
            isSvgIcon: true,
            classInput: 'bulk-upload-icon'
        },
    ];

  columns: Column[] = [
    {
      name: 'categoryName',
      header: {
        title: 'Category',
        style: {
          'padding-left': '72px',
        },
      },
      sortable: true,
      truncate: true,
      style: {
        'padding-left': '72px',
      },
    },
    {
      name: 'reportName',
      header: {
        title: 'Report Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'postedDate',
      header: {
        title: 'Posted Date',
      },
      sortable: true,
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header:{
        title: 'Action'
      },
    }
  ];

  errorMsg?: string = '';
  isLoading: boolean = true;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  totalRecords: number | any;
  sortInfo: {} | any = {};
  currentFund: any = {};
  participantReport: ParticipantReport[] = [];
  notFoundMessage: string = 'No report to display.';
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  reportCategoryName: ReportCategoryName[] = [];
  editedReportName = '';

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private signatoryService: SignatoryService,
    private fileService: FileService,
  ) {
    super();
    iconRegistry.addSvgIconLiteral('upload-icon', sanitizer.bypassSecurityTrustHtml(uploadFile));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.memberStore.dispatch(ParticipantReportAction.clearStateParticipantReport());
    this.getData();

    this.memberStore.pipe(
      select(fromMember.selectParticipantReportCategoryName),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.reportCategoryName = state.response.reportCategoryNames;
    })

    this.memberStore.pipe(
      select(fromMember.selectParticipantReportManageReport),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.participantReport = state.response.reports;
      if(state.isLoading !== true && state.isSuccess === true) {
        this.participantReport = state.response.reports.map(obj => {
          return {
            ...obj, 
            postedDate: this.formatDate(obj.postedDate)
          };
        })
      }
      this.totalRecords = state.response.total;
      this.isLoading = state.isLoading;
    })

    this.memberStore.pipe(
      select(fromMember.selectEditReport),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.isLoading = true;

      if (state.action && !state.isLoading) {        
        if(state.isSuccess === true)
          this.getData(); 
        
        const options = { 
          customMessage: 
            state.isSuccess === true 
            ? `Report ${this.editedReportName} is successfully updated.` 
            : `Error occurred updating Report. Please try again.` 
        };
        const updateState = state.isSuccess === true ? 'Success': 'Fail';

        showBanner.call(this, updateState, '', state.action, options);
      }
    })

    this.memberStore.pipe(
      select(fromMember.selectDeleteReport),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.isLoading = true;

      if (state.action && !state.isLoading) {  
        if (this.participantReport.length === 1)
          this.gridManageReport?.paginator?.jumpToFirst();
        
        if(state.isSuccess === true)
          this.getData();      

        const options = { 
          customMessage: 
            state.isSuccess === true 
            ? `Report successfully removed.` 
            : `Error occurred removing Report. Please try again.` 
        };
        const updateState = state.isSuccess === true ? 'Success': 'Fail';

        showBanner.call(this, updateState, '', state.action, options);
      }
    })

    this.memberStore.pipe(
      select(fromMember.selectCreateReport),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.isLoading = true;

      if (state.action && !state.isLoading) {  
        if(state.isSuccess === true)
          this.getData();      

        const options = { 
          customMessage: 
            state.isSuccess === true 
            ? `Report successfully added.` 
            : `Error occurred adding Report. Please try again.` 
        };
        const updateState = state.isSuccess === true ? 'Success': 'Fail';

        showBanner.call(this, updateState, '', state.action, options);
      }
    })
    this.bannerType = BannerType.Hidden;
  }

  formatDate(input: Date) : Date  {
    return new Date(new Date(input.toString().replace('T', ' ') + ' UTC').toISOString())
  }

  getData() {
    this.isLoading = true;
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    let sortType = 1;
    let sortNames = [
      'PostedDate'
    ];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: ParametersQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType
    };
    this.memberStore.dispatch(ParticipantReportAction.getManageReportRequest({ query:query }));
    this.memberStore.dispatch(ParticipantReportAction.getReportCategoryName());
    this.store.dispatch(LayoutActions.getReportCategorySidebar());
  }

  onChangeSort(event: any) {
    this.sortInfo = event;
    this.isLoading = true;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }

  onRemoveClick(row:any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Warning,
        title: 'Confirmation',
        cancelButtonTitle: 'No',
        text: `Are you sure you want to remove this report ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeReport(row?.reportId);
      }
    });
  }

  removeReport(reportId: string) {
    this.memberStore.dispatch(ParticipantReportAction.deleteReport({ reportId: reportId }));
  }

  onClickEditItem(row: any) {
    const dialogRef = this.dialog.open(ReportConfigComponent, {
      panelClass: 'confirm-popup',
      disableClose: true,
      data: {
        isEditForm: true,
        reportCategoryName: this.reportCategoryName.map(({ id, name }) => ({ value:id, displayValue:name })),
        participantReport: row as ParticipantReport
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.editedReportName = result.ReportName;
        this.memberStore.dispatch(ParticipantReportAction.editReportConfig({ reportId: row?.reportId, body: result as IEditReportConfig}));
      }
    });
  }

  addReportConfig(row: any) {
    const dialogRef = this.dialog.open(ReportConfigComponent, {
      panelClass: 'confirm-popup',
      disableClose: true,
      data: {
        reportCategoryName: this.reportCategoryName.map(({ id, name }) => ({ value:id, displayValue:name })),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(ParticipantReportAction.createReportConfig({ body:result}))
      }
    });
  }

  onDownload(document: any): void {
    let fileUrl = this.signatoryService.getUrl(document, fileKey => {
      this.fileService.signFile(fileKey).subscribe(sig => {
        this.onDownload({
          ...document,
          signature: sig
        });
      });
    });

    if (fileUrl) {
      fileSaver.saveAs(fileUrl, document.fileName);
    }
  }

  ngOnDestroy(): void {
    this.bannerType = BannerType.Hidden;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
