<div
  class="list-display"
  [ngClass]="{
    noDetail: !(paymentHistoriesData && paymentHistoriesData.length > 0),
    'no-pending-payment-data': !isHavePendingPayment
  }">
  <div class="header">
    <span class="title">Payment History</span>
    <ng-container *ptgCheckPermission = "['processing','lump_sum_payments']">
      <div
      *ngIf="
        !isHavePendingPayment &&
        paymentInforConfig.PENDINGPAYMENTS
      "
      class="btn-add"
      (click)="newOffCycle()"
    >
      <mat-icon>add</mat-icon>
      <span class="btn-title">New Off-Cycle</span>
    </div>
    </ng-container>
  </div>

  <div class="grid-wrapper">
    <ptg-grid
      [data]="paymentHistoriesData"
      [columns]="currentColumns"
      [isLoading]="isLoading"
      [totalRecords]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      (pageChange)="changePagging($event)"
      (sortChange)="sortChange($event)"
      (rowClick)="selectRow($event)"
      [(currentRowIndex)]="currentRowIndex"
      [fitToParent]="true"
      [errorMessage]="errorMsg"
      notFoundMessage="No History to Display"
    >
      <ng-template cellContent columnName="status" let-row>
        <div
          class="icon-status-container"
          #tooltip="matTooltip"
          matTooltip="{{ row?.tooltipPayStatus }}"
          matTooltipClass="custom-tooltip {{ row?.className }}"
          matTooltipPosition="below">
          <mat-icon
            *ngIf="row?.iconName"
            class="icon-status"
            [ngStyle]="{ color: row?.color }"
            >{{ row?.iconName }}
          </mat-icon>
        </div>
      </ng-template>
      <ng-template cellContent columnName="note" let-row>
        <div>
          <mat-icon
            *ngIf="row.note"
            svgIcon="note-icon"
            class="note-icon"
          ></mat-icon>
        </div>
      </ng-template>
      <ng-template cellContent columnName="type" let-row>
        {{ row?.type === PaymentInfoType.Recurring ? 'Recurring': (row?.type === PaymentInfoType.Reissue ? 'Reissue' : 'Off-Cycle')}}
      </ng-template>
    </ptg-grid>
  </div>
</div>
