<div class="member-detail" #memberDetail>
  <ptg-breadcrumb
    class="ptg-breadcrumb"
    [listBreadcrumbs]="listBreadcrumbs"
    [settings]="settings"
    [functionButtons]="functionButtons"
    (onClickBreadcrumb)="backToMemberList($event)"
    (emitFunction)="emitFunction($event)"
  ></ptg-breadcrumb>
  <ptg-overview-header
    [overviewData]="overviewData"
    [identityKey]="identityKey"
    [isLocked]="isLocked"
    [isProfileHeader]="false"
    [canRemove]="detailInfo?.memberInfo?.canRemove"
    [memberNavigationState]="memberNavigationState"
    [actionItems]="actionItems"
    (removeMemberEvent)="removeMember()"
    (lockAccountEvent)="lockAccount($event)"
    (editMemberStatusEvent)="editMemberStatusEvent()"
    (clickActionItem)="onClickActionItem($event)"
  ></ptg-overview-header>

  <div class="member-detail-container flex flex-col p-6 gap-6">
    <div
      class="banner"
      *ngIf="
        isLocked &&
        identityKey &&
        !detailInfo.isLoading &&
        !detailInfo.isDeleting &&
        detailInfo.memberInfo
      "
    >
      <ng-container>
        <mat-icon style="color: #a8a8a8">lock</mat-icon>
        <span style="vertical-align: super">This Account is Locked.</span>
      </ng-container>
    </div>

    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div
      *ngIf="
        !detailInfo.isLoading &&
        !detailInfo.isDeleting &&
        detailInfo.memberDetail
      "
      class="content-detail"
    >
      <div class="cell-detail" *ngFor="let direction of ['left', 'right']">
        <ng-container *ngFor="let section of listSection">
          <ptg-member-profile-section
            *ngIf="
              section.isDisplay &&
              ((direction === 'left' && section.onSide === SideType.Left) ||
                (direction === 'right' && section.onSide === SideType.Right))
            "
            [configSection]="section"
            [profileOverviewConfig]="detailInfo?.profileOverviewConfig"
            [memberId]="memberId"
            [memberStatus]="memberStatus"
            (deleteSectionEvent)="deleteSection($event)"
            (editSectionEvent)="editSection($event)"
            (downloadPdfEvent)="downloadPdf($event)"
            (showAttachmentsEvent)="showAttachments($event)"
          ></ptg-member-profile-section>
        </ng-container>
      </div>
    </div>

    <div id="loading-table" *ngIf="detailInfo.isLoading">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>

    <div id="loading-table" *ngIf="detailInfo.isDeleting">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Deleting participant...</p>
      </div>
    </div>
    <div
      *ngIf="isNoData"
      class="data-not-found"
      [ngClass]="{ 'not-registered': isLocked || !identityKey }"
    >
      <div class="not-configured">
        <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
        <span class="message">
          {{ profileOverviewName }} not yet configured
        </span>
      </div>
    </div>
  </div>
</div>
