import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, ParticipantPropertyType, STATE } from '@ptg-shared/constance/value.const';

import * as PaymentListConfigurationActions from '../../actions/payment-list-configuration.actions';
import {
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
  SectionConfig,
} from '../../types/models';
import * as fromMember from '../../reducers';
import * as NextPaymentActions from '../../actions/next-payment.actions';
import { PaymentInforConfigCode } from '../../constance/next-payment.const';

@Component({
  selector: 'ptg-payment-list-configuration',
  templateUrl: './payment-list-configuration.component.html',
  styleUrls: ['./payment-list-configuration.component.scss'],
})
export class PaymentListConfigurationComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
    },
    {
      name: 'Payment List Configuration',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  propertyDisplayConfigurations: PropertyDisplayConfiguration[] = [];
  propertyConfigs: PropertyDisplayConfig[] = [
    {
      propertyName: 'Deposit Date',
      propertyKey: 'depositDate',
      sectionKey: 'depositDate',
      sectionName: 'Deposit Date',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
    {
      propertyName: 'Gross',
      propertyKey: 'gross',
      sectionKey: 'grossAmount',
      sectionName: 'Gross Amount',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
    {
      propertyName: 'Net Payment',
      propertyKey: 'netPayment',
      sectionKey: 'netAmount',
      sectionName: 'Net Amount',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
    {
      propertyName: 'Note',
      propertyKey: 'note',
      sectionKey: 'note',
      sectionName: 'Note',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
    {
      propertyName: 'Payee',
      propertyKey: 'payee',
      sectionKey: 'payeeName',
      sectionName: 'Payee Name',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
    {
      propertyName: 'Type',
      propertyKey: 'type',
      sectionKey: 'paymentType',
      sectionName: 'Payment Type',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
    {
      propertyName: 'Deds (Total)',
      propertyKey: 'dedsTotal',
      sectionKey: 'totalDeductionAmount',
      sectionName: 'Total Deduction Amount',
      options: [],
      headerType: ParticipantPropertyType.ParticipantMetadata
    },
  ];

  addPropertySection: SectionConfig = {
    title: 'Add Column',
    columnName: 'Column Name',
  };
  sortPropertySection: SectionConfig = { title: 'Order Columns' };
  sortRowSection: SectionConfig = { title: 'Sort Rows' };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sectionType: string;
      sectionId: string;
      memberId: string;
      menuItemName: string;
    },
    private store: Store,
    public dialogRef: MatDialogRef<PaymentListConfigurationComponent>,
    private memberStore: Store<fromMember.MemberState>,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data) {
      this.listBreadcrumbs[0] = {
        name: this.data?.menuItemName,
      };
    }

    this.store.dispatch(NextPaymentActions.getPaymentInforConfig());
    this.store
      .pipe(select(fromMember.selectPaymentInforConfigState))
      .subscribe((paymentInforConfig) => {
        if (paymentInforConfig.length > 0) {
          const isShowNote = paymentInforConfig.find(
            (x) => x.code === PaymentInforConfigCode.NOTE
          )?.isDisplay;
          if (!isShowNote) {
            this.propertyConfigs = this.propertyConfigs.filter(
              (x) => x.propertyKey !== 'note'
            );
          }
        }
      });

    this.memberStore.dispatch(
      PaymentListConfigurationActions.getPaymentListConfigurations()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectGetPaymentListConfigurationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state.isLoading && state.payload) {
          this.propertyDisplayConfigurations = JSON.parse(
            JSON.stringify(
              state?.payload.map((item: any) => {
                const property = this.propertyConfigs.find(
                  (v) => v.propertyKey === item.propertyKey
                );
                return {
                  id: item.id,
                  propertyKey: property?.propertyKey,
                  propertyName: property?.propertyName,
                  sectionKey: property?.sectionKey,
                  sectionName: property?.sectionName,
                  columnName: item.columnName,
                  orderColumn: item.orderColumn,
                  orderRow: item.orderRow,
                  sortType: item.sortType,
                } as PropertyDisplayConfiguration;
              })
            )
          ) as PropertyDisplayConfiguration[];
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectSetPaymentListConfigurationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          if (!state.isLoading) {
            if (state.success) {
              this.bannerType = BannerType.Success;
              this.memberStore.dispatch(
                PaymentListConfigurationActions.getPaymentListConfigurations()
              );
              this.memberStore.dispatch(
                PaymentListConfigurationActions.clearPaymentListConfigurationState()
              );
              showBanner.call(this, STATE.SUCCESS, 'Payment List', ACTION.EDIT);
            }
            if (state.error) {
              this.bannerType = BannerType.Fail;
              showBanner.call(this, STATE.FAIL, 'Payment List', ACTION.EDIT);
            }
          }
        }
      });
  }

  onSubmit(paymentListConfigurations: PropertyDisplayConfiguration[]) {
    this.memberStore.dispatch(
      PaymentListConfigurationActions.setPaymentListConfigurations({
        body: {
          paymentListConfigurations,
        },
      })
    );
  }

  onClickBreadcrumb() {
    this.router.navigateByUrl(this.router.url);
    this.dialogRef.close();
  }
}
