import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import {
  ACTION_COLUMN,
  Column,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';

import { MetadataSectionService } from '../../services/metadata-detail.service';
import { AddAggregationComponent } from '../../components/add-aggregation/add-aggregation.component';
import { AggregationConfigService } from '../../services/aggregation-config.service';
import * as MetadataSectionActions from '../../actions/metadata-detail.actions';
import * as fromMember from '../../reducers';
import * as AggregationConfigActions from '../../actions/aggregation-config.actions';
import { AggregationItem, MetadataProperty } from '../../types/models';

@Component({
  selector: 'ptg-aggregation-configuration',
  templateUrl: './aggregation-configuration.component.html',
  styleUrls: ['./aggregation-configuration.component.scss'],
})
export class AggregationConfigurationComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Participant Metadata',
      url: '/member/member-metadata',
    },
    {
      name: '',
      url: '/member/member-metadata/XXX',
    },
    {
      name: 'Aggregation Configuration',
      preventTruncate: true,
    },
  ];
  sectionkey = '';
  message = '';
  bannerType: BannerType = BannerType.Hidden;
  columns: Column[] = [
    {
      name: 'label',
      header: {
        title: 'Label',
      },
      truncate: true,
    },
    {
      name: 'property',
      header: {
        title: 'Property',
      },
      truncate: true,
    },
    {
      name: 'aggregation',
      header: {
        title: 'Aggregate',
      },
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Actions',
      },
      width: '230px',
    },
  ];
  aggregationsData: (AggregationItem & Row)[] = [];
  properties: MetadataProperty[] = [];
  errorMsg?: string;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store,
    private metadataSectionService: MetadataSectionService,
    private aggregationConfigService: AggregationConfigService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );

    this.route.params.subscribe((param) => {
      this.metadataSectionService.sectionKey = param.key;
      this.aggregationConfigService.sectionKey = param.key;
      this.sectionkey = param.key;
      this.getAggregationData();
    });
    this.store
      .pipe(
        select(fromMember.selectMetadataSectionState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el?.metadataSection?.key) {
          this.listBreadcrumbs[2] = {
            name: el?.metadataSection?.name,
            url: `/member/member-metadata/${el?.metadataSection?.key}`,
            canTruncate: true,
          };
          this.properties = el.metadataSection.properties;
        }
      });
    this.store
      .pipe(
        select(fromMember.selectAggregationConfigState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.aggregationsData = state.aggregationList.items;
        this.isLoading = state.isLoading;

        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }

        if (
          state.removeState === STATE.SUCCESS ||
          state.editState === STATE.SUCCESS
        ) {
          if (state.editState === STATE.SUCCESS) {
            showBanner.call(
              this,
              BannerType.Success,
              'Aggregation',
              ACTION.EDIT
            );
          } else if (state.removeState === STATE.SUCCESS) {
            showBanner.call(
              this,
              BannerType.Success,
              'Aggregation',
              ACTION.REMOVE
            );
          }
          this.store.dispatch(AggregationConfigActions.aggregationClear());
          this.getAggregationData();
        }

        if (
          state.removeState === STATE.FAIL ||
          state.editState === STATE.FAIL
        ) {
          if (state.editState === STATE.FAIL) {
            showBanner.call(this, BannerType.Fail, 'Aggregation', ACTION.EDIT);
          } else if (state.removeState === STATE.FAIL) {
            showBanner.call(
              this,
              BannerType.Fail,
              'Aggregation',
              ACTION.REMOVE
            );
          }
          this.store.dispatch(AggregationConfigActions.aggregationClear());
        }
      });
  }

  getAggregationData() {
    this.store.dispatch(AggregationConfigActions.getAggregationList());
    this.store.dispatch(
      MetadataSectionActions.getMetadataSection({ key: this.sectionkey })
    );
  }

  onClickBreadcrumb(url: string) {
    if (url === '/member') {
      this.store.dispatch(LayoutActions.closeSideMenu());
    }
  }

  addAgg(row?: AggregationItem & Row) {
    const dialogRef = this.dialog.open(AddAggregationComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '848px',
      data: {
        isEdit: !!row,
        properties: this.properties,
        sectionKey: this.sectionkey,
        record: row,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.getAggregationData();
      }
    });
  }

  changeItemIndex(event: ReorderInfo) {
    this.store.dispatch(
      AggregationConfigActions.orderTable({
        body: {
          reorderItemId: event.reorderItemKey,
          upperAdjacentId: event.upperAdjacentKey,
        },
      })
    );
  }

  onRemoveClick(row: AggregationItem & Row) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Removing this aggregation will also remove all filters using it.<br>Are you sure you want to remove this Aggregation?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          AggregationConfigActions.removeAggregationItem({
            aggregationId: row?.id,
          })
        );
      }
    });
  }

  navigateAggGroup() {
    this.router.navigateByUrl(
      `/member/member-metadata/${this.sectionkey}/aggregation/aggregation-group`
    );
  }
}
