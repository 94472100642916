export const SUBMODULE_URL: any = {
  EMPLOYER_LIST: '/employer/employer-management',
  PARTICIPANT_LIST: '/member',
  ESTABLISH_BENEFIT: '/member/benefit',
  PENSIONESTIMATOR: '/estimator',
  COMMUNICATIONS: 'fund-management/notification',
  BULK_CONTENT_UPDATES: '/member/bulk/demographics',
  ACCESS_MANAGEMENT: '/access-management/account',
  RESOURCE_CENTER: '/fund-management/resource-center',
  HISTORICAL_DATA: '/fund-management/historical-data',
  APP_CONTENT_MANAGEMENT: '/fund-management/navigation',
  PAS_MANAGEMENT: '/fund-management/theme',
  ORGANIZATION_MANAGEMENT: '/organization/board-management',
  PROCESSING: '/processing',
  PERIODIC_PAYMENTS: '/processing/next-payroll',
  LUMP_SUM_PAYMENTS: '/processing/off-cycle-payments',
  YEARLY_PROCESSING: '/processing/yearly-processing',
  TRANSACTION_REGISTER: 'processing/register',
  REPORTS: '/processing/report/register-summary',
  PARTICIPANT_REPORT: 'member/report',
};

export const PERMISSION_SUB_MODULE_URL: any = {
  EMPLOYER_LIST: [
    '/employer/employer-management',
    '/employer/employer-overview-configuration',
    '/employer/employer-information-properties',
    '/employer/employer-list-configuration',
    '/employer/detail/',
    '/employer/department-management/',
    '/employer/reporting-calendar/',
    '/employer/employer-file-layout-configuration/',
    '/employer/wage-processing/',
    '/employer/parameters/',
    '/employer/tolerance/parameters',
    '/employer/fee-amounts',
    '/employer/annual-certification',
    '/employer/annual-certification-history',
    '/employer/remittance-submission',
    '/employer/remittance-submission-history',
  ],
  PARTICIPANT_LIST: [
    { url: '/member', strict: true },
    '/member/navigation',
    '/member/detail/',
    '/member/opt-in',
  ],
  ESTABLISH_BENEFIT: ['/member/benefit'],
  PENSION_ESTIMATOR: ['/estimator'],
  COMMUNICATIONS: [
    '/fund-management/notification',
    '/fund-management/notification/opt-in-message',
    '/fund-management/email-messaging',
  ],
  BULK_CONTENT_UPDATES: ['/member/bulk/'],
  ACCESS_MANAGEMENT: ['/access-management/account', '/access-management/roles'],
  RESOURCE_CENTER: ['/fund-management/resource-center'],
  HISTORICAL_DATA: ['/fund-management/historical-data'],
  APP_CONTENT_MANAGEMENT: [
    '/fund-management/navigation',
    '/fund-management/preferences',
    '/page',
    '/fund-management/app-content',
  ],
  PAS_MANAGEMENT: ['/fund-management/theme'],
  ORGANIZATION_MANAGEMENT: [
    '/organization/board-management',
    '/organization/board-member',
    '/organization/banks-account',
  ],
  PERIODIC_PAYMENTS: [
    '/processing/next-payroll',
    '/processing/pension-payroll',
    '/processing/federal-taxes',
    '/processing/banking-list',
    '/processing/next-payment',
    '/processing/payment-history',
    '/processing/register',
    '/processing/service-provider',
    '/processing/payment-deductions',
    '/processing/payment-information',
  ],
  LUMP_SUM_PAYMENTS: [
    '/processing/off-cycle-payments',
    '/processing/lump-sum-payment-history',
  ],
  YEARLY_PROCESSING: [
    '/processing/yearly-processing',
    '/processing/annual1099',
  ],
  REPORTS: ['/processing/report'],
  TRANSACTION_REGISTER: ['processing/register'],
  PROCESSING: [{ url: '/processing', strict: true }],
  PARTICIPANT_REPORT: ['/member/report'],
  DROP_PLAN: ['/member/drop-plan'],
};

export const PERMISSION_KEY: Record<string, string> = {
  EMPLOYERS: 'employers',
  PARTICIPANTS: 'participants',
  PARTICIPANT_LIST: 'participant_list',
  PENSION_ESTIMATOR: 'pension_estimator',
  ESTABLISH_BENEFIT: 'establish_benefit',
  COMMUNICATIONS: 'communications',
  BULK_CONTENT_UPDATES: 'bulk_content_updates',
  PERIODIC_PAYMENTS: 'periodic_payments',
  TRANSACTION_REGISTER: 'transaction_register',
  LUMP_SUM_PAYMENTS: 'lump_sum_payments',
  YEARLY_PROCESSING: 'yearly_processing',
  REPORTS: 'reports',
  ADMIN: 'admin',
  APP_CONTENT: 'app',
  RESOURCE_CENTER: 'resource_center',
  ACCESS_CONTROL: 'access_management',
  PAS_MANAGEMENT: 'pas_management',
  ORGANIZATION_MANAGEMENT: 'organization_management',
  PENSION_APP: 'pensionapp',
  MEMBER_IMPERSONATION: 'participant_impersonation',
  PROCESSING: 'processing',
  PARTICIPANT_REPORT: 'participant_report',
  DROP_PLAN: 'drop_plan',
  HISTORICAL_DATA: 'historical_data',
};

export enum ModuleKey {
  Employers = 'employers',
  Participants = 'participants',
  Processing = 'processing',
  App = 'app',
  Admin = 'admin',
  PensionApp = 'pensionapp',
}

export enum SUBMODULE_KEY {
  EMPLOYER_LIST = 'employer_list',
  PARTICIPANT_LIST = 'participant_list',
  ESTABLISH_BENEFIT = 'establish_benefit',
  PENSION_ESTIMATOR = 'pension_estimator',
  COMMUNICATIONS = 'communications',
  BULK_CONTENT_UPDATES = 'bulk_content_updates',
  PERIODIC_PAYMENTS = 'periodic_payments',
  TRANSACTION_REGISTER = 'transaction_register',
  LUMP_SUM_PAYMENTS = 'lump_sum_payments',
  YEARLY_PROCESSING = 'yearly_processing',
  REPORTS = 'reports',
  APP_CONTENT_MANAGEMENT = 'app_content_management',
  ACCESS_MANAGEMENT = 'access_management',
  RESOURCE_CENTER = 'resource_center',
  ORGANIZATION_MANAGEMENT = 'organization_management',
  PAS_MANAGEMENT = 'pas_management',
  PARTICIPANT_IMPERSONATION = 'participant_impersonation',
  PARTICIPANT_REPORT = 'participant_report',
  DROP_PLAN = 'drop_plan',
  HISTORICAL_DATA = 'historical_data',
}

export const SUBMODULE_GROUP_BY_MODULE: Record<ModuleKey, string[]> = {
  [ModuleKey.Employers]: [SUBMODULE_KEY.EMPLOYER_LIST],
  [ModuleKey.Participants]: [
    SUBMODULE_KEY.PARTICIPANT_LIST,
    SUBMODULE_KEY.ESTABLISH_BENEFIT,
    SUBMODULE_KEY.PENSION_ESTIMATOR,
    SUBMODULE_KEY.DROP_PLAN,
    SUBMODULE_KEY.COMMUNICATIONS,
    SUBMODULE_KEY.BULK_CONTENT_UPDATES,
    SUBMODULE_KEY.PARTICIPANT_REPORT,
  ],
  [ModuleKey.Processing]: [
    ModuleKey.Processing,
    SUBMODULE_KEY.PERIODIC_PAYMENTS,
    SUBMODULE_KEY.LUMP_SUM_PAYMENTS,
    SUBMODULE_KEY.YEARLY_PROCESSING,
    SUBMODULE_KEY.REPORTS,
    SUBMODULE_KEY.TRANSACTION_REGISTER,
  ],
  [ModuleKey.App]: [SUBMODULE_KEY.APP_CONTENT_MANAGEMENT],
  [ModuleKey.Admin]: [
    SUBMODULE_KEY.ACCESS_MANAGEMENT,
    SUBMODULE_KEY.RESOURCE_CENTER,
    SUBMODULE_KEY.ORGANIZATION_MANAGEMENT,
    SUBMODULE_KEY.PAS_MANAGEMENT,
    SUBMODULE_KEY.HISTORICAL_DATA,
  ],
  [ModuleKey.PensionApp]: [SUBMODULE_KEY.PARTICIPANT_IMPERSONATION],
};
