export enum DisplaySectionWODataType {
  TitleOnly = 0,
  TitleAndMessage = 1,
  TitleAndProperties_IfAny = 2,
}

export enum SideType {
  Left = 0,
  Right = 1,
}
