import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Align, Column } from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { ACTION } from '@ptg-shared/constance/value.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';

import {
  MetadataProfileHeaderConfiguration,
  ProfileHeaderList,
  PropertyDisplayConfiguration,
  SectionMetadata,
} from '../../types/models';
import * as fromMember from '../../reducers';
import * as ProfileHeaderConfigurationActions from '../../actions/profile-header-configuration.actions';
import { clearGetMemberProfileHeadersDataStateAction } from '../../actions/profile-header-configuration.actions';

@Component({
  selector: 'ptg-profile-header-configuration-list',
  templateUrl: './profile-header-configuration-list.component.html',
  styleUrls: ['./profile-header-configuration-list.component.scss'],
})
export class ProfileHeaderConfigurationListComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
      url: '',
    },
    {
      name: 'Profile Header View',
      url: '',
    },
  ];
  propertyConfigs!: SectionMetadata[];
  propertyDisplayConfigurations!: PropertyDisplayConfiguration[];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  errorMsg: string = '';
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Header Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'totalProfileHeaderConfig',
      header: {
        title: 'Property Count',
      },
      sortable: true,
      align: Align.Center,
    },
    {
      name: 'statuses',
      header: {
        title: 'Status',
      },
      style: {
        'max-width': 'unset',
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  profileHeaderConfigurationsData: MetadataProfileHeaderConfiguration[] = [];
  isLoading: boolean = true;
  sortInfo: Sort = {} as Sort;
  memberId: string = '';
  viewName: string = '';

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.listBreadcrumbs[0].url = `/member/detail/${params.id}`;
    });
    this.getData();
    this.memberStore
      .pipe(
        select(fromMember.selectProfileHeaderList),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectProfileHeaderList) => {
        this.isLoading = selectProfileHeaderList.isLoading;
        // Get an error message (if any)
        if (!selectProfileHeaderList.success && selectProfileHeaderList.error) {
          this.errorMsg = selectProfileHeaderList.error.statusText;
        }

        // Binding the result to the grid
        if (
          selectProfileHeaderList.success &&
          selectProfileHeaderList.payload
        ) {
          const statusList = (
            deepClone(selectProfileHeaderList.payload) as ProfileHeaderList
          ).statuses;
          if (
            !selectProfileHeaderList?.payload?.metadataProfileHeaders?.length
          ) {
            this.profileHeaderConfigurationsData = [];
            return;
          }
          this.profileHeaderConfigurationsData = (
            deepClone(selectProfileHeaderList.payload) as ProfileHeaderList
          ).metadataProfileHeaders.map((profileHeader) => {
            profileHeader.statuses = statusList.filter((status) =>
              profileHeader.statuses.find(
                (item) => item.id.toLowerCase() === status.id.toLowerCase()
              )
            );
            return profileHeader;
          });
        }
      });
    this.memberStore
      .pipe(
        select(fromMember.selectProfileHeaderConfigurationsDeleteState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.getData();
        showBanner.call(this, state, this.viewName, ACTION.REMOVE);
        this.memberStore.dispatch(
          ProfileHeaderConfigurationActions.clearProfileHeaderConfigurationState()
        );
        this.memberStore.dispatch(
          clearGetMemberProfileHeadersDataStateAction()
        );
      });
  }

  getData() {
    let SortType = 0;
    let SortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      SortNames = capitalizeFirstLetter(
        this.sortInfo.active === 'disabled' ? 'active' : this.sortInfo.active
      );
      SortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.getListProfileHeaderConfiguration({
        query: {
          SortNames,
          SortType,
        },
      })
    );
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  addProfileConfig() {
    void this.router.navigateByUrl(
      `/member/profile-header-configuration/${this.memberId}/detail/`
    );
  }

  removeRecord(profileHeader: MetadataProfileHeaderConfiguration) {
    this.viewName = profileHeader.name;
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Item',
        text: `Are you sure you want to remove this Header?`,
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          ProfileHeaderConfigurationActions.deleteProfileHeaderConfiguration({
            id: profileHeader.id || '',
          })
        );
      }
    });
  }

  editRecord(profileHeader: MetadataProfileHeaderConfiguration) {
    void this.router.navigateByUrl(
      `/member/profile-header-configuration/${this.memberId}/detail/${profileHeader.id}`
    );
  }
}
