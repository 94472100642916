import { createAction, props } from '@ngrx/store';

import {
  EditMemberEventBody,
  MemberAttachmentsListSectionQuery,
  MemberDetail,
  MemberDetailWithPaging,
  GetMemberAttachmentsResponse,
  MemberStatus,
  MetadataOverView,
  MetadataSection,
  ReorderSectionRequestModel,
  SectionOverviewModel,
  PayrollConfigurationInfo,
  MetadataSectionsNonListResponse as GetMetadataNonListSectionsResponse,
} from '../types/models';

export const MemberDetailRequest = '[MemberDetail/API] Send Request';
export const MemberDetailFailure = '[MemberDetail/API] Failure';
export const MemberDetailSuccess = '[MemberDetail/API] Success';
export const ClearMemberDetail = '[MemberDetail] Clear';
export const RemoveMemberRequest = '[RemoveMember/API] Send Request';
export const RemoveMemberSuccess = '[RemoveMember/API] Success';
export const RemoveMemberFailure = '[RemoveMember/API] Failure';
export const ResetStateRemoveMember = '[ResetRemoveMember] Reset';
export const LockMemberRequest = '[LockMember/API] Send Request';
export const LockMemberSuccess = '[LockMember/API] Success';
export const LockMemberFailure = '[LockMember/API] Failure';
export const UnlockMemberRequest = '[UnlockMember/API] Send Request';
export const UnlockMemberSuccess = '[UnlockMember/API] Success';
export const UnlockMemberFailure = '[UnlockMember/API] Failure';
export const RemoveMemberSectionRequest =
  '[RemoveMemberSection/API] Send Request';
export const RemoveMemberSectionSuccess = '[RemoveMemberSection/API] Success';
export const RemoveMemberSectionFailure = '[RemoveMemberSection/API] Failure';
export const AddSectionTableRequest = '[AddSectionTable/API] Send Request';
export const AddSectionTableSuccess = '[AddSectionTable/API] Success';
export const AddSectionTableFailure = '[AddSectionTable/API] Failure';
export const ResetAddSectionState = '[ResetAddSectionState] Reset';
export const GetSectionDataRequest = '[GetSectionData/API] Send Request';
export const GetSectionDataSuccess = '[GetSectionData/API] Success';
export const GetSectionDataFailure = '[GetSectionData/API] Failure';
export const AddSectionDataFixedStatusSectionRequest =
  '[AddSectionDataFixedStatusSectionRequest/API] Send Request';
export const AddSectionDataListViewRequest =
  '[AddSectionDataListView/API] Send Request';
export const AddSectionDataListViewSuccess =
  '[AddSectionDataListView/API] Success';
export const AddSectionDataListViewFailure =
  '[AddSectionDataListView/API] Failure';
export const ReorderSectionRequest = '[ReorderSection/API] Send Request';
export const ReorderSectionSuccess = '[ReorderSection/API] Success';
export const ReorderSectionFailure = '[ReorderSection/API] Failure';
export const EditSectionDataFixedStatusSectionRequest =
  '[EditSectionDataFixedStatusSectionRequest/API] Send Request';
export const EditSectionDataListViewRequest =
  '[EditSectionDataListView/API] Send Request';
export const EditSectionDataListViewSuccess =
  '[EditSectionDataListView/API] Success';
export const EditSectionDataListViewFailure =
  '[EditSectionDataListView/API] Failure';
export const GetSectionOverviewRequest =
  '[GetSectionOverview/API] Send Request';
export const GetSectionOverviewSuccess = '[GetSectionOverview/API] Success';
export const GetSectionOverviewFailure = '[GetSectionOverview/API] Failure';
export const EditSectionTableRequest = '[EditSectionTable/API] Send Request';
export const EditSectionTableSuccess = '[EditSectionTable/API] Success';
export const EditSectionTableFailure = '[EditSectionTable/API] Failure';
export const RemoveSectionTableRequest =
  '[RemoveSectionTable/API] Send Request';
export const RemoveSectionTableSuccess = '[RemoveSectionTable/API] Success';
export const RemoveSectionTableFailure = '[RemoveSectionTable/API] Failure';
export const UploadFileSectionTableRequest =
  '[UploadFileSectionTable/API] Send Request';
export const UploadFileSectionTableSuccess =
  '[UploadFileSectionTable/API] Success';
export const UploadFileSectionTableFailure =
  '[UploadFileSectionTable/API] Failure';

export const GetMemberById = '[GetMemberById/API]  Send Request';
export const GetMemberByIdSuccess = '[GetMemberById/API] Success';
export const GetMemberByIdFailure = '[GetMemberById/API] Failure';
export const RemoveSectionReset = '[RemoveSection/API] Reset';

export const SetMemberEventRequest = '[SetMemberEvent/API] Send Request';
export const SetMemberEventSuccess = '[SetMemberEvent/API] Success';
export const SetMemberEventFailure = '[SetMemberEvent/API] Failure';
export const ClearSetMemberEventState = '[SetMemberEventState] Clear';

export const CreateMemberUploadAttachments =
  '[CreateMemberUploadAttachments/API] Send Request';
export const CreateMemberUploadAttachmentsSuccess =
  '[CreateMemberUploadAttachments/API] Success';
export const CreateMemberUploadAttachmentsFailure =
  '[CreateMemberUploadAttachments/API] Failure';

export const UpdateMemberUploadAttachments =
  '[UpdateMemberUploadAttachments/API] Send Request';
export const UpdateMemberUploadAttachmentsSuccess =
  '[UpdateCreateMemberUploadAttachments/API] Success';
export const UpdateMemberUploadAttachmentsFailure =
  '[UpdateCreateMemberUploadAttachments/API] Failure';

export const RemoveMemberUploadAttachments =
  '[RemoveMemberUploadAttachments/API] Send Request';
export const RemoveMemberUploadAttachmentsSuccess =
  '[RemoveMemberUploadAttachments/API] Success';
export const RemoveMemberUploadAttachmentsFailure =
  '[RemoveMemberUploadAttachments/API] Failure';

export const ResetMemberMetadataAttachmentState =
  '[MemberMetadataAttachmentState] Reset';

export const GetMetaDataBasedOnProfileOverviewConfigRequest =
  '[GetMetaDataBasedOnProfileOverviewConfigRequest/API] Send Request';
export const GetMetaDataBasedOnProfileOverviewConfigSuccess =
  '[GetMetaDataBasedOnProfileOverviewConfigSuccess/API] Success';
export const GetMetaDataBasedOnProfileOverviewConfigFailure =
  '[GetMetaDataBasedOnProfileOverviewConfigFailure/API] Failure';

export const GetMemberDetailUnMaskedRequest =
  '[GetMemberDetailUnMasked/API] Send Request';
export const GetMemberDetailUnMaskedFailure =
  '[GetMemberDetailUnMasked/API] Failure';
export const GetMemberDetailUnMaskedSuccess =
  '[GetMemberDetailUnMasked/API] Success';

export const GetMemberConfiguredIdNameRequest =
  '[GetMemberConfiguredIdName/API] Send Request';
export const GetMemberConfiguredIdNameSuccess =
  '[GetMemberConfiguredIdName/API] Success';
export const GetMemberConfiguredIdNameFailure =
  '[GetMemberConfiguredIdName/API] Failure';

export const getMemberAttachmentsAction = createAction(
  '[GetMemberAttachments/API] Send Request',
  props<{
    memberId: string;
    itemKey: string;
    query: MemberAttachmentsListSectionQuery;
  }>()
);
export const getMemberAttachmentsSuccessAction = createAction(
  '[GetMemberAttachments/API] Success',
  props<{ response: GetMemberAttachmentsResponse }>()
);
export const getMemberAttachmentsFailureAction = createAction(
  '[GetMemberAttachments] Failure',
  props<{ error?: any }>()
);

export const getMemberDetail = createAction(
  MemberDetailRequest,
  props<{
    id: string;
    listSection: string[];
    menuId?: string;
    pageSize?: number;
  }>()
);
export const getMemberDetailSuccess = createAction(
  MemberDetailSuccess,
  props<{ memberDetail: MemberDetail }>()
);
export const getMemberDetailFailure = createAction(
  MemberDetailFailure,
  props<{ errorMsg: string }>()
);

export const clearMemberDetail = createAction(ClearMemberDetail);

export const removeMember = createAction(
  RemoveMemberRequest,
  props<{ id: string }>()
);
export const resetStateRemoveMember = createAction(ResetStateRemoveMember);
export const resetMemberMetadataAttachmentState = createAction(
  ResetMemberMetadataAttachmentState
);
export const removeMemberFailure = createAction(
  RemoveMemberFailure,
  props<{ errorMsg: string }>()
);
export const removeMemberSuccess = createAction(RemoveMemberSuccess);

export const lockMember = createAction(
  LockMemberRequest,
  props<{ id: string }>()
);
export const lockMemberSuccess = createAction(LockMemberSuccess);
export const lockMemberFailure = createAction(
  LockMemberFailure,
  props<{ errorMsg: string }>()
);

export const unlockMember = createAction(
  UnlockMemberRequest,
  props<{ id: string }>()
);
export const unlockMemberSuccess = createAction(UnlockMemberSuccess);
export const unlockMemberFailure = createAction(
  UnlockMemberFailure,
  props<{ errorMsg: string }>()
);
export const removeMemberSection = createAction(
  RemoveMemberSectionRequest,
  props<{ id: string; sectionKey: string }>()
);
export const removeMemberSectionSuccess = createAction(
  RemoveMemberSectionSuccess,
  props<{ sectionKey: string }>()
);
export const removeMemberSectionFailure = createAction(
  RemoveMemberSectionFailure,
  props<{ errorMsg: string; sectionKey: string }>()
);
export const addSectionTable = createAction(
  AddSectionTableRequest,
  props<{
    memberId: string;
    metadataItem: string;
    body: any;
    sectionData: MetadataSection;
  }>()
);
export const addSectionTableSuccess = createAction(
  AddSectionTableSuccess,
  props<{ sectionData: MetadataSection; memberId: string }>()
);
export const addSectionTableFailure = createAction(
  AddSectionTableFailure,
  props<{ error?: any }>()
);
export const resetAddSectionState = createAction(ResetAddSectionState);
export const getSectionData = createAction(
  GetSectionDataRequest,
  props<{
    memberId: string;
    sectionKey: string;
    query?: MemberDetailWithPaging;
  }>()
);
export const getSectionDataSuccess = createAction(
  GetSectionDataSuccess,
  props<{ memberId: string; sectionKey: string; metaDataPropertyValues: any }>()
);
export const getSectionDataFailure = createAction(
  GetSectionDataFailure,
  props<{ error?: any; sectionKey: string }>()
);
export const addSectionDataFixedStatusSection = createAction(
  AddSectionDataFixedStatusSectionRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const addSectionDataListView = createAction(
  AddSectionDataListViewRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const addSectionDataListViewSuccess = createAction(
  AddSectionDataListViewSuccess,
  props<{ sectionData: MetadataSection }>()
);
export const addSectionDataListViewFailure = createAction(
  AddSectionDataListViewFailure,
  props<{ sectionData: MetadataSection }>()
);
export const reorderSectionTable = createAction(
  ReorderSectionRequest,
  props<{
    memberId: string;
    metadataItem: string;
    body: ReorderSectionRequestModel;
  }>()
);
export const reorderSectionTableSuccess = createAction(ReorderSectionSuccess);
export const reorderSectionTableFailure = createAction(
  ReorderSectionFailure,
  props<{ error?: any }>()
);
export const editSectionDataFixedStatusSection = createAction(
  EditSectionDataFixedStatusSectionRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const editSectionDataListView = createAction(
  EditSectionDataListViewRequest,
  props<{ body: any; sectionData: MetadataSection }>()
);
export const editSectionDataListViewSuccess = createAction(
  EditSectionDataListViewSuccess,
  props<{ sectionData: MetadataSection }>()
);
export const editSectionDataListViewFailure = createAction(
  EditSectionDataListViewFailure,
  props<{ error?: any; sectionData: MetadataSection }>()
);

export const getSectionOverview = createAction(
  GetSectionOverviewRequest,
  props<{ memberId: string; sectionKey: string; isMetadataSection: boolean }>()
);
export const getSectionOverviewSuccess = createAction(
  GetSectionOverviewSuccess,
  props<{ sectionOverview: SectionOverviewModel }>()
);
export const getSectionOverviewFailure = createAction(
  GetSectionOverviewFailure,
  props<{ errorMsg: string }>()
);
export const editSectionTable = createAction(
  EditSectionTableRequest,
  props<{
    memberId: string;
    metadataItem: string;
    body: any;
    sectionData: MetadataSection;
  }>()
);
export const editSectionTableSuccess = createAction(
  EditSectionTableSuccess,
  props<{ sectionData: MetadataSection; memberId: string }>()
);
export const editSectionTableFailure = createAction(
  EditSectionTableFailure,
  props<{ error?: any }>()
);
export const removeSectionTable = createAction(
  RemoveSectionTableRequest,
  props<{ sectionData: MetadataSection; body: any }>()
);
export const removeSectionTableSuccess = createAction(
  RemoveSectionTableSuccess,
  props<{ sectionData: MetadataSection }>()
);
export const removeSectionTableFailure = createAction(
  RemoveSectionTableFailure,
  props<{ error?: any; sectionData: MetadataSection }>()
);
export const uploadFileSectionTable = createAction(
  UploadFileSectionTableRequest,
  props<{ memberId: string; body: any }>()
);
export const uploadFileSectionTableSuccess = createAction(
  UploadFileSectionTableSuccess
);
export const uploadFileSectionTableFailure = createAction(
  UploadFileSectionTableFailure,
  props<{ error?: any }>()
);

export const getMetadataSectionsAction = createAction(
  '[GetMetadataSections/API] Send Request'
);
export const getMetadataSectionsSuccessAction = createAction(
  '[GetMetadataSections/API] Success',
  props<{ metadata: MetadataSection[]; listStatus: MemberStatus[] }>()
);
export const getMetadataSectionsFailureAction = createAction(
  '[GetMetadataSections/API] Failure',
  props<{ error?: any }>()
);
export const clearGetMetadataSectionsStateAction = createAction(
  '[GetMetadataSections] Clear'
);

export const getMetadataNonListSectionsAction = createAction(
  '[GetMetadataNonListSections/API] Send Request',
  props<{ memberNavigationItemId: string }>()
);
export const getMetadataNonListSectionsSuccessAction = createAction(
  '[GetMetadataNonListSections/API] Success',
  props<{
    response: GetMetadataNonListSectionsResponse;
    memberNavigationItemId: string;
  }>()
);
export const getMetadataNonListSectionsFailureAction = createAction(
  '[GetMetadataNonListSections/API] Failure',
  props<{ error?: any }>()
);
export const clearGetMetadataNonListSectionsStateAction = createAction(
  '[GetMetadataNonListSections] Clear'
);

export const getMemberById = createAction(
  GetMemberById,
  props<{ id: string }>()
);

export const getMemberByIdSuccess = createAction(
  GetMemberByIdSuccess,
  props<{ memberInfo: any }>()
);

export const getMemberByIdFailure = createAction(GetMemberByIdFailure);
export const removeSectionReset = createAction(RemoveSectionReset);

export const setMemberEvent = createAction(
  SetMemberEventRequest,
  props<{ body: EditMemberEventBody }>()
);
export const setMemberEventSuccess = createAction(SetMemberEventSuccess);
export const setMemberEventFailure = createAction(SetMemberEventFailure);
export const clearSetMemberEventState = createAction(ClearSetMemberEventState);

export const getMemberDetailWithPagingAction = createAction(
  '[MemberDetailWithPaging/API] Send Request',
  props<{
    query: MemberDetailWithPaging;
    id: string;
    itemKey: string;
    menuId?: string;
  }>()
);
export const getMemberDetailWithPagingSuccessAction = createAction(
  '[MemberDetailWithPaging/API] Success',
  props<{ response: any }>()
);
export const getMemberDetailWithPagingFailureAction = createAction(
  '[MemberDetailWithPaging/API] Failure',
  props<{ error?: any }>()
);

export const createMemberUploadAttachments = createAction(
  CreateMemberUploadAttachments,
  props<{ body: any }>()
);
export const createMemberUploadAttachmentsSuccess = createAction(
  CreateMemberUploadAttachmentsSuccess
);
export const createMemberUploadAttachmentsFailure = createAction(
  CreateMemberUploadAttachmentsFailure,
  props<{ errorMsg: string }>()
);
export const updateMemberUploadAttachments = createAction(
  UpdateMemberUploadAttachments,
  props<{ body: any }>()
);
export const updateMemberUploadAttachmentsSuccess = createAction(
  UpdateMemberUploadAttachmentsSuccess
);
export const updateMemberUploadAttachmentsFailure = createAction(
  UpdateMemberUploadAttachmentsFailure,
  props<{ errorMsg: string }>()
);
export const removeMemberUploadAttachments = createAction(
  RemoveMemberUploadAttachments,
  props<{ body: any }>()
);
export const removeMemberUploadAttachmentsSuccess = createAction(
  RemoveMemberUploadAttachmentsSuccess,
  props<{ body: any }>()
);
export const removeMemberUploadAttachmentsFailure = createAction(
  RemoveMemberUploadAttachmentsFailure,
  props<{ errorMsg: string; documentName: string }>()
);

export const getMetadataBasedOnProfileOverviewConfig = createAction(
  GetMetaDataBasedOnProfileOverviewConfigRequest,
  props<{ memberId: string }>()
);

export const getMetadataBasedOnProfileOverviewConfigSuccess = createAction(
  GetMetaDataBasedOnProfileOverviewConfigSuccess,
  props<{
    metadataOverview: MetadataSection[];
    listStatus: MemberStatus[];
    profileOverviewName: string;
    profileOverviewConfig: MetadataOverView;
  }>()
);
export const getMetadataBasedOnProfileOverviewConfigFailure = createAction(
  GetMetaDataBasedOnProfileOverviewConfigFailure
);

export const getMemberDetailUnMaskedRequest = createAction(
  GetMemberDetailUnMaskedRequest,
  props<{
    memberId: string;
    listSection: string[];
    isAllData: boolean;
    menuId?: string;
  }>()
);
export const getMemberDetailUnMaskedSuccess = createAction(
  GetMemberDetailUnMaskedSuccess,
  props<{ memberDetailUnMasked: MemberDetail }>()
);
export const getMemberDetailUnMaskedFailure = createAction(
  GetMemberDetailUnMaskedFailure
);

export const getMemberConfiguredIdNameRequest = createAction(
  GetMemberConfiguredIdNameRequest
);
export const getMemberConfiguredIdNameSuccess = createAction(
  GetMemberConfiguredIdNameSuccess,
  props<{ payrollConfigurationInfo: PayrollConfigurationInfo }>()
);
export const getMemberConfiguredIdNameFailure = createAction(
  GetMemberConfiguredIdNameFailure
);
