export enum AccountType {
  Checking,
  Savings
}

export interface Address {
  street1: string,
  street2?: string,
  city: string,
  state: string,
  zipCode: string,
  country?: string,
  additional?: string,
  stateCode?: string;
  countryCode?: string;
}

export interface PersonName {
  prefix: string;
  first?: string;
  middle: string;
  last?: string;
  suffix: string;
}

export interface DirectDepositPayment {
  bankId: string;
  routing: string;
  bankName: string;
  account: string;
  accountType: AccountType;
}
export interface AsyncFunction {
  function: any;
  fieldCheck: string;
  config?: AsyncFunctionConfig;
  editValue?: string;
}

export interface AsyncFunctionConfig {
  ignoreCase?: boolean;
  params?: any;
}

export enum AbstractControlStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}
