import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataType } from '../../constance/data-type.const';
import { DetailDisplay } from '../../models/detail-display.model';

@Component({
  selector: 'ptg-detail-display-box',
  templateUrl: './detail-display.component.html',
  styleUrls: ['./detail-display.component.scss']
})
export class DetailDisplayComponent implements OnInit {
  DATA_TYPE = DataType;
  @Input() title: string = '';
  @Input() selectedItem!: any;
  @Input() nonEditItem?: any;
  @Input() propertyDisplayed!: DetailDisplay[];
  @Input() isDisableEdit: boolean = false;
  @Output() editButtonEvent = new EventEmitter();

  @Input() hasSecondButton: boolean = false;
  @Input() secondButtonIcon: string = '';
  @Input() secondButtonName: string = '';
  @Output() secondButtonEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onEdit() {
    this.editButtonEvent.emit();
  }

  onClickSecondButton() {
    this.secondButtonEvent.emit();
  }
}
