import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../../reducers/index';
import { BulkContentItem } from '../models/bulk-content-item.model';

@Injectable({
  providedIn: 'root'
})
export class BulkContentServiceService {
  activeFund: any;
  
  constructor(private httpClient: HttpClient, private store: Store<fromReducer.State>) { 
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
  }

  getMenuItems(): Observable<BulkContentItem[]> {
    if (this.activeFund?.id) {
      return this.httpClient.get<BulkContentItem[]>(`${environment.apiUrl}/Clients/${this.activeFund?.id}/Metadata/Importable`);
    }
    return of();
  }

}
