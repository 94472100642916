<div class="header-toolbar" role="banner" [class.branded]="router.url !== '/fund-list'" [ngClass]="{'header-closed move-up': !this.showHeader, 'move-down': this.showHeader}">
  <div class="toggle-header">
    <button (click)="controlHeader()">
      <ng-container *ngIf="showHeader; else arrowDown">
        <mat-icon svgIcon="doubleArrowUpIcon"></mat-icon>
      </ng-container>
      <ng-template #arrowDown>
        <mat-icon svgIcon="doubleArrowDownIcon"></mat-icon>
      </ng-template>
    </button>
  </div>
  <div id="left-header">
    <img src="../../../../../assets/images/PTG_LOGO_purewhite.svg" alt="logo-PTG">
  </div>
  <div id="middle-header">
    <ng-container *ngIf="!router.url.includes('/fund-list')">
      <div class="vertical-line"></div>
      <div class="logo-tenant">
        <img [src]="logoUrl" alt="logo-tenant">
      </div>
      <div class="tenant-name" *ngIf="showHeader">{{tenant}}</div>
      <div class="select-tenant" *ngIf="authService.Role === ADMIN_SYSTEM">
        <mat-form-field appearance="fill">
          <mat-label>Fund</mat-label>
          <mat-select [(ngModel)]="tenant" (selectionChange)="changeTenant(tenant)" panelClass="cs-option">
            <mat-option *ngFor="let fund of listFund" [value]="fund.name" >
              {{fund.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="search-member" *ngIf="authService.Role === ADMIN_SYSTEM || checkPermissionService.checkPermission(PERMISSION_KEY.PARTICIPANTS)">
        <mat-form-field class="example-form-field" appearance="fill" *ngIf="hasConfig">
          <mat-label>Search Participant</mat-label>
          <mat-icon svgIcon="personSearch" aria-hidden="false" matPrefix></mat-icon>
          <input matInput type="text" [(ngModel)]="valueSearch" (input)="searchMember(valueSearch)" [matAutocomplete]="auto" #participantSearchTrigger="matAutocompleteTrigger">
          <mat-autocomplete #auto="matAutocomplete" class="participant-search-panel" panelWidth="auto" [disableRipple]="true">
            <mat-option [disabled]="true" *ngIf="!isLoading && searchResultList?.length && valueSearch.length > 1">
              <table mat-table [dataSource]="searchResultList" class="w-full">
                <ng-container [matColumnDef]="column" *ngFor="let column of searchResultColumns">
                  <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container [ngTemplateOutletContext]="{ item: element[column] }"
                                  [ngTemplateOutlet]="displayValueTemplate"></ng-container>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="searchResultColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: searchResultColumns;" (click)="selectMember(row)"></tr>
              </table>
            </mat-option>
            <mat-option class="message-option" *ngIf="!searchResultList?.length && valueSearch.length > 1">
              {{ !isLoading ? (isError ? 'Error occurred while searching.' : 'No Participants found.') : 'Searching...' }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </ng-container>

  </div>
  <div id="right-header">
    <span id="lablel-logged" class="item-right">Logged in as:</span>
    <span id="username" class="item-right">{{username}}</span>
    <span class="item-right" style="height: 100%;"></span>
  </div>
</div>

<ng-template let-item="item" #displayValueTemplate>
  <ng-container [ngSwitch]="item?.type">
    <span class="value" *ngSwitchCase="'Currency'"
          [innerHTML]="item.value | numberLocalDecimal:'$' | customBold:valueSearch"></span>
    <span class="value" *ngSwitchCase="'Percentage'"
          [innerHTML]="((item.value | numberLocalDecimal:'') ? (item.value | numberLocalDecimal:'') + ' %' : '') | customBold:valueSearch">
    </span>
    <span class="value" *ngSwitchCase="'Date'" [innerHTML]="item.value | date: 'MM/dd/yyyy' | customBold:valueSearch"></span>
    <span class="value" *ngSwitchCase="'Person_Name'" [innerHTML]="item.value | personName : item?.typedValue | customBold:valueSearch"></span>
    <span class="value" *ngSwitchCase="'Address'" [innerHTML]="item.value | address | customBold:valueSearch"></span>
    <span class="value" *ngSwitchCase="'Decimal'"
          [innerHTML]="item.value | numberDecimal:{decimal: item?.configValue?.fractionalLengthInput, isCheckCurrentDecimal: true} | customBold:valueSearch">
    </span>
    <span class="value" *ngSwitchCase="'Whole_Number'" [innerHTML]="item.value | numberDecimal | customBold:valueSearch"></span>
    <span class="status-container" *ngSwitchCase="'Status'">
      <div class="icon-status-container">
        <mat-icon *ngIf="item?.value && item?.typedValue?.iconName" class="icon-status" [ngStyle]="{'color': item?.typedValue?.color}">{{ item?.typedValue?.iconName }}</mat-icon>
        <div class="value" [innerHTML]="item.value | customBold:valueSearch"></div>
      </div>
    </span>
    <span class="value" *ngSwitchCase="'SSN'" [innerHTML]="item.value | ssnNumber | customBold:valueSearch"></span>
    <ng-container *ngSwitchCase="'RichText'">
      <ptg-view-rich-text [title]="item?.columnName" [content]="item.value" [defaultContent]="'-'"></ptg-view-rich-text>
    </ng-container>
    <span class="value" *ngSwitchCase="'DateTime'" [innerHTML]="item?.value | date: 'MM/dd/yyyy hh:mm a' | customBold:valueSearch"></span>
    <span class="value" *ngSwitchCase="'Phone'" [innerHTML]="item?.value | phoneNumber | customBold:valueSearch"></span>
    <span class="value" *ngSwitchDefault [innerHTML]="item.value | customBold:valueSearch"></span>
  </ng-container>
</ng-template>
