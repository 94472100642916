<div class="non-list-config-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [disabled]="!isChanged || isSaving"
        (click)="onClickSave()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>

    <div class="flex gap-6">
      <div style="width: 550px">
        <div class="box-info">
          <div class="box-info-header">Add Property</div>
          <div class="box-info-body">
            <div
              *ngIf="
                !isLoadingGetNonListProperties &&
                !isLoadingGetNonListItemConfigs
              "
              class="flex flex-col gap-3"
            >
              <ptg-radio-button
                *ngIf="nonListPropertyTypeOptions?.length"
                [controlField]="nonListPropertyTypeCtrl"
                [listOption]="nonListPropertyTypeOptions"
                [isObject]="true"
                (changeValue)="onChangeNonListPropertyType($event)"
              ></ptg-radio-button>

              <ptg-select
                *ngIf="
                  nonListPropertyTypeCtrl.value ===
                    PropertyConfigType.Property ||
                  nonListPropertyTypeCtrl.value ===
                    PropertyConfigType.Aggregation ||
                  nonListPropertyTypeCtrl.value ===
                    PropertyConfigType.Calculation
                "
                [placeholder]="
                  nonListPropertyTypeCtrl.value === PropertyConfigType.Property
                    ? 'Property Name'
                    : nonListPropertyTypeCtrl.value ===
                      PropertyConfigType.Aggregation
                    ? 'Aggregation'
                    : 'Calculation'
                "
                [controlField]="nonListPropertyCtrl"
                [listData]="availableNonListPropertyOptions"
                [isOptionObj]="true"
                [isMultipleLineOption]="true"
                [width]="'100%'"
                [isRequired]="true"
                (changeOptionValue)="onChangeNonListProperty()"
              ></ptg-select>

              <ptg-textbox
                *ngIf="
                  nonListPropertyTypeCtrl.value !==
                  PropertyConfigType.BlankSpace
                "
                placeholder="Label"
                [width]="'100%'"
                [controlField]="labelCtrl"
                [hasLabel]="true"
                [isRequired]="true"
                [maxLength]="150"
                customError="duplicatedValue"
              ></ptg-textbox>

              <ptg-select
                [placeholder]="'To Column'"
                [controlField]="toColumnCtrl"
                [listData]="toColumnOptions"
                [isOptionObj]="true"
                [width]="'100%'"
                [isRequired]="true"
              ></ptg-select>

              <ptg-button
                [buttonName]="'Add to column'"
                classInput="add-button"
                type="button"
                (clickButton)="onClickAddToColumn()"
              >
                <mat-icon>add</mat-icon>
              </ptg-button>
            </div>

            <ptg-spinner
              [isLoading]="
                isLoadingGetNonListProperties || isLoadingGetNonListItemConfigs
              "
            ></ptg-spinner>
          </div>
        </div>
      </div>

      <div class="flex-grow flex flex-col gap-5">
        <div class="box-info">
          <div class="box-info-header">Column 1</div>
          <div>
            <ptg-grid
              #gridColumn1
              id="gridColumn1"
              [connectedTo]="['gridColumn2', 'gridColumn3', 'gridColumn4']"
              [data]="column1Data"
              [columns]="columns"
              [notFoundMessage]="''"
              [isLoading]="isLoadingGetNonListItemConfigs"
              [fitToParent]="true"
              [paginable]="false"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteNonListItemConfig($event)"
              (change)="markAsDataChanged()"
              (outsideRowDrop)="onOutsideRowDrop($event)"
            >
              <ng-template cellContent [columnName]="'label'" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.label }}</span>
                  <span class="description">
                    {{ row.description }}
                  </span>
                </div>
              </ng-template>
            </ptg-grid>
          </div>
        </div>

        <div class="box-info">
          <div class="box-info-header">Column 2</div>
          <div>
            <ptg-grid
              #gridColumn2
              id="gridColumn2"
              [connectedTo]="['gridColumn1', 'gridColumn3', 'gridColumn4']"
              [data]="column2Data"
              [columns]="columns"
              [notFoundMessage]="''"
              [isLoading]="isLoadingGetNonListItemConfigs"
              [fitToParent]="true"
              [paginable]="false"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteNonListItemConfig($event)"
              (change)="markAsDataChanged()"
              (outsideRowDrop)="onOutsideRowDrop($event)"
            >
              <ng-template cellContent [columnName]="'label'" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.label }}</span>
                  <span class="description">
                    {{ row.description }}
                  </span>
                </div>
              </ng-template>
            </ptg-grid>
          </div>
        </div>

        <div class="box-info">
          <div class="box-info-header">Column 3</div>
          <div>
            <ptg-grid
              #gridColumn3
              id="gridColumn3"
              [connectedTo]="['gridColumn1', 'gridColumn2', 'gridColumn4']"
              [data]="column3Data"
              [columns]="columns"
              [notFoundMessage]="''"
              [isLoading]="isLoadingGetNonListItemConfigs"
              [fitToParent]="true"
              [paginable]="false"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteNonListItemConfig($event)"
              (change)="markAsDataChanged()"
              (outsideRowDrop)="onOutsideRowDrop($event)"
            >
              <ng-template cellContent [columnName]="'label'" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.label }}</span>
                  <span class="description">
                    {{ row.description }}
                  </span>
                </div>
              </ng-template>
            </ptg-grid>
          </div>
        </div>

        <div class="box-info">
          <div class="box-info-header">Column 4</div>
          <div>
            <ptg-grid
              #gridColumn4
              id="gridColumn4"
              [connectedTo]="['gridColumn1', 'gridColumn2', 'gridColumn3']"
              [data]="column4Data"
              [columns]="columns"
              [notFoundMessage]="''"
              [isLoading]="isLoadingGetNonListItemConfigs"
              [fitToParent]="true"
              [paginable]="false"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteNonListItemConfig($event)"
              (change)="markAsDataChanged()"
              (outsideRowDrop)="onOutsideRowDrop($event)"
            >
              <ng-template cellContent [columnName]="'label'" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.label }}</span>
                  <span class="description">
                    {{ row.description }}
                  </span>
                </div>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
