<div
  class="grid-container flex flex-col"
  [ngClass]="{ 'fit-to-parent': fitToParent }"
>
    <div class="table-container" [ngClass]="{ 'hide-scrollbar': hideScrollbar }">
      <table
        mat-table
        [dataSource]="dataTable"
        matSort
        (matSortChange)="onChangeSort($event)"
        multiTemplateDataRows
        class="mat-elevation-z8"
      >
        <ng-container *ngFor="let col of columnsToDisplay" [matColumnDef]="col.name">
          <th 
            mat-header-cell
            *matHeaderCellDef 
            mat-sort-header
            [class]="getColumnClasses(col)"
            [ngStyle]="getHeaderStyle(col)"
            [disabled]="!col.sortable"
            >{{ col.header?.title || '' }}
          </th>
          <td mat-cell *matCellDef="let row" 
            [class]="getColumnClasses(col)"
            [ngStyle]="getCellStyle(col)"
            (click)="changeIcon(row)"
            >
            <ng-container [ngSwitch]="col.type">
              <ptg-number-decimal-column
                *ngSwitchCase="ColumnType.Decimal"
                [value]="row[col.name]"
                [decimal]="
                  col.templateArgs?.decimal || col.templateArgs?.decimal === 0
                    ? col.templateArgs?.decimal
                    : 2
                "
                [unit]="col.templateArgs?.unit"
                [unitPosition]="col.templateArgs?.unitPosition || 'left'"
              ></ptg-number-decimal-column>
              <ptg-datetime-column
                *ngSwitchCase="ColumnType.DateTime"
                [value]="row[col.name]"
                [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
              ></ptg-datetime-column>

              <ptg-view-rich-text
                *ngSwitchCase="ColumnType.RichText"
                [title]="col.header?.title"
                [content]="row[col.name]"
              ></ptg-view-rich-text>

              <ptg-phone-column
                *ngSwitchCase="ColumnType.PhoneNumber"
                [value]="row[col.name]"
              ></ptg-phone-column>

              <ptg-icon-column
                *ngSwitchCase="ColumnType.Icon"
                [name]="row[col.templateArgs?.nameField]"
                [color]="row[col.templateArgs?.colorField]"
                [label]="
                  col.templateArgs?.labelField
                    ? row[col.templateArgs?.labelField]
                    : ''
                "
                [style]="col.templateArgs?.styleField"
              ></ptg-icon-column>

              <ptg-address-column
                *ngSwitchCase="ColumnType.Address"
                [value]="row[col.name]"
              ></ptg-address-column>

              <ptg-person-name-column
                *ngSwitchCase="ColumnType.PersonName"
                [value]="row[col.name]"
              ></ptg-person-name-column>

              <ptg-binary-column
                *ngSwitchCase="ColumnType.Binary"
                [value]="row[col.name]"
                [templateArgs]="col.templateArgs"
              ></ptg-binary-column>

              <ng-container *ngSwitchDefault>
                <ng-container
                  [ngTemplateOutlet]="
                    getCellContentTemplate(col.name) || defaultTemplate
                  "
                  [ngTemplateOutletContext]="{ $implicit: row }"
                ></ng-container>

                <ng-template #defaultTemplate>
                  {{ col.cell ? col.cell(row) : row[col.name] }}
                </ng-template>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
    
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnsToDisplay.length"
            style="padding: 0;"
            >
            <div
                class="element-detail grid-container"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
            >
            
                <div class="table-detail-container">
                  <table
                  mat-table
                  [dataSource]="element.detail"
                  multiTemplateDataRows
                  class="mat-elevation-z8"
                  >
                  <ng-container *ngFor="let col of columnsExpandToDisplay" [matColumnDef]="col.name">
                    <td mat-cell *matCellDef="let row"
                      [class]="getColumnClasses(col)"
                      [ngStyle]="getCellStyle(col)"
                    >
                      <ng-container [ngSwitch]="col.type">
                        <ptg-number-decimal-column
                          *ngSwitchCase="ColumnType.Decimal"
                          [value]="row[col.name]"
                          [decimal]="
                            col.templateArgs?.decimal || col.templateArgs?.decimal === 0
                              ? col.templateArgs?.decimal
                              : 2
                          "
                          [unit]="col.templateArgs?.unit"
                          [unitPosition]="col.templateArgs?.unitPosition || 'left'"
                        ></ptg-number-decimal-column>
                        <ptg-datetime-column
                          *ngSwitchCase="ColumnType.DateTime"
                          [value]="row[col.name]"
                          [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
                        ></ptg-datetime-column>
          
                        <ptg-view-rich-text
                          *ngSwitchCase="ColumnType.RichText"
                          [title]="col.header?.title"
                          [content]="row[col.name]"
                        ></ptg-view-rich-text>
          
                        <ptg-phone-column
                          *ngSwitchCase="ColumnType.PhoneNumber"
                          [value]="row[col.name]"
                        ></ptg-phone-column>
          
                        <ptg-icon-column
                          *ngSwitchCase="ColumnType.Icon"
                          [name]="row[col.templateArgs?.nameField]"
                          [color]="row[col.templateArgs?.colorField]"
                          [label]="
                            col.templateArgs?.labelField
                              ? row[col.templateArgs?.labelField]
                              : ''
                          "
                          [style]="col.templateArgs?.styleField"
                        ></ptg-icon-column>
          
                        <ptg-address-column
                          *ngSwitchCase="ColumnType.Address"
                          [value]="row[col.name]"
                        ></ptg-address-column>
          
                        <ptg-person-name-column
                          *ngSwitchCase="ColumnType.PersonName"
                          [value]="row[col.name]"
                        ></ptg-person-name-column>
          
                        <ptg-binary-column
                          *ngSwitchCase="ColumnType.Binary"
                          [value]="row[col.name]"
                          [templateArgs]="col.templateArgs"
                        ></ptg-binary-column>
          
                        <ng-container *ngSwitchDefault>
                          <ng-container
                            [ngTemplateOutlet]="
                              getCellContentTemplate(col.name) || defaultTemplate
                            "
                            [ngTemplateOutletContext]="{ $implicit: row }"
                          ></ng-container>
          
                          <ng-template #defaultTemplate>
                            {{ col.cell ? col.cell(row) : row[col.name] }}
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                    <tr
                      mat-row
                      *matRowDef="let element; columns: columnNameDetails;"
                      class="element-row"
                    ></tr>
                  </table>
                </div>
            </div>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr
            mat-row
            *matRowDef="let element; columns: columnNames;"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
        ></tr>
      </table>
    </div>
    <div class="flex-grow flex">
        <div *ngIf="isLoading; else notLoading" class="spinner m-auto">
          <mat-progress-spinner diameter="50" mode="indeterminate">
          </mat-progress-spinner>
          <p>Loading...</p>
        </div>
        <ng-template #notLoading>
          <div *ngIf="!dataTable?.length && notFoundMessage" class="not-found m-auto flex flex-col">
            <ng-container *ngIf="errorMessage; else notError">
              <div class="warning-icon">
                <mat-icon class="material-icons-round">warning</mat-icon>
              </div>
              <div>
                <span class="error-msg">{{ errorMessage }} error</span>
                <span>occurred, please try again later.</span>
              </div>
            </ng-container>
    
            <ng-template #notError>
              {{ notFoundMessage }}
            </ng-template>
          </div>
        </ng-template>
      </div>
    
      <ptg-pagination
        *ngIf="paginable"
        #paginator
        [totalRecords]="totalRecords"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [maxPages]="maxPages"
        [hiddenPageSizeOptions]="hiddenPageSizeOptions"
        (pageChange)="onChangePage($event)"
      ></ptg-pagination>
</div>