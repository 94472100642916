import { Injectable } from "@angular/core";
import { FileSnapshot } from "../models";


@Injectable({
    providedIn: 'root'
})
export class SignatoryService {
 
    public getUrl(file: FileSnapshot, signFile?: (fileKey: string) => void): string | undefined {
        if(!file) {
            return;
        }

        if(!file.signatureRequired) {
            return file.uri;
        }

        if(file.signature != null && new Date(file.signature.expiration) > new Date()) {
            return `${file.uri}${file.signature.value}`;
        }

        if(!signFile) {
            return;
        }

        signFile(file.key);
        return;
    }

}