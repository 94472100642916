import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ViewCurrentFilterModule } from '@ptg-shared/controls/view-current-filter/view-current-filter.module';

import { ReplaceLookupTableValueComponent } from '@ptg-member/components/replace-lookup-table-value/replace-lookup-table-value.component';
import { ProfileOverviewConfigurationDetailComponent } from '@ptg-member/containers/profile-overview-configuration-detail/profile-overview-configuration-detail.component';
import { MemberListConfigurationEffects } from '@ptg-member/effects/member-list-configuration.effects';
import { MemberNavigationEffects } from '@ptg-member/effects/member-navigation';
import { ParticipantSearchConfigurationEffects } from '@ptg-member/effects/participant-search-configuration.effects';
import { ProfileHeaderConfigurationEffects } from '@ptg-member/effects/profile-header-configuration.effects';
import { ProfileOverviewConfigurationEffects } from '@ptg-member/effects/profile-overview-configuration.effects';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { ViewValueModule } from '@ptg-shared/controls/view-value/view-value.module';
import { TruncateTextDirective } from '@ptg-shared/layout/directive/truncate-text/truncate-text.directive';

import { CheckPermissionModule } from '@ptg-shared/layout/directive/check-permission/check-permission.module';
import { PtgReportingModule } from '@ptg-shared/report-viewer';
import { SharedModule } from '@ptg-shared/shared.module';
import { BulkUpdateModule } from '../bulk-update/bulk-update.module';
import { MetadataDetailEffects } from '../member/effects/metadata-detail.effects';
import * as fromDemos from '../member/reducers';
import { AddAggregationComponent } from './components/add-aggregation/add-aggregation.component';
import { AddBenefitTypeComponent } from './components/add-benefit-type/add-benefit-type.component';
import { AddCalculationComponent } from './components/add-calculation/add-calculation.component';
import { AddMemberFilterComponent } from './components/add-member-filter/add-member-filter.component';
import { AddMemberSectionDataComponent } from './components/add-member-section-data/add-member-section-data.component';
import { AddMenuComponent } from './components/add-menu/add-menu.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { AddPropertyComponent } from './components/add-property/add-property.component';
import { AddSectionComponent } from './components/add-section/add-section.component';
import { AggregationGroupModalComponent } from './components/aggregation-group-modal/aggregation-group-modal.component';
import { DeductionDetailComponent } from './components/deduction-detail/deduction-detail.component';
import { DefaultSectionComponent } from './components/default-section/default-section.component';
import { DemographicComponent } from './components/demographic/demographic.component';
import { DropStatementComponent } from './components/drop-statement/drop-statement.component';
import { EarningDetailComponent } from './components/earning-detail/earning-detail.component';
import { EditBenefitComponent } from './components/edit-benefit/edit-benefit.component';
import { EditDeductionsComponent } from './components/edit-deductions/edit-deductions.component';
import { EditDropPlanComponent } from './components/edit-drop-plan/edit-drop-plan.component';
import { EditEarningsComponent } from './components/edit-earnings/edit-earnings.component';
import { EditEventComponent } from './components/edit-event/edit-event.component';
import { EditLookupTableValueComponent } from './components/edit-lookup-table-value/edit-lookup-table-value.component';
import { EditLookupTableComponent } from './components/edit-lookup-table/edit-lookup-table.component';
import { EditMemberStatusEventComponent } from './components/edit-member-status-event/edit-member-status-event.component';
import { EditMemberStatusHistoryComponent } from './components/edit-member-status-history/edit-member-status-history.component';
import { EditMetadataComponent } from './components/edit-metadata/edit-metadata.component';
import { EditMemberServiceHistoryComponent } from './components/edit-municipality-service-history/edit-municipality-service-history.component';
import { EditPayrollSettingsComponent } from './components/edit-payroll-settings/edit-payroll-settings.component';
import { EditProfileNavigationItemComponent } from './components/edit-profile-navigation-item/edit-profile-navigation-item.component';
import { EditProfileNavigationComponent } from './components/edit-profile-navigation/edit-profile-navigation.component';
import { EditPropertyComponent } from './components/edit-property/edit-property.component';
import { EditStatusComponent } from './components/edit-status/edit-status.component';
import { EditTierConfigurationComponent } from './components/edit-tier-configuration/edit-tier-configuration.component';
import { EmailComponent } from './components/email/email.component';
import { EstablishBenefitComponent } from './components/establish-benefit/establish-benefit.component';
import { ListStatusComponent } from './components/grid-templates';
import { GroupAggregationComponent } from './components/group-aggregation/group-aggregation.component';
import { ManageMemberAttachmentComponent } from './components/manage-member-attachment/manage-member-attachment.component';
import { ManageMemberFilterComponent } from './components/manage-member-filter/manage-member-filter.component';
import { MemberAttachmentModalComponent } from './components/member-attachment-modal/member-attachment-modal.component';
import { MemberNavigationAttachmentListComponent } from './components/member-navigation-attachment-list/member-navigation-attachment-list.component';
import { MemberNavigationDetailNonlistComponent } from './components/member-navigation-detail-nonlist/member-navigation-detail-nonlist.component';
import { MemberNavigationDetailComponent } from './components/member-navigation-detail/member-navigation-detail.component';
import { MemberNavigationListComponent } from './components/member-navigation-list/member-navigation-list.component';
import { MemberProfileSectionComponent } from './components/member-profile-section/member-profile-section.component';
import { MemberSectionAttachmentComponent } from './components/member-section-attachment/member-section-attachment.component';
import { ModifyPropertyContainerComponent } from './components/modify-property-container/modify-property-container.component';
import { MunicipalityGroupAggregationComponent } from './components/municipality-group-aggregation/municipality-group-aggregation.component';
import { NoteMemoDetailComponent } from './components/note-memo-detail/note-memo-detail.component';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';
import { OverviewHeaderComponent } from './components/overview-header/overview-header.component';
import { OverviewSectionComponent } from './components/overview-section/overview-section.component';
import { ParticipantDetailDialogComponent } from './components/participant-detail-dialog/participant-detail-dialog.component';
import { GeneratedReportComponent } from './components/participant-report/generated-report/generated-report.component';
import { ManageReportComponent } from './components/participant-report/manage-report/manage-report.component';
import { ReportCategoriesComponent } from './components/participant-report/report-categories/report-categories.component';
import { ReportConfigComponent } from './components/participant-report/report-config/report-config.component';
import { ReportDetailComponent } from './components/participant-report/report-detail/report-detail.component';
import { ReportTemplateComponent } from './components/participant-report/report-view/report-view.component';
import { PaymentDetailComponent } from './components/payment-detail/payment-detail.component';
import { PaymentEditBankAccountPopupComponent } from './components/payment-edit-bank-account/payment-edit-bank-account-popup.component';
import { PaymentEditPopupComponent } from './components/payment-edit/payment-edit-popup.component';
import { PaymentHistoryComponent } from './components/payment-history/payment-history.component';
import { PaymentInformationConfigurationComponent } from './components/payment-information-configuration/payment-information-configuration.component';
import { PaymentListConfigurationComponent } from './components/payment-list-configuration/payment-list-configuration.component';
import { PayrollSettingsDetailComponent } from './components/payroll-settings-detail/payroll-settings-detail.component';
import { PendingPaymentComponent } from './components/pending-payment/pending-payment.component';
import { PropertyDisplayConfigurationComponent } from './components/property-display-configuration/property-display-configuration.component';
import { SaveMemberFilterComponent } from './components/save-member-filter/save-member-filter.component';
import { StatusHistoryAuditComponent } from './components/status-history-audit/status-history-audit.component';
import { UploadLookupTableComponent } from './components/upload-lookup-table/upload-lookup-table.component';
import { AggregationConfigurationComponent } from './containers/aggregation-configuration/aggregation-configuration.component';
import { AggregationGroupConfigurationComponent } from './containers/aggregation-group-configuration/aggregation-group-configuration.component';
import { BulkContentComponent } from './containers/bulk-content/bulk-content.component';
import { CalculationListComponent } from './containers/calculation-list/calculation-list.component';
import { DefinedBenefitsComponent } from './containers/defined-benefits/defined-benefits.component';
import { DropPlanComponent } from './containers/drop-plan/drop-plan.component';
import { DropStatementPopupComponent } from './containers/drop-statement-popup/drop-statement-popup.component';
import { Info1099Component } from './containers/info-1099/info-1099.component';
import { LookupTableDetailComponent } from './containers/lookup-table-detail/lookup-table-detail.component';
import { LookupTableComponent } from './containers/lookup-table/lookup-table.component';
import { MemberDetailsComponent } from './containers/member-details/member-details.component';
import { MemberListConfigurationComponent } from './containers/member-list-configuration/member-list-configuration.component';
import { MemberMetadataComponent } from './containers/member-metadata/member-metadata.component';
import { MemberNavigationComponent } from './containers/member-navigation/member-navigation.component';
import { MembersBenefitEligibleComponent } from './containers/members-benefit-eligible/members-benefit-eligible.component';
import { MembersListComponent } from './containers/members-list/members-list.component';
import { MunicipalityPaymentComponent } from './containers/municipality-payment-history/municipality-payment.component';
import { MunicipalityServiceHistoryComponent } from './containers/municipality-service-history/municipality-service-history.component';
import { NextPaymentComponent } from './containers/next-payment/next-payment.component';
import { NonListConfigurationComponent } from './containers/non-list-configuration/non-list-configuration.component';
import { ParticipantRelationshipComponent } from './containers/participant-relationship/participant-relationship.component';
import { ParticipantSearchConfigurationComponent } from './containers/participant-search-configuration/participant-search-configuration.component';
import { PaymentHistoryScreenComponent } from './containers/payment-history-screen/payment-history-screen.component';
import { ProfileHeaderConfigurationListComponent } from './containers/profile-header-configuration-list/profile-header-configuration-list.component';
import { ProfileHeaderConfigurationComponent } from './containers/profile-header-configuration/profile-header-configuration.component';
import { ProfileNavigationConfigurationDetailComponent } from './containers/profile-navigation-configuration-detail/profile-navigation-configuration-detail.component';
import { ProfileNavigationConfigurationComponent } from './containers/profile-navigation-configuration/profile-navigation-configuration.component';
import { ProfileOverviewConfigurationComponent } from './containers/profile-overview-configuration/profile-overview-configuration.component';
import { StatusDetailComponent } from './containers/status-detail/status-detail.component';
import { StatusHistoryComponent } from './containers/status-history/status-history.component';
import { StatusComponent } from './containers/status/status.component';
import { TierConfigurationComponent } from './containers/tier-configuration/tier-configuration.component';
import { AggregationConfigEffects } from './effects/aggregation-config.effects';
import { AggregationGroupEffects } from './effects/aggregation-group.effects';
import { CalculationEffects } from './effects/calculation.effect';
import { DefinedBenefitEffects } from './effects/defined-benefits.effects';
import { DropPlanEffects } from './effects/drop-plan.effects';
import { Info1099Effects } from './effects/info-1099.effects';
import { LookupTableEffects } from './effects/lookup-table.effects';
import { MemberBenefitEffects } from './effects/member-benefit.effects';
import { MemberDetailEffects } from './effects/member-detail.effects';
import { MemberListEffects } from './effects/member-list.effects';
import { MemberNotificationOptInsEffect } from './effects/member-notification-opt-in.effects';
import { MemberPropertyEffects } from './effects/member-property.effects';
import { MetadataEffects } from './effects/metadata.effects';
import { MunicipalityPaymentEffects } from './effects/municipality-payment.effects';
import { MunicipalityServiceHistoryEffects } from './effects/municipality-service-history.effects';
import { NextPaymentEffects } from './effects/next-payment.effects';
import { NonListConfigurationEffects } from './effects/non-list-configuration.effects';
import { ParticipantRelationshipEffects } from './effects/participant-relationship.effects';
import { ParticipantReportEffect } from './effects/participant-report.effects';
import { PaymentListConfigurationEffects } from './effects/payment-list-configuration.effects';
import { ProfileNavigationConfigurationEffects } from './effects/profile-navigation-configuration.effects';
import { PropertyEffects } from './effects/property.effects';
import { ReportEffects } from './effects/report.effects';
import { StatusHistoryEffects } from './effects/status-history.effects';
import { StatusEffects } from './effects/status.effects';
import { TierConfigurationEffects } from './effects/tier-configuration.effects';
import { MemberRoutingModule } from './member-routing.module';
import { CalculationService } from './services/calculation.service';
import { DefinedBenefitService } from './services/defined-benefits.service';
import { PaymentListConfigurationService } from './services/payment-list-configuration.service';
import { ReportService } from './services/report.service';

@NgModule({
  declarations: [
    MembersListComponent,
    MemberDetailsComponent,
    DemographicComponent,
    MemberProfileSectionComponent,
    BulkContentComponent,
    AddMemberSectionDataComponent,
    OverviewSectionComponent,
    MemberMetadataComponent,
    EditMetadataComponent,
    EditPropertyComponent,
    AddPropertyComponent,
    ModifyPropertyContainerComponent,
    AddSectionComponent,
    AddBenefitTypeComponent,
    OverviewHeaderComponent,
    NextPaymentComponent,
    DeductionDetailComponent,
    PaymentDetailComponent,
    EditEarningsComponent,
    EditPayrollSettingsComponent,
    AddNoteComponent,
    NoteMemoDetailComponent,
    EarningDetailComponent,
    PayrollSettingsDetailComponent,
    PaymentHistoryComponent,
    EditDeductionsComponent,
    PaymentEditPopupComponent,
    PaymentEditBankAccountPopupComponent,
    PaymentHistoryScreenComponent,
    LookupTableComponent,
    LookupTableDetailComponent,
    EditLookupTableComponent,
    EditLookupTableValueComponent,
    UploadLookupTableComponent,
    ReplaceLookupTableValueComponent,
    StatusComponent,
    EditStatusComponent,
    StatusDetailComponent,
    EditEventComponent,
    EditMemberStatusEventComponent,
    ProfileOverviewConfigurationComponent,
    PropertyDisplayConfigurationComponent,
    ProfileHeaderConfigurationComponent,
    MemberListConfigurationComponent,
    AddMemberFilterComponent,
    SaveMemberFilterComponent,
    ManageMemberFilterComponent,
    ProfileNavigationConfigurationComponent,
    AddMenuComponent,
    ProfileNavigationConfigurationDetailComponent,
    EditProfileNavigationComponent,
    EditProfileNavigationItemComponent,
    MemberNavigationComponent,
    DefaultSectionComponent,
    AggregationConfigurationComponent,
    AddAggregationComponent,
    MemberNavigationDetailComponent,
    MemberNavigationListComponent,
    StatusHistoryComponent,
    MunicipalityPaymentComponent,
    MunicipalityServiceHistoryComponent,
    EditMemberServiceHistoryComponent,
    MemberSectionAttachmentComponent,
    EditMemberStatusHistoryComponent,
    MemberNavigationAttachmentListComponent,
    AggregationGroupConfigurationComponent,
    AggregationGroupModalComponent,
    MemberAttachmentModalComponent,
    ProfileOverviewConfigurationDetailComponent,
    ProfileHeaderConfigurationListComponent,
    GroupAggregationComponent,
    MunicipalityGroupAggregationComponent,
    ParticipantSearchConfigurationComponent,
    MembersBenefitEligibleComponent,
    EstablishBenefitComponent,
    DefinedBenefitsComponent,
    ParticipantRelationshipComponent,
    OrganizationChartComponent,
    ParticipantDetailDialogComponent,
    PendingPaymentComponent,
    TruncateTextDirective,
    OptInComponent,
    PaymentInformationConfigurationComponent,
    ManageMemberAttachmentComponent,
    ListStatusComponent,
    EditBenefitComponent,
    PaymentListConfigurationComponent,
    StatusHistoryAuditComponent,
    CalculationListComponent,
    AddCalculationComponent,
    TierConfigurationComponent,
    NonListConfigurationComponent,
    EditTierConfigurationComponent,
    MemberNavigationDetailNonlistComponent,
    ReportCategoriesComponent,
    GeneratedReportComponent,
    ManageReportComponent,
    ReportTemplateComponent,
    ReportConfigComponent,
    DropPlanComponent,
    EditDropPlanComponent,
    ReportDetailComponent,
    Info1099Component,
    DropStatementComponent,
    DropStatementPopupComponent,
    EmailComponent,
  ],
  imports: [
    MemberRoutingModule,
    SharedModule,
    ControlsModule,
    BulkUpdateModule,
    PtgReportingModule,
    /**
     * StoreModule.forFeature is used for composing state
     * from feature modules. These modules can be loaded
     * eagerly or lazily and will be dynamically added to
     * the existing state.
     */
    StoreModule.forFeature(
      fromDemos.memberModuleFeatureKey,
      fromDemos.reducers,
    ),
    /**
     * Effects.forFeature is used to register effects
     * from feature modules. Effects can be loaded
     * eagerly or lazily and will be started immediately.
     *
     * All Effects will only be instantiated once regardless of
     * whether they are registered once or multiple times.
     */
    EffectsModule.forFeature([
      MemberListEffects,
      ReportEffects,
      MemberDetailEffects,
      MetadataDetailEffects,
      MemberBenefitEffects,
      DefinedBenefitEffects,
      MetadataEffects,
      PropertyEffects,
      NextPaymentEffects,
      LookupTableEffects,
      StatusEffects,
      ProfileOverviewConfigurationEffects,
      ProfileHeaderConfigurationEffects,
      MemberListConfigurationEffects,
      MemberNavigationEffects,
      ProfileNavigationConfigurationEffects,
      AggregationConfigEffects,
      StatusHistoryEffects,
      MunicipalityPaymentEffects,
      MunicipalityServiceHistoryEffects,
      AggregationGroupEffects,
      MemberPropertyEffects,
      ParticipantSearchConfigurationEffects,
      ParticipantRelationshipEffects,
      MemberNotificationOptInsEffect,
      NonListConfigurationEffects,
      PaymentListConfigurationEffects,
      CalculationEffects,
      TierConfigurationEffects,
      ParticipantReportEffect,
      DropPlanEffects,
      Info1099Effects,
    ]),
    ViewValueModule,
    ViewCurrentFilterModule,
    CheckPermissionModule,
  ],
  providers: [
    DefinedBenefitService,
    CalculationService,
    PaymentListConfigurationService,
    DatePipe,
    ReportService,
  ],
})
export class MemberModule {}
