import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';

import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

import * as fromMember from '../../reducers';
import * as StatusHistoryActions from '../../actions/status-history.action';
import {
  CreateMemberStatusRequest,
  EditMemberStatusRequest,
  MemberStatus,
  MetadataSection,
  StatusHistory,
} from '../../types/models';
import { MemberDetailActions } from '../../actions';

@Component({
  selector: 'ptg-edit-member-status-history',
  templateUrl: './edit-member-status-history.component.html',
  styleUrls: ['./edit-member-status-history.component.scss'],
})
export class EditMemberStatusHistoryComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();
  listOptionStatus!: Option[];
  listOptionEvent!: Option[];
  listMemberStatus!: MemberStatus[];
  maxDate: Date = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string;
      viewName: string;
      statusHistory: StatusHistory;
      isRedirectFromOverview?: boolean;
      sectionData?: MetadataSection;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditMemberStatusHistoryComponent>,
    private memberStore: Store<fromMember.MemberState>
  ) {}

  ngOnInit(): void {
    this.memberStore.dispatch(StatusHistoryActions.getMemberStatusList());
    this.memberStore
      .pipe(
        select(fromMember.selectListMemberStatusState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((listMemberStatus) => {
        this.listMemberStatus = listMemberStatus.filter(
          (x) => x.id === this.data.statusHistory?.statusId || x.active
        );
        this.setListOption();
        this.initFormGroup(this.data.statusHistory);
      });
  }

  initFormGroup(formData: StatusHistory) {
    const initStatusDate = this.data.statusHistory?.statusDate
      ? (DateTime.fromISO(this.data.statusHistory.statusDate) as DateTime)
      : null;
    this.editForm = this.fb.group({
      id: this.fb.control(formData?.id),
      statusDate: this.fb.control(initStatusDate, [Validators.required]),
      status: this.fb.control(formData?.statusId, [Validators.required]),
      statusEvent: this.fb.control(formData?.eventId, [Validators.required]),
    });
  }

  onSubmit() {
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }

    const formData = this.editForm.value;
    if (formData.id) {
      const body: EditMemberStatusRequest = {
        id: formData.id,
        memberId: this.data.memberId,
        statusId: formData.status,
        eventId: formData.statusEvent,
        statusDate: (formData.statusDate as DateTime)
          .toUTC(0, { keepLocalTime: true })
          .toISO(),
      };
      this.data.isRedirectFromOverview
        ? this.memberStore.dispatch(
            MemberDetailActions.editSectionDataFixedStatusSection({
              body,
              sectionData: this.data.sectionData!,
            })
          )
        : this.memberStore.dispatch(
            StatusHistoryActions.editMemberStatusHistoryAction({ body })
          );
    } else {
      const body: CreateMemberStatusRequest = {
        memberId: this.data.memberId,
        statusId: formData.status,
        eventId: formData.statusEvent,
        statusDate: (formData.statusDate as DateTime)
          .toUTC(0, { keepLocalTime: true })
          .toISO(),
      };
      this.data.isRedirectFromOverview
        ? this.memberStore.dispatch(
            MemberDetailActions.addSectionDataFixedStatusSection({
              body,
              sectionData: this.data.sectionData!,
            })
          )
        : this.memberStore.dispatch(
            StatusHistoryActions.createMemberStatusHistoryAction({ body })
          );
    }
    this.dialogRef.close();
  }

  setListOption() {
    this.listOptionStatus = this.listMemberStatus.map((item) => {
      return {
        value: item.id,
        displayValue: item.name,
      } as Option;
    });
    if (this.data.statusHistory) {
      const memberStatus = this.listMemberStatus.find(
        (x) => x.id === this.data.statusHistory.statusId
      );
      this.listOptionEvent =
        memberStatus?.memberEvent
          ?.filter(
            (item) =>
              item.id === this.data.statusHistory?.eventId || item.active
          )
          ?.map((item) => {
            return {
              value: item.id,
              displayValue: item.name,
            } as Option;
          }) || [];
    }
  }

  onChangeStatusValue() {
    this.editForm.controls.statusEvent.setValue(null);
    const memberStatus = this.listMemberStatus.find(
      (x) => x.id === this.editForm.value.status
    );
    this.listOptionEvent =
      memberStatus?.memberEvent
        ?.filter(
          (item) => item.id === this.data.statusHistory?.eventId || item.active
        )
        ?.map((item) => {
          return {
            value: item.id,
            displayValue: item.name,
          } as Option;
        }) || [];
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
