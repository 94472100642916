import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ACTION } from '@ptg-shared/constance/value.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';

import * as fromReducer from '../../../reducers';
import * as fromMember from '../../reducers';
import * as MemberListConfigurationActions from '../../actions/member-list-configuration.actions';
import {
  MemberNavigationItem,
  MemberNavigationResponse,
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
  SectionConfig,
} from '../../types/models';

@Component({
  selector: 'ptg-member-list-configuration',
  templateUrl: './member-list-configuration.component.html',
  styleUrls: ['./member-list-configuration.component.scss'],
})
export class MemberListConfigurationComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Participant List Configuration',
      url: '',
    },
  ];
  propertyConfigs!: PropertyDisplayConfig[];
  propertyDisplayConfigurations!: PropertyDisplayConfiguration[];
  addPropertySection: SectionConfig = {
    title: 'Add Column',
    columnName: 'Column Name',
  };
  sortPropertySection: SectionConfig = { title: 'Order Columns' };
  sortRowSection: SectionConfig = { title: 'Sort Rows' };
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  memberNavigationItem: MemberNavigationItem | undefined;
  isMemberListConfiguration!: boolean;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCurrentPageConfig();

    this.memberStore
      .pipe(
        select(fromMember.selectPropertyConfigs),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyConfigs) => {
        this.propertyConfigs = propertyConfigs;
      });
    this.memberStore
      .pipe(
        select(fromMember.selectPropertyDisplayConfigurations),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyDisplayConfigurations) => {
        this.propertyDisplayConfigurations = JSON.parse(
          JSON.stringify(propertyDisplayConfigurations)
        ) as PropertyDisplayConfiguration[];
      });
    this.memberStore
      .pipe(select(fromMember.selectUpdateState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.memberStore.dispatch(
          MemberListConfigurationActions.getMemberListConfiguration({
            id: this.memberNavigationItem?.id,
          })
        );
        this.memberStore.dispatch(
          MemberListConfigurationActions.clearMemberListConfigurationState()
        );
        showBanner.call(
          this,
          state,
          this.memberNavigationItem?.name || 'Participant List',
          ACTION.EDIT
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit(listMetadataMemberListConfig: PropertyDisplayConfiguration[]) {
    this.memberStore.dispatch(
      MemberListConfigurationActions.setMemberListConfiguration({
        body: {
          listMetadataMemberListConfig,
          memberNavigationItemId: this.memberNavigationItem?.id,
        },
      })
    );
    if (this.memberNavigationItem) {
      return;
    }
    this.memberStore
      .pipe(select(fromMember.selectMemberNavigationState), take(1))
      .subscribe((navigationState) => {
        if (navigationState.query?.pageIndex) {
          this.store.dispatch(
            LayoutActions.closeTab({
              tabName: DisplayedTabName.IndividualParticipant,
            })
          );
        }
      });
  }

  getCurrentPageConfig() {
    combineLatest([
      this.route.params,
      this.store.select(fromLayoutReducer.getMemberNavigationListSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.store.dispatch(LayoutActions.hiddenSideMenu());
        const currentMenu = data[0];
        const memberNavigationMenu: MemberNavigationResponse[] =
          data[1]?.payload?.memberNavigationList?.memberNavigationMenu ?? [];
        const currentNavigationMenu = memberNavigationMenu?.find((menu) =>
          menu?.menuItem?.some((item) => item.id === currentMenu.menuId)
        );
        const memberNavigationItem = currentNavigationMenu?.menuItem?.find(
          (navigation) => navigation.id === currentMenu.menuId
        );
        this.memberNavigationItem = memberNavigationItem;
        this.memberStore.dispatch(
          MemberListConfigurationActions.getPropertyList({
            itemKey: memberNavigationItem?.itemKey,
          })
        );
        this.memberStore.dispatch(
          MemberListConfigurationActions.getMemberListConfiguration({
            id: this.memberNavigationItem?.id,
          })
        );
        if (data[0]?.menuId) {
          this.handleSectionListTypeConfiguration(currentMenu);
          return;
        }
        this.handleMemberListConfiguration();
      });
  }

  handleSectionListTypeConfiguration(currentMenu: any) {
    this.listBreadcrumbs = [
      {
        name: this.memberNavigationItem?.name || '',
        url: `/member/navigation/${currentMenu.sectionType}/${currentMenu.menuId}/${currentMenu.memberId}`,
      },
      {
        name: `${this.memberNavigationItem?.name || ''} List Configuration`,
        url: '',
      },
    ];
  }

  handleMemberListConfiguration() {
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
  }
}
