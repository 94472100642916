<div class="edit-metadata">
  <ng-container *ngIf="!isLoading">
    <div class="header-container">
      <ptg-breadcrumb
        [listBreadcrumbs]="listBreadcrumbs"
        [controlField]="sectionName"
        [functionButtons]="
          sectionLayout?.value === ListLayout ? functionButtons : undefined
        "
        (emitFunction)="comeAggregation()"
        (editName)="editSectionName($event)"
        (onClickBreadcrumb)="onClickBreadcrumb($event)"
      ></ptg-breadcrumb>
      <div *ngIf="sectionLayout.value === ListLayout" class="list-layout-label">
        <p>Is a List</p>
      </div>
    </div>

    <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>

      <div class="box">
        <div class="properties-title-container">
          <p class="label-small">Metadata Configuration</p>
        </div>
        <div class="metadata-config-container">
          <div class="full-width">
            <ptg-toggle-button
              [controlField]="includeInBulkUpdate"
              label="Include in Bulk Update"
              (valueChange)="changeToggleBulkUpdate()"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
          </div>
          <ng-container *ngIf="includeInBulkUpdate.value">
            <ng-container *ngIf="isEditBulk; else textBulk">
              <div class="import-label">
                <ptg-input-text
                  [controlField]="importLabel"
                  [hasLabel]="true"
                  placeholder="Import Label"
                  errorRequire="Import Label is required."
                  errorAsync="Import Label already exists."
                ></ptg-input-text>
                <span class="close-check">
                  <ptg-button
                    buttonName=""
                    classInput="yesno-button"
                    (clickButton)="editBulkUpdate('close')"
                  >
                    <mat-icon>close</mat-icon>
                  </ptg-button>
                  <ptg-button
                    buttonName=""
                    classInput="yesno-button"
                    (clickButton)="editBulkUpdate('save')"
                  >
                    <mat-icon style="color: #196f57">check</mat-icon>
                  </ptg-button>
                </span>
              </div>
            </ng-container>
            <ng-template #textBulk>
              <div class="import-label">
                <span class="import-label-value">{{ importLabelValue }}</span>
                <ptg-button
                  buttonName=""
                  classInput="edit-button"
                  (clickButton)="isEditBulk = true"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </div>
            </ng-template>
          </ng-container>
          <div class="full-width">
            <ptg-toggle-button
              [controlField]="includeAttachment"
              label="Include a File Attachment"
              (valueChange)="storeDB('hasAttachment', includeAttachment.value)"
            ></ptg-toggle-button>
          </div>
          <div
            *ngIf="sectionLayout.value !== ListLayout"
            class="section-layout-selector"
          >
            <p class="section-label">Section Layout</p>
            <ptg-select
              (changeOptionValue)="storeDB('type', sectionLayout.value)"
              [controlField]="sectionLayout"
              [isOptionObj]="true"
              [listData]="layoutOptions"
              [isCheckChange]="true"
            ></ptg-select>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="properties-title-container">
          <p class="label-small right-separator">Metadata Properties</p>
          <ptg-button
            buttonName="New Property"
            classInput="add-property-button"
            (clickButton)="addProperty()"
          >
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>

        <ptg-grid
          [data]="metaDatasData"
          [columns]="columns"
          keyColumn="key"
          [isLoading]="isLoading"
          [errorMessage]="errorMsg"
          [paginable]="false"
          [allowDragDrop]="true"
          (rowDrop)="changeItemIndex($event)"
          notFoundMessage="No Property to Display"
        >
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="editProperty(row)"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              *ngIf="!row.isStatic"
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onRemoveClick(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </ng-container>

  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
