<div class="participant-detail-dialog-container">
  <div class="dialog-title-container">
    <div class="dialog-title-information">
      <span class="dialog-title">Participant Detail</span>
      <div class="view-profile-container" (click)="viewProfile()">
        <mat-icon>person</mat-icon>
        <span>View Profile</span>
      </div>
    </div>
    <div class="close-dialog-container" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="participant-detail-container">
    <div class="detail-item" *ngFor="let item of detailData">
      <span class="label">{{ item.label }}</span>
      <span class="value">
        <ptg-view-value [data]="{ value: item.value, type: item.type, typedValue: item?.typedValue }" [isSeparateLineAddress]="true"></ptg-view-value>
      </span>
    </div>
  </div>
</div>
