import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dengerous, destructIcon, cancelIcon, deleteIcon, waringIcon, checkCircle } from '../../constance/listIcons.const';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmType } from '../../constance/confirm-type.const';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'ptg-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  ConfirmType = ConfirmType;
  title: string = '';
  icon: string = '';
  cancelButtonTitle: string = '';
  saveButtonTitle: string = 'Save';
  displayCheckBox = new FormControl(false);

  constructor(
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      text: string;
      type?: ConfirmType;
      title?: string;
      confirm?: boolean,
      cancelButtonTitle?: string,
      hideConfirmButton?: boolean,
      haveDontShowAgainCheckbox?: boolean,
      noTruncate?: boolean,
      saveButtonTitle?: string,
      hideSaveAsButton?: boolean,
    },
  ) {
    this.data.confirm = true;
    iconRegistry.addSvgIconLiteral('dengerous', sanitizer.bypassSecurityTrustHtml(dengerous));
    iconRegistry.addSvgIconLiteral('cancelIcon', sanitizer.bypassSecurityTrustHtml(cancelIcon));
    iconRegistry.addSvgIconLiteral('deleteIcon', sanitizer.bypassSecurityTrustHtml(deleteIcon));
    iconRegistry.addSvgIconLiteral('destructIcon', sanitizer.bypassSecurityTrustHtml(destructIcon));
    iconRegistry.addSvgIconLiteral('waringIcon', sanitizer.bypassSecurityTrustHtml(waringIcon));
    iconRegistry.addSvgIconLiteral('checkCircle', sanitizer.bypassSecurityTrustHtml(checkCircle));
  }

  ngOnInit(): void {
    this.setPopupInfo();
    this.setCancelButtonTitle();
  }

  setPopupInfo() {
    this.title = this.data.title ? this.data.title : '';
    this.cancelButtonTitle = this.data.cancelButtonTitle || '';
    if (this.data.saveButtonTitle) {
      this.saveButtonTitle = this.data.saveButtonTitle;
    }
    switch (this.data.type) {
      case ConfirmType.Cancel:
      case ConfirmType.CancelPopup:
        this.icon = 'cancelIcon';
        this.title = this.data.title ? this.data.title : 'Cancel Action';
        break;
      case ConfirmType.Warning:
        this.icon = 'waringIcon';
        break;
      case ConfirmType.Delete:
        this.icon = 'deleteIcon';
        this.title = this.data.title ? this.data.title : 'Remove Action';
        break;
      case ConfirmType.Destruct:
        this.icon = 'destructIcon';
        this.title = 'THIS IS A DESTRUCTIVE ACTION';
        break;
      case ConfirmType.Success:
        this.icon = 'checkCircle';
        break;
      default:
        this.icon = 'waringIcon';
        break;
    }
  }

  setCancelButtonTitle() {
    switch (this.data.type) {
      case ConfirmType.Error:
      case ConfirmType.Confirm:
        this.cancelButtonTitle = this.cancelButtonTitle || 'Close';
        break;
      case ConfirmType.CancelPopup:
        this.cancelButtonTitle = this.cancelButtonTitle ? this.cancelButtonTitle : 'Back';
        break;
      case ConfirmType.Warning:
      case ConfirmType.Destruct:
        this.cancelButtonTitle = this.cancelButtonTitle || 'Cancel';
        break;
      case ConfirmType.Attention:
        this.cancelButtonTitle = this.cancelButtonTitle ? this.cancelButtonTitle : 'No';
        break;
      default:
        this.cancelButtonTitle = this.cancelButtonTitle || 'No';
        break;
    }
  }

  onSaveAsClick() {
    this.dialogRef.close({ isSaveAs: true });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmWithDontShowAgain() {
    this.dialogRef.close({ dontShowAgain: this.displayCheckBox.value });
  }
}
