import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { takeUntil } from 'rxjs/operators';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  ACTION_COLUMN,
  Align,
  Column,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import {
  Breadcrumb,
  FunctionButtonConfig,
  FunctionButtonData,
} from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';

import * as ProfileNavigationConfigurationActions from '../../actions/profile-navigation-configuration.action';
import * as fromMember from '../../reducers';
import * as FundListActions from '../../../fund-list/actions/fund-list.actions';
import { AddMenuComponent } from '../../components/add-menu/add-menu.component';
import {
  ChangeIndexRequest,
  MemberNavigation,
  GetMemberNavigationListResponse,
  MemberNavigationResponse,
} from '../../types/models';

@Component({
  selector: 'ptg-profile-navigation-configuration',
  templateUrl: './profile-navigation-configuration.component.html',
  styleUrls: ['./profile-navigation-configuration.component.scss'],
})
export class ProfileNavigationConfigurationComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
      url: '',
    },
    {
      name: 'Navigation Configuration',
      url: '',
    },
  ];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New Menu',
      icon: 'add',
      classInput: 'add-button',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = false;
  errorMsg: string = '';
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Menu Item',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'totalItem',
      header: {
        title: 'Item Count',
      },
      align: Align.Center,
    },
    {
      name: 'statusList',
      header: {
        title: 'Status',
      },
      style: {
        'max-width': 'unset',
      },
    },
    {
      name: 'color',
      header: {
        title: 'Menu Color',
      },
      align: Align.Center,
    },
    {
      name: 'disabled',
      header: {
        title: 'Disabled',
      },
      align: Align.Center,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  memberNavigationsData!: MemberNavigation[];
  memberId: string = '';

  constructor(
    private route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.listBreadcrumbs[0].url = `/member/detail/${params.id}`;
      this.memberId = params.id;
    });
    this.memberStore.dispatch(
      ProfileNavigationConfigurationActions.getMemberNavigationList()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectProfileNavigationConfigurationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = state.isLoading;

        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }

        this.setDataTable(state.memberNavigations);
        showBanner.call(this, state.createMenuState || '', 'Menu', ACTION.ADD);
        showBanner.call(
          this,
          state.removeMenuState || '',
          'Menu',
          ACTION.REMOVE
        );
        if (state.orderState == STATE.SUCCESS) {
          this.store.dispatch(
            FundListActions.updateFundLayout({
              updateLayout: {
                profileNavigationUpdated: new Date().toUTCString(),
              },
            })
          );
        } else if (
          state.createMenuState == STATE.SUCCESS ||
          state.removeMenuState == STATE.SUCCESS
        ) {
          this.memberStore.dispatch(
            ProfileNavigationConfigurationActions.getMemberNavigationList()
          );
          this.store.dispatch(
            FundListActions.updateFundLayout({
              updateLayout: {
                profileNavigationUpdated: new Date().toUTCString(),
              },
            })
          );
        }
        if (
          state.orderState ||
          state.createMenuState ||
          state.removeMenuState
        ) {
          this.memberStore.dispatch(
            ProfileNavigationConfigurationActions.clearStateMemberNavigation()
          );
        }
      });
  }

  setDataTable(memberNavigationList: GetMemberNavigationListResponse) {
    this.memberNavigationsData = [];
    memberNavigationList.memberNavigationMenu.map(
      (item: MemberNavigationResponse) => {
        const memberNavigation: MemberNavigation = {
          id: item.id,
          name: item.name,
          color: item.color,
          order: item.order,
          disabled: !item.active,
          totalItem: item.totalItem,
          menuItems: item.menuItem,
          statusList: memberNavigationList.listStatus.filter((x) =>
            item.statusList.some(
              (id) => x?.id?.toLowerCase() === id?.toLowerCase()
            )
          ),
        };
        this.memberNavigationsData.push(memberNavigation);
      }
    );
  }

  emitBreadcrumdFunction(event: FunctionButtonData) {
    if (event.buttonName === 'New Menu') {
      this.addMenu();
      return;
    }
  }

  changeItemIndex(event: ReorderInfo) {
    const body: ChangeIndexRequest = {
      upperAdjacentKey: event.upperAdjacentKey,
      reorderPropertyKey: event.reorderItemKey,
    };
    this.store.dispatch(
      ProfileNavigationConfigurationActions.reorderMemberNavigationList({
        body,
      })
    );
  }

  removeRecord(memberNavigation: MemberNavigation & Row) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Menu',
        text: `Are you sure you want to remove this Menu?`,
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          ProfileNavigationConfigurationActions.removeMemberNavigationRequest({
            menuId: memberNavigation.id,
          })
        );
      }
    });
  }

  editRecord(memberNavigation: MemberNavigation & Row) {
    this.router.navigateByUrl(
      `/member/profile-navigation-configuration/${this.memberId}/detail/${memberNavigation.id}`
    );
  }

  addMenu() {
    this.dialog.open(AddMenuComponent, {
      panelClass: 'add-menu-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
    });
  }
}
