import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { deepClone } from '@ptg-shared/utils/common.util';
import { BaseComponent } from '@ptg-shared/components';
import { Column, Row, ColumnType, Align } from '@ptg-shared/controls/grid';
import {
  setFractionalLength,
  stringToBoolean,
  toFixedTrunc,
} from '@ptg-shared/utils/string.util';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { dispatchGetMemberNavigationList } from '@ptg-shared/layout/helpers';
import * as fromReducer from '@ptg-reducers';

import { getColumConfig } from '../../helpers';
import { ICON_STATUS_FIELDS } from '../../constance/member-list.const';
import {
  FixedSection,
  FixedSectionName,
  MenuItemName,
  SectionLayout,
} from '../../constance/metadata.const';
import { DisplaySectionWODataType } from '../../types/enums';
import {
  MemberDetail,
  MemberDetailWithPaging,
  MemberStatus,
  MetadataOverView,
  MetadataProperty,
  MetadataSection,
} from '../../types/models';
import { MemberDetailActions } from '../../actions';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import * as fromMember from '../../reducers';

const PAGE_SIZE_CONST = '-ptg-member-profile-section-pageSize';

@Component({
  selector: 'ptg-member-profile-section',
  templateUrl: './member-profile-section.component.html',
  styleUrls: ['./member-profile-section.component.scss'],
})
export class MemberProfileSectionComponent extends BaseComponent {
  readonly DisplaySectionWODataType = DisplaySectionWODataType;
  currentFund: any = {};
  columns: Column[] = [];
  propertiesConfig: MetadataProperty[] = [];
  dataSection: any;
  attachment: any;
  sectionLayout = SectionLayout;
  FixedSection = FixedSection;
  sortInfo: {} | any = {};
  lengthPg!: number | any;
  pageSize: number = 10;
  pageNumber: number = FIRST_PAGE;
  isMetadataSection?: boolean;
  isList?: boolean = false;
  isShowMenuButton: boolean = true;
  notFoundMessage: string = '';
  isDragDrop: boolean | undefined = false;

  @Input() configSection!: MetadataSection;
  @Input() memberId!: string;
  @Input() memberStatus: MemberStatus | undefined;
  @Input() profileOverviewConfig: MetadataOverView | undefined;
  @Output() deleteSectionEvent = new EventEmitter();
  @Output() downloadPdfEvent = new EventEmitter();
  @Output() editSectionEvent = new EventEmitter();
  @Output() manageSectionEvent = new EventEmitter();
  @Output() showAttachmentsEvent = new EventEmitter();

  isDemographics: boolean = false;

  constructor(
    private router: Router,
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 10;
        this.currentFund = el;
        this.pageSize =
          Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          ) === 0
            ? this.pageSize
            : Number(
                sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
              );
      });
    this.isMetadataSection =
      this.configSection.key !== FixedSection.Status &&
      this.configSection.key !== FixedSection.ParticipantRelationship &&
      this.configSection.key !== FixedSection.MuniTotal;
    this.isShowMenuButton =
      this.configSection.key !== FixedSection.ParticipantRelationship &&
      this.configSection.key !== FixedSection.ServiceHistory &&
      this.configSection.key !== FixedSection.MuniTotal &&
      this.configSection.sectionTitle !== FixedSectionName.Contributions;
    this.propertiesConfig = this.configSection.properties;
    this.isDemographics = this.configSection.key === 'demographics';
    this.isDragDrop = this.configSection?.isDragDrop;

    combineLatest([
      this.memberStore.pipe(select(fromMember.selectMetadataOverview)),
      this.memberStore.pipe(select(fromMember.selectMemberDetailState)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        const metadataOverview = deepClone(data[0]) as MetadataSection[];
        const memberDetail = deepClone(data[1].memberDetail) as MemberDetail;
        if (memberDetail) {
          this.isList = this.configSection.type === this.sectionLayout.List;
          const value = Object.entries(memberDetail).find(
            ([key, value]) => key === this.configSection.key
          )?.[1];
          const sectionConfig = metadataOverview.find(
            (metadata) => metadata.key === this.configSection.key
          );
          this.attachment = value?.attachment;
          this.lengthPg = value?.total;
          this.drawTable();

          this.dataSection =
            this.configSection.type === this.sectionLayout.List
              ? value?.table.map((el: any, index: number) => {
                  const keys = Object.keys(el);
                  const row = JSON.parse(JSON.stringify(el));
                  keys.forEach((key) => {
                    if (!row[key]) {
                      return;
                    }
                    const property = this.propertiesConfig.find(
                      (ele) => ele.key === key
                    );
                    switch (property?.type) {
                      case 'Decimal': {
                        const valueDecimal = el[key];
                        if (property?.config?.fractionalLengthInput) {
                          const fractionalLength = setFractionalLength(
                            valueDecimal,
                            property?.config?.fractionalLengthInput
                          );
                          row[key] = toFixedTrunc(
                            valueDecimal,
                            fractionalLength || 0
                          );
                        } else {
                          row[key] =
                            valueDecimal % 1
                              ? Number(
                                  valueDecimal.toString().split('.')[0]
                                ).toLocaleString() +
                                '.' +
                                valueDecimal.toString().split('.')[1]
                              : Number(valueDecimal).toLocaleString();
                        }
                        break;
                      }
                      case PropertyType.TYPE_DEPARTMENT:
                      case PropertyType.TYPE_EMPLOYER: {
                        row[key] = property?.options?.find(
                          (op: any) => op.id === row[key]
                        )?.text;
                        break;
                      }
                      case PropertyType.TYPE_LIST: {
                        row[key] = property?.options?.find(
                          (op: any) => op.id === row[key]
                        )?.description;
                        break;
                      }
                      case PropertyType.TYPE_BOOLEAN: {
                        //Removed condition for Ticket 141711
                        row[key] = row[key];
                        break;
                      }
                      case PropertyType.TYPE_PERCENTAGE: {
                        row[key] = +row[key];
                        break;
                      }
                      case PropertyType.TYPE_PERSON_NAME: {
                        row[key].prefix = property?.options?.find(
                          (op: any) => op.id === row[key].prefix
                        )?.description;
                        break;
                      }
                      case PropertyType.TYPE_ADDRESS: {
                        row[key].state = property?.options?.find(
                          (op: any) => op.id === row[key]?.state
                        )?.description;
                        row[key].country = property?.options?.find(
                          (op: any) => op.id === row[key]?.country
                        )?.description;
                        break;
                      }
                    }
                  });
                  if (
                    this.configSection.key ===
                    FixedSection.ParticipantRelationship
                  ) {
                    row.url = `/member/detail/${row.participantId}`;
                  }
                  if (this.configSection.key === FixedSection.ServiceHistory) {
                    row.recordId = row.id;
                  }
                  return {
                    ...row,
                    id: index + 1,
                  };
                })
              : this.getValueByPropertyConfig(value, sectionConfig);

          if (this.dataSection) {
            if (this.configSection.type === this.sectionLayout.List) {
              this.dataSection = [...this.dataSection].map((item) => {
                return {
                  ...item,
                  [ICON_STATUS_FIELDS.statusIconName]:
                    item._typedValue?.iconName,
                  [ICON_STATUS_FIELDS.statusIconColor]: item._typedValue?.color,
                  [ICON_STATUS_FIELDS.statusIconLabel]: item._typedValue?.name,
                };
              });
            } else {
              this.dataSection = { ...this.dataSection };
            }
            this.propertiesConfig =
              this.configSection.type === this.sectionLayout.List
                ? this.propertiesConfig
                : this.configSection.properties;

            if (this.configSection.type !== this.sectionLayout.List) {
              this.propertiesConfig = this.propertiesConfig.map(
                (property: MetadataProperty) => {
                  let item = { ...property };
                  if (item.key === PropertyType.TYPE_ATTACHMENT){
                    item.value = this.dataSection.totalAttachment;
                  }
                  else  {
                    item.value = this.dataSection[property.key];
                  }
                  
                  return item;
                }
              );
            }

            if (Array.isArray(this.dataSection)) {
              this.dataSection.forEach((item) => this.getColumnData(item));
            } else {
              this.getColumnData(this.dataSection);
            }
          }
        }
      });
  }

  getColumnData(item: any) {
    Object.keys(item).forEach((propertyName) => {
      const config = this.propertiesConfig.find(
        (config) => propertyName === config.key
      );
      if (!config) {
        return;
      }
    });
  }

  ngAfterViewInit() {
    if (this.configSection.type === this.sectionLayout.List) {
      const tableWidth = document
        .getElementsByClassName('demo-content')[0]
        ?.getBoundingClientRect()?.width;
      if (tableWidth) {
        const columnWidth = tableWidth / this.columns.length;
        const elements = document.getElementsByClassName('mat-header-cell');
        for (let i = 0; i < elements.length; i++) {
          const div = elements[i];
          div.setAttribute('style', 'max-width:' + (columnWidth - 20) + 'px');
        }
        const tds = document.getElementsByClassName('mat-cell');
        for (let i = 0; i < tds.length; i++) {
          const div = tds[i];
          div.setAttribute('style', 'max-width:' + (columnWidth - 35) + 'px');
        }
      }
    }
  }

  deleteSection() {
    this.deleteSectionEvent.emit(this.configSection);
  }

  editSection() {
    if (this.configSection.properties && this.configSection.properties.find(prop => prop.type === PropertyType.TYPE_ATTACHMENT)){
      this.configSection.properties = this.configSection.properties.filter(prop=>prop.type !== PropertyType.TYPE_ATTACHMENT)
    }

    this.editSectionEvent.emit(this.configSection);
  }

  manageSection() {
    this.manageSectionEvent.emit(this.configSection);
  }

  downloadPdf(statement: any) {
    this.downloadPdfEvent.emit(statement);
  }

  showAttachments(rowId?: any) {
    this.configSection.rowId = rowId;
    this.showAttachmentsEvent.emit(this.configSection);
  }

  drawTable() {
    this.columns = [];
    this.propertiesConfig
      .forEach((el: any) => {
        const columConfig = getColumConfig(el.type, el.config);
        if (this.lengthPg > 0) {
          this.columns.push({
            name: el.key,
            header: {
              title: el.name,
            },
            truncate: true,
            sortable: this.isMetadataSection ? false : true,
            type: columConfig.type,
            templateArgs: columConfig.templateArgs,
          });
        } else {
          if (
            this.profileOverviewConfig?.displayListSectionWOData ===
            DisplaySectionWODataType.TitleAndProperties_IfAny
          ) {
            this.columns.push({
              name: el.key,
              header: {
                title: el.name,
              },
              truncate: true,
              sortable: this.isMetadataSection ? false : true,
              type: columConfig.type,
              templateArgs: columConfig.templateArgs,
            });
          }
        }
      });

      if (this.configSection.hasAttachment)
      {
        this.columns.push({
          name: "noOfAttachments",
          header: {
            title: PropertyType.TYPE_ATTACHMENT,
          },
          truncate: true,
          align: Align.Right,
          sortable: this.isMetadataSection ? false : true
        });

        if(this.propertiesConfig && !this.propertiesConfig.find(config => config.type === PropertyType.TYPE_ATTACHMENT)){
          const t: MetadataProperty = { 
            key: PropertyType.TYPE_ATTACHMENT,
            name: PropertyType.TYPE_ATTACHMENT,
            importKey: "",
            type: PropertyType.TYPE_ATTACHMENT,
            config: "",
            options: [],
            isStatic: false
          }

          this.propertiesConfig.push(t);
        }
      }

    this.notFoundMessage =
      this.profileOverviewConfig?.displayListSectionWOData !==
      DisplaySectionWODataType.TitleOnly
        ? 'No Data to Display'
        : '';
  }

  getValueByPropertyConfig(
    sectionData: any,
    sectionConfig: MetadataSection | undefined
  ) {
    if (!sectionConfig) {
      return sectionData;
    }
    const personNameProperties = (
      deepClone(sectionConfig) as MetadataSection
    ).properties.filter(
      (property) => property.type === PropertyType.TYPE_PERSON_NAME
    );
    const result = deepClone(sectionData);
    if (personNameProperties.length) {
      personNameProperties.forEach((property) => {
        if (!result?.[property?.key]) {
          return;
        }
        result[property.key].prefix = property.options?.find(
          (option) => option.id === result[property.key].prefix
        )?.description;
      });
    }
    return result;
  }

  onSortChange(event: any) {
    this.sortInfo = event;
    this.getMemberSection();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageSize.toString()
    );
    this.getMemberSection();
  }

  onRowClick(row: any & Row) {
    dispatchGetMemberNavigationList(this.store, row.memberId!);

    this.memberStore
      .pipe(
        select(fromLayoutReducer.getMemberNavigationListSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.payload?.memberId) {
          let pageHaveMuniService =
            state.payload.memberNavigationList.memberNavigationMenu.find((x) =>
              x.menuItem.find((o) => o.itemName === MenuItemName.ServiceHistory)
            );
          if (pageHaveMuniService) {
            let menuItemName = pageHaveMuniService.menuItem.find(
              (x) => x.itemName === MenuItemName.ServiceHistory
            )?.name;
            let menuItem = state.payload.menu.find((x) =>
              x.menuItems?.find((o) => o.name === menuItemName)
            );
            if (menuItem) {
              let urlServiceHistory =
                menuItem.menuItems?.find((x) => x.name === menuItemName)
                  ?.routerLink +
                state.payload.memberId +
                `?recordId=${row.recordId}&pageSize=${this.pageSize}&pageIndex=${this.pageNumber}`;
              this.memberStore.dispatch(
                LayoutActions.selectTab({
                  tab: DisplayedTabName.IndividualParticipant,
                  url: urlServiceHistory,
                })
              );
            }
          }
        }
      });
  }

  getMemberSection() {
    let sortType = 1;
    let sortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames =
        this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.slice(1);
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: MemberDetailWithPaging = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MemberDetailActions.getSectionData({
        memberId: this.memberId,
        sectionKey: this.configSection.key,
        query,
      })
    );
  }

  navigateByUrl(row: any & Row) {
    void this.router.navigateByUrl(row.url);
  }
}
