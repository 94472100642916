import { createAction, props } from '@ngrx/store';

import {
  MemberListQuery,
  MembersBenefitEstablish,
  MembersBenefitList,
  MembersBenefitType,
} from '../types/models';

export const GetMemberBenefitEligibleRequest =
  '[GetMemberBenefitEligible/API] Send Request';
export const GetMemberBenefitEligibleSuccess =
  '[GetMemberBenefitEligible/API] Success';
export const GetMemberBenefitEligibleFailure =
  '[GetMemberBenefitEligible/API] Failure';

export const EstablishMemberBenefitRequest =
  '[EstablishMemberBenefit/API] Send Request';
export const EstablishMemberBenefitSuccess =
  '[EstablishMemberBenefit/API] Success';
export const EstablishMemberBenefitFailure =
  '[EstablishMemberBenefit/API] Failure';

export const GetMemberBenefitTypeRequest =
  '[GetMemberBenefitType/API] Send Request';
export const GetMemberBenefitTypeSuccess = '[GetMemberBenefitType/API] Success';
export const GetMemberBenefitTypeFailure = '[GetMemberBenefitType/API] Failure';
export const ClearMemberBenefitState = 'Clear Member Benefit State';

export const getMemberBenefitEligible = createAction(
  GetMemberBenefitEligibleRequest,
  props<{ query: MemberListQuery; idClient: string }>()
);
export const getMemberBenefitEligibleSuccess = createAction(
  GetMemberBenefitEligibleSuccess,
  props<{ memberBenefitList: MembersBenefitList }>()
);
export const getMemberBenefitEligibleFailure = createAction(
  GetMemberBenefitEligibleFailure,
  props<{ errorMsg: string }>()
);
export const establishMemberBenefit = createAction(
  EstablishMemberBenefitRequest,
  props<{ memberId: string; body: MembersBenefitEstablish }>()
);
export const establishMemberBenefitSuccess = createAction(
  EstablishMemberBenefitSuccess,
  props<{ memberId: string }>()
);
export const establishMemberBenefitFailure = createAction(
  EstablishMemberBenefitFailure,
  props<{ errorMsg: string }>()
);

export const getMemberBenefitType = createAction(
  GetMemberBenefitTypeRequest,
  props<{ memberId: any; memberBenefitType: MembersBenefitType }>()
);
export const getMemberBenefitTypeSuccess = createAction(
  GetMemberBenefitTypeSuccess,
  props<{ memberBenefitType: MembersBenefitType }>()
);
export const getMemberBenefitTypeFailure = createAction(
  GetMemberBenefitTypeFailure,
  props<{ errorMsg: string }>()
);
export const clearMemberBenefitState = createAction(ClearMemberBenefitState);
