import { createAction, props } from '@ngrx/store';

import {
  NextPayment,
  ServiceProviderActionState,
  SetPaymentStepBody,
} from '../types/models';

export const VendorPaymentStateClear = '[VendorPaymentState] Clear';
export const VendorPaymentStateReset = '[VendorPaymentState] Reset';

export const VendorPaymentRequest = '[VendorPayment/API] Send Request';
export const VendorPaymentSuccess = '[VendorPayment/API] Success';
export const VendorPaymentFailure = '[VendorPayment/API] Failure';

export const SetPaymentStepRequest = '[SetPaymentStep/API] Send Request';
export const SetPaymentStepSuccess = '[SetPaymentStep/API] Success';
export const SetPaymentStepFailure = '[SetPaymentStep/API] Failure';

export const UpdateStartingCheckNumberRequest =
  '[UpdatePaymentStartingCheckNumber/API] Send Request';
export const UpdateStartingCheckNumberSuccess =
  '[UpdatePaymentStartingCheckNumber/API] Success';
export const UpdateStartingCheckNumberFailure =
  '[UpdatePaymentStartingCheckNumber/API] Failure';

export const UpdateDepositDateRequest =
  '[UpdatePaymentDepositDate/API] Send Request';
export const UpdateDepositDateSuccess =
  '[UpdatePaymentDepositDate/API] Success';
export const UpdateDepositDateFailure =
  '[UpdatePaymentDepositDate/API] Failure';

export const UpdateWarrantNumberRequest =
  '[UpdatePaymentWarrantNumber/API] Send Request';
export const UpdateWarrantNumberSuccess =
  '[UpdatePaymentWarrantNumber/API] Success';
export const UpdateWarrantNumberFailure =
  '[UpdatePaymentWarrantNumber/API] Failure';

export const NextPaymentListRequest = '[NextPaymentList/API] Send Request';
export const NextPaymentListSuccess = '[NextPaymentList/API] Success';
export const NextPaymentListFailure = '[NextPaymentList/API] Failure';

export const UpdateTransactionRequest =
  '[UpdateTransactionPayment/API] Send Request';
export const UpdateTransactionSuccess =
  '[UpdateTransactionPayment/API] Success';
export const UpdateTransactionFailure =
  '[UpdateTransactionPayment/API] Failure';

export const UploadWarrantRequest = '[UploadWarrantPayment/API] Send Request';
export const UploadWarrantSuccess = '[UploadWarrantPayment/API] Success';
export const UploadWarrantFailure = '[UploadWarrantPayment/API] Failure';

export const SetNextPaymentBoardBankRequest =
  '[SetNextPaymentBoardBankPayment/API] Send Request';
export const SetNextPaymentBoardBankSuccess =
  '[SetNextPaymentBoardBankPayment/API] Success';
export const SetNextPaymentBoardBankFailure =
  '[SetNextPaymentBoardBankPayment/API] Failure';

export const AchSentRequest = '[AchSentPayment/API] Send Request';
export const AchSentSuccess = '[AchSentPayment/API] Success';
export const AchSentFailure = '[AchSentPayment/API] Failure';

export const ChecksPrintedRequest = '[ChecksPrintedPayment/API] Send Request';
export const ChecksPrintedSuccess = '[ChecksPrintedPayment/API] Success';
export const ChecksPrintedFailure = '[ChecksPrintedPayment/API] Failure';

export const EditCheckNumberRequest =
  '[EditCheckNumberPayment/API] Send Request';
export const EditCheckNumberSuccess = '[EditCheckNumberPayment/API] Success';
export const EditCheckNumberFailure = '[EditCheckNumberPayment/API] Failure';

export const FinalizePaymentRequest = '[FinalizePayment/API] Send Request';
export const FinalizePaymentSuccess = '[FinalizePayment/API] Success';
export const FinalizePaymentFailure = '[FinalizePayment/API] Failure';

export const GetMemberAddressRequest = '[GetMemberAddress/API] Send Request';
export const GetMemberAddressFailure = '[GetMemberAddress/API] Failure';
export const GetMemberAddressSuccess = '[GetMemberAddress/API] Success';

export const ReissuePaymentRequest = '[ReissueSPPayment/API] Send Request';
export const ReissuePaymentFailure = '[ReissueSPPayment/API] Failure';
export const ReissuePaymentSuccess = '[ReissueSPPayment/API] Success';

export const GenerateEDISPPaymentRequest =
  '[GenerateEDISPPayment/API] Send Request';
export const GenerateEDISPPaymentFailure = '[GenerateEDISPPayment/API] Failure';
export const GenerateEDISPPaymentSuccess = '[GenerateEDISPPayment/API] Success';

export const ClearEditCheckNumberState =
  '[ClearEditCheckNumberPaymentState] Clear';

export const GetServiceProviderActionStateRequest =
  '[GetServiceProviderActionState/API] Send Request';
export const GetServiceProviderActionStateSuccess =
  '[GetServiceProviderActionState/API] Success';
export const GetServiceProviderActionStateFailure =
  '[GetServiceProviderActionState/API] Failure';

export const clearActionState = createAction(VendorPaymentStateClear);

export const resetActionState = createAction(VendorPaymentStateReset);

export const getPayment = createAction(VendorPaymentRequest);
export const getPaymentSuccess = createAction(
  VendorPaymentSuccess,
  props<{ payment: NextPayment }>()
);
export const getPaymentFailure = createAction(
  VendorPaymentFailure,
  props<{ errorMsg: string }>()
);

export const setPaymentStep = createAction(
  SetPaymentStepRequest,
  props<{ body: SetPaymentStepBody }>()
);
export const setPaymentStepSuccess = createAction(SetPaymentStepSuccess);
export const setPaymentStepFailure = createAction(
  SetPaymentStepFailure,
  props<{ errorMsg: string }>()
);

export const updateStartingCheckNumber = createAction(
  UpdateStartingCheckNumberRequest,
  props<{ body: any }>()
);
export const updateStartingCheckNumberSuccess = createAction(
  UpdateStartingCheckNumberSuccess,
  props<{ body: any }>()
);
export const updateStartingCheckNumberFailure = createAction(
  UpdateStartingCheckNumberFailure,
  props<{ errorMsg: string }>()
);

export const updateDepositDate = createAction(
  UpdateDepositDateRequest,
  props<{ body: any }>()
);
export const updateDepositDateSuccess = createAction(
  UpdateDepositDateSuccess,
  props<{ body: any }>()
);
export const updateDepositDateFailure = createAction(
  UpdateDepositDateFailure,
  props<{ errorMsg: string }>()
);

export const updateWarrantNumber = createAction(
  UpdateWarrantNumberRequest,
  props<{ body: any }>()
);
export const updateWarrantNumberSuccess = createAction(
  UpdateWarrantNumberSuccess,
  props<{ body: any }>()
);
export const updateWarrantNumberFailure = createAction(
  UpdateWarrantNumberFailure,
  props<{ errorMsg: string }>()
);

export const getPaymentList = createAction(
  NextPaymentListRequest,
  props<{ query: any }>()
);
export const getPaymentListSuccess = createAction(
  NextPaymentListSuccess,
  props<{ paymentListData: any }>()
);
export const getPaymentListFailure = createAction(
  NextPaymentListFailure,
  props<{ error?: any }>()
);

export const updateTransaction = createAction(
  UpdateTransactionRequest,
  props<{ body: any }>()
);
export const updateTransactionSuccess = createAction(UpdateTransactionSuccess);
export const updateTransactionFailure = createAction(
  UpdateTransactionFailure,
  props<{ errorMsg: string }>()
);

export const uploadWarrant = createAction(UploadWarrantRequest);
export const uploadWarrantSuccess = createAction(UploadWarrantSuccess);
export const uploadWarrantFailure = createAction(
  UploadWarrantFailure,
  props<{ errorMsg: string }>()
);

export const setNextPaymentBoardBank = createAction(
  SetNextPaymentBoardBankRequest,
  props<{ boardBankId: string }>()
);
export const setNextPaymentBoardBankSuccess = createAction(
  SetNextPaymentBoardBankSuccess
);
export const setNextPaymentBoardBankFailure = createAction(
  SetNextPaymentBoardBankFailure,
  props<{ errorMsg: string }>()
);
export const achSent = createAction(AchSentRequest, props<{ body: any }>());
export const achSentSuccess = createAction(
  AchSentSuccess,
  props<{ body: any }>()
);
export const achSentFailure = createAction(
  AchSentFailure,
  props<{ errorMsg: string }>()
);

export const checksPrinted = createAction(
  ChecksPrintedRequest,
  props<{ body: any }>()
);
export const checksPrintedSuccess = createAction(
  ChecksPrintedSuccess,
  props<{ body: any }>()
);
export const checksPrintedFailure = createAction(
  ChecksPrintedFailure,
  props<{ errorMsg: string }>()
);
export const editCheckNumber = createAction(
  EditCheckNumberRequest,
  props<{ body: any }>()
);
export const editCheckNumberSuccess = createAction(EditCheckNumberSuccess);
export const editCheckNumberFailure = createAction(
  EditCheckNumberFailure,
  props<{ errorMsg: string }>()
);
export const finalizePayment = createAction(FinalizePaymentRequest);
export const finalizePaymentSuccess = createAction(FinalizePaymentSuccess);
export const finalizePaymentFailure = createAction(
  FinalizePaymentFailure,
  props<{ errorMsg: string }>()
);

export const getMemberAddress = createAction(GetMemberAddressRequest);
export const getMemberAddressSuccess = createAction(
  GetMemberAddressSuccess,
  props<{ memberAddress: any }>()
);
export const getMemberAddressFailure = createAction(
  GetMemberAddressFailure,
  props<{ errorMsg: string }>()
);

export const reissuePaymentRequest = createAction(
  ReissuePaymentRequest,
  props<{ paymentId: any }>()
);
export const reissuePaymentSuccess = createAction(ReissuePaymentSuccess);
export const reissuePaymentFailure = createAction(
  ReissuePaymentFailure,
  props<{ errorMsg: string }>()
);

export const generateEDISPPaymentRequest = createAction(
  GenerateEDISPPaymentRequest,
  props<{ paymentId: any; selectedBankId: any; boardBankId: any }>()
);
export const generateEDISPPaymentSuccess = createAction(
  GenerateEDISPPaymentSuccess,
  props<{ fileContent: any }>()
);
export const generateEDISPPaymentFailure = createAction(
  GenerateEDISPPaymentFailure,
  props<{ errorMsg: string }>()
);

export const clearEditCheckNumberState = createAction(
  ClearEditCheckNumberState
);

export const getServiceProviderActionStateRequest = createAction(
  GetServiceProviderActionStateRequest
);
export const getServiceProviderActionStateSuccess = createAction(
  GetServiceProviderActionStateSuccess,
  props<{ serviceProviderActionState: ServiceProviderActionState }>()
);
export const getServiceProviderActionStateFailure = createAction(
  GetServiceProviderActionStateFailure
);
