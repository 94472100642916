import { createAction, props } from '@ngrx/store';

import {
  NewDeduction,
  DeductionList,
  DeductionListQuery,
} from '../types/models';

export const DeductionListRequest = '[DeductionList/API] Send Request';
export const DeductionListFailure = '[DeductionList/API] Failure';
export const DeductionListSuccess = '[DeductionList/API] Success';

export const CreateDeductionRequest = '[CreateDeduction/API] Send Request';
export const CreateDeductionFailure = '[CreateDeduction/API] Failure';
export const CreateDeductionSuccess = '[CreateDeduction/API] Success';

export const UpdateDeductionRequest = '[UpdateDeduction/API] Send Request';
export const UpdateDeductionFailure = '[UpdateDeduction/API] Failure';
export const UpdateDeductionSuccess = '[UpdateDeduction/API] Success';

export const RemoveDeductionRequest = '[RemoveDeduction/API] Send Request';
export const RemoveDeductionFailure = '[RemoveDeduction/API] Failure';
export const RemoveDeductionSuccess = '[RemoveDeduction/API] Success';

export const SearchPayeeRequest = '[SearchPayee/API] Send Request';
export const SearchPayeeSuccess = '[SearchPayee/API] Success';
export const SearchPayeeFailure = '[SearchPayee/API] Failure';
export const ClearSearchPayeeList = '[SearchPayee/API] Clear';

export const PaymentDeductionClear = '[PaymentDeduction] Clear';

export const getDeductionList = createAction(
  DeductionListRequest,
  props<{ query: DeductionListQuery; idClient: string }>()
);
export const getDeductionListSuccess = createAction(
  DeductionListSuccess,
  props<{ deductionList: DeductionList }>()
);
export const getDeductionListFailure = createAction(
  DeductionListFailure,
  props<{ error?: any }>()
);

export const createDeduction = createAction(
  CreateDeductionRequest,
  props<{ body: NewDeduction }>()
);
export const createtDeductionSuccess = createAction(CreateDeductionSuccess);
export const createDeductionFailure = createAction(
  CreateDeductionFailure,
  props<{ error?: any }>()
);

export const updateDeduction = createAction(
  UpdateDeductionRequest,
  props<{ id: string; body: NewDeduction }>()
);
export const updateDeductionSuccess = createAction(UpdateDeductionSuccess);
export const updateDeductionFailure = createAction(
  UpdateDeductionFailure,
  props<{ error?: any }>()
);

export const removeDeduction = createAction(
  RemoveDeductionRequest,
  props<{ id: string }>()
);
export const removeDeductionSuccess = createAction(RemoveDeductionSuccess);
export const removeDeductionFailure = createAction(
  RemoveDeductionFailure,
  props<{ error?: any }>()
);

export const paymentDeductionClear = createAction(PaymentDeductionClear);

export const getSearchPayeeRequest = createAction(
  SearchPayeeRequest,
  props<{ searchValue: string }>()
);
export const getSearchPayeeSuccess = createAction(
  SearchPayeeSuccess,
  props<{ searchList: any }>()
);
export const getSearchPayeeFailure = createAction(
  SearchPayeeFailure,
  props<{ error?: any }>()
);

export const clearSearchList = createAction(ClearSearchPayeeList);
