<ptg-breadcrumb
  [listBreadcrumbs]="listBreadcrumbs">
</ptg-breadcrumb>

<div class="add-section" [ngClass]="{'add-new-member':data.isAddNewMember}">
  <div class="wrap-breadcum-btn" *ngIf="data.isAddNewMember">
    <div class="breadcrumb-section">
      <div id="wrap-section">
        <ng-container *ngFor="let section of listSectionStatic; index as i">
          <span [id]="section?.key">{{section?.name}}</span>
          <span *ngIf="listSectionStatic[i + 1]" class="bread-point"><mat-icon>chevron_right</mat-icon></span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="wrap-btn" *ngIf="data.isAddNewMember">
    <button
      *ngIf="currentSection?.key !== listSectionStatic[listSectionStatic.length - 1]?.key"
      mat-raised-button type="button" class="btn-continue"
      (click)="onContinue()">Continue</button>
    <button
      *ngIf="currentSection?.key === listSectionStatic[listSectionStatic.length - 1]?.key"
      mat-raised-button type="button" class="btn-create-continue"
      (click)="onCreate(true)">Create & Continue</button>
    <button
      *ngIf="currentSection?.key === listSectionStatic[listSectionStatic.length - 1]?.key"
      mat-raised-button type="button" class="btn-create-add"
      (click)="onCreate(false)">Create & Add Another</button>
    <button
      *ngIf="currentSection?.key !== listSectionStatic[0]?.key"
      mat-raised-button type="button" class="btn-back"
      (click)="onBack()">Back</button>
    <button mat-raised-button type="button" class="btn-cancel" (click)="onCancel()">Cancel</button>
  </div>

  <div class="wrap-btn" *ngIf="!data.isAddNewMember">
    <button mat-raised-button type="button" class="btn-save" (click)="onSubmit()">Save</button>
    <button mat-raised-button type="button" class="btn-cancel" (click)="onCancel()">Cancel</button>
  </div>

  <form [formGroup]="frmAddSectionData" *ngIf="renewForm" class="add-section-form">
    <div *ngIf="currentSection?.key === listSectionStatic[0]?.key">
      <ptg-select
        [controlField]="frmAddSectionData.get('statusId')"
        placeholder="Status"
        [listData]="listStatus"
        [isOptionObj]="true"
        class="w776"
        [isSetMaxWidthSelect]="true"
        [isRequired]="true"
        (changeOptionValue)="setStatusEvent()">
      </ptg-select>
      <ptg-select
        [controlField]="frmAddSectionData.get('eventId')"
        placeholder="Event"
        [listData]="listStatusEvent"
        [isOptionObj]="true"
        class="w776"
        [isSetMaxWidthSelect]="true"
         (changeOptionValue)="setEvent()"
        [isRequired]="true">
      </ptg-select>
    </div>

    <ng-container *ngFor="let f of lstFormControl">
      <ng-container [ngSwitch]="f.type">

        <ng-container *ngSwitchCase="PropertyType.TYPE_CURRENCY">
          <ptg-textbox
            [controlField]="f.formControl"
            [maxLength]="f.maxLength"
            [placeholder]="f.label"
            [hasLabel]="true"
            class="w776"
            [min]="f.min"
            [max]="f.max"
            [isRequired]="f.isRequired"
            inputType="Currency"
            [errorAsync]="f.label + ' already exists.'"
          >
          </ptg-textbox>
          <br/>
        </ng-container>

        <ng-container *ngSwitchCase="[PropertyType.TYPE_TEXT, PropertyType.TYPE_EMAIL, PropertyType.TYPE_PHONE, PropertyType.TYPE_SSN].includes(f.type) ? f.type : ''">
          <ptg-textbox
            [controlField]="f.formControl"
            [maxLength]="f.maxLength"
            [placeholder]="f.label"
            [isRequired]="f.isRequired"
            [hasLabel]="true"
            [isShowIconMasked]="f?.config?.masked == 'true'"
            class="w776"
            [inputType]="f.type"
            [errorAsync]="f.label + ' already exists.'">
          </ptg-textbox>
          <br/>
        </ng-container>

        <ng-container *ngSwitchCase="[PropertyType.TYPE_WHOLE_NUMBER, PropertyType.TYPE_DECIMAL].includes(f.type) ? f.type : ''">
          <ptg-textbox
            [controlField]="f.formControl"
            [placeholder]="f.label"
            [hasLabel]="true"
            [mask]="(f.type === PropertyType.TYPE_WHOLE_NUMBER ? 'separator.0' : f.fractionalLength ? 'separator.' + f.fractionalLength : 'separator')"
            [thousandSeparator]="','"
            [min]="f.min"
            [max]="f.max"
            class="w776"
            inputType="Decimal"
            [isRequired]="f.isRequired"
            [errorAsync]="f.label + ' already exists.'">
          </ptg-textbox>
          <br/>
        </ng-container>

        <ng-container *ngSwitchCase="PropertyType.TYPE_DATE">
          <ptg-datepicker
            [controlField]="f.formControl"
            [placeholder]="f.label"
            class="w776"
            [isRequired]="f.isRequired"
            [errorAsync]="f.label + ' already exists.'">
          </ptg-datepicker>
        </ng-container>

        <ng-container *ngSwitchCase="[PropertyType.TYPE_ADDRESS, PropertyType.TYPE_PERSON_NAME].includes(f.type) ? f.type : ''">
          <ng-container *ngFor="let childForm of f.lstChildFormControl; let i = index;">
            <ng-container *ngIf="childForm.type === 'select'">
              <ptg-select
                [controlField]="childForm.formControl"
                [placeholder]="childForm.label"
                [listData]="childForm.lstOption || []"
                [isOptionObj]="true"
                [class]="childForm.className"
                [hasNoneValue]="!f.isRequired || !childForm.required"
                [isRequired]="f.isRequired && childForm.required">
              </ptg-select>
            </ng-container>
            <ng-container *ngIf="childForm.type === 'text'">
              <ptg-textbox
                [controlField]="childForm.formControl"
                [maxLength]="childForm.maxLength"
                [placeholder]="childForm.label"
                [isRequired]="f.isRequired && childForm.required"
                [hasLabel]="true"
                [class]="childForm.className">
              </ptg-textbox>
            </ng-container>
            <ng-container *ngIf="childForm.type === 'number'">
              <ptg-textbox
                [controlField]="childForm.formControl"
                [maxLength]="childForm.maxLength"
                [placeholder]="childForm.label"
                [hasLabel]="true"
                [mask]="'0'.repeat(childForm.maxLength)"
                [isAutoLeadingZeros]="true"
                [isValidation]="false"
                [class]="childForm.className"
                [isRequired]="f.isRequired && childForm.required"
                inputType="Decimal">
              </ptg-textbox>
            </ng-container>
            <ng-container *ngIf="(f.type === PropertyType.TYPE_ADDRESS && (i === 1 || i === 5)) || (f.type === PropertyType.TYPE_PERSON_NAME && i === 4)">
              <br/>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="[PropertyType.TYPE_LIST, PropertyType.TYPE_EMPLOYER, PropertyType.TYPE_DEPARTMENT].includes(f.type) ? f.type : ''">
          <ptg-select
            [controlField]="f.formControl"
            [placeholder]="f.label"
            [listData]="f.lstOption"
            [isOptionObj]="true"
            class="w776"
            [isSetMaxWidthSelect]="true"
            [hasNoneValue]="!f.isRequired"
            [isRequired]="f.isRequired"
            [isMultipleLineOption]="f.type === PropertyType.TYPE_DEPARTMENT">
          </ptg-select>
          <br/>
        </ng-container>

        <ng-container *ngSwitchCase="PropertyType.TYPE_BOOLEAN">
          <ptg-checkbox
            [controlField]="f.formControl"
            [label]="f.label"
            class="w776"
          ></ptg-checkbox>
        </ng-container>

        <ng-container *ngSwitchCase="PropertyType.TYPE_PERCENTAGE">
          <ptg-textbox
            [controlField]="f.formControl"
            [placeholder]="f.label"
            [hasLabel]="true"
            [mask]="(f.type === PropertyType.TYPE_WHOLE_NUMBER ? 'separator.0' : f.fractionalLength ? 'separator.' + f.fractionalLength : 'separator')"
            [thousandSeparator]="','"
            [min]="f.min"
            [max]="f.max"
            [isPercentage]="true"
            inputType="Decimal"
            class="w776"
            [isRequired]="f.isRequired"
            [errorAsync]="f.label + ' already exists.'"
          >
          </ptg-textbox>
          <br/>
        </ng-container>

        <ng-container *ngSwitchCase="PropertyType.TYPE_RICH_TEXT">
          <ptg-editor class="w776"
                      [controlField]="f.formControl"
                      [placeholder]="f.label"
                      [isRequired]="f.isRequired"
                      [maxLength]="f.maxLength"
                      [hasFloatLabel]="true">
          </ptg-editor>
          <br/>
        </ng-container>

        <ng-container *ngSwitchCase="PropertyType.TYPE_DATE_TIME">
          <ptg-datetime-picker class="w776"
                               [controlField]="f.formControl"
                               [isRequired]="f.isRequired">
          </ptg-datetime-picker>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ptg-textbox
            [controlField]="f.formControl"
            [maxLength]="f.maxLength"
            [placeholder]="f.label"
            [hasLabel]="true"
            class="w776"
            [isRequired]="f.isRequired"
            [inputType]="f.type"
            [errorAsync]="f.label + ' already exists.'">
          </ptg-textbox>
          <br/>
        </ng-container>
      </ng-container>
    </ng-container>
    </form>
</div>
