export interface MemberNavigationAttachmentList {
  clientKey: string;
  createdBy: string;
  dateCreated: string;
  dateUpdated?: string;
  description: string;
  documentName: string;
  fileSnapshot: FileSnapshot;
  id: string;
  itemKey: string;
  memberId: string;
  rowId?: string;
  updatedBy?: string;
}

export interface FileSnapshot {
  description: string;
  fileName: string;
  fileType: string;
  key: string;
  label: string;
  lastModified: string;
  path: string;
  signatureRequired: boolean;
  uri: string;
}
