import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { ConfirmType } from 'src/app/shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from 'src/app/shared/controls/confirm-popup/confirm-popup.component';
import { FileSnapshot } from 'src/app/shared/models';
import { FileService } from 'src/app/shared/services/file-service';
import { SignatoryService } from 'src/app/shared/services/signatory-service';
import { downloadFile } from '@ptg-shared/utils/common.util';

import { BulkUpdateLogActions } from '../../actions';
import * as fromBulkUpdateLog from '../../reducers'
import { BulkUpdateService } from '../../services/bulk-update.service';
import { UPLOAD_STATUS } from '@ptg-member/constance/lookupTable.const';

@Component({
  selector: 'ptg-file-result',
  templateUrl: './file-result.component.html',
  styleUrls: ['./file-result.component.scss']
})
export class FileResultComponent implements OnInit {
  canProceed: boolean = false;
  canCancel: boolean = false;
  isLoading: boolean = true;
  isCanceling: boolean = false;
  isProceeding: boolean = false;
  isCanceled: boolean = false;
  isProceeded: boolean = false;
  isDownloadValidation: boolean = false;
  isDownloadRawFile: boolean = false;
  isDisableGenerateValidation: boolean = false;

  summaries: string[] = [];
  warnings: string[] = [];
  errors: string[] = [];
  status: string = '';
  doc?: FileSnapshot;

  constructor(
    public dialogRef: MatDialogRef<FileResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sessionId: string, sessionStatus: string },
    private logStore: Store<fromBulkUpdateLog.State>,
    public dialog: MatDialog,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    private bulkUpdateService: BulkUpdateService
  ) {
  }

  ngOnInit(): void {
    this.canProceed = (this.data.sessionStatus === UPLOAD_STATUS.VALIDATED);
    this.canCancel = this.canProceed;
    this.isDownloadValidation = this.data.sessionStatus === UPLOAD_STATUS.INVALID || this.data.sessionStatus === UPLOAD_STATUS.VALIDATED
      || this.data.sessionStatus === UPLOAD_STATUS.CANCELED || this.data.sessionStatus === UPLOAD_STATUS.IMPORTED;
    this.isDownloadRawFile = this.data.sessionStatus === UPLOAD_STATUS.INVALID || this.data.sessionStatus === UPLOAD_STATUS.VALIDATED
      || this.data.sessionStatus === UPLOAD_STATUS.CANCELED || this.data.sessionStatus === UPLOAD_STATUS.IMPORTED || this.data.sessionStatus === UPLOAD_STATUS.ERRONEOUS;

    this.logStore.dispatch(BulkUpdateLogActions.getBulkUpdateLog({ sessionId: this.data.sessionId }));
    this.logStore.select(fromBulkUpdateLog.selectBulkUpdateLogState).subscribe(state => {
      this.summaries = state.bulkUpdateLog.summary;
      this.warnings = state.bulkUpdateLog.warnings;
      this.errors = state.bulkUpdateLog.errors;
      this.status = state.bulkUpdateLog.importStatus;
      this.isDisableGenerateValidation = state.bulkUpdateLog.isDisableReportGenerate;
      this.doc = state.bulkUpdateLog.importFile;

      this.isLoading = state.isLoading;
      this.isCanceling = state.isCanceling;
      this.isProceeding = state.isProceeding;
      this.isProceeded = state.isProceeded;
      this.isCanceled = state.isCanceled;

      if (state.isCanceled) {
        setTimeout(() => {
          this.dialogRef.close("cancel");
        }, 750);
      } else if (state.isProceeded) {
        setTimeout(() => {
          this.dialogRef.close("proceed");
        }, 750);
      }
    });
  }

  onBack() {
    this.dialogRef.close("back");
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: 'Are you sure you want to CANCEL this Bulk Update?',
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.logStore.dispatch(BulkUpdateLogActions.cancelBulkUpdate({ sessionId: this.data.sessionId }));
      }
    });
  }

  onProceed(): void {
    this.bulkUpdateService.validateExistsAnotherImport(this.data.sessionId).subscribe(response => {
      let text = 'Proceeding with this Bulk Update will import the data within the uploaded file. Is it what you want?';
      if (response?.exists) {
        text = 'Proceeding with this Bulk Update will import the data within the uploaded file and CANCEL other validated files. Is it what you want?';
      }
      this.openConfirmDialog(text);
    });
  }

  openConfirmDialog(text: string) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Proceed Action',
        text,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.logStore.dispatch(BulkUpdateLogActions.proceedBulkUpdate({ sessionId: this.data.sessionId }));
      }
    });
  }

  onDownload(doc: any): void {
    let fileName = '';
    const datePipe = new DatePipe('en-US');
    this.fileService.downloadRawFile(this.data.sessionId)
      .pipe(
        take(1)
      )
      .subscribe(
        (res: any) => {
          // download file
          if (res) {
            const labelParts = doc.label.split('.');
            const fileExtension = labelParts.pop();
            const baseFileName = labelParts.join('.');
            
            fileName = `${baseFileName}_${datePipe.transform(new Date(), 'MMddyy_HHmmss')}.${fileExtension}`;
            downloadFile.call(this, res, fileName);
          }
          else {
            setTimeout(() => this.onDownload(doc), 2500);
          }
        },
        () => {
          setTimeout(() => this.onDownload(doc), 2500);
        }
      );
  }

  onDownloadValidation(doc: any): void {
      this.fileService.generateValidationReport(this.data.sessionId).subscribe(
        () => {
          this.handleDownloadExportFile(doc);
        }
      );
  }

  handleDownloadExportFile(doc: any) {
    let fileName = '';
    const datePipe = new DatePipe('en-US');
    this.fileService.downloadValidateFile(this.data.sessionId)
      .pipe(
        take(1)
      )
      .subscribe(
        (res: any) => {
          // download file
          if (res) {
            const labelParts = doc.label.split('.');
            const fileExtension = labelParts.pop();
            const baseFileName = labelParts.join('.');
            
            fileName = `Validation Report_${baseFileName}_${datePipe.transform(new Date(), 'MMddyy_HHmmss')}.${fileExtension}`;
            
            downloadFile.call(this, res, fileName);
          }
          else {
            setTimeout(() => this.handleDownloadExportFile(doc), 2500);
          }
        },
        () => {
          setTimeout(() => this.handleDownloadExportFile(doc), 2500);
        }
      );
  } 
}
