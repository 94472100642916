import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { MemberListConfigurationComponent } from 'src/app/member/containers/member-list-configuration/member-list-configuration.component';
import { ParticipantSearchConfigurationComponent } from 'src/app/member/containers/participant-search-configuration/participant-search-configuration.component';
import { ProfileHeaderConfigurationComponent } from 'src/app/member/containers/profile-header-configuration/profile-header-configuration.component';
import { ProfileOverviewConfigurationDetailComponent } from 'src/app/member/containers/profile-overview-configuration-detail/profile-overview-configuration-detail.component';
import { ProfileOverviewConfigurationComponent } from 'src/app/member/containers/profile-overview-configuration/profile-overview-configuration.component';
import { CheckFundService } from '../shared/services/check-fund.service';
import { CheckPermissionService } from '../shared/services/check-permission.service';
import { CheckRoleService } from '../shared/services/check-role.service';
import { DropStatementComponent } from './components/drop-statement/drop-statement.component';
import { EditMetadataComponent } from './components/edit-metadata/edit-metadata.component';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { OverviewSectionComponent } from './components/overview-section/overview-section.component';
import { GeneratedReportComponent } from './components/participant-report/generated-report/generated-report.component';
import { ManageReportComponent } from './components/participant-report/manage-report/manage-report.component';
import { ReportCategoriesComponent } from './components/participant-report/report-categories/report-categories.component';
import { ReportDetailComponent } from './components/participant-report/report-detail/report-detail.component';
import { ReportTemplateComponent } from './components/participant-report/report-view/report-view.component';
import { AggregationConfigurationComponent } from './containers/aggregation-configuration/aggregation-configuration.component';
import { AggregationGroupConfigurationComponent } from './containers/aggregation-group-configuration/aggregation-group-configuration.component';
import { BulkContentComponent } from './containers/bulk-content/bulk-content.component';
import { CalculationListComponent } from './containers/calculation-list/calculation-list.component';
import { DefinedBenefitsComponent } from './containers/defined-benefits/defined-benefits.component';
import { DropPlanComponent } from './containers/drop-plan/drop-plan.component';
import { LookupTableDetailComponent } from './containers/lookup-table-detail/lookup-table-detail.component';
import { LookupTableComponent } from './containers/lookup-table/lookup-table.component';
import { MemberDetailsComponent } from './containers/member-details/member-details.component';
import { MemberMetadataComponent } from './containers/member-metadata/member-metadata.component';
import { MemberNavigationComponent } from './containers/member-navigation/member-navigation.component';
import { MembersBenefitEligibleComponent } from './containers/members-benefit-eligible/members-benefit-eligible.component';
import { MembersListComponent } from './containers/members-list/members-list.component';
import { NextPaymentComponent } from './containers/next-payment/next-payment.component';
import { NonListConfigurationComponent } from './containers/non-list-configuration/non-list-configuration.component';
import { PaymentHistoryScreenComponent } from './containers/payment-history-screen/payment-history-screen.component';
import { ProfileHeaderConfigurationListComponent } from './containers/profile-header-configuration-list/profile-header-configuration-list.component';
import { ProfileNavigationConfigurationDetailComponent } from './containers/profile-navigation-configuration-detail/profile-navigation-configuration-detail.component';
import { ProfileNavigationConfigurationComponent } from './containers/profile-navigation-configuration/profile-navigation-configuration.component';
import { StatusDetailComponent } from './containers/status-detail/status-detail.component';
import { StatusComponent } from './containers/status/status.component';
import { TierConfigurationComponent } from './containers/tier-configuration/tier-configuration.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MembersListComponent,
  },
  {
    path: 'benefit',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MembersBenefitEligibleComponent,
  },

  {
    path: 'detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MemberDetailsComponent,
  },
  {
    path: 'bulk/:key',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: BulkContentComponent,
  },
  {
    path: 'detail/:sectionKey/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: OverviewSectionComponent,
  },
  {
    path: 'detail',
    redirectTo: '/member',
    pathMatch: 'full',
  },
  {
    path: 'member-metadata',
    component: MemberMetadataComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'member-metadata/:key',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: EditMetadataComponent,
  },
  {
    path: 'member-metadata/:key/aggregation/aggregation-group',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: AggregationGroupConfigurationComponent,
  },
  {
    path: 'member-metadata/:key/aggregation',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: AggregationConfigurationComponent,
  },
  {
    path: 'retiree/next-payment',
    redirectTo: '/member',
    pathMatch: 'full',
  },
  {
    path: 'retiree/next-payment/:id',
    canActivate: [AuthGuard, CheckFundService],
    component: NextPaymentComponent,
  },
  {
    path: 'retiree/payment-history/:id',
    canActivate: [AuthGuard, CheckFundService],
    component: PaymentHistoryScreenComponent,
  },
  {
    path: 'lookup-table',
    component: LookupTableComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'lookup-table/:id',
    component: LookupTableDetailComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'status',
    component: StatusComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'status/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: StatusDetailComponent,
  },
  {
    path: 'member-list-configuration',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: MemberListConfigurationComponent,
  },
  {
    path: 'profile-overview-configuration/:memberId/detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileOverviewConfigurationDetailComponent,
  },
  {
    path: 'profile-overview-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileOverviewConfigurationComponent,
  },
  {
    path: 'profile-header-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileHeaderConfigurationListComponent,
  },
  {
    path: 'profile-header-configuration/:memberId/detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileHeaderConfigurationComponent,
  },
  {
    path: 'profile-navigation-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileNavigationConfigurationComponent,
  },
  {
    path: 'profile-navigation-configuration/:memberId/detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileNavigationConfigurationDetailComponent,
  },
  {
    path: 'navigation/nonlist-configuration/:sectionType/:menuId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: NonListConfigurationComponent,
  },
  {
    path: 'navigation/list-configuration/:sectionType/:menuId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: MemberListConfigurationComponent,
  },
  {
    path: 'navigation/:sectionType/:menuId/:id',
    canActivate: [],
    component: MemberNavigationComponent,
  },
  {
    path: 'participant-search-configuration',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ParticipantSearchConfigurationComponent,
  },
  {
    path: 'opt-in/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: OptInComponent,
  },
  {
    path: 'benefit/defined-benefits',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: DefinedBenefitsComponent,
  },
  {
    path: 'member-list-tier',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: TierConfigurationComponent,
  },
  {
    path: 'calculation',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: CalculationListComponent,
  },
  {
    path: 'report-view',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ReportTemplateComponent,
  },
  {
    path: 'report',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: ManageReportComponent,
  },
  {
    path: 'report/generated',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: GeneratedReportComponent,
  },
  {
    path: 'report/drop-statements',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: DropStatementComponent,
  },
  {
    path: 'report/category',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ReportCategoriesComponent,
  },
  {
    path: 'report/:reportId',
    canActivate: [AuthGuard, CheckFundService],
    component: ReportTemplateComponent,
  },
  {
    path: 'drop-plan',
    canActivate: [AuthGuard, CheckFundService],
    component: DropPlanComponent,
  },
  {
    path: 'report/generated/:reportId/:reportName',
    canActivate: [AuthGuard, CheckFundService],
    component: ReportDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemberRoutingModule {}
