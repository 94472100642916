export const UPLOAD_STATUS = {
  UPLOADING: 'Uploading',
  UPLOADED: 'Uploaded',
  VALIDATING: 'Validating',
  VALIDATED: 'Validated',
  IMPORTING: 'Importing',
  IMPORTED: 'Imported',
  INVALID: 'Invalid',
  CANCELED: 'Canceled',
  ERRONEOUS: 'Erroneous',
}

export const SCREEN_ID = {
  ParticipantList: 'participant_list',
  ParticipantOverview: 'participant_overview',
  ParticipantSearch: 'participant_search',
  ParticipantEstimator: 'participant_estimator',
  ParticipantBenefitEligible: 'participant_benefit_eligible',
  EmployerList: 'employer_list',
  EmployerOverview: 'employer_overview',
  EmployerWageRecordList: 'employer_wage_record_list',
  EmployerWageRecordDetail: 'employer_wage_record_detail',
  EmployerWageProcessValidate: 'employer_wage_process_validate',
  EmployerWageProcessAudit: 'employer_wage_process_audit',
  AdminFundManagement: 'admin_fund_management',
  ProcessingServiceProvider: 'processing_service_provider',
  ProcessingTransactionRegister: 'processing_transaction_register',
  Participant: 'participant',
}