<div class="dialog-container" id="edit-value-dialog">
  <div class="header-title">
    {{ lookupTableDetail?.lookupTableValue?.id ? 'Edit Value' : 'Add Value' }}
  </div>

  <form (ngSubmit)="formSubmit$.next()" [formGroup]="editForm" class="edit-form">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('code')"
        [hasLabel]="true"
        [maxLength]="5"
        restrictedRegex="[^0-9a-zA-Z]"
        errorAsync="Code already exists."
        placeholder="Code"
        [ngClass]="{'has-error': editForm.get('code')?.touched && editForm.get('code')?.errors}"
      ></ptg-textbox>
      <ptg-textbox
        [controlField]="editForm.get('name')"
        [hasLabel]="true"
        [maxLength]="255"
        placeholder="Description"
      ></ptg-textbox>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
