import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PtgReportViewerComponent } from './ptg-report-viewer.component';
/**
 * Represents the [NgModule](https://angular.io/docs/ts/latest/guide/ngmodule.html)
 * definition for the Telerik Report Viewer component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Grid module
 * import { TelerikReportingModule } from './telerik-reporting/telerik-reporting.module';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * @@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, TelerikReportingModule], // import Grid module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * ```
 */
@NgModule({
  imports: [CommonModule],
  exports: [PtgReportViewerComponent],
  declarations: [PtgReportViewerComponent],
})
export class PtgReportingModule { }