import { createReducer, on } from '@ngrx/store';

import * as NotificationActions from '../actions/member-notification-opt-in.action';
import {
  MemberNotificationOptIns,
  OptInType,
} from '../types/models/member-notification-opt-in.model';

export const memberNotificationOptInFeatureKey = 'memberNotificationOptIn';

export interface State {
  isLoading: boolean;
  memberId?: string;
  memberNotificationOptIns: MemberNotificationOptIns[];
  getMemberNotificationState: string;
  editNotificationOptInState: {
    state: string;
    optInType?: OptInType | undefined;
  };
}

const initialState: State = {
  isLoading: true,
  memberId: '',
  memberNotificationOptIns: [],
  getMemberNotificationState: '',
  editNotificationOptInState: {
    state: '',
  },
};

export const reducer = createReducer(
  initialState,
  on(NotificationActions.clearNotificationOptIn, (state) => ({
    ...state,
    editNotificationOptInState: {
      state: '',
    },
  })),

  on(
    NotificationActions.getNotificationOptInSuccess,
    (state, { memberNotificationOptIns }) => ({
      ...state,
      isLoading: false,
      memberNotificationOptIns: state.editNotificationOptInState.state
        ? [
            ...(memberNotificationOptIns.filter(
              (item) => item.type === state.editNotificationOptInState.optInType
            ) || []),
          ]
        : memberNotificationOptIns,
      getMemberNotificationState: 'Success',
    })
  ),

  on(
    NotificationActions.editNotificationOptInSuccess,
    (state, { optInType }) => ({
      ...state,
      isLoading: false,
      editNotificationOptInState: {
        state: 'Success',
        optInType: optInType,
      },
    })
  ),

  on(
    NotificationActions.editNotificationOptInFailure,
    (state, { optInType }) => ({
      ...state,
      isLoading: false,
      editNotificationOptInState: {
        state: 'Fail',
        optInType: optInType,
      },
    })
  )
);
