import { DatePipe } from '@angular/common';
import { Component, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { EmailComponent } from '@ptg-member/components/email/email.component';
import { NextPaymentService } from '@ptg-processing/services/vendor-next-payment.service';
import * as fromReducer from '@ptg-reducers';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  arrowDropDown,
  removeAccountIcon,
  switchAccountIcon,
} from '@ptg-shared/constance/listIcons.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Align, Column, GridComponent } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import {
  Breadcrumb,
  FunctionButtonConfig,
  FunctionButtonData,
} from '@ptg-shared/models/breadcrumb.model';
import { HistoryService } from '@ptg-shared/services/history.service';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { stringToBoolean } from '@ptg-shared/utils/string.util';

import { FundType } from '@ptg-shared/types/enums';
import { MemberDetailActions, MemberListActions } from '../../actions';
import * as MemberNavigationActions from '../../actions/member-navigation';
import { AddMemberFilterComponent } from '../../components/add-member-filter/add-member-filter.component';
import { AddMemberSectionDataComponent } from '../../components/add-member-section-data/add-member-section-data.component';
import { ManageMemberFilterComponent } from '../../components/manage-member-filter/manage-member-filter.component';
import { SaveMemberFilterComponent } from '../../components/save-member-filter/save-member-filter.component';
import {
  FilterPropertyType,
  ICON_REGISTER_FIELDS,
  ICON_STATUS_FIELDS,
} from '../../constance/member-list.const';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import { getColumConfig } from '../../helpers';
import * as fromMember from '../../reducers';
import { State } from '../../reducers/member-list.reducer';
import { MemberDetailService } from '../../services/member-detail.service';
import { MemberListService } from '../../services/member-list.service';
import { MetadataSectionService } from '../../services/metadata-detail.service';
import {
  ColumnConfig,
  ExportFileRequest,
  FilterInfo,
  MemberFilter,
  MemberList,
  MemberListQuery,
} from '../../types/models';

@Component({
  selector: 'ptg-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
})
export class MembersListComponent extends BaseComponent implements OnChanges {
  @ViewChild('gridMemberList') gridMemberList!: GridComponent<any>;

  columns: Column[] = [];
  errorMsg: string = '';
  membersData: [] | any = [];
  groupButtonFn = {
    lockMember: ((member: any) => {
      this.memberKey = member.Fixed_memberid;
      if (member._isLocked) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: 'Are you sure you want to unlock this account?',
            title: 'Unlock Participant',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.memberStore.dispatch(
              MemberListActions.unlockMember({ id: member._memberId })
            );
          }
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: 'Are you sure you want to lock this account?',
            title: 'Lock Participant',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.memberStore.dispatch(
              MemberListActions.lockMember({ id: member._memberId })
            );
          }
        });
      }
    }).bind(this),
    removeMember: ((member: any) => {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'THIS IS A DESTRUCTIVE ACTION',
          type: ConfirmType.Destruct,
          text: `Removing this Participant will remove all traces of them, including all data and files, from the system.<br> Are you sure you want to proceed? This action is not reversible.`,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.memberKey = member.Fixed_memberid;
          this.memberStore.dispatch(
            MemberListActions.removeMember({
              id: member._memberId,
              memberKey: this.memberKey,
            })
          );
        }
      });
    }).bind(this),
  };
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageSizeOptions: (string | number)[] = [10, 20, 30, 40, 50, 100, 200];
  pageNumber: number = FIRST_PAGE;
  isLoading: boolean = true;
  isLoadingMetadataSection: boolean = true;
  memberListStore$: Observable<State>;
  currentFund: any = {};
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '',
    },
  ];
  settings: Breadcrumb[] = [
    {
      name: 'Participant Metadata',
      url: '/member/member-metadata',
    },
    {
      name: 'Status Events',
      url: '/member/status',
    },
    {
      name: 'Lookup Tables',
      url: '/member/lookup-table',
    },
    {
      name: 'Participant List',
      url: '/member/member-list-configuration',
    },
    {
      name: 'Participant Search',
      url: '/member/participant-search-configuration',
    },
    // hidden for release 2608
    // {
    //   name: 'Tiers',
    //   url: '/member/member-list-tier',
    // },
    // {
    //   name: 'Calculation',
    //   url: '/member/calculation',
    // },
  ];
  memberKey: string = '';

  memberData: {
    demographicMetadata: any;
    memberList: MemberList | undefined;
  } = {
      demographicMetadata: null,
      memberList: undefined,
    };
  query!: MemberListQuery;
  FILTER_ACTION = {
    ADD: 'add',
    LOAD: 'load',
  };
  filterOptions: { iconName: string; label: string; value: string }[] = [
    {
      iconName: 'add_circle',
      label: 'New Filter',
      value: this.FILTER_ACTION.ADD,
    },
    {
      iconName: 'upload',
      label: 'Load Filter',
      value: this.FILTER_ACTION.LOAD,
    },
  ];
  selectedFilter: string = this.filterOptions[0].value;
  currentFilters: MemberFilter[] = [];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New Participant',
      icon: 'add',
      classInput: 'add-button',
    },
    {
      buttonName: 'Export',
      icon: 'file_download',
      classInput: 'add-button',
      isDisabled: false,
    },
    {
      buttonName: 'Send Email',
      icon: 'send-email',
      classInput: 'add-button',
      isDisabled: true,
      isSvgIcon: true,
    },
  ];
  metadata: any[] = [];
  isCheckingConditionAddNew = false;
  isError = false;
  routeState: any;
  isGetPageSize = true;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private metadataSectionService: MetadataSectionService,
    private router: Router,
    public dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private historyService: HistoryService,
    private memberDetailService: MemberDetailService,
    private nextPaymentService: NextPaymentService,
    public memberListService: MemberListService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public authService: Auth0Service
  ) {
    super();
    this.memberListStore$ = memberStore.pipe(
      select(fromMember.selectMemberListState),
      takeUntil(this.unsubscribe$)
    );

    iconRegistry.addSvgIconLiteral(
      'remove-account',
      sanitizer.bypassSecurityTrustHtml(removeAccountIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'group-account',
      sanitizer.bypassSecurityTrustHtml(switchAccountIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'arrowDropDown',
      sanitizer.bypassSecurityTrustHtml(arrowDropDown)
    );
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.routeState = this.router.getCurrentNavigation()?.extras.state;
      this.pageNumber = this.routeState?.beforeState?.pageIndex ?? FIRST_PAGE;
      this.pageSize =
        this.routeState?.beforeState?.totalPerPage ?? this.pageSize;
      this.isGetPageSize = false;
      this.store.dispatch(
        LayoutActions.setHistory({ history: { urlBeforeSearch: '' } })
      );
      this.memberStore.dispatch(
        MemberNavigationActions.clearMemberNavigationState()
      );
    }
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.getReportCategorySidebar());

    //TODO remove after implement employer portal
    if (!this.authService.isAdminPortal$.value) {
      return;
    }
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (this.currentFund.id !== el.id) {
          setTimeout(() => {
            if (this.isGetPageSize) {
              this.pageSize = el?.defaultPageSize ?? 50;
              this.pageSize =
                Number(
                  sessionStorage.getItem(
                    this.currentFund.key + '-ptg-members-list-pageSize'
                  )
                ) === 0
                  ? this.pageSize
                  : Number(
                    sessionStorage.getItem(
                      this.currentFund.key + '-ptg-members-list-pageSize'
                    )
                  );
            }
            this.getData(true);
          }, 100);
        }
        this.currentFund = el;
      });
    sessionStorage.setItem('fundId', this.currentFund.fundType);
    // Remove the "Send Email" button
    if (this.currentFund?.fundType !== FundType.MobileAL) {
      this.functionButtons = this.functionButtons.filter(
        (button) => button.buttonName !== 'Send Email'
      );
    }
    this.memberListStore$.subscribe((el) => {
      this.isLoading = el.isLoading || el.isExporting;
      if (el.isError) {
        this.isError = true;
      }

      this.memberData.memberList = el.memberList;
      this.functionButtons[1].isDisabled =
        el.memberList?.message == 'No Columns Configured' || el.isExporting;

      this.lengthPg = el.memberList.total;
      this.mapMemberMetadata();

      if (el.removeState === STATE.SUCCESS) {
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
        showBanner.call(this, BannerType.Success, 'Participant', ACTION.REMOVE);
        this.closeIndividualTab();
        setTimeout(() => {
          if (el.memberList.members.length === 1 && this.pageNumber !== 0) {
            this.gridMemberList.paginator?.jumpToFirst();
          } else {
            this.changePagging({
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          }
        });
      }

      if (el.removeState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'participant', ACTION.REMOVE);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.unLockMemberState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'participant', ACTION.UNLOCK);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.unLockMemberState === STATE.SUCCESS) {
        showBanner.call(this, BannerType.Success, 'Participant', ACTION.UNLOCK);
        if (
          this.currentFilters.filter(
            (item) =>
              item.propertyKey === 'IsLocked' && item.sectionKey === 'Fixed'
          ).length > 0
        ) {
          this.gridMemberList.paginator?.jumpToFirst();
        } else {
          this.getData(true);
        }
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.lockMemberState === STATE.SUCCESS) {
        if (
          this.currentFilters.filter(
            (item) =>
              item.propertyKey === 'IsLocked' && item.sectionKey === 'Fixed'
          ).length > 0
        ) {
          this.gridMemberList.paginator?.jumpToFirst();
        } else {
          this.getData(true);
        }
        showBanner.call(this, BannerType.Success, 'Participant', ACTION.LOCK);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.lockMemberState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'participant', ACTION.LOCK);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.createMemberFilterState === STATE.SUCCESS) {
        showBanner.call(this, BannerType.Success, 'Filter', ACTION.SAVE);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.createMemberFilterState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'Filter', ACTION.SAVE);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.updateFilterState === STATE.SUCCESS) {
        showBanner.call(this, BannerType.Success, 'Filter', ACTION.EDIT);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.updateFilterState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'Filter', ACTION.EDIT);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }
      this.disableEmailButton();
    });

    this.metadataSectionService
      .getMetadataSection('demographics')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (demographicMetadata) => {
          this.memberData.demographicMetadata = demographicMetadata;
          this.isLoadingMetadataSection = false;
          this.mapMemberMetadata();
        },
        () => {
          this.isError = true;
          this.isLoadingMetadataSection = false;
        }
      );

    this.memberStore
      .pipe(select(fromMember.selectFiltersState), takeUntil(this.unsubscribe$))
      .subscribe((filters) => {
        if (filters) {
          this.currentFilters = filters;
          this.getData();
          this.disableEmailButton();
        }
      });

    this.memberStore.dispatch(
      MemberListActions.getFilterByPropertyListRequest()
    );
    this.memberStore
      .select(fromMember.selectListSectionProperty)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((listSectionProperty) => {
        for (let i = 0; i < this.currentFilters.length; i++) {
          if (
            listSectionProperty.findIndex(
              (filter) =>
                filter.propertyKey === this.currentFilters[i].propertyKey &&
                filter.sectionKey === this.currentFilters[i].sectionKey
            ) < 0
          ) {
            this.clearFilter();
            return;
          }
        }
      });
  }

  mapMemberMetadata() {
    if (this.isLoading || this.isLoadingMetadataSection) {
      return;
    }
    this.columns = this.getConfigColumns(
      this.memberData.memberList?.columns || []
    );
    if (
      this.memberData.memberList?.members &&
      this.memberData.demographicMetadata?.properties
    ) {
      const data =
        JSON.parse(JSON.stringify(this.memberData.memberList.members)) || [];
      this.membersData = data.map((member: any) => {
        member._canRemove = stringToBoolean(member._canRemove);
        if (member.namePrefix?.length) {
          member.firstName = `${member.firstName} (${member.namePrefix})`;
        }
        if (member.nameSuffix?.length) {
          member.lastName = `${member.lastName}, ${member.nameSuffix}`;
        }
        return {
          ...member,
          [ICON_REGISTER_FIELDS.registerIconColor]: '#828282',
          [ICON_REGISTER_FIELDS.registerIconName]: member.Fixed_registered
            ? 'account_circle'
            : '',
          [ICON_STATUS_FIELDS.statusIconName]: member._typedValue?.iconName,
          [ICON_STATUS_FIELDS.statusIconColor]: member._typedValue?.color,
          [ICON_STATUS_FIELDS.statusIconLabel]: member._typedValue?.name,
          [ICON_STATUS_FIELDS.eventIconLabel]: member._typedValue?.event,
        };
      });
      return;
    }
    this.membersData = [];
  }

  getConfigColumns(columns: ColumnConfig[]) {
    this.columns = [];
    if (!columns.length) {
      return [];
    }
    columns.forEach((column: any) => {
      const columConfig = getColumConfig(
        column.type,
        column.configValue,
        column.columnKey
      );
      this.columns.push({
        name: column.columnKey,
        header: {
          title: column.columnName,
        },
        truncate: true,
        sortable: column.type === PropertyType.TYPE_REGISTER ? false : true,
        align:
          column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        type: columConfig.type,
        templateArgs: columConfig.templateArgs,
      });
    });
    this.columns.unshift({
      name: '_isLocked',
      width: '62px',
    });
    return this.columns;
  }

  getData(isInit: boolean = false) {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      (sortField =
        this.columns.find((column) => column.name === this.sortInfo.active)
          ?.header?.title || ''),
        (sortType = this.sortInfo.direction === 'desc' ? 1 : 0);
    }
    if (this.currentFilters.length > 0) {
      if (!this.pageSizeOptions.includes('All')) {
        this.pageSizeOptions.push('All');
      }
    }
    this.query = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
      listFilterInfo: this.getListFilterInfo(),
    };
    this.memberStore.dispatch(
      MemberListActions.getMemberList({
        query: this.query,
        idClient: this.currentFund.id,
      })
    );
    if (isInit) {
      this.memberStore.dispatch(
        MemberNavigationActions.setQuery({ query: this.query })
      );
    }
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getData();
    this.closeIndividualTab();
  }

  clickView(e: any) {
    this.memberStore.dispatch(
      MemberNavigationActions.clearMemberNavigationState()
    );
    this.memberStore.dispatch(
      MemberNavigationActions.setQuery({ query: this.query })
    );
    this.memberStore.dispatch(
      MemberNavigationActions.setMembers({
        memberList: this.memberData.memberList as MemberList,
        pageSize: this.query.totalPerPage,
      })
    );
    const url = '/member/detail/' + e._memberId;
    this.memberDetailService.memberId = e._memberId;
    this.nextPaymentService.memberId = e._memberId;
    this.historyService.isNextPayroll = false;
    this.store.dispatch(
      LayoutActions.selectTab({
        tab: DisplayedTabName.IndividualParticipant,
        url,
      })
    );
  }

  changePagging(event: PageEvent) {
    this.pageNumber = event.pageNumber;
    this.pageSize =
      event.pageSize.toString() == 'All' ? this.lengthPg : event.pageSize;
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-members-list-pageSize',
      this.pageSize.toString()
    );
    this.getData();
  }

  addNew() {
    this.setStatePageNumber();
    this.memberStore.dispatch(
      MemberNavigationActions.clearMemberNavigationState()
    );
    this.memberStore.dispatch(
      MemberNavigationActions.setQuery({ query: this.query })
    );
    this.memberStore.dispatch(
      MemberNavigationActions.setMembers({
        memberList: this.memberData.memberList as MemberList,
        pageSize: this.query.totalPerPage,
      })
    );
    const dialogRef = this.dialog.open(AddMemberSectionDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        isAddNewMember: true,
        listFieldsNewMember: this.metadata,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getStatePageNumber();
        this.getData();
        this.memberStore.dispatch(
          MemberNavigationActions.clearMemberNavigationState()
        );
      }
    });
  }

  addSection(
    section: any,
    addToTop: boolean = true,
    isAddNew: boolean = true
  ): void {
    // open add section screen
  }

  closeIndividualTab() {
    this.memberStore
      .pipe(select(fromMember.selectMemberNavigationState), take(1))
      .subscribe((navigationState) => {
        if (navigationState.query?.pageIndex) {
          this.store.dispatch(
            LayoutActions.closeTab({
              tabName: DisplayedTabName.IndividualParticipant,
            })
          );
        }
      });
  }

  onFilterSelect(value: string) {
    if (value === this.FILTER_ACTION.ADD) {
      this.openFilterDialog();
      this.selectedFilter = this.filterOptions[0].value;
      return;
    }
    this.openLoadFilterDialog();
    this.selectedFilter = this.filterOptions[1].value;
  }

  openFilterDialog(isEdit: boolean = false) {
    const dialogRef = this.dialog.open(AddMemberFilterComponent, {
      panelClass: 'edit-popup',
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      maxWidth: '1177px',
      data: isEdit ? { currentFilters: this.currentFilters } : undefined,
    });

    dialogRef.afterClosed().subscribe((filters) => {
      if (filters) {
        if (!isEdit) {
          this.memberListService.currentFilter = undefined;
        }
        this.currentFilters = filters;
        this.pageNumber = 1;
        if (!this.pageSizeOptions.includes('All')) {
          this.pageSizeOptions.push('All');
        }
        this.memberStore.dispatch(
          MemberListActions.setMemberFilter({ filters })
        );
        this.closeIndividualTab();
        this.disableEmailButton();
      }
    });
  }

  getListFilterInfo(): FilterInfo[] {
    return deepClone(this.currentFilters).reduce(
      (result: FilterInfo[], filter: MemberFilter) => {
        if (filter.type === FilterPropertyType.Status) {
          result.push({
            propertyName: filter.propertyName || '',
            propertyKey: filter.propertyKey,
            itemKey: filter.sectionKey || filter.itemKey || '',
            sectionName: filter.sectionKey,
            options: filter.options || '',
            type: filter.type,
            values: filter.values || [],
            operator: filter.operator,
          });
          if (filter.event?.operator) {
            result.push({
              propertyName: filter.propertyName || '',
              propertyKey: filter.propertyKey,
              itemKey: filter.sectionKey || filter.itemKey || '',
              sectionName: filter.sectionKey,
              options: filter.event.options || '',
              type: filter.type,
              values: filter.event.values || [],
              operator: filter.event.operator,
            });
          }
          if (filter.date?.operator) {
            result.push({
              propertyName: filter.propertyName || '',
              propertyKey: filter.propertyKey,
              itemKey: filter.sectionKey || filter.itemKey || '',
              sectionName: filter.sectionKey,
              options: filter.date.options || '',
              type: filter.type,
              values: filter.date.values || [],
              operator: filter.date.operator,
            });
          }
        } else {
          result.push({
            propertyName: filter.propertyName || '',
            propertyKey: filter.propertyKey,
            itemKey: filter.sectionKey || filter.itemKey || '',
            sectionName: filter.sectionKey,
            options: filter.options || '',
            type: filter.type,
            values: filter.values || [],
            operator: filter.operator,
          });
        }

        return result;
      },
      []
    );
  }

  clearFilter() {
    this.currentFilters = [];
    if (this.pageSizeOptions.includes('All')) {
      this.pageSizeOptions.pop();
    }
    this.pageNumber = FIRST_PAGE;
    this.pageSize = 50;
    this.memberStore.dispatch(
      MemberListActions.setMemberFilter({ filters: [] })
    );
    this.closeIndividualTab();
    this.getData(true);
    this.disableEmailButton();
  }

  saveFilter() {
    if (this.memberListService.currentFilter?.id) {
      let dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        data: {
          text: `Would you like to save your changes to <b>${this.memberListService.currentFilter.name}</b> filter or save as a new filter?<br> If you select to save, the changes will impact In Review and Scheduled notifications which use <b>${this.memberListService.currentFilter.name}</b> filter.`,
          type: ConfirmType.ConfirmSave,
          cancelButtonTitle: 'Cancel',
          title: 'Confirmation',
          hideConfirmButton: true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.isSaveAs) {
            this.saveNewFilter();
          } else {
            this.memberStore.dispatch(
              MemberListActions.updateFilterNameRequest({
                memberFilterName: {
                  id: this.memberListService.currentFilter?.id,
                  name: this.memberListService.currentFilter?.name as string,
                  listFilterInfo: this.getListFilterInfo(),
                },
              })
            );
          }
          this.disableEmailButton();
        }
      });
    } else {
      this.saveNewFilter();
    }
  }

  saveNewFilter() {
    this.dialog.open(SaveMemberFilterComponent, {
      panelClass: 'save-filter-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: this.getListFilterInfo(),
    });
  }

  openLoadFilterDialog() {
    const dialogRef = this.dialog.open(ManageMemberFilterComponent, {
      panelClass: 'edit-popup',
      height: 'auto',
      width: 'auto',
      minWidth: '950px',
      disableClose: true,
      autoFocus: false,
      minHeight: '185px',
      data: this.getListFilterInfo(),
    });

    dialogRef.afterClosed().subscribe((filters) => {
      if (filters) {
        this.memberListService.currentFilter = filters;
        this.currentFilters = this.getCurrentFilter(filters.listFilterInfo);
        if (!this.pageSizeOptions.includes('All')) {
          this.pageSizeOptions.push('All');
        }
        this.memberStore.dispatch(
          MemberListActions.setMemberFilter({ filters: this.currentFilters })
        );
        this.closeIndividualTab();
        this.gridMemberList.paginator?.jumpToFirst();
      }
    });
  }

  getCurrentFilter(filters: FilterInfo[]): MemberFilter[] {
    const statusIndex = filters.findIndex(
      (filter) => filter.type === FilterPropertyType.Status
    );
    return (deepClone(filters) as FilterInfo[]).reduce(
      (result: MemberFilter[], filter, currentIndex: number, array) => {
        if (filter.type !== FilterPropertyType.Status) {
          result.push({
            ...filter,
            sectionKey: filter.itemKey,
          });
        } else {
          if (filter.options === 'Name') {
            result.push({
              ...filter,
              sectionKey: filter.itemKey,
              options: 'Name',
            });
          }
          if (filter.options === 'Event') {
            result[statusIndex].event = {
              options: 'Event',
              operator: filter.operator,
              values: filter.values,
            };
          }
          if (filter.options === 'Date') {
            result[statusIndex].date = {
              options: 'Date',
              operator: filter.operator,
              values: filter.values,
            };
          }
        }
        return result;
      },
      []
    );
  }

  emitFunction(event: FunctionButtonData) {
    switch (event.buttonName) {
      case 'New Participant':
        if (event.buttonName === 'New Participant') {
          if (this.isCheckingConditionAddNew) {
            return;
          }
          this.memberStore.dispatch(
            MemberDetailActions.getMetadataSectionsAction()
          );
          this.isCheckingConditionAddNew = true;
          let metadata$ = this.memberStore
            .pipe(
              select(fromMember.getMetadataSectionsSelector),
              takeUntil(this.unsubscribe$)
            )
            .subscribe((el) => {
              if (
                el &&
                el.payload?.length &&
                this.filterSectionAndProperties(el.payload).length > 0
              ) {
                this.metadata = this.filterSectionAndProperties(el.payload);
                this.addNew();
                metadata$.unsubscribe();
                this.isCheckingConditionAddNew = false;
              } else if (
                el &&
                el.payload?.length &&
                this.filterSectionAndProperties(el.payload).length === 0
              ) {
                const dialogRef = this.dialog.open(ConfirmPopupComponent, {
                  panelClass: 'confirm-popup',
                  data: {
                    title: 'Attention',
                    text: 'Required information to add a new participant is not defined yet, please contact your system administrator for support.',
                    type: ConfirmType.Warning,
                    cancelButtonTitle: 'Close',
                    hideConfirmButton: true,
                  },
                });
                this.memberStore.dispatch(
                  MemberDetailActions.clearGetMetadataSectionsStateAction()
                );
                metadata$.unsubscribe();
                this.isCheckingConditionAddNew = false;
              }
            });
          return;
        }
        break;
      case 'Export':
        if (event.buttonName === 'Export') {
          this.onClickExport();
          // this.temporarilyDisabledExport();
          return;
        }
        break;
      case 'Send Email':
        {
          if (this.membersData.length > 0) {
            const membersId = this.membersData.map((data: any) => {
              return data._memberId;
            });
            const contactInformationKey =
              sessionStorage.getItem('keyContactInformation') || '';
            this.memberListService
              .getEmailForParticipantList({
                memberIds: membersId,
                contactInfoItemKey: contactInformationKey,
              })
              .subscribe(
                (result: any) => {
                  if (result.emails) {
                    const dialogRef = this.dialog.open(EmailComponent, {
                      panelClass: '',
                      disableClose: false,
                      data: {
                        title: 'Send Email',
                        mail: {
                          recipientsTo: result.emails,
                        },
                        createdBy: 'System',
                        isSingleRecipient: false,
                      },
                      height: 'auto',
                      width: 'auto',
                    });
                    dialogRef.componentInstance.title = 'Send Email';
                  }
                },
                (error: any) => {
                  console.log(error);
                }
              );
          }
        }
        break;
    }
  }

  filterSectionAndProperties(list: any[]) {
    let listSectionStatic = JSON.parse(JSON.stringify(list));
    // Filter Properties
    listSectionStatic.forEach((el: any) => {
      el.properties = el.properties.filter(
        (property: any) =>
          stringToBoolean(property.config.required) &&
          stringToBoolean(property.config.initiated)
      );
    });
    // Filter to remove Section don't have any property required & initiated
    listSectionStatic = listSectionStatic?.filter(
      (el: any) => el.properties.length > 0
    );
    return listSectionStatic;
  }

  temporarilyDisabledExport() {
    this.functionButtons = [
      this.functionButtons[0],
      {
        ...this.functionButtons[1],
        isDisabled: true,
      },
    ];
    setTimeout(() => {
      this.functionButtons = [
        this.functionButtons[0],
        {
          ...this.functionButtons[1],
          isDisabled: false,
        },
      ];
    }, 1000);
  }

  setStatePageNumber() {
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-members-list-pageNumber',
      this.pageNumber.toString()
    );
  }

  getStatePageNumber() {
    this.pageNumber =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-ptg-members-list-pageNumber'
        )
      ) === 0
        ? 1
        : Number(
          sessionStorage.getItem(
            this.currentFund.key + '-ptg-members-list-pageNumber'
          )
        );
  }

  onClickExport() {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      (sortField =
        this.columns.find((column) => column.name === this.sortInfo.active)
          ?.header?.title || ''),
        (sortType = this.sortInfo.direction === 'desc' ? 1 : 0);
    }
    const datePipe = new DatePipe('en-US');
    const request: ExportFileRequest = {
      fileName: `ParticipantList_${datePipe.transform(
        new Date(),
        'MMddyy_HHmmss'
      )}`,
      listFilter: this.getListFilterInfo(),
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
    };
    this.store.dispatch(
      MemberListActions.exportMembersRequest({ request: request })
    );
    // this.memberListService.exportMemberList(request);
  }

  disableEmailButton() {
    // Check if functionButtons array exists and is not empty
    if (!this.functionButtons || this.functionButtons.length === 0) {
      return;
    }

    // Find the index of the button with the name 'Send Email'
    const emailButtonIndex = this.functionButtons.findIndex(
      (x) => x.buttonName === 'Send Email'
    );

    // Check if the button index is valid
    if (emailButtonIndex !== -1 && this.functionButtons[emailButtonIndex]) {
      // Check if there are any current filters applied
      if (this.currentFilters && this.currentFilters.length > 0) {
        // Check if members data is available
        if (this.membersData && this.membersData.length > 0) {
          // Find if any member has contact information related to email
          const hasAnyContactInfoEMail = this.membersData.some((member: any) =>
            Object.keys(member).some(
              (key) =>
                key.includes('contactInformation') &&
                member[key] !== null &&
                member[key] !== undefined
            )
          );
          // Set the button's disabled state based on whether contact information exists
          this.functionButtons[emailButtonIndex].isDisabled = !hasAnyContactInfoEMail;
        } else {
          // Disable the button if no members data is present
          this.functionButtons[emailButtonIndex].isDisabled = true;
        }
      } else {
        // Disable the button if no filters are applied
        this.functionButtons[emailButtonIndex].isDisabled = true;
      }
    }
  }
}
