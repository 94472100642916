import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { DetailDisplay } from '@ptg-shared/models/detail-display.model';
import { DataType } from '@ptg-shared/constance/data-type.const';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../reducers';
import * as MunicipalityServiceHistoryActions from '../../actions/municipality-service-history.action';
import { GetListStatusHistoryQuery, History } from '../../types/models';
import { EditMemberServiceHistoryComponent } from '../../components/edit-municipality-service-history/edit-municipality-service-history.component';
import { OverviewHeaderComponent } from '../../components/overview-header/overview-header.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

@Component({
  selector: 'ptg-municipality-service-history',
  templateUrl: './municipality-service-history.component.html',
  styleUrls: ['./municipality-service-history.component.scss'],
})
export class MunicipalityServiceHistoryComponent implements OnInit, OnDestroy {
  @Input() currentPageName?: string;
  currentFund: any = {};
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Service History',
    },
  ];
  columns: Column[] = [
    {
      name: 'beginDate',
      header: {
        title: 'Begin Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'endDate',
      header: {
        title: 'End Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'serviceTime',
      header: {
        title: 'Service',
      },
      sortable: true,
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = false;
  dataTable: History[] = [];
  listArregations: any[] = [];
  items: any[] = [];
  memberId: string = '';
  selectedRowId!: string;
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  propertyDisplayed: DetailDisplay[] = [
    {
      label: 'Begin Date',
      propertyName: 'beginDate',
      type: DataType.TYPE_DATE,
    },
    { label: 'End Date', propertyName: 'endDate', type: DataType.TYPE_DATE },
    {
      label: 'Municipality',
      propertyName: 'municipalityName',
      type: DataType.TYPE_TEXT,
    },
    { label: 'Service', propertyName: 'serviceTime', type: DataType.TYPE_TEXT },
  ];
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;

  constructor(
    private route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.selectedRowId = params.recordId;
        if (params.pageSize && params.pageIndex) {
          this.pageNumber = Number.isNaN(Number(params.pageIndex))
            ? 0
            : +params.pageIndex;
          this.pageSize = Number.isNaN(Number(params.pageSize))
            ? 10
            : +params.pageSize;
        }
      });
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.currentFund = el;
        this.pageSize = el.defaultPageSize ?? 50;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-ptg-municipality-service-history-pageSize')) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-municipality-service-history-pageSize'));

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.getData();
    });
    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityServiceRecordState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.serviceRecords?.total;
        this.isLoading = state?.isLoading;
        this.dataTable = state?.serviceRecords?.serviceRecords;
        showBanner.call(
          this,
          state.createState || '',
          this.currentPageName ? this.currentPageName : 'Service Record',
          ACTION.ADD
        );
        showBanner.call(
          this,
          state.updateState || '',
          this.currentPageName ? this.currentPageName : 'Service Record',
          ACTION.EDIT
        );
        if (
          state.createState == STATE.SUCCESS ||
          state.updateState == STATE.SUCCESS
        ) {
          this.reloadOverviewHeader();
          this.getData();
        }
        if (state.createState || state.updateState) {
          this.memberStore.dispatch(
            MunicipalityServiceHistoryActions.clearMunicipalityServiceHistoryState()
          );
        }
        this.listArregations = [
          {
            items: [
              {
                name: 'Begin Date',
                value: state?.serviceRecords?.beginDate,
              },
              {
                name: 'End Date',
                value: state?.serviceRecords?.endDate,
              },
              {
                name: 'Total Service',
                value: state?.serviceRecords?.totalService,
              },
            ],
          },
        ];
        if (this.selectedRowId) {
          this.currentRowIndex = this.dataTable.findIndex(
            (row: any) => row.id === this.selectedRowId
          );
        }
      });
  }

  getData() {
    let sortType = 1;
    let sortNames = ['default'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MunicipalityServiceHistoryActions.getMunicipalityServiceHistoryList({
        query,
      })
    );
  }

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + "-ptg-municipality-service-history-pageNumber", this.pageNumber.toString());
    sessionStorage.setItem(this.currentFund.key + "-ptg-municipality-service-history-pageSize", this.pageSize.toString()); 
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.dataTable.findIndex(
      (item) => item.id === event.id
    );
  }

  onEditServiceHistory(serviceHistory?: History) {
    this.dialog.open(EditMemberServiceHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: this.currentPageName,
        serviceHistory,
      },
    });
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
