import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers/index';

import {
  clearGetMetadataNonListSectionsStateAction,
  clearGetMetadataSectionsStateAction,
} from '../actions/member-detail.actions';
import {
  AddSectionBody,
  BodyChangeKey,
  CheckExist,
  MetadataOverview,
  MetadataPropertyTypeView,
  MetadataSectionsNonListResponse,
  MetadataView,
  PropertyNavType,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  activeFund: any;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.activeFund = el;

        this.store.dispatch(clearGetMetadataSectionsStateAction());
        this.store.dispatch(clearGetMetadataNonListSectionsStateAction());
      });
  }

  getMetadataSections(query?: any): Observable<MetadataView> {
    let params = new HttpParams();
    if (query && query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<MetadataView>(
      `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata`,
      { params }
    );
  }

  getMetadataNonList(
    memberNavigationItemId?: string
  ): Observable<MetadataSectionsNonListResponse> {
    let params = new HttpParams();
    if (memberNavigationItemId) {
      params = params.append('MemberNavigationItemId', memberNavigationItemId);
    }
    return this.httpClient.get<MetadataSectionsNonListResponse>(
      `${environment.apiUrl}/Metadata/${this.activeFund?.key}/MetadataNonList`,
      { params }
    );
  }

  orderTable(body: BodyChangeKey) {
    return this.httpClient.put(
      `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${body.reorderItemKey}/Order`,
      body
    );
  }

  checkNewSectionExist = (bodyCheck: CheckExist) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Clients/Metadata/${this.activeFund?.key}/Exists/AddNewMetadata`,
      bodyCheck
    );
  };

  addSection(body: AddSectionBody) {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/${this.activeFund?.key}/AddNew`,
      body
    );
  }

  removeSection(metadataKey: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Metadata/${this.activeFund?.key}/${metadataKey}/Delete`
    );
  }

  getMetadataPropertyTypes(
    type: PropertyNavType
  ): Observable<MetadataPropertyTypeView> {
    return this.httpClient.get<MetadataPropertyTypeView>(
      `${environment.apiUrl}/Metadata/PropertyTypes/${type.navType}`
    );
  }

  getMetadataItemByProfileOverviewConfig(
    memberId: string
  ): Observable<MetadataOverview> {
    return this.httpClient.get<MetadataOverview>(
      `${environment.apiUrl}/Metadata/GetMetadataItemByProfileOverviews/${memberId}`
    );
  }
}
