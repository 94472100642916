import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { LayoutActions } from '@ptg-shared/layout/actions';
import {
  getValueWithoutFormat,
  isEmpty,
  setFractionalLength,
  stringToBoolean,
} from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';

import { LookupTableType, SectionLayout } from '../../constance/metadata.const';
import { MemberDetailActions } from '../../actions';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import {
  FieldData,
  FormControlDetail,
  MemberStatus,
  MetadataSection,
  OptionValue,
} from '../../types/models';
import * as fromMember from '../../reducers';
import { MemberDetailService } from '../../services/member-detail.service';
import { MemberListService } from '../../services/member-list.service';

export interface DialogData {
  section: MetadataSection;
  memberId: string;
  metadataItem: any;
  addToTop: boolean;
  data: any;
  isAddNew: boolean;
  isAddNewMember?: boolean;
  notShowFile?: boolean;
  memberStatus?: MemberStatus;
  customHeader?: string;
  listFieldsNewMember?: any[];
  isAllData: boolean;
  menuId?: string;
}

@Component({
  selector: 'ptg-add-member-section-data',
  templateUrl: './add-member-section-data.component.html',
  styleUrls: ['./add-member-section-data.component.scss'],
})
export class AddMemberSectionDataComponent implements OnInit, OnDestroy {
  readonly PropertyType = PropertyType;
  frmAddSectionData = new FormGroup({});
  metaData$: Observable<any> = new Observable();
  lstFormControl: FormControlDetail[] = [];
  lstInputAddress: FieldData[] = [
    new FieldData('street1', 'Street1', 'Street1', 'text', true, '46', 'w380'),
    new FieldData(
      'street2',
      'Street2',
      'Street 2 (Apartment No./Suite No.)',
      'text',
      false,
      '46',
      'w380'
    ),
    new FieldData('city', 'City', 'City', 'text', true, 'none', 'w182'),
    new FieldData(
      'state',
      'State',
      'State/Province/Territory',
      'select',
      true,
      'none',
      'w182'
    ),
    new FieldData(
      'zipCode',
      'ZipCode',
      'Zip Code/Postal Code',
      'text',
      true,
      '10',
      'w182'
    ),
    new FieldData(
      'country',
      'Country',
      'Country',
      'select',
      false,
      'none',
      'w182'
    ),
  ];
  lstInputPersonName: FieldData[] = [
    new FieldData(
      'prefix',
      'Prefix',
      'Prefix',
      'select',
      false,
      'none',
      'w167'
    ),
    new FieldData(
      'first',
      'First',
      'First Name',
      'text',
      true,
      'none',
      'w152 first-name'
    ),
    new FieldData(
      'middle',
      'Middle',
      'Middle Name',
      'text',
      false,
      'none',
      'w152'
    ),
    new FieldData(
      'last',
      'Last',
      'Last Name',
      'text',
      true,
      'none',
      'w152 last-name'
    ),
    new FieldData('suffix', 'Suffix', 'Suffix', 'text', false, 'none', 'w89'),
  ];
  acceptDocument = 'application/pdf';
  typeFileDocument = 'pdf file format';
  checkPatternDocument = new RegExp(`^.*\.(pdf)$`, 'i');
  errDuplicated = '';
  currentSection: MetadataSection;
  listSectionStatic: any[] = [];
  renewForm = true;
  dataNewMember: any[] = [];
  isAddNewMemberSuccess = false;
  subscription: any;
  sectionLayout = SectionLayout;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Add New Participant',
    },
  ];
  unsubscribe$ = new Subject<void>();
  listStatus: Option[] = [];
  listStatusEvent: Option[] = [];
  currentMemberDetailUnmask: any;
  formSubmit$ = new Subject<boolean>();
  allStatus: MemberStatus[] = [];

  // Workaround: Use a flag to check save data with the async validators
  isSave: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddMemberSectionDataComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private memberStore: Store<fromMember.MemberState>,
    public memberListService: MemberListService,
    private router: Router,
    private store: Store<fromReducer.State>,
    private memberDetailService: MemberDetailService
  ) {
    this.currentSection = this.data.section;
  }

  ngOnInit(): void {
    this.getListStatus();
    if (!this.data.isAddNewMember) {
      this.memberStore.dispatch(
        MemberDetailActions.getMemberDetailUnMaskedRequest({
          memberId: this.data.memberId,
          isAllData: this.data?.isAllData,
          listSection: [this.data.section?.key]
        })
      );
      this.memberStore
        .pipe(
          select(fromMember.selectMemberDataUnMasked),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((memberData) => {
          this.currentMemberDetailUnmask = deepClone(memberData);
          this.setValue();
        });
      this.listBreadcrumbs[0].name =
        (this.data?.isAddNew ? 'Add New ' : 'Edit ') +
        (this.data?.customHeader
          ? this.data?.customHeader
          : this.data?.section?.sectionTitle);
    } else {
      this.listSectionStatic = this.data.listFieldsNewMember as any;
      this.currentSection = this.listSectionStatic[0];
      setTimeout(() => {
        this.changeBreadcrumb(this.listSectionStatic[0]?.key);
      }, 500);
    }
    this.setLstFormControl();
    this.memberDetailService.statusId = '';
    this.memberDetailService.eventId = '';
    this.setFormGroupCs();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setFormGroupCs() {
    this.lstFormControl.forEach((f) => {
      if (f.formControl !== undefined) {
        this.frmAddSectionData.setControl(f.name, f.formControl);
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            this.frmAddSectionData.setControl(f.name + x.name, x.formControl);
          }
        });
      }
    });
    if (this.currentSection?.key === this.listSectionStatic[0]?.key) {
      this.frmAddSectionData.setControl(
        'statusId',
        new FormControl(this.data?.memberStatus?.id ?? this.memberDetailService.statusId, Validators.required)
      );
      this.frmAddSectionData.setControl(
        'eventId',
        new FormControl(this.memberDetailService.eventId ?? null, Validators.required)
      );
    }
  }

  changeBreadcrumb(id: string) {
    let z = document.getElementsByClassName('selected')[0];
    z?.classList?.remove('selected');
    let x: any = document.getElementById(id);
    x?.classList?.add('selected');
    x?.scrollIntoView({ block: 'center' });
    let indexSection = this.listSectionStatic.findIndex((el) => el.key === id);
    let wrapSection: any = document.getElementById('wrap-section');
    let breadcrumb: any =
      document.getElementsByClassName('breadcrumb-section')[0];

    if (x?.offsetWidth >= wrapSection?.offsetWidth) {
      x?.scrollIntoView({ inline: 'start' });
    } else {
      x?.scrollIntoView({ inline: 'center' });
    }
    if (
      (x?.offsetWidth + x?.offsetLeft < wrapSection?.scrollWidth &&
        wrapSection?.scrollWidth > breadcrumb.offsetWidth &&
        (breadcrumb.offsetWidth - x?.offsetWidth) / 2 <
          wrapSection?.scrollWidth - x?.offsetWidth - x?.offsetLeft - 8) ||
      x?.offsetWidth > wrapSection?.offsetWidth
    ) {
      breadcrumb.classList.add('endTruncate');
    } else {
      breadcrumb.classList.remove('endTruncate');
    }

    if (indexSection === 0) {
      breadcrumb.classList.remove('startTruncate');
    } else if (this.listSectionStatic.length > 1) {
      // let preSeection = document.getElementById(this.listSectionStatic[indexSection - 1].key);
      if (x?.offsetLeft * 2 + x?.offsetWidth - 21 > wrapSection?.offsetWidth) {
        breadcrumb.classList.add('startTruncate');
      } else {
        breadcrumb.classList.remove('startTruncate');
      }
    }
  }

  onSubmit() {
    this.frmAddSectionData.markAllAsTouched();
    this.isSave = true;
    if (this.frmAddSectionData.disabled || this.frmAddSectionData.valid) {
      this.saveValue();
      this.isSave = false;
    }
  }
  saveValue() {
    // set param
    const body: any = {
      sectionKey: this.currentSection.key,
      memberId: this.data.memberId,
      statusId: this.frmAddSectionData?.value?.statusId,
      propertyValues: [],
    };
    for (const f of this.lstFormControl) {
      let value = f.formControl?.value;
      switch (f.type) {
        case PropertyType.TYPE_ADDRESS:
        case PropertyType.TYPE_PERSON_NAME:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            objVal[childForm.name] = childForm.formControl?.value;
          }
          value = isHaveData ? JSON.stringify(objVal) : '';
          break;
        case PropertyType.TYPE_DATE:
          const datepipe = new DatePipe('en-US');
          value = value ? datepipe.transform(value, 'yyyy-MM-dd') : '';
          break;
        case PropertyType.TYPE_UPLOAD_DOC:
          break;
        case PropertyType.TYPE_BOOLEAN:
          value = value ? true : false;
          break;
        case PropertyType.TYPE_PERCENTAGE:
          value = value?.toString() || '';
          value =
            (value?.endsWith('.')
              ? value?.substring(0, value.length - 1)
              : value) || '';
          break;
        case PropertyType.TYPE_SSN:
        case PropertyType.TYPE_PHONE:
          value = getValueWithoutFormat(value);
          break;
        default:
          value = isEmpty(value) ? '' : value?.toString();
      }
      body.propertyValues.push({
        propertyKey: f.name,
        type: f.type,
        value: value,
      });
    }
    if (this.data.isAddNew) {
      if (this.currentSection.type === this.sectionLayout.List) {
        body.addToTop = this.data.addToTop;
        this.memberStore.dispatch(
          MemberDetailActions.addSectionTable({
            memberId: this.data.memberId,
            metadataItem: this.currentSection.key,
            body: body,
            sectionData: this.currentSection,
          })
        );
      } else {
        this.memberStore.dispatch(
          MemberDetailActions.addSectionDataListView({
            body: body,
            sectionData: this.currentSection,
          })
        );
      }
    } else {
      if (this.currentSection.type !== this.sectionLayout.List) {
        this.memberStore.dispatch(
          MemberDetailActions.editSectionDataListView({
            body: body,
            sectionData: this.currentSection,
          })
        );
      } else {
        body.index = this.data.data[this.currentSection.key].index;
        this.memberStore.dispatch(
          MemberDetailActions.editSectionTable({
            memberId: this.data.memberId,
            metadataItem: this.currentSection.key,
            body: body,
            sectionData: this.currentSection,
          })
        );
      }
    }

    this.dialogRef.close('submit');
  }

  onCancel() {
    this.memberDetailService.statusId = '';
    this.memberDetailService.eventId = '';
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close(this.isAddNewMemberSuccess);
      }
    });
  }

  setLstFormControl() {
    this.lstFormControl = [];
    this.currentSection?.properties.forEach((prop: any) => {
      prop = { ...prop };
      if (
        prop.type === PropertyType.TYPE_ADDRESS ||
        prop.type === PropertyType.TYPE_PERSON_NAME
      ) {
        // Address type | Person name type
        const lstChildForm =
          prop.type === PropertyType.TYPE_ADDRESS
            ? JSON.parse(JSON.stringify(this.lstInputAddress))
            : JSON.parse(JSON.stringify(this.lstInputPersonName));
        lstChildForm.forEach((inp: FieldData) => {
          const formControl = new FormControl(null);
          inp.lstOption = [];
          if (
            inp.type === 'select' &&
            prop.config?.prefixList === 'true' &&
            prop.options &&
            prop.options.length > 0
          ) {
            inp.lstOption = prop.options.map((x: any) => {
              return {
                displayValue: x.text,
                value: x.id,
              };
            });
          }
          if (inp.key === 'state' && prop.options && prop.options.length > 0) {
            inp.lstOption = deepClone(prop)
              .options.filter(
                (option: OptionValue) => option.type === LookupTableType.State
              )
              .map((option: OptionValue) => {
                return {
                  displayValue: option.text,
                  value: option.id,
                };
              });
          }
          if (
            inp.key === 'country' &&
            prop.options &&
            prop.options.length > 0
          ) {
            inp.lstOption = deepClone(prop)
              .options.filter(
                (option: OptionValue) => option.type === LookupTableType.Country
              )
              .map((option: OptionValue) => {
                return {
                  displayValue: option.text,
                  value: option.id,
                };
              });
          }
          inp.formControl = formControl;
          if (stringToBoolean(prop.config?.readOnly)) {
            formControl.disable();
          }
        });
        this.lstFormControl.push({
          name: prop.key,
          label: prop.name,
          type: prop.type,
          lstChildFormControl: lstChildForm,
          isRequired: prop.config?.required === 'true',
        } as FormControlDetail);
      } else {
        // One input type
        const formControl = new FormControl(null);
        const formControlDetail: FormControlDetail = {
          name: prop.key,
          label: prop.name,
          type: prop.type,
          lstOption: [],
          formControl: formControl,
        };
        let maxLengthAttr = 'none';

        // validate by property config
        const isExistingMinVal =
          stringToBoolean(prop.config?.inclusiveInRange) &&
          this.isValidString(prop.config?.minInput) &&
          !isNaN(Number(prop.config?.minInput));
        const isExistingMaxVal =
          stringToBoolean(prop.config?.inclusiveInRange) &&
          this.isValidString(prop.config?.maxInput) &&
          !isNaN(Number(prop.config?.maxInput));
        const isExistingMaxLengthVal =
          stringToBoolean(prop.config?.maximumLength) &&
          this.isValidString(prop.config?.maxLengthInput) &&
          !isNaN(Number(prop.config?.maxLengthInput));
        const isExistingFracLengthVal =
          stringToBoolean(prop.config?.fractionalLength) &&
          this.isValidString(prop.config?.fractionalLengthInput) &&
          !isNaN(Number(prop.config?.fractionalLengthInput));

        formControlDetail.isRequired = prop.config?.required === 'true';

        if (isExistingMinVal) {
          formControlDetail.min = prop.config?.minInput.trim();
        }
        if (isExistingMaxVal) {
          formControlDetail.max = prop.config?.maxInput.trim();
        }
        if (isExistingMaxLengthVal && !isExistingMinVal && !isExistingMaxVal) {
          maxLengthAttr = prop.config?.maxLengthInput;
        }
        if (isExistingFracLengthVal) {
          const fractionalLength = Number(prop.config?.fractionalLengthInput);
          if (fractionalLength === 0) {
            prop.type = PropertyType.TYPE_WHOLE_NUMBER;
          } else {
            const rgx = new RegExp(
              `^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${fractionalLength}})?$`
            );
            formControl.addValidators(Validators.pattern(rgx));
            formControlDetail.fractionalLength = fractionalLength.toString();
          }
        }

        if (prop.type === PropertyType.TYPE_CURRENCY) {
          formControl.setValue(null);
        }
        if (
          prop.type === PropertyType.TYPE_DECIMAL &&
          !isExistingFracLengthVal
        ) {
          prop.type = PropertyType.TYPE_WHOLE_NUMBER;
        }

        if (prop.type === PropertyType.TYPE_PERCENTAGE) {
          formControl.addValidators(Validators.min(Number(0)));
          formControlDetail.min = 0;

          formControl.addValidators(Validators.max(Number(100)));
          formControlDetail.max = 100;

          const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${2}})?$`);
          formControl.addValidators(Validators.pattern(rgx));
          formControlDetail.fractionalLength = 2;
        }

        // set list option for List type
        if (
          prop.type === PropertyType.TYPE_LIST &&
          prop.options &&
          prop.options.length > 0
        ) {
          prop.lstOption = prop.options.map((x: any) => ({
            displayValue: x.text,
            value: x.id,
          }));
        } else if (
          (prop.type === PropertyType.TYPE_EMPLOYER ||
            prop.type === PropertyType.TYPE_DEPARTMENT) &&
          prop.options &&
          prop.options.length > 0
        ) {
          if (this.data.isAddNew || this.data.isAddNewMember) {
            prop.lstOption = prop.options
              .filter((option: any) => option.active)
              .map((x: any) => ({
                displayValue: x.text,
                value: x.id,
                valueDescription: x.description,
              }));
          } else {
            const sectionData = this.data.data[this.currentSection.key] || {};
            const currentValue = sectionData[prop.key];
            prop.lstOption = prop.options
              .filter(
                (option: any) =>
                  option.active ||
                  option.text === currentValue ||
                  option.id === currentValue
              )
              .map((x: any) => ({
                displayValue: x.text,
                value: x.id,
                valueDescription: x.description,
              }));
          }
        } else if (
          prop.type === PropertyType.TYPE_LIST &&
          prop.options &&
          prop.options.length > 0
        ) {
          prop.lstOption = prop.options.map((x: any) => ({
            displayValue: x.text,
            value: x.id,
          }));
        }
        if (stringToBoolean(prop.config?.readOnly)) {
          formControl.disable();
        }
        if (prop.config?.unique === 'true') {
          formControl.addAsyncValidators(
            checkApiValidator(
              this.memberDetailService.checkUnique,
              'value',
              undefined,
              {
                params: {
                  sectionKey: this.currentSection.key,
                  propKey: prop.key,
                  propType: prop.type,
                  memberId: this.data.memberId,
                  index: this.data.data
                    ? this.data.data[this.currentSection.key]?.index
                    : undefined,
                },
              },
              () => {
                if (
                  this.isSave &&
                  (this.frmAddSectionData.disabled ||
                    this.frmAddSectionData.valid)
                ) {
                  this.saveValue();
                  this.isSave = false;
                }
              }
            )
          );
        }

        formControlDetail.type = prop.type;
        formControlDetail.maxLength = maxLengthAttr;
        formControlDetail.lstOption = prop.lstOption;
        formControlDetail.config = prop.config;
        this.lstFormControl.push(formControlDetail);
      }
    });
  }

  setValue() {
    if (this.data.isAddNew) {
      this.lstFormControl.forEach((element) => {
        if (element.type === PropertyType.TYPE_BENEFIT) {
          element.formControl?.setValue(
            this.currentMemberDetailUnmask[this.currentSection.key].benefitValue
          );
        }
      });
      return;
    }
    const sectionData =
      this.currentSection.type === this.sectionLayout.List
        ? this.currentMemberDetailUnmask[this.currentSection.key]?.table?.find(
            (x: any) =>
              x.rowId === this.data?.data[this.currentSection.key]?.rowId
          )
        : this.currentMemberDetailUnmask[this.currentSection.key];
    if (!sectionData) {
      return;
    }
    if (this.currentSection.type === this.sectionLayout.List) {
      this.currentSection?.properties.forEach((el: any) => {
        if (
          el.type === PropertyType.TYPE_LIST ||
          el.type === PropertyType.TYPE_EMPLOYER ||
          el.type === PropertyType.TYPE_DEPARTMENT
        ) {
          sectionData[el.key] = el?.options?.find(
            (op: any) =>
              op.text === sectionData[el.key] || op.id === sectionData[el.key]
          )?.id;
        }
      });
    }

    this.lstFormControl.forEach((element) => {
      const value = sectionData[element.name];
      if (isEmpty(value)) {
        return;
      }
      if (
        element.type == PropertyType.TYPE_ADDRESS ||
        element.type == PropertyType.TYPE_PERSON_NAME
      ) {
        element.lstChildFormControl?.forEach((item: any) => {
          item.formControl?.setValue(value[item.key] || null);
        });
      } else if (element.type == PropertyType.TYPE_BOOLEAN) {
        element.formControl?.setValue(value === 'true' ? true : false);
      } else {
        if (element?.fractionalLength) {
          const fractionalLengthValue = setFractionalLength(
            value,
            element?.fractionalLength
          );
          element.formControl?.setValue(
            (+value)?.toFixed(fractionalLengthValue)
          );
        } else {
          element.formControl?.setValue(value);
        }
      }
    });
  }

  uploadFile(
    event: File,
    formControl: FormControl,
    fileDocument: UploadComponent
  ) {
    // set formControl
    formControl.setValue(fileDocument.fileSelected);
    if (
      (!fileDocument.fileSelected &&
        formControl.hasValidator(Validators.required)) ||
      fileDocument.hasError ||
      fileDocument.isDuplicated
    ) {
      formControl.setErrors({ invalid: true });
    }
  }

  removeFile(
    event: any,
    formControl: FormControl,
    fileDocument: UploadComponent
  ) {
    formControl.setValue(fileDocument.fileSelected);
  }

  isValidString(str: any) {
    return str?.trim();
  }

  getDataForm() {
    let i = this.listSectionStatic.findIndex(
      (el) => el.key === this.currentSection.key
    );
    this.dataNewMember[i] = {};
    this.dataNewMember[i].sectionKey = this.listSectionStatic[i]?.key;
    this.dataNewMember[i].propertyValues = this.frmAddSectionData.getRawValue();
    return i;
  }

  controlBreadcrumb(nextDirection = 1) {
    this.renewForm = false;
    let i = this.getDataForm();
    this.currentSection = this.listSectionStatic[i + nextDirection];
    this.changeBreadcrumb(this.currentSection?.key);
    this.frmAddSectionData = new FormGroup({});
    this.lstFormControl = [];
    this.setLstFormControl();
    // Fill data back to field control
    this.lstFormControl.forEach((f) => {
      if (f.formControl !== undefined) {
        f.formControl.setValue(
          this.dataNewMember[i + nextDirection]?.propertyValues[f.name]
        );
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            x.formControl.setValue(
              this.dataNewMember[i + nextDirection]?.propertyValues[
                f.name + x.name
              ]
            );
          }
        });
      }
    });
    this.setFormGroupCs();

    setTimeout(() => {
      this.renewForm = true;
    });
  }

  onBack() {
    this.controlBreadcrumb(-1);
  }

  onContinue() {
    this.frmAddSectionData.markAllAsTouched();
    if (this.frmAddSectionData.pending) {
      let sub = this.frmAddSectionData.statusChanges
        .pipe(
          finalize(() => {
            if (
              this.frmAddSectionData.disabled ||
              this.frmAddSectionData.valid
            ) {
              this.controlBreadcrumb(1);
            }
          })
        )
        .subscribe(() => {
          sub.unsubscribe();
        });
    } else if (
      this.frmAddSectionData.disabled ||
      this.frmAddSectionData.valid
    ) {
      this.controlBreadcrumb(1);
    }
  }

  getResult() {
    let result: any = [];
    for (let y = 0; y < this.listSectionStatic.length; y += 1) {
      result[y] = {};
      result[y].sectionKey = this.listSectionStatic[y].key;
      result[y].propertyValues = [];
      for (
        let x = 0;
        x < this.listSectionStatic[y]?.properties.length;
        x += 1
      ) {
        result[y].propertyValues[x] = {};
        result[y].propertyValues[x].key =
          this.listSectionStatic[y]?.properties[x]?.key;
        result[y].propertyValues[x].type =
          this.listSectionStatic[y]?.properties[x]?.type;
        if (
          result[y].propertyValues[x].type === PropertyType.TYPE_PERSON_NAME ||
          result[y].propertyValues[x].type === PropertyType.TYPE_ADDRESS
        ) {
          const lstChild =
            result[y].propertyValues[x].type === PropertyType.TYPE_ADDRESS
              ? [...this.lstInputAddress]
              : [...this.lstInputPersonName];
          const objVal: any = {};
          let isHaveData = false;
          for (const child of lstChild) {
            if (
              this.dataNewMember[y].propertyValues?.[
                result[y].propertyValues[x].key + child.name
              ]
            ) {
              isHaveData = true;
            }
            objVal[child.name] =
              this.dataNewMember[y].propertyValues?.[
                result[y].propertyValues[x].key + child.name
              ];
          }
          result[y].propertyValues[x].value = isHaveData
            ? JSON.stringify(objVal)
            : '';
        } else if (
          result[y].propertyValues[x].type === PropertyType.TYPE_DATE
        ) {
          const datepipe = new DatePipe('en-US');
          const value =
            this.dataNewMember[y].propertyValues[
              result[y].propertyValues[x].key
            ];
          result[y].propertyValues[x].value = value
            ? datepipe.transform(value, 'yyyy-MM-dd')
            : '';
        } else if (
          result[y].propertyValues[x].type === PropertyType.TYPE_SSN ||
          result[y].propertyValues[x].type === PropertyType.TYPE_PHONE
        ) {
          const value =
            this.dataNewMember[y].propertyValues[
              result[y].propertyValues[x].key
            ];
          result[y].propertyValues[x].value = value
            ? getValueWithoutFormat(value)
            : '';
        } else {
          result[y].propertyValues[x].value =
            this.dataNewMember[y].propertyValues[
              result[y].propertyValues[x].key
            ];
        }
      }
    }

    const demographicData = this.dataNewMember.find(
      (x) => x?.sectionKey === 'demographics'
    );
    result[0].propertyValues.push({
      key: 'statusId',
      type: 'StatusId',
      value: demographicData.propertyValues.statusId,
    });
    result[0].propertyValues.push({
      key: 'eventId',
      type: 'EventId',
      value: demographicData.propertyValues.eventId,
    });
    return result;
  }

  createNewMember(isContinue: boolean) {
    this.getDataForm();
    let result = this.getResult();
    // Call Api to create new Member
    this.memberListService.createNewMember(result).subscribe((res: any) => {
      if (isContinue) {
        // Routing to member detail by id
        this.dialogRef.close();
        this.memberDetailService.memberId = res?.member?.id;
        this.store.dispatch(
          LayoutActions.selectTab({
            tab: DisplayedTabName.IndividualParticipant,
            url: `/member/detail/${res?.member?.id}`,
          })
        );
      } else {
        this.isAddNewMemberSuccess = true;
        this.renewForm = false;
        this.currentSection = this.listSectionStatic[0];
        this.changeBreadcrumb(this.currentSection?.key);
        this.dataNewMember = [];
        this.frmAddSectionData = new FormGroup({});
        this.lstFormControl = [];
        this.setLstFormControl();
        this.setFormGroupCs();
        setTimeout(() => {
          this.renewForm = true;
        }, 100);
      }
    });
  }

  onCreate(isContinue: boolean) {
    this.frmAddSectionData.markAllAsTouched();
    this.memberDetailService.statusId = '';
    this.memberDetailService.eventId = '';
    if (this.frmAddSectionData.pending) {
      let sub = this.frmAddSectionData.statusChanges
        .pipe(
          finalize(() => {
            if (
              this.frmAddSectionData.disabled ||
              this.frmAddSectionData.valid
            ) {
              this.createNewMember(isContinue);
            }
          })
        )
        .subscribe(() => {
          sub.unsubscribe();
        });
    } else if (
      this.frmAddSectionData.disabled ||
      this.frmAddSectionData.valid
    ) {
      this.createNewMember(isContinue);
    }
  }

  getListStatus() {
    this.memberStore
      .pipe(select(fromMember.selectListStatus), takeUntil(this.unsubscribe$))
      .subscribe((listStatus) => {
        this.allStatus = listStatus;
        this.listStatus = (
          listStatus?.filter(
            (status) =>
              status.active || status.id === this.data.memberStatus?.id
          ) || []
        ).map((status) => {
          return {
            displayValue: status.name,
            value: status.id,
          };
        });
      });
  }

  setEvent(): void {
    this.memberDetailService.eventId = this.frmAddSectionData.value.eventId;
  }

  setStatusEvent() {
    this.frmAddSectionData.controls['eventId'].setValue(null);
    this.memberDetailService.statusId = this.frmAddSectionData.value.statusId;
    this.listStatusEvent = (
      this.allStatus.find(
        (status) => status.id === this.frmAddSectionData.value.statusId
      )?.memberEvent || []
    )
      .filter((memberEvent) => memberEvent.active)
      .map((memberEvent) => {
        return {
          value: memberEvent.id,
          displayValue: memberEvent.name,
        };
      });
  }
}
