export function stringToBoolean(value: string | boolean | undefined): boolean {
  return value === 'true' || value === true;
}

export function getConcatString(values: string[], separator: string = ', ') {
  return values.filter(value => value).join(separator);
}

export function getDateString(value: string) {
  return (!value || value.includes('+') || value.includes('Z')) ? value : value + 'Z';
}

export function getValueWithoutFormat(value: string) {
  if (!value) {
    return '';
  }
  return value.replace(/[\D]/gi, '');
}

export function isEmpty(value: any) {
  return value === null || value === undefined;
}

export function toFixedTrunc(num: number, fixed: number): string {
  const decimalRegex = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || 0) + '})?');
  return Number(Number(num).toString().match(decimalRegex)?.[0] || '0').toLocaleString('en', { minimumFractionDigits: fixed });
}

export function titleCase(value: string): string {
  if (!value) {
    return '';
  }
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

export function getElementId(elementType: string, elementName: string) {
  return `${ elementType }-${ elementName?.replace(/\s+/g, '-').toLowerCase() }`;
}

export function capitalizeFirstLetter(value: string) {
  if (!value) {
    return '';
  }
  return value[0].toUpperCase() + value.slice(1);
}

export function setFractionalLength(value: string | number, fractionalLength: number): number {
  const currentFractionalLength = String(value).split('.')[1] ? String(value).split('.')[1].length : 0;
  return Math.min(fractionalLength, currentFractionalLength);
}

export function getTimeZone() {
  let timezone_offset_min = new Date().getTimezoneOffset();
	let offset_hrs: any = Math.floor(Math.abs(timezone_offset_min/60));
	let offset_min: any = Math.abs(timezone_offset_min%60);
	let timezone_standard;

  if (offset_hrs < 10)
    offset_hrs = '0' + offset_hrs;

  if (offset_min < 10)
    offset_min = '0' + offset_min;

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezone_offset_min < 0)
    timezone_standard = '+' + offset_hrs + ':' + offset_min;
  else if (timezone_offset_min > 0)
    timezone_standard = '-' + offset_hrs + ':' + offset_min;
  else if (timezone_offset_min == 0)
    timezone_standard = 'Z';

  // Timezone difference in hours and minutes
  // String such as +5:30 or -6:00 or Z
  return timezone_standard;
}

export function getDateFormatISO(value: string) {
  let dt = new Date(value),
	current_date: any = dt.getDate(),
	current_month: any = dt.getMonth() + 1,
	current_year: any = dt.getFullYear(),
	current_day;

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  current_date = current_date < 10 ? '0' + current_date : current_date;
  current_month = current_month < 10 ? '0' + current_month : current_month;

  // Current datetime
  // String such as 2016-07-16T19:20:30
  current_day = '' + current_year + '-' + current_month + '-' + current_date;
  return current_day;
}
