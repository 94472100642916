<mat-dialog-content class="mat-dialog-content">
  <div id="loading-table" class="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
  <h3 style="font-weight: 600; font-size: 20px; color: #38424c">{{ title }}</h3>
  <form [formGroup]="emailFormGroup" (ngSubmit)="onSubmit()">
    <label style="display: block">Recipients</label>
    <div class="full-width">
      <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList0>
          <mat-chip
            *ngFor="let option of emailFormGroup.get('recipientsTo')?.value"
            [selectable]="true"
            [removable]="true"
            [disabled]="isReadOnly"
            (removed)="remove(option, 'recipientsTo')"
          >
            <span>{{ option }}</span>
            <div class="remove-btn">
              <mat-icon matChipRemove>clear</mat-icon>
            </div>
          </mat-chip>
          <input
            [matChipInputAddOnBlur]="true"
            [matChipInputFor]="chipList0"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addEmailsInTo($event)"
            [disabled]="data.isSingleRecipient || isReadOnly"
            placeholder="To:"
          />
        </mat-chip-list>
      </mat-form-field>
      <div *ngIf="isToEmailInValid" class="mat-error">
        Please enter a valid email address
      </div>
      <div
        *ngIf="
          emailFormGroup.get('recipientsTo')?.value?.length === 0 && !isReadOnly
        "
        class="mat-error"
      >
        Please enter at least one recipient email address
      </div>
    </div>
    <div class="full-width">
      <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList2>
          <mat-chip
            *ngFor="let option of emailFormGroup.get('recipientsCc')?.value"
            [selectable]="true"
            [removable]="true"
            [disabled]="isReadOnly"
            (removed)="remove(option, 'recipientsCc')"
          >
            <span>{{ option }}</span>
            <div class="remove-btn">
              <mat-icon matChipRemove>clear</mat-icon>
            </div>
          </mat-chip>
          <input
            [matChipInputAddOnBlur]="true"
            [matChipInputFor]="chipList2"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addEmailsInCC($event)"
            [disabled]="data.isSingleRecipient || isReadOnly"
            placeholder="Cc:"
          />
        </mat-chip-list>
      </mat-form-field>
      <div *ngIf="isCCEmailInValid" class="mat-error">
        Please enter a valid email address
      </div>
    </div>
    <div class="full-width">
      <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let option of emailFormGroup.get('recipients')?.value"
            [selectable]="true"
            [removable]="true"
            [disabled]="isReadOnly"
            (removed)="remove(option, 'recipients')"
          >
            <span>{{ option }}</span>
            <div class="remove-btn" *ngIf="!data.isSingleRecipient">
              <mat-icon matChipRemove>clear</mat-icon>
            </div>
          </mat-chip>
          <input
            [matChipInputAddOnBlur]="true"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
            [disabled]="data.isSingleRecipient || isReadOnly"
            placeholder="Bcc:"
          />
        </mat-chip-list>
      </mat-form-field>
      <div *ngIf="isBccEmailInValid" class="mat-error">
        Please enter a valid email address
      </div>
    </div>

    <label style="display: block">Subject</label>
    <div style="width: 100%">
      <ptg-textbox
        class="value-input-field textbox-width"
        [controlField]="emailFormGroup.get('subject')"
        [isRequired]="true"
        [inputType]="inputType"
        [hasLabel]="true"
        [placeholder]="'Subject'"
        [isDisabled]="isReadOnly"
      >
      </ptg-textbox>
    </div>

    <label style="display: block">Email Content</label>
    <ptg-editor
      class="email-content"
      [isDisabled]="isReadOnly"
      [controlField]="emailFormGroup.get('content')"
      placeholder="Text"
    ></ptg-editor>

    <label style="display: block">Attachment</label>
    <div class="container" ptgDragDrop (fileDropped)="onFileDropped($event)">
      <input
        class="mat-raised-button"
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="fileBrowseHandler($event?.target)"
        [style.pointer-events]="isLoading ? 'none' : 'auto'"
        [disabled]="isReadOnly"
      />
      <h3>Drag Attachments to upload</h3>
      <label
        style="
          display: inline-block;
          margin-top: 1px;
          background-color: #408bf9;
        "
        for="fileDropRef"
        >Upload Attachment</label
      >
    </div>
    <div class="files-list">
      <div
        class="single-file"
        *ngFor="let file of selectedFiles; let i = index"
      >
        <div class="file-icon">
          <table class="table table-colgroup">
            <tr>
              <td>
                {{ file?.name }}
              </td>
              <td>
                {{ formatBytes(file?.size) }}
              </td>
              <td>
                <mat-icon (click)="deleteFile(i, file)"
                  >delete_forever</mat-icon
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="checkAttachmentLimit()">
      <mat-error>Attachments size cannot exceed 25 MB</mat-error>
    </div>
    <div *ngIf="invalidFileFormat">
      <mat-error
        >Files with these formats are supported csv, doc, docx, odt, pdf, rtf,
        txt, wpd, wpf, gif, jpg/jpeg, png, svg, tif/tiff</mat-error
      >
    </div>
    <mat-checkbox
      formControlName="isScheduled"
      [checked]="checkValue"
      (change)="checkBoxClick($event)"
      [disabled]="isLoading || isReadOnly"
      >Send Later</mat-checkbox
    >
    <div style="display: flex" *ngIf="checkValue">
      <div>
        <label style="display: block">Date</label>
        <ptg-datepicker
          class="datepicker-width"
          [controlField]="emailFormGroup.get('scheduleDate')"
          [isRequired]="true"
          [minDate]="currentDate"
          [placeholder]="'Date'"
          [isDisabled]="isLoading"
        ></ptg-datepicker>
      </div>
      <div>
        <label style="display: block">Time</label>
        <div style="display: flex">
          <ptg-select
            class="hour-width"
            [controlField]="emailFormGroup.get('scheduleHour')"
            [isCheckChange]="true"
            [isOptionObj]="false"
            [listData]="selectHours"
            placeholder="Hour"
            [isDisabled]="isLoading"
            autocomplete="off"
          ></ptg-select>
          <h3 style="margin: 8px 2px 0px 2px">:</h3>
          <ptg-textbox
            style="width: &quot;9vw&quot;"
            [controlField]="emailFormGroup.get('scheduleMin')"
            class="value-input-field time-width"
            [inputType]="inputTypeNumber"
            [hasLabel]="true"
            [placeholder]="'Min'"
            [errorMinMaxValue]="'Value should be in between 0 to 59'"
            [isDisabled]="isLoading"
            autocomplete="off"
          >
          </ptg-textbox>
          <ptg-select
            class="time-width"
            [controlField]="emailFormGroup.get('scheduleAMPM')"
            [isCheckChange]="true"
            [isOptionObj]="false"
            [listData]="selectAMPM"
            placeholder="AM/PM"
            [isDisabled]="isLoading"
          ></ptg-select>
        </div>
      </div>
    </div>
    <div style="display: flex" *ngIf="!isSendLaterTimeValid">
      <mat-error>Send Later time should be greater than current time</mat-error>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="dialog-footer-container">
    <button
      mat-raised-button
      type="button"
      class="submit-button"
      [disabled]="
        emailFormGroup.invalid ||
        checkAttachmentLimit() ||
        invalidFileFormat ||
        isBccEmailInValid ||
        isToEmailInValid ||
        isReadOnly ||
        emailFormGroup.get('recipientsTo')?.value?.length === 0 ||
        isLoading
      "
      (click)="onSubmit()"
    >
      Send
    </button>
    <button
      mat-raised-button
      type="button"
      class="cancel-button"
      (click)="onNoClick()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
