import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { checkApiValidator } from '../../../shared/validators/checkApi.validator';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromMember from '../../reducers';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AggregationGroupActions,  } from '../../actions';
import { AggregationGroupService } from '../../services/aggregation-group.service';
import * as fromReducer from '../../../reducers/index';
import { SwitchConfirmPopupService } from 'src/app/shared/services/switch-confirm-popup.service';
import { ConfigColums } from 'src/app/shared/controls/gridview/gridview.component';
import { STYLE_COLUMN } from 'src/app/shared/controls/gridview/gridview.const';
import { AggregationGroup } from '../../types/models/aggregation-group.model';
import * as AggregationConfigActions from '../../actions/aggregation-config.actions';

@Component({
  selector: 'ptg-aggregation-group-modal',
  templateUrl: './aggregation-group-modal.component.html',
  styleUrls: ['./aggregation-group-modal.component.scss']
})
export class AggregationGroupModalComponent implements OnInit, OnDestroy {
  editForm: FormGroup = this.fb.group({
    groupName: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(this.aggregationGroupService.checkGroupNameExist, 'groupName')
    })
  });
  unsubscribe$ = new Subject<void>();
  availableAggregationsTable = [];
  selectedAggregationsTable = [];
  availableAggregations: any = [];
  selectedAggregations: any = [];
  configColums: ConfigColums;
  subscriptions: any[] = [];

  constructor(
    public fb: FormBuilder,
    public memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AggregationGroupModalComponent>,
    public router: Router,
    public aggregationGroupService: AggregationGroupService,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private store: Store<fromReducer.State>,
    @Inject(MAT_DIALOG_DATA) public data: { aggregationGroup: AggregationGroup }
  ) {
    this.configColums = {
      displayedColumns: ['listButton', 'name'],
      headerColumns: ['', ''],
      styleColums: [STYLE_COLUMN.BUTTON_LIST, STYLE_COLUMN.TEXT]
    };
    if (this.data.aggregationGroup) {
      this.editForm.setValue({
        groupName: this.data.aggregationGroup.groupLabel
      });
      this.aggregationGroupService.currentAggregationGroup = this.data.aggregationGroup;
      this.selectedAggregations = this.data.aggregationGroup.aggregations;
    } else {
      this.aggregationGroupService.currentAggregationGroup = null;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(AggregationConfigActions.getAggregationList());
    this.subscriptions.push(this.store.pipe(select(fromMember.selectAggregationConfigState)).subscribe(el => {
      let aggregationList = el.aggregationList.items;
      if (this.data?.aggregationGroup?.aggregations) {
        aggregationList = aggregationList.filter((item: any) => !this.data.aggregationGroup.aggregations.includes(item.id));
      }
      this.availableAggregationsTable = aggregationList.map((item: any) => {
        return {
          key: item.id,
          name: [
            item.property,
            item.label
          ]
        }
      });
    
      if (this.data?.aggregationGroup?.aggregations) {
        this.selectedAggregationsTable = (this.data.aggregationGroup.aggregations as any).map((id: any) => {
          return {
            key: id,
            name: [
              el.aggregationList.items.find((item: any) => item.id === id)?.property,
              el.aggregationList.items.find((item: any) => item.id === id)?.label
            ]
          }
        })
      }
    }));
  }

  onSubmit() {
    this.aggregationGroupService.checkGroupNameExist({groupName: this.editForm.get('groupName')?.value}).subscribe((el: any) => {
      if (el && el.exists && this.editForm.get('groupName')?.value.trim() !== this.data.aggregationGroup?.groupLabel) {
        this.editForm.get('groupName')?.setErrors({inValidAsync: true})
      }
      if (this.editForm.pending) {
        let sub = this.editForm.statusChanges.subscribe(() => {
          if (this.editForm.valid) {
            this.saveGroup();
          }
          sub.unsubscribe();
        });
      } else if (this.editForm.valid) {
        this.saveGroup();
      }
    });
  }

  saveGroup() {
    let body = {
      groupName: this.editForm.get('groupName')?.value,
      aggregations: this.selectedAggregations
    }
    if (this.data.aggregationGroup) {
      this.store.dispatch(AggregationGroupActions.updateAggregationGroup({id: this.data.aggregationGroup.id, body: body }));
    } else {
      this.store.dispatch(AggregationGroupActions.addAggregationGroup({ body: body }));
    }
    this.dialogRef.close({ groupName: this.editForm.get('groupName')?.value });
  }

  changeSelectedAggregations(event: any) {
    this.selectedAggregations = event.map((item: any) => item.key);  
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
