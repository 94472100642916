import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ProfileHeaderConfigurationActions from '../actions/profile-header-configuration.actions';
import {
  GetProfileHeadersDataResponse,
  ProfileHeaderList,
  PropertyConfigs,
} from '../types/models';
import { ProfileHeaderConfigurationService } from '../services/profile-header-configuration.service';

@Injectable()
export class ProfileHeaderConfigurationEffects {
  constructor(
    private actions$: Actions,
    private profileHeaderConfigurationService: ProfileHeaderConfigurationService
  ) {}

  getPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getProfileHeaderPropertyList),
      switchMap(() => {
        return this.profileHeaderConfigurationService.getPropertyList().pipe(
          map((res: PropertyConfigs) => {
            return ProfileHeaderConfigurationActions.getProfileHeaderPropertyListSuccess(
              { propertyConfigs: res.listSectionProperty }
            );
          }),
          catchError((err) => {
            return of(
              ProfileHeaderConfigurationActions.getProfileHeaderPropertyListFailure(
                { errorMsg: err.message }
              )
            );
          })
        );
      })
    )
  );

  getProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getProfileHeaderConfiguration),
      switchMap(({ id }) => {
        return this.profileHeaderConfigurationService
          .getProfileHeaderConfiguration(id)
          .pipe(
            map((res) => {
              return ProfileHeaderConfigurationActions.getProfileHeaderConfigurationSuccess(
                { profileHeader: res }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getProfileHeaderPropertyListFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  setProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.setProfileHeaderConfiguration),
      switchMap(({ body }) => {
        return this.profileHeaderConfigurationService
          .manageConfigurationMember(body)
          .pipe(
            map((id) => {
              return ProfileHeaderConfigurationActions.setProfileHeaderConfigurationSuccess(
                { id }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.setProfileHeaderConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getMemberProfileHeaderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileHeaderConfigurationActions.getMemberProfileHeadersDataAction
      ),
      switchMap(({ memberId }) =>
        this.profileHeaderConfigurationService
          .getMemberProfileHeaderData(memberId)
          .pipe(
            map((response: GetProfileHeadersDataResponse) =>
              ProfileHeaderConfigurationActions.getMemberProfileHeadersDataSuccessAction(
                { response, memberId }
              )
            ),
            catchError((error) =>
              of(
                ProfileHeaderConfigurationActions.getMemberProfileHeadersDataFailureAction(
                  { error }
                )
              )
            )
          )
      )
    )
  );
  getListProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileHeaderConfigurationActions.getListProfileHeaderConfiguration
      ),
      switchMap(({ query }) => {
        return this.profileHeaderConfigurationService
          .getListProfileHeaderConfiguration(query)
          .pipe(
            map((profileHeaderList: ProfileHeaderList) => {
              return ProfileHeaderConfigurationActions.getListProfileHeaderConfigurationSuccess(
                { profileHeaderList }
              );
            }),
            catchError((error) => {
              return of(
                ProfileHeaderConfigurationActions.getListProfileHeaderConfigurationFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );
  deleteProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileHeaderConfigurationActions.deleteProfileHeaderConfiguration
      ),
      switchMap(({ id }) => {
        return this.profileHeaderConfigurationService
          .deleteProfileHeaderConfiguration(id)
          .pipe(
            map(() => {
              return ProfileHeaderConfigurationActions.deleteProfileHeaderConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.deleteProfileHeaderConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
  getMembersStatusNotUsedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getMembersStatusNotUsed),
      switchMap(() => {
        return this.profileHeaderConfigurationService
          .getMembersStatusNotUsed()
          .pipe(
            map(({ listStatusUsing }) => {
              return ProfileHeaderConfigurationActions.getMembersStatusNotUsedSuccess(
                { statusNotUsedList: listStatusUsing }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getMembersStatusNotUsedFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
}
