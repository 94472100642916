import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { getDateFormatISO } from '@ptg-shared/utils/string.util';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../../../reducers';
import { PtgReportViewerComponent } from '@ptg-shared/report-viewer';

@Component({ 
    selector: 'ptg-report-view', 
    templateUrl: './report-view.component.html', 
    styleUrls: ['./report-view.component.scss'], 
    encapsulation: ViewEncapsulation.None 
})

export class ReportTemplateComponent extends BaseComponent {
    constructor(
        private store: Store<fromReducer.State>, 
        private route: ActivatedRoute, 
        private authService: Auth0Service,
        ) {
        super();
    }

    listBreadcrumbs: Breadcrumb[] = [{
        name: 'Report',
        url: ''
    }];
    settings: Breadcrumb[] = [
        {
            name: 'Manage Reports',
            url: '/member/report'
        }, {
            name: 'Manage Categories',
            url: '/member/report/category'
        }
    ];

    viewerContainerStyle = {
        //position: 'relative',
        width: '100%',
        height: 'calc(100vh - 168px)',
        ['font-family']: "'Roboto'"

    };
    parameters = {
        Stage_Int: 0,
        IsReload: true,
        ClientId_Str: '',
        ClientKey_Str:'',
        UserId_Str: '',
        UserName_Str: '',
        GeneratedAt_Dt: (getDateFormatISO(new Date().toString()) + 'T' + (new Date().toLocaleTimeString([], { hour12: false }) + 'Z'))
    };

    @ViewChild('viewer1') viewer !: PtgReportViewerComponent;
    source = {};
    reportId: string = '';
    apiUrl: string = `${environment.reportUrl}/api/resolve`;

    ngOnInit(): void {
        this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            this.reportId = params?.reportId;
            if(this.parameters.ClientId_Str && this.parameters.ClientKey_Str){
                this.source = {
                    report: this.reportId,
                    parameters: this.parameters
                };

                this.viewer.setReportSource(this.source);
            }
        });
        
        this.store.pipe(select(fromReducer.selectCurrentFundState)).pipe(takeUntil(this.unsubscribe$)).subscribe((currentFund) => {
            this.parameters = {
                Stage_Int: 0,
                IsReload: true,
                ClientId_Str: currentFund?.id,
                ClientKey_Str: currentFund?.key,
                UserId_Str: this.authService?.idTokenClaim?.sub,
                UserName_Str: this.authService?.name,
                GeneratedAt_Dt: (getDateFormatISO(new Date().toString()) + 'T' + (new Date().toLocaleTimeString([], { hour12: false }) + 'Z'))
            };
            this.source = {
                report: this.reportId,
                parameters: this.parameters
            };
        });
    }
}
