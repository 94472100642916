import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as LookupTableActions from '../actions/lookup-table.actions';
import {
  ImportSessionDetail,
  LookupTableDetail,
  LookupTableList,
} from '../types/models';
import { LookupTableService } from '../services/lookup-table.service';

@Injectable()
export class LookupTableEffects {
  getLookupTableList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.getLookupTableList),
      switchMap(({ query }) => {
        return this.lookupTableService.getLookupTableList(query).pipe(
          map((res: LookupTableList) => {
            let lookupTableList = res.metadataLookupTables.map((item) => {
              item.disabled = !item.active;
              return item;
            });
            return LookupTableActions.getLookupTableListSuccess({
              lookupTableList,
            });
          }),
          catchError((error) => {
            return of(LookupTableActions.getLookupTableListFailure({ error }));
          })
        );
      })
    );
  });

  getLookupTableDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.getLookupTableDetail),
      switchMap(({ id }) => {
        return this.lookupTableService.getLookupTableDetail(id).pipe(
          map((lookupTableDetail: LookupTableDetail) => {
            return LookupTableActions.getLookupTableDetailSuccess({
              lookupTableDetail,
            });
          }),
          catchError((error) => {
            return of(LookupTableActions.getLookupTableListFailure({ error }));
          })
        );
      })
    );
  });

  createLookupTable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.createLookupTable),
      switchMap(({ lookupTableDetail }) => {
        return this.lookupTableService
          .createMetadataLookupTable(lookupTableDetail)
          .pipe(
            map(() => {
              return LookupTableActions.createLookupTableSuccess();
            }),
            catchError((err) => {
              return of(
                LookupTableActions.createLookupTableFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    );
  });

  updateLookupTable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.updateLookupTable),
      switchMap(({ lookupTableDetail }) => {
        return this.lookupTableService
          .updateMetadataLookupTable(lookupTableDetail)
          .pipe(
            map(() => {
              return LookupTableActions.updateLookupTableSuccess();
            }),
            catchError((err) => {
              return of(
                LookupTableActions.updateLookupTableFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    );
  });

  createLookupTableValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.createLookupTableValue),
      switchMap(({ id, body }) => {
        return this.lookupTableService.createLookupTableValue(id, body).pipe(
          map(() => {
            return LookupTableActions.createLookupTableValueSuccess();
          }),
          catchError((error) => {
            return of(
              LookupTableActions.createLookupTableValueFailure({ error })
            );
          })
        );
      })
    );
  });

  updateLookupTableValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.updateLookupTableValue),
      switchMap(({ id, body }) => {
        return this.lookupTableService.updateLookupTableValue(id, body).pipe(
          map(() => {
            return LookupTableActions.updateLookupTableValueSuccess();
          }),
          catchError((error) => {
            return of(
              LookupTableActions.updateLookupTableValueFailure({ error })
            );
          })
        );
      })
    );
  });

  removeLookupTableValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.removeLookupTableValue),
      switchMap(({ body }) => {
        return this.lookupTableService.removeLookupTableValue(body).pipe(
          map(() => {
            return LookupTableActions.removeLookupTableValueSuccess();
          }),
          catchError((error) => {
            return of(
              LookupTableActions.removeLookupTableValueFailure({ error })
            );
          })
        );
      })
    );
  });

  getSessionDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.getImportSessionDetail),
      switchMap(({ source, sourceId, batchId }) => {
        return this.lookupTableService
          .getImportSessionDetail(source, sourceId, batchId)
          .pipe(
            map((sessionDetail: ImportSessionDetail) => {
              return LookupTableActions.getImportSessionDetailSuccess({
                sessionDetail,
              });
            }),
            catchError((err) => {
              return of(
                LookupTableActions.getImportSessionDetailFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    );
  });

  proceedImport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.proceedImport),
      switchMap(({ id }) => {
        return this.lookupTableService.proceedImport(id).pipe(
          map(() => {
            return LookupTableActions.proceedImportSuccess();
          }),
          catchError((err) => {
            return of(
              LookupTableActions.proceedImportFailure({ errorMsg: err.message })
            );
          })
        );
      })
    );
  });

  cancelImport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupTableActions.cancelImport),
      switchMap(({ id }) => {
        return this.lookupTableService.cancelImport(id).pipe(
          map(() => {
            return LookupTableActions.cancelImportSuccess();
          }),
          catchError((err) => {
            return of(
              LookupTableActions.cancelImportFailure({ errorMsg: err.message })
            );
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lookupTableService: LookupTableService
  ) {}
}
