<div class="dialog-header">
  <span class="title">Edit Earnings</span>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="form-content">
    <div class="flex gap-5">
      <div class="flex-grow flex flex-col gap-5">
        <ptg-textbox
          *ngFor="let earning of earnings"
          [controlField]="formGroup.get(earning.name)"
          [placeholder]="earning.name"
          [hasLabel]="true"
          inputType="Currency"
          (onBlur)="onBlurEarningInput()"
          [min]="0.0"
          [max]="maxValueNumber"
          [errorMinMaxValue]="
            earning.name +
            ' must be within the range of 0.00 - 9,999,999,999,999.99.'
          "
          customError="crossEarningsValidate"
        ></ptg-textbox>
      </div>

      <div style="width: 50px"></div>

      <div class="flex-grow flex flex-col gap-5">
        <ptg-toggle-button
          *ngIf="!hiddenFederal"
          label="Recalculate Federal Tax"
          [controlField]="recalculateFederalTaxCtrl"
          [disabled]="disabledFederal"
          (valueChange)="onChangeRecalculateToggle()"
        ></ptg-toggle-button>
        <ptg-toggle-button
          *ngIf="!hiddenState"
          label="Recalculate State Tax"
          [controlField]="recalculateStateTaxCtrl"
          [disabled]="disabledState"
          (valueChange)="onChangeRecalculateToggle()"
        ></ptg-toggle-button>

        <div class="currency-label">
          <label>Gross Payment</label>
          <div>
            <span class="dollar-sign">$</span>
            <span>{{ grossPayment | numberDecimal : { decimal: 2 } }}</span>
          </div>
        </div>
        <div class="currency-label">
          <label>Total Deductions</label>
          <div>
            <span class="dollar-sign">$</span>
            <span>{{ totalDeductions | numberDecimal : { decimal: 2 } }}</span>
          </div>
        </div>
        <div class="currency-label">
          <label>Net Payment</label>
          <div>
            <span class="dollar-sign">$</span>
            <span>{{ netPayment | numberDecimal : { decimal: 2 } }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-footer">
    <button
      mat-raised-button
      type="submit"
      style="background-color: #408bf9; color: #fff"
    >
      Save
    </button>
    <button
      mat-raised-button
      type="button"
      style="background-color: #acabab; color: #fff"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
