import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { STATE } from '@ptg-shared/constance/value.const';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { PaymentDeductionsService } from '@ptg-processing/services/payment-deductions.service';

import * as NextPaymentActions from '../../actions/next-payment.actions';
import * as fromNextPayment from '../../reducers';
import {
  OffCyclePayment,
  ParticipantEarning,
  UpdateEarningsData,
} from '../../types/models';
import { TaxType } from '../../constance/member-list.const';

@Component({
  selector: 'ptg-edit-earnings',
  templateUrl: './edit-earnings.component.html',
  styleUrls: ['./edit-earnings.component.scss'],
})
export class EditEarningsComponent implements OnInit, OnDestroy {
  maxValueNumber = 9999999999999.99;
  formGroup: FormGroup;
  isEarningInputsError: boolean = false;
  earnings: any[] = [];
  oldEarnings: any[] = [];
  grossPayment: number;
  totalDeductions: number;
  netPayment: number;
  offCyclePayment: OffCyclePayment;

  hiddenFederal: boolean = true;
  hiddenState: boolean = true;
  disabledFederal: boolean = true;
  disabledState: boolean = true;

  get recalculateFederalTaxCtrl(): FormControl {
    return this.formGroup.get('recalculateFederalTax') as FormControl;
  }
  get recalculateStateTaxCtrl() {
    return this.formGroup.get('recalculateStateTax') as FormControl;
  }

  unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EditEarningsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromNextPayment.State>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private paymentDeductionsService: PaymentDeductionsService
  ) {
    // Receives input data from outside
    this.earnings = this.data.earnings || [];
    this.oldEarnings = [...this.earnings];
    this.grossPayment = this.data.grossPayment || 0;
    this.totalDeductions = this.data.totalDeductions || 0;
    this.netPayment = this.data.netPayment || 0;
    this.offCyclePayment = this.data.offCyclePayment;

    // Make form gorup and form controls object
    const controls: any = this.earnings.reduce(
      (ctrls, earning) => {
        ctrls[earning.name] = new FormControl(earning.value);
        return ctrls;
      },
      {
        recalculateFederalTax: new FormControl(false),
        recalculateStateTax: new FormControl(false),
      }
    );
    this.formGroup = new FormGroup(controls, {
      validators: [this.earningInputsValidator],
    });
  }

  earningInputsValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // Check all fields is blank
    const isAllFieldsBlank: boolean = !this.earnings.some(
      (e) => control.get(e.name)?.value === 0 || control.get(e.name)?.value
    );
    if (isAllFieldsBlank) {
      this.isEarningInputsError = true;
      this.earnings.forEach((e) =>
        control.get(e.name)?.setErrors({
          crossEarningsValidate: 'At least 1 earnings item is required.',
        })
      );
      return null;
    }

    // Check least 1 earning value not equals to 0
    const isAllFieldsEqualsZero: boolean = !this.earnings.some(
      (e) => control.get(e.name)?.value
    );
    if (isAllFieldsEqualsZero) {
      this.isEarningInputsError = true;
      this.earnings.forEach((e) =>
        control.get(e.name)?.setErrors(
          control.get(e.name)?.value === 0
            ? {
                crossEarningsValidate:
                  'At least 1 earnings item must be greater than 0.',
              }
            : null
        )
      );
      return null;
    }

    // Refresh control errors status
    if (
      !isAllFieldsBlank &&
      !isAllFieldsEqualsZero &&
      this.isEarningInputsError
    ) {
      this.isEarningInputsError = false;
      this.earnings.forEach((e) =>
        control.get(e.name)?.updateValueAndValidity()
      );
    }

    return null;
  };

  ngOnInit(): void {
    // Hidden and disable Recalculate Federal Tax and Recalculate State Tax controls
    this.store
      .select(fromNextPayment.selectNextPaymentDeduction)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const participantDeductions: any[] = state?.deductions || [];
        this.paymentDeductionsService.getActiveDeduction().subscribe((data) => {
          const deductionList = data && data.deductions ? data.deductions : [];

          // Hidden and disable Recalculate Federal Tax
          this.hiddenFederal =
            !participantDeductions.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.FederalTax
            ) || !!this.offCyclePayment;
          if (!this.hiddenFederal) {
            this.disabledFederal = !deductionList.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.FederalTax
            );
          }

          // Hidden and disable Recalculate State Tax
          this.hiddenState =
            !participantDeductions.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.StateTax
            ) || !!this.offCyclePayment;
          if (!this.hiddenState) {
            this.disabledState = !deductionList.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.StateTax
            );
          }
        });
      });

    // Listen for the result of recalculate total deductions
    this.store
      .select(fromNextPayment.selectNextPaymentRecalculateTotalDeductions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.recalculateTotalDeductionsState === STATE.SUCCESS) {
          // Recalculate Gross payment
          this.grossPayment = this.earnings.reduce(
            (grossPayment, earning) =>
              grossPayment + Number(this.formGroup.get(earning.name)?.value),
            0
          );
          // Receive total deductions from state
          this.totalDeductions = state.totalDeductions;

          // Recalculate Net payment
          this.netPayment = this.grossPayment - this.totalDeductions;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const data: UpdateEarningsData = {
        recalculateFederalTax: this.recalculateFederalTaxCtrl.value,
        recalculateStateTax: this.recalculateStateTaxCtrl.value,
        earnings: this.earnings.map(
          (earning) =>
            ({
              ...earning,
              value: this.formGroup.get(earning.name)?.value || 0,
            } as ParticipantEarning)
        ),
      };
      this.dialogRef.close(data);
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(
      this.dialogRef,
      '',
      '',
      '',
      this.store,
      NextPaymentActions.clearTotalDeductionsState()
    );
  }

  onBlurEarningInput(): void {
    // Check earnings changed
    const earningsIsChanged: boolean = this.oldEarnings.some(
      (e) => e.value !== this.formGroup.get(e.name)?.value
    );
    if (this.formGroup.valid && earningsIsChanged) {
      if (
        this.recalculateFederalTaxCtrl.value ||
        this.recalculateStateTaxCtrl.value
      ) {
        this.onlineRecalculate();
      } else {
        this.offlineRecalculate();
      }
    }

    // Update oldEarnings
    this.oldEarnings = this.oldEarnings.map((e) => ({
      ...e,
      value: this.formGroup.get(e.name)?.value,
    }));
  }

  onChangeRecalculateToggle(): void {
    if (this.formGroup.valid) this.onlineRecalculate();
  }

  onlineRecalculate(): void {
    this.store.dispatch(
      NextPaymentActions.recalculateTotalDeductions({
        body: {
          recalculateFederalTax: this.recalculateFederalTaxCtrl.value,
          recalculateStateTax: this.recalculateStateTaxCtrl.value,
          earnings: this.earnings.map((earning) => ({
            ...earning,
            value: Number(this.formGroup.get(earning.name)?.value),
          })),
        },
      })
    );
  }

  offlineRecalculate(): void {
    // Recalculate Gross payment
    this.grossPayment = this.earnings.reduce(
      (grossPayment, earning) =>
        grossPayment + Number(this.formGroup.get(earning.name)?.value),
      0
    );

    // Recalculate Net payment
    this.netPayment = this.grossPayment - this.totalDeductions;
  }
}
