import { FormArray } from '@angular/forms';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DateTime } from 'luxon';
import { ACTION, STATE } from '../constance/value.const';
import { isEmpty } from './string.util';

export function showBanner(state: string, propertyName: string, action: string, options: { customMessage: string } = { customMessage: '' }) {
  if (!state) {
    return;
  }
  // @ts-ignore
  this.bannerType = state as BannerType;
  if (options?.customMessage) {
    // @ts-ignore
    this.message = options.customMessage;
    return;
  }
  let actionType = '';
  switch (action) {
    case ACTION.ADD:
      actionType = state === STATE.SUCCESS ? 'added' : 'adding';
      break;
    case ACTION.EDIT:
      actionType = state === STATE.SUCCESS ? 'updated' : 'updating';
      break;
    case ACTION.REMOVE:
      actionType = state === STATE.SUCCESS ? 'removed' : 'removing';
      break;
    case ACTION.UPLOAD:
      actionType = state === STATE.SUCCESS ? 'uploaded' : 'uploading';
      break;
    case ACTION.LOCK:
      actionType = state === STATE.SUCCESS ? 'locked' : 'locking';
      break;
    case ACTION.UNLOCK:
      actionType = state === STATE.SUCCESS ? 'unlocked' : 'unlocking';
      break;
    case ACTION.SAVE:
      actionType = state === STATE.SUCCESS ? 'saved' : 'saving';
      break;
    case ACTION.RESET:
      actionType = state === STATE.SUCCESS ? 'reset' : 'resetting';
      break;
    case ACTION.CANCEL:
      actionType = state === STATE.SUCCESS ? 'canceled' : 'cancelling';
      break;
    case ACTION.SCHEDULED:
      actionType = state === STATE.SUCCESS ? 'scheduled' : 'scheduling';
      break;
  }
  if (state === STATE.SUCCESS) {
    // @ts-ignore
    this.message = `${ propertyName } successfully ${ actionType }.`;
    return;
  }
  if (state === STATE.FAIL) {
    // @ts-ignore
    this.message = `Error occurred ${ actionType } ${ propertyName }. Please try again.`;
    return;
  }
}

export function checkErrorDuplicate(listParameter: FormArray, propertyName: string) {
  let list = listParameter.getRawValue();
  listParameter.controls.forEach(control => {
    if (control.get(propertyName)?.hasError('duplicated')) {
      let parameter = control.get(propertyName)?.value?.toLowerCase();
      let x = 0;
      list?.forEach((el: any) => {
        if (parameter && el.parameter?.toLowerCase().replace(/\s+/, ' ') === parameter.replace(/\s+/, ' ')) {
          x += 1;
        }
      });
      if (x === 1) {
        control.get(propertyName)?.setErrors(null);
        control.get(propertyName)?.markAsTouched();
      }
    }
  });
}

export function deepClone<T>(value: T): T {
  if (value === undefined || value === null) {
    return value as T;
  }
  return JSON.parse(JSON.stringify(value)) as T;
}

export function downloadFile(res: Blob, fileName: string) {
  const url = window.URL.createObjectURL(res);
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = url;
  anchor.click();
}

export function reorderItems(items: any[], reorderItemId?: string | number, upperAdjacentId?: string | number) {
  let tmp = JSON.parse(JSON.stringify(items));
  let itemUpperAdjacent: any;
  let itemReorderItem: any;
  if (upperAdjacentId) {
    itemUpperAdjacent = items.filter((item: any) => item.id === upperAdjacentId)[0];
  }
  itemReorderItem = items.filter((item: any) => item.id === reorderItemId)[0];
  tmp.forEach((item: any) => {
    if (item.id === reorderItemId) {
      item.order = itemUpperAdjacent?.id ? itemReorderItem.order < itemUpperAdjacent.order ? itemUpperAdjacent.order : itemUpperAdjacent.order + 1 : 1;
    } else if (itemUpperAdjacent?.id) {
      if (itemReorderItem.order < itemUpperAdjacent.order) {
        if (item.order > itemReorderItem.order && item.order < itemUpperAdjacent.order + 1) {
          item.order = item.order - 1;
        }
      } else {
        if (item.order < itemReorderItem.order + 1 && item.order > itemUpperAdjacent.order) {
          item.order = item.order + 1;
        }
      }
    } else {
      if (item.order < itemReorderItem.order) {
        item.order = item.order + 1;
      }
    }
  });
  tmp = tmp.sort((a: any, b: any) => {
    if (a.order > b.order) {
      return 1;
    }
    return -1;
  });
  return tmp;
}

export function lowercaseFirstCharacterKeys(object: Record<string, any>): Record<string, any> {
  return Object.keys(object).reduce((result: Record<string, any>, key) => {
    result[key[0].toLowerCase() + key.substring(1)] = object[key];
    return result;
  }, {});
}

export function toUtcDate(dateTime: DateTime) {
  return DateTime.utc(dateTime.year, dateTime.month, dateTime.day, dateTime.hour, dateTime.minute, dateTime.second, dateTime.millisecond);
}

export function formatUtcDateString(value: string, format: string = 'MM/dd/yyyy') {
  return DateTime.fromISO(value, { zone: 'utc' }).toFormat(format);
}

export function isNumeric(value: number | string) {
  return !isEmpty(value) && value !== '' && !isNaN(Number(value));
}

export function isObjectEmpty(value: any) {
  return isEmpty(value) || Object.values(value).every(item => isEmpty(item));
}

export function deepEqual(x: any, y: any): boolean {
  if ((x && y && typeof x === 'object' && typeof y === 'object')) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }
    return Object.keys(x).every((key, index) => {
        return deepEqual(x[key], y[key]);
      });
  }
  return x === y;
}

export function deepFlattenArray<T>(sourceArray: Array<T>, childrenKey: string = 'children') {
  let result: T[] = [];
  sourceArray.forEach((item: any) => {
    result.push(item);
    if (Array.isArray(item?.[childrenKey])) {
      result = result.concat(deepFlattenArray(item?.[childrenKey], childrenKey));
    }
  });
  return result;
}

export function toCamelCase(key: any, value: any) {
  if (value && typeof value === 'object') {
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}

export function checkExistNestedKeyValue(
  sourceArray: any[],
  values: string[],
  keys: { parentKey: string, childKey: string } = { parentKey: 'Key', childKey: 'Child' },
): boolean {
  const existItem = sourceArray.find(item => item[keys.parentKey] === values[0]);
  if (!existItem) {
    return false;
  }
  if (!deepClone(values).slice(1).length) {
    return true
  }
  return checkExistNestedKeyValue(existItem[keys.childKey], deepClone(values).slice(1));
}

export function getCurrentFundData(fundModel: FundModel) {
  return {
    currentFund: {
      name: fundModel.name,
      id: fundModel.id,
      key: fundModel.key,
      active: fundModel.active,
      supportPhone: fundModel.supportPhone,
      fundType: fundModel.fundType,
      modules: fundModel.modules,
      defaultPageSize: fundModel.defaultPageSize,
    },
  };
}
