import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { dispatchGetMemberNavigationList } from '@ptg-shared/layout/helpers';
import * as fromReducer from '@ptg-reducers';

import { ParticipantRelationship } from '../../types/models';

@Component({
  selector: 'ptg-participant-detail-dialog',
  templateUrl: './participant-detail-dialog.component.html',
  styleUrls: ['./participant-detail-dialog.component.scss'],
})
export class ParticipantDetailDialogComponent implements OnInit, OnChanges {
  detailData: Record<string, any>[] = [];
  @Input() participantRelationshipDetail!: ParticipantRelationship;

  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private store: Store<fromReducer.State>
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.close.emit();
  }

  viewProfile() {
    const memberId =
      this.participantRelationshipDetail?.linkedParticipantId ||
      this.participantRelationshipDetail?.memberId;

    dispatchGetMemberNavigationList(this.store, memberId);

    const url = `/member/detail/${memberId}`;
    void this.router.navigateByUrl(url);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.participantRelationshipDetail) {
      this.mapDetailData();
    }
  }

  mapDetailData() {
    this.detailData = [
      ...this.participantRelationshipDetail.memberGraphConfigDataItems.map(
        (item) => {
          return {
            label: item.propertyLabel,
            value: item.value,
            type: item.type,
            typedValue: item.typedValue,
          };
        }
      ),
    ];
  }
}
