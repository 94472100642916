import { createAction, props } from '@ngrx/store';

import { DeductionListQuery, NewVendor, VendorList, VendorListQuery } from '../types/models';

export const VendorListRequest = '[VendorList/API] Send Request';
export const VendorListFailure = '[VendorList/API] Failure';
export const VendorListSuccess = '[VendorList/API] Success';

export const DeductionListByVendorRequest =
  '[DeductionListByVendor/API] Send Request';
export const DeductionListByVendorFailure =
  '[DeductionListByVendor/API] Failure';
export const DeductionListByVendorSuccess =
  '[DeductionListByVendor/API] Success';

export const CreateVendorRequest = '[CreateVendor/API] Send Request';
export const CreateVendorFailure = '[CreateVendor/API] Failure';
export const CreateVendorSuccess = '[CreateVendor/API] Success';

export const UpdateVendorRequest = '[UpdateVendor/API] Send Request';
export const UpdateVendorFailure = '[UpdateVendor/API] Failure';
export const UpdateVendorSuccess = '[UpdateVendor/API] Success';

export const RemoveVendorRequest = '[RemoveVendor/API] Send Request';
export const RemoveVendorFailure = '[RemoveVendor/API] Failure';
export const RemoveVendorSuccess = '[RemoveVendor/API] Success';

export const VendorMaintenanceClear = '[VendorMaintenance] Clear';

export const getVendorList = createAction(
  VendorListRequest,
  props<{ query: VendorListQuery; idClient: string }>()
);
export const getVendorListSuccess = createAction(
  VendorListSuccess,
  props<{ vendorList: VendorList }>()
);
export const getVendorListFailure = createAction(
  VendorListFailure,
  props<{ error?: any }>()
);

export const getDeductionListByVendor = createAction(
  DeductionListByVendorRequest,
  props<{ query: DeductionListQuery; idClient: string }>()
);
export const getDeductionListByVendorSuccess = createAction(
  DeductionListByVendorSuccess,
  props<{ deductionListByVendor: any }>()
);
export const getDeductionListByVendorFailure = createAction(
  DeductionListByVendorFailure,
  props<{ error?: any }>()
);

export const createVendor = createAction(
  CreateVendorRequest,
  props<{ body: NewVendor }>()
);
export const createtVendorSuccess = createAction(CreateVendorSuccess);
export const createVendorFailure = createAction(
  CreateVendorFailure,
  props<{ errorMsg: string }>()
);

export const updateVendor = createAction(
  UpdateVendorRequest,
  props<{ id: string; body: NewVendor }>()
);
export const updateVendorSuccess = createAction(UpdateVendorSuccess);
export const updateVendorFailure = createAction(
  UpdateVendorFailure,
  props<{ errorMsg: string }>()
);

export const removeVendor = createAction(
  RemoveVendorRequest,
  props<{ id: string }>()
);
export const removeVendorSuccess = createAction(RemoveVendorSuccess);
export const removeVendorFailure = createAction(
  RemoveVendorFailure,
  props<{ errorMsg: string }>()
);

export const vendorMaintenanceClear = createAction(VendorMaintenanceClear);
