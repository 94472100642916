import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';

import * as fromReducer from '../../reducers';
import { MemberNotificationOptIns, OptInType } from '../../types/models';
import * as Actions from '../../actions/member-notification-opt-in.action';
import { OverviewHeaderComponent } from '../overview-header/overview-header.component';
import { dispatchGetMemberProfileHeaders } from '../../helpers';

@Component({
  selector: 'ptg-opt-in',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss'],
})
export class OptInComponent implements OnInit {
  formGroup!: FormGroup;
  memberId: string = '';
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Opt-In Management',
    },
  ];
  editNotePaperless: boolean = false;
  editNoteSMS: boolean = false;
  isLoading: boolean = true;
  keepPaperlessValue: boolean = false;
  keepSMSValue: boolean = false;
  disabledPaperLess: boolean = false;
  disabledSMS: boolean = false;
  memberOptIn: MemberNotificationOptIns[] = [];
  paperLessDataBak?: MemberNotificationOptIns;
  smsDataBak?: MemberNotificationOptIns;
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;

  constructor(
    private memberStore: Store<fromReducer.MemberState>,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.memberId = params['memberId'];
      this.memberStore.dispatch(
        Actions.getNotificationOptIn({ memberId: this.memberId })
      );
    });

    this.memberStore
      .pipe(
        select(fromReducer.selectMemberNotificationOptInListState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        this.memberOptIn = result;
        this.paperLessDataBak =
          result?.find((x) => x.type === OptInType.Paperless) ||
          this.paperLessDataBak;
        const paperLessData = result?.find(
          (x) => x.type === OptInType.Paperless
        ) || {
          type: OptInType.Paperless,
          isOptIn: this.formGroup?.get('isPaperLess')?.value,
          note: this.formGroup?.get('notePaperless')?.value,
          updatedBy: this.formGroup?.get('paperLessUpdatedBy')?.value,
          dateUpdated: this.formGroup?.get('paperLessUpdatedDate')?.value,
          complianceMessage: this.formGroup?.get('paperLessContent')?.value,
        };

        this.smsDataBak =
          result?.find((x) => x.type === OptInType.SmsMms) || this.smsDataBak;
        const smsData = result?.find((x) => x.type === OptInType.SmsMms) || {
          type: OptInType.SmsMms,
          isOptIn: this.formGroup?.get('isSMS')?.value,
          note: this.formGroup?.get('noteSMS')?.value,
          updatedBy: this.formGroup?.get('smsUpdatedBy')?.value,
          dateUpdated: this.formGroup?.get('smsUpdatedDate')?.value,
          complianceMessage: this.formGroup?.get('smsContent')?.value,
        };

        this.formGroup = new FormGroup({
          isPaperLess: new FormControl(paperLessData?.isOptIn),
          paperLessUpdatedBy: new FormControl(paperLessData?.updatedBy),
          paperLessUpdatedDate: new FormControl(paperLessData?.dateUpdated),
          paperLessContent: new FormControl(paperLessData?.complianceMessage),
          notePaperless: new FormControl(paperLessData?.note),

          isSMS: new FormControl(smsData?.isOptIn),
          smsUpdatedBy: new FormControl(smsData?.updatedBy),
          smsUpdatedDate: new FormControl(smsData?.dateUpdated),
          smsContent: new FormControl(smsData?.complianceMessage),
          noteSMS: new FormControl(smsData?.note),
        });
        this.isLoading = false;
        this.memberStore.dispatch(Actions.clearNotificationOptIn());
      });
    this.selectEditNotificationOptIn();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectEditNotificationOptIn() {
    this.memberStore
      .pipe(
        select(fromReducer.selectEditMemberNotificationOptInState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        if (result?.state && result?.optInType === OptInType.Paperless) {
          this.editNotePaperless = false;
          this.memberStore.dispatch(
            Actions.getNotificationOptIn({ memberId: this.memberId })
          );
        } else if (result?.state && result?.optInType === OptInType.SmsMms) {
          this.editNoteSMS = false;
          this.memberStore.dispatch(
            Actions.getNotificationOptIn({ memberId: this.memberId })
          );
        }
        dispatchGetMemberProfileHeaders(this.memberStore, this.memberId);
        this.disabledPaperLess = false;
        this.disabledSMS = false;
      });
  }

  onChangePaperlessToggle(): void {
    this.disabledPaperLess = true;
    if (this.formGroup.get('isPaperLess')?.value) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Compliance Message',
          text: this.formGroup.get('paperLessContent')?.value,
          cancelButtonTitle: 'No',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.formGroup.get('isPaperLess')?.value) {
            this.editNotePaperless = true;
            this.formGroup.get('notePaperless')?.setValue('');
          }
        } else {
          this.formGroup.get('isPaperLess')?.reset(false);
          this.editNotePaperless = false;
          this.disabledPaperLess = false;
        }
      });
    } else {
      this.editNotePaperless = true;
      this.formGroup.get('notePaperless')?.setValue('');
    }
  }

  onChangeSMSToggle(): void {
    this.disabledSMS = true;
    if (this.formGroup.get('isSMS')?.value) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Compliance Message',
          text: this.formGroup.get('smsContent')?.value,
          cancelButtonTitle: 'No',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.formGroup.get('isSMS')?.value) {
            this.editNoteSMS = true;
            this.formGroup.get('noteSMS')?.setValue('');
          }
        } else {
          this.formGroup.get('isSMS')?.reset(false);
          this.editNoteSMS = false;
          this.disabledSMS = false;
        }
      });
    } else {
      this.editNoteSMS = true;
      this.formGroup.get('noteSMS')?.setValue('');
    }
  }

  onSubmitPaperless() {
    const body = {
      optInType: OptInType.Paperless,
      isOptIn: this.formGroup.get('isPaperLess')?.value,
      note: this.formGroup.get('notePaperless')?.value,
    };
    this.memberStore.dispatch(
      Actions.editNotificationOptIn({ memberId: this.memberId, body })
    );
  }

  onSubmitSMS() {
    const body = {
      optInType: OptInType.SmsMms,
      isOptIn: this.formGroup.get('isSMS')?.value,
      note: this.formGroup.get('noteSMS')?.value,
    };
    this.memberStore.dispatch(
      Actions.editNotificationOptIn({ memberId: this.memberId, body })
    );
  }

  onCancelPaperless() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dataPaperless = this.paperLessDataBak;
        this.editNotePaperless = false;
        this.formGroup.get('isPaperLess')?.reset(dataPaperless?.isOptIn);
        this.formGroup.get('notePaperless')?.setValue(dataPaperless?.note);
        this.formGroup
          .get('paperLessContent')
          ?.setValue(dataPaperless?.complianceMessage);
        this.formGroup
          .get('paperLessUpdatedBy')
          ?.setValue(dataPaperless?.updatedBy);
        this.formGroup
          .get('paperLessUpdatedDate')
          ?.setValue(dataPaperless?.dateUpdated);
        this.disabledPaperLess = false;
      }
    });
  }

  onCancelSMS() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dataSMS = this.smsDataBak;
        this.editNoteSMS = false;
        this.formGroup.get('isSMS')?.reset(dataSMS?.isOptIn);
        this.formGroup.get('noteSMS')?.setValue(dataSMS?.note);
        this.formGroup.get('smsContent')?.setValue(dataSMS?.complianceMessage);
        this.formGroup.get('smsUpdatedBy')?.setValue(dataSMS?.updatedBy);
        this.formGroup.get('smsUpdatedDate')?.setValue(dataSMS?.dateUpdated);
        this.disabledSMS = false;
      }
    });
  }
}
