<div class="earning-container">
  <div class="control-bar">
    <span class="name"> Earnings </span>
    <div
      *ngIf="
        !isHistory &&
        (!offCyclePayment ||
          (offCyclePayment &&
            offCyclePayment.paymentInfoType !== PaymentInfoTypeEnum.Reissue &&
            offCyclePayment.paymentStatus === OffCyclePaymentStatusTypeEnum.NotIssued))
      "
      class="edit-btn"
      (click)="onClickEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
  </div>

  <div>
    <table class="earnings-table">
      <tr>
        <th></th>
        <th>Current</th>
        <th *ngIf="paymentInforConfig.EARNINGSYEARTODATE">YTD</th>
      </tr>

      <tr *ngFor="let ele of earnings" [hidden]="(offCyclePayment && offCyclePayment.paymentInfoType === PaymentInfoTypeEnum.Reissue) || !paymentInforConfig.EARNINGITEMS">
        <td class="label-col" *ngIf="showEarning || (!showEarning && ele.name != null && (ele.name.trim().toLowerCase() != 'cola' && ele.name.trim().toLowerCase() != 'annuity'))">
          <mat-icon
            *ngIf="ele.manualAdjustmented"
            matTooltip="Manually Adjusted"
            matTooltipClass="custom-tooltip"
            >star</mat-icon>
          <span
            class="label"
            [ngClass]="{
              'not-star-el': hasManualAdjusted && !ele.manualAdjustmented
            }"
          >
            {{ ele.name }}
          </span>
        </td>
        <td class="value-col-current" *ngIf="showEarning || (!showEarning && ele.name != null && (ele.name.trim().toLowerCase() != 'cola' && ele.name.trim().toLowerCase() != 'annuity'))">
          <span class="dollar-sign">$</span>
          {{ ele.value | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE && (showEarning || (!showEarning && ele.name != null && (ele.name.trim().toLowerCase() != 'cola' && ele.name.trim().toLowerCase() != 'annuity')))">
          <span class="dollar-sign">$</span>
          {{ ele.ytd | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>

      <tr [hidden]="(offCyclePayment && offCyclePayment.paymentInfoType === PaymentInfoTypeEnum.Reissue) || !paymentInforConfig.EARNINGITEMS">
        <td colspan="3">
          <div [ngClass]="{'line-break': earnings?.length}"></div>
        </td>
      </tr>

      <tr>
        <td class="label-col" [ngClass]="{'not-star': hasManualAdjusted}">Gross Payment</td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.grossPayment | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdGrossPayment| numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
      <tr>
        <td class="label-col" [ngClass]="{'not-star': hasManualAdjusted}">Total Deductions</td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.totalDeductions | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdTotalDeductions | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
      <tr>
        <td class="label-col" [ngClass]="{'not-star': hasManualAdjusted}">Net Payment</td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.netPayment | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd"  *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdNetPayment | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
    </table>
  </div>
</div>
