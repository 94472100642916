import { createAction, props } from '@ngrx/store';
import { DisplayValueLocationType } from '@ptg-processing/types/enums/annual-1099.enum';
import { 
  Annual1099,
  ColumnConfigurations,
  GetAnnual1099PeriodResponse, 
  GetAnnual1099Query, 
  GetAnnual1099RecipientErrorAndWarningRequest, 
  GetAnnual1099RecipientErrorAndWarningResponse, 
  GetAnnual1099RecordsQuery, 
  GetDiscrepancyErrorDetailsRequest,
  GetDiscrepancyErrorDetailsResponse,
  GetDiscrepancyWarningDetailsRequest,
  GetDiscrepancyWarningDetailsResponse,
  PayerInformationConfigurationModel
} from '@ptg-processing/types/models/annual-1099-period.model';


export const getAnnual1099PeriodAction = createAction(
  '[GetAnnual1099Period/API] Send Request',
  props<{ query: any }>()
);

export const getAnnual1099PeriodSuccessAction = createAction(
  '[GetAnnual1099Period/API] Success',
  props<{ incompletedPeriods: GetAnnual1099PeriodResponse }>()
);

export const getAnnual1099PeriodFailureAction = createAction(
  '[GetAnnual1099Period/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAnnual1099PeriodStateAction = createAction(
  '[GetAnnual1099Period] Clear'
);

export const getCompletedAnnual1099PeriodAction = createAction(
  '[GetCompletedAnnual1099Period/API] Send Request',
  props<{ query: any }>()
);

export const getCompletedAnnual1099PeriodSuccessAction = createAction(
  '[GetCompletedAnnual1099Period/API] Success',
  props<{ completedPeriods: GetAnnual1099PeriodResponse }>()
);

export const getCompletedAnnual1099PeriodFailureAction = createAction(
  '[GetCompletedAnnual1099Period/API] Failure',
  props<{ error?: any }>()
);

export const clearGetCompletedAnnual1099PeriodStateAction = createAction(
  '[GetCompletedAnnual1099Period] Clear'
);

export const getPayerInformationConfigurationAction = createAction(
  '[GetPayerInformationConfiguration/API] Send Request'
);

export const getPayerInformationConfigurationSuccessAction = createAction(
  '[GetPayerInformationConfiguration/API] Success',
  props<{ payerInformationConfiguration: PayerInformationConfigurationModel }>()
);

export const getPayerInformationConfigurationFailureAction = createAction(
  '[GetPayerInformationConfiguration/API] Failure',
  props<{ error?: any }>()
);

export const savePayerInformationConfigurationAction = createAction(
  '[SavePayerInformationConfiguration/API] Send Request',
  props<{ payerInformationConfiguration: PayerInformationConfigurationModel }>()
);

export const savePayerInformationConfigurationSuccessAction = createAction(
  '[SavePayerInformationConfiguration/API] Success'
);

export const savePayerInformationConfigurationFailureAction = createAction(
  '[SavePayerInformationConfiguration/API] Failure',
  props<{ error?: any }>()
);

export const clearSavePayerInformationConfigurationStateAction = createAction(
  '[SavePayerInformationConfiguration] Clear'
);

export const getAnnual1099Action = createAction(
  '[GetAnnual1099/API] Send Request',
  props<{ query: GetAnnual1099Query }>()
);

export const getAnnual1099SuccessAction = createAction(
  '[GetAnnual1099/API] Success',
  props<{ annual1099: Annual1099 }>()
);

export const getAnnual1099FailureAction = createAction(
  '[GetAnnual1099/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAnnual1099StateAction = createAction(
  '[GetAnnual1099] Clear'
);

export const getAnnual1099RecordsAction = createAction(
  '[GetAnnual1099Records/API] Send Request',
  props<{ annual1099Id: string, query: GetAnnual1099RecordsQuery }>()
);

export const getAnnual1099RecordsSuccessAction = createAction(
  '[GetAnnual1099Records/API] Success',
  props<{ annual1099Records: any[], total: number }>()
);

export const getAnnual1099RecordsFailureAction = createAction(
  '[GetAnnual1099Records/API] Failure',
  props<{ error?: any }>()
);

export const getAnnual1099ColumnConfigurationsAction = createAction(
  '[GetAnnual1099ColumnConfigurations/API] Send Request',
  props<{ displayLocation: DisplayValueLocationType }>()
);

export const getAnnual1099ColumnConfigurationsSuccessAction = createAction(
  '[GetAnnual1099ColumnConfigurations/API] Success',
  props<{ columnConfigurations: ColumnConfigurations[] }>()
);

export const getAnnual1099ColumnConfigurationsFailureAction = createAction(
  '[GetAnnual1099ColumnConfigurations/API] Failure',
  props<{ error?: any }>()
);

export const searchAnnual1099RecipientsAction = createAction(
  '[SearchAnnual1099Recipients/API] Send Request',
  props<{searchValue: string, annual1099Id: string}>()
);

export const searchAnnual1099RecipientsSuccessAction = createAction(
  '[SearchAnnual1099Recipients/API] Success',
  props<{ res: any }>()
);

export const searchAnnual1099RecipientsFailureAction = createAction(
  '[SearchAnnual1099Recipients/API] Failure',
  props<{error?: any}>()
);

export const clearSearchAnnual1099RecipientsAction = createAction(
  '[SearchAnnual1099Recipients] Clear'
);

export const issueAnnual1099Action = createAction(
  '[IssueAnnual1099/API] Send Request',
  props<{ annual1099Id: string }>()
);

export const issueAnnual1099SuccessAction = createAction(
  '[IssueAnnual1099/API] Success'
);

export const issueAnnual1099FailureAction = createAction(
  '[IssueAnnual1099/API] Failure',
  props<{ error?: any }>()
);
export const clearIssueAnnual1099StateAction = createAction(
  '[IssueAnnual1099] Clear'
);

export const cancelAnnual1099Action = createAction(
  '[CancelAnnual1099/API] Send Request',
  props<{ annual1099Id: string }>()
);

export const cancelAnnual1099SuccessAction = createAction(
  '[CancelAnnual1099/API] Success'
);

export const cancelAnnual1099FailureAction = createAction(
  '[CancelAnnual1099/API] Failure',
  props<{ error?: any }>()
);

export const clearCancelAnnual1099StateAction = createAction(
  '[CancelAnnual1099] Clear'
);
export const finalizeAnnual1099Action = createAction(
  '[FinalizeAnnual1099/API] Send Request',
  props<{ annual1099Id: string }>()
);

export const finalizeAnnual1099SuccessAction = createAction(
  '[FinalizeAnnual1099/API] Success'
);

export const finalizeAnnual1099FailureAction = createAction(
  '[FinalizeAnnual1099/API] Failure',
  props<{ error?: any }>()
);
export const clearFinalizeAnnual1099StateAction = createAction(
  '[FinalizeAnnual1099] Clear'
);

export const getAnnual1099RecipientErrorAction = createAction(
  '[GetAnnual1099RecipientError/API] Send Request',
  props<{ request: GetAnnual1099RecipientErrorAndWarningRequest }>()
);

export const getAnnual1099RecipientErrorSuccessAction = createAction(
  '[GetAnnual1099RecipientError/API] Success',
  props<{ response: GetAnnual1099RecipientErrorAndWarningResponse }>()
);

export const getAnnual1099RecipientErrorFailureAction = createAction(
  '[GetAnnual1099RecipientError/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAnnual1099RecipientErrorStateAction = createAction(
  '[GetAnnual1099RecipientError] Clear'
);

export const getAnnual1099RecipientWarningAction = createAction(
  '[GetAnnual1099RecipientWarning/API] Send Request',
  props<{ request: GetAnnual1099RecipientErrorAndWarningRequest }>()
);

export const getAnnual1099RecipientWarningSuccessAction = createAction(
  '[GetAnnual1099RecipientWarning/API] Success',
  props<{ response: GetAnnual1099RecipientErrorAndWarningResponse }>()
);

export const getAnnual1099RecipientWarningFailureAction = createAction(
  '[GetAnnual1099RecipientWarning/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAnnual1099RecipientWarningStateAction = createAction(
  '[GetAnnual1099RecipientWarning] Clear'
);

export const getDiscrepancyErrorDetailsAction = createAction(
  '[GetDiscrepancyErrorDetails/API] Send Request',
  props<{ request: GetDiscrepancyErrorDetailsRequest }>()
);

export const getDiscrepancyErrorDetailsSuccessAction = createAction(
  '[GetDiscrepancyErrorDetails/API] Success',
  props<{ response: GetDiscrepancyErrorDetailsResponse }>()
);

export const getDiscrepancyErrorDetailsFailureAction = createAction(
  '[GetDiscrepancyErrorDetails/API] Failure',
  props<{ error?: any }>()
);

export const clearGetDiscrepancyErrorDetailsStateAction = createAction(
  '[GetDiscrepancyErrorDetails] Clear'
);

export const getDiscrepancyWarningDetailsAction = createAction(
  '[GetDiscrepancyWarningDetails/API] Send Request',
  props<{ request: GetDiscrepancyWarningDetailsRequest }>()
);

export const getDiscrepancyWarningDetailsSuccessAction = createAction(
  '[GetDiscrepancyWarningDetails/API] Success',
  props<{ response: GetDiscrepancyWarningDetailsResponse }>()
);

export const getDiscrepancyWarningDetailsFailureAction = createAction(
  '[GetDiscrepancyWarningDetails/API] Failure',
  props<{ error?: any }>()
);

export const clearGetDiscrepancyWarningDetailsStateAction = createAction(
  '[GetDiscrepancyWarningDetails] Clear'
);

export const efileSentToIRSAction = createAction(
  '[EfileSentToIRS/API] Send Request',
  props<{ annual1099Id: string, eFileSentToIRSOn: string }>()
);

export const efileSentToIRSSuccessAction = createAction(
  '[EfileSentToIRS/API] Success'
);

export const efileSentToIRSFailureAction = createAction(
  '[EfileSentToIRS/API] Failure',
  props<{ error?: any }>()
);
export const clearEfileSentToIRSStateAction = createAction(
  '[EfileSentToIRS] Clear'
);

export const formsPrintedAndSentAction = createAction(
  '[FormsPrintedAndSent/API] Send Request',
  props<{ annual1099Id: string }>()
);

export const formsPrintedAndSentSuccessAction = createAction(
  '[FormsPrintedAndSent/API] Success'
);

export const formsPrintedAndSentFailureAction = createAction(
  '[FormsPrintedAndSent/API] Failure',
  props<{ error?: any }>()
);
export const clearFormsPrintedAndSentStateAction = createAction(
  '[FormsPrintedAndSent] Clear'
);

