import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { GetListStatusHistoryQuery, MunicipalityList } from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityPaymentService {
  constructor(private httpClient: HttpClient) {}

  getMunicipalityPaymentList(
    query: GetListStatusHistoryQuery
  ): Observable<MunicipalityList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MunicipalityList>(
      `${environment.apiUrl}/Members/Payments`,
      { params }
    );
  }
}
