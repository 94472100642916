<div class="aggregation-group">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onClickBreadcrumb)="onClickBreadcrumb($event)"
  ></ptg-breadcrumb>

  <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="box">
      <div class="title-container">
        <p class="label-small right-separator">Aggregation Groups</p>
        <ptg-button
          buttonName="New Group"
          classInput="add-button"
          (clickButton)="openForm()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
      <ptg-grid
        [data]="aggregationGroupsData"
        [columns]="columns"
        keyColumn="id"
        [isLoading]="isLoading"
        [errorMessage]="errorMsg"
        [paginable]="false"
        [allowDragDrop]="true"
        (rowDrop)="changeItem($event)"
        notFoundMessage="No Aggregation Groups to Display"
      >
        <ng-template cellContent columnName="count" let-row>
          {{ row.count | numberDecimal }}
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="openForm(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveClick(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
