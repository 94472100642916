import { createAction, props } from '@ngrx/store';

import {
  SetProfileOverviewRequest,
  ProfileOverviewDetail,
  ProfileOverviewList,
  SectionMetadataList,
  Status,
} from '../types/models';

export const ClearProfileOverviewConfiguration =
  '[ProfileOverviewConfiguration] Clear';

export const GetSectionMetadatasRequestAction =
  '[GetSectionMetadatas/API] Send Request';
export const GGetSectionMetadatasSuccessAction =
  '[GetSectionMetadatas/API] Success';
export const GetSectionMetadatasFailureAction =
  '[GetSectionMetadatas/API] Failure';

export const GetProfileOverviewConfigurationRequestAction =
  '[GetProfileOverviewConfiguration/API] Send Request';
export const GetProfileOverviewConfigurationSuccessAction =
  '[GetProfileOverviewConfiguration/API] Success';
export const GetProfileOverviewConfigurationFailureAction =
  '[GetProfileOverviewConfiguration/API] Failure';

export const SetProfileOverviewRequestAction =
  '[SetProfileOverview/API] Send Request';
export const SetProfileOverviewSuccessAction =
  '[SetProfileOverview/API] Success';
export const SetProfileOverviewFailureAction =
  '[SetProfileOverview/API] Failure';

export const GetListProfileOverviewConfigurationRequest =
  '[GetListProfileOverviewConfiguration/API] Send Request';
export const GetListProfileOverviewConfigurationSuccess =
  '[GetListProfileOverviewConfiguration/API] Success';
export const GetListProfileOverviewConfigurationFailure =
  '[GetListProfileOverviewConfiguration/API] Failure';
export const DeleteProfileOverviewConfigurationRequest =
  '[DeleteProfileOverviewConfiguration/API] Send Request';
export const DeleteProfileOverviewConfigurationSuccess =
  '[DeleteProfileOverviewConfiguration/API] Success';
export const DeleteProfileOverviewConfigurationFailure =
  '[DeleteProfileOverviewConfiguration/API] Failure';

export const GetNotUsedStatusesRequestAction =
  '[GetNotUsedStatuses/API] Send Request';
export const GetNotUsedStatusesSuccessAction =
  '[GetNotUsedStatuses/API] Success';
export const GetNotUsedStatusesFailureAction =
  '[GetNotUsedStatuses/API] Failure';

export const ClearProfileOverviewConfigurationDetail =
  '[ClearProfileOverviewConfigurationDetail] Clear';

export const clearProfileOverviewConfigurationState = createAction(
  ClearProfileOverviewConfiguration
);

export const getSectionMetadatas = createAction(
  GetSectionMetadatasRequestAction
);
export const getSectionMetadatasSuccess = createAction(
  GGetSectionMetadatasSuccessAction,
  props<{ response: SectionMetadataList }>()
);
export const getSectionMetadatasFailure = createAction(
  GetSectionMetadatasFailureAction,
  props<{ error?: any }>()
);

export const getProfileOverviewConfiguration = createAction(
  GetProfileOverviewConfigurationRequestAction,
  props<{ profileOverviewId: string }>()
);
export const getProfileOverviewConfigurationSuccess = createAction(
  GetProfileOverviewConfigurationSuccessAction,
  props<{ response: ProfileOverviewDetail }>()
);
export const getProfileOverviewConfigurationFailure = createAction(
  GetProfileOverviewConfigurationFailureAction,
  props<{ error?: any }>()
);

export const setProfileOverview = createAction(
  SetProfileOverviewRequestAction,
  props<{ request: SetProfileOverviewRequest }>()
);
export const setProfileOverviewSuccess = createAction(
  SetProfileOverviewSuccessAction,
  props<{ id: string }>()
);
export const ssetProfileOverviewFailure = createAction(
  SetProfileOverviewFailureAction,
  props<{ error?: any }>()
);

export const getListProfileOverviewConfiguration = createAction(
  GetListProfileOverviewConfigurationRequest,
  props<{ query: { SortNames: string; SortType: number } }>()
);
export const getListProfileOverviewConfigurationSuccess = createAction(
  GetListProfileOverviewConfigurationSuccess,
  props<{ profileOverviewList: ProfileOverviewList }>()
);
export const getListProfileOverviewConfigurationFailure = createAction(
  GetListProfileOverviewConfigurationFailure,
  props<{ error?: any }>()
);

export const deleteProfileOverviewConfiguration = createAction(
  DeleteProfileOverviewConfigurationRequest,
  props<{ id: string }>()
);
export const deleteProfileOverviewConfigurationSuccess = createAction(
  DeleteProfileOverviewConfigurationSuccess
);
export const deleteProfileOverviewConfigurationFailure = createAction(
  DeleteProfileOverviewConfigurationFailure,
  props<{ errorMsg: string }>()
);

export const getNotUsedStatuses = createAction(GetNotUsedStatusesRequestAction);
export const getNotUsedStatusesSuccess = createAction(
  GetNotUsedStatusesSuccessAction,
  props<{ response: { listStatusUsing: Status[] } }>()
);
export const getNotUsedStatusesFailure = createAction(
  GetNotUsedStatusesFailureAction,
  props<{ error?: any }>()
);

export const clearProfileOverviewConfigurationDetail = createAction(
  ClearProfileOverviewConfigurationDetail
);
