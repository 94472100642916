import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DropStatementReportQuery,
  DropStatementsData,
  SetDropStatementRequest,
} from '@ptg-member/types/models/drop-statement.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DropStatementService {
  editBody: any = {};
  activeFund: any;

  constructor(private httpClient: HttpClient) {}

  getDropStatementsList(
    query: DropStatementReportQuery,
  ): Observable<DropStatementsData> {
    let params = new HttpParams();
    if (query.totalPerPage && query.pageIndex) {
      params = params.append('PageSize', query.totalPerPage);
      params = params.append('PageIndex', query.pageIndex);
    }

    if (query.sortField) {
      params = params.append('SortNames', query.sortField.toUpperCase());
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<DropStatementsData>(
      `${environment.apiUrl}/Report/GetDropStatementList`,
      { params },
    );
  }

  postDropStatement(
    reqDropStatement: SetDropStatementRequest,
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}/Report/CreateDropStatement`,
      reqDropStatement,
    );
  }

  downloadDropStatement(
    reqDropStatement: SetDropStatementRequest,
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}/Report/CreateDropStatement`,
      reqDropStatement,
    );
  }

  downloadStatementPdf(filename: string): Observable<any> {
    return this.httpClient.get(
      `${environment.apiUrl}/Report/DownloadDropStatementReport/${filename}`,
    );
  }

  deleteStatement(statementId: string) {
    return this.httpClient.delete (
      `${environment.apiUrl}/report/statement/${statementId}`
      );
  }
}
