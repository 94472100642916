import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { GetPropertyType } from '../constance/member-list.const';
import {
  ListConfig,
  ListSectionProperty,
  MemberListConfigurations,
  PropertyConfigs,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MemberListConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getPropertyList(itemKey?: string): Observable<PropertyConfigs> {
    if (itemKey) {
      return this.httpClient.get<ListSectionProperty>(
        `${environment.apiUrl}/Metadata/GetPropertyForConfigMember/${itemKey}`
      );
    }
    return this.httpClient.get<ListSectionProperty>(
      `${environment.apiUrl}/Metadata/SectionProperties/${GetPropertyType.MemberListConfiguration}`
    );
  }

  getMemberListConfiguration(id?: string): Observable<ListConfig> {
    let params = new HttpParams();
    if (id) {
      params = params.append('memberNavigationId', id);
    }
    return this.httpClient.get<ListConfig>(
      `${environment.apiUrl}/Metadata/GetMemberListConfiguration`,
      { params }
    );
  }

  manageConfigurationMember(body: MemberListConfigurations) {
    return this.httpClient.put<MemberListConfigurations>(
      `${environment.apiUrl}/Metadata/ManageConfigurationMember`,
      body
    );
  }
}
