import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GetNonListItemConfigsResponse,
  GetNonListPropertiesResponse,
  SaveNonListItemConfigsRequest,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class NonListConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getNonListProperties(
    sectionKey: string
  ): Observable<GetNonListPropertiesResponse> {
    let params = new HttpParams();
    params = params.append('SectionKey', sectionKey);
    return this.httpClient.get<GetNonListPropertiesResponse>(
      `${environment.apiUrl}/Metadata/NonListProperties`,
      { params }
    );
  }

  getNonListItemConfigs(
    navigationItemId: string
  ): Observable<GetNonListItemConfigsResponse> {
    let params = new HttpParams();
    params = params.append('NavigationItemId', navigationItemId);

    return this.httpClient.get<GetNonListItemConfigsResponse>(
      `${environment.apiUrl}/Metadata/NonListItemConfigs`,
      { params }
    );
  }

  saveNonListItemConfigs(
    request: SaveNonListItemConfigsRequest
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Metadata/SaveNonListItemConfigs`,
      request
    );
  }
}
