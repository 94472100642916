import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FilterPropertyType,
  INPUT_TYPE,
} from '@ptg-member/constance/member-list.const';
import { EmailService } from '@ptg-member/services/email.service';
import { FilterInputType } from '@ptg-member/types/models';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ptg-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  title = this.data?.title || 'Send Email';
  isReadOnly = this.data?.isReadOnly || false;
  editedMailMessage = this.data?.mail?.messageId || '';
  inputType: FilterInputType = INPUT_TYPE[FilterPropertyType.Text];
  inputTypeNumber: FilterInputType =
    INPUT_TYPE[FilterPropertyType['Whole Number']];
  acceptFile = '';
  supportedFileTypes = [
    'csv',
    'doc',
    'docx',
    'odt',
    'pdf',
    'rtf',
    'txt',
    'wpd',
    'wpf',
    'gif',
    'jpg',
    'png',
    'svg',
    'tif',
    'jpeg',
    'tiff',
    'xls',
    'xlsm',
    'xlsx',
    'xlt',
  ];
  processing = false;
  files: any[] = [];
  checkValue = this.data?.mail?.isScheduled || false;
  selectAMPM = ['AM', 'PM'];
  selectHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  totalAttachmentSize = 0;
  invalidFileFormat = false;
  selectedFiles: File[] = this.data?.files || [];
  currentDate = new Date();
  separatorKeysCodes: number[] = [13, 188];
  isBccEmailInValid = false;
  isCCEmailInValid = false;
  isToEmailInValid = false;
  isLoading = false;
  isSendLaterTimeValid = true;
  defaultEmail: string = `${environment.defaultSenderMail}`;

  emailFormGroup: FormGroup = this.fb.group({
    recipientsTo: this.fb.control(this.data?.mail?.recipientsTo || []),
    recipients: this.fb.control(
      this.data?.mail?.recipients || [this.defaultEmail],
    ),
    recipientsCc: this.fb.control(this.data?.mail?.recipientsCc || []),
    subject: this.fb.control(this.data?.mail?.subject || '', [
      Validators.required,
    ]),
    content: this.fb.control(this.data?.mail?.content || ''),
    files: this.fb.control(this.data?.mail?.files || []),
    isScheduled: this.fb.control(this.data?.mail?.isScheduled || false),
    scheduleDate: this.fb.control(
      this.data?.mail?.scheduleAt?.scheduleDate || '',
    ),
    scheduleHour: this.fb.control(
      this.data?.mail?.scheduleAt?.scheduleHour || '',
      [Validators.min(1), Validators.max(12)],
    ),
    scheduleMin: this.fb.control(
      this.data?.mail?.scheduleAt?.scheduleMin || '',
      [Validators.min(0), Validators.max(59)],
    ),
    scheduleAMPM: this.fb.control(
      this.data?.mail?.scheduleAt?.scheduleAMPM || 'AM',
    ),
  });
  constructor(
    public dialogRef: MatDialogRef<EmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private emailService: EmailService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.data?.mail?.files?.length > 0) {
      this.selectedFiles = this.data?.mail?.files;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.totalAttachmentSize = 0;
  }

  onSubmit() {
    const formData = new FormData();
    for (let file of this.selectedFiles) {
      formData.append('files', file);
    }
    formData.append(
      'recipientsTo',
      this.emailFormGroup.controls['recipientsTo'].value,
    );
    formData.append(
      'recipientsBcc',
      this.emailFormGroup.controls['recipients'].value,
    );
    formData.append(
      'recipientsCc',
      this.emailFormGroup.controls['recipientsCc'].value,
    );
    formData.append('subject', this.emailFormGroup.controls['subject'].value);
    formData.append('content', this.emailFormGroup.controls['content'].value);
    formData.append(
      'isScheduled',
      this.emailFormGroup.controls['isScheduled'].value,
    );
    formData.append('EmailType', 'text/html');
    if (this.selectedFiles.length > 0) {
      formData.append('isAttachment', 'true');
    }
    if (this.emailFormGroup.controls['isScheduled'].value) {
      if (
        this.emailFormGroup.controls['scheduleDate'].value &&
        this.emailFormGroup.controls['scheduleHour'].value &&
        this.emailFormGroup.controls['scheduleMin'].value
      )
        formData.append('scheduleAt', this.getScheduleUnixTime().toString());
      formData.append('createdBy', this.data.createdBy);
      this.isSendLaterTimeValid = this.validateSendLaterTiming();
    }
    if (this.isSendLaterTimeValid) {
      this.isLoading = true;
      this.emailService
        .sendEmail(formData, this.emailFormGroup.controls['isScheduled'].value)
        .subscribe(
          (result) => {
            this.isLoading = false;
            if (this.editedMailMessage?.trim() !== '') {
              this.cancelledScheduleMail(this.editedMailMessage.trim());
            } else {
              this.displayMessagePopup();
            }
          },
          (error: any) => {
            this.isLoading = false;
            let dialogRef = this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              autoFocus: false,
              data: {
                text: 'An error occured while sending email',
                type: ConfirmType.Error,
                cancelButtonTitle: 'Cancel',
                title: 'Error',
                hideConfirmButton: true,
              },
            });
          },
        );
    }
  }

  displayMessagePopup() {
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: this.emailFormGroup.controls['isScheduled'].value
          ? 'Email Scheduled Successfully'
          : 'Email Sent Successfully',
        type: ConfirmType.Success,
        cancelButtonTitle: 'Ok',
        title: 'Success',
        hideConfirmButton: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dialogRef.close(true);
    });
  }

  cancelledScheduleMail(messageId: string) {
    this.emailService.cancelEmail(messageId).subscribe(
      (result: any) => {
        if (result && result.batch_id) {
          this.displayMessagePopup();
        }
      },
      (error: any) => {
        this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          autoFocus: false,
          data: {
            text: 'An error occured while cancelling the email',
            type: ConfirmType.Error,
            cancelButtonTitle: 'Cancel',
            title: 'Error',
            hideConfirmButton: true,
          },
        });
      },
    );
  }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(target: any) {
    this.prepareFilesList(target.files);
  }

  deleteFile(index: number, file: any) {
    if (!this.isLoading) {
      this.invalidFileFormat = false;
      this.selectedFiles.splice(index, 1);
      this.calculateAttachmentSize(file?.size, true);
    }
  }

  prepareFilesList(files: File[]) {
    for (const item of files) {
      let fileExtension = item.name.split('.');
      if (
        fileExtension.length === 2 &&
        this.supportedFileTypes.indexOf(fileExtension[1].toLowerCase()) > -1
      ) {
        this.selectedFiles.push(item);
        this.calculateAttachmentSize(item?.size);
        this.invalidFileFormat = false;
      } else {
        this.invalidFileFormat = true;
        break;
      }
    }
  }

  formatBytes(bytes: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  checkBoxClick(event: any) {
    this.checkValue = event.checked;
    this.emailFormGroup.patchValue({ isScheduled: event.checked });
    if (event.checked) {
      this.emailFormGroup
        .get('scheduleDate')
        ?.setValidators([Validators.required]);
      this.emailFormGroup.get('scheduleDate')?.updateValueAndValidity();
      this.emailFormGroup
        .get('scheduleHour')
        ?.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(12),
        ]);
      this.emailFormGroup.get('scheduleHour')?.updateValueAndValidity();
      this.emailFormGroup
        .get('scheduleMin')
        ?.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]);
      this.emailFormGroup.get('scheduleMin')?.updateValueAndValidity();
    } else {
      this.emailFormGroup.get('scheduleMin')?.clearValidators();
      this.emailFormGroup.get('scheduleMin')?.updateValueAndValidity();
      this.emailFormGroup.get('scheduleDate')?.clearValidators();
      this.emailFormGroup.get('scheduleDate')?.updateValueAndValidity();
      this.emailFormGroup.get('scheduleHour')?.clearValidators();
      this.emailFormGroup.get('scheduleHour')?.updateValueAndValidity();
      this.isSendLaterTimeValid = true;
    }
  }

  calculateAttachmentSize(fileSize: any, isDeleted?: boolean) {
    if (isDeleted)
      this.totalAttachmentSize = this.totalAttachmentSize - fileSize;
    else this.totalAttachmentSize += fileSize;
  }

  checkAttachmentLimit() {
    return this.totalAttachmentSize / (1024 * 1024) > 25;
  }

  formGroupToFormData(formData: FormData, formGroup: FormGroup) {
    for (let key in formGroup.controls) {
      if (formGroup.controls[key] instanceof FormGroup) {
        this.formGroupToFormData(
          formData,
          formGroup.controls[key] as FormGroup,
        );
      } else {
        formData.append(key, formGroup.controls[key].value);
      }
    }
  }

  getScheduleUnixTime() {
    const dateValue = new Date(
      this.emailFormGroup.controls['scheduleDate'].value,
    );
    let hourValue = this.emailFormGroup.controls['scheduleHour'].value;
    const AMPMValue = this.emailFormGroup.controls['scheduleAMPM'].value;
    const customObj = new Date(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      hourValue,
      this.emailFormGroup.controls['scheduleMin'].value,
    ); // Set hour and minute
    if (AMPMValue === 'PM' && hourValue < 12) {
      customObj.setHours((hourValue = Number(hourValue) + 12));
    } else if (AMPMValue === 'AM' && hourValue == 12) {
      customObj.setHours(0);
    }
    const unixTimestampInSeconds = Math.floor(customObj.getTime() / 1000);
    return unixTimestampInSeconds;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.validateEmail(value.trim())) {
        this.isBccEmailInValid = false;
        this.emailFormGroup.controls['recipients'].value.push(value.trim());
        if (input) {
          input.value = '';
        }
      } else {
        this.isBccEmailInValid = true;
      }
    } else {
      this.isBccEmailInValid = false;
    }
  }

  remove(data: string, type: string): void {
    if (!this.data?.isSingleRecipient) {
      switch (type) {
        case 'recipients': {
          const index =
            this.emailFormGroup.controls['recipients'].value?.indexOf(data);
          if (index >= 0) {
            this.emailFormGroup.controls['recipients'].value.splice(index, 1);
          }
          break;
        }
        case 'recipientsCc': {
          const indexCC =
            this.emailFormGroup.controls['recipientsCc'].value?.indexOf(data);
          if (indexCC >= 0) {
            this.emailFormGroup.controls['recipientsCc'].value.splice(
              indexCC,
              1,
            );
          }
          break;
        }
        case 'recipientsTo': {
          const indexTo =
            this.emailFormGroup.controls['recipientsTo'].value?.indexOf(data);
          if (indexTo >= 0) {
            this.emailFormGroup.controls['recipientsTo'].value.splice(
              indexTo,
              1,
            );
          }
          break;
        }
        default:
          // Handle default case if needed
          break;
      }
    }
  }

  addEmailsInTo(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.validateEmail(value.trim())) {
        this.isToEmailInValid = false;
        this.emailFormGroup.controls['recipientsTo'].value.push(value.trim());
        if (input) {
          input.value = '';
        }
      } else {
        this.isToEmailInValid = true;
      }
    } else {
      this.isToEmailInValid = false;
    }
  }
  addEmailsInCC(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.validateEmail(value.trim())) {
        this.isCCEmailInValid = false;
        this.emailFormGroup.controls['recipientsCc'].value.push(value.trim());
        if (input) {
          input.value = '';
        }
      } else {
        this.isCCEmailInValid = true;
      }
    } else {
      this.isCCEmailInValid = false;
    }
  }

  validateEmail(input: string) {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) return true;
    else return false;
  }

  validateSendLaterTiming() {
    if (this.emailFormGroup.controls['isScheduled'].value) {
      const sendLaterUnixTimeStamp = this.getScheduleUnixTime();
      const currentTimeUnixTimeStamp = Math.floor(new Date().getTime() / 1000);
      return sendLaterUnixTimeStamp > currentTimeUnixTimeStamp;
    }
    return true;
  }
}
