<div class="info-1099">
  <ptg-overview-header></ptg-overview-header>
  <div class="info-1099-content flex flex-col p-6 gap-6">
    <div
      class="content"
    >
      <div *ngIf="bannerType" class="mb-4">
        <ptg-banner
          *ngIf="bannerType"
          [(bannerType)]="bannerType"
          [message]="message"
          [isHideCloseButton]="true"
        ></ptg-banner>
      </div>
      <div class="card mb-6" *ngIf="currentRow">
        <div class="px-4 py-3 border-b flex justify-between items-center">
          <div class="font-bold text-lg info-title">1099-R Information - {{ currentRow.Year }}</div>
          <div class="print-button" *ngIf="currentRow.Status === Annual1099StatusType.Finalized">
            <ptg-button
              [buttonName]="'Print Form'"
              classInput="outline-button"
              (clickButton)="printForm()"
              [isDisabled]="isPrinting"
            >
              <mat-icon>print</mat-icon>
            </ptg-button>
          </div>
        </div>
        <div class="p-6 pb-4">
          <div class="grid grid-cols-3 gap-20">
            <div *ngFor="let row of currentInformation; let i = index;">
              <div *ngFor="let item of row; let j = index;" class="mb-6">
                <label class="info-label">{{ item.displayName }}</label>
                <span class="info-value flex gap-4">
                  <ptg-view-value
                    [data]="{ value: item.value, type: LIST_MASKED.includes(item.columnName) ? FilterPropertyType.SSN : item.dataType }"
                    [isSeparateLineAddress]="true"
                  ></ptg-view-value>
                  <div class="masked-icon flex items-end" *ngIf="LIST_MASKED.includes(item.columnName) && item.value">
                    <mat-icon matSuffix (click)="onClickMasked(item.columnName, 1, i, j)">
                      {{ item.value === item.row[item.columnName + '_value'] ? 'visibility' : 'visibility_off' }}
                    </mat-icon>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="px-6 py-3 font-bold text-lg border-t deduction-title">Deduction</div>
        <div class="px-6 pb-2">
          <div class="grid grid-cols-3 gap-20">
            <div *ngFor="let row of currentDeduction; let i = index;">
              <div class="flex flex-row justify-between mb-3" *ngFor="let item of row; let j = index;">
                <label class="deduction-label">
                  {{ item.displayName }}
                  <span class="label-subtext" *ngIf="item.subDisplayName">
                    {{ item.subDisplayName }}
                  </span>
                </label>
                <span class="deduction-value flex gap-2">
                  <ptg-view-value [data]="{ value: item.value, type: item.dataType }"></ptg-view-value>
                  <div class="masked-icon flex items-end" *ngIf="item.dataType === FilterPropertyType.SSN">
                    <mat-icon matSuffix (click)="onClickMasked(item.columnName, 2, i, j)">{{ item.dataType === 9 ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="p-4 font-bold text-lg border-b info-title">1099-R History</div>
        <ptg-grid
          [data]="info1099RecordData"
          [columns]="columns"
          [isLoading]="isLoading"
          [totalRecords]="lengthPg"
          [pageSize]="pageSize"
          [pageNumber]="pageNumber"
          (pageChange)="changePagging($event)"
          (sortChange)="sortChange($event)"
          (rowClick)="selectRow($event)"
          [(currentRowIndex)]="currentRowIndex"
          [fitToParent]="true"
          [errorMessage]="errorMsg"
          notFoundMessage="No History to Display"
          [hideScrollbar]="false"
        >
        </ptg-grid>
      </div>
    </div>
  </div>
</div>
