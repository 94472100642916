import { createAction, props } from '@ngrx/store';

import {
  AddCalculationRequest,
  CalculationDetailResponse,
  CalculationPropertiesResponse,
  GetCalculationsRequest,
  GetCalculationsResponse,
  UpdateCalculationRequest,
} from '../types/models';

export const GetCalculationsRequestAction =
  '[GetCalculations/API] Send Request';
export const GetCalculationsSuccessAction = '[GetCalculations/API] Success';
export const GetCalculationsFailureAction = '[GetCalculations/API] Failure';
export const ClearGetCalculationsStateAction =
  '[ClearGetCalculationsState] Clear';

export const AddCalculationRequestAction = '[AddCalculation/API] Send Request';
export const AddCalculationSuccessAction = '[AddCalculation/API] Success';
export const AddCalculationFailureAction = '[AddCalculation/API] Failure';

export const UpdateCalculationRequestAction =
  '[UpdateCalculation/API] Send Request';
export const UpdateCalculationSuccessAction = '[UpdateCalculation/API] Success';
export const UpdateCalculationFailureAction = '[UpdateCalculation/API] Failure';

export const RemoveCalculationRequestAction =
  '[RemoveCalculation/API] Send Request';
export const RemoveCalculationSuccessAction = '[RemoveCalculation/API] Success';
export const RemoveCalculationFailureAction = '[RemoveCalculation/API] Failure';

export const GetCalculationPropertiesRequestAction =
  '[GetCalculationProperties/API] Send Request';
export const GetCalculationPropertiesSuccessAction =
  '[GetCalculationProperties/API] Success';
export const GetCalculationPropertiesFailureAction =
  '[GetCalculationProperties/API] Failure';
export const ClearCalculationPropertiesStateAction =
  '[CalculationPropertiesState] Clear';

export const GetCalculationDetailRequestAction =
  '[GetCalculationDetail/API] Send Request';
export const GetCalculationDetailSuccessAction =
  '[GetCalculationDetail/API] Success';
export const GetCalculationDetailFailureAction =
  '[GetCalculationDetail/API] Failure';

export const getCalculations = createAction(
  GetCalculationsRequestAction,
  props<{ request: GetCalculationsRequest }>()
);

export const getCalculationsSuccess = createAction(
  GetCalculationsSuccessAction,
  props<{ response: GetCalculationsResponse }>()
);

export const getCalculationsFailure = createAction(
  GetCalculationsFailureAction,
  props<{ error?: any }>()
);

export const clearCalculationState = createAction(
  ClearGetCalculationsStateAction
);

export const addCalculation = createAction(
  AddCalculationRequestAction,
  props<{
    body: AddCalculationRequest;
    isContinue: boolean;
  }>()
);

export const addCalculationSuccess = createAction(AddCalculationSuccessAction);

export const addCalculationFailure = createAction(
  AddCalculationFailureAction,
  props<{ error?: any }>()
);

export const updateCalculation = createAction(
  UpdateCalculationRequestAction,
  props<{ body: UpdateCalculationRequest }>()
);

export const updateCalculationSuccess = createAction(
  UpdateCalculationSuccessAction
);

export const updateCalculationFailure = createAction(
  UpdateCalculationFailureAction,
  props<{ error?: any }>()
);

export const removeCalculation = createAction(
  RemoveCalculationRequestAction,
  props<{ id: string }>()
);

export const removeCalculationSuccess = createAction(
  RemoveCalculationSuccessAction
);

export const removeCalculationFailure = createAction(
  RemoveCalculationFailureAction,
  props<{ error?: any }>()
);

export const clearCalculationPropertiesState = createAction(
  ClearCalculationPropertiesStateAction
);
export const getCalculationProperties = createAction(
  GetCalculationPropertiesRequestAction
);
export const getCalculationPropertiesSuccess = createAction(
  GetCalculationPropertiesSuccessAction,
  props<{ response: CalculationPropertiesResponse }>()
);
export const getCalculationPropertiesFailure = createAction(
  GetCalculationPropertiesFailureAction,
  props<{ error?: any }>()
);

export const getCalculationDetail = createAction(
  GetCalculationDetailRequestAction,
  props<{ id: string }>()
);

export const getCalculationDetailSuccess = createAction(
  GetCalculationDetailSuccessAction,
  props<{ response: CalculationDetailResponse }>()
);

export const getCalculationDetailFailure = createAction(
  GetCalculationDetailFailureAction,
  props<{ error?: any }>()
);
