import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';

import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { noteIcon } from '@ptg-shared/constance/listIcons.const';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { STATE, SortType } from '@ptg-shared/constance/value.const';
import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';

import {
  PaymentHistory,
  PaymentInforConfigObj,
  PaymentHistoryListResponse,
  NextPaymentColumnConfig,
} from '../../types/models';
import {
  PAY_STATUS_TYPE_INFO,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import * as fromNextPayment from '../../reducers/index';
import { PayStatus } from '../../constance/member-list.const';
import * as NextPaymentActions from '../../actions/next-payment.actions';
import { MemberDetailService } from '../../services/member-detail.service';
import { getColumConfig } from '../../helpers';
import { PropertyType } from '../../constance/metadataPropertyType.const';

@Component({
  selector: 'ptg-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
})
export class PaymentHistoryComponent
  extends BaseComponent
  implements OnChanges
{
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  paymentHistoriesDataResponse: PaymentHistoryListResponse = {
    paymentHistories: [],
    columns: [],
    total: 0,
  };
  paymentHistoriesData: PaymentHistory[] = [];
  isLoading = true;
  currentRowIndex: number = -1;
  isShowInTable = false;
  isHavePendingPayment: boolean = false;
  errorMsg: string = '';
  currentColumns: Column[] = [];
  columns: Column[] = [];
  PaymentInfoType = PaymentInfoType;
  currentFund: any;
	pageName = 'ptg-payment-history';

  @Input() selectedRowId: string | undefined;
  @Input() isHistory: boolean = false;
  @Input() isShowNote: boolean = false;
  @Input() paymentInforConfig!: PaymentInforConfigObj;
  @Input() isEncryptedConfiguredName: boolean = false;
  @Output() onClickNewOffCycle = new EventEmitter();
  @Output() onSelectRow = new EventEmitter();

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private memberDetailService: MemberDetailService,
    private fundStore: Store<fromReducer.State>,
    private store: Store<fromNextPayment.State>
  ) {
    super();
    iconRegistry.addSvgIconLiteral(
      'note-icon',
      sanitizer.bypassSecurityTrustHtml(noteIcon)
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fundStore
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize')) === 0?  this.pageSize: Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize'));
    this.getData();
    this.store
      .pipe(
        select(fromNextPayment.selectNextPaymentHistory),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.isLoading = el?.isLoading!;
        // Get an error message (if any)
        if (!el?.success && el?.error) {
          this.errorMsg = el?.error?.statusText;
          this.paymentHistoriesData = [];
        }

        // Binding the result to the grid
        if (el?.success) {
          this.paymentHistoriesDataResponse = el?.payload!;
          this.mapData();
        }

        this.lengthPg = el?.total;
        this.currentRowIndex = -1;
        if (this.selectedRowId) {
          const currentRowIndex =
            this.paymentHistoriesDataResponse?.paymentHistories.findIndex(
              (row: any) => row.targetId === this.selectedRowId
            );

          if (currentRowIndex! >= 0) {
            this.currentRowIndex = currentRowIndex;
          }
        }
      });
    this.store
      .pipe(
        select(fromNextPayment.selectNextPayment),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el.addNoteState === STATE.SUCCESS) {
          this.getData();
        }
      });
    this.store
      .pipe(
        select(fromNextPayment.selectPendingPaymentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success) {
          this.isHavePendingPayment = !!state?.payload?.pendingPayments?.length;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRowId) {
      const currentRowIndex =
        this.paymentHistoriesDataResponse?.paymentHistories?.findIndex(
          (row: any) => row.targetId === changes.selectedRowId.currentValue
        );

      if (currentRowIndex! >= 0) {
        this.currentRowIndex = currentRowIndex!;
      } else {
        this.currentRowIndex = -1;
      }
    } else {
      this.currentRowIndex = -1;
    }

    if (changes.isShowNote) {
      this.currentColumns = this.columns;
      if (!changes.isShowNote.currentValue)
        this.currentColumns = this.columns.filter(
          (column) => column.name !== 'note'
        );
    }
  }

  getData() {
    let sortType = 0;
    let sortField = '';

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = this.sortInfo.active;
      sortType =
        this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }

    this.store.dispatch(
      NextPaymentActions.getNextPaymentHistory({
        query: {
          pageIndex: this.pageNumber,
          totalPerPage: this.pageSize,
          sortField,
          sortType,
        },
        memberId: this.memberDetailService.memberId,
      })
    );
  }

  sortChange(event: any) {
    if (event.active === 'dedsTotal') {
      event.active = 'Deds';
    }

    if (event.active === 'status') {
      event.active = 'PayStatus';
    }

    if (event.active === 'type') {
      event.active = 'PaymentType';
    }

    this.sortInfo = event;
  }

  changePagging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-' + this.pageName + '-pageSize', this.pageSize.toString());
    sessionStorage.setItem(this.currentFund.key+ '-' + this.pageName + '-pageNumber', this.pageNumber.toString()); 
    this.getData();
  }

  selectRow(event: PaymentHistory & Row) {
    this.onSelectRow.emit(event);
  }

  newOffCycle() {
    this.onClickNewOffCycle.emit();
  }

  mapData() {
    if (this.isLoading) {
      return;
    }
    this.columns = this.getConfigColumns(
      this.paymentHistoriesDataResponse?.columns || []
    );
    if (!this.isShowNote) {
      this.currentColumns = this.columns.filter(
        (column) => column.name !== 'note'
      );
    } else {
      this.currentColumns = this.columns;
    }

    if (this.currentColumns.length > 0) {
      this.paymentHistoriesData =
        this.paymentHistoriesDataResponse?.paymentHistories.map((item: any) => {
          const statusInfo = PAY_STATUS_TYPE_INFO.find(
            (s) => s.Status === item.payStatus
          );

          return {
            ...item,
            status: '',
            tooltipPayStatus: statusInfo?.Name,
            className: statusInfo?.ClassName,
            suspend: item.payStatus === PayStatus.Suspend,
            prevSuspend: item.prevPayStatus === PayStatus.Suspend,
            iconName: statusInfo?.IconName,
            color: statusInfo?.IconColor,
          };
        });
    } else {
      this.paymentHistoriesData = [];
    }
  }

  getConfigColumns(columns: NextPaymentColumnConfig[]) {
    this.columns = [];
    if (!columns.length) {
      return [];
    }
    columns.forEach((column: any) => {
      const columConfig = getColumConfig(column.type, [], column.propertyKey);
      this.columns.push({
        name: column.propertyKey,
        header: {
          title: column.columnName,
          style:
            column.type === PropertyType.TYPE_CURRENCY
              ? {
                  'padding-right': 0,
                }
              : {},
        },
        truncate: true,
        sortable: column.propertyKey === 'note' ? false : true,
        align:
          column.type === PropertyType.TYPE_CURRENCY ? Align.Right : Align.Left,
        type:
          columConfig.type === ColumnType.Binary
            ? PropertyType.TYPE_TEXT
            : columConfig.type,
        templateArgs: columConfig.templateArgs,
        style:
          column.type === PropertyType.TYPE_CURRENCY
            ? {
                'padding-right': '16px',
              }
            : {},
      });
    });
    this.columns.unshift({
      name: 'status',
      sortable: true,
      align: Align.Center,
      width: '25px',
    });
    return this.columns;
  }
}
