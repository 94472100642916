import { Component, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, tap, switchMap, startWith, take } from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AbstractControlStatus } from '@ptg-shared/models/common.model';
import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';

import { FilterInfo, MemberFilterConfig } from '../../types/models';
import { MemberListService } from '../../services/member-list.service';
import { MemberListActions } from '../../actions';
import { AddSectionComponent } from '../add-section/add-section.component';

@Component({
  selector: 'ptg-save-member-filter',
  templateUrl: './save-member-filter.component.html',
  styleUrls: ['./save-member-filter.component.scss'],
})
export class SaveMemberFilterComponent extends BaseComponent {
  saveForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.memberService.checkMemberFilterExist,
        'name'
      ),
    }),
  });
  formSubmit$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterInfo[],
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddSectionComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public memberService: MemberListService,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.saveForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.saveForm.statusChanges.pipe(
            startWith(this.saveForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    this.saveForm.markAllAsTouched();
    if (!this.saveForm.valid) {
      return;
    }
    const body: MemberFilterConfig = {
      name: this.saveForm.value.name,
      listFilterInfo: this.data,
    };
    this.store.dispatch(MemberListActions.createMemberFilterRequest({ body }));
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
