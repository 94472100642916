import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ACTION } from '@ptg-shared/constance/value.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromReducer from '@ptg-reducers';

import * as ParticipantSearchConfigurationActions from '../../actions/participant-search-configuration.actions';
import {
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
  SectionConfig,
} from '../../types/models';
import * as fromMember from '../../reducers';

@Component({
  selector: 'ptg-participant-search-configuration',
  templateUrl: './participant-search-configuration.component.html',
  styleUrls: ['./participant-search-configuration.component.scss'],
})
export class ParticipantSearchConfigurationComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Participant Search Configuration',
      url: '',
    },
  ];
  propertyConfigs!: PropertyDisplayConfig[];
  propertyDisplayConfigurations!: PropertyDisplayConfiguration[];
  addPropertySection: SectionConfig = {
    title: 'Add Property',
    columnName: 'Label Name',
    propertyName: 'Property Name',
  };
  sortPropertySection: SectionConfig = {
    title: 'Order Properties In Result List',
  };
  sortRowSection: SectionConfig = { title: 'Rank Searchable Properties' };
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.memberStore.dispatch(
      ParticipantSearchConfigurationActions.getParticipantSearchPropertyList()
    );
    this.memberStore.dispatch(
      ParticipantSearchConfigurationActions.getParticipantSearchConfiguration()
    );

    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchPropertyConfigs),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyConfigs) => {
        this.propertyConfigs = propertyConfigs;
      });
    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchPropertyDisplayConfigurations),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyDisplayConfigurations) => {
        this.propertyDisplayConfigurations = JSON.parse(
          JSON.stringify(propertyDisplayConfigurations)
        ) as PropertyDisplayConfiguration[];
      });
    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchConfigurationUpdateState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.memberStore.dispatch(
          ParticipantSearchConfigurationActions.getParticipantSearchConfiguration()
        );
        this.memberStore.dispatch(
          ParticipantSearchConfigurationActions.clearParticipantSearchConfigurationState()
        );
        showBanner.call(
          this,
          state,
          'Participant Search Configuration',
          ACTION.EDIT
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit(listMemberSearchConfig: PropertyDisplayConfiguration[]) {
    this.memberStore.dispatch(
      ParticipantSearchConfigurationActions.setParticipantSearchConfiguration({
        body: {
          listMemberSearchConfig,
        },
      })
    );
    this.memberStore
      .pipe(select(fromMember.selectMemberNavigationState), take(1))
      .subscribe((navigationState) => {
        if (navigationState.query?.pageIndex) {
          this.store.dispatch(
            LayoutActions.closeTab({
              tabName: DisplayedTabName.SearchedParticipant,
            })
          );
        }
      });
  }
}
