import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MemberListConfigurationActions from '../actions/member-list-configuration.actions';
import {
  ListConfig,
  PropertyConfigs,
} from '../types/models/property-display-configuration.model';
import { MemberListConfigurationService } from '../services/member-list-configuration.service';

@Injectable()
export class MemberListConfigurationEffects {
  constructor(
    private actions$: Actions,
    private memberListConfigurationService: MemberListConfigurationService
  ) {}

  getPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListConfigurationActions.getPropertyList),
      switchMap(({ itemKey }) => {
        return this.memberListConfigurationService
          .getPropertyList(itemKey)
          .pipe(
            map((res: PropertyConfigs) => {
              return MemberListConfigurationActions.getPropertyListSuccess({
                propertyConfigs: res.listSectionProperty,
              });
            }),
            catchError((err) => {
              return of(
                MemberListConfigurationActions.getPropertyListFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  getMemberListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListConfigurationActions.getMemberListConfiguration),
      switchMap(({ id }) => {
        return this.memberListConfigurationService
          .getMemberListConfiguration(id)
          .pipe(
            map((res: ListConfig) => {
              return MemberListConfigurationActions.getMemberListConfigurationSuccess(
                { propertyDisplayConfigurations: res.listConfig }
              );
            }),
            catchError((err) => {
              return of(
                MemberListConfigurationActions.getMemberListConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  setMemberListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListConfigurationActions.setMemberListConfiguration),
      switchMap(({ body }) => {
        return this.memberListConfigurationService
          .manageConfigurationMember(body)
          .pipe(
            map(() => {
              return MemberListConfigurationActions.setMemberListConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                MemberListConfigurationActions.setMemberListConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
}
