<div class="ngx-timepicker" *ngIf="controlField">
  <mat-form-field appearance="fill" class="{{class}}">
      <mat-label>&ensp;{{placeholder}}</mat-label>
      <input matInput
          #inputTime
          [ngxTimepicker]="picker"
          [formControl]="controlField"
          [disableClick]="true"
          autocomplete="off"
          (focusout)="onFocusOut($event)"
          (focusin)="onFocusIn($event)"
          (input)="onInput($event)"
          (keypress)="onKeypress($event)">
      <ngx-material-timepicker-toggle matSuffix [for]="picker" ></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #picker
          [appendToInput]="true"
          [disableAnimation]="true"
          (closed)="onTimepickerClosed()"
          [enableKeyboardInput]="true"
          (timeChanged)="onTimeChange($event)"
          timepickerClass="time-picker-container">
      </ngx-material-timepicker>
      <mat-error *ngIf="controlField?.errors?.required">{{placeholder + ' is required.'}}</mat-error>
      <mat-error *ngIf="!controlField?.errors?.required && controlField?.errors?.invalid">{{errorInvalid}}</mat-error>
      <mat-error *ngIf="!controlField?.errors?.required && !controlField?.errors?.invalid && controlField?.errors?.invalidMinTime">{{errorInvalidMinTime}}</mat-error>
  </mat-form-field>
</div>
