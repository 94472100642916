import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { FIRST_PAGE } from './constants';
import { PageEvent, Pager } from './types';

@Component({
  selector: 'ptg-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  readonly FIRST_PAGE = FIRST_PAGE;

  pager: Pager = {
    totalRecords: 0,
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    startPage: 0,
    endPage: 0,
    startIndex: 0,
    endIndex: 0,
    pages: [],
  };

  @Input() pageNumber: number = FIRST_PAGE;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number | string = 50;
  @Input() pageSizeOptions: (string | number)[] = [
    10, 20, 30, 40, 50, 100, 200,
  ];
  @Input() hiddenPageSizeOptions: boolean = false;
  @Input() maxPages: number = 5;

  @Output() pageChange = new EventEmitter<PageEvent>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.pageNumber ||
      changes.totalRecords ||
      changes.pageSize ||
      changes.maxPages
    ) {
      this.setPager();
    }
  }

  jumpToFirst(): void {
    this.jumpTo(FIRST_PAGE);
  }

  jumpToPrev(): void {
    this.jumpTo(this.pager.pageNumber - 1);
  }

  jumpTo(pageNumber: number): void {
    this.pageNumber = pageNumber;
    this.setPager();
    this.pageChange.emit({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    } as PageEvent);
  }

  jumpToNext(): void {
    this.jumpTo(this.pager.pageNumber + 1);
  }

  jumpToLast(): void {
    this.jumpTo(this.pager.totalPages);
  }

  onChangePageSize(event: MatSelectChange): void {
    this.pageSize =
      event.value.toString() == 'All' ? this.pager.totalRecords : event.value;
    this.jumpToFirst();
  }

  private setPager(): void {
    if (!this.totalRecords) {
      this.pager = {
        totalRecords: 0,
        pageNumber: FIRST_PAGE,
        pageSize: this.pageSize,
        totalPages: 0,
        startPage: 0,
        endPage: 0,
        startIndex: 0,
        endIndex: 0,
        pages: [],
      };
      return;
    }

    this.pageSize = this.pageSize == 'All' ? this.totalRecords : this.pageSize;
    const totalPages = Math.ceil(this.totalRecords / Number(this.pageSize));
    if (this.pageNumber < 1) {
      this.pageNumber = 1;
    } else if (this.pageNumber > totalPages) {
      this.pageNumber = totalPages;
    }

    let startPage = 1;
    let endPage = 1;
    if (totalPages <= this.maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(this.maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(this.maxPages / 2) - 1;
      if (this.pageNumber <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = this.maxPages;
      } else if (this.pageNumber + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - this.maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = this.pageNumber - maxPagesBeforeCurrentPage;
        endPage = this.pageNumber + maxPagesAfterCurrentPage;
      }
    }

    const startIndex = (this.pageNumber - 1) * Number(this.pageSize);
    const endIndex = Math.min(
      startIndex + Number(this.pageSize) - 1,
      this.totalRecords - 1,
    );

    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    this.pager = {
      totalRecords: this.totalRecords,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
    this.pageSize = this.pageSize == this.totalRecords ? 'All' : this.pageSize;
  }
}
