import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { MemberGraphViewData } from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class ParticipantRelationshipService {
  constructor(private httpClient: HttpClient) {}

  getParticipantRelationships(
    memberId: string
  ): Observable<MemberGraphViewData> {
    return this.httpClient.get<MemberGraphViewData>(
      `${environment.apiUrl}/Members/${memberId}/MemberGraphViewData`
    );
  }
}
