import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import {
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
} from '../types/models';
import * as MemberListConfigurationAction from '../actions/member-list-configuration.actions';

export const memberListConfigurationFeatureKey = 'memberListConfiguration';

export interface State {
  isLoading: boolean;
  propertyConfigs: PropertyDisplayConfig[];
  propertyDisplayConfigurations: PropertyDisplayConfiguration[];
  updateState: string;
}

const initialState: State = {
  isLoading: true,
  propertyConfigs: [],
  propertyDisplayConfigurations: [],
  updateState: '',
};

export const reducer = createReducer(
  initialState,
  on(
    MemberListConfigurationAction.clearMemberListConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
    })
  ),
  on(
    MemberListConfigurationAction.getPropertyListSuccess,
    (state, { propertyConfigs }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
    })
  ),
  on(
    MemberListConfigurationAction.getPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(
    MemberListConfigurationAction.getMemberListConfigurationSuccess,
    (state, { propertyDisplayConfigurations }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations,
    })
  ),
  on(
    MemberListConfigurationAction.getMemberListConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
    })
  ),
  on(
    MemberListConfigurationAction.setMemberListConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
    })
  ),
  on(
    MemberListConfigurationAction.setMemberListConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
      updateState: STATE.FAIL,
    })
  )
);
