<div class="accordion">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [settings]="settings"
  ></ptg-breadcrumb>
  <ptg-report-viewer #viewer1
    [containerStyle]="viewerContainerStyle"
    [serviceUrl]="apiUrl"
    [reportSource]="source"
    [viewMode]="'PRINT_PREVIEW'"
    [scaleMode]="'FIT_PAGE_WIDTH'"
    [scale]="1.0"
    [enableAccessibility]="false"
    [documentMapVisible]="true"
    [documentMapAreaPosition]="'LEFT'"
    [parameters]="{editors: {
      singleSelect: 'COMBO_BOX',
      multiSelect: 'COMBO_BOX'
    }}"
    [templateUrl]="'assets/telerikReportViewerTemplate.html'"
    [parametersAreaPosition]="'TOP'">
  </ptg-report-viewer>
</div>