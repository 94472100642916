export enum ColumnType {
  Address,
  Binary,
  Decimal,
  DateTime,
  Icon,
  PhoneNumber,
  PersonName,
  RichText,
  // Select,
  // Text,
  // TextPlus,
  // RichText,
  // WholeNumber,
  // Icon,
  // Button,
  // GroupButton,
  // Link,
  // Image,
  // TextHTML,
}
