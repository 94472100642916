import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { LookupTable, PropertyEditData } from '../../types/models';

@Component({
  selector: 'ptg-modify-property-container',
  templateUrl: './modify-property-container.component.html',
  styleUrls: ['./modify-property-container.component.scss'],
})
export class ModifyPropertyContainerComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModifyPropertyContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      property: PropertyEditData;
      isEdit: boolean;
      lookupTables?: LookupTable[];
      itemKey?: string;
    },
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  closeDialog(propertyData: PropertyEditData) {
    this.dialogRef.close(propertyData);
  }
}
