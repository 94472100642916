import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ParticipantSearchConfigurationActions from '../actions/participant-search-configuration.actions';
import {
  ParticipantSearchConfigurations,
  PropertyConfigs,
} from '../types/models';
import { ParticipantSearchConfigurationService } from '../services/participant-search-configuration.service';

@Injectable()
export class ParticipantSearchConfigurationEffects {
  constructor(
    private actions$: Actions,
    private participantSearchConfigurationService: ParticipantSearchConfigurationService
  ) {}

  getPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.getParticipantSearchPropertyList
      ),
      switchMap(() => {
        return this.participantSearchConfigurationService
          .getParticipantSearchPropertyList()
          .pipe(
            map((res: PropertyConfigs) => {
              return ParticipantSearchConfigurationActions.getParticipantSearchPropertyListSuccess(
                { propertyConfigs: res.listSectionProperty }
              );
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.getParticipantSearchPropertyListFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getParticipantSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.getParticipantSearchConfiguration
      ),
      switchMap(() => {
        return this.participantSearchConfigurationService
          .getParticipantSearchConfiguration()
          .pipe(
            map((res: ParticipantSearchConfigurations) => {
              return ParticipantSearchConfigurationActions.getParticipantSearchConfigurationSuccess(
                { propertyDisplayConfigurations: res.listMemberSearchConfig }
              );
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.getParticipantSearchConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  setParticipantSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.setParticipantSearchConfiguration
      ),
      switchMap(({ body }) => {
        return this.participantSearchConfigurationService
          .manageParticipantSearchConfiguration(body)
          .pipe(
            map(() => {
              return ParticipantSearchConfigurationActions.setParticipantSearchConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.setParticipantSearchConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
}
