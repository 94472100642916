import { Store, select } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { ColumnType } from '@ptg-shared/controls/grid';

import { PropertyType } from './constance/metadataPropertyType.const';
import {
  FIXED_PROPERTIES,
  ICON_REGISTER_FIELDS,
  ICON_STATUS_FIELDS,
} from './constance/member-list.const';
import { getMemberProfileHeadersDataAction } from './actions/profile-header-configuration.actions';
import {
  MemberState,
  getMetadataNonListSectionsSelector,
  getMetadataSectionsSelector,
  getProfileHeadersSelector,
} from './reducers';
import {
  getMetadataNonListSectionsAction,
  getMetadataSectionsAction,
} from './actions/member-detail.actions';

const EXPIRE_TIME = 60 * 60;

export function getColumConfig(
  columnType: typeof PropertyType,
  configValue: any,
  columnKey: string = ''
) {
  let type = undefined;
  let templateArgs = undefined;
  switch (columnType) {
    case PropertyType.TYPE_DATE:
      type = ColumnType.DateTime;
      templateArgs = { format: 'MM/dd/yyyy' };
      break;
    case PropertyType.TYPE_DATE_TIME:
      type = ColumnType.DateTime;
      templateArgs = { format: 'MM/dd/yyyy h:mm a' };
      break;
    case PropertyType.TYPE_CURRENCY:
      if (columnKey.startsWith('Calculation_')) {
        type = ColumnType.Decimal;
        templateArgs = {
          decimal: configValue?.fractionalLengthInput,
          unit: '$',
          unitPosition: 'left',
        };
      } else {
        type = ColumnType.Decimal;
        templateArgs = { decimal: 2, unit: '$', unitPosition: 'left' };
      }

      break;
    case PropertyType.TYPE_PERCENTAGE:
      if (columnKey.startsWith('Calculation_')) {
        type = ColumnType.Decimal;
        templateArgs = {
          decimal: configValue?.fractionalLengthInput,
          unit: '%',
          unitPosition: 'right',
        };
      } else {
        type = ColumnType.Decimal;
        templateArgs = { decimal: 2, unit: '%', unitPosition: 'right' };
      }
      break;
    case PropertyType.TYPE_DECIMAL:
      type = ColumnType.Decimal;
      templateArgs = {
        decimal: configValue?.fractionalLengthInput
          ? +configValue.fractionalLengthInput
          : 0,
      };
      break;
    case PropertyType.TYPE_WHOLE_NUMBER:
      type = ColumnType.Decimal;
      templateArgs = { decimal: 0 };
      break;
    case PropertyType.TYPE_RICH_TEXT:
      type = ColumnType.RichText;
      break;
    case PropertyType.TYPE_STATUS:
    case PropertyType.TYPE_MEMBER_STATUS:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: ICON_STATUS_FIELDS.statusIconName,
        colorField: ICON_STATUS_FIELDS.statusIconColor,
        labelField:
          columnKey === FIXED_PROPERTIES.StatusEvent
            ? ICON_STATUS_FIELDS.eventIconLabel
            : ICON_STATUS_FIELDS.statusIconLabel,
      };
      break;
    case PropertyType.TYPE_PHONE:
      type = ColumnType.PhoneNumber;
      break;
    case PropertyType.TYPE_REGISTER:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: ICON_REGISTER_FIELDS.registerIconName,
        colorField: ICON_REGISTER_FIELDS.registerIconColor,
        styleField: { 'justify-content': 'center' },
      };
      break;
    case PropertyType.TYPE_ADDRESS:
      type = ColumnType.Address;
      break;
    case PropertyType.TYPE_PERSON_NAME:
      type = ColumnType.PersonName;
      break;
    case PropertyType.TYPE_BOOLEAN:
      type = ColumnType.Binary;
      templateArgs = configValue;
      break;
    default:
      break;
  }
  return {
    type: type,
    templateArgs: templateArgs,
  };
}

export function dispatchGetMemberProfileHeaders(
  store: Store<MemberState>,
  memberId: string,
  forceLoad: boolean = false
) {
  store
    .pipe(
      select(getProfileHeadersSelector),
      take(1),
      filter(
        (state) =>
          !state?.updatedAt ||
          DateTime.now().diff(DateTime.fromISO(state.updatedAt), 'seconds')
            .seconds > EXPIRE_TIME ||
          forceLoad ||
          state?.payload?.memberId !== memberId
      )
    )
    .subscribe(() =>
      store.dispatch(getMemberProfileHeadersDataAction({ memberId }))
    );
}

export function dispatchGetMetadataSections(
  store: Store<MemberState>,
  forceLoad: boolean = false
) {
  store
    .pipe(
      select(getMetadataSectionsSelector),
      take(1),
      filter(
        (state) =>
          !state?.updatedAt ||
          DateTime.now().diff(DateTime.fromISO(state.updatedAt), 'seconds')
            .seconds > EXPIRE_TIME ||
          forceLoad
      )
    )
    .subscribe(() => store.dispatch(getMetadataSectionsAction()));
}

export function dispatchGetMetadataNonListSections(
  store: Store<MemberState>,
  memberNavigationItemId: string,
  forceLoad: boolean = false
) {
  store
    .pipe(
      select(getMetadataNonListSectionsSelector),
      take(1),
      filter(
        (state) =>
          !state?.updatedAt ||
          DateTime.now().diff(DateTime.fromISO(state.updatedAt), 'seconds')
            .seconds > EXPIRE_TIME ||
          forceLoad ||
          state?.payload?.memberNavigationItemId !== memberNavigationItemId
      )
    )
    .subscribe(() =>
      store.dispatch(
        getMetadataNonListSectionsAction({ memberNavigationItemId })
      )
    );
}
