export interface EditMunicipalityServiceHistoryRequest {
  id?: string;
  memberId: string;
  beginDate: string;
  endDate?: string;
  municipalityId: string;
}

export interface MunicipalityNameList {
  employers: Municipality[];
}

export interface Municipality {
  value: string;
  description: string;
  disabled: boolean;
}
