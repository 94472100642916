import { createReducer, on } from '@ngrx/store';

import { ParticipantRelationship } from '../types/models';
import * as ParticipantRelationshipAction from '../actions/participant-relationship.actions';

export const participantRelationshipFeatureKey = 'participantRelationship';

export interface State {
  isLoading: boolean;
  participantRelationships: ParticipantRelationship[];
}

const initialState: State = {
  isLoading: true,
  participantRelationships: [],
};

export const reducer = createReducer(
  initialState,
  on(ParticipantRelationshipAction.getParticipantRelationship, (state) => ({
    ...state,
    isLoading: true,
    participantRelationships: [],
  })),
  on(
    ParticipantRelationshipAction.getParticipantRelationshipSuccess,
    (state, { participantRelationships }) => ({
      ...state,
      isLoading: false,
      participantRelationships,
    })
  ),
  on(
    ParticipantRelationshipAction.getParticipantRelationshipFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      participantRelationships: [],
    })
  )
);
