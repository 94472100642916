import { createReducer, on } from '@ngrx/store';

import * as MunicipalityPaymentAction from '../actions/municipality-payment.action';
import { MunicipalityList } from '../types/models';

export const MunicipalityPaymentFeatureKey = 'municipalityPayment';
export interface State {
  isLoading: boolean;
  payments: MunicipalityList;
  createState?: string;
  updateState?: string;
}

const initialState: State = {
  isLoading: true,
  payments: {
    total: 0,
    payments: [],
    totalService: '',
  },
  createState: '',
  updateState: '',
};

export const reducer = createReducer(
  initialState,
  on(MunicipalityPaymentAction.getMunicipalityPaymentList, (state, {}) => ({
    ...state,
    isLoading: true,
    payments: {
      total: 0,
      payments: [],
      totalService: '',
    },
  })),
  on(
    MunicipalityPaymentAction.municipalityPaymentListSuccess,
    (state, { payments }) => ({
      ...state,
      isLoading: false,
      payments,
    })
  ),
  on(MunicipalityPaymentAction.municipalityPaymentListFailure, (state) => ({
    ...state,
    isLoading: false,
    payments: {
      total: 0,
      payments: [],
      totalService: '',
    },
  })),

  on(MunicipalityPaymentAction.clearMunicipalityPaymentState, (state) => ({
    ...state,
    createState: '',
    updateState: '',
  }))
);
