import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import {
  Align,
  Column,
  ColumnType,
  GridComponent,
  Row,
} from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import * as fromReducer from '@ptg-reducers';

import * as NextPaymentActions from '../../actions/next-payment.actions';
import {
  PAY_STATUS_TYPE_INFO,
  PaymentInfoField,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import {
  NextPaymentColumnConfig,
  PendingPayment,
  PendingPaymentListResponse,
  PendingPaymentQuery,
} from '../../types/models';
import * as fromNextPayment from './../../reducers/index';
import { getColumConfig } from '../../helpers';
import { PropertyType } from '../../constance/metadataPropertyType.const';

const PAGE_SIZE_CONST = '-ptg-pending-payment-pageSize'
@Component({
  selector: 'ptg-pending-payment',
  templateUrl: './pending-payment.component.html',
  styleUrls: ['./pending-payment.component.scss'],
})
export class PendingPaymentComponent extends BaseComponent {
  @Input() selectedRowId: string | undefined = undefined;
  @Input() isEncryptedConfiguredName: boolean = false;
  @Input() isShowNote: boolean = false;

  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  pendingPaymentsDataResponse: PendingPaymentListResponse = {
    total: 0,
    pendingPayments: [],
    columns: [],
  };

  pendingPaymentsData: PendingPayment[] = [];
  isLoading: boolean = false;
  currentRowIndex: number = -1;
  memberId: string = '';
  errorMsg: string = '';
  currentColumns: Column[] = [];
  columns: Column[] = [];
  PaymentInfoType = PaymentInfoType;
  currentFund: any = {};
  @Output() onSelectRow = new EventEmitter();
  @Output() onClickNewOffCycle = new EventEmitter();
  @ViewChild('table') table!: GridComponent<PendingPayment>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromNextPayment.State>,
    private fundStore: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fundStore
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));

      });
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.getData();
      }
    });
    this.store
      .pipe(
        select(fromNextPayment.selectPendingPaymentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && state?.isLoading === false) {
          this.isLoading = state?.isLoading || false;
          // Get an error message (if any)
          if (!state.success && state.error) {
            this.errorMsg = state.error.statusText;
            this.pendingPaymentsData = [];
          }

          // Binding the result to the grid
          if (state.success) {
            this.lengthPg = state?.total;
            this.pendingPaymentsDataResponse = state?.payload!;
            this.mapData();
          }

          this.currentRowIndex = -1;
          if (this.selectedRowId) {
            this.currentRowIndex =
              this.pendingPaymentsDataResponse?.pendingPayments.findIndex(
                (row: any) => row.id === this.selectedRowId
              );
          }
        }
      });
    this.store
      .pipe(
        select(fromNextPayment.selectReloadPendingPaymentsStateState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.isReload) {
          this.selectedRowId = state?.selectedRowId;
          if (state?.resetSort) {
            this.table?.clearSort();
          } else {
            this.getData();
          }
          this.store.dispatch(
            NextPaymentActions.clearReloadPendingPaymentsState()
          );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRowId) {
      this.currentRowIndex =
        this.pendingPaymentsDataResponse?.pendingPayments.findIndex(
          (row: any) => row.id === changes.selectedRowId.currentValue
        );
    } else {
      this.currentRowIndex = -1;
    }

    if (changes.isShowNote) {
      this.currentColumns = this.columns;
      if (!changes.isShowNote.currentValue) {
        this.currentColumns = this.columns.filter(
          (column) => column.name !== 'note'
        );
      }
    }
  }

  getData() {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField =
        this.sortInfo.active === PaymentInfoField.Payee &&
        this.isEncryptedConfiguredName
          ? PaymentInfoField.OrderByPayee
          : this.sortInfo.active;
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: PendingPaymentQuery = {
      participantId: this.memberId,
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
    };
    this.store.dispatch(NextPaymentActions.getPendingPaymentRequest({ query }));
  }

  sortChange(event: any) {
    this.sortInfo = event;
  }

  changePagging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
     
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString()); 
    this.getData();
  }

  selectRow(event: PendingPayment & Row) {
    this.onSelectRow.emit(event);
  }

  newOffCycle() {
    this.onClickNewOffCycle.emit();
  }

  mapData() {
    if (this.isLoading) {
      return;
    }
    this.columns = this.getConfigColumns(
      this.pendingPaymentsDataResponse?.columns || []
    );
    if (!this.isShowNote) {
      this.currentColumns = this.columns.filter(
        (column) => column.name !== 'note'
      );
    } else {
      this.currentColumns = this.columns;
    }

    if (this.currentColumns.length > 0) {
      this.pendingPaymentsData =
        this.pendingPaymentsDataResponse?.pendingPayments?.map((item: any) => {
          const statusInfo = PAY_STATUS_TYPE_INFO.find(
            (s) => s.Status === item.status
          );
          return {
            ...item,
            netPayment: item.netPayment < 0 ? 0 : item.netPayment,
            payStatus: '',
            tooltipPayStatus: statusInfo?.Name,
            iconName: statusInfo?.IconName,
            color: statusInfo?.IconColor,
            className: statusInfo?.ClassName,
          };
        });
    } else {
      this.pendingPaymentsData = [];
    }
  }

  getConfigColumns(columns: NextPaymentColumnConfig[]) {
    this.columns = [];
    if (!columns.length) {
      return [];
    }
    columns.forEach((column: any) => {
      const columConfig = getColumConfig(column.type, [], column.propertyKey);
      this.columns.push({
        name: column.propertyKey,
        header: {
          title: column.columnName,
          style:
            column.type === PropertyType.TYPE_CURRENCY
              ? {
                  'padding-right': 0,
                }
              : {},
        },
        truncate: true,
        sortable: column.propertyKey === 'note' ? false : true,
        align:
          column.type === PropertyType.TYPE_CURRENCY ? Align.Right : Align.Left,
        type:
          columConfig.type === ColumnType.Binary
            ? PropertyType.TYPE_TEXT
            : columConfig.type,
        templateArgs: columConfig.templateArgs,
        style:
          column.type === PropertyType.TYPE_CURRENCY
            ? {
                'padding-right': '16px',
              }
            : {},
      });
    });
    this.columns.unshift({
      name: 'status',
      sortable: true,
      align: Align.Center,
      width: '25px',
    });
    return this.columns;
  }
}
