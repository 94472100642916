import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { FileService } from '@ptg-shared/services/file-service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { iconUploadAttachment } from '@ptg-shared/constance/listIcons.const';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ACTION } from '@ptg-shared/constance/value.const';
import * as fromReducer from '@ptg-reducers';

import * as fromMember from '../../reducers';
import { MetadataSection } from '../../types/models';
import { MemberDetailActions } from '../../actions';
import { MemberDetailService } from '../../services/member-detail.service';
import { MemberAttachmentModalComponent } from '../member-attachment-modal/member-attachment-modal.component';
import { SectionLayout } from '../../constance/metadata.const';

const PAGE_SIZE_CONST = '-ptg-member-section-attachment-pageSize';

export interface AttachmentData {
  memberId: string;
  itemKey: string;
  configSection: MetadataSection;
  breadcrumbs: Breadcrumb[];
  isNavigationPage: boolean;
  rowId: string;
  menuName: string;
}

@Component({
  selector: 'ptg-member-section-attachment',
  templateUrl: './member-section-attachment.component.html',
  styleUrls: ['./member-section-attachment.component.scss'],
})
export class MemberSectionAttachmentComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  isLoading: boolean = true;
  dataTable: any[] = [];
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  memberId!: string;
  itemKey!: string;
  sectionData!: MetadataSection;
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Overview' }, { name: '' }];
  columns: Column[] = [
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'dateCreated',
      header: {
        title: 'Date Posted',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      sortable: true,
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'attachment',
      header: {
        title: 'Attachment',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'action',
      header: {
        title: 'Action',
      },
    },
  ];
  currentFund: any = {};
  constructor(
    private store: Store<fromReducer.State>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentData,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<MemberSectionAttachmentComponent>,
    private memberDetailService: MemberDetailService,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'upload-attachment-icon',
      sanitizer.bypassSecurityTrustHtml(iconUploadAttachment)
    );
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize =
          Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          ) === 0
            ? this.pageSize
            : Number(
                sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
              );
      });
    if (this.data) {
      this.memberId = this.data.memberId;
      this.itemKey = this.data.configSection?.key;
      this.sectionData = this.data.configSection;
    }
    this.getBreadcrumb();
    this.getDataTable();
    this.getAttachmentList();
    this.checkAttachmentState();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAttachmentList() {
    let sortType = 1;
    let sortField = 'DateCreated';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    
    this.store.dispatch(
      MemberDetailActions.getMemberAttachmentsAction({
        memberId: this.memberId,
        itemKey: this.itemKey,
        query: {
          rowId: this.data?.rowId,
          pageIndex: this.pageNumber,
          pageSize: this.pageSize,
          sortField,
          sortType,
        },
      })
    );
  }

  getDataTable() {
    this.store
      .pipe(
        select(fromMember.getMemberAttachmentsSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = !!state?.isLoading;
        if (state?.success && !state?.isLoading && state?.payload) {
          this.lengthPg = state?.total;
          this.dataTable = (state?.payload ?? []).map((data) =>{
            return {...data, dateCreated: getDateString(data.dateCreated)};
          });
        }
      });
  }

  checkAttachmentState() {
    this.store
      .pipe(
        select(fromMember.selectMemberMetadataAttachmentState),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.isLoading = !!el?.isLoading;
        let titleMsg = '';
        if (this.data.isNavigationPage && this.data.breadcrumbs) {
          titleMsg = this.data.breadcrumbs[0].name;
        } else {
          titleMsg = this.sectionData?.sectionTitle || '';
        }
        if ((el as any).hasOwnProperty('success') && el?.success) {
          this.store.dispatch(
            MemberDetailActions.resetMemberMetadataAttachmentState()
          );
          const message = el?.isCreate
            ? `${titleMsg} document`
            : el?.isEdit
            ? `Attachment`
            : el?.documentName;
          showBanner.call(
            this,
            BannerType.Success,
            message!,
            el?.isEdit
              ? ACTION.EDIT
              : el?.isRemove
              ? ACTION.REMOVE
              : ACTION.UPLOAD
          );

          if (
            el?.isRemove &&
            this.dataTable.length === 1 &&
            this.pageNumber > FIRST_PAGE
          ) {
            this.pageNumber = this.pageNumber - 1;
          }

          this.getAttachmentList();
        } else if ((el as any).hasOwnProperty('success') && !el?.success) {
          this.bannerType = BannerType.Fail;
          const message = el?.isCreate
            ? `document to ${titleMsg}`
            : el?.isEdit
            ? `Attachment`
            : el?.documentName;
          showBanner.call(
            this,
            BannerType.Fail,
            message!,
            el?.isEdit
              ? ACTION.EDIT
              : el?.isRemove
              ? ACTION.REMOVE
              : ACTION.UPLOAD
          );
          this.store.dispatch(
            MemberDetailActions.resetMemberMetadataAttachmentState()
          );
        }
      });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getAttachmentList();
  }

  pageChange(event: any) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageSize.toString()
    );
    this.getAttachmentList();
  }

  onClickBreadcrumb(event: any) {
    this.dialogRef.close();
  }

  addAttachment(attachment: any) {
    this.dialog.open(MemberAttachmentModalComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        itemKey: this.itemKey,
        attachment,
        rowId: this.data.rowId,
      },
    });
  }

  editAttachment(attachment: any) {
    this.dialog.open(MemberAttachmentModalComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        itemKey: this.itemKey,
        attachment,
      },
    });
  }

  removeAttachment(attachment: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove this ${this.data.menuName}: ${attachment.documentName}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          MemberDetailActions.removeMemberUploadAttachments({
            body: {
              attachmentId: attachment.id,
              itemKey: this.itemKey,
              memberId: this.memberId,
              documentName: attachment.documentName,
            },
          })
        );
      }
    });
  }

  openPDF(document: any) {
    const pdfUrl = this.signatoryService.getUrl(document, (fileKey) => {
      this.fileService.signFile(fileKey).subscribe((sig) => {
        this.openPDF({
          ...document,
          signature: sig,
        });
      });
    });

    if (pdfUrl) window.open(pdfUrl, '_blank');
  }

  getBreadcrumb() {
    this.listBreadcrumbs[0].url = `/member/detail/${this.memberId}`;
    this.listBreadcrumbs[1].name = this.sectionData?.name + ' - Attachments';
    if (this.data.breadcrumbs) {
      this.listBreadcrumbs = deepClone(this.data.breadcrumbs);
    }
  }
}
