<div class="header-add">
  Edit Property
</div>
<form class="edit-form" [formGroup]="editForm">
  <div class="property-config-info-container">
    <div class="property-config-container label-only mt-5">
      <span class="mt-23">Property Type: {{data.type}}</span>
      <span *ngIf="data?.config?.encrypted" class="mgt-16">Encrypted: {{data?.config?.encrypted === 'true' ? 'On' : 'Off'}}</span>
      <span *ngIf="data?.config?.unique" class="mgt-16">Unique: {{data?.config?.unique === 'true' ? 'On' : 'Off'}}</span>
      <span *ngIf="data?.type === PropertyTypeLabel.Lookup" class="mgt-16">Lookup Table: {{ currentLookupTable }}</span>
    </div>
    <ptg-textbox
      [controlField]="editForm.get('name')"
      [maxLength]="255"
      placeholder="Name"
      [hasLabel]="true"
      [isRequired]="true"
      [errorAsync]="editForm?.get('name')?.touched && editForm?.get('name')?.errors?.['inValidAsync'] && editForm?.get('name')?.errors?.message"
    ></ptg-textbox>
  </div>
  <div class="element-container">
    <div class="w-50">
      <div class="property-config-container">
        <ptg-toggle-button
          [controlField]="editForm.get('requiredCondition')"
          label="Required"
        ></ptg-toggle-button>
        <ptg-toggle-button
          [controlField]="editForm.get('initiatedCondition')"
          label="Initiated"
          (valueChange)="changeToggle($event, 'initiated')"
        ></ptg-toggle-button>
      </div>
      <div *ngIf="data?.type !== PropertyType.TYPE_RICH_TEXT" class="property-config-container">
        <ptg-toggle-button
          [controlField]="editForm.get('includeInBulkUpdate')"
          (valueChange)="changeToggle($event, 'includeInBulkUpdate')"
          label="Include in Bulk Update"
          [isIncludeChild]="true"
        ></ptg-toggle-button>
        <ng-container *ngIf="editForm.get('includeInBulkUpdate')?.value">
          <div class="input-container" [ngClass]="{'error-margin-bottom': editForm.get('importLabel')?.touched && editForm.get('importLabel')?.errors}">
            <ptg-textbox
              [controlField]="editForm.get('importLabel')"
              [maxLength]="255"
              [hasLabel]="true"
              placeholder="Import Label"
              [isRequired]="true"
              [errorAsync]="editForm?.get('importLabel')?.touched && editForm?.get('importLabel')?.errors?.['inValidAsync'] && editForm?.get('importLabel')?.errors?.message"
            ></ptg-textbox>
          </div>
        </ng-container>
      </div>
      <ng-container *ngFor="let elementName of elementNames; let isOdd = odd">
        <ng-container *ngIf="isOdd" [ngTemplateOutlet]="propertyConfig"
                      [ngTemplateOutletContext]="{elementName: elementName}"></ng-container>
      </ng-container>
    </div>
    <div class="w-50">
      <div [ngClass]="{'read-only': data?.type !== PropertyType.TYPE_RICH_TEXT}" class="property-config-container">
        <ptg-toggle-button
          [controlField]="editForm.get('readOnlyCondition')"
          (valueChange)="changeToggle($event, 'readOnly')"
          label="Read-Only"
        ></ptg-toggle-button>
      </div>
      <div *ngIf="showMasked" class="property-config-container only-masked">
        <ptg-toggle-button
          [controlField]="editForm.get('maskedCondition')"
          (valueChange)="changeToggle($event, 'masked')"
          label="Masked"
        ></ptg-toggle-button>
      </div>
      <ng-container *ngFor="let elementName of elementNames; let isEven = even">
        <ng-container *ngIf="isEven" [ngTemplateOutlet]="propertyConfig"
                      [ngTemplateOutletContext]="{elementName: elementName}"></ng-container>
      </ng-container>
    </div>
    <ng-template let-elementName="elementName" #propertyConfig>
      <ng-container [ngSwitch]="elementName">
        <ng-template ngSwitchCase="inclusiveInRange">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('inclusiveInRange')"
              (valueChange)="changeToggle($event, 'inclusiveInRange')"
              label="Inclusive in Range (min/max)"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <ng-container *ngIf="editForm.get('inclusiveInRange')?.value">
              <div class="input-container double-input">
                <ptg-textbox
                  [controlField]="editForm.get('minInput')"
                  [hasLabel]="true"
                  placeholder="Min"
                  (onBlur)="markMinMaxAsTouched()"
                  inputType="Number"
                  [customValidator] = "customValidators"
                  [showError]="false"
                ></ptg-textbox>
                <ptg-textbox
                  [controlField]="editForm.get('maxInput')"
                  [hasLabel]="true"
                  (onBlur)="markMinMaxAsTouched()"
                  placeholder="Max"
                  inputType="Number"
                  [customValidator] = "customValidators"
                  [showError]="false"
                ></ptg-textbox>
              </div>
              <div class="error-container">
                <mat-error class="error-text"
                           *ngIf="(editForm.get('minInput')?.errors?.minHigherThanMax || editForm.get('maxInput')?.errors?.minHigherThanMax)
                        && !(editForm.get('minInput')?.errors?.min || editForm.get('minInput')?.errors?.max || editForm.get('maxInput')?.errors?.min || editForm.get('maxInput')?.errors?.max)">
                  Max range must be higher than Min range.
                </mat-error>
                <mat-error class="error-text"
                           *ngIf="editForm.get('minInput')?.errors?.min || editForm.get('minInput')?.errors?.max || editForm.get('maxInput')?.errors?.min || editForm.get('maxInput')?.errors?.max">
                  Range must be within the range of -2147483647 - 2147483647.
                </mat-error>
                <mat-error class="error-text"
                           *ngIf="(editForm.get('minInput')?.errors?.required && editForm.get('minInput')?.touched) || (editForm.get('maxInput')?.errors?.required && editForm.get('maxInput')?.touched)">
                  Range is required.
                </mat-error>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="maximumLength">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('maximumLength')"
              (valueChange)="changeToggle($event, 'maximumLength')"
              label="Maximum Length"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <ng-container *ngIf="editForm.get('maximumLength')?.value">
              <div class="input-container">
                <ptg-textbox
                  [controlField]="editForm.get('maxLengthInput')"
                  [hasLabel]="true"
                  placeholder="Maximum Length"
                  [isDecimal]="false"
                  [isPositive]="true"
                  [allowZero]="false"
                  [isRequired]="true"
                  [min]="1"
                  [max]="2147483647"
                  inputType="Number"
                ></ptg-textbox>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="fractionalLength">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('fractionalLength')"
              (valueChange)="changeToggle($event, 'fractionalLength')"
              label="Fractional Length"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <ng-container *ngIf="editForm.get('fractionalLength')?.value">
              <div class="input-container">
                <ptg-textbox
                  [controlField]="editForm.get('fractionalLengthInput')"
                  [hasLabel]="true"
                  [isDecimal]="false"
                  [isPositive]="true"
                  placeholder="Fractional Length"
                  [isRequired]= "true"
                  [min]= "0"
                  [max]= "10"
                  inputType="Number"
                ></ptg-textbox>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="smsEnabled">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('smsEnabled')"
              (valueChange)="changeToggle($event)"
              label="SMS Enabled"
            ></ptg-toggle-button>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="prefixList">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('prefixList')"
              (valueChange)="changeToggle($event, 'prefixList')"
              label="Prefix List"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <div class="input-container">
              <ptg-select
                *ngIf="editForm.get('prefixList')?.value"
                placeholder="Lookup Table"
                [controlField]="editForm.get('lookupTable')"
                [listData]="lookupTableOptions"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
              ></ptg-select>
            </div>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="listValues">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('listValues')"
              (valueChange)="changeToggle($event, 'listValues')"
              label="Lookup Values"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <div class="input-container">
              <ptg-select
                *ngIf="editForm.get('listValues')?.value"
                placeholder="Lookup Table"
                [controlField]="editForm.get('lookupTable')"
                [listData]="lookupTableOptions"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                customError="lookupEnabledError"
              ></ptg-select>
            </div>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="boolean">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('boolean')"
              (valueChange)="changeToggle($event, 'boolean')"
              label="Display Values"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <ng-container *ngIf="editForm.get('boolean')?.value">
              <div class="input-container double-input">
                <ptg-textbox
                  placeholder="Affirmative"
                  [controlField]="editForm.get('affirmative')"
                  [hasLabel]="true"
                  [showError]="false"
                  (onBlur)="markDisplayValueAsTouched()"
                  errorRequire="Affirmative value is required."
                ></ptg-textbox>
                <ptg-textbox
                  placeholder="Negative"
                  [controlField]="editForm.get('negative')"
                  [hasLabel]="true"
                  [showError]="false"
                  (onBlur)="markDisplayValueAsTouched()"
                  errorRequire="Negative value is required."
                ></ptg-textbox>
              </div>
              <div class="error-container">
                <mat-error class="error-text"
                           *ngIf="editForm.get('affirmative')?.errors?.duplicateValue || editForm.get('negative')?.errors?.duplicateValue">
                  Affirmative and Negative are duplicated.
                </mat-error>
                <mat-error class="error-text"
                           *ngIf="(editForm.get('affirmative')?.errors?.required && editForm.get('affirmative')?.touched) || (editForm.get('negative')?.errors?.required && editForm.get('negative')?.touched)">
                  Display value is required.
                </mat-error>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="usedStateLookupTable">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('usedStateLookupTable')"
              (valueChange)="changeToggle($event, 'usedStateLookupTable')"
              label="State/Province/Territory"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <div class="input-container">
              <ptg-select
                *ngIf="editForm.get('usedStateLookupTable')?.value"
                placeholder="State/Province/Territory"
                [controlField]="editForm.get('stateLookupTable')"
                [listData]="lookupTableOptions"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
              ></ptg-select>
            </div>
          </div>
        </ng-template>
        <ng-template ngSwitchCase="usedCountryLookupTable">
          <div class="property-config-container">
            <ptg-toggle-button
              [controlField]="editForm.get('usedCountryLookupTable')"
              (valueChange)="changeToggle($event, 'usedCountryLookupTable')"
              label="Country"
              [isIncludeChild]="true"
            ></ptg-toggle-button>
            <div class="input-container">
              <ptg-select
                *ngIf="editForm.get('usedCountryLookupTable')?.value"
                placeholder="Country"
                [controlField]="editForm.get('countryLookupTable')"
                [listData]="lookupTableOptions"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
              ></ptg-select>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </div>

  <div class="row-button">
    <button mat-raised-button type="submit" class="primary" (click)="formSubmit$.next()">Save</button>
    <button mat-raised-button type="button" class="secondary" (click)="onNoClick()">Cancel</button>
  </div>
</form>


