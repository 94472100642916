import { FileSnapshot } from '@ptg-shared/models';

import { MemberListQuery } from './member-list';
import { MetadataSection } from './metadata.model';

export interface MemberDetail {
  demographics?: any;
  beneficiary?: any;
  benefit?: any;
  drop?: any;
  fiscal?: any;
  fiscalHistory?: any;
}

export interface AddSectionState {
  addSectionSuccess?: boolean;
  addToTop?: boolean;
  isTable?: boolean;
  metaDataPropertyValues?: any;
  sectionData: MetadataSection;
  saveSectionDataSuccess?: boolean;
  reloadSection: boolean;
  memberId: string;
  isEdit?: boolean;
  isRemove?: boolean;
  isUploadFile?: boolean;
  error?: any;
}

export interface EditMemberEventBody {
  memberId: string;
  statusId: string;
  eventId: string;
}

export interface MemberNavigationState {
  query?: MemberListQuery;
  totalPage: number;
  startPage: number;
  endPage: number;
  members: Record<string, unknown>[];
  memberIndex: number;
}

export interface MemberDetailWithPaging {
  pageIndex: number;
  totalPerPage: number;
  sortField?: string;
  sortType?: number;
  attachmentPageSize?: number;
  attachmentPageIndex?: number;
  attachmentSortName?: string;
  attachmentSortType?: number;
}

export interface MemberMetadataAttachmentState {
  success?: boolean;
  isCreate?: boolean;
  isEdit?: boolean;
  isRemove?: boolean;
  documentName?: string;
  isLoading?: boolean;
}

export interface MemberPropetyDataByMaskedConfigQuery {
  page?: string;
  memberId: string;
  itemKey: string;
  propertyKey: string;
  index: string;
  optionMasked: boolean;
}

export interface MemberPropetyDataByMaskedConfigResponse {
  value: string;
}

export interface GetMemberAttachmentsResponse {
  total?: number;
  items: any;
  success?: boolean;
  isLoading?: boolean;
}
export interface MemberAttachmentDetail {
  memberId: string;
  itemKey: string;
  rowId: string;
  documentName?: string;
  description: string;
  fileSnapshot?: FileSnapshot;
}
export interface MemberAttachmentsListSectionQuery {
  rowId?: string;
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortType?: number;
}
