import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService extends BaseComponent {
  currentFund$: BehaviorSubject<FundModel> = new BehaviorSubject<FundModel>({} as any);

  constructor(
    private store: Store<fromReducer.State>,
  ) {
    super();
    this.getCurrentFund();
  }

  get fundType() {
    return this.currentFund$.value.fundType;
  }

  getCurrentFund() {
    this.store.pipe(select(fromReducer.selectCurrentFundState))
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((currentFund) => {
        this.currentFund$.next(currentFund);
      });
  }
}
