<div class="list-display">
  <div class="list-header">
    <span class="title-name">{{ title }}</span>
    <div class="button-container" *ngIf="btnAddTitle">
      <div class="separator"></div>
      <div class="button-group">
        <ptg-button
          [buttonName]="btnAddTitle"
          classInput="edit-button"
          (clickButton)="onClickAddItem()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
    </div>
    <div class="button-container" *ngIf="btnGenerate">
      <div class="button-group">
        <ptg-button
          [buttonName]="btnGenerate"
          classInput="generate-button"
          (clickButton)="onClickGenerateRecord()"
        >
          <mat-icon
            svgIcon="generate-icon-outline"
            aria-hidden="false"
            aria-label="generate-icon-outline"
          ></mat-icon>
        </ptg-button>
      </div>
    </div>
  </div>

  <div class="grid-wrapper">
    <ptg-grid
      [data]="dataTable"
      [columns]="columns"
      [isLoading]="isLoading"
      [totalRecords]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      (pageChange)="onChangePage($event)"
      (sortChange)="onSortChange($event)"
      (rowClick)="onSelectRow($event)"
      [(currentRowIndex)]="currentRowIndex"
      [fitToParent]="true"
      [paginable]="paginable"
      notFoundMessage="No {{ pageName }} to Display"
    >
      <ng-template cellContent columnName="statusName" let-row>
        <div class="icon-status-container">
          <mat-icon
            *ngIf="row?._typedValue?.iconName"
            class="icon-status"
            [ngStyle]="{ color: row?._typedValue?.color }"
            >{{ row?._typedValue?.iconName }}</mat-icon
          >
          <div class="status-name">{{ row.statusName }}</div>
        </div>
      </ng-template>
    </ptg-grid>
  </div>
</div>
