export enum BannerType {
  Success = 'Success',
  Fail = 'Fail',
  Warning = 'Warning',
  Info = 'Info',
  Hidden = '',
}

export interface BannerIconConfig {
  icon: string;
  className?: string;
}
