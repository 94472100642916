<div class="header">
  <ng-container>
    <div class="icon-div"><mat-icon class="svg-icon" [svgIcon]="icon"></mat-icon></div>
    <span class="title">{{ title }}</span>
  </ng-container>
</div>

<div class="content">
  <ng-container *ngIf="data.type === ConfirmType.Error"><mat-icon svgIcon="dengerous"></mat-icon></ng-container>
  <p [innerHTML]="data.text" [ngClass]="{'no-truncate': data.noTruncate}"></p>
  <div>
    <ptg-checkbox
      *ngIf="data.haveDontShowAgainCheckbox"
      [controlField]="displayCheckBox"
      label="Do not show this again"
  ></ptg-checkbox>
  </div>

</div>

<div class="footer">
  <button mat-stroked-button *ngIf="data.type !== ConfirmType.Error && data.type !== ConfirmType.Confirm && data.type !== ConfirmType.ConfirmWithDontShowAgain && !data?.hideConfirmButton" [ngClass]="{'btn-destruct':data.type === ConfirmType.Destruct,'btn-delete':data.type === ConfirmType.Delete}" [mat-dialog-close]="data.confirm">Yes</button>
  <button mat-raised-button type="button" class="primary" *ngIf="data.type === ConfirmType.ConfirmSave" [mat-dialog-close]="data.confirm"> {{ saveButtonTitle }}</button>
  <button mat-stroked-button type="button" class="secondary" *ngIf="data.type === ConfirmType.ConfirmSave && !data.hideSaveAsButton" (click)="onSaveAsClick()">Save As</button>
  <button mat-stroked-button *ngIf="data.type === ConfirmType.ConfirmWithDontShowAgain" (click)="onConfirmWithDontShowAgain()">Yes</button>
  <button mat-stroked-button (click)="onNoClick()">{{ cancelButtonTitle }}</button>
</div>

<button mat-icon-button *ngIf="data.type === ConfirmType.Error" aria-label="close icon" (click)="onNoClick()" class="x-button">
  <mat-icon>close</mat-icon>
</button>
