import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FIRST_PAGE } from '@ptg-shared/controls/pagination';

import { MetadataService } from '../../member/services/metadata.service';
import { MemberDetailActions } from '../actions';
import {
  MemberDetail,
  MemberDetailWithPaging,
  MetadataSection,
  MetadataSectionsNonListResponse,
} from '../types/models';
import { MemberDetailService } from '../services/member-detail.service';
import { StatusHistoryService } from '../services/status-history.service';

@Injectable()
export class MemberDetailEffects {
  getMemberDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDetail),
      switchMap(({ id, listSection, menuId, pageSize }) => {
        return this.memberDetailService
          .memberDetail(id, listSection, true, false, menuId, pageSize)
          .pipe(
            map((memberDetail: MemberDetail) => {
              return MemberDetailActions.getMemberDetailSuccess({
                memberDetail,
              });
            }),
            catchError((err) => {
              console.log('---------Error-----------------');
              console.log(err);
              return of(
                MemberDetailActions.getMemberDetailFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  lockMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.lockMember),
      switchMap(({ id }) => {
        return this.memberDetailService.lockMember(id).pipe(
          map(() => {
            return MemberDetailActions.lockMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.lockMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  unlockMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.unlockMember),
      switchMap(({ id }) => {
        return this.memberDetailService.unlockMember(id).pipe(
          map(() => {
            return MemberDetailActions.unlockMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.unlockMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMember),
      switchMap(({ id }) => {
        return this.memberDetailService.removeMember(id).pipe(
          map(() => {
            return MemberDetailActions.removeMemberSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.removeMemberFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMemberSection),
      switchMap(({ id, sectionKey }) => {
        return this.memberDetailService.removeSection(id, sectionKey).pipe(
          map(() => {
            return MemberDetailActions.removeMemberSectionSuccess({
              sectionKey: sectionKey,
            });
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberDetailActions.removeMemberSectionFailure({
                errorMsg: err.message,
                sectionKey: sectionKey,
              })
            );
          })
        );
      })
    )
  );

  createMemberSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.addSectionTable),
      switchMap(({ memberId, metadataItem, body, sectionData }) => {
        return this.memberDetailService
          .addSection(memberId, metadataItem, body)
          .pipe(
            map(() => {
              return MemberDetailActions.addSectionTableSuccess({
                sectionData,
                memberId,
              });
            }),
            catchError((error) => {
              return of(MemberDetailActions.addSectionTableFailure({ error }));
            })
          );
      })
    )
  );

  getSectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getSectionData),
      switchMap(({ memberId, sectionKey, query }) => {
        return this.memberDetailService
          .getSection(memberId, sectionKey, query)
          .pipe(
            map((metaDataPropertyValues) => {
              return MemberDetailActions.getSectionDataSuccess({
                memberId,
                sectionKey,
                metaDataPropertyValues,
              });
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.getSectionDataFailure({ error, sectionKey })
              );
            })
          );
      })
    )
  );

  addSectionDataListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.addSectionDataListView),
      switchMap(({ body, sectionData }) => {
        return this.memberDetailService.addSectionDataListView(body).pipe(
          map(() => {
            return MemberDetailActions.addSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.addSectionDataListViewFailure({ sectionData })
            );
          })
        );
      })
    )
  );

  addSectionDataFixedStatusSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.addSectionDataFixedStatusSection),
      switchMap(({ body, sectionData }) => {
        return this.statusHistoryService.createMemberStatus(body).pipe(
          map(() => {
            return MemberDetailActions.addSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.addSectionDataListViewFailure({ sectionData })
            );
          })
        );
      })
    )
  );

  reorderSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.reorderSectionTable),
      switchMap(({ memberId, metadataItem, body }) => {
        return this.memberDetailService
          .reorderSection(memberId, metadataItem, body)
          .pipe(
            map(() => {
              return MemberDetailActions.reorderSectionTableSuccess();
            }),
            catchError((error) => {
              return of(
                MemberDetailActions.reorderSectionTableFailure({ error })
              );
            })
          );
      })
    )
  );

  editSectionDataListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.editSectionDataListView),
      switchMap(({ body, sectionData }) => {
        return this.memberDetailService.editSectionDataListView(body).pipe(
          map(() => {
            return MemberDetailActions.editSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.editSectionDataListViewFailure({
                error,
                sectionData,
              })
            );
          })
        );
      })
    )
  );

  editSectionDataFixedStatusSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.editSectionDataFixedStatusSection),
      switchMap(({ body, sectionData }) => {
        return this.statusHistoryService.editMemberStatus(body).pipe(
          map(() => {
            return MemberDetailActions.editSectionDataListViewSuccess({
              sectionData,
            });
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.editSectionDataListViewFailure({
                error,
                sectionData,
              })
            );
          })
        );
      })
    )
  );

  getSectionMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MemberDetailActions.getSectionOverview),
      switchMap(({ memberId, sectionKey, isMetadataSection }) => {
        return forkJoin([
          this.memberDetailService.getSection(
            memberId,
            sectionKey,
            !isMetadataSection
              ? ({
                  pageIndex: FIRST_PAGE,
                  totalPerPage: 10,
                } as MemberDetailWithPaging)
              : undefined
          ),
          this.metadataService.getMetadataSections(),
          this.metadataService.getMetadataItemByProfileOverviewConfig(memberId),
        ]).pipe(
          map((response) => {
            const sectionOverview = {
              metaDataPropertyValues: response[0],
              sectionData: isMetadataSection
                ? (response[1]?.metadata?.find(
                    (item) => item.key === sectionKey
                  ) as MetadataSection)
                : (response[2]?.metadataItems?.find(
                    (item) => item.key === sectionKey
                  ) as MetadataSection),
            };
            return MemberDetailActions.getSectionOverviewSuccess({
              sectionOverview,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.getSectionOverviewFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    );
  });

  editMemberSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.editSectionTable),
      switchMap(({ memberId, metadataItem, body, sectionData }) => {
        return this.memberDetailService
          .editSectionTableView(memberId, metadataItem, body)
          .pipe(
            map(() => {
              return MemberDetailActions.editSectionTableSuccess({
                sectionData,
                memberId,
              });
            }),
            catchError((error) => {
              return of(MemberDetailActions.editSectionTableFailure({ error }));
            })
          );
      })
    )
  );

  removeMemberSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeSectionTable),
      switchMap(({ sectionData, body }) => {
        return this.memberDetailService.removeSectionTable(body).pipe(
          map(() => {
            return MemberDetailActions.removeSectionTableSuccess({
              sectionData,
            });
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.removeSectionTableFailure({
                error,
                sectionData,
              })
            );
          })
        );
      })
    )
  );

  uploadFileSectionTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.uploadFileSectionTable),
      switchMap(({ memberId, body }) => {
        return this.memberDetailService.uploadFileSection(memberId, body).pipe(
          map(() => {
            return MemberDetailActions.uploadFileSectionTableSuccess();
          }),
          catchError((error) => {
            return of(
              MemberDetailActions.uploadFileSectionTableFailure({ error })
            );
          })
        );
      })
    )
  );

  getMetadataSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMetadataSectionsAction),
      switchMap(() =>
        this.metadataService.getMetadataSections().pipe(
          map((response) =>
            MemberDetailActions.getMetadataSectionsSuccessAction({
              metadata: response.metadata,
              listStatus: response.listStatus,
            })
          ),
          catchError((error) =>
            of(MemberDetailActions.getMetadataSectionsFailureAction({ error }))
          )
        )
      )
    )
  );

  getMetadataNonList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMetadataNonListSectionsAction),
      switchMap(({ memberNavigationItemId }) =>
        this.metadataService.getMetadataNonList(memberNavigationItemId).pipe(
          map((response: MetadataSectionsNonListResponse) =>
            MemberDetailActions.getMetadataNonListSectionsSuccessAction({
              response,
              memberNavigationItemId,
            })
          ),
          catchError((error) =>
            of(
              MemberDetailActions.getMetadataNonListSectionsFailureAction({
                error,
              })
            )
          )
        )
      )
    )
  );

  getMemberById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberById),
      switchMap(({ id }) => {
        return this.memberDetailService.getMemberDetail(id).pipe(
          map((res: any) => {
            const memberInfo = {
              memberInfo: {
                ...res?.member,
              },
            };
            return MemberDetailActions.getMemberByIdSuccess(memberInfo);
          }),
          catchError(() => {
            return of(MemberDetailActions.getMemberByIdFailure());
          })
        );
      })
    )
  );

  editMemberEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.setMemberEvent),
      switchMap(({ body }) => {
        return this.memberDetailService.editMemberEvent(body).pipe(
          map(() => {
            return MemberDetailActions.setMemberEventSuccess();
          }),
          catchError((err) => {
            return of(MemberDetailActions.setMemberEventFailure());
          })
        );
      })
    )
  );

  getMemberDetailWithPaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDetailWithPagingAction),
      switchMap(({ id, itemKey, query, menuId }) =>
        this.memberDetailService
          .memberDetailWithPaging(
            id,
            itemKey,
            query as MemberDetailWithPaging,
            menuId
          )
          .pipe(
            map((response) =>
              MemberDetailActions.getMemberDetailWithPagingSuccessAction({
                response,
              })
            ),
            catchError((error) =>
              of(
                MemberDetailActions.getMemberDetailWithPagingFailureAction({
                  error,
                })
              )
            )
          )
      )
    )
  );

  createMemberUploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.createMemberUploadAttachments),
      switchMap(({ body }) => {
        return this.memberDetailService.uploadMetaDataAttachment(body).pipe(
          map(() => {
            return MemberDetailActions.createMemberUploadAttachmentsSuccess();
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.createMemberUploadAttachmentsFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  updateMemberUploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.updateMemberUploadAttachments),
      switchMap(({ body }) => {
        return this.memberDetailService.updateMetaDataAttachment(body).pipe(
          map(() => {
            return MemberDetailActions.updateMemberUploadAttachmentsSuccess();
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.updateMemberUploadAttachmentsFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  removeMemberUploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.removeMemberUploadAttachments),
      switchMap(({ body }) => {
        return this.memberDetailService.removeMetaDataAttachment(body).pipe(
          map(() => {
            return MemberDetailActions.removeMemberUploadAttachmentsSuccess({
              body,
            });
          }),
          catchError((err) => {
            return of(
              MemberDetailActions.removeMemberUploadAttachmentsFailure({
                errorMsg: err.message,
                documentName: body.documentName,
              })
            );
          })
        );
      })
    )
  );

  getMetadataBasedOnProfileOverviewConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMetadataBasedOnProfileOverviewConfig),
      switchMap(({ memberId }) => {
        return this.metadataService
          .getMetadataItemByProfileOverviewConfig(memberId)
          .pipe(
            map((res) => {
              return MemberDetailActions.getMetadataBasedOnProfileOverviewConfigSuccess(
                {
                  metadataOverview: res.metadataItems,
                  listStatus: res.listStatus,
                  profileOverviewName: res.profileOverviewName,
                  profileOverviewConfig: res.metadataOverview,
                }
              );
            }),
            catchError(() => {
              return of(
                MemberDetailActions.getMetadataBasedOnProfileOverviewConfigFailure()
              );
            })
          );
      })
    )
  );

  getMemberDetailUnMaskedRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberDetailUnMaskedRequest),
      switchMap(({ memberId, listSection, isAllData, menuId }) => {
        return this.memberDetailService
          .memberDetail(memberId, listSection, false, isAllData, menuId)
          .pipe(
            map((memberDetailUnMasked: MemberDetail) => {
              return MemberDetailActions.getMemberDetailUnMaskedSuccess({
                memberDetailUnMasked,
              });
            }),
            catchError((err) => {
              console.log('---------Error-----------------');
              console.log(err);
              return of(MemberDetailActions.getMemberDetailUnMaskedFailure());
            })
          );
      })
    )
  );

  getMemberConfiguredIdName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberConfiguredIdNameRequest),
      switchMap(() => {
        return this.memberDetailService.getMemberConfiguredIdName().pipe(
          map((payrollConfigurationInfo) => {
            return MemberDetailActions.getMemberConfiguredIdNameSuccess({
              payrollConfigurationInfo,
            });
          }),
          catchError((err) => {
            return of(MemberDetailActions.getMemberConfiguredIdNameFailure());
          })
        );
      })
    )
  );

  getMemberAttachmentsListSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberDetailActions.getMemberAttachmentsAction),
      switchMap(({ memberId, itemKey, query }) => {
        return this.memberDetailService
          .getMemberAttachmentsListSection(memberId, itemKey, query)
          .pipe(
            map((response) =>
              MemberDetailActions.getMemberAttachmentsSuccessAction({
                response,
              })
            ),
            catchError((error) =>
              of(
                MemberDetailActions.getMemberAttachmentsFailureAction({
                  error,
                })
              )
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private memberDetailService: MemberDetailService,
    private metadataService: MetadataService,
    private statusHistoryService: StatusHistoryService
  ) {}
}
