import { Action, createReducer, on } from '@ngrx/store';
import { setHistory } from '@ptg-shared/layout/actions/layout.actions';
import { DEFAULT_TAB } from '@ptg-shared/layout/components/captionbar/types/caption-bar.const';
import { Tab } from '@ptg-shared/layout/components/captionbar/types/caption-bar.model';
import {
  DisplayedTabName,
  HiddenTabName,
  SidebarOpenedStatus
} from '@ptg-shared/layout/constance/layout.const';
import { DEFAULT_TAB_MENU_GROUPS } from '@ptg-shared/layout/constance/menu.const';
import { deepClone, deepFlattenArray } from '@ptg-shared/utils/common.util';
import { MenuGroup, TabName, TabsUrl } from 'src/app/shared/layout/models/layout.model';
import { LayoutActions } from '../actions';

export const SideMenuFeature = 'sideMenu';

export interface MainLayoutHistory {
  urlBeforeSearch?: string;
}

export interface State {
  sidebarOpenedStatus: SidebarOpenedStatus,
  tab: TabName;
  url: string;
  menuGroups?: MenuGroup[];
  allMenuGroups: Partial<Record<DisplayedTabName, MenuGroup[]>>;
  isCloseTab?: boolean;
  isChangePage?: boolean;
  tabs: Tab[];
  history: MainLayoutHistory;
}

const initialState: State = {
  sidebarOpenedStatus: SidebarOpenedStatus.Opened,
  url: '',
  tab: HiddenTabName.Hidden,
  tabs: [],
  allMenuGroups: deepClone(DEFAULT_TAB_MENU_GROUPS),
  history: {
    urlBeforeSearch: ''
  },
};

export const sideMenuReducer = createReducer(
  initialState,
  on(LayoutActions.closeSideMenu, (state): State => ({
    ...state,
    sidebarOpenedStatus: SidebarOpenedStatus.Closed
  })),
  on(LayoutActions.openSideMenu, (state): State => ({
    ...state,
    sidebarOpenedStatus: SidebarOpenedStatus.Opened
  })),
  on(LayoutActions.hiddenSideMenu, (state): State => ({
    ...state,
    sidebarOpenedStatus: SidebarOpenedStatus.Hidden
  })),
  on(LayoutActions.setSidebarOpenedStatus, (state, { sidebarOpenedStatus }): State => ({
    ...state,
    sidebarOpenedStatus
  })),
  on(LayoutActions.selectTab, (state, { tab, url }): State => {
    let tabs = deepClone(state.tabs) as Tab[];
    tabs = tabs.map((item) => {
      item.isSelected = item.tabName === tab;
      if (item.isSelected && url) {
        item.url = url;
      }
      if (item.children) {
        item.children = item.children.map(child => {
          child.isSelected = child.tabName === tab;
          if (child.isSelected && url) {
            child.url = url;
          }
          return child;
        })
      }
      return item;
    });
    return {
      ...state,
      tabs,
      url: url || state.url
    }
  }),
  on(LayoutActions.setTabUrl, (state, { tab, url }): State => {
    let tabs = deepClone(state.tabs);
    tabs = tabs.map((item) => {
      if (item.tabName === tab && url) {
        item.url = url;
      }
      if (item.children) {
        item.children = item.children.map(child => {
          if (child.tabName === tab && url) {
            child.url = url;
          }
          return child;
        })
      }
      return item;
    });
    return {
      ...state,
      tabs,
      url: url || state.url
    }
  }),
  on(LayoutActions.hideAllTabs, (state): State => ({
    ...state,
    tab: HiddenTabName.Hidden
  })),
  on(LayoutActions.setTabHiddenState, (state, { tabName, isHidden }): State => {
    let tabs = deepClone(state.tabs);
    tabs = tabs.map((item) => {
      if (item.tabName === tabName) {
        item.isHidden = isHidden;
      }
      if (item.children) {
        item.children = item.children.map(child => {
          if (child.tabName === tabName) {
            child.isHidden = isHidden;
          }
          return child;
        })
      }
      return item;
    });
    return {
      ...state,
      tabs
    }
  }),
  on(LayoutActions.closeTab, (state, { tabName }): State => {
    let tabs = deepClone(state.tabs);
    const closedTabName = tabName || (deepFlattenArray(tabs) as Tab[]).find(item => item.isSelected)?.tabName;
    tabs = tabs.map((tab) => {
      if (tab.tabName === closedTabName) {
        tab.url = '';
      }
      if (tab.children) {
        tab.children = tab.children.map(child => {
          if (child.tabName === closedTabName) {
            child.url = '';
          }
          return child;
        })
      }
      return tab;
    });
    return {
      ...state,
      tabs,
    }
  }),
  on(LayoutActions.changePage, (state): State => ({
    ...state,
    isChangePage: true
  })),
  on(LayoutActions.changePageSuccess, (state): State => ({
    ...state,
    isChangePage: false
  })),
  on(LayoutActions.setAllMenuGroups, (state, { allMenuGroups }): State => ({
    ...state,
    allMenuGroups
  })),
  on(LayoutActions.resetAllMenuGroups, (state): State => ({
    ...state,
    allMenuGroups: deepClone(DEFAULT_TAB_MENU_GROUPS),
  })),
  on(LayoutActions.setHistory, (state, { history }): State => ({
    ...state,
    history: {
      ...state.history,
      ...history
    }
  })),
  on(LayoutActions.setTabs, (state, { tabs }): State => {
    return {
      ...state,
      tabs,
    }
  }),
);

export function reducer(state: State | undefined, action: Action): State {
  return sideMenuReducer(state, action);
}
