import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/models';

import {
  AggregationGroup,
  AggregationGroupDetails,
} from '../types/models/aggregation-group.model';
import { AggregationGroupActions } from '../actions';

export interface AggregationGroupState {
  isLoading: boolean;
  aggregationGroup: AggregationGroup[];
  getAggregationGroupDetails?: BaseActionState<AggregationGroupDetails[]>;
  removeState: string;
  createState: string;
  updateState: string;
  error?: any;
}

const initialState: AggregationGroupState = {
  isLoading: true,
  aggregationGroup: [],
  removeState: '',
  createState: '',
  updateState: '',
  error: undefined,
};

export const aggregationGroupReducer = createReducer(
  initialState,
  on(AggregationGroupActions.getAggregationGroupList, (state, {}) => ({
    ...state,
    isLoading: true,
    aggregationGroup: [],
    error: undefined,
  })),
  on(
    AggregationGroupActions.getAggregationGroupSuccess,
    (state, { aggregationGroup }) => ({
      ...state,
      isLoading: false,
      aggregationGroup,
      error: undefined,
    })
  ),
  on(
    AggregationGroupActions.getAggregationGroupListFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      aggregationGroup: [],
      error: error,
    })
  ),
  on(AggregationGroupActions.clearAggregationGroup, (state, {}) => ({
    ...initialState,
    aggregationGroup: [],
    removeState: '',
    createState: '',
    updateState: '',
  })),
  on(AggregationGroupActions.addAggregationGroupSuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(
    AggregationGroupActions.addAggregationGroupFailure,
    (state, { errorMsg }) => ({
      ...state,
      createState: STATE.FAIL,
    })
  ),
  on(AggregationGroupActions.updateAggregationGroupSuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(
    AggregationGroupActions.updateAggregationGroupFailure,
    (state, { errorMsg }) => ({
      ...state,
      updateState: STATE.FAIL,
    })
  ),
  on(AggregationGroupActions.removeAggregationGroupSuccess, (state) => ({
    ...state,
    removeState: STATE.SUCCESS,
  })),
  on(AggregationGroupActions.removeAggregationGroupFailure, (state) => ({
    ...state,
    removeState: STATE.FAIL,
  })),
  on(AggregationGroupActions.getAggregationGroupDetailsAction, (state, {}) => ({
    ...state,
    getAggregationGroupDetails: {
      isLoading: true,
    },
  })),
  on(
    AggregationGroupActions.getAggregationGroupDetailsSuccessAction,
    (state, { response }) => ({
      ...state,
      getAggregationGroupDetails: {
        isLoading: false,
        success: true,
        payload: response.aggregationGroups,
      },
    })
  ),
  on(
    AggregationGroupActions.getAggregationGroupDetailsFailureAction,
    (state, { error }) => ({
      ...state,
      getAggregationGroupDetails: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  )
);
