import { createAction, props } from '@ngrx/store';
import {
  FilterByPropertyConfig,
  FilterInformation,
  RegisterFilter,
  RegisterFilterConfig,
  RegisterFilterName,
  SummaryList,
} from '../types/models';

export const GetSummaryListRequest = '[GetSummaryList/API] Send Request';
export const GetSummaryListSuccess = '[GetSummaryList/API] Success';
export const GetSummaryListFailure = '[GetSummaryList/API] Failure';
export const GetRegisterFilterByPropertyListRequest =
  '[GetRegisterFilterByPropertyList/API] Send Request';
export const GetRegisterFilterByPropertyListSuccess =
  '[GetRegisterFilterByPropertyList/API] Success';
export const GetRegisterFilterByPropertyListFailure =
  '[GetRegisterFilterByPropertyList/API] Failure';
export const SetRegisterFilters = '[SetRegisterFilters] Set';
export const CreateRegisterFilterRequest =
  '[CreateRegisterFilter/API] Send Request';
export const CreateRegisterFilterSuccess = '[CreateRegisterFilter/API] Success';
export const CreateRegisterFilterFailure = '[CreateRegisterFilter/API] Failure';
export const GetRegisterFilterRequest = '[GetRegisterFilter/API] Send Request';
export const GetRegisterFilterSuccess = '[GetRegisterFilter/API] Success';
export const GetRegisterFilterFailure = '[GetRegisterFilter/API] Failure';
export const UpdateFilterNameRequest =
  '[UpdateRegisterFilterName/API] Send Request';
export const UpdateFilterNameSuccess = '[UpdateRegisterFilterName/API] Success';
export const UpdateFilterNameFailure = '[UpdateRegisterFilterName/API] Failure';
export const RemoveRegisterFilterRequest =
  '[RemoveRegisterFilter/API] Send Request';
export const RemoveRegisterFilterSuccess = '[RemoveRegisterFilter/API] Success';
export const RemoveRegisterFilterFailure = '[RemoveRegisterFilter/API] Failure';
export const RegisterClear = '[RegisterClear]';

export const CreateNewAdjustmentRequest =
  '[CreateNewAdjustment/API] Send Request';
export const CreateNewAdjustmentSuccess = '[CreateNewAdjustment/API] Success';
export const CreateNewAdjustmentFailure = '[CreateNewAdjustment/API] Failure';

export const ReissueTransactionRegisterRequest =
  '[ReissueTransactionRegister/API] Send Request';
export const ReissueTransactionRegisterSuccess =
  '[ReissueTransactionRegister/API] Success';
export const ReissueTransactionRegisterFailure =
  '[ReissueTransactionRegister/API] Failure';

export const ExportTransactionRegisterRequest = '[ExportTransactionRegisterRequest/API] Send Request';
export const ExportTransactionRegisterSuccess = '[ExportTransactionRegisterSuccess/API] Success';
export const ExportTransactionRegisterFailure = '[ExportTransactionRegisterFailure/API] Failure';

export const DownloadransactionRegisterRequest = '[DownloadransactionRegisterRequest/API] Send Request';
export const DownloadTransactionRegisterSuccess = '[DownloadTransactionRegisterSuccess/API] Success';
export const DownloadTransactionRegisterFailure = '[DownloadTransactionRegisterFailure/API] Failure';


export const getSummaryList = createAction(
  GetSummaryListRequest,
  props<{ query: any }>()
);
export const getSummaryListSuccess = createAction(
  GetSummaryListSuccess,
  props<{ summaryList: SummaryList }>()
);
export const getSummaryListFailure = createAction(
  GetSummaryListFailure,
  props<{ error?: any }>()
);
export const getFilterByPropertyListRequest = createAction(
  GetRegisterFilterByPropertyListRequest
);
export const getFilterByPropertyListSuccess = createAction(
  GetRegisterFilterByPropertyListSuccess,
  props<{ listFilterProperty: FilterByPropertyConfig[] }>()
);
export const getFilterByPropertyListFailure = createAction(
  GetRegisterFilterByPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const setRegisterFilter = createAction(
  SetRegisterFilters,
  props<{ filters: RegisterFilter[] }>()
);
export const createRegisterFilterRequest = createAction(
  CreateRegisterFilterRequest,
  props<{ body: RegisterFilterConfig }>()
);
export const createRegisterFilterSuccess = createAction(
  CreateRegisterFilterSuccess
);
export const createRegisterFilterFailure = createAction(
  CreateRegisterFilterFailure
);
export const getRegisterFilterRequest = createAction(GetRegisterFilterRequest);
export const getRegisterFilterSuccess = createAction(
  GetRegisterFilterSuccess,
  props<{ listFilterInfo: FilterInformation[] }>()
);
export const getRegisterFilterFailure = createAction(GetRegisterFilterFailure);
export const updateFilterNameRequest = createAction(
  UpdateFilterNameRequest,
  props<{ registerFilterName: RegisterFilterName }>()
);
export const updateFilterNameSuccess = createAction(
  UpdateFilterNameSuccess,
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const updateFilterNameFailure = createAction(
  UpdateFilterNameFailure,
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const removeRegisterFilterRequest = createAction(
  RemoveRegisterFilterRequest,
  props<{ id: string }>()
);
export const removeRegisterFilterSuccess = createAction(
  RemoveRegisterFilterSuccess,
  props<{ id: string }>()
);
export const removeRegisterFilterFailure = createAction(
  RemoveRegisterFilterFailure
);

export const registerClear = createAction(RegisterClear);

export const reissueTransactionRegister = createAction(
  ReissueTransactionRegisterRequest,
  props<{ body: any }>()
);
export const reissueTransactionRegisterSuccess = createAction(
  ReissueTransactionRegisterSuccess,
  props<{ paymentType: number; isReissue: boolean; status: number }>()
);
export const reissueTransactionRegisterFailure = createAction(
  ReissueTransactionRegisterFailure,
  props<{
    errorMsg: string;
    paymentType: number;
    isReissue: boolean;
    status: number;
  }>()
);

export const createNewAdjustment = createAction(
  CreateNewAdjustmentRequest,
  props<{ body: any }>()
);
export const createNewAdjustmentSuccess = createAction(
  CreateNewAdjustmentSuccess
);
export const createNewAdjustmentFailure = createAction(
  CreateNewAdjustmentFailure,
  props<{ errorMsg: string }>()
)

export const exportTransactionRegisterRequest = createAction(
  ExportTransactionRegisterRequest,
  props<{ request: any }>()
);
export const exportTransactionRegisterSuccess = createAction(
  ExportTransactionRegisterSuccess,
  props<{ data: any }>()
);
export const exportTransactionRegisterFailure = createAction(
  ExportTransactionRegisterFailure
);

export const downloadTransactionRegisterRequest = createAction(
  DownloadransactionRegisterRequest,
  props<{ fileName: string }>()
);
export const downloadTransactionRegisterSuccess = createAction(
  DownloadTransactionRegisterSuccess
);
export const downloadTransactionRegisterFailure = createAction(
  DownloadTransactionRegisterFailure
);
