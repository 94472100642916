<div class="opt-in-message" #optInMessage>
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <ptg-overview-header #overViewHeader ></ptg-overview-header>
  <div class="opt-in-option" *ngIf="!isLoading">
      <div class="title">
          <div class="title-toggle">
              <ptg-toggle-button
                  [controlField]="formGroup.get('isPaperLess')"
                  label="Paperless"
                  [disabled]="disabledPaperLess"
                  (valueChange)="onChangePaperlessToggle()">
              </ptg-toggle-button>
          </div>
          <div class="flex" *ngIf="!editNotePaperless">
            <div class="title-name" *ngIf="formGroup.get('paperLessUpdatedBy')?.value && !formGroup.get('isPaperLess')?.dirty">
              <span class="name">
                  Last Updated By:
              </span>
              <span class="value">
                 {{ formGroup.get('paperLessUpdatedBy')?.value }}
              </span>
          </div>
          <div class="title-date" *ngIf="formGroup.get('paperLessUpdatedDate')?.value && !formGroup.get('isPaperLess')?.dirty">
              <span class="name">
                  At:
              </span>
              <span class="value">
                  {{ formGroup.get('paperLessUpdatedDate')?.value  | dateFormat:false }}
              </span>
          </div>
          </div>

      </div>
      <div class="message" *ngIf="formGroup.get('isPaperLess')?.value && formGroup.get('paperLessUpdatedDate')?.value && formGroup.get('paperLessContent')?.value && !editNotePaperless">
          <h5 class="message-title">Compliance Message</h5>
          <span class="message-content">{{ formGroup.get('paperLessContent')?.value }}</span>
      </div>
      <div class="note-input" *ngIf="editNotePaperless">
        <ptg-textbox
          [controlField]="formGroup.get('notePaperless')"
          [hasLabel]="true"
          placeholder="Note"
        ></ptg-textbox>
        <div class="wrap-btn">
          <button mat-raised-button type="button" class="btn-save" (click)="onSubmitPaperless()">Save</button>
          <button mat-raised-button type="button" class="btn-cancel" (click)="onCancelPaperless()">Cancel</button>
        </div>
      </div>
      <div class="message" *ngIf="formGroup.get('notePaperless')?.value && !editNotePaperless" >
        <h5 class="message-title">Note</h5>
        <span class="message-content">{{ formGroup.get('notePaperless')?.value }}</span>
      </div>
      <div class="title">
          <div class="title-toggle">
              <ptg-toggle-button
                  [controlField]="formGroup.get('isSMS')"
                  label="SMS"
                  [disabled]="disabledSMS"
                  (valueChange)="onChangeSMSToggle()">
              </ptg-toggle-button>
          </div>
          <div class="flex" *ngIf="!editNoteSMS">
            <div class="title-name" *ngIf="formGroup.get('smsUpdatedBy')?.value && !formGroup.get('isSMS')?.dirty">
              <span class="name">
                  Last Updated By:
              </span>
              <span class="value">
                  {{ formGroup.get('smsUpdatedBy')?.value }}
              </span>
          </div>
          <div class="title-date" *ngIf="formGroup.get('smsUpdatedDate')?.value && !formGroup.get('isSMS')?.dirty">
              <span class="name">
                  At:
              </span>
              <span class="value">
                  {{ formGroup.get('smsUpdatedDate')?.value  | dateFormat:false }}
              </span>
          </div>
          </div>

      </div>
      <div class="message" *ngIf="formGroup.get('isSMS')?.value && formGroup.get('smsUpdatedDate')?.value && formGroup.get('smsContent')?.value && !editNoteSMS">
          <h5 class="message-title">Compliance Message</h5>
          <span class="message-content">{{ formGroup.get('smsContent')?.value }}</span>
      </div>
      <div class="note-input" *ngIf="editNoteSMS">
        <ptg-textbox
          [controlField]="formGroup.get('noteSMS')"
          [hasLabel]="true"
          placeholder="Note"
        ></ptg-textbox>
        <div class="wrap-btn">
          <button mat-raised-button type="button" class="btn-save" (click)="onSubmitSMS()">Save</button>
          <button mat-raised-button type="button" class="btn-cancel" (click)="onCancelSMS()">Cancel</button>
        </div>
      </div>
      <div class="message" *ngIf="formGroup.get('noteSMS')?.value && !editNoteSMS">
        <h5 class="message-title">Note</h5>
        <span class="message-content">{{ formGroup.get('noteSMS')?.value }}</span>
      </div>
  </div>
  <div id="loading-table" *ngIf="isLoading">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
</div>
