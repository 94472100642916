import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  OffCyclePayment,
  PaymentInforConfigObj,
} from '../../types/models/next-payment.model';
import {
  OffCyclePaymentStatusType,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import * as fromNextPayment from '../../reducers';
import * as NextPaymentActions from '../../actions/next-payment.actions';
import { EditDeductionsComponent } from '../edit-deductions/edit-deductions.component';

@Component({
  selector: 'ptg-deduction-detail',
  templateUrl: './deduction-detail.component.html',
  styleUrls: ['./deduction-detail.component.scss'],
})
export class DeductionDetailComponent implements OnInit, OnDestroy {
  readonly PaymentInfoTypeEnum = PaymentInfoType;
  readonly OffCyclePaymentStatusTypeEnum = OffCyclePaymentStatusType;

  deductionsInfo: any;
  deductions: any[] = [];
  displayedColumns = ['name', 'currentValue'];
  hasManualAdjusted: boolean = false;
  offCyclePayment?: OffCyclePayment;
  unsubscribe$ = new Subject<void>();

  @Input() isHistory?: boolean;
  @Input() paymentInforConfig!: PaymentInforConfigObj;
  @Output() changeBannerEvent = new EventEmitter();

  constructor(
    private store: Store<fromNextPayment.State>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.store
      .select(fromNextPayment.selectNextPayment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) this.offCyclePayment = data.offCyclePayment;
        this.setDisplayedColumns();
      });

    this.store
      .select(fromNextPayment.selectNextPaymentDeduction)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.deductionsInfo = state;
          this.deductions = this.deductionsInfo.deductions;
          this.hasManualAdjusted = this.deductions?.some(
            (item: any) => item.manualAdjustmented
          );
        }
      });
  }

  setDisplayedColumns() {
    if (!this.paymentInforConfig.DEDUCTIONSYEARTODATE) {
      this.displayedColumns = ['name', 'currentValue'];
    } else {
      this.displayedColumns = ['name', 'currentValue', 'YTDValue'];
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit(): void {
    const dialogRef = this.dialog.open(EditDeductionsComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '800px',
      data: {
        ...this.deductionsInfo,
        offCyclePayment: this.offCyclePayment,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.changeBannerEvent.emit();
      if (result)
        this.store.dispatch(
          NextPaymentActions.updateDeduction({
            body: result,
            targetId: this.offCyclePayment?.id,
          })
        );
    });
  }
}
