<div class="member-metadata">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="addSection()"
    buttonAddLabel="New Section"
    (onClickBreadcrumb)="onClickBreadcrumb($event)"
  ></ptg-breadcrumb>

  <div *ngIf="bannerType" class="p-6">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <ptg-grid
    [data]="metaDatasData"
    [columns]="columns"
    [isLoading]="isLoading"
    [fitToParent]="true"
    [paginable]="false"
    (sortChange)="sortChange($event)"
    [errorMessage]="errorMsg"
    notFoundMessage="No Metadata to Display"
  >
    <ng-template cellContent columnName="type" let-row>
      <mat-icon
        *ngIf="row.showMark"
        style="
          color: #408bf9;
          font-size: 17px;
          width: 17px;
          height: 17px;
          margin-top: 5px;
        "
        >check_circle</mat-icon
      >
    </ng-template>
    <ng-template cellContent columnName="propertyCount" let-row>
      {{ row.propertyCount | numberDecimal }}
    </ng-template>
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="editSection(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
      <ptg-button
        *ngIf="!row.isStatic"
        buttonName="Remove"
        classInput="remove-button"
        (clickButton)="onRemoveClick(row)"
      >
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
