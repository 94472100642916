import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { FundModel, Module } from '@ptg-fund-list/models/fund-list.model';
import * as fromReducer from '@ptg-reducers';
import {
  EMPLOYER_FIRST_SUBMODULE_URL,
  EMPLOYER_PORTAL_SUBMODULE_URL,
  EMPLOYER_SUBMODULES_GROUP_BY_MODULE, EmployerSubmoduleKey,
} from '@ptg-shared/constance/employer-portal-permission.const';
import { checkExistNestedKeyValue, deepClone, deepFlattenArray } from '@ptg-shared/utils/common.util';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth0Service } from '../auth/services/auth0.service';
import {
  ModuleKey,
  PERMISSION_KEY,
  PERMISSION_SUB_MODULE_URL,
  SUBMODULE_URL,
  SUBMODULE_GROUP_BY_MODULE,
} from '../constance/permission.const';
import { ADMIN_FUND, ADMIN_SYSTEM } from '../constance/value.const';
import { PermissionModel } from '../models/permission.model';

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionService {
  permission: any;
  permission$: BehaviorSubject<string[]>;
  isLoading = false;
  isNoPermission = false;
  _permissionModuleUrl = deepClone(this.authService.isAdminPortal$.value ? PERMISSION_SUB_MODULE_URL : EMPLOYER_PORTAL_SUBMODULE_URL);
  currentFund$: BehaviorSubject<FundModel> = new BehaviorSubject({} as any);
  get permissionModuleUrl() {
    return this._permissionModuleUrl;
  }
  set permissionModuleUrl(moduleUrl) {
    this._permissionModuleUrl = moduleUrl;
  }

  get isAdminPortal() {
    return this.authService.isAdminPortal$.value;
  }

  get submoduleUrl() {
    return this.isAdminPortal ? SUBMODULE_URL : EMPLOYER_FIRST_SUBMODULE_URL;
  }

  constructor(
    private _router: Router,
    private authService: Auth0Service,
    private store: Store<fromReducer.State>,
  ) {
    this.permission$ = new BehaviorSubject(this.permission);
    this.store.pipe(select(fromReducer.selectCurrentFundState))
      .subscribe(currentFund => this.currentFund$.next(currentFund));
    this.authService.isAdminPortal$.subscribe((isAdminPortal) => {
      this._permissionModuleUrl = deepClone(isAdminPortal ? PERMISSION_SUB_MODULE_URL : EMPLOYER_PORTAL_SUBMODULE_URL);
    });
    
  }

  isSystemAdmin() {
    return this.authService.Role === ADMIN_SYSTEM;
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isSystemAdmin()) {
      return of(this.checkCondition(state?.url, this.getPermissionFilterByModule()));
    }
    if (this.permission || this.authService.Permissions) {
      if (!this.permission) {
        this.permission = this.authService.Permissions;
        this.permission$.next(this.permission);
      }
      return of(this.checkCondition(state?.url, this.getPermissionFilterByModule()));
    }
    return this.getPermission().pipe(
      map((el: any) => {
        this.permission = el;
        this.permission$.next(el);
        localStorage.setItem('prm', btoa(JSON.stringify(el)));
        return this.checkCondition(state?.url, this.getPermissionFilterByModule());
      })
    );
  }

  checkCondition(url: string, allPermission: PermissionModel[]) {
    if (this.checkPermissionByUrl(url, allPermission)) {
      return true;
    }
    void this._router.navigateByUrl(this.getFirstAccessibleModule(url));
    return false;
  }

  getPermission() {
    return of(this.getPermissionFilterByModule());
  }

  getEnabledModules(modules: Module[]) {
    return (deepClone(modules) || [])
      .filter(module => !module.clientModuleIsDisabled)
      .map(module => {
        if (module?.subModules?.length) {
          module.subModules = this.getEnabledModules(module.subModules);
        }
        return module;
      })
      .filter(module => !module.subModules || module.subModules.length);
  }

  getPermissionFilterByModule() {
    return this.filterModulePermission((this.authService.Permissions || []), this.renameProperty(this.getEnabledModules(this.currentFund$.value?.modules)));
  }

  filterModulePermission(permissions: PermissionModel[], modules: PermissionModel[]): PermissionModel[] {
    const currentModules = deepClone(modules);
    if (this.isSystemAdmin()) {
      return currentModules;
    }
    return deepClone(permissions)
      .filter(permission => currentModules.find(module => module.Key === permission.Key))
      .map(permission => {
        const childModules: PermissionModel[] = (currentModules.find(module => (module.Key === permission.Key))?.Child ?? []);
        if (this.isAdminPortal) {
          permission.Child = this.filterModulePermission(permission.Child ?? [], childModules);
        }
        return permission;
      });
  }

  renameProperty(sourceArray: any[]): any[] {
    return deepClone(sourceArray).map(item => {
      item.Key = item.moduleKey;
      delete item.moduleKey;
      if (item.subModules) {
        item.Child = this.renameProperty(item.subModules);
      }
      delete item.subModules;
      return item;
    });
  }

  hasPermission(permissionKey: string | string[], permissions: PermissionModel[]): boolean {
    if (typeof permissionKey === 'string') {
      const isAdminModule = [EmployerSubmoduleKey.ViewRoleList, EmployerSubmoduleKey.AddRole, EmployerSubmoduleKey.EditRole, EmployerSubmoduleKey.RemoveRole].includes(permissionKey as any);
      // TODO update permission for sysadmin when access employer portal
      if (!this.authService.isAdminPortal$.value && this.isSystemAdmin() && isAdminModule) {
        return true;
      }
      return deepFlattenArray(permissions, 'Child').some(permission => permission.Key.toUpperCase() === permissionKey.toUpperCase());
    }
    return checkExistNestedKeyValue(permissions, permissionKey);
  }

  checkPermission(permissionKey: string | string[]) {
    return (permissionKey && this.hasPermission(permissionKey, this.getPermissionFilterByModule()));
  }

  redirectByPermission() {
    this.permission = this.authService.Permissions;
    if (!this.permission?.length || !this.authService.Permissions?.length) {
      this.isNoPermission = true;
      return;
    }
    if (!this.permission) {
      this.permission = this.authService.Permissions;
      this.permission$.next(this.permission);
    }
    if (this.permission.length === 1 && this.permission[0].Key.toUpperCase() === PERMISSION_KEY['PENSION_APP'].toUpperCase()) {
      this.isNoPermission = true;
      return;
    }
    const currentPermissions = this.getPermissionFilterByModule() || [];
    if (!currentPermissions.length || !currentPermissions[0]?.Child?.[0]?.Key) {
      this.isNoPermission = true;
      return;
    }
    this.isNoPermission = false;
    void this._router.navigateByUrl(this.submoduleUrl[currentPermissions[0]?.Child?.[0]?.Key?.toUpperCase()] || '/member');
  }

  getFirstAccessibleUrl(subModuleName: string) {
    // redirect to processing landing page
    if (subModuleName === ModuleKey.Processing && this.isAdminPortal) {
      return this.submoduleUrl.PROCESSING;
    }
    const moduleKey = this.getPermissionFilterByModule().find(permission => permission.Key.toUpperCase() === subModuleName?.toUpperCase())?.Child?.[0]?.Key?.toUpperCase();
    return this.submoduleUrl[moduleKey ?? ''] || '';
  }

  getFirstAccessibleModule(url: string) {
    const currentSubmoduleKey = Object.entries(deepClone(this._permissionModuleUrl))
      .find(([key, moduleUrls]) => (moduleUrls as any[])
        .find(moduleUrl => {
          if (typeof moduleUrl === 'string') {
            return url.includes(moduleUrl);
          }
          return moduleUrl.strict && url === moduleUrl.url;
        }))?.[0];
    const moduleKey = Object.entries(deepClone(this.isAdminPortal ? SUBMODULE_GROUP_BY_MODULE : EMPLOYER_SUBMODULES_GROUP_BY_MODULE))
      .find(([key, submoduleKeys]) => submoduleKeys
        .find(submoduleKey => submoduleKey === currentSubmoduleKey?.toLowerCase()))?.[0];
    if (!moduleKey) {
      return '/permission';
    }
    if (this.getPermissionFilterByModule().find(permission => permission.Key === moduleKey)) {
      return this.getFirstAccessibleUrl(moduleKey);
    }
    return this.getPermissionFilterByModule()?.[0]?.Key || PERMISSION_KEY.PARTICIPANTS;
  }

  checkPermissionByUrl(url: string, allPermission: PermissionModel[]) {
    const permissionModules = deepFlattenArray(deepClone(allPermission),'Child')
      .reduce((prev, current) => {
        if (this.permissionModuleUrl[current.Key.toUpperCase()]) {
          (prev as any).push(...this.permissionModuleUrl[current.Key.toUpperCase()]);
        }
        let children = (current.Child ?? []).map(item => item.Key);
        // check permission by module when login employer portal by system admin or fund admin
        if (!this.isAdminPortal && [ADMIN_SYSTEM, ADMIN_FUND].includes(this.authService.Role)) {
          children = EMPLOYER_SUBMODULES_GROUP_BY_MODULE[current.Key.toUpperCase()] ?? [];
        }
        (prev as any).push(...children.reduce((result, item) => {
          if (this.permissionModuleUrl[item.toUpperCase()]) {
            (result as any).push(...this.permissionModuleUrl[item.toUpperCase()]);
          }
          return result;
        }, []));
        return prev;
      }, [] as string[]);

    return permissionModules.some((item: any) => {
      if (typeof item === 'string') {
        return url.includes(item);
      }
      return item.strict && url === item.url;
    });
  }
}
