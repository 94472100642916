import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as MetadataSectionActions from '../../actions/metadata-detail.actions';
import { MetadataSectionService } from '../../services/metadata-detail.service';
import * as fromMember from '../../reducers';
import { SwitchConfirmPopupService } from 'src/app/shared/services/switch-confirm-popup.service';
import { checkApiValidator } from 'src/app/shared/validators/checkApi.validator';
import { AggregationConfigService } from '../../services/aggregation-config.service';
import * as AggregationConfigActions from '../../actions/aggregation-config.actions';
import { STATE } from 'src/app/shared/constance/value.const';
import { PropertyType } from '../../constance/metadataPropertyType.const';

interface DataAggregation {
  isEdit: boolean;
  properties: any[];
  record: {} | any;
  sectionKey: string;
}

@Component({
  selector: 'ptg-add-aggregation',
  templateUrl: './add-aggregation.component.html',
  styleUrls: ['./add-aggregation.component.scss']
})
export class AddAggregationComponent implements OnInit, OnDestroy {
  readonly PropertyType = PropertyType;
  editForm: FormGroup;
  listAggregate: any[] = [];
  listAggregateFull = [
    {displayValue: 'SUM', value: 1},
    {displayValue: 'AVERAGE', value: 2},
    {displayValue: 'MIN', value: 3},
    {displayValue: 'MAX', value: 4},
    {displayValue: 'COUNT', value: 0},
  ];
  listTypeFull = ['Currency', 'Percentage', 'Whole Number', 'Decimal'];
  isAddAnother = false;
  properties: any[] = [];
  haveReloadAggList = false;
  subscriptions: any[] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddAggregationComponent>,
    private store: Store<fromMember.MemberState>,
    private metadataSectionService: MetadataSectionService,
    @Inject(MAT_DIALOG_DATA) public data: DataAggregation,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private aggregationConfigService: AggregationConfigService
  ) { 
    this.editForm = new FormGroup({
      label: new FormControl('', {asyncValidators: checkApiValidator(this.aggregationConfigService.checkExits, 'name')}),
      property: new FormControl(''),
      aggregate: new FormControl('')
    });
    
    if (this.data.properties?.length > 0) {
      this.properties = this.data.properties.map((el: any) => {
        return {
          displayValue: el?.name, value: el?.key
        };
      })
    }

    if (this.data.record) {
      this.aggregationConfigService.currentNewAggregationId = this.data.record.id;

      this.editForm.get('label')?.setValue(this.data.record.label);
      let propertiValue = this.properties.find(el => el.displayValue === this.data.record.property);
      this.editForm.get('property')?.setValue(propertiValue?.value);
      let aggregateType = this.listAggregateFull.find(el => el.displayValue === this.data.record.aggregation);
      this.editForm.get('aggregate')?.setValue(aggregateType?.value);

      this.setAggregateOptions(propertiValue?.value);
    } else {
      this.aggregationConfigService.currentNewAggregationId = '';
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.store.pipe(select(fromMember.selectAggregationConfigState)).subscribe(state => {
      if (state.addState === STATE.SUCCESS) {
        this.haveReloadAggList = true;
        if (!this.isAddAnother) {
          this.data.isEdit = true;
          this.data.record = {id: this.aggregationConfigService.currentNewAggregationId};
        } else {
          this.editForm.reset();
          this.listAggregate = [];
          this.aggregationConfigService.currentNewAggregationId = '';
        }
        this.store.dispatch(AggregationConfigActions.aggregationClear());
      }
    }));
    this.subscriptions.push(this.editForm.get('property')?.valueChanges.subscribe(val => {
      this.setAggregateOptions(val);
      this.editForm.get('aggregate')?.reset();
    }));
  }

  setAggregateOptions(propertyKey: string) {
    let selectedProperty = this.data.properties.find(item => item.key === propertyKey);
    if (selectedProperty?.type === PropertyType.TYPE_DATE || selectedProperty?.type === PropertyType.TYPE_DATE_TIME) {
      this.listAggregate = [
        {displayValue: 'MIN', value: 3},
        {displayValue: 'MAX', value: 4},
        {displayValue: 'COUNT', value: 0},
      ]
    } else if (this.listTypeFull.includes(selectedProperty?.type)) {
      this.listAggregate = JSON.parse(JSON.stringify(this.listAggregateFull));
    } else {
      this.listAggregate = [{displayValue: 'COUNT', value: 0}];
    }
  }

  saveAggregation(isAddAnother: boolean) {
    let payload = {
      name: this.editForm.get('label')?.value,
      propertyKey: this.editForm.get('property')?.value,
      aggregationType: this.editForm.get('aggregate')?.value,
    }
    if (!this.data.isEdit) {
      this.store.dispatch(AggregationConfigActions.addAggregation({body: payload}));
    } else {
      // Dispatch Action Edit
      this.store.dispatch(AggregationConfigActions.editAggregation({body: payload}));
      this.dialogRef.close(true);
    }
  }

  submitForm(isAddAnother: boolean = false) {
    this.editForm.markAllAsTouched();
    this.isAddAnother = isAddAnother;
    if (this.editForm.invalid) {
      return;
    }
    this.aggregationConfigService.checkExits({name: this.editForm.get('label')?.value}).subscribe((res: any) => {
      if (res && res.isExisted) {
        this.editForm.get('label')?.setErrors({inValidAsync: true})
      }
      if (this.editForm.pending) {
        let sub = this.editForm.statusChanges.subscribe(() => {
          if (this.editForm.valid) {
            this.saveAggregation(isAddAnother);
          }
          sub.unsubscribe();
        });
      } else if (this.editForm.valid) {
        this.saveAggregation(isAddAnother);
      }
    });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: any) => sub.unsubscribe());
  }
}
