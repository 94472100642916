import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AggregationGroup } from '../types/models/aggregation-group.model';
import { AggregationGroupService } from '../services/aggregation-group.service';
import { AggregationGroupActions } from '../actions';

@Injectable()
export class AggregationGroupEffects {
  getAggregationGroupList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationGroupActions.getAggregationGroupList),
      switchMap(() => {
        return this.aggregationGroupService.getAggregationGroupList().pipe(
          map((res: any) => {
            let aggregationGroup: AggregationGroup[] = res.groups.map(
              (item: AggregationGroup) => {
                return {
                  ...item,
                  count: item.aggregations.length,
                };
              }
            );
            return AggregationGroupActions.getAggregationGroupSuccess({
              aggregationGroup,
            });
          }),
          catchError((error) => {
            return of(
              AggregationGroupActions.getAggregationGroupListFailure({ error })
            );
          })
        );
      })
    )
  );

  orderTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AggregationGroupActions.orderTable),
        switchMap(({ body }) => {
          return this.aggregationGroupService.orderTable(body);
        })
      ),
    { dispatch: false }
  );

  addAggregationGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationGroupActions.addAggregationGroup),
      switchMap(({ body }) => {
        return this.aggregationGroupService.addAggregationGroup(body).pipe(
          map(() => {
            return AggregationGroupActions.addAggregationGroupSuccess();
          }),
          catchError((err) => {
            return of(
              AggregationGroupActions.addAggregationGroupFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  updateAggregationGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationGroupActions.updateAggregationGroup),
      switchMap(({ id, body }) => {
        return this.aggregationGroupService
          .updateAggregationGroup(id, body)
          .pipe(
            map(() => {
              return AggregationGroupActions.updateAggregationGroupSuccess();
            }),
            catchError((err) => {
              return of(
                AggregationGroupActions.addAggregationGroupFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  removeAggregationGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationGroupActions.removeAggregationGroup),
      switchMap((action) => {
        return this.aggregationGroupService
          .removeAggregationGroup(action.id)
          .pipe(
            map(() => {
              return AggregationGroupActions.removeAggregationGroupSuccess();
            }),
            catchError((err) => {
              return of(
                AggregationGroupActions.removeAggregationGroupFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  getAggregationGroupDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationGroupActions.getAggregationGroupDetailsAction),
      switchMap(({ body }) =>
        this.aggregationGroupService.getAggregationGroupDetails(body).pipe(
          map((response: any) =>
            AggregationGroupActions.getAggregationGroupDetailsSuccessAction({
              response,
            })
          ),
          catchError((error) =>
            of(
              AggregationGroupActions.getAggregationGroupDetailsFailureAction({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private aggregationGroupService: AggregationGroupService
  ) {}
}
