<div class="demographic-info">
  <ng-container *ngIf="demoDetail">
    <p class="title">
      <span>{{configSection?.sectionTitle}}</span>
      <ng-container>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Icon-button with a menu" class="group-button ignore-row">
          <mat-icon class="ignore-row">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editSection()">
            <mat-icon class="demographics-action-icon">edit</mat-icon>
            <span class="label-icon">Edit</span>
          </button>
          <button mat-menu-item *ngIf="configSection.hasAttachment && configSection.type !== sectionLayout.List" (click)="showAttachments()">
            <mat-icon class="member-section-action-icon">download</mat-icon>
            <span class="label-icon">Attachment</span>
          </button>
        </mat-menu>
      </ng-container>
    </p>
    <div class="demo-content">
      <div class="second-line">
        <p class="half-row space-between first-col d-flex align-items-center" style="display: flex;">
          <span class="lable">{{ propertiesConfig?.name?.name }}</span>
          <span class="value pr-7">{{ demoDetail?.name?.full }}</span>
        </p>
        <p class="half-row space-between d-flex align-items-center" style="display: flex;">
          <span class="lable status-label">Status</span>
          <span class="data-demo">
            <mat-icon class="icon-status" [ngStyle]="{'color': memberStatus?.color}">
                    {{ memberStatus?.iconName }}
                  </mat-icon>
            <span class="status-name">{{ memberStatus?.name }}</span>
          </span>
        </p>
      </div>

      <p class="second-line m-0">
        <span class="half-row">
          <span class="space-between" style="display: flex; align-items: center;">
          <span class="lable" style="max-width: calc(100% - 119px);">{{propertiesConfig?.last4Ssn?.name}}</span>
        <span class="ssn">
          <span style="color: #b1b1b1;">XXX-XX</span>
          <span class="value">-{{ demoDetail?.last4Ssn}}</span>
        </span>
        </span>
        </span>
        <span class="half-row display-inline-flex space-between member-id">
          <span class="lable pl-16"></span>
          <span class="value" style="vertical-align: middle">{{ memberKey }}</span>
        </span>
      </p>

      <div class="pr-20">
        <div class="half-row">
          <p class="third-line space-between">
            <span class="lable">{{propertiesConfig?.birthday?.name}}</span>
            <span class="value">{{ demoDetail?.birthday | date: 'MM/dd/yyyy' }}</span>
          </p>

          <p class="third-line space-between">
            <span class="lable">{{propertiesConfig?.hireDate?.name}}</span>
            <span class="value">{{ demoDetail?.hireDate | date: 'MM/dd/yyyy' }}</span>
          </p>

          <p class="third-line space-between">
            <span class="lable">{{propertiesConfig?.retireDate?.name}}</span>
            <span class="value">{{ demoDetail?.retireDate | date: 'MM/dd/yyyy' }}</span>
          </p>
        </div>
        <div class="half-row vertical-top display-inline-flex space-between">
          <div class="half-row lable address vertical-top">
            {{propertiesConfig?.address?.name}}
          </div>
          <div class="half-row value address-value">
            <p>{{ demoDetail?.address?.street1 }}{{demoDetail?.address?.street2 && demoDetail?.address?.street1 ? ', ' : ''}}{{ demoDetail?.address?.street2 }}</p>
            <p>
              {{ demoDetail?.address?.city ? demoDetail?.address?.city + ', ' : '' }}
              {{ demoDetail?.address?.state }}
              <span style="word-break: break-word;">
                {{ demoDetail?.address?.zipCode }}{{ demoDetail?.address?.country ? '-' + demoDetail?.address?.country : ''}}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="fourth-line pr-20">
        <p class="half-row space-between first-col" style="display: flex;">
          <span class="lable">{{propertiesConfig?.phone?.name}}</span>
          <span class="value">
            {{ propertiesConfig?.phone?.value | phoneNumber }}
            <ptg-masked-eye 
              *ngIf="propertiesConfig?.phone?.value  && propertiesConfig?.phone?.config?.masked == 'true'"
              page="demographics"
              [memberId]="memberId"
              [propertyValue]="propertiesConfig?.phone"
              itemKey="demographics"
              propertyKey="phone"
              index="0">
            </ptg-masked-eye>
          </span>
          
        </p>
        <p class="half-row space-between" style="display: flex;">
          <span class="lable pl-23" style="width: 76px;">{{propertiesConfig?.email?.name}}</span>
          <span class="value">
            {{ propertiesConfig?.email?.value }}
            <ptg-masked-eye 
              *ngIf="propertiesConfig?.email?.value && propertiesConfig?.email?.config?.masked == 'true'"
              page="demographics"
              [memberId]="memberId"
              [propertyValue]="propertiesConfig?.email"
              itemKey="demographics"
              propertyKey="email"
              index="0">
            </ptg-masked-eye>
          </span>
        </p>
      </div>

      <div class="add-properties pr-20">
        <div class="half-row mg-b-15" *ngFor="let property of additionalProperties">
          <div class="third-line" [ngClass]="{'masked-value': property?.value && propertiesConfig[property.key]?.config?.masked == 'true'}">
            <span class="lable truncate">{{ propertiesConfig[property.key]?.name }}</span>
            <ng-container [ngSwitch]="propertiesConfig[property.key]?.type">
              <span class="value truncate" *ngSwitchCase="'Currency'">{{ property.value | numberLocalDecimal:'$' }}</span>
              <span class="value truncate" *ngSwitchCase="'Date'">{{ property.value | date: 'MM/dd/yyyy' }}</span>
              <span class="value truncate" *ngSwitchCase="'Person_Name'">{{ property.value | personName: propertiesConfig[property.key]?.options  }}</span>
              <span class="value truncate" *ngSwitchCase="'Address'">{{ property.value | address: propertiesConfig[property.key]?.options }}</span>
              <span class="value truncate" *ngSwitchCase="'Decimal'">{{ property.value | numberDecimal:{decimal: propertiesConfig[property.key]?.config?.fractionalLengthInput, isCheckCurrentDecimal: true} }}</span>
              <span class="value truncate" *ngSwitchCase="'Whole_Number'">{{ property.value | numberDecimal }}</span>
              <span class="value truncate" *ngSwitchCase="'Lookup'">{{ property.value | propertyList : propertiesConfig[property.key]?.options }}</span>
              <span class="value truncate" *ngSwitchCase="'Binary'">{{property.value === 'true' ? propertiesConfig[property.key]?.config.affirmative: propertiesConfig[property.key]?.config.negative}}</span>
              <span class="value truncate" *ngSwitchCase="'Percentage'">{{property.value}}&percnt;</span>
              <span class="value truncate" *ngSwitchCase="'Employer'">{{ property.value | propertyList : propertiesConfig[property.key]?.options}}</span>
              <ng-container *ngSwitchCase="'RichText'">
                <ptg-view-rich-text [title]="propertiesConfig[property.key]?.name" [content]="property?.value"></ptg-view-rich-text>
              </ng-container>
              <span class="value truncate" *ngSwitchCase="'Department'">{{ property.value | propertyList : propertiesConfig[property.key]?.options}}</span>
              <span class="value truncate" *ngSwitchCase="'DateTime'">{{ property?.value | date: 'MM/dd/yyyy hh:mm a' }}</span>
              <span class="value truncate" *ngSwitchDefault>{{ property.value }}</span>
            </ng-container>
            <ptg-masked-eye 
              *ngIf="property.value && propertiesConfig[property.key]?.config?.masked == 'true'"
              page="demographics"
              [memberId]="memberId"
              [propertyValue]="property"
              itemKey="demographics"
              [propertyKey]="propertiesConfig[property.key]?.key"
              index="0">
            </ptg-masked-eye>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
