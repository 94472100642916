import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION, SORT_TYPE } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Align, Column } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { FileService } from '@ptg-shared/services/file-service';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

import { DOCUMENT } from '@angular/common';
import { AggregationGroupActions, MemberDetailActions } from '../../actions';
import { FixedSectionKey, SectionLayout } from '../../constance/metadata.const';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import {
  dispatchGetMetadataNonListSections,
  dispatchGetMetadataSections,
  getColumConfig,
} from '../../helpers';
import * as fromMember from '../../reducers';
import {
  MemberState,
  selectAddSectionState,
  selectMemberMetadataAttachmentState,
} from '../../reducers';
import {
  MemberNavigationAttachmentList,
  MemberStatus,
  MetadataProperty,
  MetadataSection,
} from '../../types/models';
import { AddMemberSectionDataComponent } from '../add-member-section-data/add-member-section-data.component';
import { MemberAttachmentModalComponent } from '../member-attachment-modal/member-attachment-modal.component';
import { OverviewHeaderComponent } from '../overview-header/overview-header.component';
import { DropStatementPopupComponent } from '@ptg-member/containers/drop-statement-popup/drop-statement-popup.component';

const PAGE_SIZE_CONST = '-ptg-member-navigation-page-pageSize';
const PAGE_SIZE_ATT_CONST = '-ptg-member-navigation-page-att-pageSize';

@Component({
  selector: 'ptg-default-section',
  templateUrl: './default-section.component.html',
  styleUrls: ['./default-section.component.scss'],
})
export class DefaultSectionComponent extends BaseComponent {
  isSubmitting: boolean = false;
  memberStatus: MemberStatus | undefined;
  dataTable?: any[];
  isLoadingDataTable: boolean = true;
  dataAttachment: any = [];
  isLoadingAttachments: boolean = true;
  aggregationDetails: any = [];
  attachments: MemberNavigationAttachmentList[] = [];
  columns?: Column[];
  errorMsg: string = '';
  sortInfo: {} | any = {};
  lengthPg: number = 0;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;

  attPageSize: number = 50;
  attPageNumber: number = FIRST_PAGE;
  attLengthPg: number = 0;

  bannerType: BannerType = BannerType.Hidden;
  message = '';
  currentRowIndex: any = 0;
  activeFund: any;
  metadataSections: MetadataSection[] = [];
  metadataNonListSections: MetadataSection[] = [];
  metadataSection?: MetadataSection;
  metadataNonListSection?: MetadataSection;
  memberDetail: any;
  detailInfo: any = {
    memberDetail: {},
  };
  listArregations: any[] = [];
  isHideBannerIcon: boolean = false;
  isHideCloseButton: boolean = false;

  @Input() currentSectionLayout?: SectionLayout;
  @Input() memberId!: string;
  @Input() menuId!: string;
  @Input() itemKey!: string;
  @Input() menuItemName!: string;
  @Input() isList?: boolean;

  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;

  constructor(
    private store: Store<fromReducer.State>,
    private memberStore: Store<MemberState>,
    private router: Router,
    private dialog: MatDialog,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    public layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    const currentFund = this.layoutService.currentFund$.value;
    this.pageSize = currentFund.defaultPageSize ?? 50;
    this.attPageSize = currentFund.defaultPageSize ?? 50;
    this.activeFund = currentFund;

    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.activeFund.key + this.menuId + this.memberId + PAGE_SIZE_CONST,
        ),
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.activeFund.key +
                this.menuId +
                this.memberId +
                PAGE_SIZE_CONST,
            ),
          );
    this.attPageSize =
      Number(
        sessionStorage.getItem(
          this.activeFund.key +
            this.menuId +
            this.memberId +
            PAGE_SIZE_ATT_CONST,
        ),
      ) === 0
        ? this.attPageSize
        : Number(
            sessionStorage.getItem(
              this.activeFund.key +
                this.menuId +
                this.memberId +
                PAGE_SIZE_ATT_CONST,
            ),
          );

    if (!this._isSpecialPage()) {
      this.store.dispatch(
        MemberDetailActions.getMemberById({ id: this.memberId }),
      );
      dispatchGetMetadataSections(this.memberStore);

      if (
        this.currentSectionLayout === SectionLayout.Column ||
        this.currentSectionLayout === SectionLayout.Table
      ) {
        dispatchGetMetadataNonListSections(this.memberStore, this.menuId);
      }
    }

    combineLatest([
      this.store.select(fromMember.getMetadataSectionsSelector),
      this.store.select(fromMember.getMetadataNonListSectionsSelector),
    ])
      .pipe(
        filter(([state1, state2]) => !!state1 || !!state2),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(([state1, state2]) => {
        if (state1?.success && !state1?.isLoading && state1?.payload) {
          this.metadataSections = state1.payload;
          if (this.metadataSections.length > 0) {
            this.metadataSection = this.metadataSections.find(
              (x) => x.key === this.itemKey,
            );
          }
        }

        if (state2?.success && !state2?.isLoading && state2?.payload) {
          this.metadataNonListSections = state2.payload.nonListSections;
          if (this.metadataNonListSections.length > 0) {
            this.metadataNonListSection = this.metadataNonListSections.find(
              (x) => x.key === this.itemKey,
            );
          }
        }

        if (
          this.isList ||
          this.metadataSection?.hasAttachment ||
          this.metadataNonListSection?.hasAttachment
        ) {
          this.getMemberMetaDataList();
          this.getAggregationGroupDetails();
        } else if (this.itemKey && !this.itemKey.startsWith(FixedSectionKey)) {
          this.store.dispatch(
            MemberDetailActions.getMemberDetail({
              id: this.memberId,
              listSection: [this.itemKey],
              menuId: this.menuId,
            }),
          );
        }
      });

    this.store
      .pipe(
        select(fromMember.selectMemberDetailState),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((el) => {
        this.detailInfo.memberInfo = el.memberInfo;
        const listStatus = el.listStatus?.filter(
          (status) =>
            status.active || status.id === this.detailInfo.memberInfo?.statusId,
        );
        this.memberStatus = listStatus?.find(
          (item) => item.id === this.detailInfo.memberInfo?.statusId,
        );

        if (!this.isList && !this.metadataSection?.hasAttachment) {
          this.memberDetail = el.memberDetail
            ? (el.memberDetail as any)[`${this.itemKey}`]
            : undefined;
          if (this.memberDetail) {
            this.detailInfo.memberDetail[this.itemKey] = this.memberDetail;
            this.dataTable = [
              {
                ...this.memberDetail,
              },
            ].map((item: any, index: number) =>
              this.getColumnData(
                item,
                this.metadataSection?.properties || [],
                index,
              ),
            );
          }
        }
      });

    this.store
      .pipe(
        select(selectAddSectionState),
        filter(() => this.isSubmitting),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((el) => {
        if (el.reloadSection) {
          if (el.saveSectionDataSuccess) {
            this.store.dispatch(MemberDetailActions.resetStateRemoveMember());
            this.scrollToTop();
            showBanner.call(
              this,
              BannerType.Success,
              this.menuItemName,
              el.isEdit
                ? ACTION.EDIT
                : el.isRemove
                  ? ACTION.REMOVE
                  : ACTION.ADD,
            );
            if (this.isList || this.metadataSection?.hasAttachment) {
              this.getMemberMetaDataList();
            } else if (this.itemKey) {
              this.getMemberMetaDataList();
            }

            this.reloadOverviewHeader();
            this.getAggregationGroupDetails();
          } else {
            showBanner.call(
              this,
              BannerType.Fail,
              this.menuItemName,
              el.isEdit
                ? ACTION.EDIT
                : el.isRemove
                  ? ACTION.REMOVE
                  : ACTION.ADD,
            );
            this.store.dispatch(MemberDetailActions.resetStateRemoveMember());
          }
        } else if (el.saveSectionDataSuccess === false) {
          showBanner.call(
            this,
            BannerType.Fail,
            this.menuItemName,
            el.isEdit ? ACTION.EDIT : el.isRemove ? ACTION.REMOVE : ACTION.ADD,
          );
          this.store.dispatch(MemberDetailActions.resetStateRemoveMember());
        }

        this.isSubmitting = false;
      });

    this.store
      .pipe(
        select(selectMemberMetadataAttachmentState),
        filter(() => this.isSubmitting),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((el) => {
        this.scrollToTop();
        if ((el as any).hasOwnProperty('success') && el?.success) {
          this.store.dispatch(
            MemberDetailActions.resetMemberMetadataAttachmentState(),
          );
          const message = el?.isCreate
            ? `${this.menuItemName} document`
            : el?.isEdit
              ? `Attachment`
              : el?.documentName;
          showBanner.call(
            this,
            BannerType.Success,
            message!,
            el?.isEdit
              ? ACTION.EDIT
              : el?.isRemove
                ? ACTION.REMOVE
                : ACTION.ADD,
          );
          if (this.isList || this.metadataSection?.hasAttachment) {
            if (
              el?.isRemove &&
              this.dataTable?.length === 1 &&
              this.pageNumber > FIRST_PAGE
            ) {
              this.pageNumber = this.pageNumber - 1;
            }
            this.getMemberMetaDataList();
          } else if (this.itemKey) {
            this.store.dispatch(
              MemberDetailActions.getMemberDetail({
                id: this.memberId,
                listSection: [this.itemKey],
                menuId: this.menuId,
              }),
            );
          }
        } else if ((el as any).hasOwnProperty('success') && !el?.success) {
          const message = el?.isCreate
            ? `document to ${this.menuItemName}`
            : el?.isEdit
              ? `Attachment`
              : el?.documentName;
          showBanner.call(
            this,
            BannerType.Fail,
            message!,
            el?.isEdit
              ? ACTION.EDIT
              : el?.isRemove
                ? ACTION.REMOVE
                : ACTION.ADD,
          );
          this.store.dispatch(
            MemberDetailActions.resetMemberMetadataAttachmentState(),
          );
        }

        this.isSubmitting = false;
      });

    this.store
      .pipe(
        select(fromMember.getMemberDetailWithPagingSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((el) => {
        this.isLoadingDataTable = !!el?.isLoading;

        // Get an error message (if any)
        if (!el?.success && el?.error) {
          this.errorMsg = el.error.statusText;
        }
        this.detailInfo.memberDetail = {};
        const properties = this.metadataSection?.properties || [];

        if (el?.payload) {
          if (this.isList) {
            const columns = [];
            this.detailInfo.memberDetail[this.itemKey] = el.payload;
            if (properties.length > 0) {
              this.lengthPg = el.payload.total;
              this.attLengthPg = el.payload.totalAttachment;
              this.memberDetail = el.payload.table;
              this.dataTable = this.memberDetail?.map(
                (item: any, index: number) =>
                  this.getColumnData(item, properties, index),
              );
              el.payload.columns.forEach((column: any) => {
                const columConfig = getColumConfig(
                  column.type,
                  column.configValue,
                );
                columns.push({
                  name: column.columnKey,
                  header: {
                    title: column.columnName,
                  },
                  truncate: true,
                  sortable: true,
                  type: columConfig.type,
                  align:
                    column.type === PropertyType.TYPE_REGISTER
                      ? Align.Center
                      : undefined,
                  templateArgs: columConfig.templateArgs,
                });
              });
              if (this.metadataSection?.hasAttachment) {
                this.attachments = el.payload.attachments.map((att: any) => ({
                  ...att,
                  attachment: att?.fileSnapshot?.fileName,
                }));
                columns.push({
                  name: 'noOfAttachments',
                  header: {
                    title: 'Attachment',
                  },
                  align: Align.Right,
                });
                if (this.isList && this.dataTable?.length) {
                  this.getAttachmentList();
                }
              }

              this.columns = columns;
            }
          } else {
            this.memberDetail = el.payload;
            this.dataTable = [
              {
                ...el.payload,
              },
            ].map((item: any, index: number) =>
              this.getColumnData(item, properties, index),
            );
            if (this.metadataSection?.hasAttachment) {
              this.attachments = el.payload.attachments.map((att: any) => ({
                ...att,
                attachment: att?.fileSnapshot?.fileName,
              }));
              this.columns = [
                {
                  name: 'noOfAttachments',
                  header: {
                    title: 'Attachment',
                  },
                  align: Align.Right,
                },
              ];
              if (this.isList && this.dataTable?.length) {
                this.getAttachmentList();
              }
            }
          }
        } else {
          this.dataTable = [];
          this.attachments = [];
        }
      });

    this.store
      .pipe(
        select(fromMember.getAggregationGroupDetailsSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((el) => {
        if (el?.payload?.length) {
          this.listArregations = el.payload.map((aggregation) => {
            return {
              ...aggregation,
              items: aggregation.items.map((item) => {
                return {
                  ...item,
                  value: this.setValueAggregation(
                    item.value,
                    item.aggregationType,
                    item.propertyType,
                  ),
                };
              }),
            };
          });
        } else {
          this.listArregations = [];
        }
      });

    this.store
      .pipe(
        select(fromMember.getMemberAttachmentsSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.isLoadingAttachments = !!state?.isLoading;

        if (state?.success && !state?.isLoading && state?.payload)
          this.dataAttachment = (state.payload ?? []).map((att: any) => ({
            ...att,
            attachment: att?.fileSnapshot?.fileName,
          }));
        this.attLengthPg = state?.total ?? 0;
      });
  }

  getAttachmentList() {
    if (
      this.metadataSection?.type === SectionLayout.List &&
      !this.dataTable?.[this.currentRowIndex]?.rowId
    ) {
      return;
    }
    this.store.dispatch(
      MemberDetailActions.getMemberAttachmentsAction({
        memberId: this.memberId,
        itemKey: this.itemKey,
        query: {
          rowId: this.dataTable?.[this.currentRowIndex]?.rowId,
          pageIndex: this.attPageNumber,
          pageSize: this.attPageSize,
        },
      }),
    );
  }

  setValueAggregation(
    value: string,
    aggregationType: number,
    propertyType: number,
  ) {
    if (!value) {
      return value;
    }
    if (aggregationType === 0) {
      return +value;
    }
    if (propertyType === 7 || propertyType === 8) {
      return new Date(value);
    }
    return +value;
  }

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  getColumnData(item: any, properties: MetadataProperty[], index: number) {
    const columnData = JSON.parse(JSON.stringify(item));
    Object.keys(columnData).forEach((propertyName) => {
      if (columnData[propertyName]) {
        const config = properties.find((config) => propertyName === config.key);
        if (
          config?.type === PropertyType.TYPE_LIST ||
          config?.type === PropertyType.TYPE_EMPLOYER ||
          config?.type === PropertyType.TYPE_DEPARTMENT
        ) {
          columnData[propertyName] = config?.options?.find(
            (op) => op.id === columnData[propertyName],
          )?.text;
        } else if (config?.type === PropertyType.TYPE_BOOLEAN) {
          columnData[propertyName] =
            columnData[propertyName] === 'true'
              ? config?.config?.affirmative
              : config?.config?.negative;
        } else if (config?.type === PropertyType.TYPE_PERCENTAGE) {
          columnData[propertyName] = +columnData[propertyName];
        }
      }
    });
    columnData.id = index;
    return columnData;
  }

  //#region Handle for Attachments
  onChangePageAttachments(event: PageEvent): void {
    this.attPageSize = event.pageSize;
    this.attPageNumber = event.pageNumber;

    this.getAttachmentList();
  }
  //#endregion

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
  }

  changePagging(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.activeFund.key + this.menuId + this.memberId + PAGE_SIZE_CONST,
      this.pageSize.toString(),
    );
    this.getMemberMetaDataList();
  }

  changeAttachmentPagging(event: PageEvent) {
    this.currentRowIndex = 0;
    this.attPageSize = event.pageSize;
    this.attPageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.activeFund.key + this.menuId + this.memberId + PAGE_SIZE_ATT_CONST,
      this.attPageSize.toString(),
    );
    this.getMemberMetaDataList();
  }

  getMemberMetaDataList() {
    let sortType = this.metadataSection?.hasAttachment
      ? SORT_TYPE.DESC
      : SORT_TYPE.ASC;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField =
        this.metadataSection?.type !== SectionLayout.List &&
        this.metadataSection?.hasAttachment
          ? capitalizeFirstLetter(this.sortInfo.active)
          : this.sortInfo.active;
      sortType =
        this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    this.store.dispatch(
      MemberDetailActions.getMemberDetailWithPagingAction({
        id: this.memberId,
        itemKey: this.itemKey,
        query: {
          pageIndex: this.pageNumber,
          totalPerPage: this.pageSize,
          sortField,
          sortType,
          attachmentSortName: sortField,
          attachmentSortType: sortType,
          attachmentPageSize: this.metadataSection?.hasAttachment
            ? this.attPageSize
            : undefined,
          attachmentPageIndex: this.metadataSection?.hasAttachment
            ? this.attPageNumber
            : undefined,
        },
        menuId: this.menuId,
      }),
    );
  }

  getAggregationGroupDetails() {
    if (this.isList) {
      const body = {
        memberId: this.memberId,
        itemKey: this.itemKey,
      };
      this.store.dispatch(
        AggregationGroupActions.getAggregationGroupDetailsAction({ body }),
      );
    }
  }

  selectRow(event: any) {
    this.currentRowIndex = event?.id;
    if (this.metadataSection?.hasAttachment) {
      this.getAttachmentList();
    }
  }

  openOverview(
    sectionKey: string,
    isShowBanner?: boolean,
    saveSectionDataSuccess?: boolean,
  ) {
    this.router.navigateByUrl(`/member/detail/${this.memberId}/${sectionKey}`, {
      state: {
        isShowBanner,
        saveSectionDataSuccess,
      },
    });
  }

  removeMetaData() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: `Remove ${this.menuItemName}`,
        text: `Are you sure you want to remove this ${this.menuItemName}?`,
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.metadataSection) {
          this.isSubmitting = true;
          this.store.dispatch(
            MemberDetailActions.removeSectionTable({
              sectionData: this.metadataSection,
              body: {
                memberId: this.memberId,
                sectionKey: this.metadataSection.key,
                index: this.dataTable?.[this.currentRowIndex]['index'],
              },
            }),
          );
        }
      }
    });
  }

  openMetaDataForm(
    section: any,
    addToTop: boolean = true,
    isAddNew: boolean = true,
  ) {
    this.bannerType = BannerType.Hidden;
    const dialogRef = this.dialog.open(AddMemberSectionDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        section: this.metadataSection,
        memberId: this.memberId,
        metadataItem: this.detailInfo?.memberInfo?.memberKey,
        addToTop,
        isAddNew,
        memberStatus: this.memberStatus,
        customHeader: this.menuItemName,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this.isSubmitting = true;
      }
    });
  }

  editMetaDataForm() {
    if (this.isList) {
      if (this.metadataSection) {
        let editData = {
          ...((this.memberDetail as []).find(
            (x) =>
              x['index'] === this.memberDetail[this.currentRowIndex]['index'],
          ) as any),
        };
        this.metadataSection.properties.forEach((prop) => {
          if (
            prop.type === PropertyType.TYPE_LIST ||
            prop.type === PropertyType.TYPE_EMPLOYER ||
            prop.type === PropertyType.TYPE_DEPARTMENT
          ) {
            editData[prop.key] = prop.options?.find(
              (x) => x.id === editData[prop.key],
            )?.text;
          }
        });

        this.openEditDialog({
          isAddNew: false,
          data: { [this.metadataSection.key]: editData },
          notShowFile: true,
        });
      }
    } else {
      const dialogRef = this.dialog.open(AddMemberSectionDataComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {
          section: this.metadataSection,
          data: this.detailInfo.memberDetail,
          memberId: this.memberId,
          isAddNew: false,
          metadataItem: this.detailInfo?.memberInfo?.memberKey,
          memberStatus: this.memberStatus,
          customHeader: this.menuItemName,
        },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === 'submit') {
          this.isSubmitting = true;
        }
      });
    }
  }

  openEditDialog(options: any) {
    const sectionData = JSON.parse(JSON.stringify(this.metadataSection));
    const dialogRef = this.dialog.open(AddMemberSectionDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        section: sectionData,
        memberId: this.memberId,
        metadataItem: sectionData.key,
        customHeader: this.menuItemName,
        isAllData: true,
        ...options,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this.isSubmitting = true;
      }
    });
  }

  onOpenUpLoadAttachment(attachment: any) {
    const dialogRef = this.dialog.open(MemberAttachmentModalComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        itemKey: this.itemKey,
        attachment,
      },
    });
  }

  onRemoveAttachment(attachment: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove this ${this.menuItemName}: ${attachment.documentName}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          MemberDetailActions.removeMemberUploadAttachments({
            body: {
              attachmentId: attachment.id,
              itemKey: this.itemKey,
              memberId: this.memberId,
              documentName: attachment.documentName,
            },
          }),
        );
      }
    });
  }

  onOpenPDF(document: any) {
    var pdfUrl = this.signatoryService.getUrl(document, (fileKey) => {
      this.fileService.signFile(fileKey).subscribe((sig) => {
        this.onOpenPDF({
          ...document,
          signature: sig,
        });
      });
    });

    if (pdfUrl) window.open(pdfUrl, '_blank');
  }

  private _isSpecialPage(): boolean {
    return (
      this.currentSectionLayout !== undefined &&
      [
        SectionLayout.StatusHistory,
        SectionLayout.PaymentHistory,
        SectionLayout.MunicipalityPayment,
        SectionLayout.MunicipalityServiceRecord,
      ].includes(this.currentSectionLayout)
    );
  }

  getCurrentMonthName() {
    const date = new Date();
    return date.toLocaleString('default', { month: 'long' });
  }

  scrollToTop(): void {
    const element = this.document.getElementById('scrollTopBanner');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openDropPopup() {
    this.closeBanner();
    const dialogRef = this.dialog.open(DropStatementPopupComponent, {
      panelClass: '',
      disableClose: true,
      data: {
        title: 'Generate DROP',
        memberId: this.memberId,
      },
      height: 'auto',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isHideBannerIcon = false;
        this.isHideCloseButton = false;
        const customMessage = 'Generated drop statement';
        showBanner.call(this, BannerType.Success, '', '', {
          customMessage,
        });
        this.scrollToTop();
      }
    });
  }

  closeBanner() {
    this.bannerType = BannerType.Hidden;
  }
}
