import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BannerIconConfig,
  BannerType,
} from '@ptg-shared/controls/banner/types/banner.model';
import { loadingIcon } from '../../constance/listIcons.const';

const BANNER_ICON_CONFIG: Record<string, BannerIconConfig> = {
  Success: {
    icon: 'check_circle',
    className: 'success',
  },
  Fail: {
    icon: 'error',
    className: 'fail',
  },
  Warning: {
    icon: 'warning',
    className: 'warning',
  },
  Info: {
    icon: 'info',
    className: 'info',
  },
};

@Component({
  selector: 'ptg-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnChanges {
  bannerIconConfig!: BannerIconConfig;

  @Input() bannerType: BannerType = BannerType.Hidden;
  @Input() message: string = '';
  @Input() isHideCloseButton = false;
  @Input() isHideBannerIcon = false;
  @Input() isLoadingInfo: boolean = false;
  @Input() style?: any;

  @Output() closeBanner = new EventEmitter();
  @Output() bannerTypeChange: EventEmitter<BannerType> = new EventEmitter();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      'loadingIcon',
      sanitizer.bypassSecurityTrustHtml(loadingIcon),
    );
  }

  ngOnInit(): void {}

  closeAlert() {
    this.closeBanner.emit();
    this.bannerTypeChange.emit(BannerType.Hidden);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message || changes.bannerType) {
      this.getBannerIconConfig();
    }
  }

  getBannerIconConfig(): void {
    this.bannerIconConfig = BANNER_ICON_CONFIG[this.bannerType];
  }
}
