<ng-container>
  <div class="section-info">
    <ng-container>
      <p class="title">
        <span>{{ configSection.sectionTitle }}</span>
        <ng-container *ngIf="isShowMenuButton">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Icon-button with a menu"
            class="group-button ignore-row"
          >
            <mat-icon class="ignore-row">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="!isList" (click)="editSection()">
              <mat-icon class="member-section-action-icon">edit</mat-icon>
              <span class="label-icon">Edit</span>
            </button>
            <button mat-menu-item *ngIf="isList" (click)="editSection()">
              <mat-icon class="member-section-action-icon">add</mat-icon>
              <span class="label-icon">Add New</span>
            </button>
            <button
              mat-menu-item
              (click)="manageSection()"
              *ngIf="
                dataSection &&
                ((isList && isDragDrop) ||
                  configSection.key === FixedSection.Status)
              "
            >
              <mat-icon class="member-section-action-icon"> list_alt </mat-icon>
              <span class="label-icon">Manage</span>
            </button>
            <button
              mat-menu-item
              *ngIf="
              configSection.hasAttachment &&
              configSection.type !== sectionLayout.List
              "
              (click)="showAttachments()"
            >
              <mat-icon class="member-section-action-icon">download</mat-icon>
              <span class="label-icon">Attachment</span>
            </button>
          </mat-menu>
        </ng-container>
      </p>
      <div class="demo-content" [ngClass]="{ 'can-paging': isList }">
        <!-- List -->
        <ng-container
          *ngIf="
            configSection.type === sectionLayout.List &&
            configSection.key !== FixedSection.ServiceHistory
          "
        >
          <ng-container *ngIf="columns.length > 0">
            <ptg-grid
              class="update-header"
              [data]="dataSection"
              [columns]="columns"
              [totalRecords]="lengthPg"
              [pageSize]="pageSize"
              [pageNumber]="pageNumber"
              (pageChange)="onChangePage($event)"
              (sortChange)="onSortChange($event)"
              [hideScrollbar]="false"
              [notFoundMessage]="notFoundMessage"
            >
              <ng-container
                *ngIf="
                  configSection.key === FixedSection.ParticipantRelationship
                "
              >
                <ng-template cellContent columnName="name" let-row>
                  <a
                    *ngIf="!!row.name; else defaultValue"
                    class="link"
                    href="javascript:void(0)"
                    (click)="navigateByUrl(row)"
                    >{{ row.name }}</a
                  >
                  <ng-template #defaultValue>
                    <span>{{ row.nameDefault || "" }}</span>
                  </ng-template>
                </ng-template>
              </ng-container>
              <ng-template cellContent columnName="noOfAttachments" let-row>
                <a class="attachment"
                (click)="showAttachments(row.rowId)"
                href="javascript:void(0)">{{ row.noOfAttachments }}</a>
              </ng-template>
            </ptg-grid>
          </ng-container>
          <ng-container
            *ngIf="
              profileOverviewConfig?.displayListSectionWOData ===
                DisplaySectionWODataType.TitleAndMessage && columns.length === 0
            "
          >
            <div class="data-not-found">No Data to Display</div>
          </ng-container>
        </ng-container>

        <!-- Municipality Service History -->
        <ng-container
          *ngIf="
            configSection.type === sectionLayout.List &&
            configSection.key === FixedSection.ServiceHistory
          "
        >
          <ng-container *ngIf="columns.length > 0">
            <ptg-grid
              class="update-header"
              [data]="dataSection"
              [columns]="columns"
              [totalRecords]="lengthPg"
              [pageSize]="pageSize"
              [pageNumber]="pageNumber"
              (pageChange)="onChangePage($event)"
              (sortChange)="onSortChange($event)"
              (rowClick)="onRowClick($event)"
              [hideScrollbar]="false"
              [notFoundMessage]="notFoundMessage"
            ></ptg-grid>
          </ng-container>
          <ng-container
            *ngIf="
              profileOverviewConfig?.displayListSectionWOData ===
                DisplaySectionWODataType.TitleAndMessage && columns.length === 0
            "
          >
            <div class="data-not-found">No Data to Display</div>
          </ng-container>
        </ng-container>

        <!-- Table -->
        <ng-container *ngIf="configSection.type === sectionLayout.Table">
          <ng-container
            *ngIf="
              (configSection.hasData && !configSection.isRemoved) ||
              profileOverviewConfig?.displayNonListSectionWOData ===
                DisplaySectionWODataType.TitleAndProperties_IfAny
            "
          >
            <ng-container *ngFor="let item of propertiesConfig; index as i">
              <div
                class="row-normal"
                *ngIf="
                  item?.value ||
                  item?.value === 0 ||
                  (!item?.value && configSection?.showPropertyDataless)
                "
                [ngClass]="{
                  'masked-value': item?.value && item?.config?.masked == 'true'
                }"
              >
                <span class="config-name truncate">{{ item?.name }}:</span>
                <div class="flex justify-start w-1/2 gap-2">
                  <ng-container [ngSwitch]="item.type">
                    <span class="value truncate" *ngSwitchCase="'Number'">
                      {{ (item?.value | number) || "-" }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Currency'">
                      {{ (item?.value | numberLocalDecimal : "$") || "-" }}
                    </span>
                    <span *ngSwitchCase="'Date'">
                      {{ (item?.value | date : "MM/dd/yyyy") || "-" }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Person_Name'">
                      {{ (item?.value | personName) || "-" }}
                    </span>
                    <div class="mw-50 value" *ngSwitchCase="'Address'">
                      <ptg-view-address
                        [value]="item?.value"
                        [options]="item.options"
                        customClass="text-end"
                      ></ptg-view-address>
                    </div>
                    <span class="value truncate" *ngSwitchCase="'Decimal'">
                      {{
                        (item?.value
                          | numberDecimal
                            : {
                                decimal: item.config?.fractionalLengthInput,
                                isCheckCurrentDecimal: true
                              }) || "-"
                      }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Whole_Number'">
                      {{ (item?.value | numberDecimal) || "-" }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Lookup'">
                      {{ (item?.value | propertyList : item.options) || "-" }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Binary'">
                      {{
                        !item?.value
                          ? "-"
                          : item?.value === "true"
                          ? item.config.affirmative
                          : item.config.negative
                      }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Percentage'">
                      {{
                        (item?.value | numberDecimal)
                          ? (item?.value | numberDecimal) + "%"
                          : "-"
                      }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Employer'">
                      {{ (item?.value | propertyList : item.options) || "-" }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Department'">
                      {{ (item?.value | propertyList : item.options) || "-" }}
                    </span>
                    <ng-container *ngSwitchCase="'RichText'">
                      <ptg-view-rich-text
                        [title]="item?.name"
                        [content]="item?.value"
                      ></ptg-view-rich-text>
                    </ng-container>
                    <span class="value truncate" *ngSwitchCase="'DateTime'">
                      {{ (item?.value | date : "MM/dd/yyyy hh:mm a") || "-" }}
                    </span>
                    <span class="value truncate" *ngSwitchCase="'Attachment'">
                      <a style="color: -webkit-link; cursor: pointer; text-decoration: underline;"
                        (click)="showAttachments()"
                        href="javascript:void(0)">{{ item?.value }}</a>
                    </span>
                    <span class="value truncate" *ngSwitchDefault>
                      {{ item?.value || "-" }}
                    </span>
                  </ng-container>
                  <ptg-masked-eye
                    *ngIf="item?.value && item?.config?.masked == 'true'"
                    page="member-profile-section"
                    [memberId]="memberId"
                    [propertyValue]="item"
                    [itemKey]="configSection.key"
                    [propertyKey]="item.key"
                    index="0"
                  ></ptg-masked-eye>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              (!configSection.hasData || configSection.isRemoved) &&
              profileOverviewConfig?.displayNonListSectionWOData ===
                DisplaySectionWODataType.TitleAndMessage
            "
          >
            <div class="data-not-found">No Data to Display</div>
          </ng-container>
        </ng-container>

        <!-- Column -->
        <ng-container *ngIf="configSection.type === sectionLayout.Column">
          <ng-container
            *ngIf="
              (configSection.hasData && !configSection.isRemoved) ||
              profileOverviewConfig?.displayNonListSectionWOData ===
                DisplaySectionWODataType.TitleAndProperties_IfAny
            "
          >
            <div class="column-section">
              <ng-container
                *ngFor="let property of propertiesConfig; let index = index"
              >
                <div
                  class="half-row"
                  *ngIf="
                    (!property?.value && configSection?.showPropertyDataless) ||
                    property?.value || property?.value === 0
                  "
                >
                  <div
                    class="value-container"
                    [ngClass]="{
                      'masked-value':
                        property?.value && property?.config?.masked == 'true'
                    }"
                  >
                    <span class="lable truncate">{{ property?.name }}</span>
                    <div class="value-item">
                      <ng-container [ngSwitch]="property?.type">
                        <span class="value truncate" *ngSwitchCase="'Currency'">
                          {{
                            (property?.value | numberLocalDecimal : "$") || "-"
                          }}
                        </span>
                        <span class="value truncate" *ngSwitchCase="'Date'">
                          {{ (property?.value | date : "MM/dd/yyyy") || "-" }}
                        </span>
                        <span
                          class="value truncate"
                          *ngSwitchCase="'Person_Name'"
                        >
                          {{ (property?.value | personName) || "-" }}
                        </span>
                        <div class="value w-100" *ngSwitchCase="'Address'">
                          <ptg-view-address
                            [value]="property?.value"
                            [options]="property.options"
                          ></ptg-view-address>
                        </div>
                        <span class="value truncate" *ngSwitchCase="'Decimal'">
                          {{
                            (property?.value
                              | numberDecimal
                                : {
                                    decimal:
                                      property?.config?.fractionalLengthInput,
                                    isCheckCurrentDecimal: true
                                  }) || "-"
                          }}
                        </span>
                        <span
                          class="value truncate"
                          *ngSwitchCase="'Whole_Number'"
                        >
                          {{ (property?.value | numberDecimal) || "-" }}
                        </span>
                        <span class="value truncate" *ngSwitchCase="'Lookup'">
                          {{
                            (property?.value
                              | propertyList : property.options) || "-"
                          }}
                        </span>
                        <span class="value truncate" *ngSwitchCase="'Binary'">
                          {{
                            !property?.value
                              ? "-"
                              : property?.value === "true"
                              ? property.config.affirmative
                              : property.config.negative
                          }}
                        </span>
                        <span
                          class="value truncate"
                          *ngSwitchCase="'Percentage'"
                        >
                          {{
                            (property?.value | numberDecimal)
                              ? (property?.value | numberDecimal) + "%"
                              : "-"
                          }}
                        </span>
                        <span class="value truncate" *ngSwitchCase="'Employer'">
                          {{
                            (property?.value
                              | propertyList : property.options) || "-"
                          }}
                        </span>
                        <span
                          class="value truncate"
                          *ngSwitchCase="'Department'"
                        >
                          {{
                            (property?.value
                              | propertyList : property.options) || "-"
                          }}
                        </span>
                        <ng-container *ngSwitchCase="'RichText'">
                          <ptg-view-rich-text
                            [title]="property?.name"
                            [content]="property?.value"
                          ></ptg-view-rich-text>
                        </ng-container>
                        <span class="value truncate" *ngSwitchCase="'DateTime'">
                          {{
                            (property?.value | date : "MM/dd/yyyy hh:mm a") ||
                              "-"
                          }}
                        </span>
                        <span
                          class="value truncate break-word"
                          *ngSwitchCase="'Email'"
                        >
                          {{ property?.value || "-" }}
                        </span>
                        <span
                          class="value truncate break-word"
                          *ngSwitchCase="'Attachment'"
                        >
                          <a style="color: -webkit-link; cursor: pointer; text-decoration: underline;"
                          (click)="showAttachments()"
                          href="javascript:void(0)">{{ property?.value }}</a>
                        </span>
                        <span class="value truncate" *ngSwitchDefault>
                          {{ property?.value || "-" }}
                        </span>
                      </ng-container>
                      <ptg-masked-eye
                        *ngIf="
                          property?.value && property?.config?.masked == 'true'
                        "
                        page="member-profile-section"
                        [memberId]="memberId"
                        [propertyValue]="property"
                        [itemKey]="configSection.key"
                        [propertyKey]="property.key"
                        index="0"
                      ></ptg-masked-eye>
                    </div>
                  </div>
                </div>
              </ng-container>

            </div>
          </ng-container>
          <ng-container
            *ngIf="
              (!configSection.hasData || configSection.isRemoved) &&
              profileOverviewConfig?.displayNonListSectionWOData ===
                DisplaySectionWODataType.TitleAndMessage
            "
          >
            <div class="data-not-found">No Data to Display</div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>


