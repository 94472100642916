<div class="add-aggregation">
  <div class="header-title">{{data?.isEdit ? 'Edit' : 'Add New'}} Aggregation</div>
  <form class="edit-form" [formGroup]="editForm">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('label')"
        placeholder="Label"
        [hasLabel]="true"
        [isRequired]="true"
        [maxLength]="100"
        errorAsync="Label already exists."
      ></ptg-textbox>

      <ptg-select
        *ngIf="!data?.isEdit"
        placeholder="Property"
        [controlField]="editForm.get('property')"
        [listData]="properties"
        errorRequire="Property is required."
        [isOptionObj]="true"
      ></ptg-select>

      <ptg-select
        *ngIf="!data?.isEdit"
        placeholder="Aggregate"
        [controlField]="editForm.get('aggregate')"
        [listData]="listAggregate"
        errorRequire="Aggregate is required."
        [isOptionObj]="true"
      ></ptg-select>
    </div>

    <div class="row-button">
      <ng-container *ngIf="!data?.isEdit">
        <button mat-raised-button type="button" class="primary" (click)="submitForm()">Create & Continue</button>
        <button mat-raised-button type="button" class="secondary" (click)="submitForm(true)">Create & Add Another</button>
      </ng-container>
      <ng-container *ngIf="data?.isEdit">
        <button mat-raised-button type="button" class="primary" (click)="submitForm()">Save</button>
      </ng-container>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>