import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { Option } from '@ptg-shared/controls/select/select.component';
import { BaseComponent } from '@ptg-shared/components';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { Row } from '@ptg-shared/controls/grid';

import { DefinedBenefitActions } from '../../actions';
import {
  AddBenefitTypeRequest,
  BenefitType,
  GetBenefitTypesRequest,
  MemberStatus,
  ParentBenefitType,
  SetBenefitTypeRequest,
} from '../../types/models';
import { DefinedBenefitService } from '../../services/defined-benefits.service';
import * as fromMember from '../../reducers';

@Component({
  selector: 'ptg-add-benefit-type',
  templateUrl: './add-benefit-type.component.html',
  styleUrls: ['./add-benefit-type.component.scss'],
})
export class AddBenefitTypeComponent extends BaseComponent {
  isEdit?: boolean;
  editForm?: FormGroup;
  eventOptions: Option[] = [];
  statuses: MemberStatus[] = [];
  statuseOptions: Option[] = [];
  benefitRecordOptions: Option[] = [];
  relationshipTypeOptions: Option[] = [];
  parentBenefitTypes: BenefitType[] = [];
  parentBenefitTypeOptions: Option[] = [];
  listParameter = new FormArray([]);
  benefitTypeHasParticipant?: boolean;

  get nameCtrl() {
    return this.editForm?.get('name') as FormControl;
  }
  get codeCtrl() {
    return this.editForm?.get('code') as FormControl;
  }
  get memberStatusIdCtrl() {
    return this.editForm?.get('memberStatusId') as FormControl;
  }
  get memberEventIdCtrl() {
    return this.editForm?.get('memberEventId') as FormControl;
  }
  get benefitRecordCtrl() {
    return this.editForm?.get('benefitRecord') as FormControl;
  }

  constructor(
    public fb: FormBuilder,
    public memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddBenefitTypeComponent>,
    public router: Router,
    public defineBenefitService: DefinedBenefitService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      benefitType: BenefitType & Row;
      isEdit?: boolean;
      benefitTypesData: (BenefitType & Row)[];
    }
  ) {
    super();
    this.isEdit = !!data?.isEdit;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dispatchActions();
    this.subscribeData();
    this.createForm();
  }

  private dispatchActions(): void {
    this.memberStore.dispatch(DefinedBenefitActions.getStatusAndEvents());
    this.memberStore.dispatch(DefinedBenefitActions.getBenefitRecords());
    if (!this.isEdit) {
    this.memberStore.dispatch(DefinedBenefitActions.getAllBenefitTypes({}));
    } else {
      let request: GetBenefitTypesRequest = {
        benefitTypeId: this.data?.benefitType?.id
      };
      this.memberStore.dispatch(DefinedBenefitActions.getAllBenefitTypes({request}));
    }
    this.memberStore.dispatch(DefinedBenefitActions.getLookupTableOptions());
  }

  private subscribeData(): void {
    combineLatest([
      this.memberStore.select(fromMember.selectGetStatusAndEventsState),
      this.memberStore.select(fromMember.selectGetBenefitRecordsState),
      this.memberStore.select(fromMember.selectGetAllBenefitTypesState),
      this.memberStore.select(fromMember.selecGetLookupTableOptionsState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([state1, state2, state3, state4]) => {
        if (!state1?.isLoading && state1?.success && state1?.payload?.length) {
          this.statuses = state1?.payload;
          this.statuseOptions = state1?.payload.map((item) => ({
            displayValue: item.name,
            value: item.id,
          }));

          this.eventOptions = (
            this.statuses.find(
              (status) => status.id === this.memberStatusIdCtrl?.value
            )?.memberEvent || []
          )
            .filter((memberEvent) => memberEvent.active)
            .map((memberEvent) => ({
              value: memberEvent.id,
              displayValue: memberEvent.name,
            }));
        }

        if (!state2?.isLoading && state2?.success && state2?.payload?.length) {
          this.benefitRecordOptions = state2.payload.map((item) => ({
            displayValue: item.name,
            value: item.key,
          }));
        }

        if (!state3?.isLoading && state3?.success && state3?.payload?.length) {
          this.parentBenefitTypes = state3.payload;
          this.parentBenefitTypeOptions = state3.payload.map((item) => ({
            displayValue: `${item.code} - ${item.name}`,
            value: item.id,
          }));
        }

        if (!state4?.isLoading && state4?.success && state4?.payload?.length) {
          this.relationshipTypeOptions = state4.payload.map((item) => ({
            displayValue: item.description,
            value: item.id,
          }));
        }
      });
  }

  private createForm(): void {
    this.benefitTypeHasParticipant =
      this.data?.benefitType?.totalParticipant >= 1;
    this.editForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: checkApiValidator(
          this.defineBenefitService.checkBenefitTypeExists,
          'name',
          this.data?.benefitType?.name || ''
        ),
      }),
      code: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(5)],
        asyncValidators: checkApiValidator(
          this.defineBenefitService.checkBenefitTypeExists,
          'code',
          this.data?.benefitType?.code || ''
        ),
      }),
      memberStatusId: new FormControl(
        { value: null, disabled: this.benefitTypeHasParticipant },
        [Validators.required]
      ),
      memberEventId: new FormControl(
        { value: null, disabled: this.benefitTypeHasParticipant },
        [Validators.required]
      ),
      benefitRecord: new FormControl(
        { value: null, disabled: this.benefitTypeHasParticipant },
        [Validators.required]
      ),
    });

    if (this.isEdit) {
      this.editForm?.setValue({
        name: this.data?.benefitType?.name,
        code: this.data?.benefitType?.code,
        memberStatusId: this.data?.benefitType?.memberStatusId,
        memberEventId: this.data?.benefitType?.memberEventId,
        benefitRecord: this.data?.benefitType?.sectionKey,
      });

      // Order by Benefit Type Code (by SRS)
      const parentTypes = this.data?.benefitType?.parentTypes
        ? [...this.data?.benefitType?.parentTypes]
        : [];

      parentTypes.sort((a, b) =>
        a.benefitTypeCode! > b.benefitTypeCode!
          ? 1
          : a.benefitTypeCode! < b.benefitTypeCode!
          ? -1
          : 0
      );
      parentTypes.forEach((item) => {
        this.listParameter.push(
          new FormGroup({
            parentType: new FormControl({
              value: item.benefitTypeId,
              disabled: this.benefitTypeHasParticipant,
            }),
            relationshipType: new FormControl({
              value: item.relationshipTypeId,
              disabled: this.benefitTypeHasParticipant,
            }),
            parentLabel: new FormControl({
              value: item.parentLabel,
              disabled: this.benefitTypeHasParticipant,
            }),
            childLabel: new FormControl({
              value: item.childLabel,
              disabled: this.benefitTypeHasParticipant,
            }),
            parentTypeEditable: new FormControl(
              !this.benefitTypeHasParticipant
            ),
          })
        );
      });
    }
  }

  onChangeStatus(): void {
    this.memberEventIdCtrl?.setValue(null);
    this.eventOptions = (
      this.statuses.find(
        (status) => status.id === this.memberStatusIdCtrl.value
      )?.memberEvent || []
    )
      .filter((memberEvent) => memberEvent.active)
      .map((memberEvent) => ({
        value: memberEvent.id,
        displayValue: memberEvent.name,
      }));
  }

  parentTypesIsValid(): boolean {
    return !this.listParameter
      .getRawValue()
      .some(
        (param) =>
          !param?.parentType ||
          !param?.relationshipType ||
          !param?.parentLabel ||
          !param?.childLabel
      );
  }

  onClickAddParentType(): void {
    this.listParameter.push(
      new FormGroup({
        parentType: new FormControl(''),
        relationshipType: new FormControl({ value: null, disabled: true }),
        parentLabel: new FormControl({ value: '', disabled: true }),
        childLabel: new FormControl({ value: '', disabled: true }),
        parentTypeEditable: new FormControl(true),
      })
    );
  }

  onClickRemoveParentType(index: number): void {
    this.listParameter.removeAt(index);
    this.listParameter.updateValueAndValidity();
  }

  onChangeParentType(rowControls: any): void {
    if (rowControls.controls['relationshipType']?.disabled)
      rowControls.controls['relationshipType']?.enable();
    if (rowControls.controls['parentLabel']?.disabled)
      rowControls.controls['parentLabel']?.enable();
    if (rowControls.controls['childLabel']?.disabled)
      rowControls.controls['childLabel']?.enable();
  }

  filterBenefitTypes(benefitTypeId: string): Option[] {
    const selectedBenefitTypes =
      this.listParameter?.controls?.map(
        (rowControls) => rowControls.get('parentType')?.value
      ) || [];
    if (!benefitTypeId) {
      return this.parentBenefitTypeOptions.filter(
        (item) =>
          !selectedBenefitTypes.some((s) => s === item.value) &&
          item.value !== this.data?.benefitType?.id
      );
    }

    return this.parentBenefitTypeOptions.filter(
      (item) =>
        (item.value === benefitTypeId ||
          !selectedBenefitTypes.some((s) => s === item.value)) &&
        item.value !== this.data?.benefitType?.id
    );
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onSubmit(): void {
    if (this.editForm?.pending || this.listParameter.pending) {
      let sub = this.editForm?.statusChanges.subscribe(() => {
        if (this.editForm?.valid && this.listParameter.valid) {
          this.saveBenefitType();
        }
        sub?.unsubscribe();
      });
    } else if (this.editForm?.valid && this.listParameter.valid) {
      this.saveBenefitType();
    }
  }

  saveBenefitType(): void {
    const formData = this.editForm?.getRawValue();

    if (!this.data?.isEdit) {
      let data: AddBenefitTypeRequest = {
        name: formData?.name,
        code: formData?.code,
        memberStatusId: formData?.memberStatusId,
        memberEventId: formData?.memberEventId,
        sectionKey: formData?.benefitRecord,
        parentTypes: this.getParentTypeParams(),
      };
      this.memberStore.dispatch(
        DefinedBenefitActions.addBenefitType({ request: data })
      );
    } else {
      let data: SetBenefitTypeRequest = {
        id: this.data?.benefitType.id,
        name: formData?.name,
        code: formData?.code,
        memberStatusId: formData?.memberStatusId,
        memberEventId: formData?.memberEventId,
        sectionKey: formData?.benefitRecord,
        parentTypes: this.getParentTypeParams(),
      };
      this.memberStore.dispatch(
        DefinedBenefitActions.updateBenefitType({ request: data })
      );
    }
    this.dialogRef.close();
  }

  private getParentTypeParams(): ParentBenefitType[] | undefined {
    let parentBenefitTypes: ParentBenefitType[] | undefined =
      this.listParameter?.value
        ?.filter((item: any) => item.parentType)
        ?.map((item: any) => {
          const benefitType = this.parentBenefitTypes.find(
            (benefitType) => benefitType.id === item.parentType
          );
          return {
            benefitTypeId: item.parentType,
            benefitTypeCode: benefitType?.code,
            relationshipTypeId: item.relationshipType,
            parentLabel: item.parentLabel,
            childLabel: item.childLabel,
          } as ParentBenefitType;
        }) as ParentBenefitType[];

    // Order by Benefit Type Code (by SRS)
    parentBenefitTypes?.sort((a, b) =>
      a.benefitTypeCode! > b.benefitTypeCode!
        ? 1
        : a.benefitTypeCode! < b.benefitTypeCode!
        ? -1
        : 0
    );

    return parentBenefitTypes;
  }
}
