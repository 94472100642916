import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  DISCARD_CONFIRM_MESSAGE,
  STATE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';

import { PaymentInforConfigCode } from '../../constance/next-payment.const';
import { PaymentInforConfigData } from '../../types/models';
import * as NextPaymentActions from '../../actions/next-payment.actions';
import * as fromNextPayment from '../../reducers';

@Component({
  selector: 'ptg-payment-information-configuration',
  templateUrl: './payment-information-configuration.component.html',
  styleUrls: ['./payment-information-configuration.component.scss'],
})
export class PaymentInformationConfigurationComponent
  implements OnInit, OnDestroy
{
  formGroup: FormGroup;
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Payment Information',
    },
    {
      name: 'Payment Information Configuration',
    },
  ];
  paymentInforConfig: PaymentInforConfigData[] = [];
  isSaveSuccess: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sectionType: string;
      sectionId: string;
      memberId: string;
      menuItemName: string;
    },
    public dialogRef: MatDialogRef<PaymentInformationConfigurationComponent>,
    private dialog: MatDialog,
    private store: Store
  ) {
    this.formGroup = new FormGroup({});
  }

  ngOnInit(): void {
    this.store.dispatch(NextPaymentActions.getPaymentInforConfig());
    this.store
      .pipe(select(fromNextPayment.selectPaymentInforConfigState))
      .subscribe((paymentInforConfig) => {
        if (paymentInforConfig.length > 0) {
          this.paymentInforConfig = paymentInforConfig;
          this.paymentInforConfig.forEach((ele) => {
            this.formGroup.setControl(ele.code, new FormControl(ele.isDisplay));
            if (ele.children.length > 0) {
              ele.children.forEach((child) => {
                this.formGroup.setControl(
                  child.code,
                  new FormControl(child.isDisplay)
                );
              });
            }
          });
        }
      });
    this.store
      .pipe(select(fromNextPayment.selectSavePaymentInforConfigState))
      .subscribe((state) => {
        if (state === STATE.SUCCESS) {
          this.isSaveSuccess = true;
        }

        if (state === STATE.FAIL) {
          this.paymentInforConfig.forEach((ele) => {
            this.formGroup.get(ele.code)?.setValue(ele.isDisplay);
            if (ele.children.length > 0) {
              ele.children.forEach((child) => {
                this.formGroup.get(child.code)?.setValue(child.isDisplay);
              });
            }
          });
        }
        this.store.dispatch(NextPaymentActions.clearNextPayment());
      });

    if (this.data) {
      this.listBreadcrumbs[0] = {
        name: this.data?.menuItemName,
      };
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    let paymentInforConfigData = this.paymentInforConfig.map((item) => {
      return {
        id: item.id,
        isDisplay: this.formGroup.get(item.code)?.value,
        children: item.children.map((ele) => {
          return {
            id: ele.id,
            isDisplay: this.formGroup.get(ele.code)?.value,
          };
        }),
      };
    });
    this.store.dispatch(
      NextPaymentActions.savePaymentInforConfig({
        paymentInforConfig: paymentInforConfigData,
      })
    );
    this.dialogRef.close();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.isSaveSuccess) {
          this.store.dispatch(NextPaymentActions.getPaymentInforConfig());
        }
        this.dialogRef.close();
      }
    });
  }

  changeToggle(value: any) {
    if (!value) {
      if (
        this.formGroup.get(PaymentInforConfigCode.EARNINGS)?.value === value
      ) {
        this.formGroup
          .get(PaymentInforConfigCode.EARNINGSYEARTODATE)
          ?.setValue(value);
        this.formGroup
          .get(PaymentInforConfigCode.EARNINGITEMS)
          ?.setValue(value);
      } else {
        if (
          this.formGroup.get(PaymentInforConfigCode.DEDUCTIONS)?.value === value
        ) {
          this.formGroup
            .get(PaymentInforConfigCode.DEDUCTIONSYEARTODATE)
            ?.setValue(value);
        }
      }
    }
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }
}
