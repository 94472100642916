<div class="date-time-container">
  <ptg-datepicker (changeValue)="changeDateTime()"
                  [controlField]="editForm.get('date')"
                  [isRequired]="isRequired || (!isRequired && editForm.get('time')?.value)"
                  [minDate]="minDate"
                  [placeholder]="datePlaceholder">
  </ptg-datepicker>
  <ptg-timepicker (timeChanged)="changeDateTime()"
                  [controlField]="editForm.get('time')"
                  [isRequired]="isRequired"
                  [placeholder]="timePlaceholder">
  </ptg-timepicker>
  <mat-error *ngIf="!editForm.get('date')?.errors && !editForm.get('time')?.errors && controlField?.errors?.[customError] && !controlField?.errors?.matDatepickerParse && !controlField?.errors?.required">
    {{ controlField.getError([customError]) }}
  </mat-error>

</div>
