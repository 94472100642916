<div class="dialog-container">
  <div class="header-title">
    {{data?.aggregationGroup?.id ? 'Edit' : 'Add New'}} Aggregation Group
  </div>
  <form class="edit-form" [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('groupName')"
        placeholder="Group Label"
        [maxLength]="100"
        [hasLabel]="true"
        [isRequired]="true"
        errorAsync="Group Label already exists."
      ></ptg-textbox>
      <ptg-gridview_transferring
        [configColums]="configColums"
        [dataTableLeft]="availableAggregationsTable"
        [dataTableRight]="selectedAggregationsTable"
        [labelLeft]="'Avaiable Aggregations'"
        [labelRight]="'Selected Aggregations'"
        (onDropDragAction)="changeSelectedAggregations($event)"
      ></ptg-gridview_transferring>
    </div>
    <div class="row-button">
      <button mat-raised-button type="submit" [disabled]="selectedAggregations.length === 0" class="primary">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
