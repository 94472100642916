export const VERTICAL_LINE_SEPARATOR: string = '|';
export const COMMA_SEPARATOR: string = ', ';

export enum AppKey {
  PensionEmployer = 'PensionEmployer',
  PensionAdmin = 'PensionAdmin',
}
export enum DomainType {
  EmployerPortal = 'EmployerPortal',
  AdminPortal = 'AdminPortal',
}
