<div class="member-detail-display">
  <div class="detail-header">
    <span class="title">{{ "Selected " }}{{ menuItemName }} </span>

    <div class="space-line"></div>

    <div class="edit-btn" (click)="edit()">
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>

    <div class="edit-btn" (click)="remove()">
      <mat-icon>delete_forever</mat-icon>
      <span class="title-edit"> Remove</span>
    </div>
  </div>

  <div class="detail-content">
    <div
      class="first-row flex"
      *ngFor="let item of listValue"
      [ngStyle]="{ width: metadataSection?.hasAttachment ? '45%' : '20%' }"
    >
      <div class="item">
        <label>{{ item?.title }}</label>
        <p class="value flex">
          <ng-container [ngSwitch]="item.type">
            <span *ngSwitchCase="'Currency'">
              {{ (item.value | numberLocalDecimal : "$") || "-" }}
            </span>
            <span *ngSwitchCase="'Date'">
              {{ (item.value | date : "MM/dd/yyyy") || "-" }}
            </span>
            <span *ngSwitchCase="'Person_Name'">
              {{ (item.value | personName : item.options) || "-" }}
            </span>
            <div class="value w-100" *ngSwitchCase="'Address'">
              <ptg-view-address
                [value]="item?.value"
                [options]="item.options"
              ></ptg-view-address>
            </div>
            <span *ngSwitchCase="'Decimal'">
              {{
                (item.value
                  | numberDecimal
                    : {
                        decimal: item?.config?.fractionalLengthInput,
                        isCheckCurrentDecimal: true
                      }) || "-"
              }}
            </span>
            <span *ngSwitchCase="'Whole_Number'">
              {{ (item.value | numberDecimal) || "-" }}
            </span>
            <span *ngSwitchCase="'Lookup'">{{ item.value || "-" }}</span>
            <span *ngSwitchCase="'Binary'">{{ item.value || "-" }}</span>
            <span *ngSwitchCase="'Percentage'">
              {{ (item.value | numberLocalDecimal : "") || "-" }}
              {{ (item.value | numberLocalDecimal : "") ? "%" : "" }}
            </span>
            <span *ngSwitchCase="'Employer'">{{ item.value || "-" }}</span>
            <ng-container *ngSwitchCase="'RichText'">
              <ptg-view-rich-text
                [title]="item?.title"
                [content]="item.value"
                [defaultContent]="'-'"
              ></ptg-view-rich-text>
            </ng-container>
            <span *ngSwitchCase="'DateTime'">
              {{ (item?.value | date : "MM/dd/yyyy hh:mm a") || "-" }}
            </span>
            <span *ngSwitchDefault>
              <span *ngIf="item.key !== 'last4Ssn'; else ssn">
                {{ item.value || "-" }}
              </span>
              <ng-template #ssn>
                <span style="color: #b1b1b1">XXX-XX</span>
                <span>-{{ item.value }}</span>
              </ng-template>
            </span>
          </ng-container>

          <ptg-masked-eye
            *ngIf="item.value && item?.config?.masked == 'true'"
            [page]="'member-navigation' + menuItemName"
            [memberId]="memberId"
            [propertyValue]="item"
            [itemKey]="metadataSection?.key || ''"
            [propertyKey]="item?.key"
            [index]="currentRow?.index"
          ></ptg-masked-eye>
        </p>
      </div>
    </div>
  </div>
</div>
