import {
  FilterPropertyType,
  Operator,
  OPERATOR_BY_TYPE,
} from '../../constance/member-list.const';
import { OptionValue } from './metadata.model';

export interface MemberListQuery {
  pageIndex: number;
  totalPerPage: number;
  sortField?: string;
  sortType?: number;
  listFilterInfo?: FilterInfo[];
}

export interface MemberList {
  total: number;
  members: Record<string, unknown>[];
  columns: ColumnConfig[];
  message: string;
  success?: boolean;
  error?: any;
}

export interface ColumnConfig {
  columnName: string;
  columnKey: string;
  type: string;
  configValue: any;
}

export interface MemberFilter {
  sectionKey: string;
  itemKey?: string;
  propertyKey: string;
  operator: Operator;
  type: number;
  values: string[] | number[];
  propertyName?: string;
  options?: string;
  selectedPropertyItem?: string;
  event?: {
    operator: Operator;
    options: string;
    values: string[] | number[];
  };
  date?: {
    operator: Operator;
    options: string;
    values: string[] | number[];
  };
  optionValues?: OptionValue[];
}

export interface ListSectionProperty {
  listSectionProperty: FilterByPropertyConfig[];
}

export interface FilterByPropertyConfig {
  propertyName: string;
  propertyKey: string;
  sectionKey: string;
  sectionName: string;
  options: { key: string; value: string }[];
  propertyType: FilterPropertyType;
  propertyTypeLabel?: string;
  headerType: number;
  aggregationId?: string;
  aggregationName?: string;
  aggregationType?: number;
  isRequired?: boolean;
  calculationId?: string;
  calculationName?: string;
}

export interface FilterInfo {
  propertyName: string;
  propertyKey: string;
  itemKey: string;
  sectionName: string;
  options: string;
  type: FilterPropertyType;
  values: string[] | number[];
  operator: Operator;
}

export interface ExportFileRequest {
  fileName: string;
  listFilter: FilterInfo[];
  pageIndex: number;
  totalPerPage: number;
  sortField?: string;
  sortType?: number;
}

export type PropertyItem = keyof typeof OPERATOR_BY_TYPE;

export type FilterInputType =
  | 'Text'
  | 'Number'
  | 'Date'
  | 'Boolean'
  | 'DateTime';

export interface MemberFilterModal {
  clientId?: string;
  editMode?: boolean;
  id: string;
  listFilterInfo?: MemberFilter[];
  name: string;
}
	