import { createAction, props } from '@ngrx/store';

import { GetPaymentHistories, PaymentInfor } from '../types/models';

export const PaymentNoneFinalizedRequest =
  '[PaymentNoneFinalized/API] Send Request';
export const PaymentNoneFinalizedSuccess = '[PaymentNoneFinalized/API] Success';
export const PaymentNoneFinalizedFailure = '[PaymentNoneFinalized/API] Failure';

export const GetListPaymentRequest = '[GetListPaymentDetail/API] Send Request';
export const GetListPaymentSuccess = '[GetListPaymentDetail/API] Success';
export const GetListPaymentFailure = '[GetListPaymentDetail/API] Failure';

export const getPaymentNoneFinalized = createAction(
  PaymentNoneFinalizedRequest
);
export const getPaymentNoneFinalizedSuccess = createAction(
  PaymentNoneFinalizedSuccess,
  props<{ paymentList: PaymentInfor[] }>()
);
export const getPaymentNoneFinalizedFailure = createAction(
  PaymentNoneFinalizedFailure,
  props<{ error?: any }>()
);

export const getListPayment = createAction(
  GetListPaymentRequest,
  props<{ query: any }>()
);
export const getListPaymentSuccess = createAction(
  GetListPaymentSuccess,
  props<{ listPaymentDetail: GetPaymentHistories }>()
);
export const getListPaymentFailure = createAction(
  GetListPaymentFailure,
  props<{ error?: any }>()
);
