import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FundType } from '@ptg-shared/types/enums';
import * as fromReducer from '@ptg-reducers/index';
import { chicagoParkDepositDate } from '@ptg-processing/constants/next-payroll.const';

import {
  OffCyclePaymentStatusType,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import { OffCyclePayment, PaymentInforConfigObj } from '../../types/models';
import * as fromNextPayment from '../../reducers';
import * as NextPaymentActions from '../../actions/next-payment.actions';
import { EditEarningsComponent } from '../edit-earnings/edit-earnings.component';

@Component({
  selector: 'ptg-earning-detail',
  templateUrl: './earning-detail.component.html',
  styleUrls: ['./earning-detail.component.scss'],
})
export class EarningDetailComponent implements OnInit, OnDestroy {
  readonly PaymentInfoTypeEnum = PaymentInfoType;
  readonly OffCyclePaymentStatusTypeEnum = OffCyclePaymentStatusType;

  earningsInfo: any;
  earnings: any[] = [];
  displayedColumns = ['name', 'currentValue', 'YTDValue'];
  displayedStaticColumn = ['name', 'currentValue', 'YTDValue'];
  hasManualAdjusted: boolean = false;
  offCyclePayment?: OffCyclePayment;

  unsubscribe$ = new Subject<void>();
  showEarning: boolean = true;
  isChicagoPark: boolean = false;

  @Input() isHistory?: boolean;
  @Input() paymentInforConfig!: PaymentInforConfigObj;
  @Output() changeBannerEvent = new EventEmitter();

  constructor(
    private store: Store<fromNextPayment.State>,
    private dialog: MatDialog,
    private storeFund: Store<fromReducer.State>
  ) {}

  ngOnInit(): void {
    this.store
      .select(fromNextPayment.selectNextPayment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) this.offCyclePayment = data.offCyclePayment;
        let depositDate = data.depositDate
          ? new Date(data.depositDate)
          : new Date();
        this.showEarning =
          this.isChicagoPark && this.isHistory
            ? depositDate >= chicagoParkDepositDate
            : true;
      });

    this.store
      .select(fromNextPayment.selectNextPaymentEarnings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.earningsInfo = state;
          this.earnings = this.earningsInfo.earnings;
          this.hasManualAdjusted = this.earnings?.some(
            (item: any) => item.manualAdjustmented
          );
        }
      });

    this.storeFund
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.isChicagoPark = el?.fundType === FundType.ChicagoParks;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit() {
    const dialogRef = this.dialog.open(EditEarningsComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '800px',
      data: {
        ...this.earningsInfo,
        offCyclePayment: this.offCyclePayment,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.changeBannerEvent.emit();
      if (result)
        this.store.dispatch(
          NextPaymentActions.updateEarnings({
            body: result,
            targetId: this.offCyclePayment?.id,
          })
        );
    });
  }
}
