<div class="upload-lookup-table-dialog" id="upload-lookup-table-dialog">
  <ptg-dialog-header
    header="Lookup Tables"
    prefix="Upload">
  </ptg-dialog-header>
  <div class="upload-file" *ngIf="!showTransitionDialog; else transitionDialog">
    <button mat-raised-button type="button" (click)="onCancel()">Cancel</button>
    <ptg-upload
      (onUploadAction)="uploadFile($event)"
      [checkPattern]="checkPattern"
      [errMsg]="'Unsupported file name or file format.'"
      [typeFile]="supportedFileTypes"
      [accept]="acceptFile"
    ></ptg-upload>
    <div class="info-box">
      <mat-icon>info</mat-icon>
      <p>
        Table Name and Code value are unique while Description value could be duplicated.
        If uploaded tables exist, system will update existing tables.
        If uploaded codes exist, system will update their descriptions.
      </p>
    </div>
  </div>

  <ng-template #transitionDialog>
    <div class="transition-dialog-container">
      <div class="progress-banner">
        <mat-icon>info</mat-icon>
        <span>{{ uploadStatusMessage }}</span>
      </div>
      <div class="import-information">
        <div class="title">File:</div>
        <div class="value">{{ fileName }}</div>
      </div>
      <div class="import-information">
        <div class="title">Progress:</div>
        <mat-progress-bar class="progress-custom"
                          mode="indeterminate">
        </mat-progress-bar>
        <button mat-icon-button aria-label="close icon" (click)="cancelUpload()" class="x-button" *ngIf="sessionDetail?.status !== UPLOAD_STATUS.IMPORTING">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>
</div>
