import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'ptg-input-area',
  templateUrl: './input-area.component.html',
  styleUrls: ['./input-area.component.scss']
})
export class InputAreaComponent implements OnInit {

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() maxLength?: number;
  @Input() errorRequire?: string;
  @Input() errorMaxLength?: string;
  @Input() minRows?: number = 5;
  @Input() maxRows?: number;
  @Input() hasLabel?: boolean = true;
  @Input() isRequired?: boolean;
  constructor() { }

  ngOnInit(): void {
    if (this.isRequired) {
      this.controlField.addValidators(Validators.required);
      this.errorRequire = `${this.placeholder} is required.`;
    }
  }

  
  trimValue() {
    if (this.controlField.value) {
      this.controlField.setValue(this.controlField.value.trim());
    }
  }

}
