import { createAction, props } from '@ngrx/store';

import {
  ParticipantSearchConfigurations,
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
} from '../types/models';

export const ClearParticipantSearchConfiguration =
  '[ParticipantSearchConfiguration] Clear';
export const GetParticipantSearchPropertyListRequest =
  '[GetParticipantSearchPropertyList/API] Send Request';
export const GetParticipantSearchPropertyListSuccess =
  '[GetParticipantSearchPropertyList/API] Success';
export const GetParticipantSearchPropertyListFailure =
  '[GetParticipantSearchPropertyList/API] Failure';
export const GetParticipantSearchConfigurationRequest =
  '[GetParticipantSearchConfiguration/API] Send Request';
export const GetParticipantSearchConfigurationSuccess =
  '[GetParticipantSearchConfiguration/API] Success';
export const GetParticipantSearchConfigurationFailure =
  '[GetParticipantSearchConfiguration/API] Failure';
export const SetParticipantSearchConfigurationRequest =
  '[SetParticipantSearchConfiguration/API] Send Request';
export const SetParticipantSearchConfigurationSuccess =
  '[SetParticipantSearchConfiguration/API] Success';
export const SetParticipantSearchConfigurationFailure =
  '[SetParticipantSearchConfiguration/API] Failure';

export const clearParticipantSearchConfigurationState = createAction(
  ClearParticipantSearchConfiguration
);
export const getParticipantSearchPropertyList = createAction(
  GetParticipantSearchPropertyListRequest
);
export const getParticipantSearchPropertyListSuccess = createAction(
  GetParticipantSearchPropertyListSuccess,
  props<{ propertyConfigs: PropertyDisplayConfig[] }>()
);
export const getParticipantSearchPropertyListFailure = createAction(
  GetParticipantSearchPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const getParticipantSearchConfiguration = createAction(
  GetParticipantSearchConfigurationRequest
);
export const getParticipantSearchConfigurationSuccess = createAction(
  GetParticipantSearchConfigurationSuccess,
  props<{ propertyDisplayConfigurations: PropertyDisplayConfiguration[] }>()
);
export const getParticipantSearchConfigurationFailure = createAction(
  GetParticipantSearchConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const setParticipantSearchConfiguration = createAction(
  SetParticipantSearchConfigurationRequest,
  props<{ body: ParticipantSearchConfigurations }>()
);
export const setParticipantSearchConfigurationSuccess = createAction(
  SetParticipantSearchConfigurationSuccess
);
export const setParticipantSearchConfigurationFailure = createAction(
  SetParticipantSearchConfigurationFailure,
  props<{ errorMsg: string }>()
);
