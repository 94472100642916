import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptg-group-aggregation',
  templateUrl: './group-aggregation.component.html',
  styleUrls: ['./group-aggregation.component.scss'],
})
export class GroupAggregationComponent {
  @Input() aggregationDetails: any = [];
}
