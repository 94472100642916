import {
  AfterViewInit,
  Injectable,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

// This class is open to common implementation
// Please discuss with your team before implementing
@Injectable()
export abstract class BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
