import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/models';

import * as Info1099Actions from '../actions/info-1099.actions';
import {
  Info1099ConfigData,
  Info1099RecordResponse,
} from '../types/models';

export const info1099FeatureKey = 'info1099';

export interface State {
  isLoading: boolean;
  info1099Config: Info1099ConfigData[];
  info1099Record?: BaseActionState<Info1099RecordResponse>
}

const initialState: State = {
  isLoading: true,
  info1099Config: [],
  info1099Record: {
    isLoading: false,
  },
};

export const reducer = createReducer(
  initialState,
  on(Info1099Actions.getInfo1099Record, (state) => ({
    ...state,
    info1099Record: {
      isLoading: true,
      total: 0,
    },
  })),
  on(
    Info1099Actions.getInfo1099RecordSuccess,
    (state, { response }) => ({
      ...state,
      info1099Record: {
        isLoading: false,
        total: response.total,
        success: true,
        payload: response,
      },
      isLoading: false,
    })
  ),
  on(Info1099Actions.getInfo1099RecordFailure, (state, { error }) => ({
    ...state,
    info1099Record: {
      isLoading: false,
      total: 0,
      success: false,
      error: error,
    },
  })),
  on(Info1099Actions.clearGetInfo1099RecordState, (state) => ({
    ...state,
    info1099Record: undefined,
  })),

  on(
    Info1099Actions.getInfo1099ConfigSuccess,
    (state, { info1099ConfigData }) => ({
      ...state,
      info1099Config: info1099ConfigData,
    })
  ),
  on(Info1099Actions.getInfo1099ConfigFailure, (state) => ({
    ...state,
    info1099Config: [],
  })),
);
