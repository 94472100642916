import { createAction, props } from '@ngrx/store';

import {
  AggregationGroupBody,
  AggregationGroup,
  AggregationGroupBodyChangeKey,
} from '../types/models';

export const ClearAggregationGroup = '[AggregationGroup] Clear';

export const AggregationGroupListRequest =
  '[AggregationGroupList/API] Send Request';
export const AggregationGroupListFailure = '[AggregationGroupList/API] Failure';
export const AggregationGroupListSuccess = '[AggregationGroupList/API] Success';

export const AggregationGroupOrder = '[AggregationGroupOrder/API] Send Request';

export const AddAggregationGroupRequest =
  '[AddAggregationGroup/API] Send Request';
export const AddAggregationGroupSuccess = '[AddAggregationGroup/API] Success';
export const AddAggregationGroupFailure = '[AddAggregationGroup/API] Failure';

export const UpdateAggregationGroupRequest =
  '[UpdateAggregationGroup/API] Send Request';
export const UpdateAggregationGroupSuccess =
  '[UpdateAggregationGroup/API] Success';
export const UpdateAggregationGroupFailure =
  '[UpdateAggregationGroup/API] Failure';

export const RemoveAggregationGroupRequest =
  '[RemoveAggregationGroup/API] Send Request';
export const RemoveAggregationGroupSuccess =
  '[RemoveAggregationGroup/API] Success';
export const RemoveAggregationGroupFailure =
  '[RemoveAggregationGroup/API] Failure';

export const getAggregationGroupList = createAction(
  AggregationGroupListRequest
);
export const getAggregationGroupSuccess = createAction(
  AggregationGroupListSuccess,
  props<{ aggregationGroup: AggregationGroup[] }>()
);
export const getAggregationGroupListFailure = createAction(
  AggregationGroupListFailure,
  props<{ error?: any }>()
);
export const clearAggregationGroup = createAction(ClearAggregationGroup);
export const orderTable = createAction(
  AggregationGroupOrder,
  props<{ body: AggregationGroupBodyChangeKey }>()
);

export const addAggregationGroup = createAction(
  AddAggregationGroupRequest,
  props<{
    body: AggregationGroupBody;
  }>()
);
export const addAggregationGroupSuccess = createAction(
  AddAggregationGroupSuccess
);
export const addAggregationGroupFailure = createAction(
  AddAggregationGroupFailure,
  props<{ errorMsg: string }>()
);

export const updateAggregationGroup = createAction(
  UpdateAggregationGroupRequest,
  props<{
    id: string;
    body: AggregationGroupBody;
  }>()
);
export const updateAggregationGroupSuccess = createAction(
  UpdateAggregationGroupSuccess
);
export const updateAggregationGroupFailure = createAction(
  UpdateAggregationGroupFailure,
  props<{ errorMsg: string }>()
);

export const removeAggregationGroup = createAction(
  RemoveAggregationGroupRequest,
  props<{ id: string }>()
);
export const removeAggregationGroupSuccess = createAction(
  RemoveAggregationGroupSuccess
);
export const removeAggregationGroupFailure = createAction(
  RemoveAggregationGroupFailure,
  props<{ errorMsg: string }>()
);

export const getAggregationGroupDetailsAction = createAction(
  '[AggregationGroupDetails/API] Send Request',
  props<{ body: any }>()
);
export const getAggregationGroupDetailsSuccessAction = createAction(
  '[AggregationGroupDetails/API] Success',
  props<{ response: any }>()
);
export const getAggregationGroupDetailsFailureAction = createAction(
  '[AggregationGroupDetails/API] Failure',
  props<{ error?: any }>()
);
