import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import {
  ACTION,
  CANCEL_CONFIRM_MESSAGE,
  STATE,
} from '@ptg-shared/constance/value.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  OutsideReorderInfo,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { AbstractControlStatus } from '@ptg-shared/models/common.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import * as fromReducer from '@ptg-reducers';

import { ColumnIndex, NonListPropertyType } from '../../types/enums';
import { NonListItemConfig, NonListProperty } from '../../types/models';
import { NonListConfigurationActions } from '../../actions';
import * as fromMember from '../../reducers';
import {
  clearGetMetadataNonListSectionsStateAction,
  clearGetMetadataSectionsStateAction,
} from '../../actions/member-detail.actions';

@Component({
  selector: 'ptg-non-list-configuration',
  templateUrl: './non-list-configuration.component.html',
  styleUrls: ['./non-list-configuration.component.scss'],
})
export class NonListConfigurationComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly PropertyConfigType = NonListPropertyType;

  sectionKey: string = '';
  menuItemId: string = '';
  menuItemName: string = '';
  listBreadcrumbs: Breadcrumb[] = [];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  private _defaultAddPropertyFormData = {
    nonListPropertyType: NonListPropertyType.Property,
    nonListProperty: '',
    label: '',
    toColumn: ColumnIndex.Column1,
  };
  isChanged: boolean = false;

  nonListPropertyTypeOptions: RadioOption[] = [
    {
      label: 'Property',
      value: NonListPropertyType.Property,
    },
    {
      label: 'Aggregation',
      value: NonListPropertyType.Aggregation,
    },
    {
      label: 'Calculation',
      value: NonListPropertyType.Calculation,
    },
    {
      label: 'Blank Space',
      value: NonListPropertyType.BlankSpace,
    },
  ];

  // Property name selection
  nonListProperties: NonListProperty[] = [];
  availableNonListPropertyOptions: Option[] = [];

  toColumnOptions: Option[] = [
    {
      displayValue: '1',
      value: ColumnIndex.Column1,
    },
    {
      displayValue: '2',
      value: ColumnIndex.Column2,
    },
    {
      displayValue: '3',
      value: ColumnIndex.Column3,
    },
    {
      displayValue: '4',
      value: ColumnIndex.Column4,
    },
  ];

  nonListPropertyTypeCtrl: FormControl = new FormControl(
    this._defaultAddPropertyFormData.nonListPropertyType
  );
  nonListPropertyCtrl: FormControl = new FormControl(
    this._defaultAddPropertyFormData.nonListProperty
  );
  labelCtrl: FormControl = new FormControl(
    this._defaultAddPropertyFormData.label,
    [this._checkLabelDuplicated()]
  );
  toColumnCtrl: FormControl = new FormControl(
    this._defaultAddPropertyFormData.toColumn
  );

  isLoadingGetNonListProperties: boolean = true;
  isLoadingGetNonListItemConfigs: boolean = true;

  columns: Column[] = [
    {
      name: 'label',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(150),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength}-character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      width: '50px',
      align: Align.Right,
    },
  ];
  column1Data: (NonListItemConfig & Row)[] = [];
  column2Data: (NonListItemConfig & Row)[] = [];
  column3Data: (NonListItemConfig & Row)[] = [];
  column4Data: (NonListItemConfig & Row)[] = [];
  get column1DataGrid(): (NonListItemConfig & Row)[] {
    return this.gridColumn1?.dataSource ?? [];
  }
  get column2DataGrid(): (NonListItemConfig & Row)[] {
    return this.gridColumn2?.dataSource ?? [];
  }
  get column3DataGrid(): (NonListItemConfig & Row)[] {
    return this.gridColumn3?.dataSource ?? [];
  }
  get column4DataGrid(): (NonListItemConfig & Row)[] {
    return this.gridColumn4?.dataSource ?? [];
  }

  isSaving: boolean = false;

  @ViewChild('gridColumn1') gridColumn1?: GridComponent<NonListItemConfig>;
  @ViewChild('gridColumn2') gridColumn2?: GridComponent<NonListItemConfig>;
  @ViewChild('gridColumn3') gridColumn3?: GridComponent<NonListItemConfig>;
  @ViewChild('gridColumn4') gridColumn4?: GridComponent<NonListItemConfig>;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store.dispatch(LayoutActions.hiddenSideMenu());

    // Listen for URL parameters and the current menu info
    combineLatest([
      this.route.params,
      this.store.select(fromLayoutReducer.getMemberNavigationListSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, state]) => {
        if (
          !params?.menuId ||
          !params?.memberId ||
          !state?.payload?.memberNavigationList?.memberNavigationMenu?.length
        )
          return;

        const currentNavigationMenu =
          state.payload.memberNavigationList.memberNavigationMenu.find((x) =>
            x.menuItem.some((y) => y.id === params.menuId)
          );
        if (!currentNavigationMenu?.menuItem?.length) return;

        const memberNavigationItem = currentNavigationMenu.menuItem.find(
          (navigation) => navigation.id === params.menuId
        );
        if (!memberNavigationItem) return;

        this.sectionKey = memberNavigationItem.itemKey;
        this.menuItemId = params.menuId;
        this.menuItemName = memberNavigationItem.name ?? '';
        this.listBreadcrumbs = [
          {
            name: memberNavigationItem.name ?? '',
            url: `/member/navigation/${params.sectionType}/${params.menuId}/${params.memberId}`,
          },
          {
            name: `${memberNavigationItem.name ?? ''} Configuration`,
            url: '',
          },
        ];

        this.memberStore.dispatch(
          NonListConfigurationActions.getNonListProperties({
            sectionKey: this.sectionKey,
          })
        );
        this.memberStore.dispatch(
          NonListConfigurationActions.getNonListItemConfigs({
            navigationItemId: this.menuItemId,
          })
        );
      });

    // Listen for get the list of properties and configurations
    combineLatest([
      this.store.select(fromMember.selectGetNonListPropertiesState),
      this.store.select(fromMember.selectGetNonListItemConfigsState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([state1, state2]) => {
        if (state1?.isLoading !== undefined) {
          this.isLoadingGetNonListProperties = state1.isLoading;
        }
        if (state2?.isLoading !== undefined) {
          this.isLoadingGetNonListItemConfigs = state2.isLoading;
        }

        if (state1?.success && !state1.isLoading && state1?.payload) {
          this.nonListProperties = state1?.payload;
        }

        if (state2?.success && !state2.isLoading && state2?.payload) {
          this.column1Data = this._filterNonListItemConfig(
            state2?.payload,
            ColumnIndex.Column1
          );
          this.column2Data = this._filterNonListItemConfig(
            state2?.payload,
            ColumnIndex.Column2
          );
          this.column3Data = this._filterNonListItemConfig(
            state2?.payload,
            ColumnIndex.Column3
          );
          this.column4Data = this._filterNonListItemConfig(
            state2?.payload,
            ColumnIndex.Column4
          );
        }

        // Refresh the non-list property options when finished
        if (
          !this.isLoadingGetNonListProperties &&
          !this.isLoadingGetNonListItemConfigs
        ) {
          this._refreshNonListPropertyOptions(
            this.nonListPropertyTypeCtrl.value
          );
        }
      });

    // Listen for save
    this.memberStore
      .pipe(
        select(fromMember.selectSaveNonListItemConfigsState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isSaving = !!state?.isLoading;

        if (state && !state?.isLoading) {
          if (state?.success) {
            showBanner.call(
              this,
              STATE.SUCCESS,
              this.menuItemName,
              ACTION.EDIT
            );

            this._reloadData();

            this.memberStore.dispatch(clearGetMetadataSectionsStateAction());
            this.memberStore.dispatch(
              clearGetMetadataNonListSectionsStateAction()
            );
          } else {
            showBanner.call(this, STATE.FAIL, this.menuItemName, ACTION.EDIT);
          }
        }
      });
  }

  private _reloadData(): void {
    this.memberStore.dispatch(
      NonListConfigurationActions.getNonListProperties({
        sectionKey: this.sectionKey,
      })
    );
    this.memberStore.dispatch(
      NonListConfigurationActions.getNonListItemConfigs({
        navigationItemId: this.menuItemId,
      })
    );

    this._resetAddPropertyForm(this.nonListPropertyTypeCtrl.value, true);
    this._markAsDataUnchanged();
  }

  //#region Property name
  onChangeNonListPropertyType(type: NonListPropertyType): void {
    this._resetAddPropertyForm(type);
  }

  private _refreshNonListPropertyOptions(
    type: NonListPropertyType = NonListPropertyType.Property
  ): void {
    this.availableNonListPropertyOptions = this.nonListProperties.reduce(
      (result: Option[], property) => {
        if (
          property.propertyType === type &&
          ![
            ...this.column1Data,
            ...this.column2Data,
            ...this.column3Data,
            ...this.column4Data,
          ].some(
            (item) =>
              (property.propertyType === NonListPropertyType.Property &&
                item.property === property.propertyKey) ||
              ((property.propertyType === NonListPropertyType.Aggregation ||
                property.propertyType === NonListPropertyType.Calculation) &&
                item.property === property.id)
          )
        ) {
          result.push({
            value: this._getPropertyValue(property),
            displayValue: this._getPropertyDisplayValue(property),
            valueDescription: this._getPropertyDescription(property),
            extraData: property,
          } as Option);
        }
        return result;
      },
      []
    );
  }

  private _getPropertyValue(property: NonListProperty): string | undefined {
    if (property.propertyType === NonListPropertyType.Property) {
      return property.propertyKey;
    }

    if (
      property.propertyType === NonListPropertyType.Aggregation ||
      property.propertyType === NonListPropertyType.Calculation
    ) {
      return property.id;
    }

    return undefined;
  }

  private _getPropertyDisplayValue(
    property: NonListProperty
  ): string | undefined {
    if (property.propertyType === NonListPropertyType.Property) {
      return property.propertyName;
    }

    if (
      property.propertyType === NonListPropertyType.Aggregation ||
      property.propertyType === NonListPropertyType.Calculation
    ) {
      return property.name;
    }

    return undefined;
  }

  private _getPropertyDescription(
    property: NonListProperty
  ): string | undefined {
    if (property.propertyType === NonListPropertyType.Aggregation) {
      return `${property.sectionName} / ${property.propertyName} / ${property.name}`;
    }

    return undefined;
  }

  onChangeNonListProperty(): void {
    if (
      this.nonListPropertyCtrl?.value === NonListPropertyType.Property ||
      this.nonListPropertyTypeCtrl?.value !== NonListPropertyType.BlankSpace
    ) {
      this.labelCtrl.setValue(
        this.availableNonListPropertyOptions?.find(
          (x) => x.value === this.nonListPropertyCtrl.value
        )?.displayValue
      );
    }
  }
  //#endregion

  //#region Label
  private _checkLabelDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        [
          ...this.column1DataGrid,
          ...this.column2DataGrid,
          ...this.column3DataGrid,
          ...this.column4DataGrid,
        ].some(
          (config) =>
            config.label?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: `Label already exists.`,
        };
      }

      return null;
    };
  }

  private checkExits(config: NonListItemConfig): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const existed = [
        ...this.column1DataGrid,
        ...this.column2DataGrid,
        ...this.column3DataGrid,
        ...this.column4DataGrid,
      ].some(
        (item) =>
          item.property && // Is not Blank Space
          item.property !== config.property &&
          item.label.toLowerCase().trim() === control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }
  //#endregion

  onClickAddToColumn(): void {
    this.nonListPropertyCtrl.markAsTouched();
    this.labelCtrl.markAsTouched();
    this.toColumnCtrl.markAsTouched();

    let newRow: NonListItemConfig & Row;
    if (this.nonListPropertyTypeCtrl.value === NonListPropertyType.BlankSpace) {
      if (!this.toColumnCtrl.valid) return;

      newRow = {
        propertyType: this.nonListPropertyTypeCtrl.value,
        label: 'Blank Space',
        column: this.toColumnCtrl.value,
        nonEditable: true,
      };
    } else {
      if (
        !this.nonListPropertyCtrl.valid ||
        !this.labelCtrl.valid ||
        !this.toColumnCtrl.valid
      )
        return;

      const selectedProperty = this.availableNonListPropertyOptions.find(
        (item) => item.value === this.nonListPropertyCtrl.value
      );
      newRow = {
        itemKey: selectedProperty?.extraData?.sectionKey, // For Property / Aggregation
        property: selectedProperty?.value,
        propertyType: this.nonListPropertyTypeCtrl.value,
        label: this.labelCtrl.value,
        description: this._getNonListItemConfigDescription(
          selectedProperty?.extraData
        ),
        column: this.toColumnCtrl.value,
      };
    }

    this._appendRowToTable(newRow);
    this._resetAddPropertyForm(this.nonListPropertyTypeCtrl.value);
    this.markAsDataChanged();
  }

  private _getNonListItemConfigDescription(
    property: NonListProperty
  ): string | undefined {
    if (property.propertyType === NonListPropertyType.Calculation) {
      return property.name;
    }

    if (property.propertyType === NonListPropertyType.Property) {
      return `${property.sectionName} / ${property.propertyName}`;
    }

    if (property.propertyType === NonListPropertyType.Aggregation) {
      return `${property.sectionName} / ${property.propertyName} / ${property.name}`;
    }

    return undefined;
  }

  private _appendRowToTable(row: NonListItemConfig) {
    switch (this.toColumnCtrl?.value) {
      case ColumnIndex.Column1:
        this.column1Data = [
          ...(this.gridColumn1?.dataSource ?? []),
          {
            ...row,
            form: this._createInlineEditFormControls(row),
          },
        ];
        break;
      case ColumnIndex.Column2:
        this.column2Data = [
          ...(this.gridColumn2?.dataSource ?? []),
          {
            ...row,
            form: this._createInlineEditFormControls(row),
          },
        ];
        break;
      case ColumnIndex.Column3:
        this.column3Data = [
          ...(this.gridColumn3?.dataSource ?? []),
          {
            ...row,
            form: this._createInlineEditFormControls(row),
          },
        ];
        break;
      case ColumnIndex.Column4:
        this.column4Data = [
          ...(this.gridColumn4?.dataSource ?? []),
          {
            ...row,
            form: this._createInlineEditFormControls(row),
          },
        ];
        break;
    }
  }

  private _createInlineEditFormControls(row: NonListItemConfig): FormGroup {
    return this.fb.group({
      label: new FormControl(
        row.label,
        getValidatorsFromColumns('label', this.columns, row)
      ),
    });
  }

  private _filterNonListItemConfig(
    data: NonListItemConfig[],
    column: ColumnIndex
  ): (NonListItemConfig & Row)[] {
    return data
      .filter((item) => item.column === column)
      .map((item) => ({
        ...item,
        nonEditable: item.propertyType === NonListPropertyType.BlankSpace,
        form: this._createInlineEditFormControls(item),
      }));
  }

  onOutsideRowDrop(event: OutsideReorderInfo<NonListItemConfig>): void {
    if (event.prevTableId === 'gridColumn1') {
      this.column1Data = [...(this.gridColumn1?.dataSource ?? [])];
    } else if (event.prevTableId === 'gridColumn2') {
      this.column2Data = [...(this.gridColumn2?.dataSource ?? [])];
    } else if (event.prevTableId === 'gridColumn3') {
      this.column3Data = [...(this.gridColumn3?.dataSource ?? [])];
    } else if (event.prevTableId === 'gridColumn4') {
      this.column4Data = [...(this.gridColumn4?.dataSource ?? [])];
    }

    // Update column index
    if (event.currentTableId === 'gridColumn1') {
      this.column1Data =
        this.gridColumn1?.dataSource.map((item) => ({
          ...item,
          column: ColumnIndex.Column1,
        })) ?? [];
    } else if (event.currentTableId === 'gridColumn2') {
      this.column2Data =
        this.gridColumn2?.dataSource.map((item) => ({
          ...item,
          column: ColumnIndex.Column2,
        })) ?? [];
    } else if (event.currentTableId === 'gridColumn3') {
      this.column3Data =
        this.gridColumn3?.dataSource.map((item) => ({
          ...item,
          column: ColumnIndex.Column3,
        })) ?? [];
    } else if (event.currentTableId === 'gridColumn4') {
      this.column4Data =
        this.gridColumn4?.dataSource.map((item) => ({
          ...item,
          column: ColumnIndex.Column4,
        })) ?? [];
    }
  }

  markAsDataChanged(): void {
    this.isChanged = true;
  }

  private _markAsDataUnchanged(): void {
    this.isChanged = false;
  }

  onSoftDeleteNonListItemConfig(row: NonListItemConfig & Row): void {
    row.order = -1; // Mark the row is deleted
  }

  onClickSave(): void {
    if (
      this.gridColumn1?.formStatus !== AbstractControlStatus.VALID ||
      this.gridColumn2?.formStatus !== AbstractControlStatus.VALID ||
      this.gridColumn3?.formStatus !== AbstractControlStatus.VALID ||
      this.gridColumn4?.formStatus !== AbstractControlStatus.VALID
    )
      return;

    this.isSaving = true;
    this.memberStore.dispatch(
      NonListConfigurationActions.saveNonListItemConfigs({
        request: {
          navigationItemId: this.menuItemId,
          nonListItemConfigs: [
            ...this.column1DataGrid,
            ...this.column2DataGrid,
            ...this.column3DataGrid,
            ...this.column4DataGrid,
          ]
            .filter((item) => item.order !== -1)
            .map(
              (item) =>
                ({
                  id: item.id,
                  itemKey: this.sectionKey,
                  label: item.label,
                  property: item.property,
                  propertyType: item.propertyType,
                  column: item.column,
                  order: item.order,
                } as NonListItemConfig)
            ),
        },
      })
    );
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._resetAddPropertyForm(this.nonListPropertyTypeCtrl.value);
        this.memberStore.dispatch(
          NonListConfigurationActions.getNonListItemConfigs({
            navigationItemId: this.menuItemId,
          })
        );
      }
    });
  }

  private _resetAddPropertyForm(
    nonListPropertyType: NonListPropertyType,
    resetNonListProperyType?: boolean
  ): void {
    this._refreshNonListPropertyOptions(nonListPropertyType);

    if (resetNonListProperyType) {
      this.nonListPropertyCtrl.setValue(
        this._defaultAddPropertyFormData.nonListProperty
      );
    }

    // Reset form values
    this.nonListPropertyCtrl.setValue(
      this._defaultAddPropertyFormData.nonListProperty
    );
    this.labelCtrl.setValue(this._defaultAddPropertyFormData.label);
    this.toColumnCtrl.setValue(this._defaultAddPropertyFormData.toColumn);

    // Reset form errors
    this.labelCtrl.setErrors(null);
    this.nonListPropertyCtrl.setErrors(null);
    this.toColumnCtrl.setErrors(null);

    this.labelCtrl.markAsUntouched();
    this.nonListPropertyCtrl.markAsUntouched();
    this.toColumnCtrl.markAsUntouched();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(
      NonListConfigurationActions.clearGetNonListPropertiesState()
    );
    this.memberStore.dispatch(
      NonListConfigurationActions.clearGetNonListItemConfigsStateAction()
    );
    this.memberStore.dispatch(
      NonListConfigurationActions.clearSaveNonListItemConfigs()
    );
  }
}
