import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import * as StatusActions from '../actions/status.actions';
import {
  MemberStatus,
  MemberStatusDetail,
  StatusDetailState,
} from '../types/models/status.model';

export const memberStatusFeatureKey = 'memberStatus';

export interface State {
  isLoading: boolean;
  statusList: MemberStatus[];
  isContinue: boolean;
  removeState: string;
  createStatusState: string;
  editStatusState: string;
  createEventState: string;
  editEventState: string;
  statusDetailState: StatusDetailState;
  removeStatusState: string;
  removeEventState: string;
  success?: boolean;
  error?: any;
}

const initialState: State = {
  isLoading: true,
  statusList: [],
  isContinue: false,
  removeState: '',
  createStatusState: '',
  editStatusState: '',
  createEventState: '',
  editEventState: '',
  statusDetailState: {} as StatusDetailState,
  removeStatusState: '',
  removeEventState: '',
};

export const reducer = createReducer(
  initialState,
  on(StatusActions.getStatus, (state, {}) => ({
    ...state,
    isLoading: true,
    statusList: [],
  })),
  on(StatusActions.getStatusSuccess, (state, { statusList }) => ({
    ...state,
    isLoading: false,
    statusList,
    success: true,
    error: undefined,
  })),
  on(StatusActions.getStatusFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    statusList: [],
    success: false,
    error: error,
  })),
  on(StatusActions.clearStatusState, (state) => ({
    ...state,
    isLoading: false,
    createStatusState: '',
    editStatusState: '',
    createEventState: '',
    editEventState: '',
    removeEventState: '',
    removeStatusState: '',
  })),
  on(StatusActions.createStatus, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(StatusActions.createStatusSuccess, (state) => ({
    ...state,
    isLoading: false,
    createStatusState: STATE.SUCCESS,
  })),
  on(StatusActions.createStatusFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    createStatusState: STATE.FAIL,
  })),
  on(StatusActions.editStatus, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(StatusActions.editStatusSuccess, (state) => ({
    ...state,
    isLoading: false,
    editStatusState: STATE.SUCCESS,
  })),
  on(StatusActions.editStatusFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    editStatusState: STATE.FAIL,
  })),
  on(StatusActions.removeStatus, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(StatusActions.removeStatusSuccess, (state) => ({
    ...state,
    isLoading: false,
    removeStatusState: STATE.SUCCESS,
  })),
  on(StatusActions.removeStatusFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    removeStatusState: STATE.FAIL,
  })),
  on(StatusActions.getStatusDetail, (state, {}) => ({
    ...state,
    statusDetailState: {
      isLoading: true,
      statusDetail: {} as MemberStatusDetail,
    },
  })),
  on(StatusActions.getStatusDetailSuccess, (state, { statusDetail }) => ({
    ...state,
    statusDetailState: {
      isLoading: false,
      statusDetail,
      success: true,
      error: undefined,
    },
  })),
  on(StatusActions.getStatusDetailFailure, (state, { error }) => ({
    ...state,
    statusDetailState: {
      isLoading: false,
      statusDetail: {} as MemberStatusDetail,
      success: false,
      error: error,
    },
  })),
  on(StatusActions.createEvent, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(StatusActions.createEventSuccess, (state) => ({
    ...state,
    isLoading: false,
    createEventState: STATE.SUCCESS,
  })),
  on(StatusActions.createEventFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    createEventState: STATE.FAIL,
  })),
  on(StatusActions.editEvent, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(StatusActions.editEventSuccess, (state) => ({
    ...state,
    isLoading: false,
    editEventState: STATE.SUCCESS,
  })),
  on(StatusActions.editEventFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    editEventState: STATE.FAIL,
  })),
  on(StatusActions.removeEvent, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(StatusActions.removeEventSuccess, (state) => ({
    ...state,
    isLoading: false,
    removeEventState: STATE.SUCCESS,
  })),
  on(StatusActions.removeEventFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    removeEventState: STATE.FAIL,
  }))
);
