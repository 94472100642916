<div
  id="profile-navigation-configuration-detail"
  class="profile-navigation-configuration-detail-container"
>
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div *ngIf="memberNavigation" class="box">
      <div class="title">
        <span class="title-name">Menu Configuration</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditMenu()"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail detail-name">
          <span class="label" id="label-table-name">Menu Name</span>
          <span class="value" id="value-table-name">
            <ng-container>{{ memberNavigation?.name }}</ng-container>
          </span>
        </div>
        <div class="detail detail-status">
          <span class="label" id="label-table-status">Status</span>
          <span class="value" id="value-table-status">
            <div class="flex">
              <div class="list-status">
                <mat-chip-list>
                  <mat-chip *ngFor="let status of memberNavigation?.statusList">
                    <mat-icon
                      *ngIf="status?.iconName"
                      class="icon-status"
                      [ngStyle]="{ color: status?.color }"
                      >{{ status?.iconName }}</mat-icon
                    >
                    <span>{{ status?.name }}</span>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </span>
        </div>
        <div class="detail detail-color">
          <span class="label" id="label-table-color">Menu Color</span>
          <span class="value" id="value-table-color">
            <div
              class="color-div"
              [style.background]="memberNavigation?.color"
            ></div>
          </span>
        </div>
        <div class="detail detail-disabled">
          <span class="label" id="label-table-disabled">Disabled</span>
          <span class="value" id="value-table-disabled">
            {{ memberNavigation?.disabled ? "Yes" : "No" }}
          </span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="title">
        <span class="title-name">Menu Item</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="New Menu Item"
            classInput="edit-button"
            (clickButton)="editMenuItem(undefined)"
          >
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>
      </div>
      <ptg-grid
        [data]="dataTable"
        [columns]="columns"
        notFoundMessage="No Navigation Configuration to Display"
        [isLoading]="isLoading"
        [errorMessage]="errorMsg"
        [fitToParent]="true"
        [paginable]="false"
        [allowDragDrop]="true"
        (rowDrop)="changeItemIndex($event)"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="editMenuItem(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="removeRecord(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
