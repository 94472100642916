import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Align, Column } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';

import * as fromReducer from '../../../reducers';
import * as StatusActions from '../../actions/status.actions';
import { EditEventComponent } from '../../components/edit-event/edit-event.component';
import { EditStatusComponent } from '../../components/edit-status/edit-status.component';
import { MemberEvent, MemberStatusDetail } from '../../types/models';
import * as fromMember from '../../reducers';
import { StatusService } from '../../services/status.service';

@Component({
  selector: 'ptg-status-detail',
  templateUrl: './status-detail.component.html',
  styleUrls: ['./status-detail.component.scss'],
})
export class StatusDetailComponent extends BaseComponent {
  sortInfo!: Sort;
  statusDetail!: MemberStatusDetail;
  memberEventsData!: MemberEvent[];
  isLoading: boolean = true;
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Event Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'disabled',
      header: {
        title: 'Disabled',
      },
      sortable: true,
      align: Align.Center,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  errorMsg: string = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Status',
      url: '/member/status',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  statusId: string = '';

  constructor(
    private route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private statusService: StatusService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.statusId = params.id;
      this.getStatusDetail(this.statusId);
    });

    this.memberStore
      .select(fromMember.selectStatusDetail)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((statusDetailState) => {
        this.statusDetail = statusDetailState.statusDetail;
        this.listBreadcrumbs = [
          {
            name: 'Participant List',
            moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
            url: '/member',
          },
          {
            name: 'Status',
            url: '/member/status',
          },
          {
            name: statusDetailState.statusDetail.name,
            url: '',
          },
        ];
        this.isLoading = statusDetailState.isLoading;

        if (!statusDetailState.success && statusDetailState.error) {
          this.errorMsg = statusDetailState.error.statusText;
        }
        this.memberEventsData = (
          statusDetailState.statusDetail.memberEvents || []
        ).map((item) => {
          return {
            ...item,
            disabled: !item.active,
          };
        });
      });

    this.memberStore
      .select(fromMember.selectEditStatusState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((createStatusState) => {
        showBanner.call(this, createStatusState, 'Status', ACTION.EDIT);
        this.memberStore.dispatch(StatusActions.clearStatusState());
        this.getStatusDetail(this.statusDetail.id || '');
      });

    this.memberStore
      .select(fromMember.selectCreateEventState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, 'Event', ACTION.ADD);
        this.memberStore.dispatch(StatusActions.clearStatusState());
        this.getStatusDetail(this.statusDetail.id || '');
      });

    this.memberStore
      .select(fromMember.selectEditEventState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, 'Event', ACTION.EDIT);
        this.memberStore.dispatch(StatusActions.clearStatusState());
        this.getStatusDetail(this.statusDetail.id || '');
      });

    this.memberStore
      .select(fromMember.selectRemoveEventState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, 'Event', ACTION.REMOVE);
        this.memberStore.dispatch(StatusActions.clearStatusState());
        this.getStatusDetail(this.statusDetail.id || '');
      });
  }

  getStatusDetail(id: string) {
    if (!id) {
      return;
    }
    let SortType = 0;
    let SortNames = 'Name';
    let Id = id;
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      SortNames = capitalizeFirstLetter(
        this.sortInfo.active === 'disabled' ? 'active' : this.sortInfo.active
      );
      SortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.memberStore.dispatch(
      StatusActions.getStatusDetail({ query: { SortNames, SortType, Id } })
    );
  }

  onEditStatus() {
    if (!this.statusDetail) {
      return;
    }
    this.dialog.open(EditStatusComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        ...this.statusDetail,
        disabled: !this.statusDetail.active,
      },
    });
  }

  onRemoveEvent(eventId: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: 'Are you sure you want to remove this Event?',
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(StatusActions.removeEvent({ eventId }));
      }
    });
  }

  onShowEventPopupAttention() {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Attention',
        text: 'This Event cannot be removed since it had been linked to a participant.',
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  onCheckLinkedEvent(event: any) {
    const body = {
      eventId: event?.id,
      statusId: this.statusId,
    };
    this.statusService.validateMemberStatus(body).subscribe((result: any) => {
      if (!result.isValid) {
        this.onShowEventPopupAttention();
      } else {
        this.onRemoveEvent(event.id);
      }
    });
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getStatusDetail(this.statusDetail.id || '');
  }

  editEvent(event: MemberEvent = {} as MemberEvent) {
    this.dialog.open(EditEventComponent, {
      panelClass: ['edit-popup', 'edit-event-dialog'],
      disableClose: true,
      height: 'auto',
      autoFocus: false,
      data: {
        ...event,
        memberStatusId: this.statusDetail.id,
        disabled: event.disabled,
      },
    });
  }
}
