import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Row } from '@ptg-shared/controls/grid';
import { Option } from '@ptg-shared/controls/select/select.component';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';

import * as fromMember from '../../reducers';
import { CalculationService } from '../../services/calculation.service';
import {
  AddCalculationRequest,
  Calculation,
  CalculationDataType,
  CalculationDetail,
  CalculationPropertyType,
  MetadataCalculationComponent,
  Operator,
  UpdateCalculationRequest,
} from '../../types/models/';
import { CalculationActions } from '../../actions';
import { PropertyType } from '../../constance/metadata.const';

@Component({
  selector: 'ptg-add-calculation',
  templateUrl: './add-calculation.component.html',
  styleUrls: ['./add-calculation.component.scss'],
})
export class AddCalculationComponent extends BaseComponent {
  formGroup?: FormGroup;
  readonly CalculationDataType = CalculationDataType;
  readonly PropertyType = PropertyType;
  readonly Operator= Operator;
  get calculationNameCtrl() {
    return this.formGroup?.get('calculationName') as FormControl;
  }

  get displayFormatCtrl() {
    return this.formGroup?.get('displayFormat') as FormControl;
  }

  get fractionalLengthCtrl() {
    return this.formGroup?.get('fractionalLength') as FormControl;
  }

  get operatorCtrl() {
    return this.formGroup?.get('operator') as FormControl;
  }

  get dataTypeCtrl() {
    return this.formGroup?.get('dataType') as FormControl;
  }

  get propertyNameCtrl() {
    return this.formGroup?.get('propertyName') as FormControl;
  }

  get numberValueCtrl() {
    return this.formGroup?.get('numberValue') as FormControl;
  }

  displayFormatOptions: Option[] = [
    {
      displayValue: 'Elapsed Time',
      value: PropertyType['Elapsed Time'],
    },
    {
      displayValue: 'Decimal',
      value: PropertyType.Decimal,
    },
    {
      displayValue: 'Currency',
      value: PropertyType.Currency,
    },
    {
      displayValue: 'Percentage',
      value: PropertyType.Percentage,
    },
    {
      displayValue: 'Whole Number',
      value: PropertyType['Whole Number'],
    },
  ];

  operatorOptions: Option[] = [
    {
      displayValue: '+',
      value: Operator.Plus,
    },
    {
      displayValue: '-',
      value: Operator.Minus,
    },
    {
      displayValue: '*',
      value: Operator.Multiply,
    },
    {
      displayValue: '/',
      value: Operator.Divide,
    },
  ];

  dataTypeOptions: Option[] = [
    {
      displayValue: 'Property',
      value: CalculationDataType.Property,
    },
    {
      displayValue: 'Fixed Number',
      value: CalculationDataType.Number,
    },
  ];

  title: string = '';
  buttonSubmitName = 'Create';
  isEdit: boolean = false;
  isShowFractionLength: boolean = false;
  isContinue: boolean = false;
  formulaOverview: string = '';
  propertyNameOptions?: any[] = [];
  listParameter = new FormArray([]);
  formSubmit$ = new Subject<boolean>();
  availablePropertyConfigs!: Option[] | any[];
  calculationDetailData?: CalculationDetail;

  constructor(
    public fb: FormBuilder,
    public memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCalculationComponent>,
    public router: Router,
    public calculationService: CalculationService,
    @Inject(MAT_DIALOG_DATA)
    public data: { calculation: Calculation & Row; isEdit?: boolean }
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.title = this.data?.isEdit ? 'Edit Calculation' : 'Add New Calculation';
    this.isEdit = this.data?.isEdit ? true : false;
    this.buttonSubmitName = this.data?.isEdit ? 'Save' : 'Create';
    this.emitFormSubmit();
    this.createForm();
    this.dispatchActions();
    this.subscribeData();
  }

  private dispatchActions(): void {
    this.memberStore.dispatch(CalculationActions.getCalculationProperties());
    if (this.isEdit) {
      this.memberStore.dispatch(
        CalculationActions.getCalculationDetail({
          id: this.data?.calculation?.id,
        })
      );
    }
  }

  private subscribeData(): void {
    this.memberStore
      .pipe(
        select(fromMember.selectCalculationPropertiesState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el?.success && !el.isLoading) {
          this.propertyNameOptions = el?.payload;
          this.getProperties();
          this.memberStore
            .pipe(
              select(fromMember.selectCalculationDetailState),
              takeUntil(this.unsubscribe$)
            )
            .subscribe((el) => {
              if (el?.success && !el.isLoading) {
                this.calculationDetailData = el.payload;
                this.initForm();
              }
            });
        }
      });
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      calculationName: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: checkApiValidator(
          this.calculationService.validateCalculationName,
          'name',
          this.data?.calculation?.calculationName || ''
        ),
      }),
      displayFormat: new FormControl('', {
        validators: [Validators.required],
      }),
      fractionalLength: new FormControl({ value: null }),
      dataType: new FormControl(CalculationDataType.Property, {
        validators: [Validators.required],
      }),
      propertyName: new FormControl('', { validators: [Validators.required] }),
      numberValue: new FormControl(''),
    });
  }

  private initForm(): void {
    if (this.isEdit && this.calculationDetailData) {
      // first row
      const firstRow = this.calculationDetailData?.calculationComponents?.length
        ? this.calculationDetailData.calculationComponents[0]
        : null;
      const valueConvert =
        firstRow?.dataType === CalculationDataType.Property
          ? JSON.parse(firstRow.value)
          : null;
      let propertyName =
        firstRow?.dataType === CalculationDataType.Property
          ? this.getValueProperties(valueConvert)
          : firstRow?.value;

      this.formGroup?.setValue({
        calculationName: this.calculationDetailData?.name,
        displayFormat: this.calculationDetailData?.displayFormat,
        fractionalLength: this.calculationDetailData?.displayValue,
        dataType: firstRow?.dataType,
        propertyName: propertyName,
        numberValue: propertyName,
      });

      const displayFormat = this.displayFormatCtrl?.value;
      if (displayFormat === PropertyType['Elapsed Time']) {
        // TODO: Other story will cover Elapsed Time.
      }

      if (this.calculationDetailData?.calculationComponents?.length! > 1) {
        this.calculationDetailData?.calculationComponents?.forEach((item) => {
          if (item.order > 0) {
            const valueConvert =
              item.dataType === CalculationDataType.Property
                ? JSON.parse(item?.value)
                : null;
            let propertyName = '';
            if (item?.dataType === CalculationDataType.Property) {
              propertyName = this.getValueProperties(valueConvert)!;
            } else {
              propertyName = item?.value;
            }
            this.listParameter.push(
              new FormGroup({
                operator: new FormControl(item.operator),
                dataType: new FormControl(item.dataType),
                propertyName: new FormControl(propertyName),
                numberValue: new FormControl(propertyName),
              })
            );
          }
        });
      }

      this.onChangeFormulaOverview();
      this.disableElement();
    }
  }

  private disableElement(): void {
    this.displayFormatCtrl.disable();
    this.fractionalLengthCtrl.disable();
    this.dataTypeCtrl.disable();
    this.propertyNameCtrl.disable();
    this.numberValueCtrl.disable();
  }

  onChangeDisplayFormat(): void {
    const displayFormat = this.displayFormatCtrl?.value;
    if (displayFormat === PropertyType['Elapsed Time']) {
      // TODO: Other story will cover Elapsed Time.
    }

    this.fractionalLengthCtrl.setValue('');
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: 'Are you sure you want to cancel any/all changes?',
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onClickRemoveRow(index: number): void {
    this.listParameter.removeAt(index);
    this.onChangeFormulaOverview();
    this.listParameter.updateValueAndValidity();
  }

  onClickAddNew() {
    this.listParameter.push(
      new FormGroup({
        operator: new FormControl(null, {
          validators: [Validators.required],
        }),
        dataType: new FormControl(CalculationDataType.Property),
        propertyName: new FormControl('', {
          validators: [Validators.required],
        }),
        numberValue: new FormControl(''),
      })
    );
  }

  emitFormSubmit() {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.formGroup?.markAllAsTouched();
          this.listParameter?.markAllAsTouched();
        })
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    if (this.formGroup?.pending) {
      let sub = this.formGroup.statusChanges.subscribe(() => {
        if (this.formGroup?.valid) {
          this.saveCalculation();
        }
        sub.unsubscribe();
      });
    } else if (this.formGroup?.valid) {
      this.saveCalculation();
    }
  }

  saveCalculation() {
    if (!this.formGroup?.valid || !this.listParameter.valid) {
      return;
    }

    if (!this.isEdit) {
      const property = this.propertyNameOptions?.find(
        (ele: any) =>
          ele.propertyKey === this.formGroup?.value.propertyName ||
          ele.id === this.formGroup?.value.propertyName
      );
      const propertyConvert = property !== '' ? JSON.stringify(property) : '';
      const firstRow: MetadataCalculationComponent = {
        dataType: this.formGroup.value.dataType,
        value:
          this.formGroup.value.dataType === CalculationDataType.Property
            ? propertyConvert
            : this.formGroup.value.numberValue.toString(),
        order: 0,
        propertyType: property?.propertyType,
      };
      const listParameterValue = this.getCalculationComponent();

      const body: AddCalculationRequest = Object.assign({
        name: this.formGroup.value.calculationName,
        displayValue: this.formGroup.value.fractionalLength,
        displayFormat: Number(this.formGroup.value.displayFormat),
        calculationComponents: [firstRow, ...listParameterValue],
      });

      this.memberStore.dispatch(
        CalculationActions.addCalculation({ body, isContinue: this.isContinue })
      );
    } else {
      const body: UpdateCalculationRequest = Object.assign({
        id: this.calculationDetailData?.id,
        name: this.formGroup.value.calculationName,
      });

      this.memberStore.dispatch(CalculationActions.updateCalculation({ body }));
    }

    if (!this.isContinue) {
      this.resetData();
    } else {
      this.dialogRef.close();
    }
  }

  resetData() {
    this.formGroup?.reset();
    this.listParameter = new FormArray([]);
    this.dataTypeCtrl.setValue(CalculationDataType.Property);
    this.formulaOverview = '';
  }

  private getCalculationComponent(): MetadataCalculationComponent[] {
    let order = 0;
    let calculationComponent: MetadataCalculationComponent[] =
      this.listParameter?.value?.map((item: any) => {
        const currentValue = this.propertyNameOptions?.find(
          (ele: any) =>
            ele.propertyKey === item.propertyName ||
            ele.id === item.propertyName
        );
        const currentValueConvert = currentValue
          ? JSON.stringify(currentValue)
          : '';
        order += 1;
        return {
          operator: item.operator,
          dataType: item?.dataType,
          value:
            item?.dataType === CalculationDataType.Property
              ? currentValueConvert
              : item.numberValue.toString(),
          order: order,
          propertyType: currentValue?.propertyType,
        } as MetadataCalculationComponent;
      }) as MetadataCalculationComponent[];

    return calculationComponent;
  }

  addNewButtonIsValid(): boolean {
    return !this.listParameter.getRawValue().some((param) => {
      if (Number(param.dataType) === CalculationDataType.Property) {
        return param?.operator === null || !param?.propertyName;
      } else {
        return param?.operator === null || !param?.numberValue || param?.numberValue === '0';
      }
    });
  }

  private getProperties() {
    this.availablePropertyConfigs = (this.propertyNameOptions as any).reduce(
      (result: Option[] | any, propertyConfig: any) => {
        result.push({
          value: this.getValueProperties(propertyConfig),
          displayValue: this.getDisplayValueProperties(propertyConfig),
          valueDescription: this.getValueDescriptionProperties(propertyConfig),
        });
        return result;
      },
      [] as Option[]
    );
  }

  getValueProperties(propertyConfig: any): string | null {
    if (
      propertyConfig.propertyType === CalculationPropertyType.Aggregation ||
      propertyConfig.propertyType ===
        CalculationPropertyType.ParticipantMetadata
    ) {
      return propertyConfig.propertyKey;
    }

    if (propertyConfig.propertyType === CalculationPropertyType.Calculation) {
      return propertyConfig.id;
    }
    return null;
  }

  getDisplayValueProperties(propertyConfig: any): string | null {
    if (
      propertyConfig.propertyType ===
      CalculationPropertyType.ParticipantMetadata
    ) {
      return propertyConfig.propertyName;
    }

    if (propertyConfig.propertyType === CalculationPropertyType.Aggregation) {
      return propertyConfig.name;
    }

    if (propertyConfig.propertyType === CalculationPropertyType.Calculation) {
      return propertyConfig.name;
    }
    return null;
  }

  getValueDescriptionProperties(propertyConfig: any): string | null {
    if (
      propertyConfig.propertyType ===
      CalculationPropertyType.ParticipantMetadata
    ) {
      return propertyConfig.sectionName;
    }

    if (propertyConfig.propertyType === CalculationPropertyType.Calculation) {
      return 'Calculation';
    }

    if (propertyConfig.propertyType === CalculationPropertyType.Aggregation) {
      return `${propertyConfig?.sectionName} / ${propertyConfig?.propertyName} / ${propertyConfig?.name}`;
    }

    return null;
  }

  onChangeFormulaOverview(index?: number) {
    this.formulaOverview = '';
    this.formulaOverview =
      this.calculationNameCtrl.value !== ''
        ? `${this.calculationNameCtrl.value} = `
        : '';
    if (this.dataTypeCtrl.value === CalculationDataType.Property) {
      const propertyValue = this.availablePropertyConfigs?.find(
        (x) => x.value === this.propertyNameCtrl.value
      );
      this.formulaOverview =
        this.formulaOverview +
        ` ` +
        (propertyValue ? propertyValue.displayValue : '');
      this.formulaOverview =
        this.formulaOverview + ` ` + this.getFormulaOverviewFromListParameter();
    } else {
      this.formulaOverview =
        this.formulaOverview + ` ` + this.numberValueCtrl.value;
      this.formulaOverview =
        this.formulaOverview + ` ` + this.getFormulaOverviewFromListParameter();
    }

    if (
      this.listParameter?.value[index || 0]?.operator === Operator.Divide &&
      this.listParameter?.value[index || 0]?.numberValue === '0' &&
      this.listParameter?.value[index || 0]?.dataType === CalculationDataType.Number
    ) {
      this.listParameter?.controls[index || 0]?.get('numberValue')?.setErrors({ inValidAsync: true });
    }
  }

  private getFormulaOverviewFromListParameter(): string {
    let formulaValue = '';
    if (this.listParameter?.value?.length > 0) {
      this.listParameter?.value?.forEach((element: any) => {
        if (element.dataType === CalculationDataType.Property) {
          const propertyValue = this.availablePropertyConfigs?.find(
            (x) => x.value === element.propertyName
          );
          const operatorValue = this.operatorOptions.find(
            (x) => x.value === element.operator
          );
          formulaValue =
            formulaValue +
            ` ` +
            `${operatorValue ? operatorValue.displayValue : ''} ${
              propertyValue ? propertyValue.displayValue : ''
            }`;
        } else {
          const operatorValue = this.operatorOptions.find(
            (x) => x.value === element.operator
          );
          formulaValue =
            formulaValue +
            ` ` +
            `${operatorValue ? operatorValue.displayValue : ''} ${
              element.numberValue
            }`;
        }
      });
    }

    return formulaValue;
  }

  onChangeDataType() {
    if (this.dataTypeCtrl.value === CalculationDataType.Property) {
      this.numberValueCtrl?.removeValidators(Validators.required);
      this.propertyNameCtrl?.addValidators(Validators.required);
      this.propertyNameCtrl?.addValidators(Validators.required);
      this.numberValueCtrl?.reset('');
    } else {
      this.propertyNameCtrl?.removeValidators(Validators.required);
      this.numberValueCtrl?.addValidators(Validators.required);
      this.propertyNameCtrl?.reset('');
    }
    this.numberValueCtrl?.updateValueAndValidity({ emitEvent: false });
    this.propertyNameCtrl?.updateValueAndValidity({ emitEvent: false });
  }

  onChangeDataTypeOfListParameter(index: number) {
    const currentFormControl = this.listParameter.at(index);
    if (
      currentFormControl?.get('dataType')?.value ===
      CalculationDataType.Property
    ) {
      currentFormControl
        ?.get('numberValue')
        ?.removeValidators(Validators.required);
      currentFormControl
        ?.get('propertyName')
        ?.addValidators(Validators.required);
      currentFormControl?.get('numberValue')?.reset('');
    } else {
      currentFormControl
        ?.get('propertyName')
        ?.removeValidators(Validators.required);
      currentFormControl
        ?.get('numberValue')
        ?.addValidators(Validators.required);
      currentFormControl?.get('propertyName')?.reset('');
    }
    currentFormControl
      ?.get('numberValue')
      ?.updateValueAndValidity({ emitEvent: false });
    currentFormControl
      ?.get('propertyName')
      ?.updateValueAndValidity({ emitEvent: false });
  }
}
