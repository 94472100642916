import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { InterceptorActions } from '@ptg-shared/layout/actions';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import * as fromReducer from '@ptg-reducers';
import { Router } from '@angular/router';
import { LoggerService } from '@ptg-shared/services/logger.service';

export const SKIP_ERRORS = new HttpContextToken<string | string[]>(() => '');
export const SKIP_LOG = new HttpContextToken<string | string[]>(() => '');

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private authService: Auth0Service,
    private router: Router,
    private store: Store<fromReducer.State>,
    private logger: LoggerService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      headers: request.headers.set('Cache-control', 'no-cache'),
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    request = request.clone({ headers: request.headers.set('ClientKey', '') });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          this.authService.logout();
        }
        if(error && error.status === 404){
          this.redirectWhenError()
        }
        let skipErrors = request.context.get(SKIP_ERRORS);
        const errorObject =
          typeof error.error === 'string'
            ? JSON.parse(error.error)
            : error.error;
        const isSkipError =
          skipErrors.includes(error.status.toString()) ||
          skipErrors.includes(errorObject?.errorType);
        if (request.method === 'GET' && !isSkipError) {
          const errorStatus = deepClone(error) as any;
          delete errorStatus.headers;
          this.store.dispatch(
            InterceptorActions.setError({ error: errorStatus })
          );
        }

        // Cast Error object to json to pass in the redux action
        error = JSON.parse(JSON.stringify(error));

        // Log error
        const skipLog = request.context.get(SKIP_LOG);
        if (!skipLog && !skipErrors) {
          const message = `HttpResponse {Url: "${request?.urlWithParams}", Method: "${request?.method}", Status: ${error?.status}, Body: ${JSON.stringify(request?.body)}, Error: ${error?.message}}`;
          error && error.status === 400
            ? this.logger.warning(message)
            : this.logger.error(message);
        }
        return throwError(error);
      })
    );
  }

  private redirectWhenError() {
    const url = this.router.url;
    let absPath = url.split('/')[1];
    switch (absPath) {
      case 'member':{
        let page = url.split('/')[2];
        if(page)
          this.navigateByUrlCustom('/member');
        break;
      }
      default:
        break;
    }
  }

  private navigateByUrlCustom(urlRedirect: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlRedirect]);
    });
  }
}
