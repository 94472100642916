import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortType } from '@ptg-shared/constance/value.const';
import { environment } from 'src/environments/environment';
import {
  Info1099RecordQuery,
  Info1099RecordResponse,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class Info1099Service {
  memberId?: string = '';
  payeeId?: string = '';
  payrollId?: string = '';
  selectRowHistory = new Subject();

  constructor(private httpClient: HttpClient) {}

  getInfo1099Config() {
    return this.httpClient.get(
      `${environment.apiUrl}/annual-1099/column-configurations`
    );
  }

  getInfo1099Record(
    query: Info1099RecordQuery,
    memberId: string
  ): Observable<Info1099RecordResponse> {
    let params = new HttpParams();
    params = params.append('ParticipantId', memberId);

    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append('SortNames', query.sortField);
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }

    return this.httpClient.get<Info1099RecordResponse>(
      `${environment.apiUrl}/annual-1099/periods/null/records`,
      { params }
    );
  }

  downloadPDF({ annual1099Id, participantId, recordId }: any): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' };
    return this.httpClient
      .get<Blob>(`${environment.apiUrl}/annual-1099/periods/${annual1099Id}/recipients/${participantId}/records/${recordId}/print`, options)
      .pipe(map(res => new Blob([res], { type: 'application/pdf' })));
  }
}
