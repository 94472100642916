import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { BaseActionState } from '@ptg-shared/models';
import { Menu, MenuItem } from '@ptg-shared/layout/models/layout.model';
import { ItemMenuType } from '@ptg-shared/layout/constance/layout.const';
import {
  GetMemberNavigationListResponse,
  MemberNavigationResponse,
} from '@ptg-member/types/models';
import { SectionLayout } from '@ptg-member/constance/metadata.const';

import { LayoutActions } from '../actions';

export interface ProfileNavState {
  getMemberNavigationList?: BaseActionState<{
    memberId?: string;
    memberNavigationList: GetMemberNavigationListResponse;
    menu: Menu[];
  }>;
}

const initialState: ProfileNavState = {};

export const profileNavReducer = createReducer(
  initialState,
  on(LayoutActions.getMemberNavigationListAction, (state) => ({
    ...state,
    getMemberNavigationList: {
      isLoading: true,
    },
  })),
  on(
    LayoutActions.getMemberNavigationListSuccessAction,
    (state, { response, memberId }) => {
      const listMemberNavigation = response?.memberNavigationMenu?.filter(
        (item: MemberNavigationResponse) => item?.active
      );
      const menu = (listMemberNavigation || []).map(
        (memberNavigationResponse: MemberNavigationResponse) => {
          const menuItem: Menu = {
            title: memberNavigationResponse.name,
            style: `color:${memberNavigationResponse.color} !important`,
            menuItems: memberNavigationResponse?.menuItem?.map((item) => {
              const subMenu: MenuItem = {
                name: item.name,
                routerLink: `/member/navigation/${item.type}/${item.id}/`,
              };
              if (item.type === SectionLayout.Payment) {
                subMenu.type = ItemMenuType.NextPayment;
              } else if (item.type === SectionLayout.PaymentHistory) {
                subMenu.type = ItemMenuType.PaymentHistory;
              } else if (
                item.type === SectionLayout.MunicipalityServiceRecord
              ) {
                subMenu.type = ItemMenuType.MunicipalityServiceRecord;
              } else if ( item.type === SectionLayout.Info1099) {
                subMenu.type = ItemMenuType.Info1099;
              }
              return subMenu;
            }),
          };
          return menuItem;
        }
      );
      return {
        ...state,
        getMemberNavigationList: {
          isLoading: false,
          success: true,
          payload: {
            menu,
            memberId,
            memberNavigationList: response,
          },
          updatedAt: DateTime.now().toISO(),
        },
      };
    }
  ),
  on(
    LayoutActions.getMemberNavigationListFailureAction,
    (state, { error }) => ({
      ...state,
      getMemberNavigationList: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(LayoutActions.clearGetMemberNavigationListAction, (state) => ({
    ...state,
    getMemberNavigationList: undefined,
  }))
);
