import { createReducer, on } from '@ngrx/store';
import { GetGeneratedReportResponse, GetManageReportResponse, GetReportCategoryNameResponse, GetReportCategorySidebarResponse } from '@ptg-member/types/models/participant-report.model';
import { ACTION } from '@ptg-shared/constance';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as ParticipantReportAction from '../actions/participant-report.actions';


export const participantReportFeatureKey = 'participantReport';

export interface State {
  getManageParticipantState: {
    response: GetManageReportResponse,
    isLoading: boolean,
    error?: any,
    isSuccess?: boolean,
  };
  getReportCategorySidebarState: {
    response: GetReportCategorySidebarResponse,
    isLoading: boolean,
    error?: any,
    isSuccess?: boolean,
  };
  getReportCategoryNameState: {
    response: GetReportCategoryNameResponse,
    isLoading: boolean,
    error?: any,
    isSuccess?: boolean,
  };
  editReportState: {
    action: string,
    isLoading: boolean,
    isSuccess?: boolean,
    error?: any,
  };
  createReportState: {
    action: string,
    isLoading: boolean,
    isSuccess?: boolean,
    error?: any,
  };
  deleteReportState: {
    action: string,
    isLoading: boolean,
    isSuccess?: boolean,
    error?: any,
  };
  getGeneratedReportState: {
    response: GetGeneratedReportResponse,
    isLoading: boolean,
    error?: any,
    isSuccess?: boolean,
  };
  deleteGeneratedReportState: {
    action: string,
    isLoading: boolean,
    isSuccess?: boolean,
    error?: any,
  };
}

const initialState: State = {
  getManageParticipantState: {
    isLoading: true,
    response:{
      total: 0,
      reports: []
    },
  },
  getReportCategorySidebarState: {
    isLoading: true,
    response: {
      reportCategory: []
    }
  },
  getReportCategoryNameState: {
    isLoading: true,
    response: {
      reportCategoryNames: []
    }
  },
  editReportState: {
    action: ACTION.EDIT,
    isLoading: true,
  },
  createReportState: {
    action: ACTION.ADD,
    isLoading: true,
  },
  deleteReportState: {
    action: ACTION.REMOVE,
    isLoading: true,
  },
  getGeneratedReportState: {
    isLoading: true,
    response:{
      total: 0,
      generatedReports: []
    },
  },
  deleteGeneratedReportState: {
    action: ACTION.REMOVE,
    isLoading: true,
  },
};

export const reducer = createReducer(
  initialState,

  on(ParticipantReportAction.clearStateParticipantReport, (state)=> ({
    ...initialState,
  })),

  on(ParticipantReportAction.editReportConfig, (state) => ({
    ...state,
    editReportState: {
      isLoading: true,
      action: ACTION.EDIT,
    }
  })),
  on(ParticipantReportAction.editReportConfigSuccess, (state) => ({
    ...state,
    editReportState: {
      isLoading: false,
      isSuccess: true,
      action: ACTION.EDIT,
    }
  })),
  on(ParticipantReportAction.editReportConfigFailure, (state) => ({
    ...state,
    editReportState: {
      isLoading: false,
      isSuccess: false,
      action: ACTION.EDIT,
    }
  })),

  on(ParticipantReportAction.getManageReportRequest, (state) => ({
    ...state,
    getManageParticipantState: {
      isLoading: true,
      response: {
        total: 0,
        reports: [],
      },
    }
  })),
  on(ParticipantReportAction.getManageReportSuccess, (state, { res }) => ({
    ...state,
    getManageParticipantState: {
      isLoading: false,
      isSuccess: true,
      response: res,
    }
  })),
  on(ParticipantReportAction.getManageReportFailure, (state) => ({
    ...state,
    getManageParticipantState: {
      isLoading: false,
      isSuccess: false,
      response: {
        total: 0,
        reports: [],
      },
    }
  })),

  on(ParticipantReportAction.deleteReport, (state) => ({
    ...state,
    deleteReportState: {
      isLoading: true,
      action: ACTION.REMOVE,
    }
  })),
  on(ParticipantReportAction.deleteReportSuccess, (state) => ({
    ...state,
    deleteReportState: {
      isLoading: false,
      isSuccess: true,
      action: ACTION.REMOVE,
    }
  })),
  on(ParticipantReportAction.deleteReportFailure, (state) => ({
    ...state,
    deleteReportState: {
      isLoading: false,
      isSuccess: false,
      action: ACTION.REMOVE,
    }
  })),

  on(LayoutActions.getReportCategorySidebar, (state) => ({
    ...state,
    getReportCategorySidebarState: {
      isLoading: true,
      response: {
        reportCategory: [],
      },
    }
  })),
  on(LayoutActions.getReportCategorySidebarSuccess, (state, { res }) => ({
    ...state,
    getReportCategorySidebarState: {
      isLoading: false,
      isSuccess: true,
      response: res
    }
  })),
  on(LayoutActions.getReportCategorySidebarFailure, (state) => ({
    ...state,
    getReportCategorySidebarState: {
      isLoading: false,
      isSuccess: false,
      response: {
        reportCategory: [],
      },
    }
  })),

  on(ParticipantReportAction.createReportConfig, (state) => ({
    ...state,
    createReportState: {
      action: ACTION.ADD,
      isLoading: true,
    }
  })),
  on(ParticipantReportAction.createReportConfigSuccess, (state) => ({
    ...state,
    createReportState: {
      isLoading: false,
      action: ACTION.ADD,
      isSuccess: true,
    }
  })),
  on(ParticipantReportAction.createReportConfigFailure, (state) => ({
    ...state,
    createReportState: {
      isLoading: false,
      action: ACTION.ADD,
      isSuccess: false,
    }
  })),

  on(ParticipantReportAction.getReportCategoryName, (state) => ({
    ...state,
    getReportCategoryNameState: {
      isLoading: true,
      response: {
        reportCategoryNames: [],
      },
    }
  })),
  on(ParticipantReportAction.getReportCategoryNameSuccess, (state, {res}) => ({
    ...state,
    getReportCategoryNameState: {
      isLoading: false,
      isSuccess: true,
      response: res
    }
  })),
  on(ParticipantReportAction.getReportCategoryNameFailure, (state) => ({
    ...state,
    getReportCategoryNameState: {
      isLoading: false,
      isSuccess: false,
      response: {
        reportCategoryNames: [],
      },
    }
  })),

  on(ParticipantReportAction.getGeneratedReportRequest, (state) => ({
    ...state,
    getGeneratedReportState: {
      isLoading: true,
      response: {
        total: 0,
        generatedReports: [],
      },
    }
  })),
  on(ParticipantReportAction.getGeneratedReportSuccess, (state, { res }) => ({
    ...state,
    getGeneratedReportState: {
      isLoading: false,
      isSuccess: true,
      response: res,
    }
  })),
  on(ParticipantReportAction.getCategorySidebarFailure, (state) => ({
    ...state,
    getGeneratedReportState: {
      isLoading: false,
      isSuccess: false,
      response: {
        total: 0,
        generatedReports: [],
      },
    }
  })),

  on(ParticipantReportAction.deleteGeneratedReport, (state) => ({
    ...state,
    deleteGeneratedReportState: {
      isLoading: true,
      action: ACTION.REMOVE,
    }
  })),
  on(ParticipantReportAction.deleteGeneratedReportSuccess, (state) => ({
    ...state,
    deleteGeneratedReportState: {
      isLoading: false,
      isSuccess: true,
      action: ACTION.REMOVE,
    }
  })),
  on(ParticipantReportAction.deleteGeneratedReportFailure, (state) => ({
    ...state,
    deleteGeneratedReportState: {
      isLoading: false,
      isSuccess: false,
      action: ACTION.REMOVE,
    }
  })),
  on(ParticipantReportAction.deleteGeneratedReportClear, (state)=> ({
    ...state,
    deleteGeneratedReportState: {
      isLoading: true,
      action: ACTION.REMOVE,
    }
  })),

);
