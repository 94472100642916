import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  AddCalculationRequest,
  CalculationDetailResponse,
  GetCalculationsRequest,
  GetCalculationsResponse,
  UpdateCalculationRequest,
} from '../types/models';

@Injectable()
export class CalculationService {
  id: string | undefined = '';
  constructor(private httpClient: HttpClient) {}

  getCalculations(
    request: GetCalculationsRequest
  ): Observable<GetCalculationsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType || 0);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get<GetCalculationsResponse>(
      `${environment.apiUrl}/Metadata/Calculations`,
      { params }
    );
  }

  addCalculation(request: AddCalculationRequest): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Metadata/AddCalculation`,
      { ...request }
    );
  }

  updateCalculation(body: UpdateCalculationRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Metadata/Calculation/${body.id}`,
      body
    );
  }

  removeCalculation(id: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Metadata/Calculation/${id}`
    );
  }

  getCalculationProperties(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/Metadata/GetCalculationProperties`
    );
  }

  getCalculationDetail(id: string): Observable<CalculationDetailResponse> {
    return this.httpClient.get<CalculationDetailResponse>(
      `${environment.apiUrl}/Metadata/Calculation/${id}`
    );
  }

  getMemberId(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/Members/` + id);
  }

  validateCalculationName = (body: any) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/Calculation/Exists`,
      body
    );
  };
}
