import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/models';

import * as NextPaymentActions from '../actions/next-payment.actions';
import { ERROR_TYPE_MISSING_INFO } from '../constance/next-payment.const';
import {
  MemberAddressItem,
  OffCyclePayment,
  PaymentDeduction,
  PaymentHistoryListResponse,
  PaymentInforConfigData,
  PendingPaymentListResponse,
  RepresentativePayee,
} from '../types/models';

export const nextPaymentFeatureKey = 'nextPayment';

export interface State {
  offCyclePayment?: OffCyclePayment;
  paymentInfoNotes?: any;
  deductions?: any;
  isLoading: boolean;
  earnings?: any;
  payments?: any;
  settings?: any;
  paymentHistoryList?: BaseActionState<PaymentHistoryListResponse>;
  pendingPaymentList?: BaseActionState<PendingPaymentListResponse>;
  depositDate?: string;
  addNoteState?: string;
  updateMemoState?: string;
  removeMemoState?: string;
  updateEarningsState?: string;
  updateDeductionState?: string;
  updatePayrollSettingsState?: string;
  recalculateDeductionsError?: any;
  updatePaymentState?: string;
  memberAddressItems?: MemberAddressItem[];
  isEstablishBenefit?: boolean;
  targetId?: string;
  recalculateTotalDeductions?: {
    recalculateTotalDeductionsState: string;
    totalDeductions: number;
  };
  representativePayees?: RepresentativePayee[];
  issueOffCyclePayment?: {
    isLoading: boolean;
    missingInfomation: string;
    issueState: string;
  };
  paymentDeductions?: PaymentDeduction[];
  createOffCycleState?: {
    state: string;
    newOffCyclePaymentId: string;
  };
  reloadPendingPaymentsState?: {
    isReload: boolean;
    selectedRowId?: string;
    resetSort?: boolean;
  };
  participantNextPaymentSetting?: any;
  withdrawOffCyclePayment?: {
    withdrawOffCyclePaymentState?: string;
  };
  removeOffCyclePaymentState?: string;
  savePaymentInforConfigState?: string;
  paymentInforConfig: PaymentInforConfigData[];
}

const initialState: State = {
  isLoading: true,
  paymentInforConfig: [],
};

export const reducer = createReducer(
  initialState,
  on(NextPaymentActions.getNextPayment, (state) => ({
    ...state,
  })),
  on(
    NextPaymentActions.getNextPaymentSuccess,
    (state, { dataNextPayment, section, depositDate, targetId }) => {
      let updatedState: any;
      switch (section) {
        case 0:
          updatedState = {
            ...state,
            isEstablishBenefit: dataNextPayment?.isEstablishBenefit,
            offCyclePayment: dataNextPayment?.offCyclePayment,
            deductions: dataNextPayment?.deductions,
            earnings: dataNextPayment?.earnings,
            payments: dataNextPayment?.payments,
            isLoading: false,
            settings: {
              participantSetting: dataNextPayment?.settings,
              payrollSettings: dataNextPayment?.payrollSettings,
              representativePayee: dataNextPayment?.representativePayee,
            },
            depositDate,
            targetId,
          };
          break;
        case 1:
        case 2:
          updatedState = {
            ...state,
            isLoading: false,
            offCyclePayment: dataNextPayment?.offCyclePayment,
            deductions: dataNextPayment?.deductions,
            earnings: dataNextPayment?.earnings,
            payments: dataNextPayment?.payments,
          };
          break;
        case 3:
          updatedState = {
            ...state,
            isLoading: false,
            offCyclePayment: dataNextPayment?.offCyclePayment,
            deductions: dataNextPayment?.deductions,
            earnings: dataNextPayment?.earnings,
            payments: dataNextPayment?.payments,
            settings: {
              participantSetting: dataNextPayment?.settings,
              payrollSettings: dataNextPayment?.payrollSettings,
              representativePayee: dataNextPayment?.representativePayee,
            },
          };
          break;
        case 4:
          updatedState = {
            ...state,
            isLoading: false,
            offCyclePayment: dataNextPayment?.offCyclePayment,
            payments: dataNextPayment?.payments,
          };
          break;
        case 5:
          updatedState = {
            ...state,
            isLoading: false,
            notes: dataNextPayment?.notes,
          };
          break;
        default:
          updatedState = { ...state };
          break;
      }
      return updatedState;
    }
  ),
  on(NextPaymentActions.getNextPaymentFailure, (state, { errorMsg }) => ({
    ...state,
    offCyclePayment: undefined,
    deductions: null,
    earnings: null,
    payments: null,
    settings: null,
  })),
  on(NextPaymentActions.getNextPaymentHistory, (state) => ({
    ...state,
    paymentHistoryList: {
      isLoading: true,
      total: 0,
    },
  })),
  on(
    NextPaymentActions.getNextPaymentHistorySuccess,
    (state, { response }) => ({
      ...state,
      paymentHistoryList: {
        isLoading: false,
        total: response.total,
        success: true,
        payload: response,
      },
      isLoading: false,
    })
  ),
  on(NextPaymentActions.getNextPaymentHistoryFailure, (state, { error }) => ({
    ...state,
    paymentHistoryList: {
      isLoading: false,
      total: 0,
      success: false,
      error: error,
    },
  })),
  on(NextPaymentActions.clearGetPaymentHistoryState, (state) => ({
    ...state,
    paymentHistoryList: undefined,
  })),
  on(NextPaymentActions.addNoteSuccess, (state) => ({
    ...state,
    addNoteState: 'Success',
  })),
  on(NextPaymentActions.addNoteFailure, (state: State, { errorMsg }) => ({
    ...state,
    addNoteState: 'Fail',
  })),

  on(NextPaymentActions.updateEarningsSuccess, (state) => ({
    ...state,
    updateEarningsState: 'Success',
    recalculateDeductionsError: undefined,
  })),
  on(NextPaymentActions.updateEarningsFailure, (state: State, { error }) => ({
    ...state,
    updateEarningsState: 'Fail',
    recalculateDeductionsError: error,
  })),

  on(NextPaymentActions.updateDeductionSuccess, (state) => ({
    ...state,
    updateDeductionState: 'Success',
    recalculateDeductionsError: undefined,
  })),
  on(NextPaymentActions.updateDeductionFailure, (state: State, { error }) => ({
    ...state,
    updateDeductionState: 'Fail',
    recalculateDeductionsError: error,
  })),
  on(NextPaymentActions.updatePayrollSettingsSuccess, (state) => ({
    ...state,
    updatePayrollSettingsState: 'Success',
    recalculateDeductionsError: undefined,
  })),
  on(
    NextPaymentActions.updatePayrollSettingsFailure,
    (state: State, { error }) => ({
      ...state,
      updatePayrollSettingsState: 'Fail',
      recalculateDeductionsError: error,
    })
  ),
  on(NextPaymentActions.updatePaymentSuccess, (state) => ({
    ...state,
    updatePaymentState: 'Success',
  })),
  on(NextPaymentActions.updatePaymentFailure, (state: State, { errorMsg }) => ({
    ...state,
    updatePaymentState: 'Fail',
  })),
  on(
    NextPaymentActions.getMemberAddressItemsSuccess,
    (state: State, { memberAddressItems }) => ({
      ...state,
      memberAddressItems,
    })
  ),
  on(NextPaymentActions.getMemberAddressItemsFailure, (state: State) => ({
    ...state,
    memberAddressItems: [],
  })),
  on(
    NextPaymentActions.recalculateTotalDeductionsSuccess,
    (state: State, { totalDeductions }) => ({
      ...state,
      recalculateTotalDeductions: {
        recalculateTotalDeductionsState: STATE.SUCCESS,
        totalDeductions: totalDeductions,
      },
      recalculateDeductionsError: undefined,
    })
  ),
  on(
    NextPaymentActions.recalculateTotalDeductionsFailure,
    (state: State, { error }) => ({
      ...state,
      recalculateTotalDeductions: {
        recalculateTotalDeductionsState: STATE.FAIL,
        totalDeductions: 0,
      },
      recalculateDeductionsError: error,
    })
  ),
  on(NextPaymentActions.clearTotalDeductionsState, (state: State) => ({
    ...state,
    recalculateTotalDeductions: undefined,
  })),
  on(NextPaymentActions.updateMemoSuccess, (state) => ({
    ...state,
    updateMemoState: 'Success',
  })),
  on(NextPaymentActions.updateMemoFailure, (state: State, { errorMsg }) => ({
    ...state,
    updateMemoState: 'Fail',
  })),
  on(NextPaymentActions.removeMemoSuccess, (state) => ({
    ...state,
    removeMemoState: 'Success',
  })),
  on(NextPaymentActions.removeMemoFailure, (state: State, { errorMsg }) => ({
    ...state,
    removeMemoState: 'Fail',
  })),
  on(NextPaymentActions.getNotesSuccess, (state, paymentInfoNotes) => ({
    ...state,
    paymentInfoNotes,
  })),
  on(NextPaymentActions.getNotesFailure, (state) => ({
    ...state,
    paymentInfoNotes: null,
  })),
  on(NextPaymentActions.clearNextPayment, (state, {}) => ({
    ...state,
    addNoteState: '',
    updateMemoState: '',
    removeMemoState: '',
    updateEarningsState: '',
    updateDeductionState: '',
    updatePayrollSettingsState: '',
    recalculateDeductionsError: undefined,
    updatePaymentState: '',
    createOffCycleState: {
      state: '',
      newOffCyclePaymentId: '',
    },
    removeOffCyclePaymentState: '',
    withdrawOffCyclePayment: {},
    savePaymentInforConfigState: '',
  })),
  on(
    NextPaymentActions.getRepresentativePayeesSuccess,
    (state: State, { representativePayees }) => ({
      ...state,
      representativePayees: representativePayees,
    })
  ),
  on(NextPaymentActions.getRepresentativePayeesFailure, (state: State) => ({
    ...state,
    representativePayees: [],
  })),
  on(NextPaymentActions.getPendingPaymentRequest, (state) => ({
    ...state,
    pendingPaymentList: {
      isLoading: true,
      total: 0,
    },
  })),
  on(NextPaymentActions.getPendingPaymentSuccess, (state, { response }) => ({
    ...state,
    pendingPaymentList: {
      isLoading: false,
      total: response.total,
      payload: response,
      success: true,
    },
  })),
  on(NextPaymentActions.getPendingPaymentFailure, (state, { error }) => ({
    ...state,
    pendingPaymentList: {
      isLoading: false,
      success: false,
      error: error,
      total: 0,
    },
  })),
  on(NextPaymentActions.clearGetPendingPaymentLoadingState, (state) => ({
    ...state,
    pendingPaymentList: {
      isLoading: false,
      total: 0,
      pendingPayments: [],
      columns: [],
    },
  })),
  on(NextPaymentActions.clearGetPendingPaymentState, (state) => ({
    ...state,
    pendingPaymentList: {
      isLoading: false,
      total: 0,
      pendingPayments: [],
      columns: [],
    },
  })),
  on(NextPaymentActions.issueOffCyclePayment, (state) => ({
    ...state,
    issueOffCyclePayment: {
      isLoading: true,
      missingInfomation: '',
      issueState: '',
    },
  })),
  on(NextPaymentActions.issueOffCyclePaymentSuccess, (state) => ({
    ...state,
    issueOffCyclePayment: {
      isLoading: false,
      missingInfomation: '',
      issueState: STATE.SUCCESS,
    },
  })),
  on(NextPaymentActions.issueOffCyclePaymentFailure, (state, { error }) => {
    let missingInfomation = '';
    if (
      error &&
      error.errorType === ERROR_TYPE_MISSING_INFO &&
      error.errorMessage
    ) {
      missingInfomation = error.errorMessage[0];
    }
    return {
      ...state,
      issueOffCyclePayment: {
        isLoading: false,
        missingInfomation,
        issueState: STATE.FAIL,
      },
    };
  }),
  on(NextPaymentActions.clearIssueOffCyclePaymentState, (state) => ({
    ...state,
    issueOffCyclePayment: {
      isLoading: false,
      missingInfomation: '',
      issueState: '',
    },
  })),
  on(NextPaymentActions.getPaymentDeductionsRequest, (state) => ({
    ...state,
    paymentDeductions: [],
  })),
  on(
    NextPaymentActions.getPaymentDeductionsSuccess,
    (state, { paymentDeductions }) => ({
      ...state,
      paymentDeductions,
    })
  ),
  on(NextPaymentActions.getPaymentDeductionsFailure, (state) => ({
    ...state,
    paymentDeductions: [],
  })),
  on(
    NextPaymentActions.createOffCyclePaymentSuccess,
    (state, { newOffCyclePaymentId }) => ({
      ...state,
      createOffCycleState: {
        state: STATE.SUCCESS,
        newOffCyclePaymentId,
      },
    })
  ),
  on(NextPaymentActions.createOffCyclePaymentFailure, (state) => ({
    ...state,
    createOffCycleState: {
      state: STATE.FAIL,
      newOffCyclePaymentId: '',
    },
  })),
  on(NextPaymentActions.clearCreateOffCyclePaymentState, (state) => ({
    ...state,
    createOffCycleState: {
      state: '',
      newOffCyclePaymentId: '',
    },
  })),
  on(
    NextPaymentActions.reloadPendingPayments,
    (state, { isReload, selectedRowId }) => ({
      ...state,
      reloadPendingPaymentsState: {
        isReload,
        selectedRowId,
      },
    })
  ),
  on(NextPaymentActions.clearReloadPendingPaymentsState, (state) => ({
    ...state,
    reloadPendingPaymentsState: {
      isReload: false,
      selectedRowId: '',
    },
  })),
  on(NextPaymentActions.getParticipantNextPaymentRequest, (state) => ({
    ...state,
    participantNextPaymentSetting: {},
  })),
  on(
    NextPaymentActions.getParticipantNextPaymentSuccess,
    (state, { participantNextPaymentSetting }) => ({
      ...state,
      participantNextPaymentSetting,
    })
  ),
  on(NextPaymentActions.getParticipantNextPaymentFailure, (state) => ({
    ...state,
    participantNextPaymentSetting: {},
  })),

  on(NextPaymentActions.withdrawOffCyclePaymentSuccess, (state, {}) => ({
    ...state,
    withdrawOffCyclePayment: {
      withdrawOffCyclePaymentState: STATE.SUCCESS,
    },
  })),
  on(NextPaymentActions.withdrawOffCyclePaymentFailure, (state) => ({
    ...state,
    withdrawOffCyclePayment: {
      withdrawOffCyclePaymentState: STATE.FAIL,
    },
  })),

  on(NextPaymentActions.removeOffCyclePaymentRequest, (state) => ({
    ...state,
    removeOffCyclePaymentState: '',
  })),
  on(NextPaymentActions.removeOffCyclePaymentSuccess, (state) => ({
    ...state,
    removeOffCyclePaymentState: STATE.SUCCESS,
  })),
  on(NextPaymentActions.removeOffCyclePaymentFailure, (state) => ({
    ...state,
    removeOffCyclePaymentState: STATE.FAIL,
  })),
  on(
    NextPaymentActions.getPaymentInforConfigSuccess,
    (state, { paymentInforConfigData }) => ({
      ...state,
      paymentInforConfig: paymentInforConfigData,
    })
  ),
  on(NextPaymentActions.savePaymentInforConfigFailure, (state) => ({
    ...state,
    paymentInforConfig: [],
  })),
  on(NextPaymentActions.savePaymentInforConfigSuccess, (state) => ({
    ...state,
    savePaymentInforConfigState: 'Success',
  })),
  on(NextPaymentActions.savePaymentInforConfigFailure, (state) => ({
    ...state,
    savePaymentInforConfigState: 'Fail',
  }))
);
