<div class="list-display" [ngClass]="{'hidden': !pendingPaymentsData.length}">
    <div class="header">
        <span class="title">Pending Payments</span>
        <div class="btn-add" (click)="newOffCycle()" *ptgCheckPermission = "['processing','lump_sum_payments']">
            <mat-icon>add</mat-icon>
            <span class="btn-title">New Off-Cycle</span>
        </div>
    </div>
    <ptg-grid
        #table
        [data]="pendingPaymentsData"
        [columns]="currentColumns"
        [isLoading]="isLoading"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="changePagging($event)"
        (sortChange)="sortChange($event)"
        (rowClick)="selectRow($event)"
        [(currentRowIndex)]="currentRowIndex"
        [errorMessage]="errorMsg"
        notFoundMessage="No Pending Payments to Display"
    >
    <ng-template cellContent columnName="status" let-row>
      <div class="icon-status-container"
        #tooltip="matTooltip"
        matTooltip={{row?.tooltipPayStatus}}
        matTooltipClass="custom-tooltip {{row?.className}}"
        matTooltipPosition="below"
      >
        <mat-icon *ngIf="row?.iconName" class="icon-status" [ngStyle]="{'color': row?.color}">{{row?.iconName}}</mat-icon>
      </div>
    </ng-template>
    <ng-template cellContent columnName="note" let-row>
      <div>
        <mat-icon *ngIf="row.note" svgIcon="note-icon" class="note-icon"></mat-icon>
      </div>
    </ng-template>
    <ng-template cellContent columnName="type" let-row>
      {{ row?.type === PaymentInfoType.Recurring ? 'Recurring': (row?.type === PaymentInfoType.Reissue ? 'Reissue' : 'Off-Cycle')}}
      </ng-template>
  </ptg-grid>
</div>
