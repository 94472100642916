import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers/index';

import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Align, Column, Row } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { takeUntil } from 'rxjs/operators';

import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';

import { MemberListActions } from '../../actions';
import * as MetadataActions from '../../actions/metadata.actions';
import * as ParticipantSearchConfigurationActions from '../../actions/participant-search-configuration.actions';
import { AddSectionComponent } from '../../components/add-section/add-section.component';
import { SectionLayout } from '../../constance/metadata.const';
import { MetadataSection } from '../../types/models';
import * as fromMember from '../../reducers';

@Component({
  selector: 'ptg-member-metadata',
  templateUrl: './member-metadata.component.html',
  styleUrls: ['./member-metadata.component.scss'],
})
export class MemberMetadataComponent extends BaseComponent {
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Section Name',
      },
      width: '25%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'type',
      header: {
        title: 'Is List',
      },
      align: Align.Center,
    },
    {
      name: 'importKey',
      header: {
        title: 'Import Label',
      },
      width: '25%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'propertyCount',
      header: {
        title: 'Property Count',
      },
      width: '15%',
      align: Align.Center,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Actions',
      },
      width: '230px',
    },
  ];
  metaDatasData: (MetadataSection & Row)[] = [];
  isLoading: boolean = true;
  currentFund: any = {};
  message = '';
  sectionName = '';
  errorMsg?: string;
  bannerType: BannerType = BannerType.Hidden;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Participant Metadata',
      url: '',
    },
  ];
  activeFund: any;
  lstFillter: any[] = [];
  sortInfo: {} | any = {};

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private memberMetadataStore: Store<fromMember.MemberState>,
    private layoutStore: Store<fromLayoutReducer.LayoutState>,
    public dialog: MatDialog,
    private router: Router,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.getData();
    this.memberMetadataStore
      .pipe(
        select(fromMember.selectMetadataState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state.isContinue && state.newSectionKey) {
          this.memberMetadataStore.dispatch(MetadataActions.clearMetadata());
          this.memberMetadataStore.dispatch(MetadataActions.getMetadata({}));
        }

        if (state.removeState === 'Success') {
          this.memberStore.dispatch(
            ParticipantSearchConfigurationActions.getParticipantSearchConfiguration()
          );
          this.layoutStore.dispatch(LayoutActions.getBulkContentItemRequest());
          this.memberMetadataStore.dispatch(MetadataActions.clearMetadata());
          this.memberMetadataStore.dispatch(MetadataActions.getMetadata({}));
          showBanner.call(
            this,
            BannerType.Success,
            this.sectionName,
            ACTION.REMOVE
          );
        }

        if (!state.createSuccess) {
          this.layoutStore.dispatch(LayoutActions.getBulkContentItemRequest());
        }

        if (state.removeState === 'Fail') {
          this.memberMetadataStore.dispatch(MetadataActions.clearMetadata());
          this.memberMetadataStore.dispatch(MetadataActions.getMetadata({}));
          showBanner.call(
            this,
            BannerType.Fail,
            this.sectionName,
            ACTION.REMOVE
          );
        }

        // Get an error message (if any)
        if (!state.metadata.success && state.metadata.error) {
          this.errorMsg = state.metadata.error.statusText;
        }

        if (state.metadata.success && state.metadata.payload) {
          this.metaDatasData = state.metadata.payload.map((section) => {
            return {
              ...section,
              showMark: section.type === SectionLayout.List,
              propertyCount: section.properties?.length,
            };
          });
        }
        this.isLoading = state.metadata.isLoading;
      });

    this.memberStore
      .pipe(select(fromMember.selectFiltersState))
      .subscribe((filters) => {
        if (filters) {
          this.lstFillter = filters;
        }
      });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getData();
  }

  getData() {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      (sortField = this.sortInfo.active),
        (sortType = this.sortInfo.direction === 'desc' ? 1 : 0);
    }

    this.memberMetadataStore.dispatch(
      MetadataActions.getMetadata({
        query: {
          sortField,
          sortType,
        },
      })
    );
  }

  editSection(event: MetadataSection & Row) {
    this.router.navigateByUrl('/member/member-metadata/' + event.key);
  }

  addSection() {
    this.dialog.open(AddSectionComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
    });
  }

  onClickBreadcrumb(url: string) {
    if (url === '/member') {
      this.store.dispatch(LayoutActions.closeSideMenu());
    }
  }

  onRemoveClick(event: MetadataSection & Row) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Destruct,
        text: `Removing this section will remove all of its related participants data and all filters using its properties.<br> Are you sure you want to proceed?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sectionName = event.name;
        this.memberMetadataStore.dispatch(
          MetadataActions.removeSectionMetadata({ metadataKey: event.key })
        );
        if (this.lstFillter && this.lstFillter.length > 0) {
          const filter = this.lstFillter.filter(
            (o) => o.sectionKey?.toLowerCase() != event.key?.toLowerCase()
          );
          if (filter.length != this.lstFillter.length) {
            this.memberStore.dispatch(
              MemberListActions.setMemberFilter({ filters: filter })
            );
          }
        }
      }
    });
  }
}
