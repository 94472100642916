import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/models';

import { MemberDetailActions } from '../actions';
import {
  AddSectionState,
  MemberDetail,
  MemberMetadataAttachmentState,
  MemberStatus,
  MetadataOverView,
  MetadataSection,
  PayrollConfigurationInfo,
} from '../types/models';

export interface MemberDetailState {
  isLoading: boolean;
  isDeleting: boolean;
  wasDeleted: boolean;
  memberDetail: MemberDetail | null;
  memberDetailUnMasked: MemberDetail | null;
  tmp: any;
  addSectionState: AddSectionState;
  getMetadataSections?: BaseActionState<MetadataSection[]>;
  getMetadataNonListSections?: BaseActionState<{
    nonListSections: MetadataSection[];
    memberNavigationItemId: string;
  }>;
  memberInfo: any;
  removeSectionState?: string;
  lockMemberState: string;
  unLockMemberState: string;
  removeMemberState: string;
  listStatus: MemberStatus[];
  setMemberEventState: string;
  getMemberDetailWithPaging?: BaseActionState<any>;
  memberMetadataAttachmentState: MemberMetadataAttachmentState;
  metadataOverview: MetadataSection[] | null;
  profileOverviewName: string;
  isLoadingMemberInfo: boolean;
  isLoadingMetadataOverview: boolean;
  payrollConfigurationInfo?: PayrollConfigurationInfo;
  getMemberAttachments?: BaseActionState<any[]>;
  profileOverviewConfig: MetadataOverView;
}

const initialState: MemberDetailState = {
  isLoading: false,
  isDeleting: false,
  wasDeleted: false,
  memberDetail: {},
  memberDetailUnMasked: {},
  tmp: {},
  addSectionState: {} as AddSectionState,
  memberInfo: {},
  removeSectionState: '',
  lockMemberState: '',
  removeMemberState: '',
  unLockMemberState: '',
  listStatus: [],
  setMemberEventState: '',
  memberMetadataAttachmentState: {} as MemberMetadataAttachmentState,
  metadataOverview: [],
  profileOverviewName: '',
  isLoadingMemberInfo: true,
  isLoadingMetadataOverview: false,
  profileOverviewConfig: {} as MetadataOverView,
};

export const memberDetailReducer = createReducer(
  initialState,

  on(MemberDetailActions.getMemberAttachmentsAction, (state) => ({
    ...state,
    isLoading: true,
    getMemberAttachments: {
      isLoading: true,
    },
  })),
  on(
    MemberDetailActions.getMemberAttachmentsSuccessAction,
    (state, { response }) => ({
      ...state,
      isLoading: false,
      getMemberAttachments: {
        isLoading: false,
        success: true,
        payload: response?.items,
        total: response?.total,
      },
    })
  ),
  on(
    MemberDetailActions.getMemberAttachmentsFailureAction,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      getMemberAttachments: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),

  on(MemberDetailActions.getMemberDetail, (state, { id }) => ({
    ...state,
    isLoading: true,
    memberDetail: null,
  })),
  on(MemberDetailActions.getMemberDetailSuccess, (state, { memberDetail }) => {
    const temp = { ...memberDetail };
    Object.entries(temp).forEach(([key, value]) => {
      if (
        !value ||
        Object.keys(value).length === 0 ||
        (value.table && !value.table.length)
      ) {
        temp[key as keyof MemberDetail] = null;
      }
    });
    return {
      ...state,
      isLoading: false,
      memberDetail: temp,
    };
  }),
  on(MemberDetailActions.getMemberDetailFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    memberDetail: null,
  })),
  on(MemberDetailActions.getMemberById, (state, { id }) => ({
    ...state,
    isLoadingMemberInfo: true,
    memberInfo: null,
  })),
  on(MemberDetailActions.getMemberByIdSuccess, (state, { memberInfo }) => ({
    ...state,
    isLoadingMemberInfo: false,
    memberInfo,
  })),
  on(MemberDetailActions.getMemberByIdFailure, (state) => ({
    ...state,
    isLoadingMemberInfo: false,
    memberInfo: null,
  })),

  on(MemberDetailActions.getMetadataSectionsAction, (state) => ({
    ...state,
    getMetadataSections: {
      isLoading: true,
    },
  })),
  on(
    MemberDetailActions.getMetadataSectionsSuccessAction,
    (state, { metadata, listStatus }) => ({
      ...state,
      getMetadataSections: {
        isLoading: false,
        success: true,
        payload: metadata,
        updatedAt: DateTime.now().toISO(),
      },
      listStatus,
    })
  ),
  on(
    MemberDetailActions.getMetadataSectionsFailureAction,
    (state, { error }) => ({
      ...state,
      getMetadataSections: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(MemberDetailActions.clearGetMetadataSectionsStateAction, (state) => ({
    ...state,
    getMetadataSections: undefined,
  })),

  on(MemberDetailActions.clearMemberDetail, (state) => ({
    ...initialState,
    memberDetail: null,
  })),

  on(MemberDetailActions.getMetadataNonListSectionsAction, (state) => ({
    ...state,
    getMetadataNonListSections: {
      isLoading: true,
    },
  })),
  on(
    MemberDetailActions.getMetadataNonListSectionsSuccessAction,
    (state, { response, memberNavigationItemId }) => ({
      ...state,
      getMetadataNonListSections: {
        isLoading: false,
        success: true,
        payload: {
          nonListSections: response.metadata ?? [],
          memberNavigationItemId,
        },
        updatedAt: DateTime.now().toISO(),
      },
    })
  ),
  on(
    MemberDetailActions.getMetadataNonListSectionsFailureAction,
    (state: MemberDetailState, { error }) => ({
      ...state,
      getMetadataNonListSections: createFailureState(error),
    })
  ),
  on(
    MemberDetailActions.clearGetMetadataNonListSectionsStateAction,
    (state) => ({
      ...state,
      getMetadataNonListSections: undefined,
    })
  ),

  on(MemberDetailActions.removeMember, (state, {}) => ({
    ...state,
    isDeleting: true,
    memberDetail: null,
  })),
  on(MemberDetailActions.removeMemberSuccess, (state, {}) => ({
    ...state,
    isDeleting: false,
    removeMemberState: STATE.SUCCESS,
    wasDeleted: true,
    memberDetail: null,
  })),
  on(MemberDetailActions.resetStateRemoveMember, (state, {}) => ({
    ...state,
    wasDeleted: false,
    lockMemberState: '',
    unLockMemberState: '',
    removeMemberState: '',
    addSectionState: {} as AddSectionState,
    memberMetadataAttachmentState: {},
  })),
  on(MemberDetailActions.removeMemberFailure, (state, {}) => ({
    ...state,
    isDeleting: false,
    removeMemberState: STATE.FAIL,
  })),

  on(MemberDetailActions.removeMemberSection, (state, { sectionKey }) => {
    let memberDetail: any = { ...state.memberDetail };
    let currentSection: any = {};
    const temp = { ...state.tmp };
    temp[`${sectionKey}`] = memberDetail[sectionKey];
    const currentSectionOverview = state.metadataOverview?.find(
      (x) => x.key === sectionKey
    );
    if (!currentSectionOverview) return state;
    if (currentSectionOverview.type) {
      currentSection = { ...memberDetail[currentSectionOverview.key] };
      const notBenefitProperties = currentSectionOverview.properties.filter(
        (x) => x.type !== 'Benefit'
      );
      notBenefitProperties.forEach((bp) => {
        delete currentSection[bp.key];
      });
    } else {
      currentSection = null;
    }

    return {
      ...state,
      memberDetail: {
        ...memberDetail,
        [currentSectionOverview.key]: currentSection,
      },
      tmp: temp,
    };
  }),
  on(
    MemberDetailActions.removeMemberSectionSuccess,
    (state, { sectionKey }) => {
      const temp = { ...state.tmp };
      temp[`${sectionKey}`] = null;
      return {
        ...state,
        removeSectionState: STATE.SUCCESS,
        memberDetail: {
          ...state.memberDetail,
        },
        tmp: temp,
      };
    }
  ),
  on(
    MemberDetailActions.removeMemberSectionFailure,
    (state, { sectionKey }) => {
      const temp = { ...state.tmp };
      temp[sectionKey] = null;
      const memberDetail = { ...state.memberDetail } as any;
      memberDetail[sectionKey] = state.tmp[sectionKey];
      return {
        ...state,
        removeSectionState: STATE.FAIL,
        memberDetail: memberDetail,
        tmp: temp,
      };
    }
  ),
  on(MemberDetailActions.removeSectionReset, (state, {}) => ({
    ...state,
    removeSectionState: '',
  })),
  on(MemberDetailActions.lockMember, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(MemberDetailActions.lockMemberSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    lockMemberState: STATE.SUCCESS,
    memberInfo: {
      ...state.memberInfo,
      isLocked: true,
    },
  })),
  on(MemberDetailActions.lockMemberFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    lockMemberState: STATE.FAIL,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unlockMember, (state, {}) => ({
    ...state,
    isLoading: true,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unlockMemberSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    unLockMemberState: STATE.SUCCESS,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unlockMemberFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    unLockMemberState: STATE.FAIL,
    memberInfo: {
      ...state.memberInfo,
      isLocked: true,
    },
  })),
  on(
    MemberDetailActions.addSectionTableSuccess,
    (state, { sectionData, memberId }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        saveSectionDataSuccess: true,
        sectionData,
        memberId,
        reloadSection: true,
        isEdit: false,
        isRemove: false,
        error: undefined,
      },
    })
  ),
  on(MemberDetailActions.addSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      saveSectionDataSuccess: false,
      reloadSection: true,
      isEdit: false,
      isRemove: false,
      error: error,
    },
  })),
  on(MemberDetailActions.resetAddSectionState, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      saveSectionDataSuccess: false,
      isEdit: false,
      isRemove: false,
      error: undefined,
    },
  })),
  on(MemberDetailActions.getSectionData, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      reloadSection: false,
      error: undefined,
    },
  })),
  on(
    MemberDetailActions.getSectionDataSuccess,
    (state, { metaDataPropertyValues, sectionKey }) => ({
      ...state,
      memberDetail: {
        ...state.memberDetail,
        [sectionKey]: metaDataPropertyValues,
      },
      addSectionState: {
        ...state.addSectionState,
        metaDataPropertyValues,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.getSectionDataFailure,
    (state, { error, sectionKey }) => ({
      ...state,
      memberDetail: {
        ...state.memberDetail,
        [sectionKey]: undefined,
      },
      addSectionState: {
        ...state.addSectionState,
        metaDataPropertyValues: { table: [] },
        error: error,
      },
    })
  ),
  on(MemberDetailActions.reorderSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      reloadSection: true,
      error: error,
    },
  })),
  on(
    MemberDetailActions.addSectionDataListViewSuccess,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        saveSectionDataSuccess: true,
        reloadSection: true,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.addSectionDataListViewFailure,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        saveSectionDataSuccess: false,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.editSectionDataListViewSuccess,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        isEdit: true,
        saveSectionDataSuccess: true,
        reloadSection: true,
        sectionData,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.editSectionDataListViewFailure,
    (state, { error, sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        isEdit: true,
        saveSectionDataSuccess: false,
        sectionData,
        error: error,
      },
    })
  ),
  on(MemberDetailActions.getSectionOverview, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      error: undefined,
    },
  })),
  on(
    MemberDetailActions.getSectionOverviewSuccess,
    (state, { sectionOverview }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData: sectionOverview.sectionData,
        metaDataPropertyValues: sectionOverview.metaDataPropertyValues,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.editSectionTableSuccess,
    (state, { sectionData, memberId }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        memberId,
        isEdit: true,
        isRemove: false,
        isUploadFile: false,
        saveSectionDataSuccess: true,
        reloadSection: true,
        error: undefined,
      },
    })
  ),
  on(MemberDetailActions.editSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      isEdit: true,
      isRemove: false,
      isUploadFile: false,
      saveSectionDataSuccess: false,
      reloadSection: true,
      error: error,
    },
  })),
  on(
    MemberDetailActions.removeSectionTableSuccess,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        isEdit: false,
        isRemove: true,
        isUploadFile: false,
        saveSectionDataSuccess: true,
        reloadSection: true,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.removeSectionTableFailure,
    (state, { error, sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        isEdit: false,
        isRemove: true,
        isUploadFile: false,
        saveSectionDataSuccess: false,
        reloadSection: true,
        error: error,
      },
    })
  ),
  on(MemberDetailActions.uploadFileSectionTableSuccess, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      isEdit: false,
      isRemove: false,
      isUploadFile: true,
      saveSectionDataSuccess: true,
      reloadSection: true,
      error: undefined,
    },
  })),
  on(MemberDetailActions.uploadFileSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      isEdit: false,
      isRemove: false,
      isUploadFile: true,
      saveSectionDataSuccess: false,
      reloadSection: true,
      error: error,
    },
  })),
  on(MemberDetailActions.setMemberEventSuccess, (state) => ({
    ...state,
    setMemberEventState: STATE.SUCCESS,
  })),
  on(MemberDetailActions.setMemberEventFailure, (state) => ({
    ...state,
    setMemberEventState: STATE.FAIL,
  })),
  on(MemberDetailActions.clearSetMemberEventState, (state) => ({
    ...state,
    setMemberEventState: '',
  })),

  on(MemberDetailActions.getMemberDetailWithPagingAction, (state) => ({
    ...state,
    getMemberDetailWithPaging: {
      isLoading: true,
    },
  })),
  on(
    MemberDetailActions.getMemberDetailWithPagingSuccessAction,
    (state, { response }) => ({
      ...state,
      getMemberDetailWithPaging: {
        isLoading: false,
        success: true,
        payload: response?.data,
        total: response?.total,
      },
    })
  ),
  on(
    MemberDetailActions.getMemberDetailWithPagingFailureAction,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      getMemberDetailWithPaging: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),

  on(MemberDetailActions.createMemberUploadAttachments, (state) => ({
    ...state,
    isLoading: true,
    memberMetadataAttachmentState: { isLoading: true },
  })),
  on(MemberDetailActions.createMemberUploadAttachmentsSuccess, (state) => ({
    ...state,
    isLoading: false,
    memberMetadataAttachmentState: {
      ...state.memberMetadataAttachmentState,
      success: true,
      isCreate: true,
      isEdit: false,
      isRemove: false,
      isLoading: false,
    },
  })),
  on(
    MemberDetailActions.createMemberUploadAttachmentsFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: false,
        isCreate: true,
        isEdit: false,
        isRemove: false,
        isLoading: false,
      },
    })
  ),

  on(MemberDetailActions.updateMemberUploadAttachments, (state) => ({
    ...state,
    isLoading: true,
    memberMetadataAttachmentState: { isLoading: true },
  })),
  on(MemberDetailActions.updateMemberUploadAttachmentsSuccess, (state) => ({
    ...state,
    isLoading: false,
    memberMetadataAttachmentState: {
      ...state.memberMetadataAttachmentState,
      success: true,
      isCreate: false,
      isEdit: true,
      isRemove: false,
      isLoading: false,
    },
  })),
  on(
    MemberDetailActions.updateMemberUploadAttachmentsFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: false,
        isCreate: false,
        isEdit: true,
        isRemove: false,
        isLoading: false,
      },
    })
  ),

  on(MemberDetailActions.updateMemberUploadAttachments, (state) => ({
    ...state,
    isLoading: true,
    memberMetadataAttachmentState: { isLoading: true },
  })),
  on(
    MemberDetailActions.removeMemberUploadAttachmentsSuccess,
    (state, { body }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: true,
        isCreate: false,
        isEdit: false,
        isRemove: true,
        documentName: body.documentName,
        isLoading: false,
      },
    })
  ),
  on(
    MemberDetailActions.removeMemberUploadAttachmentsFailure,
    (state, { errorMsg, documentName }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: false,
        isCreate: false,
        isEdit: false,
        isRemove: true,
        documentName: documentName,
        isLoading: false,
      },
    })
  ),

  on(MemberDetailActions.resetMemberMetadataAttachmentState, (state, {}) => ({
    ...state,
    isLoading: false,
    memberMetadataAttachmentState: {},
  })),

  on(MemberDetailActions.getMetadataBasedOnProfileOverviewConfig, (state) => ({
    ...state,
    isLoadingMetadataOverview: true,
  })),
  on(
    MemberDetailActions.getMetadataBasedOnProfileOverviewConfigSuccess,
    (
      state,
      {
        metadataOverview,
        listStatus,
        profileOverviewName,
        profileOverviewConfig,
      }
    ) => ({
      ...state,
      isLoadingMetadataOverview: false,
      metadataOverview,
      listStatus,
      profileOverviewName,
      profileOverviewConfig,
    })
  ),
  on(
    MemberDetailActions.getMetadataBasedOnProfileOverviewConfigFailure,
    (state) => ({
      ...state,
      isLoadingMetadataOverview: false,
    })
  ),
  on(
    MemberDetailActions.getMemberDetailUnMaskedSuccess,
    (state, { memberDetailUnMasked }) => ({
      ...state,
      memberDetailUnMasked,
    })
  ),
  on(MemberDetailActions.getMemberDetailUnMaskedFailure, (state) => ({
    ...state,
    memberDetailUnMasked: null,
  })),
  on(MemberDetailActions.getMemberConfiguredIdNameRequest, (state) => ({
    ...state,
    payrollConfigurationInfo: undefined,
  })),
  on(
    MemberDetailActions.getMemberConfiguredIdNameSuccess,
    (state, { payrollConfigurationInfo }) => ({
      ...state,
      payrollConfigurationInfo,
    })
  ),
  on(MemberDetailActions.getMemberConfiguredIdNameFailure, (state) => ({
    ...state,
    payrollConfigurationInfo: undefined,
  }))
);
const createFailureState = (error: any) => {
  return {
    isLoading: false,
    success: false,
    error: error,
  };
};
