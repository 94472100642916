import { createAction, props } from '@ngrx/store';
import { ParametersQuery } from '@ptg-employer/models';
import { IEditReportConfig } from '@ptg-member/components/participant-report/report-config/report-config.component';
import { GetGeneratedReportResponse, GetManageReportResponse, GetReportCategoryNameResponse, ReportCategorySidebar } from '@ptg-member/types/models/participant-report.model';

export const ClearStateParticipantReport = '[ClearStateParcitipantReport]';

export const clearStateParticipantReport = createAction(
    ClearStateParticipantReport
);


export const GetManageReportRequest = '[GetManageReport/API] Send Request';
export const GetManageReportSuccess = '[GetManageReport/API] Success';
export const GetManageReportFailure = '[GetManageReport/API] Failure';

export const getManageReportRequest = createAction(
    GetManageReportRequest,
    props<{ query: ParametersQuery }>()
);
export const getManageReportSuccess = createAction(
    GetManageReportSuccess,
    props<{ res: GetManageReportResponse }>()
);
export const getManageReportFailure = createAction(
    GetManageReportFailure,
    props<{ error?: any }>()
);


export const GetCategorySidebarRequest = '[GetCategorySidebar/API] Send Request';
export const GetCategorySidebarSuccess = '[GetCategorySidebar/API] Success';
export const GetCategorySidebarFailure = '[GetCategorySidebar/API] Failure';

export const getCategorySidebar = createAction(
    GetCategorySidebarRequest,
);
export const getCategorySidebarSuccess = createAction(
    GetCategorySidebarSuccess,
    props<{ reportCategory: ReportCategorySidebar[]}>()
);
export const getCategorySidebarFailure = createAction(
    GetCategorySidebarFailure,
    props<{ error?: any }>()
);


export const DeleteReportRequest = '[DeleteReport/API] Send Request';
export const DeleteReportSuccess = '[DeleteReport/API] Success';
export const DeleteReportFailure = '[deleteReport/API] Failure';

export const deleteReport = createAction(
    DeleteReportRequest,
    props<{ reportId: string }>()
);
export const deleteReportSuccess = createAction(
    DeleteReportSuccess
);
export const deleteReportFailure = createAction(
    DeleteReportFailure,
    props<{ error?: any }>()
);


export const CreateReportConfig = '[CreateReportConfig/API] Send Request';
export const CreateReportConfigSuccess = '[CreateReportConfig/API] Success';
export const CreateReportConfigFailure = '[CreateReportConfig/API] Failure';

export const createReportConfig = createAction(
    CreateReportConfig,
    props<{ body: IEditReportConfig }>()
);
export const createReportConfigSuccess = createAction(
    CreateReportConfigSuccess
);
export const createReportConfigFailure = createAction(
    CreateReportConfigFailure,
    props<{ error?: any }>()
);


export const GetReportCategoryName = '[GetReportCategoryName/API] Send Request';
export const GetReportCategoryNameSuccess = '[GetReportCategoryName/API] Success';
export const GetReportCategoryNameFailure = '[GetReportCategoryName/API] Failure';

export const getReportCategoryName = createAction(
    GetReportCategoryName
);
export const getReportCategoryNameSuccess = createAction(
    GetReportCategoryNameSuccess,
    props<{ res: GetReportCategoryNameResponse}>()
);
export const getReportCategoryNameFailure = createAction(
    GetReportCategoryNameFailure,
    props<{ error?: any }>()
);


export const EditReportConfig = '[EditReportConfig/API] Send Request';
export const EditReportConfigSuccess = '[EditReportConfig/API] Success';
export const EditReportConfigFailure = '[EditReportConfig/API] Failure';

export const editReportConfig = createAction(
    EditReportConfig,
    props<{ reportId: string, body: IEditReportConfig }>()
);
export const editReportConfigSuccess = createAction(
    EditReportConfigSuccess
);
export const editReportConfigFailure = createAction(
    EditReportConfigFailure,
    props<{ error?: any }>()
);


export const GetGeneratedReportRequest = '[GetGeneratedReport/API] Send Request';
export const GetGeneratedReportSuccess = '[GetGeneratedReport/API] Success';
export const GetGeneratedReportFailure = '[GetGeneratedReport/API] Failure';

export const getGeneratedReportRequest = createAction(
    GetGeneratedReportRequest,
    props<{ query: ParametersQuery }>()
);
export const getGeneratedReportSuccess = createAction(
    GetGeneratedReportSuccess,
    props<{ res: GetGeneratedReportResponse }>()
);
export const getGeneratedReportFailure = createAction(
    GetGeneratedReportFailure,
    props<{ error?: any }>()
);


export const DeleteGeneratedReportRequest = '[DeleteGeneratedReport/API] Send Request';
export const DeleteGeneratedReportSuccess = '[DeleteGeneratedReport/API] Success';
export const DeleteGeneratedReportFailure = '[DeleteGeneratedReport/API] Failure';
export const DeleteGeneratedReportClear = '[DeleteGeneratedReport/API] Clear';


export const deleteGeneratedReport = createAction(
    DeleteGeneratedReportRequest,
    props<{ reportId: string }>()
);
export const deleteGeneratedReportSuccess = createAction(
    DeleteGeneratedReportSuccess
);
export const deleteGeneratedReportFailure = createAction(
    DeleteGeneratedReportFailure,
    props<{ error?: any }>()
);
export const deleteGeneratedReportClear = createAction(
    DeleteGeneratedReportClear
);

