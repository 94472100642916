<div class="municipality-payment-container" id="municipality-payment-page">
  <ptg-overview-header
    #overViewHeader
    (changeMemberDetailEvent)="onChangeMemberDetail()"
  ></ptg-overview-header>

  <div class="flex flex-col p-6 gap-6">
    <ptg-municipality-group-aggregation [aggregationDetails]="listArregations">
    </ptg-municipality-group-aggregation>

    <ptg-detail-display-box
      *ngIf="dataTable && dataTable.length"
      title="Selected Payment Record"
      [nonEditItem]="dataTable[currentRowIndex]"
      [selectedItem]="dataTable[currentRowIndex]"
      [propertyDisplayed]="propertyDisplayed"
    ></ptg-detail-display-box>

    <ptg-list-display-box
      title="Payment History"
      pageName="{{ currentPageName }}"
      btnGenerate="Generate Service record"
      [isLoading]="isLoading"
      [dataTable]="dataTable"
      [columns]="columns"
      [sortInfo]="sortInfo"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [currentRowIndex]="currentRowIndex"
      (changePage)="changePage($event)"
      (sortChange)="sortChange($event)"
      (selectRow)="selectRow($event)"
    ></ptg-list-display-box>
  </div>
</div>
