import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MunicipalityPaymentAction from '../actions/municipality-payment.action';
import { MunicipalityPaymentService } from '../services/municipality-payment.service';
import { MunicipalityList } from '../types/models/status-history.model';

@Injectable()
export class MunicipalityPaymentEffects {
  getMunicipalityPaymentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityPaymentAction.getMunicipalityPaymentList),
      switchMap(({ query }) => {
        return this.municipalityPaymentService
          .getMunicipalityPaymentList(query)
          .pipe(
            map((payments: MunicipalityList) => {
              return MunicipalityPaymentAction.municipalityPaymentListSuccess({
                payments,
              });
            }),
            catchError((err) => {
              return of(
                MunicipalityPaymentAction.municipalityPaymentListFailure()
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private municipalityPaymentService: MunicipalityPaymentService
  ) {}
}
