<div class="report-detail">
    <ptg-breadcrumb 
        [listBreadcrumbs]="listBreadcrumbs" 
        [settings]="settings">
    </ptg-breadcrumb>
    <div class="drop-banner" *ngIf="bannerType">
        <ptg-banner
        [(bannerType)]="bannerType"
        [message]="message"
        ></ptg-banner>
    </div>
    <ptg-report-viewer #viewer1
        [containerStyle]="viewerContainerStyle"
        [serviceUrl]="apiUrl"
        [reportSource]="source"
        [viewMode]="'PRINT_PREVIEW'" 
        [scaleMode]="'FIT_PAGE_WIDTH'"
        [scale]="1.0"
        [enableAccessibility]="false"
        [parametersAreaVisible]="false"
        [documentMapVisible]="true"
        [documentMapAreaPosition]="'RIGHT'"
        [parameters]="{
            editors: {
                singleSelect: 'COMBO_BOX',
                multiSelect: 'COMBO_BOX'
            }
        }"
        [templateUrl]="'assets/telerikReportViewerTemplate.html'"
        [error]="onError"
        >
    </ptg-report-viewer>
</div>