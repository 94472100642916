import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberNotificationOptInsList } from '../types/models';
import { MemberNotificationOptInService } from '../services/member-notification-opt-in.service';
import * as NotificationActions from '../actions/member-notification-opt-in.action';

@Injectable()
export class MemberNotificationOptInsEffect {
  getNotificationOptIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.getNotificationOptIn),
      switchMap(({ memberId }) => {
        return this.memberNotificationOptInService
          .getNotificationOptIn(memberId)
          .pipe(
            map((memberNotificationOptIns: MemberNotificationOptInsList) => {
              return NotificationActions.getNotificationOptInSuccess({
                memberNotificationOptIns:
                  memberNotificationOptIns.memberNotificationOptIns,
              });
            }),
            catchError((err) => {
              return of(NotificationActions.getNotificationOptInFailure());
            })
          );
      })
    )
  );
  editNotificationOptIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.editNotificationOptIn),
      switchMap(({ memberId, body }) => {
        return this.memberNotificationOptInService
          .editNotificationOptIn(memberId, body)
          .pipe(
            map(() => {
              return NotificationActions.editNotificationOptInSuccess({
                optInType: body.optInType,
              });
            }),
            catchError((err) => {
              return of(
                NotificationActions.editNotificationOptInFailure({
                  optInType: body.optInType,
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private memberNotificationOptInService: MemberNotificationOptInService
  ) {}
}
