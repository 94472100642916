import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { DetailDisplay } from '@ptg-shared/models/detail-display.model';
import { DataType } from '@ptg-shared/constance/data-type.const';
import { PaginationComponent } from '@ptg-shared/controls/pagination/pagination.component';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromReducer from '@ptg-reducers';

import {
  GetListStatusHistoryQuery,
  History,
} from '../../types/models';
import * as fromMember from '../../reducers';
import * as MunicipalityPaymentActions from '../../actions/municipality-payment.action';
import { OverviewHeaderComponent } from '../../components/overview-header/overview-header.component';

@Component({
  selector: 'ptg-municipality-payment',
  templateUrl: './municipality-payment.component.html',
  styleUrls: ['./municipality-payment.component.scss'],
})
export class MunicipalityPaymentComponent implements OnInit, OnDestroy {
  @Input() currentPageName?: string;

  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Payments',
    },
  ];
  columns: Column[] = [
    {
      name: 'year',
      header: {
        title: 'Year',
      },
      sortable: true,
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality Name (ID)',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'disabilityFees',
      header: {
        title: 'Disability Fees',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
    },
    {
      name: 'muniPaidPension',
      header: {
        title: 'Municipality Paid Pension',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
    },
    {
      name: 'memberPaidPension',
      header: {
        title: 'Member Paid Pension',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
    },
    {
      name: 'sumOfPensionPayments',
      header: {
        title: 'Sum Of Pension Payments',
      },
      type: ColumnType.Decimal,
      sortable: true,
      templateArgs: { decimal: 2, unit: '$', unitPosition: 'left' },
    },
    {
      name: 'metRequirement',
      header: {
        title: 'Met Requirements',
      },
      sortable: true,
    },
    {
      name: 'lastEvent',
      header: {
        title: 'Last Event',
      },
      sortable: true,
      truncate: true,
    },
  ];
  currentFund: any = {};
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = false;
  dataTable: History[] = [];
  listArregations: any[] = [];
  memberId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  paginator?: PaginationComponent;
  propertyDisplayed: DetailDisplay[] = [
    { label: 'Year', propertyName: 'year', type: DataType.TYPE_NUMBER },
    {
      label: 'Receipt Number',
      propertyName: 'receiptNumber',
      type: DataType.TYPE_NUMBER,
    },
    {
      label: 'Disability Fees',
      propertyName: 'disabilityFees',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Member Paid Pension',
      propertyName: 'memberPaidPension',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Met Requirements',
      propertyName: 'metRequirement',
      type: DataType.TYPE_TEXT,
    },
    {
      label: 'Municipality',
      propertyName: 'municipalityName',
      type: DataType.TYPE_TEXT,
    },
    {
      label: 'Payment Date',
      propertyName: 'paymentDate',
      type: DataType.TYPE_DATE,
    },
    {
      label: 'Municipality Paid Pension',
      propertyName: 'muniPaidPension',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Sum Of Pension Payments',
      propertyName: 'sumOfPensionPayments',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Last Event',
      propertyName: 'lastEvent',
      type: DataType.TYPE_TEXT,
    },
  ];
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;

  constructor(
    private route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.getData();
    });
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.currentFund = el;
        this.pageSize = el.defaultPageSize ?? 50;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageSize')) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageSize'));

    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityPaymentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.payments?.total;
        this.isLoading = state?.isLoading;
        this.dataTable = state?.payments?.payments.map(
          (item, index: number) => {
            return {
              ...item,
              index: index,
              metRequirement:
                item.metRequirement === null
                  ? ''
                  : item.metRequirement
                  ? 'Yes'
                  : 'No',
            };
          }
        );
        this.listArregations = [
          {
            items: [
              {
                name: 'Total Service',
                value: state?.payments?.totalService,
              },
            ],
          },
        ];
      });
  }

  getData() {
    let sortType = 1;
    let sortNames = ['Year', 'MunicipalityName'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MunicipalityPaymentActions.getMunicipalityPaymentList({ query })
    );
  }

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + "-ptg-members-list-pageNumber", this.pageNumber.toString());
    sessionStorage.setItem(this.currentFund.key + "-ptg-members-list-pageSize", this.pageSize.toString()); 
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.dataTable.findIndex(
      (item) => item.id === event.id
    );
  }

  genereateRecord() {
    return;
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
