import { Component, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';

import * as FundListActions from '@ptg-fund-list/actions/fund-list.actions';
import { FundModel, Module } from '@ptg-fund-list/models/fund-list.model';
import { ResourceCenterService } from '@ptg-fund-management/services/resource-center.service';
import { MemberListActions } from '@ptg-member/actions';
import * as fromMember from '@ptg-member/reducers';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';
import {
  ReportCategorySidebar,
  ReportSidebar,
} from '@ptg-member/types/models/participant-report.model';
import * as fromReducer from '@ptg-reducers/index';

import { Auth0Service } from '../auth/services/auth0.service';
import { BaseComponent } from '../components';
import { PERMISSION_KEY } from '../constance/permission.const';
import { ConfirmPopupComponent } from '../controls/confirm-popup/confirm-popup.component';
import { PermissionModel } from '../models/permission.model';
import { CheckPermissionService } from '../services/check-permission.service';
import { HandleErrorService } from '../services/handle-error.service';
import { LayoutService } from '../services/layout.service';
import { FundType } from '../types/enums';
import {
  deepClone,
  deepEqual,
  deepFlattenArray,
  getCurrentFundData,
} from '../utils/common.util';
import { LayoutActions } from './actions';
import {
  DEFAULT_TAB,
  EMPLOYER_PORTAL_DEFAULT_TAB,
} from './components/captionbar/types/caption-bar.const';
import { Tab } from './components/captionbar/types/caption-bar.model';
import { DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS } from './constance/employer-portal-menu.const';
import {
  DisplayedTabName,
  HiddenTabName,
  OVERVIEW_URLS,
  SidebarOpenedStatus,
  STRING_QUERY_PARAM,
  TAB_PERMISSION,
  TAB_URLS,
} from './constance/layout.const';
import {
  BVFF_EMPLOYER_OVERVIEW_MENU_LIST,
  BVFF_EMPLOYERS_SETTING_MENU_GROUP,
  DEFAULT_TAB_MENU_GROUPS,
  EMPLOYER_OVERVIEW_MENU_LIST,
  MEMBER_OVERVIEW_MENU_LIST,
} from './constance/menu.const';
import { dispatchGetMemberNavigationList } from './helpers';
import { Menu, MenuGroup, TabName } from './models/layout.model';
import * as fromLayoutReducer from './reducers';

@Component({
  selector: 'ptg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent {
  readonly SIDE_BAR_OPENED_STATUS_ENUM = SidebarOpenedStatus;

  title = 'pension-admin';
  isLogin = false;
  isDisabledMenu = false;
  sidebarOpenedStatus: SidebarOpenedStatus = SidebarOpenedStatus.Opened;
  tabName: TabName = HiddenTabName.Hidden;
  tabs: Tab[] = this.defaultTabs;
  menuGroups: MenuGroup[] = [];
  allMenuGroups: Partial<Record<DisplayedTabName, MenuGroup[]>> =
    this.defaultTabMenuGroups;
  disabledSideBar: boolean = false;
  currentFund!: FundModel;
  isFirstLoad: boolean = true;
  get defaultTabs() {
    return deepClone(
      this.authService.isAdminPortal$.value
        ? DEFAULT_TAB
        : EMPLOYER_PORTAL_DEFAULT_TAB
    );
  }
  get defaultTabMenuGroups() {
    return deepClone(
      this.authService.isAdminPortal$.value
        ? DEFAULT_TAB_MENU_GROUPS
        : DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS
    );
  }

  constructor(
    private authService: Auth0Service,
    public router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    public checkPermissionService: CheckPermissionService,
    public memberDetailService: MemberDetailService,
    private handleErrorService: HandleErrorService,
    private memberStore: Store<fromMember.MemberState>,
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private resourceCenterService: ResourceCenterService,
    private cookieService: CookieService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.checkAuthenticationState();
    this.loadAllDynamicMenu();
    this.getAllMenuGroups();
    this.checkSelectedTab();
    this.checkSidebarState();
    this.checkNavigationEnd();
    this.checkCommonError();
    this.checkFundChange();
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Log Out',
        text: 'Are you sure you want to log out?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cookieService.delete('version');
        this.authService.logout();
      }
    });
  }

  checkCommonError() {
    this.store
      .select(fromLayoutReducer.selectInterceptorState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.showErrorDialog && state.error) {
          this.handleErrorService.showError$.next(state.error);
        }
      });
  }

  changeSelectedTab(tabName: TabName) {
    this.store.dispatch(LayoutActions.selectTab({ tab: tabName }));
  }

  checkAuthenticationState() {
    this.authService.initializeAuth();
    this.authService.isAuthenticated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated: boolean) => {
        this.isLogin = isAuthenticated;
        this.setDefaultLayoutValue();
        this.reloadCurrentFund();
        if (!this.isLogin) {
          localStorage.clear();
          location.reload();
        }
      });
  }

  checkSidebarState() {
    this.store
      .select(fromLayoutReducer.selectSidebarOpenedStatusState)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((sidebarOpenedStatusState) => {
        this.sidebarOpenedStatus = sidebarOpenedStatusState;
        this.controlSideMenu(sidebarOpenedStatusState);
      });
  }

  checkNavigationEnd() {
    this.router.events
      .pipe(
        pairwise(),
        tap(([previousEvent, currentEvent]) => {
          if (currentEvent instanceof NavigationCancel) {
            this.handleAfterRedirect(this.router.url);
          }
        }),
        map(([previousEvent, currentEvent]) => currentEvent),
        filter((event) => event instanceof NavigationEnd),
        startWith({ urlAfterRedirects: this.router.url }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        this.handleAfterRedirect((event as NavigationEnd).urlAfterRedirects);
      });
  }

  handleAfterRedirect(url: string) {
    // overview url /member/detail/:id/:sectionKey
    const isOverview =
      url.includes('/member/detail/') &&
      url.split('/').filter((element) => element).length === 4;
    const isEditFundCapability =
      url.includes('fund-list/capability-management/') &&
      url.split('/').filter((element) => element).length === 3;
    this.isDisabledMenu = isOverview || isEditFundCapability;
    const tabName = this.getTabByUrl(url);
    if (
      url !== '/fund-list' &&
      this.checkPermissionService.permission &&
      !this.checkPermissionService.checkCondition(
        url,
        this.checkPermissionService.permission
      )
    ) {
      url = this.checkPermissionService.getFirstAccessibleUrl(
        PERMISSION_KEY[TAB_PERMISSION[tabName as DisplayedTabName]]
      );
    }
    if (
      [
        DisplayedTabName.IndividualParticipant,
        DisplayedTabName.SearchedParticipant,
      ].includes(tabName as any)
    ) {
      dispatchGetMemberNavigationList(
        this.store,
        this.getMemberIdFromUrl(url) ?? '',
        this.isFirstLoad
      );
      this.isFirstLoad = false;
    }

    if (tabName === DisplayedTabName.Employers) {
      this.getDynamicEmployerMenuGroup(url);
    }
    this.store.dispatch(LayoutActions.selectTab({ tab: tabName, url }));
    this.getCurrentMenuGroups();
    const sidebarOpenedStatus = this.getDefaultSidebarOpenedState(url);
    this.store.dispatch(
      LayoutActions.setSidebarOpenedStatus({ sidebarOpenedStatus })
    );
  }

  getDynamicEmployerMenuGroup(url: string) {
    let currentMenuGroup = deepClone(this.allMenuGroups)[
      DisplayedTabName.Employers
    ];
    if (!currentMenuGroup) {
      return;
    }
    currentMenuGroup = this.getCurrentEmployerMenuGroup(url, currentMenuGroup);
    this.updateAllMenuGroup(DisplayedTabName.Employers, currentMenuGroup);
    this.getCurrentMenuGroups();
  }

  getCurrentEmployerMenuGroup(
    url: string,
    menuGroups: MenuGroup[]
  ): MenuGroup[] {
    const currentMenuGroups = deepClone(menuGroups);
    const isBVFFFund = this.layoutService.fundType === FundType.BVFF;
    if (
      this.router.url === '/employer/employer-management' ||
      this.router.url === '/employer/fee-amounts' ||
      this.router.url === '/employer/tolerance/parameters'
    ) {
      currentMenuGroups[0].menu = [];
      if (isBVFFFund) {
        currentMenuGroups[1] = BVFF_EMPLOYERS_SETTING_MENU_GROUP;
      }
      return currentMenuGroups;
    }
    let employerOverviewMenuList = [
      ...deepClone(
        isBVFFFund
          ? BVFF_EMPLOYER_OVERVIEW_MENU_LIST
          : EMPLOYER_OVERVIEW_MENU_LIST
      ),
    ];
    if (url.split('/')[3]) {
      let employerId = url.split('/')[3]?.split('?')?.[0];
      employerOverviewMenuList.forEach((menu) => {
        menu.menuItems = menu.menuItems?.map((menuItems) => {
          return {
            ...menuItems,
            routerLink: menuItems.routerLink.includes(employerId)
              ? menuItems.routerLink
              : `${menuItems.routerLink}/${employerId}`,
          };
        });
      });
    }
    currentMenuGroups[0].menu = [...deepClone(employerOverviewMenuList)];
    return currentMenuGroups;
  }

  getDefaultSidebarOpenedState(url: string) {
    const hiddenSideMenuUrls = [
      '/fund-management/app-content',
      '/member/member-metadata',
      '/processing/federal-taxes',
      '/member/profile-overview-configuration',
      '/fund-management/preferences/participant-id',
      '/employer/employer-list-configuration',
      '/employer/employer-overview-configuration',
      '/employer/employer-information-properties',
      '/fund-list/capability-management/',
      '/employer/remittance-submission/',
    ];
    const hiddenSideMenuEqualUrls = [
      '/permission',
      '/fund-list',
      '/processing',
    ];
    if (
      hiddenSideMenuUrls.some((hiddenUrl) => url.includes(hiddenUrl)) ||
      hiddenSideMenuEqualUrls.some((hiddenUrl) => url === hiddenUrl)
    ) {
      return SidebarOpenedStatus.Hidden;
    }
    const closedSideMenuUrls = [
      '/member',
      '/member/benefit',
      '/employer/employer-management',
    ];
    if (closedSideMenuUrls.some((closedUrl) => url === closedUrl)) {
      return SidebarOpenedStatus.Closed;
    }
    if (this.sidebarOpenedStatus === SidebarOpenedStatus.Hidden) {
      return SidebarOpenedStatus.Opened;
    }
    return this.sidebarOpenedStatus;
  }

  getTabByUrl(url: string) {
    if (!url) {
      return HiddenTabName.Hidden;
    }
    if (OVERVIEW_URLS.some((item) => url.includes(item))) {
      if (url.includes(STRING_QUERY_PARAM.SEARCH)) {
        return DisplayedTabName.SearchedParticipant;
      }
      return DisplayedTabName.IndividualParticipant;
    }
    if (
      TAB_URLS[DisplayedTabName.Employers].some((item) => url.includes(item))
    ) {
      return DisplayedTabName.Employers;
    }
    if (
      TAB_URLS[DisplayedTabName.Participants].some((item) => url.includes(item))
    ) {
      return DisplayedTabName.Participants;
    }
    if (
      TAB_URLS[DisplayedTabName.AppContent].some((item) => url.includes(item))
    ) {
      return DisplayedTabName.AppContent;
    }
    if (TAB_URLS[DisplayedTabName.Admin].some((item) => url.includes(item))) {
      return DisplayedTabName.Admin;
    }
    if (
      TAB_URLS[DisplayedTabName.Processing].some((item) => url.includes(item))
    ) {
      return DisplayedTabName.Processing;
    }
    return HiddenTabName.Hidden;
  }

  getAllMenuGroups() {
    this.store
      .select(fromLayoutReducer.selectAllMenuGroupsState)
      .pipe(
        distinctUntilChanged((previous, current) =>
          deepEqual(previous, current)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((allMenuGroupsState) => {
        this.allMenuGroups = allMenuGroupsState;
        this.getCurrentMenuGroups();
      });
  }

  checkSelectedTab() {
    this.store
      .select(fromLayoutReducer.selectTabsState)
      .pipe(
        distinctUntilChanged((previous, current) =>
          deepEqual(previous, current)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((tabs) => {
        this.tabs = tabs;
        const currentTab = this.getCurrentTab(tabs);
        if (currentTab && currentTab.url) {
          if (
            currentTab.tabName === DisplayedTabName.Participants &&
            this.authService.isAdminPortal$.value
          ) {
            this.store.dispatch(LayoutActions.getBulkContentItemRequest());
          }
          if (this.router.url !== currentTab.url) {
            void this.router.navigateByUrl(currentTab.url);
            return;
          }
        }
      });
  }

  getCurrentTab(tabs: Tab[]) {
    return (deepFlattenArray(tabs) as Tab[]).find((item) => item.isSelected);
  }

  getMemberIdFromUrl(url: string) {
    if (!url) {
      return null;
    }
    const listElement = url.split('?')[0].split('/');
    if (
      url.includes('member/profile-header-configuration') ||
      url.includes('member/profile-overview-configuration') ||
      url.includes('member/profile-navigation-configuration')
    ) {
      return listElement[3];
    }
    return listElement[listElement.length - 1];
  }

  getCurrentMenuGroups() {
    const tabName = this.getTabByUrl(this.router.url);
    if (!tabName) {
      return;
    }
    let currentMenuGroups = (deepClone(this.allMenuGroups) as any)[
      tabName
    ] as MenuGroup[];
    if (
      [
        DisplayedTabName.IndividualParticipant,
        DisplayedTabName.SearchedParticipant,
      ].includes(tabName as DisplayedTabName)
    ) {
      const memberId = this.getMemberIdFromUrl(this.router.url) || '';

      currentMenuGroups = currentMenuGroups.map((currentMenuGroup) => {
        currentMenuGroup.menu = (currentMenuGroup.menu || []).map((menu) => {
          (menu.menuItems || []).map((menuItem) => {
            // menuItem.routerLink = `${menuItem.routerLink}/${memberId}`;
            menuItem.routerLink =
              '/' +
              menuItem.routerLink
                .split('/')
                .concat([memberId])
                .filter((x) => x)
                .join('/');
            if (tabName === DisplayedTabName.SearchedParticipant) {
              menuItem.queryParams = { search: true };
              menuItem.routerLinkExpand = `${menuItem.routerLink}?${STRING_QUERY_PARAM.SEARCH}`;
            }
            return menuItem;
          });
          return menu;
        });
        return currentMenuGroup;
      });
    }
    const allPermissions = deepFlattenArray(
      deepClone(
        this.checkPermissionService.getPermissionFilterByModule() || []
      ),
      'Child'
    );
    const allSubModules = deepFlattenArray(
      deepClone(this.currentFund?.modules || []),
      'subModules'
    ).filter(
      (item) =>
        item.parentId &&
        !item.clientModuleIsDisabled &&
        allPermissions.find((permission) => permission.Key === item.moduleKey)
    );
    const isIgnoreTabs = [
      DisplayedTabName.IndividualParticipant,
      DisplayedTabName.SearchedParticipant,
    ].includes(tabName as DisplayedTabName);
    if (currentMenuGroups) {
      currentMenuGroups = currentMenuGroups.map((menuGroup) => {
        if (this.currentFund) {
          menuGroup.isHidden =
            this.authService.isAdminPortal$.value &&
            !isIgnoreTabs &&
            !allSubModules.find(
              (subModule) => subModule.moduleKey === menuGroup.moduleKey
            );
        }
        menuGroup.isSelected =
          this.checkSelectedMenuItem(menuGroup) ||
          menuGroup.menu?.some((menu) =>
            menu.menuItems?.some(
              (menuItem) =>
                this.checkSelectedMenuItem(menuItem) ||
                this.router.url.includes(menuItem.routerLink)
            )
          );
        return menuGroup;
      });
      this.menuGroups = currentMenuGroups;
    }
  }

  checkSelectedMenuItem(menuItem: any) {
    return (
      this.router.url === menuItem?.routerLink ||
      (menuItem?.routerLinkExpand &&
        this.router.url.includes(menuItem?.routerLinkExpand))
    );
  }

  loadAllDynamicMenu() {
    if (!this.authService.isAdminPortal$.value) {
      return;
    }
    this.checkPermissionToLoadDynamicMenu(this.authService.Permissions);
  }

  checkPermissionToLoadDynamicMenu(permissions: PermissionModel[]) {
    if (!permissions) {
      return;
    }
    const parentPermissions = permissions.map((permission) => permission.Key);
    if (
      this.authService.isSysAdmin ||
      parentPermissions?.includes(PERMISSION_KEY.PARTICIPANTS)
    ) {
      this.getMemberDynamicMenu();
      this.getMenuProfileNavigation();
      this.getParticipantReportMenu();
    }
    if (
      this.authService.isSysAdmin ||
      parentPermissions?.includes(PERMISSION_KEY.APP_CONTENT)
    ) {
      this.getAppContentDynamicMenu();
    }
  }

  getDynamicMenu(
    menu: MenuGroup,
    items: any[],
    options: {
      nameKey: string;
      routerLinkPrefix: string;
      routerLinkKey: string;
      menuIndex?: number;
    }
  ): MenuGroup {
    const menuItems = deepClone(items).map((menuItem: any) => {
      return {
        name: menuItem[options.nameKey],
        routerLink: `${options.routerLinkPrefix}/${
          menuItem[options.routerLinkKey]
        }`,
      };
    });
    const currentMenu = deepClone(menu) as MenuGroup;
    const menuIndex = options.menuIndex || 0;
    if (currentMenu?.menu?.length) {
      currentMenu.menu[menuIndex].menuItems = [
        ...menuItems,
        ...(currentMenu.menu[menuIndex].menuItems || []),
      ];
    }
    return currentMenu;
  }

  getParticipantReportMenu() {
    this.store.dispatch(LayoutActions.getReportCategorySidebar());

    this.store
      .pipe(
        select(fromMember.selectParticipantReportCategorySidebar),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        const allMenuGroups = deepClone(this.allMenuGroups) as Record<
          DisplayedTabName,
          MenuGroup[]
        >;
        const menuParticipantReportIndex = allMenuGroups[
          DisplayedTabName.Participants
        ].findIndex((menuGroup) => menuGroup?.name === 'participant_report');
        const defaultParticipantReportMenuGroup = this.defaultTabMenuGroups?.[
          DisplayedTabName.Participants
        ]?.find((menuGroup) => menuGroup?.name === 'participant_report');

        if (
          defaultParticipantReportMenuGroup &&
          menuParticipantReportIndex > -1
        ) {
          var menu: Menu[] = [];
          state.response.reportCategory.forEach(
            (reportCategory: ReportCategorySidebar) => {
              menu.push({
                title: reportCategory.name,
                menuItems: deepClone(reportCategory.reports).map(
                  (report: ReportSidebar) => {
                    return {
                      name: report.name,
                      routerLink: `/member/report/${report.id}`,
                    };
                  }
                ),
              });
            }
          );
          const currentMenu = deepClone(
            defaultParticipantReportMenuGroup
          ) as MenuGroup;
          menu = menu.concat(currentMenu.menu ?? []);

          allMenuGroups[DisplayedTabName.Participants][
            menuParticipantReportIndex
          ].menu = menu;
        }
        this.updateAllMenuGroup(
          DisplayedTabName.Participants,
          allMenuGroups[DisplayedTabName.Participants]
        );
      });
  }

  getMemberDynamicMenu() {
    this.store
      .select(fromLayoutReducer.selectBulkContentState)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((bulkContentState) => {
        if (bulkContentState.isLoading) {
          return;
        }
        const allMenuGroups = deepClone(this.allMenuGroups) as Record<
          DisplayedTabName,
          MenuGroup[]
        >;
        const menuBulkUpdateIndex = allMenuGroups[
          DisplayedTabName.Participants
        ].findIndex((menuGroup) => menuGroup?.name === 'bulk_update');
        const defaultBulkUpdateMenuGroup = this.defaultTabMenuGroups?.[
          DisplayedTabName.Participants
        ]?.find((menuGroup) => menuGroup?.name === 'bulk_update');
        if (defaultBulkUpdateMenuGroup && menuBulkUpdateIndex > -1) {
          const dynamicMenu = this.getDynamicMenu(
            defaultBulkUpdateMenuGroup,
            bulkContentState.items,
            {
              nameKey: 'importKey',
              routerLinkPrefix: '/member/bulk',
              routerLinkKey: 'key',
            }
          );
          allMenuGroups[DisplayedTabName.Participants][menuBulkUpdateIndex] =
            dynamicMenu;
        }
        const menuComunicationIndex = allMenuGroups[
          DisplayedTabName.Participants
        ].findIndex((menuGroup) => menuGroup?.name === 'comunication');
        const defaultComunicationMenuGroup = this.defaultTabMenuGroups?.[
          DisplayedTabName.Participants
        ]?.find((menuGroup) => menuGroup?.name === 'comunication');

        //Display Email Messaging only for mobile
        if (this.currentFund?.fundType !== FundType.MobileAL) {
          defaultComunicationMenuGroup?.menu?.forEach((menu: Menu) => {
            menu.menuItems = menu?.menuItems?.filter(
              (item) => item.name !== 'Email Messaging'
            );
          });

          allMenuGroups[DisplayedTabName.Participants][menuComunicationIndex] =
            defaultComunicationMenuGroup!!;
        }

        this.updateAllMenuGroup(
          DisplayedTabName.Participants,
          allMenuGroups[DisplayedTabName.Participants]
        );
      });

    this.store
      .pipe(
        select(fromReducer.selectMetadataUpdated),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((metadataUpdated) => {
        if (!this.authService.isAdminPortal$.value) {
          return;
        }
        this.store.dispatch(LayoutActions.getBulkContentItemRequest());
      });
  }

  getAppContentDynamicMenu() {
    this.store
      .select(fromLayoutReducer.selectAppContentState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((appContentState) => {
        if (appContentState.isLoading) {
          return;
        }
        const allMenuGroups = deepClone(this.allMenuGroups) as Record<
          DisplayedTabName,
          MenuGroup[]
        >;
        const appContentMenuIndex = allMenuGroups[
          DisplayedTabName.AppContent
        ]?.findIndex((menuGroup) => menuGroup.name === 'ACM');
        const defaultAppContentMenuGroup = deepClone(
          DEFAULT_TAB_MENU_GROUPS[DisplayedTabName.AppContent]
        ).find((menuGroup) => menuGroup?.name === 'ACM');

        if (defaultAppContentMenuGroup && appContentMenuIndex > -1) {
          allMenuGroups[DisplayedTabName.AppContent][appContentMenuIndex] =
            this.getDynamicMenu(
              defaultAppContentMenuGroup,
              appContentState.items,
              {
                nameKey: 'navigation',
                routerLinkPrefix: '/page',
                routerLinkKey: 'id',
                menuIndex: 1,
              }
            );
        }
        this.updateAllMenuGroup(
          DisplayedTabName.AppContent,
          allMenuGroups[DisplayedTabName.AppContent]
        );
      });

    // Update to use store
    this.store
      .pipe(
        select(fromReducer.selectNavigationUpdated),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.store.dispatch(LayoutActions.getAppContentItemRequest());
      });
  }

  getMenuProfileNavigation() {
    this.store
      .pipe(
        select(fromReducer.selectProfileNavigationUpdated),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el) {
          const memberId = this.getMemberIdFromUrl(this.router.url) || '';
          if (memberId) {
            dispatchGetMemberNavigationList(this.store, memberId);
          }
        }
      });

    this.store
      .pipe(
        select(fromLayoutReducer.getMemberNavigationListSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.payload) {
          this.disabledSideBar = state.isLoading;
        }
        if (state?.isLoading || !state?.payload?.memberId) {
          return;
        }
        const currentTabName = this.getCurrentTab(this.tabs)
          ?.tabName as DisplayedTabName;
        if (
          [
            DisplayedTabName.IndividualParticipant,
            DisplayedTabName.SearchedParticipant,
          ].includes(currentTabName)
        ) {
          const currentMenuGroup = deepClone(this.allMenuGroups)[
            currentTabName as DisplayedTabName
          ];
          if (!currentMenuGroup) {
            return;
          }
          currentMenuGroup[0].menu = [
            ...deepClone(MEMBER_OVERVIEW_MENU_LIST),
            ...deepClone(state.payload.menu),
          ];
          this.updateAllMenuGroup(currentTabName, currentMenuGroup);
        }
      });
  }

  checkFundChange() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        distinctUntilChanged((previous, current) =>
          deepEqual(previous, current)
        ),
        tap((currentFund) => {
          this.currentFund = currentFund;
          this.updateTabsLabel();
        }),
        pairwise(),
        tap(([previous, current]) => {
          if (previous.id !== current.id) {
            this.store.dispatch(
              LayoutActions.closeTab({
                tabName: DisplayedTabName.IndividualParticipant,
              })
            );
            this.store.dispatch(
              LayoutActions.closeTab({
                tabName: DisplayedTabName.SearchedParticipant,
              })
            );
            this.store.dispatch(
              LayoutActions.setTabUrl({
                tab: DisplayedTabName.Employers,
                url: '/employer/employer-management',
              })
            );

            this.store.dispatch(
              LayoutActions.setTabUrl({
                tab: DisplayedTabName.Processing,
                url: '/processing',
              })
            );

            this.isFirstLoad = true;
          }
        }),
        filter(([previous, current]) => !!previous.id && !!current.id),
        map(([previous, current]) => current),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((currentFund) => {
        if (
          !this.checkPermissionService.checkCondition(
            this.router.url,
            this.checkPermissionService.getPermissionFilterByModule()
          )
        ) {
          return;
        }
        this.store.dispatch(
          LayoutActions.setAllMenuGroups({
            allMenuGroups: deepClone(
              this.authService.isAdminPortal$.value
                ? DEFAULT_TAB_MENU_GROUPS
                : DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS
            ),
          })
        );
        this.memberStore.dispatch(
          MemberListActions.setMemberFilter({ filters: [] })
        );
        if (currentFund?.id && this.authService.isAdminPortal$.value) {
          this.store.dispatch(LayoutActions.getBulkContentItemRequest());
          this.store.dispatch(LayoutActions.getAppContentItemRequest());
          this.store.dispatch(
            LayoutActions.setTabUrl({
              tab: DisplayedTabName.Employers,
              url: '/employer/employer-management',
            })
          );
          this.store.dispatch(LayoutActions.getReportCategorySidebar());
        }
      });
  }

  updateAllMenuGroup(tabName: DisplayedTabName, menuGroups: MenuGroup[]) {
    const allMenuGroups = {
      ...deepClone(this.allMenuGroups),
      [tabName]: menuGroups,
    };
    this.store.dispatch(LayoutActions.setAllMenuGroups({ allMenuGroups }));
  }

  changeSidebarOpenedStatus(sidebarOpenedStatus: SidebarOpenedStatus) {
    this.store.dispatch(
      LayoutActions.setSidebarOpenedStatus({ sidebarOpenedStatus })
    );
  }

  closeSideMenu() {
    this.changeSidebarOpenedStatus(SidebarOpenedStatus.Closed);
  }

  controlSideMenu(sidebarOpenedStatus: SidebarOpenedStatus) {
    if (sidebarOpenedStatus === SidebarOpenedStatus.Opened) {
      this.renderer.removeClass(document.body, 'menu-collapsed');
      this.renderer.removeClass(document.body, 'menu-hidden');
    } else if (sidebarOpenedStatus === SidebarOpenedStatus.Closed) {
      this.renderer.addClass(document.body, 'menu-collapsed');
      this.renderer.removeClass(document.body, 'menu-hidden');
    } else {
      this.renderer.addClass(document.body, 'menu-hidden');
      this.renderer.removeClass(document.body, 'menu-collapsed');
    }
  }

  updateTabsLabel() {
    const modules: Module[] = this.currentFund.modules;
    if (!modules) {
      return;
    }
    const allModule = (deepFlattenArray(deepClone(modules)) || []).filter(
      (module) => !module.clientModuleIsDisabled
    );
    const tabs = deepClone(this.tabs).map((tab) => {
      const ignoreTabConfigTabs = [
        DisplayedTabName.IndividualParticipant,
        DisplayedTabName.SearchedParticipant,
      ];
      if (ignoreTabConfigTabs.includes(tab.tabName as DisplayedTabName)) {
        return tab;
      }
      const moduleConfig = allModule.find(
        (item) => item.moduleKey === tab.tabName
      );
      if (
        this.authService.isAdminPortal$.value ||
        tab.tabName !== DisplayedTabName.Admin
      ) {
        tab.tabLabel = moduleConfig?.clientModuleName || tab.tabLabel;
      }
      tab.isHidden = !moduleConfig;
      return tab;
    });
    this.store.dispatch(LayoutActions.setTabs({ tabs }));
  }

  setDefaultLayoutValue() {
    this.store.dispatch(
      LayoutActions.setTabs({
        tabs: this.defaultTabs,
      })
    );
    this.store.dispatch(
      LayoutActions.setAllMenuGroups({
        allMenuGroups: deepClone(
          this.authService.isAdminPortal$.value
            ? DEFAULT_TAB_MENU_GROUPS
            : DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS
        ),
      })
    );
  }

  reloadCurrentFund() {
    if (!this.layoutService.currentFund$.value.key) {
      return;
    }
    this.resourceCenterService
      .getFundByKey(this.layoutService.currentFund$.value.key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (fundModel: FundModel) => {
          this.store.dispatch(
            FundListActions.selectFund(getCurrentFundData(fundModel))
          );
        },
        (error: any) => {}
      );
  }
}
