import { createAction, props } from '@ngrx/store';

import { GetListStatusHistoryQuery, MunicipalityList } from '../types/models';

export const MunicipalityPaymentListRequest =
  '[MunicipalityPaymentList/API] Send Request';
export const MunicipalityPaymentListSuccess =
  '[MunicipalityPaymentList/API] Success';
export const MunicipalityPaymentListFailure =
  '[MunicipalityPaymentList/API] Failure';

export const CreateMemberMunicipalityPaymentRequest =
  '[CreateMemberMunicipalityPayment/API] Send Request';
export const CreateMemberMunicipalityPaymentSuccess =
  '[CreateMemberMunicipalityPayment/API] Success';
export const CreateMemberMunicipalityPaymentFailure =
  '[CreateMemberMunicipalityPayment/API] Failure';
export const EditMemberMunicipalityPaymentRequest =
  '[EditMemberMunicipalityPayment/API] Send Request';
export const EditMemberMunicipalityPaymentSuccess =
  '[EditMemberMunicipalityPayment/API] Success';
export const EditMemberMunicipalityPaymentFailure =
  '[EditMemberMunicipalityPayment/API] Failure';
export const ClearMunicipalityPaymentState = '[ClearMunicipalityPaymentState]';

export const getMunicipalityPaymentList = createAction(
  MunicipalityPaymentListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const municipalityPaymentListSuccess = createAction(
  MunicipalityPaymentListSuccess,
  props<{ payments: MunicipalityList }>()
);
export const municipalityPaymentListFailure = createAction(
  MunicipalityPaymentListFailure
);
export const clearMunicipalityPaymentState = createAction(
  ClearMunicipalityPaymentState
);
