import { Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { BaseComponent } from '@ptg-shared/components';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ACTION, SortType } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { showBanner } from '@ptg-shared/utils/common.util';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';

import * as fromMember from '../../reducers';
import { Calculation, GetCalculationsRequest } from '../../types/models';
import { CalculationActions } from '../../actions';
import { AddCalculationComponent } from '../../components/add-calculation/add-calculation.component';

const PAGE_SIZE_CONST = '-ptg-calculation-list-pageSize'
@Component({
  selector: 'ptg-calculation-list',
  templateUrl: './calculation-list.component.html',
  styleUrls: ['./calculation-list.component.scss'],
})
export class CalculationListComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Calculation Configuration',
      url: '',
    },
  ];

  columns: Column[] = [
    {
      name: 'calculationName',
      header: {
        title: 'Calculation Name',
      },
      width: '25%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'displayFormat',
      header: {
        title: 'Display Format',
      },
      width: '25%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'calculationFormula',
      header: {
        title: 'Calculation Formula',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Actions',
      },
      width: '170px',
    },
  ];

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  calculationsData: (Calculation & Row)[] = [];
  isLoading?: boolean = false;
  errorMsg?: string;
  sortInfo?: Sort;
  totalRecords: number = 0;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  currentFund: any = {};
  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.currentFund = el;
        this.pageSize = el.defaultPageSize ?? 50;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      });
    this.store.dispatch(LayoutActions.hiddenSideMenu());

    this.getCalculationsState();
    this.getAddCalculationState();
    this.getUpdateCalculationState();
    this.getRemoveCalculationState();
    this.getData();
  }

  private getCalculationsState() {
    this.memberStore
      .pipe(
        select(fromMember.selectGetCalculationsState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = state?.isLoading;
        if (state && !state.isLoading && state.success) {
          this.calculationsData = state.payload || [];
          this.totalRecords = state.total || 0;
        }
      });
  }

  private getAddCalculationState() {
    this.memberStore
      .pipe(
        select(fromMember.selectAddCalculationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.memberStore.dispatch(
              CalculationActions.getCalculationProperties()
            );
            this.getData();
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Calculation', ACTION.ADD);
          this.store.dispatch(CalculationActions.clearCalculationState());
          this.store.dispatch(
            CalculationActions.clearCalculationPropertiesState()
          );
        }
      });
  }

  private getUpdateCalculationState() {
    this.memberStore
      .pipe(
        select(fromMember.selectUpdateCalculationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.memberStore.dispatch(
              CalculationActions.getCalculationProperties()
            );
            this.getData();
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Calculation', ACTION.EDIT);
          this.store.dispatch(CalculationActions.clearCalculationState());
          this.store.dispatch(
            CalculationActions.clearCalculationPropertiesState()
          );
        }
      });
  }

  private getRemoveCalculationState() {
    this.memberStore
      .pipe(
        select(fromMember.selectRemoveCalculationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.memberStore.dispatch(
              CalculationActions.getCalculationProperties()
            );
            this.getData(true);
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Calculation', ACTION.REMOVE);
          this.store.dispatch(CalculationActions.clearCalculationState());
          this.store.dispatch(
            CalculationActions.clearCalculationPropertiesState()
          );
        }
      });
  }

  onChangePage(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
     
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString()); 
    this.getData();
  }

  onChangeSort(event: Sort): void {
    this.sortInfo = event;
  }

  getData(jumpToFirst?: boolean): void {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }

    let request: GetCalculationsRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };

    if (this.sortInfo) {
      const field =
        this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType:
          this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.memberStore.dispatch(CalculationActions.getCalculations({ request }));
  }

  onClickAddCalculation() {
    this.dialog.open(AddCalculationComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: {
        isEdit: false,
      },
    });
  }

  onClickEditCalculation(row: Calculation & Row): void {
    this.dialog.open(AddCalculationComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: {
        isEdit: true,
        calculation: row,
      },
    });
  }

  onClickRemoveCalculation(row: Calculation & Row): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Item',
        text: 'Removing this calculation will also remove all filters and other Calculation using it. Are you sure you want to remove this Calculation?',
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          CalculationActions.removeCalculation({ id: row.id })
        );
      }
    });
  }
}
