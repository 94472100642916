import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  MetadataProfileHeaderConfiguration,
  GetProfileHeadersDataResponse,
  ProfileHeaderList,
  PropertyConfigs,
  Status,
} from '../types/models';
import { GetPropertyType } from '../constance/member-list.const';

const AggregationType = 1;

@Injectable({
  providedIn: 'root',
})
export class ProfileHeaderConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getPropertyList() {
    return this.httpClient.get<PropertyConfigs>(
      `${environment.apiUrl}/Metadata/SectionProperties/${GetPropertyType.ProfileHeader}`
    );
  }

  getProfileHeaderConfiguration(
    id: string
  ): Observable<MetadataProfileHeaderConfiguration> {
    return this.httpClient.get<MetadataProfileHeaderConfiguration>(
      `${environment.apiUrl}/Metadata/${id}/GetProfileHeaderConfigurations`
    );
  }

  getListProfileHeaderConfiguration(query: {
    SortNames: string;
    SortType: number;
  }): Observable<ProfileHeaderList> {
    const params = new HttpParams({ fromObject: query });
    return this.httpClient.get<ProfileHeaderList>(
      `${environment.apiUrl}/Metadata/ProfileHeaders`,
      { params }
    );
  }

  manageConfigurationMember(
    body: MetadataProfileHeaderConfiguration
  ): Observable<string> {
    let metadataProfileHeaderConfigurations =
      body.metadataProfileHeaderConfigurations.map((el) => {
        let res = {
          ...el,
          headerItemType: el.headerType,
        };
        if (el?.headerType !== AggregationType) {
          res.headerItemKey = el.headerItemKey;
        } else {
          res.headerItemKey = el.aggregationId;
        }
        return res;
      });
    let payload = {
      ...body,
      metadataProfileHeaderConfigurations,
    };
    return this.httpClient.post<string>(
      `${environment.apiUrl}/Metadata/ManageProfileHeaderConfig`,
      payload
    );
  }

  getMemberProfileHeaderData(memberId: string): Observable<GetProfileHeadersDataResponse> {
    return this.httpClient.get<GetProfileHeadersDataResponse>(
      `${environment.apiUrl}/Members/${memberId}/ProfileHeader`
    );
  }

  updateProfileHeaderConfiguration(
    body: MetadataProfileHeaderConfiguration
  ): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.apiUrl}/Metadata/ProfileHeader/ManageProfileHeaders`,
      body
    );
  }
  deleteProfileHeaderConfiguration(profileHeaderId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Metadata/ProfileHeaders/${profileHeaderId}`
    );
  }
  getMembersStatusNotUsed(): Observable<{ listStatusUsing: Status[] }> {
    return this.httpClient.get<{ listStatusUsing: Status[] }>(
      `${environment.apiUrl}/Metadata/ProfileHeaders/GetMemberStatusNotUsed`
    );
  }

  checkProfileHeaderExists = (body: {
    name: string;
    profileHeaderId?: string;
  }) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/ProfileHeaders/Exists`,
      body
    );
  };
}
