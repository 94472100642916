import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { deepClone } from '@ptg-shared/utils/common.util';

import * as Info1099Actions from '../actions/info-1099.actions';
import {
  Info1099RecordResponse,
  Info1099RecordQuery,
} from '../types/models';
import { Info1099Service } from '../services/info-1099.service';

@Injectable()
export class Info1099Effects {
  constructor(
    private actions$: Actions,
    private info1099Service: Info1099Service
  ) {}

  getInfo1099Record$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Info1099Actions.getInfo1099Record),
      switchMap(({ query, memberId }) => {
        return this.info1099Service
          .getInfo1099Record(query as Info1099RecordQuery, memberId)
          .pipe(
            map((response: Info1099RecordResponse) => {
              return Info1099Actions.getInfo1099RecordSuccess({
                response,
              });
            }),
            catchError((error) => {
              return of(
                Info1099Actions.getInfo1099RecordFailure({ error })
              );
            })
          );
      })
    )
  );

  getInfo1099Config$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Info1099Actions.getInfo1099Config),
      switchMap(() => {
        return this.info1099Service.getInfo1099Config().pipe(
          map((res: any) => {
            return Info1099Actions.getInfo1099ConfigSuccess({
              info1099ConfigData: res.annual1099ColumnConfiguration,
            });
          }),
          catchError((err) => {
            return of(Info1099Actions.getInfo1099ConfigFailure());
          })
        );
      })
    )
  );
}
