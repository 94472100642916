<div class="member-detail-display section-non-list">
  <div class="detail-header">
    <span class="title">
      {{ menuItemName }}
    </span>

    <div class="space-line"></div>

    <div
      class="edit-btn"
      (click)="edit()"
      [ngClass]="{
        'btn-disabled':
          allColumnData === undefined ||
          !metadataNonListSection ||
          !metadataSection
      }"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
  </div>

  <div class="flex flex-col">
    <table class="table-content">
      <tr *ngFor="let row of allColumnData">
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[0] }"
          ></ng-container>
        </td>
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[1] }"
          ></ng-container>
        </td>
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[2] }"
          ></ng-container>
        </td>
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[3] }"
          ></ng-container>
        </td>
      </tr>
    </table>

    <div
      *ngIf="
        allColumnData === undefined ||
        !metadataNonListSection ||
        !metadataSection
      "
      style="margin: 20px auto"
    >
      <ptg-spinner [isLoading]="true"></ptg-spinner>
    </div>
  </div>

  <div
    *ngIf="
      column1Data?.length === 0 &&
      column2Data?.length === 0 &&
      column3Data?.length === 0 &&
      column4Data?.length === 0
    "
    class="data-not-found"
  >
    <div class="message">Display had not been configured</div>
  </div>
</div>

<ng-template #nonListLayout let-data="data">
  <div
    *ngIf="data"
    class="flex"
    [ngClass]="{
      'flex-col': currentSectionLayout === SectionLayout.Column,
      'gap-3': currentSectionLayout === SectionLayout.Table
    }"
  >
    <div class="title-container flex">
      <div class="icon">
        <mat-icon
          *ngIf="data.tooltipContent"
          matTooltip="{{ data.tooltipContent }}"
          matTooltipClass="custom-tooltip"
        >
          star
        </mat-icon>
      </div>

      <div class="title flex-grow">{{ data?.title }}</div>
    </div>

    <div class="value-container flex-grow flex">
      <ng-container [ngSwitch]="data.type">
        <span
          *ngSwitchCase="'Currency'"
          [ngClass]="{
            'font-bold': data?.tooltipContent
          }"
        >
          {{ (data.value | numberLocalDecimal : "$") || "-" }}
        </span>
        <span *ngSwitchCase="'Date'">
          {{ (data.value | date : "MM/dd/yyyy") || "-" }}
        </span>
        <span *ngSwitchCase="'Person_Name'">
          {{ (data.value | personName : data.options) || "-" }}
        </span>
        <div *ngSwitchCase="'Address'">
          <ptg-view-address
            [value]="data?.value"
            [options]="data.options"
          ></ptg-view-address>
        </div>
        <span
          *ngSwitchCase="'Decimal'"
          [ngClass]="{
            'font-bold': data?.tooltipContent
          }"
        >
          {{
            (data.value
              | numberDecimal
                : {
                    decimal:
                      data?.config?.fractionalLengthInput || data.displayValue,
                    isCheckCurrentDecimal: true
                  }) || "-"
          }}
        </span>
        <span
          *ngSwitchCase="'Whole_Number'"
          [ngClass]="{
            'font-bold': data?.tooltipContent
          }"
        >
          {{ (data.value | numberDecimal) || "-" }}
        </span>
        <span *ngSwitchCase="'Lookup'">{{ data.value || "-" }}</span>
        <span
          *ngSwitchCase="'Binary'"
          [ngClass]="{
            'font-bold': data?.tooltipContent
          }"
        >
          {{ data.value || "-" }}
        </span>
        <span
          *ngSwitchCase="'Percentage'"
          [ngClass]="{
            'font-bold': data?.tooltipContent
          }"
        >
          {{
            (data.value
              | numberDecimal
                : {
                    decimal:
                      data?.config?.fractionalLengthInput || data.displayValue,
                    isCheckCurrentDecimal: true
                  }) || "-"
          }}
          {{
            (data.value
            | numberDecimal
              : {
                  decimal:
                    data?.config?.fractionalLengthInput || data.displayValue,
                  isCheckCurrentDecimal: true
                })
              ? "%"
              : ""
          }}
        </span>
        <span *ngSwitchCase="'Employer'">{{ data.value || "-" }}</span>
        <ng-container *ngSwitchCase="'RichText'">
          <ptg-view-rich-text
            *ngIf="!data?.tooltipContent"
            [title]="data?.title"
            [content]="data.value"
            [defaultContent]="'-'"
          ></ptg-view-rich-text>
          <span *ngIf="data?.tooltipContent" class="font-bold">
            {{ data.value || "-" }}
          </span>
        </ng-container>
        <span *ngSwitchCase="'DateTime'">
          {{ (data?.value | date : "MM/dd/yyyy hh:mm a") || "-" }}
        </span>
        <span *ngSwitchCase="'BlankSpace'">
          <br />
        </span>
        <ng-container *ngSwitchDefault>
          <span
            [ngClass]="{
              'font-bold': data?.tooltipContent
            }"
            *ngIf="data.key !== 'last4Ssn'; else ssn"
          >
            {{ data.value || "-" }}
          </span>
          <ng-template #ssn>
            <span style="color: #b1b1b1">XXX-XX</span>
            <span>-{{ data.value }}</span>
          </ng-template>
        </ng-container>
      </ng-container>

      <ptg-masked-eye
        *ngIf="data.value && data?.config?.masked == 'true'"
        [page]="'member-navigation' + menuItemName"
        [memberId]="memberId"
        [propertyValue]="data"
        [itemKey]="metadataNonListSection?.key ?? ''"
        [propertyKey]="data?.key"
        [index]="'0'"
      ></ptg-masked-eye>
    </div>
  </div>
</ng-template>
