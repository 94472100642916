<div class="member-list-configuration" id="member-list-configuration-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6">
    <ptg-property-display-configuration
      [propertyDisplayConfigurations]="propertyDisplayConfigurations"
      [propertyConfigs]="propertyConfigs"
      [addPropertySection]="addPropertySection"
      [sortPropertySection]="sortPropertySection"
      (onSubmitEvent)="onSubmit($event)"
      [sortRowSection]="sortRowSection"
      [canSelectMultipleOption]="true"
      [canSelectMultipleTimes]="true"
      [sortable]="false"
      [isSectionConfiguration]="false"
      [isParticipantSearchConfiguration]="true"
    ></ptg-property-display-configuration>
  </div>
</div>
