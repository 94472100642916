import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT_NOT_FOUND_MSG } from './constants';
import { CellContentSearch } from './directives';

@Component({
  selector: 'ptg-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent<T> implements OnInit {

  search$ = new BehaviorSubject('');

  @ContentChild(CellContentSearch) cellContentSearch?: CellContentSearch;
  @ViewChild('searchTrigger') searchTrigger?: MatAutocompleteTrigger;

  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() svgIcon: string = '';
  @Input() searchContent: string = '';
  @Input() isShowClearButton: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isError: boolean = false;
  @Input() data: T[] = [];
  @Input() columns: string[] = [];
  @Input() noDataMsg: string = DEFAULT_NOT_FOUND_MSG;
  @Input() lengthToSearch: number = 2;
  @Input() typingTime: number = 500;
  @Input() isSelected: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() searchClass: string = '';

  @Output() search = new EventEmitter<string>();
  @Output() selectItem = new EventEmitter<T>();
  @Output() clickClear = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.search$.pipe(debounceTime(this.typingTime)).subscribe((value) => {
      if (value && value.length >= this.lengthToSearch) {
        this.search.emit(value);
      }
    });
  }

  onChange(value: string): void {
    this.searchContent = value;
  }

  onSearch(): void {
    this.search$.next(this.searchContent);
    this.isLoading = true;
  }

  onClickSelect(row: T) {
    this.isSelected = true;
    this.searchTrigger?.closePanel();
    this.selectItem.emit(row);
  }

  onClickClearSearch(): void {
    if (!this.isDisabled) {
      const oldIsSelected = !!this.isSelected;
      this.clearSearch();
      this.clickClear.emit(oldIsSelected);
    }
  }

  clearSearch(): void {
    this.isSelected = false;
    this.searchContent = '';
  }
}
