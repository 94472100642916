import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, startWith, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { getDateFormatISO } from '@ptg-shared/utils/string.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

import * as fromMember from '../../reducers';
import { MunicipalityServiceHistoryService } from '../../services/municipality-service-history.service';
import {
  EditMunicipalityServiceHistoryRequest,
  History,
  MetadataSection,
  Municipality,
} from '../../types/models';
import * as MunicipalityServiceHistoryAction from '../../actions/municipality-service-history.action';

@Component({
  selector: 'ptg-edit-municipality-service-history',
  templateUrl: './edit-municipality-service-history.component.html',
  styleUrls: ['./edit-municipality-service-history.component.scss'],
})
export class EditMemberServiceHistoryComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();
  listOptionMunicipality!: Option[];
  maxBeginDate: Date = new Date();
  maxDate: Date = new Date();
  minDate: Date = new Date();
  isLoading: boolean = true;
  isEditForm: boolean = false;
  municipality = new FormControl('');
  filteredOptions!: Observable<Option[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string;
      viewName: string;
      serviceHistory: History;
      isRedirectFromOverview?: boolean;
      sectionData?: MetadataSection;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditMemberServiceHistoryComponent>,
    private serviceHistoryService: MunicipalityServiceHistoryService,
    private memberStore: Store<fromMember.MemberState>
  ) { }

  get beginDate(): FormControl {
    return this.editForm.get('beginDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.editForm.get('endDate') as FormControl;
  }

  ngOnInit(): void {
    this.memberStore.dispatch(
      MunicipalityServiceHistoryAction.getMunicipalityList()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityNameListState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((listMunicipality) => {
        this.setListOption(listMunicipality);
        this.initFormGroup(this.data.serviceHistory);
        this.filteredOptions = this.municipality.valueChanges.pipe(
          startWith(''),
          map((value) => {
            const muniName =
              typeof value === 'string' ? value : value?.displayValue;
            return muniName
              ? this._filter(muniName as string)
              : this.listOptionMunicipality.slice();
          })
        );
      });
    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityLoadingState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  initFormGroup(formData: History) {
    const initBeginDate = this.data.serviceHistory?.beginDate
      ? DateTime.fromISO(this.data.serviceHistory.beginDate)
      : DateTime.now();
    const initEndDate = this.data.serviceHistory?.endDate
      ? DateTime.fromISO(this.data.serviceHistory.endDate)
      : null;
    this.municipality.setValue({
      value: formData?.municipalityId,
      displayValue: formData?.municipalityName,
    });
    this.minDate = this.data.serviceHistory?.beginDate
      ? new Date(this.data.serviceHistory?.beginDate)
      : new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.editForm = this.fb.group({
      id: this.fb.control(formData?.id),
      beginDate: this.fb.control(initBeginDate, Validators.required),
      endDate: this.fb.control(initEndDate),
      municipality: this.fb.control(this.municipality.value),
    });
  }

  onSubmit() {
    this.municipality.setErrors(null);
    if (
      (this.municipality.value.value === undefined &&
        typeof this.municipality.value !== 'string') ||
      this.municipality.value === ''
    ) {
      this.municipality.setErrors({ required: true });
      return;
    } else {
      if (
        this.municipality.enabled &&
        typeof this.municipality.value === 'string'
      ) {
        this.municipality.setErrors({ inValidAsync: true });
        return;
      }
    }
    let formData = this.editForm.value;
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    let bodyCheck = {
      beginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      endDate: formData.endDate
        ? getDateFormatISO(new Date(formData.endDate).toString())
        : null,
      municipalityId: this.municipality.value.value,
      memberId: this.data.memberId,
      serviceId: formData.id,
    };
    this.serviceHistoryService
      .checkExits(bodyCheck)
      .pipe(
        catchError((err) => {
          return of(
            MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure()
          );
        })
      )
      .subscribe((el: any) => {
        if (el && el.exists) {
          this.endDate.setErrors({ inValidAsync: true });
          return;
        }
        if (this.editForm.pending) {
          let sub = this.editForm.statusChanges.subscribe(() => {
            if (this.editForm.valid) {
              this.saveData(this.editForm.value);
            }
            sub.unsubscribe();
          });
        } else if (this.editForm.valid) {
          this.saveData(this.editForm.value);
        }
      });
  }

  saveData(formData: any) {
    const body: EditMunicipalityServiceHistoryRequest = {
      id: formData.id,
      memberId: this.data.memberId,
      beginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      endDate: formData.endDate
        ? getDateFormatISO(new Date(formData.endDate).toString())
        : undefined,
      municipalityId: this.municipality.value.value,
    };
    formData.id
      ? this.memberStore.dispatch(
          MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryRequest(
            { body }
          )
        )
      : this.memberStore.dispatch(
          MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryRequest(
            { body }
          )
        );

    this.dialogRef.close();
  }

  setListOption(listData: Municipality[]) {
    let listDataDisplay = this.isEditForm
      ? listData
      : listData.filter((x) => x.disabled === false);
    this.listOptionMunicipality = listDataDisplay.map((item) => {
      return {
        value: item.value,
        displayValue: item.description,
      } as Option;
    });
  }

  onChangeBeginValue(event: any) {
    this.endDate.markAllAsTouched();
    if (this.endDate.errors?.inValidAsync) {
      this.endDate.setErrors(null);
    }
    let beginDate = new Date(event);
    let endDate = new Date(
      this.endDate.value ? this.endDate.value : DateTime.now
    );
    if (beginDate <= new Date() && endDate <= new Date()) {
      this.minDate = beginDate;
      if (beginDate >= endDate) {
        this.beginDate.setErrors({ inValidAsync: true });
      }
    } else if (beginDate > new Date()) {
      this.minDate = new Date();
    } else if (beginDate <= new Date()) {
      this.minDate = beginDate;
    }
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  onChangeEndValue(event: any) {
    this.beginDate.markAllAsTouched();
    if (this.endDate.errors?.inValidAsync) {
      this.endDate.setErrors(null);
    }
    let currentDate = new Date();
    let beginDate = new Date(this.beginDate.value);
    if(!event) return;
    if(event){
      let endDate = new Date(event);
      if (beginDate > currentDate) {
        this.minDate = currentDate;
        this.minDate.setDate(this.minDate.getDate() + 1);
        return;
      }
      if (endDate > beginDate) {
        this.beginDate.setErrors(null);
      }
      if(beginDate <= currentDate && endDate <= currentDate){
        if(endDate <= beginDate){
          this.beginDate.setErrors({ inValidAsync:true });
        } else {
          this.endDate.setErrors(null);
        }
      }
    }
  }

  displayFn(value: Option): string {
    return value && value.displayValue ? value.displayValue : '';
  }

  private _filter(name: string): Option[] {
    const filterValue = name.toLowerCase();

    return this.listOptionMunicipality.filter((option) =>
      option.displayValue.toLowerCase().includes(filterValue)
    );
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  validateMuni(): void {
    if (this.endDate.errors?.inValidAsync) {
      this.endDate.setErrors(null);
    }
    if (this.municipality.value) {
      if (
        this.municipality.enabled &&
        typeof this.municipality.value === 'string'
      ) {
        this.municipality.setErrors({ inValidAsync: true });
        return;
      }
      if (this.municipality.value.value === undefined) {
        this.municipality.setErrors({ required: true });
      }
    }
    if (this.municipality.value === '') {
      this.municipality.setErrors({ required: true });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
