import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';

import { environment } from 'src/environments/environment';
import { ProcessingType } from '../types/enums';
import {
  BoardBank,
  OffCycleData,
  OffCyclePaymentIds,
  GetOffCyclePaymentsResponse,
  OffCyclePaymentListQuery,
  OffCycleActionState,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class OffCyclePaymentsService {
  offCycleId: string = '';
  selectedOffCyclePayment: any;
  constructor(private httpClient: HttpClient) {}

  getOffCyclePayments(
    query: OffCyclePaymentListQuery,
    isCompleted: boolean
  ): Observable<GetOffCyclePaymentsResponse> {
    let params = new HttpParams();
    params = params.append('isCompleted', isCompleted);
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<GetOffCyclePaymentsResponse>(
      `${environment.apiUrl}/Payroll/OffCycles/Payments`,
      { params }
    );
  }

  getOffCycle(offCycleId: string): Observable<OffCycleData> {
    let params = new HttpParams();
    params = params.append('OffCycleId', offCycleId);
    return this.httpClient.get<OffCycleData>(
      `${environment.apiUrl}/Payroll/OffCycles`,
      { params }
    );
  }

  getCompletePaymentList(query: any): Observable<any> {
    let params = new HttpParams();
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    if (query?.filter?.checksOnly) {
      params = params.append('ChecksOnly', true);
    }
    if (query?.filter?.errorsOnly) {
      params = params.append('ErrorOnly', true);
    }
    return this.httpClient.get<GetOffCyclePaymentsResponse>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/CompletedOffCyclePayments`,
      { params }
    );
  }

  achSent() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/AchSent`,
      null
    );
  }

  checksPrinted() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/ChecksPrinted`,
      null
    );
  }

  validateCheckNumber = (body: any) => {
    let dataCheck = {
      checkNumber: body.checkNumber,
      transactionId: this.selectedOffCyclePayment.id,
    };
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/ValidateCheckNumber`,
      dataCheck
    );
  };

  editCheckNumber(body: any) {
    let data = {
      ...body,
      id: this.selectedOffCyclePayment?.id,
      checkNumber: body.checkNumber,
      orderOfPayment: this.selectedOffCyclePayment?.order,
      oldCheckNumber: this.selectedOffCyclePayment?.checkNumber,
    };
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/CheckNumber`,
      data
    );
  }

  checkBoardBankDisabled(boardBankId: string) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/BoardInformation/BoardBanks/${boardBankId}/IsDisabled`,
      null
    );
  }

  getSelectBoardBanks(): Observable<{ boardBanks: BoardBank[] }> {
    return this.httpClient.get<{ boardBanks: BoardBank[] }>(
      `${environment.apiUrl}/BoardInformation/BoardBanks`
    );
  }

  setCompletePaymentBoardBank(offCycleId: any, boardBankId: string) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/OffCycles/${offCycleId}/SetBoardBank`,
      { boardBankId }
    );
  }

  finalizeCompletePayment() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/Finalize`,
      null
    );
  }

  completeOffCyclePayment(
    offCyclePaymentIds: OffCyclePaymentIds
  ): Observable<string> {
    return this.httpClient.put<string>(
      `${environment.apiUrl}/Payroll/OffCycles/CompleteOffCyclePayment`,
      offCyclePaymentIds
    );
  }

  generateEDI(offCycleId: any, selectedBankId: any, boardBankId: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/${offCycleId}/GenerateEDIOffCycle`,
      { selectedBankId: selectedBankId, boardBankId: boardBankId },
      { responseType: 'text' }
    );
  }

  reissueOffCycle(offCycleId: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/Payroll/OffCycles/${offCycleId}/ReissueOffCycle`,
      null
    );
  }

  cancelOffCyclePayment() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/Cancel`,
      null
    );
  }

  updateCompleteOffCycleDepositDate(
    depositDate: string,
    offCycleId: string
  ): Observable<string> {
    return this.httpClient.put<string>(
      `${environment.apiUrl}/Payroll/OffCycles/${offCycleId}/DepositDate`,
      { depositDate }
    );
  }

  validatePaymentInformation(sourceId: string) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/Payroll/${sourceId}/ValidatePaymentInformation`,
      { sourceType: ProcessingType.OffCyclePayment }
    );
  }

  getOffCycleActionState(): Observable<OffCycleActionState> {
    let context = new HttpContext().set(SKIP_ERRORS, '404');
    return this.httpClient.get<OffCycleActionState>(
      `${environment.apiUrl}/Payroll/OffCycles/${this.offCycleId}/ConcurrentState`,
      { context }
    );
  }
}
