import { createAction, props } from '@ngrx/store';

import {
  Info1099RecordQuery,
  Info1099ConfigData,
  Info1099RecordResponse,
} from '../types/models';

export const Info1099Request = '[Info1099/API] Send Request';
export const Info1099Failure = '[Info1099/API] Failure';
export const Info1099Success = '[Info1099/API] Success';
export const Info1099RecordRequest =
  '[Info1099Record/API] Send Request';
export const Info1099RecordFailure = '[Info1099Record/API] Failure';
export const Info1099RecordSuccess = '[Info1099Record/API] Success';
export const ClearGetInfo1099RecordState =
  '[ClearGetInfo1099RecordState] Clear';
export const ClearInfo1099 = '[Info1099] Clear';

export const GetInfo1099ConfigRequest = '[GetInfo1099Config/API] Send Request';
export const GetInfo1099ConfigSuccess = '[GetInfo1099Config/API] Success';
export const GetInfo1099ConfigFailure = '[GetInfo1099Config/API] Failure';
export const ClearGetInfo1099ConfigState =  '[GetInfo1099Config] Clear';

export const clearInfo1099 = createAction(ClearInfo1099);

export const getInfo1099Record = createAction(
  Info1099RecordRequest,
  props<{ query: Info1099RecordQuery; memberId: string }>()
);
export const getInfo1099RecordSuccess = createAction(
  Info1099RecordSuccess,
  props<{ response: Info1099RecordResponse }>()
);
export const getInfo1099RecordFailure = createAction(
  Info1099RecordFailure,
  props<{ error?: any }>()
);
export const clearGetInfo1099RecordState = createAction(
  ClearGetInfo1099RecordState
);

export const getInfo1099Config = createAction(GetInfo1099ConfigRequest);
export const getInfo1099ConfigSuccess = createAction(
  GetInfo1099ConfigSuccess,
  props<{ info1099ConfigData: Info1099ConfigData[] }>()
);
export const getInfo1099ConfigFailure = createAction(
  GetInfo1099ConfigFailure
);
export const clearGetInfo1099ConfigState = createAction(
  ClearGetInfo1099ConfigState
);