import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import {
  Breadcrumb,
  FunctionButtonConfig,
  FunctionButtonData,
} from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { ACTION_COLUMN, Align, Column } from '@ptg-shared/controls/grid';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import * as fromReducer from '@ptg-reducers';

import { UploadLookupTableComponent } from '../../components/upload-lookup-table/upload-lookup-table.component';
import { UPLOAD_STATUS } from '../../constance/lookupTable.const';
import * as LookupTableActions from '../../actions/lookup-table.actions';
import { EditLookupTableComponent } from '../../components/edit-lookup-table/edit-lookup-table.component';
import * as fromMember from '../../reducers';
import { LookupTable } from '@ptg-member/types/models';

@Component({
  selector: 'ptg-lookup-table',
  templateUrl: './lookup-table.component.html',
  styleUrls: ['./lookup-table.component.scss'],
})
export class LookupTableComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Lookup Tables',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Table Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'totalValue',
      header: {
        title: 'Total Values',
      },
      align: Align.Center,
      truncate: true,
      sortable: true,
    },
    {
      name: 'disabled',
      header: {
        title: 'Disabled',
      },
      align: Align.Center,
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Actions',
      },
      width: '170px',
    },
  ];
  lookupTablesData!: LookupTable[];
  errorMsg?: string;
  isLoading: boolean = true;
  sortInfo!: Sort;
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New Lookup Table',
      icon: 'add',
      classInput: 'add-button',
    },
    {
      buttonName: 'Bulk Upload',
      icon: 'upload_file',
      classInput: 'add-button',
    },
  ];

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private router: Router,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.getData();
    this.memberStore
      .select(fromMember.selectLookupTable)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.lookupTablesData = state.lookupTableList;
        this.isLoading = state.isLoading;

        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }
      });
    this.memberStore
      .select(fromMember.selectCreateLookupTableState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, 'Table', ACTION.ADD);
        if (state === STATE.SUCCESS) {
          this.getData();
        }
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
      });
    this.memberStore
      .select(fromMember.selectSessionDetailState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((sessionDetailState) => {
        const state = sessionDetailState?.sessionDetail;
        if (state?.status === UPLOAD_STATUS.CANCELED) {
          this.bannerType = BannerType.Hidden;
          return;
        }
        if (!state?.status) {
          return;
        }
        this.bannerType = (
          state?.status === UPLOAD_STATUS.IMPORTED ? STATE.SUCCESS : STATE.FAIL
        ) as BannerType;
        if (state?.status === UPLOAD_STATUS.IMPORTED) {
          showBanner.call(this, BannerType.Success, 'Table(s)', ACTION.UPLOAD);
          this.getData();
        } else {
          const options = {
            customMessage:
              state?.error ||
              'Error occurred uploading Table(s). Please try again.',
          };
          showBanner.call(this, BannerType.Fail, '', ACTION.UPLOAD, options);
        }
      });
  }

  getData() {
    let SortType = 0;
    let SortNames = 'Name';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      SortNames = capitalizeFirstLetter(
        this.sortInfo.active === 'disabled' ? 'active' : this.sortInfo.active
      );
      SortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.memberStore.dispatch(
      LookupTableActions.getLookupTableList({ query: { SortNames, SortType } })
    );
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  viewDetail(lookupTable: LookupTable) {
    this.router.navigateByUrl('/member/lookup-table/' + lookupTable.id);
  }

  addLookupTable() {
    this.dialog.open(EditLookupTableComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {},
    });
  }

  bulkUpload() {
    this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
    const dialog = this.dialog.open(UploadLookupTableComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
    });
  }

  emitFunction(event: FunctionButtonData) {
    if (event.buttonName === 'New Lookup Table') {
      this.addLookupTable();
      return;
    }
    if (event.buttonName === 'Bulk Upload') {
      this.bulkUpload();
      return;
    }
  }
}
