import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { DateTime } from 'luxon';


@Component({
  selector: 'ptg-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit, OnChanges {

  @Input() controlField!: AbstractControl | any;
  @Input() isRequired!: boolean;
  @Input() placeholder!: string;
  @Input() class?: string;
  @Input() minDateControl!: AbstractControl | any;

  @Output() timeChanged = new EventEmitter<string>();

  errorInvalid = "Invalid time."
  errorInvalidMinTime = "Chosen time must be more than an hour of current time."

  @ViewChild('inputTime') inputTime!: ElementRef;
  currentVal = '';

  constructor() {
  }

  ngOnInit(): void {
    if (this.minDateControl) {
      this.minDateControl.valueChanges.subscribe(() => {
        this.validateScheduleTime();
      });

      if (this.controlField) {
        this.controlField.valueChanges.subscribe(() => {
          this.validateScheduleTime();
        });
      }
    }
  }

  onInput(event: any) {
    this.currentVal = event.target.value;
    this.timeChanged.emit(event.target?.value);
  }

  onKeypress(event: any) {
    const position = event?.srcElement?.selectionStart;
    const length = event?.target?.value?.length;
    const isValidFormatHHMM = (length === 4 || length === 5) && !event?.target?.value?.includes(' ') && /((1[0-2]|0?[1-9]):([0-5][0-9]))/.test(event.target?.value);
    if (event.key === 'Enter' || (event.code === 'Space' && (position === 0 || (!isValidFormatHHMM && position === length)))) {
      event.preventDefault();
    }
  }

  onFocusIn(event: any) {
    this.checkTimeInvalid(event);

  }

  onFocusOut(event: any) {
    this.checkTimeInvalid(event);
    this.timeChanged.emit(event.target?.value);
  }

  checkTimeInvalid(event: any) {
    this.inputTime.nativeElement.dispatchEvent(new Event('change'));
    if (this.controlField.value?.trim()) {
      if (event.target?.value === 'Invalid DateTime') {
        this.inputTime.nativeElement.value = this.currentVal;
        this.controlField.setErrors({ 'invalid': true });
      } else {
        const errors = { ...this.controlField.errors };
        delete errors.invalid;
        this.controlField.setErrors(Object.keys(errors).length ? errors : null);
      }
    }
  }

  onTimepickerClosed() {
    if (this.isRequired && !this.controlField.value?.trim()) {
      this.controlField.setErrors({ 'required': true });
      this.controlField.markAsTouched();
    } else {
      const errors = { ...this.controlField.errors };
      delete errors.required;
      this.controlField.setErrors(Object.keys(errors).length ? errors : null);
    }
  }

  validateScheduleTime() {
    if (this.minDateControl) {
      const strDateTimeSelected = this.minDateControl?.value?.toLocaleString(DateTime.DATE_MED) + ', ' + this.controlField?.value;
      const dateTimeSelected = DateTime.fromFormat(strDateTimeSelected, "ff");
      const now = DateTime.now();
      const scheduledDateTime = DateTime.utc(dateTimeSelected.year, dateTimeSelected.month, dateTimeSelected.day, dateTimeSelected.hour, dateTimeSelected.minute);
      const minDateTime = DateTime.utc(now.year, now.month, now.day, now.hour + 1, now.minute);
      if (this.minDateControl?.errors === null && this.controlField?.errors === null && dateTimeSelected.isValid && scheduledDateTime.toMillis() < minDateTime.toMillis()) {
        this.controlField?.setErrors({ invalidMinTime: true })
      } else {
        const errors = { ...this.controlField?.errors };
        delete errors.invalidMinTime;
        this.controlField?.setErrors(Object.keys(errors).length ? errors : null);
      }
    }
  }

  onTimeChange(event: any) {
    this.inputTime.nativeElement.value = event;
    this.inputTime.nativeElement.dispatchEvent(new Event('change'));
    this.timeChanged.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRequired) {
      this.checkIsRequiredControl();
    }
  }

  checkIsRequiredControl() {
    if (this.isRequired) {
      this.controlField.addValidators(Validators.required);
      this.controlField.updateValueAndValidity({ emitEvent: false });
    } else {
      this.controlField.removeValidators(Validators.required);
      this.controlField.updateValueAndValidity({ emitEvent: false });
    }
  }
}
