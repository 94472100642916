<div class="calculation" id="calculation-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="list-display">
      <div class="list-header">
        <span class="title-name">Calculation</span>
        <div class="button-container">
          <div class="separator"></div>
          <div class="button-group">
            <ptg-button
              buttonName="Add Calculation"
              classInput="edit-button"
              (clickButton)="onClickAddCalculation()"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </div>
      </div>

      <div class="grid-wrapper">
        <ptg-grid
          [data]="calculationsData"
          [columns]="columns"
          [isLoading]="isLoading"
          [totalRecords]="totalRecords"
          [pageSize]="pageSize"
          [pageNumber]="pageNumber"
          [fitToParent]="true"
          [errorMessage]="errorMsg"
          (pageChange)="onChangePage($event)"
          (sortChange)="onChangeSort($event)"
          notFoundMessage="No Calculation to Display"
        >
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="onClickEditCalculation(row)"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onClickRemoveCalculation(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </div>
</div>
