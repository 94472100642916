import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { uploadFileBlue } from '@ptg-shared/constance/listIcons.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { UploadPopupComponent } from '@ptg-shared/controls/upload-popup/upload-popup.component';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import {
  setFractionalLength,
  stringToBoolean,
  toFixedTrunc,
} from '@ptg-shared/utils/string.util';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, SORT_TYPE, STATE } from '@ptg-shared/constance/value.const';
import {
  ACTION_COLUMN,
  Align,
  Column,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromReducer from '@ptg-reducers';

import { MemberSectionAttachmentComponent } from '../../components/member-section-attachment/member-section-attachment.component';
import { ICON_STATUS_FIELDS } from '../../constance/member-list.const';
import * as MetadataActions from '../../actions/metadata.actions';
import { MemberDetailActions } from '../../actions';
import { FixedSection } from '../../constance/metadata.const';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import {
  MemberDetailWithPaging,
  MetadataProperty,
  MetadataSection,
  StatusHistory,
} from '../../types/models';
import * as fromMember from '../../reducers';
import { getColumConfig } from '../../helpers';
import { AddMemberSectionDataComponent } from '../add-member-section-data/add-member-section-data.component';
import { EditMemberStatusHistoryComponent } from '../edit-member-status-history/edit-member-status-history.component';

const PAGE_SIZE_CONST = '-ptg-overview-section-pageSize'

@Component({
  selector: 'ptg-overview-section',
  templateUrl: './overview-section.component.html',
  styleUrls: ['./overview-section.component.scss'],
})
export class OverviewSectionComponent extends BaseComponent {
  errorMsg?: string;
  isLoading = false;
  columns: Column[] = [];
  dataTable!: (any & Row)[];
  addToTop!: boolean;
  sectionData!: MetadataSection;
  memberId: string = '';
  metaDataPropertyValues: any;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  state!: { [p: string]: boolean } | undefined;
  sectionKey = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
    },
    {
      name: '',
    },
  ];
  currentFund: any = {};
  addTypeSelectControl = new FormControl('');
  addTypeOptions = [
    {
      displayValue: 'Add to Top',
      value: true,
      iconConfig: {
        icon: 'add',
        isSvg: false,
        iconFirst: true,
      },
    },
    {
      displayValue: 'Add to Bottom',
      value: false,
      iconConfig: {
        icon: 'add',
        isSvg: false,
        iconFirst: true,
      },
    },
  ];
  sortInfo: {} | any = {};
  lengthPg!: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  isMetadataSection?: boolean;
  currentRowIndex: number = 0;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super();
    iconRegistry.addSvgIconLiteral(
      'upload-icon',
      sanitizer.bypassSecurityTrustHtml(uploadFileBlue)
    );
    this.state = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));

      });
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.sectionKey = params.sectionKey;
      this.isMetadataSection = params.sectionKey !== FixedSection.Status;
      this.getSectionOverview(params.sectionKey, this.isMetadataSection);
      this.listBreadcrumbs[0].url = `/member/detail/${this.memberId}`;
    });
    this.emitAddSectionState();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(MemberDetailActions.clearMemberDetail());
    this.memberStore.dispatch(MetadataActions.clearMetadata());
  }

  emitAddSectionState() {
    this.memberStore
      .pipe(
        select(fromMember.selectAddSectionState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }

        if (state.metaDataPropertyValues?.table && state.sectionData) {
          this.metaDataPropertyValues = state.metaDataPropertyValues;
          this.lengthPg = this.metaDataPropertyValues?.total;
          this.getConfigColumns(
            state.sectionData.properties,
            state.sectionData.hasAttachment
          );
          this.dataTable = state.metaDataPropertyValues.table.map(
            (item: any, index: number) =>
              this.getColumnData(
                item,
                state.sectionData.properties,
                index,
                state.sectionData.hasAttachment
              )
          );
          this.dataTable = this.dataTable.map((item: any) => {
            return {
              ...item,
              [ICON_STATUS_FIELDS.statusIconName]: item._typedValue?.iconName,
              [ICON_STATUS_FIELDS.statusIconColor]: item._typedValue?.color,
              [ICON_STATUS_FIELDS.statusIconLabel]: item._typedValue?.name,
            };
          });
          this.sectionData = state.sectionData;
          this.listBreadcrumbs[1].name =
            this.sectionData?.sectionTitle + ' Overview';
          if (this.state?.isShowBanner) {
            this.showBanner(this.state?.saveSectionDataSuccess);
            this.state = undefined;
          }
        }
        if (state.reloadSection) {
          this.showBanner(
            state.saveSectionDataSuccess,
            state.isEdit,
            state.isRemove,
            state.isUploadFile
          );
          if (this.dataTable.length !== 1 || !state.isRemove) {
            this.isMetadataSection
              ? this.getSectionData(state.sectionData.key)
              : this.getSectionDataWithPaging();
          }
        }
      });
  }

  showBanner(
    saveSectionDataSuccess?: boolean,
    isEdit?: boolean,
    isRemove?: boolean,
    isUploadFile?: boolean
  ) {
    if (saveSectionDataSuccess) {
      this.bannerType = BannerType.Success;
      if (isEdit) {
        showBanner.call(
          this,
          STATE.SUCCESS,
          this.sectionData?.sectionTitle!,
          ACTION.EDIT
        );
      } else if (isRemove) {
        showBanner.call(
          this,
          STATE.SUCCESS,
          this.sectionData?.sectionTitle!,
          ACTION.REMOVE
        );
      } else if (isUploadFile) {
        showBanner.call(
          this,
          STATE.SUCCESS,
          this.sectionData?.sectionTitle!,
          ACTION.UPLOAD
        );
      } else {
        showBanner.call(
          this,
          STATE.SUCCESS,
          this.sectionData?.sectionTitle!,
          ACTION.ADD
        );
      }
    } else {
      this.bannerType = BannerType.Fail;
      if (isEdit) {
        showBanner.call(
          this,
          STATE.FAIL,
          this.sectionData?.sectionTitle!,
          ACTION.EDIT
        );
      } else if (isRemove) {
        showBanner.call(
          this,
          STATE.FAIL,
          this.sectionData?.sectionTitle!,
          ACTION.REMOVE
        );
      } else if (isUploadFile) {
        showBanner.call(
          this,
          STATE.FAIL,
          this.sectionData?.sectionTitle!,
          ACTION.UPLOAD
        );
      } else {
        showBanner.call(
          this,
          STATE.FAIL,
          this.sectionData?.sectionTitle!,
          ACTION.ADD
        );
      }
    }
  }

  getSectionData(sectionKey: string = '') {
    this.memberStore.dispatch(
      MemberDetailActions.getSectionData({
        memberId: this.memberId,
        sectionKey,
      })
    );
  }

  getSectionOverview(sectionKey: string, isMetadataSection: boolean) {
    this.memberStore.dispatch(
      MemberDetailActions.getSectionOverview({
        memberId: this.memberId,
        sectionKey,
        isMetadataSection,
      })
    );
  }

  getConfigColumns(
    properties: MetadataProperty[],
    hasAttachment: boolean = false
  ) {
    this.columns = [];
    properties.forEach((el: any) => {
      const columConfig = getColumConfig(el.type, el.config);
      this.columns.push({
        name: el.key,
        header: {
          title: el.name,
        },
        truncate: true,
        sortable: !this.isMetadataSection,
        type: columConfig.type,
        templateArgs: columConfig.templateArgs,
      });
    });
    if (hasAttachment) {
      this.columns.push({
        name: 'noOfAttachments',
        header: {
          title: 'Number of Attachments',
        },
        align: Align.Right,
      });
    }
    this.columns.push({
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: this.isMetadataSection ? '230px' : '170px',
    });
  }

  changeItemIndex(event: ReorderInfo) {
    this.memberStore.dispatch(
      MemberDetailActions.reorderSectionTable({
        memberId: this.memberId,
        metadataItem: this.sectionData.key,
        body: {
          moveFromIndex: event.previousIndex,
          moveToIndex: event.currentIndex,
        },
      })
    );
  }

  back() {
    this.router.navigateByUrl(`/member/detail/${this.memberId}`);
  }

  editRecord(rowData: any) {
    let editData = {
      ...((this.metaDataPropertyValues?.table as []).find(
        (x) => x['index'] === rowData['index']
      ) as any),
    };
    if (this.isMetadataSection) {
      this.sectionData.properties.forEach((prop) => {
        if (
          prop.type === PropertyType.TYPE_LIST ||
          prop.type === PropertyType.TYPE_EMPLOYER ||
          prop.type === PropertyType.TYPE_DEPARTMENT
        ) {
          editData[prop.key] = prop.options?.find(
            (x) => x.id === editData[prop.key]
          )?.text;
        }
      });

      this.openEditDialog({
        isAddNew: false,
        data: { [this.sectionData.key]: editData },
        notShowFile: true,
      });
    } else if (this.sectionKey === FixedSection.Status) {
      const statusHistory: StatusHistory = {
        id: editData.Id,
        statusId: editData.StatusId,
        eventId: editData.EventId,
        statusDate: editData.StatusDate,
        statusName: editData.StatusName,
        statusEvent: editData.StatusEvent,
        _typedValue: editData._typedValue,
      };
      this.onEditMemberStatus(statusHistory);
    }
  }

  removeRecord($event: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: 'Are you sure you want to remove this row?',
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.dataTable.length === 1) {
          this.back();
        }
        this.memberStore.dispatch(
          MemberDetailActions.removeSectionTable({
            sectionData: this.sectionData,
            body: {
              memberId: this.memberId,
              sectionKey: this.sectionKey,
              index: $event.index,
            },
          })
        );
      }
    });
  }

  uploadFile() {
    const dialogRef = this.dialog.open(UploadPopupComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      width: 'auto',
      height: 'auto',
      autoFocus: false,
      data: { fileName: this.metaDataPropertyValues?.attachment?.label },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let body = result;
        body.sectionKey = this.sectionKey;
        this.memberStore.dispatch(
          MemberDetailActions.uploadFileSectionTable({
            memberId: this.memberId,
            body: [body],
          })
        );
      }
    });
  }

  addSectionRecord(): void {
    this.addToTop = this.addTypeSelectControl.value;
    this.openEditDialog({
      addToTop: this.addToTop,
      isAddNew: true,
      notShowFile: true,
    });
  }

  openEditDialog(options: any) {
    const sectionData = JSON.parse(JSON.stringify(this.sectionData));
    this.dialog.open(AddMemberSectionDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        section: sectionData,
        memberId: this.memberId,
        metadataItem: sectionData.key,
        isAllData: true,
        ...options,
      },
    });
  }

  getColumnData(
    item: any,
    properties: MetadataProperty[],
    index: number,
    hasAttachment: boolean = false
  ) {
    const columnData = deepClone(item);
    Object.keys(columnData).forEach((propertyName) => {
      if (columnData[propertyName]) {
        const property = properties.find(
          (config) => propertyName === config.key
        );
        switch (property?.type) {
          case 'Decimal': {
            const valueDecimal = columnData[propertyName];
            if (property?.config?.fractionalLengthInput) {
              const fractionalLength = setFractionalLength(
                valueDecimal,
                property?.config?.fractionalLengthInput
              );
              columnData[propertyName] = toFixedTrunc(
                valueDecimal,
                fractionalLength || 0
              );
            } else {
              columnData[propertyName] =
                valueDecimal % 1
                  ? Number(
                      valueDecimal.toString().split('.')[0]
                    ).toLocaleString() +
                    '.' +
                    valueDecimal.toString().split('.')[1]
                  : Number(valueDecimal).toLocaleString();
            }
            break;
          }
          case PropertyType.TYPE_LIST:
          case PropertyType.TYPE_DEPARTMENT:
          case PropertyType.TYPE_EMPLOYER: {
            columnData[propertyName] = property?.options?.find(
              (op: any) => op.id === columnData[propertyName]
            )?.text;
            break;
          }
          case PropertyType.TYPE_BOOLEAN: {
            //Removed condition for Ticket 141711
            columnData[propertyName] = columnData[propertyName];
            break;
          }
          case PropertyType.TYPE_PERCENTAGE: {
            columnData[propertyName] = +columnData[propertyName];
            break;
          }
          case PropertyType.TYPE_PERSON_NAME: {
            columnData[propertyName].prefix = property?.options?.find(
              (op: any) => op.id === columnData[propertyName]?.prefix
            )?.text;
            break;
          }
          case PropertyType.TYPE_ADDRESS: {
            columnData[propertyName].state = property?.options?.find(
              (op: any) => op.id === columnData[propertyName]?.state
            )?.description;
            columnData[propertyName].country = property?.options?.find(
              (op: any) => op.id === columnData[propertyName]?.country
            )?.description;
            break;
          }
        }
      }
    });
    if (hasAttachment) {
      columnData.attachment = 'View Detail';
    }
    return columnData;
  }

  onSortChange(event: Sort) {
    this.sortInfo = event;
    this.getSectionDataWithPaging();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
     
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString()); 
    this.getSectionDataWithPaging();
  }

  getSectionDataWithPaging() {
    let sortType = 1;
    let sortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = this.sortInfo.active;
      sortType =
        this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const query: MemberDetailWithPaging = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      MemberDetailActions.getSectionData({
        memberId: this.memberId,
        sectionKey: this.sectionKey,
        query,
      })
    );
  }

  onEditMemberStatus(statusHistory?: any) {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: this.sectionData?.sectionTitle,
        statusHistory,
        isRedirectFromOverview: true,
        sectionData: this.sectionData,
      },
    });
  }

  viewAttachment(event: any) {
    this.dialog.open(MemberSectionAttachmentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        configSection: this.sectionData,
        breadcrumbs: [
          {
            name: 'Overview',
            url: this.router.url,
          },
          {
            name: `${this.sectionData?.sectionTitle} - Attachments`,
            url: '',
          },
        ],
        rowId: event.rowId,
        menuName: this.sectionData?.sectionTitle,
      },
    });
  }
}
