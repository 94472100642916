import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers';

import { PropertyType } from '../constance/metadataPropertyType.const';
import {
  EditMemberEventBody,
  GetMemberAttachmentsResponse,
  MemberAttachmentsListSectionQuery,
  MemberDetail,
  MemberDetailWithPaging,
  MemberPropetyDataByMaskedConfigQuery,
  MemberPropetyDataByMaskedConfigResponse,
  PayrollConfigurationInfo,
  ReorderSectionRequestModel,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MemberDetailService {
  memberFormData: FormData = new FormData();
  isRetiree = false;
  memberId: string = '';
  activeFund: any;
  statusId: string = '';
  eventId: string = '';

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  memberDetail(
    id: string,
    listSection: string[],
    isMasked: boolean = true,
    isAllData: boolean = false,
    menuId?: string,
    pageSize: number = 10
  ): Observable<any> {
    let objOb: any = {};
    let params = new HttpParams();
    params = params.append('Masked', isMasked);
    if (!isAllData) {
      params = params.append('PageSize', pageSize);
      params = params.append('PageIndex', 1);
    }
    if (menuId) {
      params = params.append('memberNavigationItemId', menuId);
    }

    listSection.forEach((el) => {
      objOb[el] = this.httpClient
        .get<MemberDetail>(`${environment.apiUrl}/Members/${id}/${el}`, {
          params,
        })
        .pipe(catchError((error) => of(null)));
    });
    return forkJoin(objOb);
  }

  memberDetailWithPaging(
    id: string,
    itemKey: string,
    query: MemberDetailWithPaging,
    menuId?: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('Masked', true);
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);
    if (query.sortField) {
      params = params.append('SortNames', query.sortField);
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }

    if (query.attachmentPageSize) {
      params = params.append('AttachmentPageSize', query.attachmentPageSize);
    }
    if (query.attachmentPageIndex) {
      params = params.append('AttachmentPageIndex', query.attachmentPageIndex);
    }
    if (query.attachmentSortName) {
      params = params.append('AttachmentSortNames', query.attachmentSortName);
      params = params.append(
        'AttachmentSortType',
        query.attachmentSortType ? query.attachmentSortType : 0
      );
    }

    if (menuId) {
      params = params.append('memberNavigationItemId', menuId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/Members/${id}/${itemKey}/Paging`,
      { params }
    );
  }

  uploadMetaDataAttachment(body: any) {
    const data = new FormData();
    data.append('DocumentName', body[0].documentName || '');
    data.append('Description', body[0].description || '');
    data.append('RowId', body[0].rowId || '');
    data.append('File', body[0].file);

    return this.httpClient.post(
      `${environment.apiUrl}/Members/${body[0].memberId}/${body[0].itemKey}/MetadataAttachments`,
      data
    );
  }

  updateMetaDataAttachment(body: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/${body.memberId}/${body.itemKey}/MetadataAttachments/${body.attachmentId}`,
      body
    );
  }

  removeMetaDataAttachment(body: any) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Members/${body.memberId}/${body.itemKey}/MetadataAttachments/${body.attachmentId}`
    );
  }

  checkMetaDataAttachmentExists(body: any) {
    let params = new HttpParams();
    if (body.fileName) {
      params = params.append('fileName', body.fileName);
    }
    if (body.rowId) {
      params = params.append('rowId', body.rowId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/Members/${body.memberId}/${body.itemKey}/MetadataAttachments/Exists`,
      { params }
    );
  }

  lockMember(id: string): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Members/${id}/Lock`, {});
  }

  unlockMember(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Members/${id}/Lock`);
  }

  removeMember(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Members/${id}`);
  }

  removeSection(id: string, sectionKey: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Members/${id}/${sectionKey}`
    );
  }

  getMemberId(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/Members/` + id);
  }

  addSection(
    memberId: string,
    metadataItem: string,
    body: any
  ): Observable<any> {
    let formData = new FormData();
    formData.append('AddToTop', body.addToTop);
    formData.append('SectionKey', body.sectionKey);
    for (let i = 0; i < body.propertyValues.length; i++) {
      let item = body.propertyValues[i];
      formData.append(`PropertyValues[${i}].type`, item.type);
      formData.append(`PropertyValues[${i}].key`, item.propertyKey);
      formData.append(`PropertyValues[${i}].value`, item.value);
      if (item.type === PropertyType.TYPE_UPLOAD_DOC) {
        formData.delete(`PropertyValues[${i}].value`);
        formData.append(`PropertyValues[${i}].file`, item.value);
      }
    }
    return this.httpClient.post(
      `${environment.apiUrl}/Members/${memberId}/${metadataItem}/CreateNewSection`,
      formData
    );
  }

  getSection(
    memberId: string,
    sectionName: string,
    query?: MemberDetailWithPaging
  ) {
    let params = new HttpParams();
    params = params.append('Masked', true);
    if (query) {
      if (query.totalPerPage) {
        params = params.append('PageSize', query.totalPerPage);
      }
      params = params.append('PageIndex', query.pageIndex);
      if (query.sortField) {
        params = params.append('SortNames', query.sortField);
        params = params.append('SortType', query.sortType ? query.sortType : 0);
      }
    }
    return this.httpClient.get<any>(
      `${environment.apiUrl}/Members/${memberId}/${sectionName}`,
      { params }
    );
  }

  reorderSection(
    memberId: string,
    metadataItem: string,
    body: ReorderSectionRequestModel
  ) {
    return this.httpClient.put<any>(
      `${environment.apiUrl}/Members/${memberId}/${metadataItem}/ReorderSectionData`,
      body
    );
  }

  addSectionDataListView(body: any) {
    let formData = new FormData();
    formData.append('StatusId', body.statusId);
    formData.append('MemberId', body.memberId);
    formData.append('MemberKey', body.memberKey);
    formData.append('Sections[0].SectionKey', body.sectionKey);
    for (let i = 0; i < body.propertyValues.length; i++) {
      let item = body.propertyValues[i];
      formData.append(`Sections[0].PropertyValues[${i}].type`, item.type);
      formData.append(`Sections[0].PropertyValues[${i}].key`, item.propertyKey);
      formData.append(`Sections[0].PropertyValues[${i}].value`, item.value);
      if (item.type === PropertyType.TYPE_UPLOAD_DOC) {
        formData.delete(`Sections[0].PropertyValues[${i}].value`);
        formData.append(`Sections[0].PropertyValues[${i}].file`, item.value);
      }
    }
    return this.httpClient.post(
      `${environment.apiUrl}/Members/AddSectionData`,
      formData
    );
  }

  editSectionDataListView(body: any) {
    let formData = new FormData();
    formData.append('MemberId', body.memberId);
    formData.append('MemberKey', body.memberKey);
    formData.append('Sections[0].SectionKey', body.sectionKey);
    for (let i = 0; i < body.propertyValues.length; i++) {
      let item = body.propertyValues[i];
      formData.append(`Sections[0].PropertyValues[${i}].type`, item.type);
      formData.append(`Sections[0].PropertyValues[${i}].key`, item.propertyKey);
      formData.append(`Sections[0].PropertyValues[${i}].value`, item.value);
      if (item.type === PropertyType.TYPE_UPLOAD_DOC) {
        if (item?.value?.size) {
          formData.delete(`Sections[0].PropertyValues[${i}].value`);
          formData.append(`Sections[0].PropertyValues[${i}].file`, item.value);
        } else if (!item?.value) {
          formData.delete(`Sections[0].PropertyValues[${i}].value`);
        }
      }
    }
    return this.httpClient.put(
      `${environment.apiUrl}/Members/${body.memberId}/EditSectionData`,
      formData
    );
  }

  editSectionTableView(memberId: string, metadataItem: string, body: any) {
    let formData = new FormData();
    formData.append('Index', body.index);
    for (let i = 0; i < body.propertyValues.length; i++) {
      let item = body.propertyValues[i];
      formData.append(`PropertyValues[${i}].type`, item.type);
      formData.append(`PropertyValues[${i}].key`, item.propertyKey);
      formData.append(`PropertyValues[${i}].value`, item.value);
      if (item.type === PropertyType.TYPE_UPLOAD_DOC) {
        formData.delete(`PropertyValues[${i}].value`);
        formData.append(`PropertyValues[${i}].file`, item.value);
      }
    }
    return this.httpClient.put(
      `${environment.apiUrl}/Members/${memberId}/${metadataItem}/UpdateSection`,
      formData
    );
  }

  removeSectionTable(body: any) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Members/${body.memberId}/${body.sectionKey}/${body.index}`
    );
  }

  uploadFileSection(memberId: string, body: any) {
    let payload = new FormData();
    payload.append('SectionKey', body[0].sectionKey);
    payload.append('File', body[0].file);
    return this.httpClient.post(
      `${environment.apiUrl}/Members/${memberId}/UploadFileForMemberSection`,
      payload
    );
  }

  getMemberDetail(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/Members/Detail/` + id);
  }

  checkRetiredMember(id: string) {
    return this.httpClient
      .get(`${environment.apiUrl}/Members/Detail/${id}`)
      .pipe(
        map((el: any) => {
          const isRetiree = el?.member?.status === 'Retired';
          this.isRetiree = isRetiree;
          return isRetiree;
        }),
        catchError(() => of(null))
      );
  }

  editMemberEvent(body: EditMemberEventBody) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/SetMemberEvent`,
      body
    );
  }

  checkUnique = (bodyCheck: any) => {
    if (bodyCheck.value && bodyCheck.sectionKey) {
      let value = bodyCheck.value;
      if (bodyCheck.propType === PropertyType.TYPE_DATE) {
        const datepipe = new DatePipe('en-US');
        value = value ? datepipe.transform(value, 'yyyy-MM-dd') : '';
      }
      let body: any = {
        value: value,
      };

      if (bodyCheck.memberId) {
        body.memberId = bodyCheck.memberId;
      }
      if (bodyCheck.index || bodyCheck.index === 0) {
        body.index = bodyCheck.index;
      }
      return this.httpClient.post(
        `${environment.apiUrl}/Metadata/${bodyCheck.sectionKey}/${bodyCheck.propKey}/CheckUnique`,
        body
      );
    }
    return of(null);
  };

  getPropetyDataByMaskedConfig(query: MemberPropetyDataByMaskedConfigQuery) {
    return this.httpClient.get<MemberPropetyDataByMaskedConfigResponse>(
      `${environment.apiUrl}/Members/${query.memberId}/${query.itemKey}/${query.propertyKey}/${query.index}/GetSingleMemberPropertyDataWithMasked/${query.optionMasked}`
    );
  }

  getMemberConfiguredIdName(): Observable<any> {
    return this.httpClient.get<PayrollConfigurationInfo>(
      `${environment.apiUrl}/Members/ConfiguredIdName`
    );
  }

  getMemberAttachmentsListSection(
    memberId: string,
    itemKey: string,
    query: MemberAttachmentsListSectionQuery
  ) {
    let params = new HttpParams();
    if (query) {
      params = params.append('PageIndex', query.pageIndex);
      params = params.append('PageSize', query.pageSize);
      if(query.rowId){
        params = params.append('RowId', query.rowId);
      }

      if (query.sortField) {
        params = params.append('SortNames', query.sortField);
        params = params.append('SortType', query.sortType ? query.sortType : 0);
      }
    }
    return this.httpClient.get<GetMemberAttachmentsResponse>(
      `${environment.apiUrl}/Members/${memberId}/${itemKey}/MetadataAttachments`,
      { params }
    );
  }
}
