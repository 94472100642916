import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GetBenefitTypesResponse,
  GetStatusAndEventsResponse,
  GetBenefitRecordsResponse,
  GetLookupTableOptionsResponse,
  GetBenefitResponse,
  AddBenefitTypeRequest,
  UpdateBenefitRequest,
  GetLookupTablesReponse,
  GetQDROBenefitTypesResponse,
  GetBenefitTypesRequest,
  GetQDROMetaDataReponse,
  SetBenefitTypeRequest,
} from '../types/models';

@Injectable()
export class DefinedBenefitService {
  constructor(private httpClient: HttpClient) {}

  getBenefitTypes(
    request: GetBenefitTypesRequest
  ): Observable<GetBenefitTypesResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType || 0);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get<GetBenefitTypesResponse>(
      `${environment.apiUrl}/Members/BenefitTypes`,
      { params }
    );
  }

  getBenefit(): Observable<GetBenefitResponse> {
    return this.httpClient.get<GetBenefitResponse>(
      `${environment.apiUrl}/Members/Benefit`
    );
  }

  getAllBenefitType(
    request?: GetBenefitTypesRequest
  ): Observable<GetBenefitTypesResponse> {
    let params = new HttpParams();
    if (request?.benefitTypeId) {
      params = params.append('BenefitTypeId', request.benefitTypeId);
    }

    return this.httpClient.get<GetBenefitTypesResponse>(
      `${environment.apiUrl}/Members/BenefitTypes`,
      { params }
    );
  }

  getStatusAndEvents(): Observable<GetStatusAndEventsResponse> {
    return this.httpClient.get<GetStatusAndEventsResponse>(
      `${environment.apiUrl}/Members/GetMemberStatusEvent`
    );
  }

  getBenefitRecord(): Observable<GetBenefitRecordsResponse> {
    return this.httpClient.get<GetBenefitRecordsResponse>(
      `${environment.apiUrl}/Members/BenefitRecords`
    );
  }

  GetLookupTableOptions(): Observable<GetLookupTableOptionsResponse> {
    return this.httpClient.get<GetLookupTableOptionsResponse>(
      `${environment.apiUrl}/Members/LookupTableOptions`
    );
  }

  addBenefitType(request: AddBenefitTypeRequest): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Members/AddMemberBenefitType`,
      { ...request }
    );
  }

  checkBenefitTypeExists = (bodyCheck: { name?: string; code?: string }) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/BenefitType/Exists`,
      bodyCheck
    );
  };

  updateBenefit(request: UpdateBenefitRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/ConfigureBenefit`,
      request
    );
  }

  getLookupTables(): Observable<GetLookupTablesReponse> {
    return this.httpClient.get<GetLookupTablesReponse>(
      `${environment.apiUrl}/Members/LookupTables`
    );
  }

  getQDROBenefitTypes(): Observable<GetQDROBenefitTypesResponse> {
    return this.httpClient.get<GetQDROBenefitTypesResponse>(
      `${environment.apiUrl}/Members/QDROBenefitTypes`
    );
  }

  getQDROMetadatas(): Observable<GetQDROMetaDataReponse> {
    return this.httpClient.get<GetQDROMetaDataReponse>(
      `${environment.apiUrl}/Members/QDROMetadatas`
    );
  }

  removeBenefitType(benefitId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Members/RemoveBenefitType/${benefitId}`
    );
  }

  updateBenefitType(request: SetBenefitTypeRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/SetMemberBenefitType`,
      request
    );
  }
}
