import { createAction, props } from '@ngrx/store';

import {
  SetPaymentListConfigurations,
  GetPaymentListConfigurationsResponse,
} from '../types/models';

export const SetPaymentListConfigurationRequestAction =
  '[SetPaymentListConfiguration/API] Send Request';
export const SetPaymentListConfigurationSuccessAction =
  '[SetPaymentListConfiguration/API] Success';
export const SetPaymentListConfigurationFailureAction =
  '[SetPaymentListConfiguration/API] Failure';

export const GetPaymentListConfigurationRequestAction =
  '[GetPaymentListConfiguration/API] Send Request';
export const GetPaymentListConfigurationSuccessAction =
  '[GetPaymentListConfiguration/API] Success';
export const GetPaymentListConfigurationFailureAction =
  '[GetPaymentListConfiguration/API] Failure';

export const ClearPaymentListConfiguration = '[ClearListConfiguration] Clear';

export const setPaymentListConfigurations = createAction(
  SetPaymentListConfigurationRequestAction,
  props<{ body: SetPaymentListConfigurations }>()
);

export const setPaymentListConfigurationsSuccess = createAction(
  SetPaymentListConfigurationSuccessAction
);

export const setPaymentListConfigurationsFailure = createAction(
  SetPaymentListConfigurationFailureAction,
  props<{ error?: any }>()
);

export const getPaymentListConfigurations = createAction(
  GetPaymentListConfigurationRequestAction
);

export const getPaymentListConfigurationsSuccess = createAction(
  GetPaymentListConfigurationSuccessAction,
  props<{ response: GetPaymentListConfigurationsResponse }>()
);

export const getPaymentListConfigurationsFailure = createAction(
  GetPaymentListConfigurationFailureAction,
  props<{ error?: any }>()
);

export const clearPaymentListConfigurationState = createAction(
  ClearPaymentListConfiguration
);
