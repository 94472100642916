import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { DropPlanService } from '@ptg-member/services/drop-plan.service';
import { CheckOverlapRequest, CheckOverlapResponse, DropPlan } from '@ptg-member/types/models/drop-plan.model';
import { BaseComponent } from '@ptg-shared/components';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as fromMember from 'src/app/member/reducers';
import * as DropPlanActions from 'src/app/member/actions/drop-plan.actions';
import { Subject } from 'rxjs';
import { AbstractControlStatus } from '@ptg-shared/models/common.model';
@Component({
  selector: 'ptg-edit-drop-plan',
  templateUrl: './edit-drop-plan.component.html',
  styleUrls: ['./edit-drop-plan.component.scss'],
})
export class EditDropPlanComponent extends BaseComponent {
  InputType = InputType;
  editForm: FormGroup;
  isEndDateError: boolean = true;
  formSubmit$ = new Subject<boolean>();
  isCheckExitsOverlap: boolean = false;
  isSubmit: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EditDropPlanComponent>,
    private memberStore: Store<fromMember.MemberState>,
    @Inject(MAT_DIALOG_DATA) public dataDetail: DropPlan,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dropPlanService: DropPlanService
  ) {
    super();
    this.editForm = this.fb.group({
      planYear: [
        this.dataDetail?.planYear ? this.dataDetail.planYear : '', {
          validators: [Validators.required],
          asyncValidators: checkApiValidator(this.dropPlanService.checkExitsPlanYear, 'planYear')
        }
      ],
      beginDate: [
        this.dataDetail.beginDate ? this.dataDetail.beginDate : '',[Validators.required],
      ],
      endDate: [this.dataDetail?.endDate ? this.datePipe.transform(new Date(this.dataDetail.endDate), 'MM/dd/yyyy') : '-'],
      annualInterestRate: [
        this.dataDetail?.annualInterestRate
          ? this.dataDetail.annualInterestRate
          : '',
        [Validators.required],
      ],
      monthInterestRate: [
        this.dataDetail?.monthInterestRate
          ? this.dataDetail.monthInterestRate.toFixed(2)
          : '-',
      ],
    });
  }

  ngOnInit(): void {
    this.submitForm();
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(status => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        if(this.isCheckExitsOverlap) {
          this.isSubmit = true;
          return;
        }
        this.submit();
      });
  }

  submit() {
    if (this.editForm.invalid) {
      return;
    }
    const { beginDate, endDate } = this.editForm.value;
    const body = {
      ...this.editForm.value,beginDate: new DatePipe('en-US').transform(beginDate, "yyyy-MM-dd'T'00:00:00.000'Z'"),
      endDate: new DatePipe('en-US').transform(endDate, "yyyy-MM-dd'T'00:00:00.000'Z'"),
    };
    delete body.monthInterestRate;
    if(this.dataDetail?.id) {
      body.dropPlanId = this.dataDetail?.id;
    }
    this.memberStore.dispatch(
      DropPlanActions.setDropPlan({ body })
    );
    this.dialogRef.close();
  }

  onChangeBeginDate(value: any) {
    if(!value) {
      this.editForm.patchValue({
        endDate: '-'
      });
      return;
    }
    this.isCheckExitsOverlap = true;
    const { beginDate } = this.editForm.value;
    const nextBeginningYear = new Date(beginDate).setFullYear(new Date(beginDate).getFullYear() + 1);
    const nextBeginningDate = new Date(nextBeginningYear).setDate(new Date(nextBeginningYear).getDate() - 1);
    const body: CheckOverlapRequest = {
      beginDate: new DatePipe('en-US').transform(beginDate, "yyyy-MM-dd'T'00:00:00.000'Z'")
      ,
      endDate: new DatePipe('en-US').transform(nextBeginningDate, "yyyy-MM-dd'T'00:00:00.000'Z'"),
    };
    this.editForm.patchValue({
      endDate: new DatePipe('en-US').transform(nextBeginningDate, 'MM/dd/yyyy'),
    });
    if(this.dataDetail?.id) {
      body.dropPlanId = this.dataDetail?.id;
    }
    this.isEndDateError = true;
    this.dropPlanService.checkExitsOverlap(body).pipe(takeUntil(this.unsubscribe$)).subscribe((res: CheckOverlapResponse) => {
      if (res?.isBeginDateError || res?.isEndDateError) {
        if(res?.isBeginDateError) {
          this.editForm.get('beginDate')?.setErrors({inValidAsync:true});
        }
        if(res?.isEndDateError) {
          this.editForm.get('endDate')?.setErrors({inValidAsync:true});
          this.editForm.controls['endDate'].markAsTouched();
          if(!res?.isBeginDateError) {
            this.isEndDateError = false;
            this.editForm.get('beginDate')?.setErrors({inValidAsync:true});
          }
        }
      } else {
        if(this.isSubmit) {
          this.submit();
        }
      }
      this.isSubmit = false;
      this.isCheckExitsOverlap = false;
    });
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onChangeAnnualInterestRate() {
    const { annualInterestRate } = this.editForm.value;
    const getAnnualInterestRate =  this.editForm.controls['annualInterestRate'];
    
    if(getAnnualInterestRate.invalid) {
      this.editForm.patchValue({
        monthInterestRate: '-' 
      });
      return;
    }
    this.editForm.patchValue({
      monthInterestRate: ((Math.pow((+annualInterestRate / 100) + 1, 1/12) - 1) * 100).toFixed(2),
    });
  }
}
