import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/models';
import { STATE } from '@ptg-shared/constance/value.const';

import * as StatusHistoryAction from '../actions/status-history.action';
import { AuditTrail, MemberStatus, StatusHistoryList } from '../types/models';

export const StatusHistoryFeatureKey = 'statusHistory';

export interface State {
  isLoading: boolean;
  getStatusHistoryList?: BaseActionState<StatusHistoryList>;
  createStatusHistory?: BaseActionState;
  updateStatusHistory?: BaseActionState;
  listMemberStatus: MemberStatus[];
  getAuditTrails?: BaseActionState<AuditTrail[]>;
}

const initialState: State = {
  isLoading: true,
  listMemberStatus: [],
};

export const reducer = createReducer(
  initialState,
  on(StatusHistoryAction.getStatusHistoryList, (state) => ({
    ...state,
    isLoading: true,
    getStatusHistoryList: {
      isLoading: true,
    },
  })),
  on(
    StatusHistoryAction.statusHistoryListSuccess,
    (state, { statusHistoryList }) => ({
      ...state,
      isLoading: false,
      getStatusHistoryList: {
        isLoading: false,
        success: true,
        payload: statusHistoryList,
        total: statusHistoryList.total,
      },
    })
  ),
  on(StatusHistoryAction.statusHistoryListFailure, (state) => ({
    ...state,
    isLoading: false,
    getStatusHistoryList: {
      isLoading: false,
      success: false,
    },
  })),
  on(
    StatusHistoryAction.getMemberStatusListSuccess,
    (state, { listMemberStatus }) => ({
      ...state,
      listMemberStatus,
    })
  ),
  on(StatusHistoryAction.getMemberStatusListFailure, (state) => ({
    ...state,
    listMemberStatus: [],
  })),

  on(StatusHistoryAction.createMemberStatusHistoryAction, (state) => ({
    ...state,
    createStatusHistory: {
      isLoading: true,
    },
  })),
  on(StatusHistoryAction.createMemberStatusHistorySuccessAction, (state) => ({
    ...state,
    createStatusHistory: {
      isLoading: false,
      success: true,
    },
  })),
  on(StatusHistoryAction.createMemberStatusHistoryFailureAction, (state) => ({
    ...state,
    createStatusHistory: {
      isLoading: false,
      success: false,
    },
  })),
  on(
    StatusHistoryAction.clearCreateMemberStatusHistoryStateAction,
    (state) => ({
      ...state,
      createStatusHistory: undefined,
    })
  ),

  on(StatusHistoryAction.editMemberStatusHistoryAction, (state) => ({
    ...state,
    updateStatusHistory: {
      isLoading: true,
    },
  })),
  on(StatusHistoryAction.editMemberStatusHistorySuccessAction, (state) => ({
    ...state,
    updateStatusHistory: {
      isLoading: false,
      success: true,
    },
  })),
  on(StatusHistoryAction.editMemberStatusHistoryFailureAction, (state) => ({
    ...state,
    updateStatusHistory: {
      isLoading: false,
      success: false,
    },
  })),
  on(StatusHistoryAction.clearEditMemberStatusHistoryStateAction, (state) => ({
    ...state,
    updateStatusHistory: undefined,
  })),

  on(StatusHistoryAction.getAuditTrails, (state) => ({
    ...state,
    getAuditTrails: {
      isLoading: true,
    },
  })),
  on(StatusHistoryAction.getAuditTrailsSuccess, (state, { response }) => ({
    ...state,
    getAuditTrails: {
      isLoading: false,
      success: true,
      payload: response.auditTrails || [],
      total: response.total || 0,
    },
  })),
  on(StatusHistoryAction.getAuditTrailsFailure, (state, { error }) => ({
    ...state,
    getAuditTrails: {
      isLoading: false,
      success: false,
      error: error,
    },
  }))
);
