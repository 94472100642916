import { createAction, props } from '@ngrx/store';
import {
  CreateMemberStatusRequest,
  EditMemberStatusRequest,
  GetAuditTrailsRequest,
  GetAuditTrailsResponse,
  GetListStatusHistoryQuery,
  MemberStatus,
  StatusHistoryList,
} from '../types/models';

export const StatusHistoryListRequest = '[StatusHistoryList/API] Send Request';
export const StatusHistoryListSuccess = '[StatusHistoryList/API] Success';
export const StatusHistoryListFailure = '[StatusHistoryList/API] Failure';
export const GetMemberStatusListRequest =
  '[GetMemberStatusList/API] Send Request';
export const GetMemberStatusListSuccess = '[GetMemberStatusList/API] Success';
export const GetMemberStatusListFailure = '[GetMemberStatusList/API] Failure';

export const GetAuditTrailsRequestAction = '[GetAuditTrails/API] Send Request';
export const GetAuditTrailsSuccessAction = '[GetAuditTrails/API] Success';
export const GetAuditTrailsFailureAction = '[GetAuditTrails/API] Failure';

export const getStatusHistoryList = createAction(
  StatusHistoryListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const statusHistoryListSuccess = createAction(
  StatusHistoryListSuccess,
  props<{ statusHistoryList: StatusHistoryList }>()
);
export const statusHistoryListFailure = createAction(StatusHistoryListFailure);
export const getMemberStatusList = createAction(GetMemberStatusListRequest);
export const getMemberStatusListSuccess = createAction(
  GetMemberStatusListSuccess,
  props<{ listMemberStatus: MemberStatus[] }>()
);
export const getMemberStatusListFailure = createAction(
  GetMemberStatusListFailure
);

export const createMemberStatusHistoryAction = createAction(
  '[CreateMemberStatusHistory/API] Send Request',
  props<{ body: CreateMemberStatusRequest }>()
);
export const createMemberStatusHistorySuccessAction = createAction(
  '[CreateMemberStatusHistory/API] Success'
);
export const createMemberStatusHistoryFailureAction = createAction(
  '[CreateMemberStatusHistory/API] Failure'
);
export const clearCreateMemberStatusHistoryStateAction = createAction(
  '[CreateMemberStatusHistory] Clear'
);

export const editMemberStatusHistoryAction = createAction(
  '[EditMemberStatusHistory/API] Send Request',
  props<{ body: EditMemberStatusRequest }>()
);
export const editMemberStatusHistorySuccessAction = createAction(
  '[EditMemberStatusHistory/API] Success'
);
export const editMemberStatusHistoryFailureAction = createAction(
  '[EditMemberStatusHistory/API] Failure'
);
export const clearEditMemberStatusHistoryStateAction = createAction(
  '[EditMemberStatusHistory] Clear'
);

export const getAuditTrails = createAction(
  GetAuditTrailsRequestAction,
  props<{ request: GetAuditTrailsRequest }>()
);
export const getAuditTrailsSuccess = createAction(
  GetAuditTrailsSuccessAction,
  props<{ response: GetAuditTrailsResponse }>()
);
export const getAuditTrailsFailure = createAction(
  GetAuditTrailsFailureAction,
  props<{ error?: any }>()
);
