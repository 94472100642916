<div class="header">
  <span class="title">
    {{ offCyclePayment ? "Edit Payment Settings" : "Edit Payroll Settings" }}
  </span>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div *ngIf="!offCyclePayment" style="margin: 48px 0 16px 0">
    <ptg-checkbox
      [ngClass]="{'add-btn-disable': disabledSuspend}"
      [controlField]="formGroup.get('payStatus')"
      [isDisabled]="disabledSuspend"
      (changeValue)="onChangePayStatus()"
      label="Suspend"
    ></ptg-checkbox>
  </div>
  <div *ngIf="!offCyclePayment" style="border: 1px solid #CECECE;">
  </div>
  <div class="select-address">
    <ptg-select [controlField]="formGroup.get('address')"
                [isMultipleLineOption]="true"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="optionsAddress"
                [hasNoneValue]="true"
                [errorRequire]="!formGroup.get('payStatus')?.value ? 'Payment Address is required.' : ''"
                panelClass="property-name-dropdown"
                placeholder="Payment Address">
    </ptg-select>
  </div>
  <div class="representative-payee">
    <ptg-select [controlField]="formGroup.get('representativePayee')"
                [isMultipleLineOption]="true"
                [isOptionObj]="true"
                [hasNoneValue]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="optionsRepresentativePayees"
                panelClass="property-name-dropdown"
                placeholder="Representative Payee"
                (changeOptionValue)="changeRepresentativePayeeOptionValue()">
    </ptg-select>
  </div>
  <div style="border: 1px solid #CECECE;" *ngIf="(indexFederal > -1 || indexState > -1) && !offCyclePayment">
  </div>
  <div class="group-field" *ngIf="(indexFederal > -1 || indexState > -1) && !offCyclePayment">
    <div class="federal" *ngIf="indexFederal > -1">
      <p>Federal</p>
      <div class="group-control">
        <ptg-toggle-button
          [controlField]="formGroup.get('isRecalculateFederal')"
          label="Recalculate Federal Tax"
          [disabled]="disabledFederal"
      ></ptg-toggle-button>
        <ptg-select
          [controlField]="formGroup.get('formFederal')"
          placeholder="Form"
          [listData]="listForm"
          [isOptionObj]="true"
          [isRequired]="true"
          [isDisabled]="disabledFederal"
          (changeOptionValue)="onChangeFormFederal()"
        ></ptg-select>
        <ptg-select
          [controlField]="formGroup.get('withholdingFederal')"
          placeholder="Withholding"
          [listData]="listFederalTable"
          [isOptionObj]="true"
          [isRequired]="true"
          [isDisabled]="(formGroup.get('formFederal')?.value !== 0 && formGroup.get('formFederal')?.value !== 1) || disabledFederal"
        ></ptg-select>
        <div class="group-control-field" *ngIf="formGroup.get('formFederal')?.value === 1 && (formGroup.get('withholdingFederal')?.value !== 'None' && !!formGroup.get('withholdingFederal')?.value)">
          <ptg-textbox
          class="income-invalid-value"
          [controlField]="formGroup.get('incomeFederal')"
          placeholder="Income from Job/Pension/Annuities"
          [hasLabel]="true"
          inputType="Currency"
          [isDisabled]="disabledFederal"
          [min]="-9999999999999.99"
          [max]="9999999999999.99"
          errorMinMaxValue="Income from Job/Pension/Annuities must be within the range of -9,999,999,999,999.99 - 9,999,999,999,999.99."
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="formGroup.get('claimDependentsFederal')"
          placeholder="Claim Dependents"
          [hasLabel]="true"
          inputType="Currency"
          [isDisabled]="disabledFederal"
          [min]="0.00"
          [max]="9999999999999.99"
          errorMinMaxValue="Claim Dependents must be within the range of 0.00 - 9,999,999,999,999.99."
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="formGroup.get('additionalIncomeFederal')"
          placeholder="Additional Income"
          [hasLabel]="true"
          inputType="Currency"
          [isDisabled]="disabledFederal"
          [min]="0.00"
          [max]="9999999999999.99"
          errorMinMaxValue="Additional Income must be within the range of 0.00 - 9,999,999,999,999.99."
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="formGroup.get('additionalDeductionsFederal')"
          placeholder="Additional Deductions"
          [hasLabel]="true"
          inputType="Currency"
          [isDisabled]="disabledFederal"
          [min]="0.00"
          [max]="9999999999999.99"
          errorMinMaxValue="Additional Deductions must be within the range of 0.00 - 9,999,999,999,999.99."
        ></ptg-textbox>
        </div>
        <ptg-textbox
          *ngIf="formGroup.get('formFederal')?.value === 0 && (formGroup.get('withholdingFederal')?.value !== 'None' && !!formGroup.get('withholdingFederal')?.value)"
          [controlField]="formGroup.get('exemptionFederal')"
          placeholder="Exemption"
          [hasLabel]="true"
          inputType="Currency"
          [isDisabled]="disabledFederal"
          prefix=""
          [precision]="0"
          [maxLength]="15"
        ></ptg-textbox>
        <ptg-select
          [controlField]="formGroup.get('additionalWithholdingFederal')"
          placeholder="Additional Withholding"
          [listData]="listAdditional"
          [isOptionObj]="true"
          (changeOptionValue)="onChangeAdditionalFederal()"
          [isDisabled]="disabledFederal"
          [isRequired]="true">
        </ptg-select>
        <div [ngClass]="{'mgb-35' : formGroup.get('absoluteState')?.errors && (formGroup.get('absoluteState')?.touched || submitted) || formGroup.get('percentState')?.errors && (formGroup.get('absoluteState')?.touched || submitted), 'mgb-16': !formGroup.get('absoluteState')?.errors && !formGroup.get('percentState')?.errors}">
          <ng-container *ngIf="formGroup.get('additionalWithholdingFederal')?.value === 1">
            <ptg-textbox
              [controlField]="formGroup.get('absoluteFederal')"
              placeholder="Absolute"
              [hasLabel]="true"
              [isRequired]="formGroup.get('additionalWithholdingFederal')?.value === 1"
              inputType="Currency"
              [min]="0.00"
              [max]="9999999999999.99"
              [maxLength]="16"
              [isDisabled]="disabledFederal"
              errorMinMaxValue="Absolute must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </ng-container>
        </div>
        <div [ngClass]="{'mgb-35' : formGroup.get('absoluteFederal')?.errors && (formGroup.get('absoluteFederal')?.touched || submitted) || formGroup.get('percentFederal')?.errors && (formGroup.get('percentFederal')?.touched || submitted), 'mgb-16': !formGroup.get('absoluteFederal')?.errors && !formGroup.get('percentFederal')?.errors}">
          <ng-container *ngIf="formGroup.get('additionalWithholdingFederal')?.value === 2">
            <ptg-textbox
              [controlField]="formGroup.get('percentFederal')"
              placeholder="Percent"
              [hasLabel]="true"
              [isRequired]="formGroup.get('additionalWithholdingFederal')?.value === 2"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              inputType="Currency"
              [isDisabled]="disabledFederal"
              suffix=" %"
              prefix=""
              [min]="0.00"
              [max]="100.00"
              [maxLength]="5"
              errorMinMax="Percent must be within the range of 0.00 - 100.00."
            ></ptg-textbox>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="state" *ngIf="indexState > -1">
      <p>State</p>
      <div class="group-control">
        <ptg-toggle-button
          [controlField]="formGroup.get('isRecalculateState')"
          label="Recalculate State Tax"
          [disabled]="disabledState"
      ></ptg-toggle-button>
        <ptg-select
          [controlField]="formGroup.get('formState')"
          placeholder="Form"
          [listData]="listForm"
          [isOptionObj]="true"
          [isRequired]="true"
          [isDisabled]="disabledState"
          (changeOptionValue)="onChangeFormState()"
        ></ptg-select>
        <ptg-select
          [controlField]="formGroup.get('withholdingState')"
          placeholder="Withholding"
          [listData]="listStateTable"
          [isOptionObj]="true"
          [isRequired]="true"
          [isDisabled]="(formGroup.get('formState')?.value !== 0 && formGroup.get('formState')?.value !== 1) || disabledState"
        ></ptg-select>
        <div class="group-control-field" *ngIf="formGroup.get('formState')?.value === 1 && (formGroup.get('withholdingState')?.value !== 'None' && !!formGroup.get('withholdingState')?.value)">
          <ptg-textbox
            class="income-invalid-value"
            [controlField]="formGroup.get('incomeState')"
            placeholder="Income from Job/Pension/Annuities"
            [hasLabel]="true"
            inputType="Currency"
            [isDisabled]="disabledState"
            [min]="-9999999999999.99"
            [max]="9999999999999.99"
            errorMinMaxValue="Income from Job/Pension/Annuities must be within the range of -9,999,999,999,999.99 - 9,999,999,999,999.99."
          ></ptg-textbox>
          <ptg-textbox
            [controlField]="formGroup.get('claimDependentsState')"
            placeholder="Claim Dependents"
            [hasLabel]="true"
            inputType="Currency"
            [isDisabled]="disabledState"
            [min]="0.00"
            [max]="9999999999999.99"
            errorMinMaxValue="Claim Dependents must be within the range of 0.00 - 9,999,999,999,999.99."
          ></ptg-textbox>
          <ptg-textbox
            [controlField]="formGroup.get('additionalIncomeState')"
            placeholder="Additional Income"
            [hasLabel]="true"
            inputType="Currency"
            [isDisabled]="disabledState"
            [min]="0.00"
            [max]="9999999999999.99"
            errorMinMaxValue="Additional Income must be within the range of 0.00 - 9,999,999,999,999.99."
          ></ptg-textbox>
          <ptg-textbox
            [controlField]="formGroup.get('additionalDeductionsState')"
            placeholder="Additional Deductions"
            [hasLabel]="true"
            inputType="Currency"
            [isDisabled]="disabledState"
            [min]="0.00"
            [max]="9999999999999.99"
            errorMinMaxValue="Additional Deductions must be within the range of 0.00 - 9,999,999,999,999.99."
          ></ptg-textbox>
        </div>
        <ptg-textbox
          *ngIf="formGroup.get('formState')?.value === 0 && (formGroup.get('withholdingState')?.value !== 'None' && !!formGroup.get('withholdingState')?.value)"
          [controlField]="formGroup.get('exemptionState')"
          placeholder="Exemption"
          [hasLabel]="true"
          inputType="Currency"
          [isDisabled]="disabledState"
          prefix=""
          [precision]="0"
          [maxLength]="15"
        ></ptg-textbox>
        <ptg-select
          [controlField]="formGroup.get('additionalWithholdingState')"
          placeholder="Additional Withholding"
          [listData]="listAdditional"
          [isOptionObj]="true"
          [isDisabled]="disabledState"
          (changeOptionValue)="onChangeAdditionalState()"
          [isRequired]="true">
        </ptg-select>
       <div [ngClass]="{'mgb-35' : formGroup.get('absoluteState')?.errors && (formGroup.get('absoluteState')?.touched || submitted) || formGroup.get('percentState')?.errors && (formGroup.get('absoluteState')?.touched || submitted), 'mgb-16': !formGroup.get('absoluteState')?.errors && !formGroup.get('percentState')?.errors}">
          <ng-container *ngIf="formGroup.get('additionalWithholdingState')?.value === 1">
            <ptg-textbox
              [controlField]="formGroup.get('absoluteState')"
              placeholder="Absolute"
              [hasLabel]="true"
              [isRequired]="formGroup.get('additionalWithholdingState')?.value === 1"
              inputType="Currency"
              [isDisabled]="disabledState"
              [min]="0.00"
              [max]="9999999999999.99"
              [maxLength]="16"
              errorMinMaxValue="Absolute must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </ng-container>
        </div>
        <div [ngClass]="{'mgb-35' : formGroup.get('absoluteFederal')?.errors && (formGroup.get('absoluteFederal')?.touched || submitted) || formGroup.get('percentFederal')?.errors && (formGroup.get('percentFederal')?.touched || submitted), 'mgb-16': !formGroup.get('absoluteFederal')?.errors && !formGroup.get('percentFederal')?.errors}">
          <ng-container *ngIf="formGroup.get('additionalWithholdingState')?.value === 2">
            <ptg-textbox
              [controlField]="formGroup.get('percentState')"
              placeholder="Percent"
              [hasLabel]="true"
              [isRequired]="formGroup.get('additionalWithholdingState')?.value === 2"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              inputType="Currency"
              [isDisabled]="disabledState"
              suffix=" %"
              prefix=""
              [min]="0.00"
              [max]="100.00"
              [maxLength]="5"
              errorMinMax="Percent must be within the range of 0.00 - 100.00."
            ></ptg-textbox>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="wrap-btn">
    <button mat-raised-button type="submit" style="background-color: #408bf9;color: #fff; margin-right: 8px;">Save</button>
    <button mat-raised-button type="button" style="background-color: #acabab;color: #fff" (click)="onCancel()">Cancel</button>
  </div>
</form>
