import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { deepClone } from '@ptg-shared/utils/common.util';

import {
  MemberName,
  MemberStatus,
  MetadataSection,
  PropertyValue,
} from '../../types/models';
import * as fromMember from '../../reducers';
import { LookupTableType, SectionLayout } from '../../constance/metadata.const';

@Component({
  selector: 'ptg-demographic',
  templateUrl: './demographic.component.html',
  styleUrls: ['./demographic.component.scss'],
})
export class DemographicComponent implements OnInit, OnDestroy {
  demoDetail: any = {};
  propertiesConfig: any = {};
  configSection: any = {};
  subscriptions: any;
  defaultProperties = [
    'address',
    'birthday',
    'email',
    'hireDate',
    'last4Ssn',
    'name',
    'phone',
    'retireDate',
    'status',
    'attachments',
  ];
  additionalProperties: PropertyValue[] = [];
  sectionLayout = SectionLayout;

  @Input() properties!: any[];
  @Input() memberKey!: string;
  @Input() memberId!: string;
  @Input() memberStatus: MemberStatus | undefined;

  @Output() downloadPdfEvent = new EventEmitter();
  @Output() editSectionEvent = new EventEmitter();
  @Output() showAttachmentsEvent = new EventEmitter();
  unsubscribe$ = new Subject<void>();

  constructor(private memberStore: Store<fromMember.MemberState>) {}

  ngOnInit(): void {
    this.configSection = this.properties.find(
      (el) => el.key === 'demographics'
    );
    this.configSection?.properties?.forEach((element: any) => {
      this.propertiesConfig[element.key] = element;
    });
    combineLatest([
      this.memberStore.pipe(select(fromMember.selectMetadataOverview)),
      this.memberStore.pipe(select(fromMember.selectDemographicDetailState)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        const metadataOverview = deepClone(data[0]) as MetadataSection[];
        const demographicsDetail = deepClone(data[1]);
        const name = metadataOverview
          .find((metadata) => metadata.key === 'demographics')
          ?.properties?.find((property) => property.key === 'name');
        const addressProperty = metadataOverview
          .find((metadata) => metadata.key === 'demographics')
          ?.properties?.find((property) => property.key === 'address');
        if (demographicsDetail?.name?.prefix && name) {
          demographicsDetail.name.prefix = name.options?.find(
            (option) => option.id === demographicsDetail?.name?.prefix
          )?.text;
        }
        if (demographicsDetail?.address?.state && addressProperty) {
          demographicsDetail.address.state = addressProperty.options?.find(
            (o) =>
              o.id === demographicsDetail?.address?.state &&
              o.type === LookupTableType.State
          )?.text;
        }
        if (demographicsDetail?.address?.country && addressProperty) {
          demographicsDetail.address.country = addressProperty.options?.find(
            (o) =>
              o.id === demographicsDetail?.address?.country &&
              o.type === LookupTableType.Country
          )?.text;
        }
        if (demographicsDetail) {
          this.demoDetail = {
            ...demographicsDetail,
            name: new MemberName(
              demographicsDetail ? { ...demographicsDetail.name } : {}
            ),
          };
          this.additionalProperties = this.setListAdditionalProperty(
            this.demoDetail
          );
          Object.entries(this.demoDetail).forEach(([key, value]) => {
            if (this.propertiesConfig[key]) {
              this.propertiesConfig[key].value = value;
            }
          });
        } else {
          this.demoDetail = null;
        }
      });
  }

  setListAdditionalProperty(sectionData: any) {
    return Object.entries(sectionData)
      .filter(([key, value]) => this.defaultProperties.every((x) => x !== key))
      .map(([key, value]) => {
        return { key, value };
      });
  }

  editSection() {
    this.editSectionEvent.emit(this.configSection);
  }

  downloadPdf(statement: any) {
    this.downloadPdfEvent.emit(statement);
  }

  showAttachments() {
    this.showAttachmentsEvent.emit(this.configSection);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
