import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Subject, combineLatest, forkJoin } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import * as fromMember from 'src/app/member/reducers';
import * as TierConfigurationActions from 'src/app/member/actions/tier-configuration.actions';
import {
  TierConditionOperatorType,
  TierFilePropertyType,
} from '@ptg-member/constance/tier-configuration.const';
import { AsyncFunction } from '@ptg-shared/models/common.model';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { TierConfigurationService } from '@ptg-member/services/tier-configuration.service';
import { 
  BinaryDetail,
  BinaryValuesResponse,
  CheckExitsResponsive,
  ConditionLookupResponse,
  ConditionOperatorsResponse,
  EditTierRequest,
  PlanDetail,
  PropertiesDetail,
  ResultDetail,
  TierOperatorsDetail
  } from '@ptg-member/types/models';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';

@Component({
  selector: 'ptg-edit-tier-configuration',
  templateUrl: './edit-tier-configuration.component.html',
  styleUrls: ['./edit-tier-configuration.component.scss'],
})
export class EditTierConfigurationComponent implements OnInit, OnDestroy {
  InputType = InputType;
  PropertyType = TierFilePropertyType;
  TierConditionOperatorType = TierConditionOperatorType;
  unsubscribe$ = new Subject<void>();
  formSubmit$ = new Subject<boolean>();
  maxValueNumber = 9999999999999.99;
  editForm: FormGroup;
  asyncFn?: AsyncFunction;
  listPlansDefault: PlanDetail[] = [];
  listMetadataDefault: PropertiesDetail[] = [];
  listOperatorsDefault: TierOperatorsDetail[] = [];
  listPlans: PlanDetail[] = [];
  currentPlans: PlanDetail[] = [];
  selectedPlan: any = {};
  isLoading: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<EditTierConfigurationComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    @Inject(MAT_DIALOG_DATA) public statusDetail: any,
    private tierConfigurationService: TierConfigurationService
  ) {
    this.editForm = this.fb.group({
      tierCode: [
        this.statusDetail.tierCode ? this.statusDetail.tierCode : '',
        [Validators.required],
      ],
      tierName: [
        this.statusDetail.tierName ? this.statusDetail.tierName : '',
        [Validators.required],
      ],
      salaryCapping: [
        this.statusDetail.salaryCapping ? this.statusDetail.salaryCapping : null,
        [Validators.required],
      ],
      plan: [''],
      conditions: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.initPage();
  }

  initPage() {
    this.memberStore.dispatch(
      TierConfigurationActions.clearTierConfigurationState()
    );
    this.memberStore.dispatch(
      TierConfigurationActions.getTierConfigurationPlan()
    );
    this.memberStore.dispatch(
      TierConfigurationActions.getTierConfigurationMetadata()
    );
    combineLatest([
      this.memberStore.pipe(select(fromMember.selectTierConfigurationPlan)),
      this.memberStore.pipe(select(fromMember.selectTierConfigurationMetadata)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data[0]?.plans && data[1]?.properties) {
          this.listPlansDefault = data[0].plans.map((item: PlanDetail) => {
            return {
              ...item,
              value: item.planId,
              valueDescription: item.employerName,
              displayValue: item.name,
              selected: true,
            };
          });
          this.listMetadataDefault = data[1].properties.map((item: PropertiesDetail) => {
            return {
              ...item,
              value: item.propertyKey,
              valueDescription: item.sectionName,
              displayValue: item.propertyName,
              selected: true,
            };
          });
          this.setFormConditions();
          this.setPlan();
        }
      });
  }

  setPlan() {
    if (this.statusDetail?.id) {
      this.listPlansDefault.forEach((item: PlanDetail) => {
        const checkItemPlan = this.statusDetail.plans.find(
          (value: PlanDetail) => value.planId === item.planId
        );
        if (checkItemPlan) {
          item.selected = false;
        }
      });
      this.listPlans = this.listPlansDefault.filter(
        (item: PlanDetail) => item.selected
      );
      this.currentPlans = this.listPlansDefault.filter(
        (item: PlanDetail) => !item.selected
      );
    } else {
      this.listPlans = deepClone(this.listPlansDefault);
    }
  }

  async setFormConditions() {
    if (this.statusDetail?.id) {
      this.listMetadataDefault.forEach((item: PropertiesDetail) => {
        const checkItemSelected = this.statusDetail.conditions.find(
          (value: any) => value.propertyKey == item.propertyKey
        );
        item.selected = checkItemSelected ? false : true;
      });
      const conditions = this.statusDetail.conditions;
      for (let i = 0; i < conditions?.length; i++) {
        try {
          const getListOperator = this.tierConfigurationService.getTierConfigurationOperator({ type: conditions[i].propertyType, propertyKey: conditions[i].propertyKey });
          const getListBinaryValues: any = this.tierConfigurationService.getTierConfigurationBinaryValues({ propertyKey: conditions[i].propertyKey });
          const getListLookup: any = this.tierConfigurationService.getTierConfigurationConditionLookup({ type: conditions[i].propertyType, propertyKey: conditions[i].propertyKey });
          let formCreateSelect = [
            getListOperator
          ];
  
          if (
            ![
              TierConditionOperatorType.IsBetween,
              TierConditionOperatorType.IsIn,
              TierConditionOperatorType.IsNotIn,
            ].includes(conditions[i].operatorType) &&
            (conditions[i].propertyType === TierFilePropertyType.Lookup ||
              conditions[i].propertyType === TierFilePropertyType.Department)
          ) {
            formCreateSelect = [
              getListOperator, getListLookup
            ];
          }
          if (
            ![
              TierConditionOperatorType.IsBetween,
              TierConditionOperatorType.IsIn,
              TierConditionOperatorType.IsNotIn,
            ].includes(
              conditions[i].operatorType
            ) && conditions[i].propertyType === TierFilePropertyType.Binary
          ) {
            formCreateSelect = [
              getListOperator, getListBinaryValues
            ];
          }
          const data: any = await forkJoin(formCreateSelect).pipe(takeUntil(this.unsubscribe$)).toPromise();
          this.listOperatorsDefault = data[0]?.tierOperators.map(
            (item: TierOperatorsDetail) => {
              return {
                ...item,
                value: item.operatorType,
                displayValue: item.operatorName,
                selected: true,
              };
            }
          );
          const listMetadataSelected = this.listMetadataDefault.filter(
            (value: PropertiesDetail) => {
              return value.propertyKey === conditions[i].propertyKey || value.selected;
            }
          );
          const checkStepCondition = this.listMetadataDefault.find(
            (value: PropertiesDetail) => value.propertyKey === conditions[i].propertyKey
          );
          let listDropdown = [];
          if (
            ![
              TierConditionOperatorType.IsBetween,
              TierConditionOperatorType.IsIn,
              TierConditionOperatorType.IsNotIn,
            ].includes(conditions[i].operatorType) &&
            (conditions[i].propertyType === TierFilePropertyType.Lookup ||
              conditions[i].propertyType === TierFilePropertyType.Department)
          ) {
            listDropdown = data[1]?.result.map((item: ResultDetail) => {
              return {
                value: item.id,
                displayValue: item.value,
                valueDescription: item?.subText ? item?.subText : '',
              };
            });
          }
          if (
            ![
              TierConditionOperatorType.IsBetween,
              TierConditionOperatorType.IsIn,
              TierConditionOperatorType.IsNotIn,
            ].includes(
              conditions[i].operatorType) &&
              conditions[i].propertyType === TierFilePropertyType.Binary
          ) {
            listDropdown = data[1]?.result.map((item: BinaryDetail) => {
              return {
                ...item,
                displayValue: item.valueLabel,
              };
            });
          }
          const conditionForm = this.fb.group({
            propertyKey: [conditions[i].propertyKey, Validators.required],
            propertyType: [conditions[i].propertyType],
            listMetadata: [listMetadataSelected],
            operatorType: [conditions[i].operatorType, Validators.required],
            listOperator: [this.listOperatorsDefault],
            firstValue: [conditions[i].firstValue, Validators.required],
            secondValue: [
              conditions[i].operatorType === TierConditionOperatorType.IsBetween
                ? conditions[i].secondValue
                : null,
            ],
            listLookup: [
              listDropdown.length &&
              conditions[i].propertyType === TierFilePropertyType.Lookup
                ? listDropdown
                : [],
            ],
            listDepartment: [
              listDropdown.length &&
              conditions[i].propertyType === TierFilePropertyType.Department
                ? listDropdown
                : [],
            ],
            listBinary: [
              listDropdown.length &&
              conditions[i].propertyType === TierFilePropertyType.Binary
                ? listDropdown
                : [],
            ],
            min: [checkStepCondition?.minInput],
            max: [checkStepCondition?.maxInput],
          });
          if (conditions[i].secondValue) {
            conditionForm
              .get('secondValue')
              ?.setValidators([
                Validators.required,
                this.validateBetweenValue(),
              ]);
            conditionForm.get('secondValue')?.updateValueAndValidity();
          }
          this.conditions.push(conditionForm);
          
        } catch (e) {
          this.isLoading = false;
          break;
        }
      }
      this.isLoading = false;
    } else {
      this.addCondition();
      this.isLoading = false;
    }
  }

  get conditions() {
    return this.editForm.controls['conditions'] as FormArray;
  }

  validateBetweenValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !control.parent ||
        control.parent.get('operatorType')?.value !==
          TierConditionOperatorType.IsBetween
      ) {
        return null;
      }
      const { value: valueFrom } = control.parent.get(
        'firstValue'
      ) as FormControl;
      const { value: valueTo } = control.parent.get(
        'secondValue'
      ) as FormControl;
      const { value: propertyType } = control.parent.get(
        'propertyType'
      ) as FormControl;
      const isDate = propertyType === TierFilePropertyType.Date;

      if (valueFrom && valueTo && isDate) {
        return DateTime.fromISO(valueFrom) >= DateTime.fromISO(valueTo)
          ? {
              fromToError: `The input value must be greater than the ${new DatePipe(
                'en-US'
              ).transform(valueFrom, 'MM/dd/yyyy')}.`,
            }
          : null;
      }
      if (
        valueTo &&
        valueFrom &&
        (+valueFrom > +valueTo || +valueFrom === +valueTo)
      ) {
        return {
          fromToError: `The input value must be greater than the ${valueFrom}.`,
        };
      }
      return null;
    };
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onchangePlan() {
    this.editForm.get('plan')?.setValidators(null);
    this.editForm.get('plan')?.updateValueAndValidity();
    const { plan } = this.editForm.value;
    this.selectedPlan = this.listPlans.find((item: PlanDetail) => item.value === plan);
  }

  addPlan() {
    const { plan } = this.editForm.value;
    if (!this.listPlans.length || !plan?.length) {
      return;
    }
    this.listPlans = this.listPlans.filter((item: PlanDetail) => item.value !== plan);
    this.currentPlans.push(this.selectedPlan);
    const checkItemPlan = this.listPlansDefault.find(
      (item: PlanDetail) => item.planId === plan
    );
    if (checkItemPlan) {
      checkItemPlan.selected = false;
    }
    this.editForm.patchValue({
      plan: '',
    });
  }

  removePlan(data: PlanDetail, index: number) {
    this.currentPlans.splice(index, 1);
    const checkItemPlan = this.listPlansDefault.find(
      (item: PlanDetail) => item.planId === data.planId
    );
    if (checkItemPlan) {
      checkItemPlan.selected = true;
    }
    this.listPlans = this.listPlansDefault.filter((item: PlanDetail) => item.selected);
  }

  addCondition() {
    if (this.conditions.invalid) {
      return;
    }
    const { conditions } = this.editForm.value;
    this.listMetadataDefault.forEach((item: PropertiesDetail) => {
      const checkItemSelected = conditions.find(
        (value: any) => value.propertyKey == item.propertyKey
      );
      item.selected = checkItemSelected ? false : true;
    });

    const listMetadataSelected = this.listMetadataDefault.filter(
      (item: PropertiesDetail) => item.selected
    );
    const conditionForm = this.fb.group({
      propertyKey: ['', Validators.required],
      propertyType: [''],
      listMetadata: [listMetadataSelected],
      operatorType: ['', Validators.required],
      listOperator: [[]],
      firstValue: ['', Validators.required],
      secondValue: [''],
      listLookup: [[]],
      listDepartment: [[]],
      listBinary: [[]],
      min: [''],
      max: [''],
    });

    this.conditions.push(conditionForm);
  }

  deleteCondition(stepForm: any, index: number) {
    const stepFormGroup = stepForm as FormGroup;
    const propertyKey = stepFormGroup.controls['propertyKey'].value;
    const checkStepCondition = this.listMetadataDefault.find(
      (item: PropertiesDetail) => item.propertyKey === propertyKey
    );
    if (checkStepCondition) {
      checkStepCondition.selected = true;
    }
    this.conditions.removeAt(index);
    const { conditions } = this.editForm.value;
    conditions.forEach((item: any) => {
      const listMetadataSelected = this.listMetadataDefault.filter(
        (value: PropertiesDetail) => {
          return value.propertyKey === item.propertyKey || value.selected;
        }
      );
      item.listMetadata = listMetadataSelected;
    });
  }

  onChangeMetadata(stepForm: any, i: number) {
    const stepFormGroup = stepForm as FormGroup;
    this.updateListMetadata(i);
    const propertyKey = stepFormGroup.controls['propertyKey'].value;
    const checkStepCondition = this.listMetadataDefault.find(
      (item: PropertiesDetail) => item.propertyKey === propertyKey
    );

    this.memberStore.dispatch(
      TierConfigurationActions.clearTierConfigurationState()
    );
    this.memberStore.dispatch(
      TierConfigurationActions.getTierConfigurationOperator({
        query: { type: checkStepCondition?.propertyType, propertyKey: checkStepCondition?.propertyKey },
      })
    );
    this.memberStore
      .pipe(
        select(fromMember.selectTierConfigurationOperator),
        filter((state: any) => state?.tierOperators?.length),
        first(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state: ConditionOperatorsResponse) => {
        this.listOperatorsDefault = state.tierOperators.map((item: any) => {
          return {
            ...item,
            value: item.operatorType,
            displayValue: item.operatorName,
            selected: true,
          };
        });
        const listMetadataSelected = this.listMetadataDefault.filter(
          (value: PropertiesDetail) => {
            return value.propertyKey === propertyKey || value.selected;
          }
        );
        stepFormGroup.patchValue({
          listOperator: this.listOperatorsDefault,
          firstValue: '',
          secondValue: '',
          listMetadata: listMetadataSelected,
          propertyType: checkStepCondition?.propertyType,
          operatorType: '',
          min: checkStepCondition?.minInput,
          max: checkStepCondition?.maxInput,
        });
        stepFormGroup.markAsUntouched();
      });
  }

  onChangeFirstValue(stepForm: any) {
    const stepFormGroup = stepForm as FormGroup;
    stepFormGroup
      .get('secondValue')
      ?.updateValueAndValidity({ emitEvent: false });
  }

  updateListMetadata(value: number){
    const { conditions } = this.editForm.value;
    this.listMetadataDefault.forEach((item: PropertiesDetail) => {
      const checkItemSelected = conditions.find(
        (value: any) => value.propertyKey == item.propertyKey
      );
      item.selected = checkItemSelected ? false : true;
    });
    if (conditions?.length > 1) {
      conditions.forEach((item: any, index: number) => {
        if (index != value) {
          const listMetadataSelected = this.listMetadataDefault.filter(
            (value: PropertiesDetail) => {
              return value.propertyKey === item.propertyKey || value.selected;
            }
          );
          item.listMetadata = listMetadataSelected;
        }
      });
    }
  }

  onChangeOperator(stepForm: any) {
    const stepFormGroup = stepForm as FormGroup;
    stepFormGroup.patchValue({
      firstValue: '',
      secondValue: '',
    });
    stepFormGroup.markAsUntouched();
    const operatorType = stepFormGroup.controls['operatorType'].value;
    const propertyKey = stepFormGroup.controls['propertyKey'].value;
    const propertyType = stepFormGroup.controls['propertyType'].value;
    if (operatorType === this.TierConditionOperatorType.IsBetween) {
      stepFormGroup.controls['secondValue'].setValidators([
        Validators.required,
        this.validateBetweenValue(),
      ]);
    } else {
      stepFormGroup.controls['secondValue'].setValidators(null);
    }
    stepFormGroup.get('secondValue')?.updateValueAndValidity();
    if (
      ![
        TierConditionOperatorType.IsBetween,
        TierConditionOperatorType.IsIn,
        TierConditionOperatorType.IsNotIn,
      ].includes(operatorType) &&
      (propertyType === TierFilePropertyType.Lookup ||
        propertyType === TierFilePropertyType.Department)
    ) {
      this.memberStore.dispatch(
        TierConfigurationActions.clearTierConfigurationState()
      );
      this.memberStore.dispatch(
        TierConfigurationActions.getTierConfigurationConditionLookup({
          query: { type: propertyType, propertyKey: propertyKey },
        })
      );
      this.memberStore
        .pipe(
          select(fromMember.selectTierConfigurationLookup),
          filter((state: any) => state?.result?.length),
          first(),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state: ConditionLookupResponse) => {
          const result = state.result.map((item: ResultDetail) => {
            return {
              value: item.id,
              displayValue: item.value,
              valueDescription: item?.subText ? item?.subText : '',
            };
          });
          stepFormGroup.patchValue({
            listLookup:
              propertyType === TierFilePropertyType.Lookup ? result : [],
            listDepartment:
              propertyType === TierFilePropertyType.Department ? result : [],
          });
        });
    }
    if (
      ![
        TierConditionOperatorType.IsBetween,
        TierConditionOperatorType.IsIn,
        TierConditionOperatorType.IsNotIn,
      ].includes(operatorType) && propertyType === TierFilePropertyType.Binary
    ) {
      this.memberStore.dispatch(
        TierConfigurationActions.clearTierConfigurationState()
      );
      this.memberStore.dispatch(
        TierConfigurationActions.getTierConfigurationBinaryValues({
          query: { propertyKey: propertyKey },
        })
      );
      this.memberStore
        .pipe(
          select(fromMember.selectTierConfigurationBinary),
          filter((state: any) => state?.result?.length),
          first(),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state: BinaryValuesResponse) => {
          const result = state.result.map((item: BinaryDetail) => {
            return {
              ...item,
              displayValue: item.valueLabel,
            };
          });
          stepFormGroup.patchValue({
            listBinary: result,
          });
        });
    }
  }

  
  submit() {
    if (!this.currentPlans.length) {
      this.editForm.patchValue({
        plan: '',
      });
      this.editForm.get('plan')?.setValidators([Validators.required]);
      this.editForm.get('plan')?.updateValueAndValidity();
      this.editForm.controls['plan'].markAsTouched();
    }
    if (this.editForm.invalid) {
      return;
    }
    const { tierName, salaryCapping, conditions, tierCode } =
      this.editForm.value;
    const coverPlans = this.currentPlans.map((item: PlanDetail) => {
      return { planId: item.planId };
    });
    const coverConditions = conditions.map((item: any) => {
      const result = {
        propertyKey: item.propertyKey,
        operatorType: item.operatorType,
        firstValue: item.firstValue.toString(),
        secondValue: item.secondValue ? item.secondValue.toString() : null,
      };

      if (![
        TierConditionOperatorType.IsIn,
        TierConditionOperatorType.IsNotIn,
      ].includes(item.operatorType) && item.propertyType === this.PropertyType.Date ) {
        const coverDate = new DatePipe('en-US');

        result.firstValue = coverDate.transform(
          result.firstValue,
          'yyyy-MM-dd'
        );
        result.secondValue = result.secondValue
          ? coverDate.transform(result.secondValue, 'yyyy-MM-dd')
          : null;
      }
      return result;
    });
    const body = {
      tierName: tierName,
      salaryCapping: salaryCapping,
      tierCode: tierCode,
      plans: coverPlans,
      conditions: coverConditions,
    };
    const param = {
      tierName: tierName,
      tierCode: tierCode,
      tierId: this.statusDetail?.id ? this.statusDetail?.id : ''
    };
    this.tierConfigurationService.checkExits(param).pipe(takeUntil(this.unsubscribe$)).subscribe((res: CheckExitsResponsive) => {
      if (res?.isCodeExist || res?.isNameExist) {
        if(res?.isCodeExist) {
          this.editForm.get('tierCode')?.setErrors({inValidAsync:true});
        }
        if(res?.isNameExist) {
          this.editForm.get('tierName')?.setErrors({inValidAsync:true});
        }
      } else {
        this.createOrUpdateTier(body);
      }
    });
  }

  createOrUpdateTier(body: EditTierRequest) {
    if (this.statusDetail?.id) {
      this.memberStore.dispatch(
        TierConfigurationActions.editTierConfiguration({
          body,
          query: { id: this.statusDetail?.id },
        })
      );
    } else {
      this.memberStore.dispatch(
        TierConfigurationActions.setTierConfiguration({ body })
      );
    }
    this.dialogRef.close({ tierName: body.tierName });
  }

  onValidator(stepForm: any) {
    const stepFormGroup = stepForm as FormGroup;
    const firstValue = stepFormGroup.controls['firstValue'].value;
    const propertyType = stepFormGroup.controls['propertyType'].value;
    const operatorType = stepFormGroup.controls['operatorType'].value;
    if(this.PropertyType.Text === propertyType || 
      this.PropertyType.Department === propertyType ||
      this.PropertyType.Lookup === propertyType || 
      !operatorType
    ) return;

    const checkValidator = firstValue.split(',').find((value: any) => {
      let result;
      if (propertyType === this.PropertyType.Date) {
        const selectedDate = DateTime.fromFormat(value, 'M/d/yyyy');
        result =  !selectedDate.isValid;
      } else {
        // default regex Currency an Decimal
        let regex = /^-?\d+(\.\d+)?$/;
        if(propertyType === this.PropertyType.Percentage) {
          regex = /^\d*\.?\d*$/;
        } else if(propertyType === this.PropertyType.Whole_Number) { 
          regex = /^[+-]?\d+$/;
        }
        result = !regex.test(value)
        
      }
      return result;
    });
    if (checkValidator) {
      stepFormGroup.get('firstValue')?.setErrors({ inValidAsync: true });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
