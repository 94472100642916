<div class="aggregation-configuration">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onClickBreadcrumb)="onClickBreadcrumb($event)"
  ></ptg-breadcrumb>

  <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="box">
      <div class="properties-title-container">
        <p class="label-small right-separator">Aggregations</p>
        <ptg-button
          buttonName="Add Aggregation"
          classInput="add-property-button"
          (clickButton)="addAgg()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
        <ptg-button
          buttonName="Group Aggregation"
          classInput="add-property-button"
          (clickButton)="navigateAggGroup()"
        >
          <mat-icon>device_hub</mat-icon>
        </ptg-button>
      </div>

      <ptg-grid
        [data]="aggregationsData"
        [columns]="columns"
        keyColumn="id"
        [isLoading]="isLoading"
        [errorMessage]="errorMsg"
        [paginable]="false"
        [allowDragDrop]="true"
        (rowDrop)="changeItemIndex($event)"
        notFoundMessage="No Aggregation to Display"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="addAgg(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveClick(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
