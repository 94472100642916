import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { GetPropertyType } from '../constance/member-list.const';
import {
  ListSectionProperty,
  ParticipantSearchConfigurations,
  PropertyConfigs,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class ParticipantSearchConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getParticipantSearchPropertyList(): Observable<PropertyConfigs> {
    return this.httpClient.get<ListSectionProperty>(
      `${environment.apiUrl}/Metadata/SectionProperties/${GetPropertyType.ParticipantSearchConfiguration}`
    );
  }

  getParticipantSearchConfiguration(): Observable<ParticipantSearchConfigurations> {
    return this.httpClient.get<ParticipantSearchConfigurations>(
      `${environment.apiUrl}/MetadataPortal/GetSearchMemberConfigurations`
    );
  }

  manageParticipantSearchConfiguration(body: ParticipantSearchConfigurations) {
    return this.httpClient.put<ParticipantSearchConfigurations>(
      `${environment.apiUrl}/Metadata/ManageSearchMemberConfiguration`,
      body
    );
  }
}
