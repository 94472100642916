<div
  class="member-list-container"
  id="member-list-page"
  *ngIf="authService.isAdminPortal$.value; else employerPortal"
>
  <div class="header-container">
    <div class="filter-select-container">
      <mat-select [(ngModel)]="selectedFilter">
        <mat-select-trigger>
          <mat-icon>filter_alt</mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let option of filterOptions" [value]="option.value">
          <div (click)="onFilterSelect(option.value)">
            <mat-icon>{{ option.iconName }}</mat-icon>
            <span>{{ option.label }}</span>
          </div>
        </mat-option>
      </mat-select>
    </div>
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
      [settings]="settings"
      [functionButtons]="functionButtons"
      (emitFunction)="emitFunction($event)"
    ></ptg-breadcrumb>
  </div>
  <div class="member-list-content flex flex-col">
    <div class="filter-container" *ngIf="currentFilters.length">
      <div class="current-filter-container">
        <ptg-view-current-filter [currentFilters]="currentFilters">
        </ptg-view-current-filter>
      </div>
      <div class="filter-action-button-container">
        <button
          mat-raised-button
          type="button"
          class="secondary"
          (click)="openFilterDialog(true)"
        >
          Edit
        </button>
        <button
          mat-raised-button
          type="button"
          class="submit-button"
          (click)="saveFilter()"
        >
          Save
        </button>
        <button
          mat-raised-button
          type="button"
          class="cancel-button"
          (click)="clearFilter()"
        >
          Clear filter
        </button>
      </div>
    </div>
    <div class="flex-grow overflow-hidden">
      <ptg-grid
        #gridMemberList
        [ngClass]="{ 'has-error': isError }"
        *ngIf="!(memberData?.memberList?.message === 'No Columns Configured')"
        [data]="membersData"
        [columns]="columns"
        [isLoading]="isLoading || isLoadingMetadataSection"
        [paginable]="membersData.length > 0"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="changePagging($event)"
        (sortChange)="sortChange($event)"
        (rowClick)="clickView($event)"
        [errorMessage]="errorMsg"
        [fitToParent]="true"
        [hideScrollbar]="false"
        [pageSizeOptions]="pageSizeOptions"
        notFoundMessage="No Participants to Display"
      >
        <ng-template cellContent columnName="_isLocked" let-row>
          <mat-icon *ngIf="row._isLocked" style="color: #cbc89f">lock</mat-icon>
        </ng-template>
      </ptg-grid>
      <div
        *ngIf="
          !isError && !isLoading && !memberData?.memberList?.columns?.length
        "
      >
        <div
          class="data-not-found"
          [ngClass]="{
            'no-columns-display': !memberData?.memberList?.columns?.length
          }"
        >
          <mat-icon *ngIf="!memberData?.memberList?.columns?.length"
            >grid_off</mat-icon
          >
          <div class="message">
            {{
              !memberData?.memberList?.columns?.length
                ? "List not yet configured"
                : "No Participants to Display"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #employerPortal> </ng-template>
