<div class="list-display" [ngClass]="{ noDetail: !dataTable?.length }">
  <div class="list-header">
    {{ menuItemName }} List

    <div class="space-line"></div>

    <div
      class="edit-btn"
      (click)="addNew($event)"
      [ngClass]="{ noConfiguredBtn: columns === undefined }"
    >
      <mat-icon>add</mat-icon>
      <span class="title-edit">New {{ menuItemName }}</span>
    </div>
    <div
      *ngIf="menuItemName == 'DROP Accruals'"
      class="edit-btn"
      (click)="dropStatement($event)"
      [ngClass]="{ noConfiguredBtn: columns === undefined }"
    >
      <mat-icon>add</mat-icon>
      <span class="title-edit">Generate DROP Statement</span>
    </div>
  </div>

  <ptg-grid
    *ngIf="
      columns !== undefined &&
        ((!sectionHasAttachment && columns.length > 0) ||
          (sectionHasAttachment && columns.length > 1));
      else notYetConfig
    "
    [data]="dataTable"
    [columns]="columns"
    [isLoading]="isLoading"
    [totalRecords]="lengthPg"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    (pageChange)="changePagging($event)"
    (sortChange)="sortChange($event)"
    (rowClick)="selectRow($event)"
    [(currentRowIndex)]="currentRowIndex"
    [errorMessage]="errorMsg"
    [fitToParent]="true"
    [hideScrollbar]="false"
    [notFoundMessage]="'No ' + menuItemName + ' to Display'"
  ></ptg-grid>

  <ng-template #notYetConfig>
    <div
      *ngIf="!isLoading; else loadingTemplate"
      class="list-data-not-found no-columns-display"
    >
      <div class="message">List not yet configured</div>
    </div>

    <ng-template #loadingTemplate>
      <div style="margin: 20px auto">
        <ptg-spinner [isLoading]="true"></ptg-spinner>
      </div>
    </ng-template>
  </ng-template>
</div>
