import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'customBold',
})
export class CustomBoldPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  transform(value: string | null, searchValue: string): any {
    if (!value) {
      return '';
    }
    let searchValues = searchValue.split(' ').filter(value => value?.trim()).join('|');
    return this.sanitize(this.replace(value, searchValues) || '');
  }

  replace(value: string, pattern: string) {
    return value.toString().replace(new RegExp(`(${pattern})`, 'gi'), '<b>$1</b>');
  }

  sanitize(value: string) {
    return this.sanitizer.sanitize(SecurityContext.HTML, value || '');
  }
}
