import { createAction, props } from '@ngrx/store';

import {
  OffCycleData,
  GetOffCyclePaymentsResponse,
  OffCyclePaymentListQuery,
  OffCycleActionState,
} from '../types/models';

export const GetOffCyclePaymentRequest =
  '[GetOffCyclePayment/API] Send Request';
export const GetOffCyclePaymentSuccess = '[GetOffCyclePayment/API] Success';
export const GetOffCyclePaymentFailure = '[GetOffCyclePayment/API] Failure';

export const GetOffCycleRequest = '[GetOffCycle/API] Send Request';
export const GetOffCycleSuccess = '[GetOffCycle/API] Success';
export const GetOffCycleFailure = '[GetOffCycle/API] Failure';

export const CompletePaymentListRequest =
  '[CompletePaymentList/API] Send Request';
export const CompletePaymentListSuccess = '[CompletePaymentList/API] Success';
export const CompletePaymentListFailure = '[CompletePaymentList/API] Failure';

export const AchSentRequest = '[AchSentCompletePayment/API] Send Request';
export const AchSentSuccess = '[AchSentCompletePayment/API] Success';
export const AchSentFailure = '[AchSentCompletePayment/API] Failure';

export const ChecksPrintedRequest =
  '[ChecksPrintedCompletePayment/API] Send Request';
export const ChecksPrintedSuccess =
  '[ChecksPrintedCompletePayment/API] Success';
export const ChecksPrintedFailure =
  '[ChecksPrintedCompletePayment/API] Failure';

export const EditCheckNumberRequest =
  '[EditCheckNumberCompletePayment/API] Send Request';
export const EditCheckNumberSuccess =
  '[EditCheckNumberCompletePayment/API] Success';
export const EditCheckNumberFailure =
  '[EditCheckNumberCompletePayment/API] Failure';

export const FinalizeCompletePaymentRequest =
  '[FinalizeCompletePayment/API] Send Request';
export const FinalizeCompletePaymentSuccess =
  '[FinalizeCompletePayment/API] Success';
export const FinalizeCompletePaymentFailure =
  '[FinalizeCompletePayment/API] Failure';
export const ClearFinalizeCompletePaymentState =
  '[ClearFinalizeCompletePaymentState] Clear';

export const GenerateEDIOffCycleRequest =
  '[GenerateEDIOffCycle/API] Send Request';
export const GenerateEDIOffCycleSuccess = '[GenerateEDIOffCycle/API] Success';
export const GenerateEDIOffCycleFailure = '[GenerateEDIOffCycle/API] Failure';
export const ClearGenerateEDIOffCycleState =
  '[ClearGenerateEDIOffCycleState] Clear';

export const ReissueOffCycleRequest = '[ReissueOffCycle/API] Send Request';
export const ReissueOffCycleSuccess = '[ReissueOffCycle/API] Success';
export const ReissueOffCycleFailure = '[ReissueOffCycle/API] Failure';
export const ClearReissueOffCycleState = '[ClearReissueOffCycleState] Clear';

export const SetCompletePaymentBoardBankRequest =
  '[SetCompletePaymentBoardBank/API] Send Request';
export const SetCompletePaymentBoardBankSuccess =
  '[SetCompletePaymentBoardBank/API] Success';
export const SetCompletePaymentBoardBankFailure =
  '[SetCompletePaymentBoardBank/API] Failure';
export const ClearSetBoardBankOffCycleState =
  '[ClearSetBoardBankOffCycleState] Clear';

export const ClearEditCheckNumberState =
  '[ClearEditCheckNumberCompletePaymentState] Clear';
export const CompleteOffCyclePaymentsRequest =
  '[CompleteOffCyclePayments/API] Send Request';
export const CompleteOffCyclePaymentsSuccess =
  '[CompleteOffCyclePayments/API] Success';
export const CompleteOffCyclePaymentsFailure =
  '[CompleteOffCyclePayments/API] Failure';
export const ClearCompleteOffCyclePaymentId =
  '[ClearCompleteOffCyclePaymentId] Clear';

export const CancelOffCyclePaymentsRequest =
  '[CancelOffCyclePayments/API] Send Request';
export const CancelOffCyclePaymentsSuccess =
  '[CancelOffCyclePayments/API] Success';
export const CancelOffCyclePaymentsFailure =
  '[CancelOffCyclePayments/API] Failure';
export const ClearCancelOffCyclePaymentsState =
  '[CancelOffCyclePayments/API] Clear';
export const QueueCancelOffCyclePaymentsSuccess =
  '[QueueCancelOffCyclePayments/API] Success';

export const UpdateCompleteOffCycleDepositDateRequest =
  '[UpdateCompleteOffCycleDepositDate/API] Send Request';
export const UpdateCompleteOffCycleDepositDateSuccess =
  '[UpdateCompleteOffCycleDepositDate/API] Success';
export const UpdateCompleteOffCycleDepositDateFailure =
  '[UpdateCompleteOffCycleDepositDate/API] Failure';
export const ClearUpdateCompleteOffCycleDepositDateState =
  '[ClearUpdateCompleteOffCycleDepositDateState] Clear';

export const GetOffCycleActionStateRequest =
  '[GetOffCycleActionState/API] Send Request';
export const GetOffCycleActionStateSuccess =
  '[GetOffCycleActionState/API] Success';
export const GetOffCycleActionStateFailure =
  '[GetOffCycleActionState/API] Failure';

export const ClearOffCycleActionState = '[ClearOffCycleActionState] Clear';

export const getOffCyclePayments = createAction(
  GetOffCyclePaymentRequest,
  props<{ query: OffCyclePaymentListQuery; isCompleted: boolean }>()
);
export const getOffCyclePaymentsSuccess = createAction(
  GetOffCyclePaymentSuccess,
  props<{ response: GetOffCyclePaymentsResponse }>()
);
export const getOffCyclePaymentsFailure = createAction(
  GetOffCyclePaymentFailure,
  props<{ error?: any }>()
);

export const getOffCycle = createAction(
  GetOffCycleRequest,
  props<{ offCycleId: string }>()
);
export const getOffCycleSuccess = createAction(
  GetOffCycleSuccess,
  props<{ payees: OffCycleData }>()
);
export const getOffCycleFailure = createAction(
  GetOffCycleFailure,
  props<{ errorMsg: string }>()
);

export const getCompletePaymentList = createAction(
  CompletePaymentListRequest,
  props<{ query: any }>()
);
export const getCompletePaymentListSuccess = createAction(
  CompletePaymentListSuccess,
  props<{ response: GetOffCyclePaymentsResponse }>()
);
export const getCompletePaymentListFailure = createAction(
  CompletePaymentListFailure,
  props<{ error?: any }>()
);

export const achSent = createAction(AchSentRequest, props<{ body: any }>());
export const achSentSuccess = createAction(
  AchSentSuccess,
  props<{ body: any }>()
);
export const achSentFailure = createAction(
  AchSentFailure,
  props<{ errorMsg: string }>()
);

export const checksPrinted = createAction(
  ChecksPrintedRequest,
  props<{ body: any }>()
);
export const checksPrintedSuccess = createAction(
  ChecksPrintedSuccess,
  props<{ body: any }>()
);
export const checksPrintedFailure = createAction(
  ChecksPrintedFailure,
  props<{ errorMsg: string }>()
);

export const editCheckNumber = createAction(
  EditCheckNumberRequest,
  props<{ body: any }>()
);
export const editCheckNumberSuccess = createAction(EditCheckNumberSuccess);
export const editCheckNumberFailure = createAction(
  EditCheckNumberFailure,
  props<{ errorMsg: string }>()
);
export const clearEditCheckNumberState = createAction(
  ClearEditCheckNumberState
);
export const generateEDIOffCycle = createAction(
  GenerateEDIOffCycleRequest,
  props<{ offCycleId: any; selectedBankId: any; boardBankId: any }>()
);
export const generateEDIOffCycleSuccess = createAction(
  GenerateEDIOffCycleSuccess,
  props<{ fileContent: any }>()
);
export const generateEDIOffCycleFailure = createAction(
  GenerateEDIOffCycleFailure,
  props<{ errorMsg: string }>()
);
export const clearGenerateEDIOffCycleState = createAction(
  ClearGenerateEDIOffCycleState
);
export const reissueOffCycle = createAction(
  ReissueOffCycleRequest,
  props<{ offCycleId: any }>()
);
export const reissueOffCycleSuccess = createAction(ReissueOffCycleSuccess);
export const reissueOffCycleFailure = createAction(
  ReissueOffCycleFailure,
  props<{ errorMsg: string }>()
);
export const clearReissueOffCycleState = createAction(
  ClearReissueOffCycleState
);
export const finalizeCompletePayment = createAction(
  FinalizeCompletePaymentRequest
);
export const finalizeCompletePaymentSuccess = createAction(
  FinalizeCompletePaymentSuccess
);
export const finalizeCompletePaymentFailure = createAction(
  FinalizeCompletePaymentFailure,
  props<{ errorMsg: string }>()
);
export const setCompletePaymentBoardBank = createAction(
  SetCompletePaymentBoardBankRequest,
  props<{ boardBankId: any; offCycleId: any }>()
);
export const setCompletePaymentBoardBankSuccess = createAction(
  SetCompletePaymentBoardBankSuccess
);
export const setCompletePaymentBoardBankFailure = createAction(
  SetCompletePaymentBoardBankFailure,
  props<{ errorMsg: string }>()
);
export const completeOffCyclePayments = createAction(
  CompleteOffCyclePaymentsRequest,
  props<{ offCyclePaymentIds: string[] }>()
);
export const completeOffCyclePaymentsSuccess = createAction(
  CompleteOffCyclePaymentsSuccess,
  props<{ completeOffCyclePaymentId: string }>()
);
export const completeOffCyclePaymentsFailure = createAction(
  CompleteOffCyclePaymentsFailure,
  props<{ errorMsg: string }>()
);
export const clearCompleteOffCyclePaymentId = createAction(
  ClearCompleteOffCyclePaymentId
);

export const updateCompleteOffCycleDepositDate = createAction(
  UpdateCompleteOffCycleDepositDateRequest,
  props<{ depositDate: string; offCycleId: string }>()
);
export const updateCompleteOffCycleDepositDateSuccess = createAction(
  UpdateCompleteOffCycleDepositDateSuccess
);
export const updateCompleteOffCycleDepositDateFailure = createAction(
  UpdateCompleteOffCycleDepositDateFailure,
  props<{ errorMsg: string }>()
);
export const clearUpdateCompleteOffCycleDepositDateState = createAction(
  ClearUpdateCompleteOffCycleDepositDateState
);
export const cancelOffCyclePayments = createAction(
  CancelOffCyclePaymentsRequest
);
export const cancelOffCyclePaymentsSuccess = createAction(
  CancelOffCyclePaymentsSuccess
);
export const queueCancelOffCyclePaymentsSuccess = createAction(
  QueueCancelOffCyclePaymentsSuccess
);
export const cancelOffCyclePaymentsFailure = createAction(
  CancelOffCyclePaymentsFailure,
  props<{ errorMsg: string }>()
);
export const clearCancelOffCyclePaymentsState = createAction(
  ClearCancelOffCyclePaymentsState
);
export const clearFinalizeCompletePaymentState = createAction(
  ClearFinalizeCompletePaymentState
);
export const clearSetBoardBankOffCycleState = createAction(
  ClearSetBoardBankOffCycleState
);

export const getOffCycleActionStateRequest = createAction(
  GetOffCycleActionStateRequest
);
export const getOffCycleActionStateSuccess = createAction(
  GetOffCycleActionStateSuccess,
  props<{ offCycleActionState: OffCycleActionState }>()
);
export const getOffCycleActionStateFailure = createAction(
  GetOffCycleActionStateFailure
);

export const clearOffCycleActionState = createAction(ClearOffCycleActionState);
