<div class="ptg-sidebar"
     [ngClass]="{'sidebar-opened': sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Opened, 'disabled' : isDisabled}"
     *ngIf="sidebarOpenedStatus !== SIDE_BAR_OPENED_STATUS_ENUM.Hidden"
>
  <div class="control-bar">
    <ng-container *ngFor="let menuGroup of currentMenuGroups; let index = index">
      <div [ngClass]="{ selected: menuGroup?.isSelected }" class="item-bar" *ngIf="!menuGroup?.isHidden">
        <button mat-icon-button (click)="selectMenuGroup(index)">
          <mat-icon *ngIf="!menuGroup.isSVG; else svgIconTemplate">{{ menuGroup.icon }}</mat-icon>
          <ng-template #svgIconTemplate>
            <mat-icon [svgIcon]="menuGroup.name" aria-hidden="false"></mat-icon>
          </ng-template>
        </button>
      </div>
    </ng-container>
  </div>
  <mat-drawer-container class="toggle-container">
    <mat-drawer #drawer class="toggle-content" mode="side"
                [opened]="sidebarOpenedStatus === SIDE_BAR_OPENED_STATUS_ENUM.Opened" disableClose>

      <div class="menu-list" *ngIf="currentMenuGroups?.[currentMenuGroupIndex]?.menu?.length">
        <ng-container *ngFor="let menu of currentMenuGroups[currentMenuGroupIndex].menu">
          <h3 class="flex items-center sub-title" [ngClass]="{'no-title': !menu?.title}" [style]="menu?.style">
            <mat-icon *ngIf="menu?.icon" class="menu-icon">{{ menu.icon }}</mat-icon>
            {{ menu.title }}
          </h3>
          <ng-container *ngFor="let menuItem of menu?.menuItems">
            <a (click)="onClickMenuItem(menuItem)"
               [id]="menuItem?.name"
               [ngClass]="{ 'selected-screen': router.url === menuItem.routerLink 
                            || (menuItem.routerLinkExpand && router.url.includes(menuItem.routerLinkExpand)) 
                            || (menuItem.type !== undefined && (router.url.includes(menuItem.routerLink))
                            || (router.url.includes(menuItem.routerLink) && menuItem.routerLink.includes(NAVIGATION_CONST.employerParametersNavigation))
                            )}"
               [routerLink]="menuItem?.routerLink"
               [queryParams]="menuItem?.queryParams"
               class="sub-item"
               routerLinkActive="active">
              {{ menuItem?.name }}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
