import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptg-datetime-column',
  templateUrl: './datetime-column.component.html',
  styleUrls: ['./datetime-column.component.scss'],
})
export class DatetimeColumnComponent {
  @Input() value!: string;
  @Input() format: string = 'MM/dd/yyyy';
  @Input() nullValue?: string;
}
