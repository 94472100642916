import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { AbstractControlStatus } from '@ptg-shared/models/common.model';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import * as fromReducer from '@ptg-reducers/index';
import * as FundListActions from '@ptg-fund-list/actions/fund-list.actions';

import { PropertyType, SectionLayout } from '../../constance/metadata.const';
import { AddSectionBody } from '../../types/models';
import * as fromMember from '../../reducers';
import * as MetadataActions from '../../actions/metadata.actions';
import { MetadataService } from '../../services/metadata.service';

@Component({
  selector: 'ptg-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss'],
})
export class AddSectionComponent implements OnInit, OnDestroy {
  editForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.metadataService.checkNewSectionExist,
        'sectionName'
      ),
    }),
    isList: this.fb.control(false),
  });
  properties: string[] = Object.keys(PropertyType)
    .map((key: any) => PropertyType[key])
    .filter((value) => typeof value === 'string')
    .sort();
  formSubmit$ = new Subject<boolean>();
  isContinue = false;
  unsubscribe$ = new Subject<void>();
  activeFund: any;

  constructor(
    public fb: FormBuilder,
    public memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddSectionComponent>,
    public router: Router,
    public metadataService: MetadataService,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private store: Store<fromReducer.State>
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
    this.emitFormSubmit();
    this.memberStore
      .pipe(
        select(fromMember.selectMetadataState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state.newSectionKey && state.isContinue) {
          this.dialogRef.close();
          this.memberStore.dispatch(MetadataActions.clearMetadata());
          void this.router.navigateByUrl(
            '/member/member-metadata/' + state.newSectionKey
          );
        }
      });
  }

  emitFormSubmit() {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const body: AddSectionBody = Object.assign({
      name: this.editForm.value.name,
      type: (this.editForm.value.isList
        ? SectionLayout.List
        : SectionLayout.Table
      ).toString(),
    });
    this.memberStore.dispatch(
      MetadataActions.addSection({ body, isContinue: this.isContinue })
    );
    if (!this.isContinue) {
      this.editForm.reset({ name: '', isList: false });
    }
    this.store.dispatch(
      FundListActions.selectFund({ currentFund: { ...this.activeFund } })
    );
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
