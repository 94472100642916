import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

import * as fromReducer from '@ptg-reducers';
import { environment } from 'src/environments/environment';

import { GetPropertyType } from '../constance/member-list.const';
import {
  ExportFileRequest,
  FilterInformationList,
  ListSectionProperty,
  MemberFilterConfig,
  MemberFilterModal,
  MemberFilterName,
  MemberList,
  MemberListQuery,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MemberListService {
  activeFund: any;
  currentFilter: MemberFilterModal | undefined;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>,
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  memberList(query: MemberListQuery, idClient: string): Observable<MemberList> {
    let body: any = {
      pageSize: query.totalPerPage,
      pageIndex: query.pageIndex,
      listFilterInfo: query?.listFilterInfo,
    };
    if (query.sortField) {
      body = {
        ...body,
        sortNames: [query?.sortField],
        sortType: query.sortType || 0,
      };
    }

    return this.httpClient.post<MemberList>(
      `${environment.apiUrl}/Members`,
      body,
    );
  }

  removeMember(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Members/${id}`);
  }

  lockMember(id: string): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Members/${id}/Lock`, {});
  }

  unlockMember(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Members/${id}/Lock`);
  }

  checkExits = (bodyCheck: any) => {
    const body = {
      ...bodyCheck,
      clientId: this.activeFund?.id,
    };
    return this.httpClient.post(`${environment.apiUrl}/Members/Exists`, body);
  };

  createNewMember(dataMember: any[]) {
    let formData = new FormData();
    dataMember.forEach((el: any, i) => {
      formData.append(`Sections[${i}].sectionKey`, el.sectionKey);
      el?.propertyValues?.forEach((item: any, y: number) => {
        formData.append(`Sections[${i}].propertyValues[${y}].key`, item?.key);
        formData.append(`Sections[${i}].propertyValues[${y}].type`, item?.type);
        if (
          item.type === 'MemberKey' ||
          item.type === 'StatusId' ||
          item.type === 'EventId'
        ) {
          formData.append(item.type, item.value);
        } else {
          formData.append(
            `Sections[${i}].propertyValues[${y}].value`,
            item?.value,
          );
        }
      });
    });
    return this.httpClient.post(
      `${environment.apiUrl}/Members/AddSectionData`,
      formData,
    );
  }

  getFilterByPropertyList(): Observable<ListSectionProperty> {
    return this.httpClient.get<ListSectionProperty>(
      `${environment.apiUrl}/Metadata/SectionProperties/${GetPropertyType.MemberListFilter}`,
    );
  }

  checkMemberFilterExist = (body: MemberFilterName) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberFilter/Exists`,
      body,
    );
  };

  createMemberFilter(body: MemberFilterConfig): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/CreateMemberFilter`,
      body,
    );
  }

  getMemberFilter(): Observable<FilterInformationList> {
    return this.httpClient.get<FilterInformationList>(
      `${environment.apiUrl}/Members/GetMemberFilter`,
    );
  }

  updateFilterName(memberFilterName: MemberFilterName) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/SetMemberFilter`,
      memberFilterName,
    );
  }

  removeMemberFilter(id: string): Observable<void> {
    if (id === this.currentFilter?.id) {
      this.currentFilter = undefined;
    }
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Members/RemoveMemberFilter/${id}`,
    );
  }

  exportMemberList(request: ExportFileRequest) {
    let body: any = {
      pageSize: request.totalPerPage,
      pageIndex: request.pageIndex,
      fileName: request.fileName,
      listFilter: request.listFilter,
      timeZone: DateTime.now().toFormat('ZZ'),
    };
    if (request.sortField) {
      body = {
        ...body,
        sortNames: [
          request?.sortField?.[0]?.toUpperCase() +
            (request?.sortField?.substring(1) || ''),
        ],
        sortType: request?.sortType || 0,
      };
    }
    return this.httpClient.post(
      `${environment.apiUrl}/Members/ExportMembers/GenerateExcelFile`,
      body,
    );
  }

  downloadExportFile(fileName: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/ExportMembers/Download`,
      { fileName },
      { responseType: 'blob' },
    );
  }

  getEmailForParticipantList(memberIdObj: any): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/Emails`,
      memberIdObj,
    );
  }
}
