import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MemberPropertyActions from '../actions/member-property.actions';
import { MemberPropetyDataByMaskedConfigResponse } from '../types/models';
import { MemberDetailService } from '../services/member-detail.service';

@Injectable()
export class MemberPropertyEffects {
  getPropetyDataByMaskedConfigRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberPropertyActions.getPropetyDataByMaskedConfigRequest),
      switchMap(({ query }) => {
        return this.memberDetailService
          .getPropetyDataByMaskedConfig(query)
          .pipe(
            map((res: MemberPropetyDataByMaskedConfigResponse) => {
              return MemberPropertyActions.getPropetyDataByMaskedConfigSuccess({
                value: res.value,
                query,
              });
            }),
            catchError((err) => {
              console.log('---------Error-----------------');
              console.log(err);
              return of(
                MemberPropertyActions.getPropetyDataByMaskedConfigFailure()
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private memberDetailService: MemberDetailService
  ) {}
}
