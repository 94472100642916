import { createAction, props } from "@ngrx/store";
import { NewBank } from "../types/models/bank.model";

export const BankingListRequest = '[BankingList/API] Send Request';
export const BankingListSuccess = '[BankingList/API] Success';
export const BankingListFailure = '[BankingList/API] Failure';

export const CreateBankRequest = '[CreateBank/API] Send Request';
export const CreateBankFailure = '[CreateBank/API] Failure';
export const CreateBankSuccess = '[CreateBank/API] Success';

export const UpdateBankRequest = '[UpdateBank/API] Send Request';
export const UpdateBankFailure = '[UpdateBank/API] Failure';
export const UpdateBankSuccess = '[UpdateBank/API] Success';

export const BankClear = '[BankClear] Clear';

export const getBankingList = createAction(
  BankingListRequest,
  props<{ query: any }>()
);
export const getBankingListSuccess = createAction(
  BankingListSuccess,
  props<{ bankingList: any }>()
);
export const getBankingListFailure = createAction(
  BankingListFailure,
  props<{ error?: any }>()
);

export const createBank = createAction(
  CreateBankRequest,
  props<{ body: NewBank }>()
);
export const createtBankSuccess = createAction(
  CreateBankSuccess,
);
export const createBankFailure = createAction(
  CreateBankFailure,
  props<{ errorMsg: string }>()
);

export const updateBank = createAction(
  UpdateBankRequest,
  props<{ id: string, body: NewBank}>()
);
export const updateBankSuccess = createAction(
  UpdateBankSuccess,
);
export const updateBankFailure = createAction(
  UpdateBankFailure,
  props<{ errorMsg: string }>()
);

export const bankClear = createAction(
  BankClear
);