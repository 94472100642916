import { SelectionModel } from '@angular/cdk/collections';
import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { OffCyclePaymentsService } from '@ptg-processing/services/off-cycle-payments.service';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { isNumeric } from '@ptg-shared/utils/common.util';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/fund-management/services/navigation-page.service';
import { NextPayrollService } from 'src/app/processing/services/next-payroll.service';
import { NextPaymentService } from 'src/app/processing/services/vendor-next-payment.service';
import * as fromReducer from 'src/app/reducers';
import { InterceptorActions, LayoutActions } from 'src/app/shared/layout/actions';
import * as fromLayoutReducer from 'src/app/shared/layout/reducers';
import { insertDriveFile } from '../../../shared/constance/listIcons.const'
import {
  arrowDropDown,
  deductionDisableIcon,
  dragHandle,
  fundIcon,
  inReviewIcon,
  missingAddressIcon,
  noteIcon,
  removeAccountIcon,
  scheduledIcon,
  shieldCheckIcon,
  switchAccountIcon
} from '../../constance/listIcons.const';
import { ERROR_NAME, PaymentStep } from '../../constance/value.const';
import { checkApiValidatorGetMessage } from '../../validators/checkApi.validator';
import { STYLE_COLUMN } from './gridview.const';

export interface ConfigColums {
  styleColums: string[];
  displayedColumns: string[];
  headerColumns: string[];
  configValueColumns?: any[];
}

@Component({
  selector: 'ptg-gridview',
  templateUrl: './gridview.component.html',
  styleUrls: ['./gridview.component.scss']
})
export class GridviewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  readonly isNumericFunc = isNumeric;

  @ViewChild('table') table!: MatTable<any>;

  @Input() configColums!: ConfigColums;
  @Input() dataTable!: {} | any;
  @Input() sortHeader?: boolean;
  @Input() groupButtonFunction?: {} | any;
  @Input() statusWithIcon?: boolean;
  @Input() isLoading?: boolean;
  @Input() tableStriped?: boolean;
  @Input() demoField?: string;
  @Input() sortStatus?: boolean;
  @Input() canDropDrag?: boolean;
  @Input() isSticky: boolean = true;
  @Input() isHighlightRow?: boolean;
  @Input() needTooltip?: boolean;
  @Input() isHistory?: boolean;
  @Input() pageName?: string;
  @Input() hideTime?: boolean;
  @Input() haveRemove?: boolean;
  @Input() isFileList?: boolean;
  @Input() categorisArchived?: any[];
  @Input() flagCheckChange?: boolean;
  @Input() lockFirstRow?: boolean;
  @Input() isTableMemberSection?: boolean;
  @Input() dynamicGrid?: boolean;
  @Input() onlySortDateTime?: boolean;
  @Input() currentUserEmail?: string;
  @Input() hideScroll: boolean = true;
  @Input() hasCurrentDetail?: boolean;
  @Input() currentRowIndex: number = 0;
  @Input() isNotSetDefaultPadding: boolean = false;
  @Input() noSortIconMarkColumn: boolean = false;
  @Input() noSortNoteColumn: boolean = false;
  @Input() isPayroll: boolean = false;
  @Input() isOffCycle: boolean = false;
  @Input() canDropDragStatic: boolean = false;
  @Input() isListViewPage: boolean = false;
  @Input() customNotFoundMessage: string = '';

  @Output() buttonViewClick = new EventEmitter();
  @Output() buttonMemberClick = new EventEmitter();
  @Output() buttonFundClick = new EventEmitter();
  @Output() buttonAppContentClick = new EventEmitter();
  @Output() buttonRemoveClick = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() clickLink = new EventEmitter();
  @Output() onRowAction = new EventEmitter();
  @Output() onDropDragAction = new EventEmitter();
  @Output() buttonDownloadClick = new EventEmitter();
  @Output() buttonResourceCenterClick = new EventEmitter();
  @Output() onCategoryAction = new EventEmitter();
  @Output() onRowActions = new EventEmitter();
  @Output() onChangeSelection = new EventEmitter();
  @Output() submitCheckNumber = new EventEmitter();
  @Output() buttonErrorClick = new EventEmitter();
  @Output() buttonRegisterErrorClick = new EventEmitter();
  @Output() currentRowIndexChange = new EventEmitter();

  @ViewChild(MatSort) sort!: MatSort;
  STYLE_COLUMN = STYLE_COLUMN;
  styleDisplayedColumns: string[] = [];
  displayedColumns: string[] = [];
  headerColumns: string[] = [];
  configValueColumns?: any[] = [];
  dataSource: never | any = new MatTableDataSource([]);
  selection: never | any = new SelectionModel(true, []);
  faLock = faLock;
  groupButtonFn: {} | any;
  isTruncated = true;

  formItems: any = this.formBuilder.array([]);
  valueBlank = '';
  arrIdEnable: any[] = [];
  isDragDisabled = true;
  controlEditCheckNumber!: FormControl;
  updateSubsequent = new FormControl(false);
  PaymentStep = PaymentStep;

  Math = Math;
  dataNotFoundMessage: string = '';
  isError: boolean = false;
  representativeTooltip = 'Representative: ';
  showSelectAllCheckBox: boolean = false;
  currentCheckNumberValue: string = '';
  currentCheckNumberRowIndex: number = -1;

  constructor(
    iconRegistry: MatIconRegistry,sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private nextPayrollService: NextPayrollService,
    private nextPaymentService: NextPaymentService,
    private offCyclePaymentService: OffCyclePaymentsService,
    private store: Store<fromReducer.State>,
    private router: Router,
  ) {
    iconRegistry.addSvgIconLiteral('thumbs-up', sanitizer.bypassSecurityTrustHtml(shieldCheckIcon));
    iconRegistry.addSvgIconLiteral('remove-account', sanitizer.bypassSecurityTrustHtml(removeAccountIcon));
    iconRegistry.addSvgIconLiteral('group-account', sanitizer.bypassSecurityTrustHtml(switchAccountIcon));
    iconRegistry.addSvgIconLiteral('fund', sanitizer.bypassSecurityTrustHtml(fundIcon));
    iconRegistry.addSvgIconLiteral('insert-drive-file', sanitizer.bypassSecurityTrustHtml(insertDriveFile));
    iconRegistry.addSvgIconLiteral('arrowDropDown', sanitizer.bypassSecurityTrustHtml(arrowDropDown));
    iconRegistry.addSvgIconLiteral('drag-handle', sanitizer.bypassSecurityTrustHtml(dragHandle));
    iconRegistry.addSvgIconLiteral('scheduled', sanitizer.bypassSecurityTrustHtml(scheduledIcon));
    iconRegistry.addSvgIconLiteral('in-review', sanitizer.bypassSecurityTrustHtml(inReviewIcon));
    iconRegistry.addSvgIconLiteral('deduction-disable', sanitizer.bypassSecurityTrustHtml(deductionDisableIcon));
    iconRegistry.addSvgIconLiteral('note-icon', sanitizer.bypassSecurityTrustHtml(noteIcon));
    iconRegistry.addSvgIconLiteral('missing-address', sanitizer.bypassSecurityTrustHtml(missingAddressIcon));
  }

  ngOnInit() {
    this.getNotFoundMessage();
    this.styleDisplayedColumns = this.configColums.styleColums;
    this.displayedColumns = this.configColums.displayedColumns;
    this.headerColumns = this.configColums.headerColumns;
    this.configValueColumns = this.configColums?.configValueColumns;
    this.dataSource = new MatTableDataSource(this.dataTable);
    this.dataSource.sort = this.sort;
    this.groupButtonFn = this.groupButtonFunction;
    this.controlEditCheckNumber = new FormControl('', {
      validators: Validators.required});

    if (this.pageName === 'Category' && this.canDropDrag) {
      if (this.formItems.controls.length === 0) {
        for (let i = 0; i < this.dataTable.length; i++) {
          this.formItems.push(this.createControlCategory(this.dataTable[i]));
        }
      }

      this.formItems.statusChanges.subscribe((status: any) => {
        let formValues = this.getValueForm(this.formItems.controls);
        let arrValues = this.categorisArchived ? formValues.concat(this.categorisArchived) : formValues;
        this.formItems.controls.forEach((ele: any) => {
          if (arrValues.filter((item: any) => item.categoryName.toLowerCase().trim() === ele.controls.categoryName.value.toLowerCase().trim()).length === 1 &&
            ele.controls.categoryName.value !== '' &&
            ele.controls.categoryName.value.length < 101 &&
            ele.controls.categoryName.errors?.inValidAsync) {
            ele.controls.categoryName.setErrors(null);
          }
        });
      });
    }

    if (this.pageName === 'Navigation') {
      if (this.formItems.controls.length === 0) {
        for (let i = 0; i < this.dataTable.length; i++) {
          this.formItems.push(this.createControlNavigation(this.dataTable[i]));
        }
      }
    }

    if (this.pageName === 'User') {
      if (this.formItems.controls.length === 0) {
        for (let i = 0; i < this.dataTable.length; i++) {
          this.formItems.push(this.createControlNotification(this.dataTable[i]));
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configColums) {
      this.headerColumns = changes.configColums.currentValue.headerColumns;
      this.displayedColumns = changes.configColums.currentValue.displayedColumns;
      this.styleDisplayedColumns = changes.configColums.currentValue.styleColums;
      this.configValueColumns = changes.configColums.currentValue?.configValueColumns;
    }

    if (changes.customNotFoundMessage) {
      this.getNotFoundMessage();
    }

    this.initFormItems();
    this.dataSource = new MatTableDataSource(this.dataTable);
    this.showSelectAllCheckBox = !!this.dataTable?.find((item: any) => !item.dontShowCheckBox);
  }

  ngOnDestroy(): void {
    this.store.dispatch(InterceptorActions.setError({ error: undefined }));
    this.store.dispatch(InterceptorActions.setShowErrorDialogCondition({ showErrorDialog: true }));
  }

  initFormItems() {
    if (this.pageName === 'Category' && this.canDropDrag) {
      while (this.formItems.length !== 0) {
        this.formItems.removeAt(0);
      }
      for (let i = 0; i < this.dataTable.length; i++) {
        this.formItems.push(this.createControlCategory(this.dataTable[i]));
        if (this.dataTable[i].enable) {
          this.formItems.controls[i]?.controls?.categoryName.markAsTouched();
        }
      }
      return;
    }

    if (this.pageName === 'Navigation') {
      let formItemsOld = this.formItems;
      this.formItems = this.formBuilder.array([]);
      if (formItemsOld.controls.length > 0) {
        for (let i = 0; i < this.dataTable.length; i++) {
          let index = formItemsOld.controls.findIndex((item: any) => item.controls.id.value === this.dataTable[i].id);
          this.formItems.push(formItemsOld.controls[index]);
        }
      } else {
        for (let i = 0; i < this.dataTable.length; i++) {
          this.formItems.push(this.createControlNavigation(this.dataTable[i]));
        }
      }
      return;
    }

    if (this.pageName === 'User') {
      this.formItems = this.formBuilder.array([]);
      for (let i = 0; i < this.dataTable.length; i++) {
        this.formItems.push(this.createControlNotification(this.dataTable[i]));
      }
      return;
    }

    if (this.pageName === 'Property Display Config') {
      const oldFormArrayControls = [...this.formItems.controls];
      this.formItems = this.formBuilder.array([]);
      this.dataTable?.forEach((item: any) => {
        const oldFormGroup = oldFormArrayControls.find((formItem: FormGroup) => {
          const element = formItem.value;
          if (item.aggregationId) {
            return item.aggregationId === element.aggregationId;
          }
          return element.sectionKey === item.sectionKey && ((!element.propertyKey && !item.propertyKey) || (element.propertyKey === item.propertyKey)) && ((!element.options && !item.options) || (element.options === item.options))}
        );
        const newFormGroup = this.formBuilder.group({
          columnName: this.formBuilder.control(
            oldFormGroup?.value?.columnName || item.columnName,
            [Validators.required, Validators.maxLength(item.columnNameMaxLength), this.checkDuplicated()]
          ),
          maxLength: item.columnNameMaxLength,
          sectionKey: item.sectionKey,
          propertyKey: item.propertyKey,
          aggregationId: item.aggregationId,
          options: item.options
        });
        if (oldFormGroup && oldFormGroup?.controls?.['columnName'].touched) {
          newFormGroup.controls['columnName'].markAsTouched();
        }
        this.formItems.push(newFormGroup);
      });
      this.updateFormArrayValueAndValidity();
      return;
    }

    this.formItems = this.formBuilder.array([]);
    this.dataTable?.forEach((item: any) => {
      this.formItems.push(this.initFormGroup(item));
    });
  }

  updateFormArrayValueAndValidity() {
    this.formItems?.controls?.forEach((formItem: FormGroup) => {
      (formItem.get('columnName') as FormControl)?.valueChanges.subscribe(() => {
        this.formItems?.controls?.forEach((item: FormGroup) => (item.get('columnName') as FormControl).updateValueAndValidity({ emitEvent: false }));
      });
    });
  }

  checkDuplicated(): ValidatorFn {
    const columnName = this.headerColumns[this.displayedColumns.findIndex(item => item === 'columnName')] || '';
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.parent) {
        return null;
      }
      if (control.parent?.parent?.value.filter((item: any) => item.columnName?.trim()?.toLowerCase() === control.value?.trim()?.toLowerCase()).length > 1) {
        return { duplicatedValue: `${ columnName } already exists.` };
      }
      return null;
    };
  }

  initFormGroup(row: Record<string, unknown>) {
    const result = this.formBuilder.group({});
    this.displayedColumns.forEach((column, index) => {
      if ([STYLE_COLUMN.TEXT, STYLE_COLUMN.MULTIPLE_LINE_TEXT].includes(this.styleDisplayedColumns[index])) {
        result.setControl(column, this.formBuilder.control(row[column]));
      }
    });
    return result;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  createControlCategory(item: any): FormGroup {
    return this.formBuilder.group({
      categoryName: new FormControl(item.categoryName, {
        validators: [Validators.required, Validators.maxLength(100)],
        asyncValidators: this.checkExits(item)
      }),
      id: item.id
    });
  }

  getValueForm(formControls: any) {
    let temp = [];
    for (let i = 0; i < formControls.length; i++) {
      temp.push({
        categoryName: formControls[i].controls.categoryName.value.trim(),
        id: formControls[i].controls.id.value
      })
    }
    return temp;
  }

  checkExits(item: any): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.formItems.controls.length > 0) {
        let formValues = this.getValueForm(this.formItems.controls);
        let arrData = this.categorisArchived ? formValues.concat(this.categorisArchived) : formValues;
        if (arrData.filter((x: any) => x.id === item.id).length > 0) {
          let currentItem = arrData.filter((x: any) => x.id === item.id)[0];
          for (let i = 0; i < arrData.length; i++) {
            if (arrData[i].id !== item.id && arrData[i].categoryName.toLowerCase().trim() === currentItem.categoryName.toLowerCase().trim()) {
              return of({ inValidAsync: true });
            }
          }
        }
      }
      return of(null);
    };
  }

  createControlNotification(item: any): FormGroup {
    return this.formBuilder.group({
      active: new FormControl(item.active)
    });
  }

  createControlNavigation(item: any): FormGroup {
    if (item.navigation.length > this.valueBlank.length) {
      let blankString = ' ';
      this.valueBlank = blankString.repeat(item.navigation.length);
    }

    return this.formBuilder.group({
      navigation: new FormControl(item.navigation, {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: this.checkExitsNavigation(item)
      }),
      active: new FormControl(item.active),
      id: item.id
    });
  }

  getValueFormNavigation(formControls: any) {
    let temp = [];
    for (let i = 0; i < formControls.length; i++) {
      temp.push({
        navigation: formControls[i].controls.navigation.value.trim(),
        id: formControls[i].controls.id.value
      })
    }
    return temp;
  }

  checkExitsNavigation(item: any): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.formItems.controls.length > 0) {
        let formValues = this.getValueFormNavigation(this.formItems.controls);
        let index = formValues.findIndex((el: any) => item.id === el.id);
        if (index < 0 || index > -1 && formValues[index].navigation.toLowerCase().trim() === item.navigation.toLowerCase().trim() || formValues[index].navigation.trim() === '') {
          return of(null);
        } else {
          return this.navigationService.checkExits({
            navigation: formValues[index].navigation.trim(),
            pageDefinitionId: item.id
          }).pipe(
            map((response: any) => {
              if (response && response.exists) {
                return { inValidAsync: true };
              }
              return null;
            })
          );
        }
      } else {
        return of(null);
      }
    };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return this.dataSource.data.every((row: any) => this.selection.selected.findIndex((item: any) => item.id === row.id) > -1);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        const row = this.dataSource.data[i];
        var found = this.selection.selected.find((item: any) => item.id === row.id);
        if (found) found.checked = false;
        this.selection.deselect(found);
      }
      this.onChangeSelection.emit();
      return;
    }

    for (let i = 0; i < this.dataSource.data.length; i++) {
      const row = this.dataSource.data[i];
      var found = this.selection.selected.find((item: any) => item.id === row.id);
      if (found) {
        found.checked = true;
      } else {
        this.selection.select(row);
        row.checked = true;
      }
    }
    this.onChangeSelection.emit();
  }

  checked(row: any) {
    this.selection.select(row);
    var found = this.selection.selected.find((item: any) => item.id == row.id);
    if (found) found.checked = true;
    this.onChangeSelection.emit();
  }

  unChecked(row: any) {
    var found = this.selection.selected.find((item: any) => item.id == row.id);
    if (found) found.checked = false;
    this.selection.deselect(found);
    this.onChangeSelection.emit();
  }

  isChecked(row: any) {
    var found = this.selection.selected.find((item: any) => item.id == row.id);
    if (found) return found.checked;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: never | any): string {
    if (!row) {
      return `${ this.isAllSelected() ? 'deselect' : 'select' } all`;
    }
    return `${ this.selection.isSelected(row) ? 'deselect' : 'select' } row ${ row.position + 1 }`;
  }

  sortData(sort: Sort) {
    /** coding about sort header function to change position of data here: */
    this.sortChange.emit(sort);
  }

  fnButtonView(event: never | any, row: never | any) {
    this.buttonViewClick.emit(row);
  }

  fnButtonMember(event: never | any, row: never | any) {
    this.buttonMemberClick.emit(row);
  }

  fnButtonFund(event: never | any, row: never | any) {
    this.buttonFundClick.emit(row);
  }

  fnButtonAppContent(event: never | any, row: never | any) {
    this.buttonAppContentClick.emit(row);
  }

  fnButtonRemove(event: never | any, row: never | any) {
    this.buttonRemoveClick.emit(row);
  }

  onClickLink(element: any) {
    this.clickLink.emit(element);
  }

  fnActionRow(event: any, row: any, index: number) {
    // Do not trigger this function on the current row while in edit check number mode
    if (this.currentCheckNumberValue && this.currentCheckNumberRowIndex === index) return;

    this.currentRowIndex = index;
    this.currentRowIndexChange.emit(index);
    this.onRowAction.emit({ row, className: event.target.className, index });
  }

  fnButtonDownload(event: never | any, row: never | any) {
    this.buttonDownloadClick.emit(row);
  }

  fnButtonResourceCenter(event: never | any, row: never | any) {
    this.buttonResourceCenterClick.emit(row);
  }

  fnActionsRow(event: never | any, row: never | any, type: string) {
    if (this.pageName === 'Category') {
      if (type === 'close') {
        this.dataTable[row.index].categoryName = row.name;
        this.formItems.controls[row.index].controls.categoryName.setValue(row.name);
      } else if (type === 'archive') {
        this.formItems.removeAt(row.index);
      }
      this.onCategoryAction.emit({ row: row, type: type });
    } else {
      this.handleAction(type, row);
    }
  }

  fnButtonError(row: never | any) {
    if (row.iconName === 'remove_circle' || row.iconName === 'pending') {
      this.buttonErrorClick.emit(row);
    }
  }

  fnRegisterButtonError(row: never | any) {
      if(row.hasError){
        this.buttonRegisterErrorClick.emit(row);
      }
  }


  handleAction(type: string, row: any) {
    switch (type) {
      case 'checkmark': {
        if (this.formItems.controls[row.index].controls.navigation.errors) {
          return;
        } else {
          this.navigationService.checkExits({
            navigation: this.formItems.controls[row.index].controls.navigation.value.trim(),
            pageDefinitionId: row.id
          }).subscribe((response: any) => {
            if (response && response.exists) {
              this.formItems.controls[row.index].controls.navigation.setErrors({ inValidAsync: true });
            } else {
              row.enable = false;
              row.navigation = this.formItems.controls[row.index].controls.navigation.value;
              this.arrIdEnable.forEach((value, index) => {
                if (value === row.id) {
                  this.arrIdEnable.splice(index, 1);
                }
              });
              this.dataTable.filter((item: any) => item.id !== row.id).forEach((data:any) =>{
                this.handleAction('close',data)
              }
              );
              this.onRowActions.emit({ row: row, type: type, arrIdEnable: this.arrIdEnable });
            }
          });
        }
        break;
      }
      case 'edit': {
        if (this.pageName === 'navigation') {
          this.handleNavigationEdit(row);
          return;
        }
        this.onRowActions.emit({ row, type });
        break;
      }
      case 'changeToggle': {
        row.active = this.formItems.controls[row.index].controls.active.value;
        this.onRowActions.emit({ row: row, type: type, arrIdEnable: this.arrIdEnable });
        break;
      }
      case 'close': {
        this.arrIdEnable.forEach((value, index) => {
          if (value === row.id) {
            this.arrIdEnable.splice(index, 1);
          }
          this.onRowActions.emit({ arrIdEnable: this.arrIdEnable });
        });
        this.updateFormArrayValueAndValidity();
        const index = this.dataTable.findIndex((item: any) => item === row);
        if (index > -1) {
          this.displayedColumns.forEach((column, i) => {
            if ([STYLE_COLUMN.TEXT, STYLE_COLUMN.MULTIPLE_LINE_TEXT].includes(this.styleDisplayedColumns[i])) {
              this.formItems.controls[index].get(column).setValue(row[column]);
            }
          });
        }
        break;
      }
      default: {
        this.onRowActions.emit({ row, type });
        break;
      }
    }
  }

  handleNavigationEdit(row: any) {
    this.formItems.controls[row.index].controls.navigation.setValue(row.navigation);
    this.valueBlank = '';
    this.arrIdEnable.push(row.id);
    this.onRowActions.emit({ arrIdEnable: this.arrIdEnable });
    this.getValueFormNavigation(this.formItems.controls).forEach(ele => {
      if (ele.navigation.length > this.valueBlank.length) {
        const blankString = ' ';
        this.valueBlank = blankString.repeat(ele.navigation.length);
      }
    });
  }

  hoverCell(event: any, field?: string) {
    if (field && !['linkUrl', 'label', 'description', 'categoryName', 'filename', 'title', 'content', 'tertiary'].includes(field) && !this.isTableMemberSection) {
      this.isTruncated = false;
      return;
    }
    if (event.target.offsetWidth < event.target.scrollWidth) {
      this.isTruncated = true;
    } else {
      this.isTruncated = false;
    }
  }

  outCell() {
    this.isTruncated = false;
  }

  dropTable(event: CdkDragDrop<any[]>) {
    this.isDragDisabled = true;
    let res: any = [];
    let newData = JSON.parse(JSON.stringify(this.dataSource.filteredData));
    let temp = JSON.parse(JSON.stringify(this.dataSource.filteredData));
    for (let i = 0; i < newData.length; i += 1) {
      if ((i < event.currentIndex && i >= event.previousIndex) ||
        (i > event.currentIndex && i <= event.previousIndex)) {
        res[i] = newData[i + (event.currentIndex > event.previousIndex ? 1 : -1)];
      } else {
        res[i] = newData[i];
      }
    }
    res[event.currentIndex] = newData[event.previousIndex];
    this.dataSource = new MatTableDataSource(res);
    this.table.renderRows();
    this.isTruncated = true;
    let objRes = {
      upperAdjacentId: res[event.currentIndex - 1] ? res[event.currentIndex - 1].id : null,
      reorderItemId: res[event.currentIndex].id
    }

    if (this.dynamicGrid) {
      for (let i = 0; i < temp.length; i += 1) {
        res[i].id = temp[i].id;
      }
      objRes = {
        upperAdjacentId: event.previousIndex > event.currentIndex ? (res[event.currentIndex - 1] ? res[event.currentIndex - 1].id : null) : temp[event.currentIndex].id,
        reorderItemId: temp[event.previousIndex].id
      }
    }
    switch (this.pageName) {
      case 'Category': {
        this.onDropDragAction.emit({
          data: this.dataSource.filteredData,
          formControls: this.getValueForm(this.formItems.controls),
          status: this.formItems.status === 'VALID' ? true : false
        });
        break;
      }
      case 'Metadata': {
        this.onDropDragAction.emit({
          upperAdjacentKey: res[event.currentIndex - 1] ? res[event.currentIndex - 1].key : null,
          reorderItemKey: res[event.currentIndex].key
        });
        break;
      }
      case 'Property':
      case 'Content Pieces': {
        this.onDropDragAction.emit({
          body: {
            upperAdjacentKey: res[event.currentIndex - 1] ? res[event.currentIndex - 1].key : null,
            reorderPropertyKey: res[event.currentIndex].key
          },
          newPosition: res
        });
        break;
      }
      case 'Overview Section': {
        this.onDropDragAction.emit({
          moveFromIndex: event.previousIndex + 1,
          moveToIndex: event.currentIndex + 1
        });
        break;
      }
      case 'Property Display Config': {
        this.onDropDragAction.emit(JSON.parse(JSON.stringify([...this.dataSource.filteredData])));
        break;
      }
      default: {
        this.onDropDragAction.emit(objRes);
      }
    }
    this.moveControl(event);
  }

  moveControl(event: CdkDragDrop<any[]>) {
    const dir = event.currentIndex > event.previousIndex ? 1 : -1;

    const from = event.previousIndex;
    const to = event.currentIndex;

    const temp = this.formItems.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = this.formItems.at(i + dir);
      this.formItems.setControl(i, current);
    }
    this.formItems.setControl(to, temp);
    this.updateFormArrayValueAndValidity();
  }

  customPredicate() {
    let lockFirst = this.lockFirstRow;
    let arr = this.dataTable?.filter((el: any) => (!this.canDropDragStatic && el.isStatic)).map((el: any, index: number) => index);

    function sortPredicate(index: number, item: CdkDrag<any>) {
      if (lockFirst) {
        return index !== 0;
      }
      return !arr?.includes(index);
    }

    return sortPredicate;
  }

  freeDragArea() {
    this.isDragDisabled = false;
  }

  clearSort() {
    this.sort.sort({id: '', start: 'asc', disableClear: false});
  }

  fnControlEditCheckNumber(element: any, index: number) {
    let dataTableTemp = JSON.parse(JSON.stringify(this.dataTable));
    dataTableTemp.forEach((el: any, i: number) => {
      el.enable = false;
      if (i === index) {
        el.isEditMode = true;
      }
    });
    this.dataSource = new MatTableDataSource(dataTableTemp);

    if (this.isPayroll) {
      this.nextPayrollService.selectedPayee = element;
    } else if (this.isOffCycle) {
      this.offCyclePaymentService.selectedOffCyclePayment = element;
    } else {
      this.nextPaymentService.selectedPayment = element;
    }

    this.currentCheckNumberValue = element.checkNumber || '';
    this.currentCheckNumberRowIndex = index;
    this.controlEditCheckNumber.setValue(this.currentCheckNumberValue);
    this.controlEditCheckNumber.clearAsyncValidators();
    this.controlEditCheckNumber.addAsyncValidators(
      this.isPayroll ? checkApiValidatorGetMessage(this.nextPayrollService.validateCheckNumber, 'checkNumber', this.currentCheckNumberValue)
      : this.isOffCycle ? checkApiValidatorGetMessage(this.offCyclePaymentService.validateCheckNumber, 'checkNumber', this.currentCheckNumberValue)
      : checkApiValidatorGetMessage(this.nextPaymentService.validateCheckNumber, 'checkNumber', this.currentCheckNumberValue));

    this.updateSubsequent.setValue(false);
  }

  saveEditCheckNumber(element: any) {
    // Stop here if value is no changes
    if (this.controlEditCheckNumber.value.trim() === this.currentCheckNumberValue?.trim()) return;

    this.controlEditCheckNumber.markAsTouched();
    let dataEmit = {
      checkNumber: this.controlEditCheckNumber.value,
      updateSubsequent: this.updateSubsequent.value
    };
    if (this.controlEditCheckNumber.valid) {
      this.currentCheckNumberValue = '';
      this.currentCheckNumberRowIndex = -1;
      this.submitCheckNumber.emit(dataEmit)
    } else if (this.controlEditCheckNumber.pending) {
      let sub = this.controlEditCheckNumber.statusChanges.subscribe(() => {
        if (this.controlEditCheckNumber.valid) {
          this.currentCheckNumberValue = '';
          this.currentCheckNumberRowIndex = -1;
          this.submitCheckNumber.emit(dataEmit)
        }
        sub.unsubscribe();
      });
    }
  }

  closeEditCheckNumber(element: any, index: number) {
    let dataTableTemp = JSON.parse(JSON.stringify(this.dataTable));
    dataTableTemp.forEach((el: any, i: number) => {
      if (el.paymentType === 0) {
        el.enable = true;
      }
      if (i === index) {
        el.isEditMode = false;
      }
    });
    this.controlEditCheckNumber.setValue('');
    this.controlEditCheckNumber.reset();
    this.currentCheckNumberValue = '';
    this.dataSource = new MatTableDataSource(dataTableTemp);
  }

  getNotFoundMessage() {
    if (this.customNotFoundMessage) {
      this.dataNotFoundMessage = this.customNotFoundMessage;
      return;
    }

    if (this.isListViewPage) {
      this.store.dispatch(InterceptorActions.setShowErrorDialogCondition({ showErrorDialog: false }));
      this.store.select(fromLayoutReducer.selectInterceptorState).subscribe((state) => {
        if (!state.showErrorDialog && state.error) {
          this.dataNotFoundMessage = `${(ERROR_NAME[state.error.status] || 'An unexpected')} error`;
        }
        this.isError = !!state.error;
      });
    }
    if (this.dataNotFoundMessage) {
      return;
    }
    if (this.isHistory) {
      this.dataNotFoundMessage = 'No History to Display';
      return;
    }
    if (this.pageName) {
      this.dataNotFoundMessage = 'No ' + this.pageName + ' to Display';
      return;
    }
    if (this.isFileList) {
      this.dataNotFoundMessage = 'No Files to Display';
      return;
    }
    this.dataNotFoundMessage = 'Data Not Found !';
  }

  navigateByUrl(element: any) {
    void this.router.navigateByUrl(element.url);
  }
}
