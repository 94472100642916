import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import * as MunicipalityServiceHistoryAction from '../actions/municipality-service-history.action';
import { Municipality, MunicipalityServiceRecordList } from '../types/models';

export const MunicipalityServiceHistoryFeatureKey =
  'municipalityServiceHistory';
export interface State {
  isLoading: boolean;
  isFormEditLoading: boolean;
  serviceRecords: MunicipalityServiceRecordList;
  listMunicipality: Municipality[];
  createState?: string;
  updateState?: string;
}

const initialState: State = {
  isLoading: true,
  isFormEditLoading: true,
  serviceRecords: {
    total: 0,
    beginDate: '',
    endDate: '',
    serviceRecords: [],
    totalService: '',
  },
  listMunicipality: [],
  createState: '',
  updateState: '',
};

export const reducer = createReducer(
  initialState,
  on(
    MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryList,
    (state, {}) => ({
      ...state,
      isLoading: true,
      serviceRecords: {
        total: 0,
        beginDate: '',
        endDate: '',
        serviceRecords: [],
        totalService: '',
      },
    })
  ),
  on(
    MunicipalityServiceHistoryAction.municipalityServiceHistoryListSuccess,
    (state, { serviceRecords }) => ({
      ...state,
      isLoading: false,
      serviceRecords,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.municipalityServiceHistoryListFailure,
    (state) => ({
      ...state,
      isLoading: false,
      serviceRecords: {
        total: 0,
        beginDate: '',
        endDate: '',
        serviceRecords: [],
        totalService: '',
      },
    })
  ),
  on(MunicipalityServiceHistoryAction.getMunicipalityList, (state, {}) => ({
    ...state,
    isFormEditLoading: true,
  })),
  on(
    MunicipalityServiceHistoryAction.getMunicipalityListSuccess,
    (state, { listMunicipality }) => ({
      ...state,
      isFormEditLoading: false,
      listMunicipality,
    })
  ),
  on(MunicipalityServiceHistoryAction.getMunicipalityListFailure, (state) => ({
    ...state,
    isFormEditLoading: false,
    listMunicipality: [],
  })),

  on(
    MunicipalityServiceHistoryAction.clearMunicipalityServiceHistoryState,
    (state) => ({
      ...state,
      createState: '',
      updateState: '',
    })
  ),
  on(
    MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistorySuccess,
    (state) => ({
      ...state,
      createState: STATE.SUCCESS,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryFailure,
    (state) => ({
      ...state,
      createState: STATE.FAIL,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistorySuccess,
    (state) => ({
      ...state,
      updateState: STATE.SUCCESS,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure,
    (state) => ({
      ...state,
      updateState: STATE.FAIL,
    })
  )
);
