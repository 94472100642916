import { createAction, props } from '@ngrx/store';

import {
  AggregationList,
  AggregationPayload,
  AggregationGroupBodyChangeKey,
} from '../types/models';

export const AggregationListRequest = '[AggregationList/API] Send Request';
export const AggregationListFailure = '[AggregationList/API] Failure';
export const AggregationListSuccess = '[AggregationList/API] Success';
export const AggregationListOrder = '[AggregationListOrder/API] Send Request';
export const AddAggregationRequest = '[AddAggregation/API] Send Request';
export const AddAggregationSuccess = '[AddAggregation/API] Success';
export const AddAggregationFailure = '[AddAggregation/API] Failure';
export const RemoveAggregationListRequest =
  '[RemoveAggregationList/API] Send Request';
export const RemoveAggregationListSuccess =
  '[RemoveAggregationList/API] Success';
export const RemoveAggregationListFailure =
  '[RemoveAggregationList/API] Failure';
export const AggregationConfigClear = '[AggregationConfig] Clear';
export const EditAggregationRequest = '[EditAggregation/API] Send Request';
export const EditAggregationSuccess = '[EditAggregation/API] Success';
export const EditAggregationFailure = '[EditAggregation/API] Failure';

export const getAggregationList = createAction(AggregationListRequest);
export const getAggregationListSuccess = createAction(
  AggregationListSuccess,
  props<{ aggregationList: AggregationList }>()
);
export const getAggregationListFailure = createAction(
  AggregationListFailure,
  props<{ error?: any }>()
);
export const orderTable = createAction(
  AggregationListOrder,
  props<{ body: AggregationGroupBodyChangeKey }>()
);

export const addAggregation = createAction(
  AddAggregationRequest,
  props<{ body: AggregationPayload }>()
);
export const addAggregationSuccess = createAction(AddAggregationSuccess);
export const addAggregationFailure = createAction(
  AddAggregationFailure,
  props<{ errorMsg: string }>()
);

export const removeAggregationItem = createAction(
  RemoveAggregationListRequest,
  props<{ aggregationId: string }>()
);
export const removeAggregationItemSuccess = createAction(
  RemoveAggregationListSuccess
);
export const removeAggregationItemFailure = createAction(
  RemoveAggregationListFailure,
  props<{ errorMsg: string }>()
);

export const aggregationClear = createAction(AggregationConfigClear);

export const editAggregation = createAction(
  EditAggregationRequest,
  props<{ body: AggregationPayload }>()
);
export const editAggregationSuccess = createAction(EditAggregationSuccess);
export const editAggregationFailure = createAction(EditAggregationFailure);
