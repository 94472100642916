import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptg-number-decimal-column',
  templateUrl: './number-decimal-column.component.html',
  styleUrls: ['./number-decimal-column.component.scss'],
})
export class NumberDecimalColumnComponent {
  @Input() value!: number | string;
  @Input() decimal: number = 2;
  @Input() unit?: string;
  @Input() unitPosition: string = 'left';
}
