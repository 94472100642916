import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { uploadPlaceholder } from '../../constance/listIcons.const';

@Component({
  selector: 'ptg-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  controlField = new FormControl('');
  hasError = false;
  fileSelected: any;
  firstTouch = true;
  isDuplicated = false;

  @Input() checkPattern!: RegExp;
  @Input() typeFile?: string;
  @Input() noUpload?: boolean;
  @Input() isDisabled?: boolean;
  @Input() accept?: string;
  @Input() class?: string;
  @Input() fileLabel?: string;
  @Input() pageHeader?: string;
  @Input() imageFile?: boolean;

  @Input() placeholder?: string;

  @Input() isRequired?: boolean;

  @Input() showDirection?: boolean;

  @Output() onUploadAction = new EventEmitter();
  @Output() deleteFile = new EventEmitter();

  @Input() errMsg? = 'Unsupported file format.';
  @Input() errRequired?: string;
  @Input() errLength?: string;
  @Input() errDuplicated?: string;
  @Input() uploadButtonName?: string;

  @ViewChild('inputFile') inputFile!: ElementRef;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('upload-placeholder', sanitizer.bypassSecurityTrustHtml(uploadPlaceholder));
  }

  ngOnInit(): void {
    this.controlField.disable();
    if(this.isRequired){
      this.controlField.addValidators(Validators.required);
    }

  }

  selectFile() {
    this.inputFile.nativeElement.click();
  }

  getFile(event: any) {
    this.firstTouch = false;
    if (event.target.files && event.target.files.item(0)) {
      this.controlField.setValue(event.target.files[0].name);
      if (this.checkPattern.test(this.controlField.value)) {
        this.fileSelected = event.target.files.item(0);
        this.hasError = false;
      } else {
        this.hasError = true;
        this.fileSelected = null;
      }
    }

    if (this.noUpload) {
      this.onUploadAction.emit(this.fileSelected);
    }
  }

  uploadFile() {
    if (this.fileSelected && !this.hasError) {
      this.onUploadAction.emit(this.fileSelected);
    }
  }

  fnButtonRemove() {
    this.fileSelected = null;
    this.controlField.setValue('');
    this.inputFile.nativeElement.value = '';
    this.hasError = false;
    this.firstTouch = false;
    this.isDuplicated = false;
    this.deleteFile.emit();
  }
}
