import { FormControl } from "@angular/forms";
import { FieldData } from "./field-data";

export interface FormControlDetail {
  name?: any;
  label?: any;
  type?: any;
  maxLength?: any;
  min?: any;
  max?: any;
  fractionalLength?: any;
  lstOption: any[];
  formControl: FormControl;
  lstChildFormControl?: FieldData[];
  isRequired?: any;
  config?: any;
}
