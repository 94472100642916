import { createAction, props } from '@ngrx/store';

import { PayeeFilterType } from '../types/enums';
import {
  GetListPayeesResponse,
  GetNextPayrollTotalDeductionQuery,
  NextPayroll,
  TotalPayeeItem,
  SetPayrollStepBody,
  TotalDeduction,
  PayrollConfigurationInfo,
  PayrollActionState,
} from '../types/models';

export const NextPayrollStateClear = '[NextPayrollState] Clear';
export const NextPayrollStateReset = '[NextPayrollState] Reset';

export const NextPayrollRequest = '[NextPayroll/API] Send Request';
export const NextPayrollSuccess = '[NextPayroll/API] Success';
export const NextPayrollFailure = '[NextPayroll/API] Failure';

export const SetPayrollStepRequest = '[SetPayrollStep/API] Send Request';
export const SetPayrollStepSuccess = '[SetPayrollStep/API] Success';
export const SetPayrollStepFailure = '[SetPayrollStep/API] Failure';

export const UpdateStartingCheckNumberRequest =
  '[UpdateStartingCheckNumber/API] Send Request';
export const UpdateStartingCheckNumberSuccess =
  '[UpdateStartingCheckNumber/API] Success';
export const UpdateStartingCheckNumberFailure =
  '[UpdateStartingCheckNumber/API] Failure';

export const UpdateDepositDateRequest = '[UpdateDepositDate/API] Send Request';
export const UpdateDepositDateSuccess = '[UpdateDepositDate/API] Success';
export const UpdateDepositDateFailure = '[UpdateDepositDate/API] Failure';

export const UpdateWarrantNumberRequest =
  '[UpdateWarrantNumber/API] Send Request';
export const UpdateWarrantNumberSuccess = '[UpdateWarrantNumber/API] Success';
export const UpdateWarrantNumberFailure = '[UpdateWarrantNumber/API] Failure';

export const NextPayrollListRequest = '[NextPayrollList/API] Send Request';
export const NextPayrollListSuccess = '[NextPayrollList/API] Success';
export const NextPayrollListFailure = '[NextPayrollList/API] Failure';

export const UpdateTransactionRequest = '[UpdateTransaction/API] Send Request';
export const UpdateTransactionSuccess = '[UpdateTransaction/API] Success';
export const UpdateTransactionFailure = '[UpdateTransaction/API] Failure';
export const UpdateTransactionPending = '[UpdateTransaction/API] Pending';

export const UploadWarrantRequest = '[UploadWarrant/API] Send Request';
export const UploadWarrantSuccess = '[UploadWarrant/API] Success';
export const UploadWarrantFailure = '[UploadWarrant/API] Failure';

export const AchSentRequest = '[AchSent/API] Send Request';
export const AchSentSuccess = '[AchSent/API] Success';
export const AchSentFailure = '[AchSent/API] Failure';

export const ChecksPrintedRequest = '[ChecksPrinted/API] Send Request';
export const ChecksPrintedSuccess = '[ChecksPrinted/API] Success';
export const ChecksPrintedFailure = '[ChecksPrinted/API] Failure';

export const EditCheckNumberRequest = '[EditCheckNumber/API] Send Request';
export const EditCheckNumberSuccess = '[EditCheckNumber/API] Success';
export const EditCheckNumberFailure = '[EditCheckNumber/API] Failure';

export const EditCheckMemoRequest = '[EditCheckMemo/API] Send Request';
export const EditCheckMemoSuccess = '[EditCheckMemo/API] Success';
export const EditCheckMemoFailure = '[EditCheckMemo/API] Failure';

export const SelectBankRequest = '[SelectBank/API] Send Request';
export const SelectBankSuccess = '[SelectBank/API] Success';
export const SelectBankFailure = '[SelectBank/API] Failure';

export const FinalizePayrollRequest = '[FinalizePayroll/API] Send Request';
export const FinalizePayrollSuccess = '[FinalizePayroll/API] Success';
export const FinalizePayrollFailure = '[FinalizePayroll/API] Failure';

export const SetPayrollBoardBankRequest =
  '[SetPayrollBoardBank/API] Send Request';
export const SetPayrollBoardBankSuccess = '[SetPayrollBoardBank/API] Success';
export const SetPayrollBoardBankFailure = '[SetPayrollBoardBank/API] Failure';

export const ResetPayrollRequest = '[ResetPayroll/API] Send Request';
export const ResetPayrollSuccess = '[ResetPayroll/API] Success';
export const ResetPayrollFailure = '[ResetPayroll/API] Failure';

export const GenerateEDIRequest = '[GenerateEDI/API] Send Request';
export const GenerateEDIFailure = '[GenerateEDI/API] Failure';
export const GenerateEDISuccess = '[GenerateEDI/API] Success';

export const ReissuePaymentRequest = '[ReissuePayment/API] Send Request';
export const ReissuePaymentFailure = '[ReissuePayment/API] Failure';
export const ReissuePaymentSuccess = '[ReissuePayment/API] Success';

export const GetTotalPayeesRequest = '[GetTotalPayees/API] Send Request';
export const GetTotalPayeesFailure = '[GetTotalPayees/API] Failure';
export const GetTotalPayeesSuccess = '[GetTotalPayees/API] Success';

export const ClearEditCheckNumberState = '[ClearEditCheckNumberState] Clear';

export const SelectPayeeFilterType = '[SelectPayeeFilterType] Filter';
export const ClearPayeeFilterType = '[ClearPayeeFilterType] Filter';

export const GetTotalDeductionRequest = '[GetTotalDeduction/API] Send Request';
export const GetTotalDeductionFailure = '[GetTotalDeduction/API] Failure';
export const GetTotalDeductionSuccess = '[GetTotalDeduction/API] Success';

export const GetPayrollConfigurationInfoRequest =
  '[GetPayrollConfigurationInfo/API] Send Request';
export const GetPayrollConfigurationInfoSuccess =
  '[GetPayrollConfigurationInfo/API] Success';
export const GetPayrollConfigurationInfoFailure =
  '[GetPayrollConfigurationInfo/API] Failure';

export const GetPayrollActionStateRequest =
  '[GetPayrollActionState/API] Send Request';
export const GetPayrollActionStateSuccess =
  '[GetPayrollActionState/API] Success';
export const GetPayrollActionStateFailure =
  '[GetPayrollActionState/API] Failure';

export const clearActionState = createAction(NextPayrollStateClear);

export const resetActionState = createAction(NextPayrollStateReset);

export const getPayroll = createAction(
  NextPayrollRequest,
  props<{ filterType?: PayeeFilterType }>()
);
export const getPayrollSuccess = createAction(
  NextPayrollSuccess,
  props<{ payroll: NextPayroll }>()
);
export const getPayrollFailure = createAction(
  NextPayrollFailure,
  props<{ errorMsg: string }>()
);

export const setPayrollStep = createAction(
  SetPayrollStepRequest,
  props<{ body: SetPayrollStepBody }>()
);
export const setPayrollStepSuccess = createAction(SetPayrollStepSuccess);
export const setPayrollStepFailure = createAction(
  SetPayrollStepFailure,
  props<{ errorMsg: string }>()
);

export const updateStartingCheckNumber = createAction(
  UpdateStartingCheckNumberRequest,
  props<{ body: any }>()
);
export const updateStartingCheckNumberSuccess = createAction(
  UpdateStartingCheckNumberSuccess,
  props<{ body: any }>()
);
export const updateStartingCheckNumberFailure = createAction(
  UpdateStartingCheckNumberFailure,
  props<{ errorMsg: string }>()
);

export const updateDepositDate = createAction(
  UpdateDepositDateRequest,
  props<{ body: any }>()
);
export const updateDepositDateSuccess = createAction(
  UpdateDepositDateSuccess,
  props<{ body: any }>()
);
export const updateDepositDateFailure = createAction(
  UpdateDepositDateFailure,
  props<{ errorMsg: string }>()
);

export const updateWarrantNumber = createAction(
  UpdateWarrantNumberRequest,
  props<{ body: any }>()
);
export const updateWarrantNumberSuccess = createAction(
  UpdateWarrantNumberSuccess,
  props<{ body: any }>()
);
export const updateWarrantNumberFailure = createAction(
  UpdateWarrantNumberFailure,
  props<{ errorMsg: string }>()
);

export const getPayrollList = createAction(
  NextPayrollListRequest,
  props<{ query: any }>()
);
export const getPayrollListSuccess = createAction(
  NextPayrollListSuccess,
  props<{ response: GetListPayeesResponse }>()
);
export const getPayrollListFailure = createAction(
  NextPayrollListFailure,
  props<{ error?: any }>()
);

export const updateTransaction = createAction(
  UpdateTransactionRequest,
  props<{ body: any }>()
);
export const updateTransactionSuccess = createAction(UpdateTransactionSuccess);
export const updateTransactionFailure = createAction(
  UpdateTransactionFailure,
  props<{ errorMsg: string }>()
);

export const uploadWarrant = createAction(UploadWarrantRequest);
export const uploadWarrantSuccess = createAction(UploadWarrantSuccess);
export const uploadWarrantFailure = createAction(
  UploadWarrantFailure,
  props<{ errorMsg: string }>()
);

export const achSent = createAction(AchSentRequest, props<{ body: any }>());
export const achSentSuccess = createAction(
  AchSentSuccess,
  props<{ body: any }>()
);
export const achSentFailure = createAction(
  AchSentFailure,
  props<{ errorMsg: string }>()
);

export const checksPrinted = createAction(
  ChecksPrintedRequest,
  props<{ body: any }>()
);
export const checksPrintedSuccess = createAction(
  ChecksPrintedSuccess,
  props<{ body: any }>()
);
export const checksPrintedFailure = createAction(
  ChecksPrintedFailure,
  props<{ errorMsg: string }>()
);

export const editCheckNumber = createAction(
  EditCheckNumberRequest,
  props<{ body: any }>()
);
export const editCheckNumberSuccess = createAction(EditCheckNumberSuccess);
export const editCheckNumberFailure = createAction(
  EditCheckNumberFailure,
  props<{ errorMsg: string }>()
);

export const editCheckMemo = createAction(
  EditCheckMemoRequest,
  props<{ checkMemo: string }>()
);
export const editCheckMemoSuccess = createAction(EditCheckMemoSuccess);
export const editCheckMemoFailure = createAction(
  EditCheckMemoFailure,
  props<{ errorMsg: string }>()
);

export const selectBank = createAction(
  SelectBankRequest,
  props<{ bankName: string }>()
);
export const selectBankSuccess = createAction(SelectBankSuccess);
export const selectBankFailure = createAction(SelectBankFailure);
export const finalizePayroll = createAction(FinalizePayrollRequest);
export const finalizePayrollSuccess = createAction(FinalizePayrollSuccess);
export const finalizePayrollFailure = createAction(
  FinalizePayrollFailure,
  props<{ errorMsg: string }>()
);
export const setPayrollBoardBank = createAction(
  SetPayrollBoardBankRequest,
  props<{ boardBankId: string }>()
);
export const setPayrollBoardBankSuccess = createAction(
  SetPayrollBoardBankSuccess
);
export const setPayrollBoardBankFailure = createAction(
  SetPayrollBoardBankFailure,
  props<{ errorMsg: string }>()
);

export const resetPayroll = createAction(
  ResetPayrollRequest,
  props<{ body: any }>()
);
export const resetPayrollSuccess = createAction(ResetPayrollSuccess);
export const resetPayrollFailure = createAction(
  ResetPayrollFailure,
  props<{ errorMsg: string }>()
);

export const generateEDIRequest = createAction(
  GenerateEDIRequest,
  props<{ payrollId: any; selectedBankId: any; boardBankId: any }>()
);
export const generateEDISuccess = createAction(GenerateEDISuccess);
export const generateEDIFailure = createAction(
  GenerateEDIFailure,
  props<{ errorMsg: string }>()
);

export const clearEditCheckNumberState = createAction(
  ClearEditCheckNumberState
);

export const reissuePaymentRequest = createAction(
  ReissuePaymentRequest,
  props<{ payrollId: any }>()
);
export const reissuePaymentSuccess = createAction(ReissuePaymentSuccess);
export const reissuePaymentFailure = createAction(
  ReissuePaymentFailure,
  props<{ errorMsg: string }>()
);

export const getTotalPayeesRequest = createAction(GetTotalPayeesRequest);
export const getTotalPayeesSuccess = createAction(
  GetTotalPayeesSuccess,
  props<{ totalPayees: TotalPayeeItem[] }>()
);
export const getTotalPayeesFailure = createAction(
  GetTotalPayeesFailure,
  props<{ errorMsg: string }>()
);

export const selectPayeeFilterType = createAction(
  SelectPayeeFilterType,
  props<{ payeeFilterType?: PayeeFilterType }>()
);
export const clearPayeeFilterType = createAction(
  ClearPayeeFilterType,
  props<{ isLoadData: boolean }>()
);

export const getTotalDeductionRequest = createAction(
  GetTotalDeductionRequest,
  props<{ query: GetNextPayrollTotalDeductionQuery }>()
);
export const getTotalDeductionSuccess = createAction(
  GetTotalDeductionSuccess,
  props<{ totalDeduction: TotalDeduction }>()
);
export const getTotalDeductionFailure = createAction(GetTotalDeductionFailure);

export const getPayrollConfigurationInfoRequest = createAction(
  GetPayrollConfigurationInfoRequest
);
export const getPayrollConfigurationInfoSuccess = createAction(
  GetPayrollConfigurationInfoSuccess,
  props<{ payrollConfigurationInfo: PayrollConfigurationInfo }>()
);
export const getPayrollConfigurationInfoFailure = createAction(
  GetPayrollConfigurationInfoFailure
);

export const getPayrollActionStateRequest = createAction(
  GetPayrollActionStateRequest
);
export const getPayrollActionStateSuccess = createAction(
  GetPayrollActionStateSuccess,
  props<{ payrollActionState: PayrollActionState }>()
);
export const getPayrollActionStateFailure = createAction(
  GetPayrollActionStateFailure
);
