<h2 mat-dialog-title>Add New Off-Cycle Payment</h2>
<mat-dialog-content *ngIf="offCycleForm" [formGroup]="offCycleForm" class="content-container">
  <div class="form-field">
    <ptg-select [compareWithFunction]="compareWithFunction"
                [controlField]="offCycleForm.get('paymentAddress')"
                [isMultipleLineOption]="true"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="addressOptions"
                panelClass="property-name-dropdown"
                placeholder="Payment Address">
    </ptg-select>
  </div>
  <div class="form-field">
    <ptg-select (changeOptionValue)="changeRepresentativePayee()"
                [compareWithFunction]="compareWithFunction"
                [controlField]="offCycleForm.get('representativePayee')"
                [hasNoneValue]="true"
                [isMultipleLineOption]="true"
                [isOptionObj]="true"
                [isRequired]="false"
                [isSetMaxWidthSelect]="true"
                [listData]="representativePayeeOptions"
                panelClass="property-name-dropdown"
                placeholder="Representative Payee">
    </ptg-select>
  </div>
  <div *ngIf="earningControls?.length" [ngClass]="{'error': offCycleForm.controls?.['earnings']?.touched && !offCycleForm.controls?.['earnings']?.valid}"
       class="w-100 earnings-container">
    <div *ngFor="let earning of earningControls" [ngClass]="{'w-100': earningControls?.length === 1}"
         class="earning-item">
      <ptg-textbox [controlField]="$any(earning).controls['value']"
                   [hasLabel]="true"
                   [max]="9999999999999.99"
                   [min]="0.0"
                   [placeholder]="earning.value.name"
                   class="form-field"
                   customError="crossEarningsValidate"
                   inputType="Currency"
      ></ptg-textbox>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="gross-payment-container">
      <label>Gross Payment</label>
      <div>
        $<span>{{ grossPayment | numberDecimal : { decimal: 2 } }}</span>
      </div>
    </div>
    <div *ngIf="isShowWithholdTaxes" class="withhold-taxes-container">
      <ptg-toggle-button [controlField]="offCycleForm.controls['withTaxes']"
                         label="Withhold Taxes"
      ></ptg-toggle-button>
    </div>
  </div>
  <div class="w-100 form-field">
    <ptg-input-area [controlField]="offCycleForm.get('reason')"
                    [maxRows]="5"
                    errorRequire="Note is required."
                    placeholder="Reason"
    ></ptg-input-area>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="footer-row">
    <button (click)="formSubmit$.next()" class="button-save" mat-raised-button type="button">Save</button>
    <button (click)="onCancel()" class="button-cancel" mat-raised-button type="button">Cancel</button>
  </div>
</mat-dialog-actions>
