import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromReducer from './../../reducers';

@Injectable({
  providedIn: 'root'
})
export class CheckFundService implements CanActivate {
  constructor(private _router: Router, private store: Store<fromReducer.State>) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let curent$ = this.store.pipe(select(fromReducer.selectCurrentFundState));
    return curent$.pipe(
      map((el: any) => {
        if (el && el.id) {
          return true;
        } else {
          this._router.navigateByUrl('/');
        }
        return false;
      }) 
    );
  }
}
