import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { filter, takeUntil } from 'rxjs/operators';

import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import {
  capitalizeFirstLetter,
  getDateString,
} from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';

import * as fromMember from '../../reducers';
import * as StatusHistoryActions from '../../actions/status-history.action';
import { GetListStatusHistoryQuery, History } from '../../types/models';
import { EditMemberStatusHistoryComponent } from '../../components/edit-member-status-history/edit-member-status-history.component';
import { OverviewHeaderComponent } from '../../components/overview-header/overview-header.component';
import { StatusHistoryAuditComponent } from '../../components/status-history-audit/status-history-audit.component';
import {
  clearCreateMemberStatusHistoryStateAction,
  clearEditMemberStatusHistoryStateAction,
} from '../../actions/status-history.action';

@Component({
  selector: 'ptg-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.scss'],
})
export class StatusHistoryComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = false;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'statusDate',
      header: {
        title: 'Event Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'statusName',
      header: {
        title: 'Status',
      },
      sortable: true,
    },
    {
      name: 'statusEvent',
      header: {
        title: 'Event',
      },
      sortable: true,
    },
    {
      name: 'lastModifiedAt',
      header: {
        title: 'Last Modified At',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy h:mm a' },
    },
    {
      name: 'lastModifiedBy',
      header: {
        title: 'Modified By',
      },
      sortable: true,
    },
  ];
  statusHistorysData: History[] = [];
  memberId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  @ViewChild(OverviewHeaderComponent) overViewHeader!: OverviewHeaderComponent;

  constructor(
    private route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.memberStore.dispatch(clearCreateMemberStatusHistoryStateAction());
    this.memberStore.dispatch(
      StatusHistoryActions.clearEditMemberStatusHistoryStateAction()
    );

    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });

    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-ptg-status-history-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-ptg-status-history-pageSize'
            )
          );

    this.route.params.subscribe((params) => {
      this.memberId = params.id;
      this.getData();
    });

    this.memberStore
      .pipe(
        select(fromMember.selectStatusHistoryListState),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = !!state?.isLoading;

        if (state?.success && !state?.isLoading && state?.payload) {
          this.lengthPg = state.total;
          this.statusHistorysData =
            state.payload.memberStatusHistoryDto?.map((item: any) => ({
              ...item,
              lastModifiedAt: getDateString(item?.lastModifiedAt),
            })) ?? [];
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.createStatusHistorySelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.isLoading) {
          showBanner.call(
            this,
            state?.success ? STATE.SUCCESS : STATE.FAIL,
            'Participant Status',
            ACTION.ADD
          );

          if (state?.success) {
            this.reloadOverviewHeader();
            this.getData();
          }

          this.memberStore.dispatch(
            clearCreateMemberStatusHistoryStateAction()
          );
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.updateStatusHistorySelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.isLoading) {
          showBanner.call(
            this,
            state?.success ? STATE.SUCCESS : STATE.FAIL,
            'Participant Status',
            ACTION.EDIT
          );

          if (state?.success) {
            this.reloadOverviewHeader();
            this.getData();
          }

          this.memberStore.dispatch(clearEditMemberStatusHistoryStateAction());
        }
      });
  }

  getData() {
    let sortType = 1;
    let sortNames = ['StatusDate'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      StatusHistoryActions.getStatusHistoryList({ query })
    );
  }

  reloadOverviewHeader() {
    if (this.overViewHeader) {
      this.overViewHeader.memberId = this.memberId;
      this.overViewHeader.getMemberProfileData();
      this.overViewHeader.checkMemberIndex();
    }
  }

  onEditMemberStatus(statusHistory?: History) {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: 'Participant Status',
        statusHistory,
      },
    });
  }

  onClickSecondButton(id?: string) {
    this.dialog.open(StatusHistoryAuditComponent, {
      panelClass: 'dialog-full-screen',
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        statusHistoryId: id,
      },
    });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-status-history-pageNumber',
      this.pageNumber.toString()
    );
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-status-history-pageSize',
      this.pageSize.toString()
    );
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.statusHistorysData.findIndex(
      (item) => item.id === event.id
    );
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }
}
