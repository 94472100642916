import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import * as TierConfigurationAction from '../actions/tier-configuration.actions';
import {
  BinaryValuesResponse,
  ConditionLookupResponse,
  ConditionOperatorsResponse,
  ConditionPropertiesResponse,
  GetTierConfigurationsResponse,
  PlanResponse,
} from '../types/models';

export const tierConfigurationFeatureKey = 'tierConfiguration';

export interface State {
  isLoading: boolean;
  tierConfiguration: GetTierConfigurationsResponse;
  updateState: string;
  createState: string;
  plans: PlanResponse;
  metadata: ConditionPropertiesResponse;
  operator: ConditionOperatorsResponse;
  result: ConditionLookupResponse;
  resultBinary: BinaryValuesResponse;
}

const initialState: State = {
  isLoading: true,
  tierConfiguration: {
    tiers: [],
    totalCount: 0,
  },
  updateState: '',
  createState: '',
  plans: {} as PlanResponse,
  metadata: {} as ConditionPropertiesResponse,
  operator: {} as ConditionOperatorsResponse,
  result: {} as ConditionLookupResponse,
  resultBinary: {} as BinaryValuesResponse,
};

export const reducer = createReducer(
  initialState,
  on(TierConfigurationAction.clearTierConfigurationState, (state) => ({
    ...state,
    operator: {} as ConditionOperatorsResponse,
    metadata: {} as ConditionPropertiesResponse,
    result: {} as ConditionLookupResponse,
    resultBinary: {} as BinaryValuesResponse,
    plans: {} as PlanResponse,
    updateState: '',
    createState: '',
  })),
  on(
    TierConfigurationAction.getTierConfigurationSuccess,
    (state, { tierConfiguration }) => ({
      ...state,
      isLoading: false,
      tierConfiguration: tierConfiguration,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(TierConfigurationAction.setTierConfigurationsSuccess, (state) => ({
    ...state,
    isLoading: false,
    updateState: STATE.SUCCESS,
  })),
  on(
    TierConfigurationAction.getTierConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(TierConfigurationAction.setTierConfigurationsSuccess, (state) => ({
    ...state,
    isLoading: false,
    createState: STATE.SUCCESS,
  })),

  on(TierConfigurationAction.setTierConfigurationsFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    createState: STATE.FAIL,
  })),
  on(TierConfigurationAction.editTierConfigurationsSuccess, (state) => ({
    ...state,
    isLoading: false,
    updateState: STATE.SUCCESS,
  })),

  on(TierConfigurationAction.editTierConfigurationsFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    updateState: STATE.FAIL,
  })),
  on(
    TierConfigurationAction.getTierConfigurationPlanSuccess,
    (state, { plans }) => ({
      ...state,
      isLoading: false,
      plans: plans,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationPlanFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationMetadataSuccess,
    (state, { properties }) => ({
      ...state,
      isLoading: false,
      metadata: properties,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationMetadataFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationOperatorSuccess,
    (state, { operator }) => ({
      ...state,
      isLoading: false,
      operator: operator,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationOperatorFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationConditionLookupSuccess,
    (state, { result }) => ({
      ...state,
      isLoading: false,
      result: result,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationConditionLookupFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationBinaryValuesSuccess,
    (state, { resultBinary }) => ({
      ...state,
      isLoading: false,
      resultBinary: resultBinary,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationBinaryValuesFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  )
);
