<div class="edit-drop-plan-container">
  <ptg-title-bar
    [name]="dataDetail?.id ? 'Edit  Plan Year' : 'Add New Plan Year'"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-control-container">
      <ptg-textbox
        placeholder="Plan Year"
        errorAsync="Plan Year already exists."
        [controlField]="editForm.get('planYear')"
        [hasLabel]="true"
        [maxLength]="250"
        [isRequired]="true"
      ></ptg-textbox>
      <div class="col-item flex">
        <ptg-datepicker
          [controlField]="editForm.get('beginDate')"
          placeholder="Begin Date"
          [isRequired]="true"
          [errorAsync]="isEndDateError ? 'Selected date is already included in another plan' : 'Selected plan period overlaps with another plan'"
          (changeValue)="onChangeBeginDate($event)"
        ></ptg-datepicker>
        <ptg-textbox
          placeholder="End Date"
          class="disable-input"
          errorAsync="Selected plan period overlaps with another plan"
          [controlField]="editForm.get('endDate')"
          [hasLabel]="true"
        ></ptg-textbox>
      </div>
      <ptg-textbox
        placeholder="Annual Interest Rate %"
        [controlField]="editForm.get('annualInterestRate')"
        [hasLabel]="true"
        [isFocusOut]="true"
        [mask]="'separator.02'"
        [min]="-100"
        [max]="100"
        [inputType]="InputType.TYPE_DECIMAL"
        errorMinMaxValue="Invalid Interest Rate."
        (onBlur)="onChangeAnnualInterestRate()"
      ></ptg-textbox>
      <ptg-textbox
        class="disable-input"
        placeholder="Monthly Interest Rate (%)"
        [controlField]="editForm.get('monthInterestRate')"
        [hasLabel]="true"
      ></ptg-textbox>
    </div>
  </form>
</div>
