import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../../../reducers';
import { PtgReportViewerComponent } from '@ptg-shared/report-viewer';

@Component({
  selector: 'ptg-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportDetailComponent extends BaseComponent  {
  fileName: string = '';

  constructor(
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super();

  }

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Generated List',
      url: '/member/report/generated'
    },
    {
      name: this.fileName,
      url: ''
    }
  ];
  settings: Breadcrumb[] = [
    {
      name: 'Manage Reports',
      url:'/member/report'
    },
    {
      name: 'Manage Categories',
      url:'/member/report/category'
    }
  ];

  bannerType: BannerType = BannerType.Fail;
  message = 'Error occurred downloading the document. Please try again.';

  viewerContainerStyle = {
    //position: 'relative',
    width: '100%',
    height: 'calc(100vh - 168px)',
    ['font-family']: "'Roboto'"

};
  @ViewChild('viewer1') viewer!: PtgReportViewerComponent;
  title = 'my-app';
  source = {};
  reportId: string = '';
  apiUrl: string = `${environment.reportUrl}/api/resolve`;


  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.reportId = params?.reportId;
      this.fileName = params?.reportName;
    });

    this.listBreadcrumbs = [
      {
        name: 'Generated List',
        url: '/member/report/generated'
      },
      {
        name: this.fileName,
        url: ''
      }
    ];

    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentFund) => {
        this.source = {
          report: this.reportId,
          parameters: {
            Stage_Int: 2,
            ClientId_Str: currentFund.id,
            ClientKey_Str: currentFund.key,
            ReportId: this.reportId,
            DocumentName_Str: this.fileName
          },
        };
      });
  }
  
  onError(e: any, args: any) {
    var element = document.getElementsByClassName("trv-error-message")[1] as any;
    element.outerText = "";
    var banner = document.getElementsByClassName("drop-banner")[0] as any;
    banner.style.display = "block";
  }

}
