export enum SidebarOpenedStatus {
  Hidden,
  Opened,
  Closed,
}

export const OVERVIEW_URLS = [
  '/member/detail/',
  '/member/opt-in',
  '/member/retiree/',
  'member/profile-header-configuration',
  'member/profile-overview-configuration',
  'member/profile-navigation-configuration',
  'member/navigation',
];

export enum DisplayedTabName {
  Employers = 'employers',
  Admin = 'admin',
  Participants = 'participants',
  IndividualParticipant = 'individualParticipant',
  SearchedParticipant = 'searchedParticipant',
  Processing = 'processing',
  AppContent = 'app',
}

export enum HiddenTabName {
  Hidden = '',
  ParticipantOverviewGroup = 'participantOverviewGroup',
}

export const TAB_URLS: Record<DisplayedTabName, string[]> = {
  employers: ['employer/'],
  participants: ['/fund-management/notification', '/member', '/estimator'],
  admin: [
    'access-management',
    'organization/',
    '/fund-management/resource-center',
    '/fund-management/historical-data',
    '/fund-management/theme',
  ],
  processing: ['processing'],
  individualParticipant: OVERVIEW_URLS,
  searchedParticipant: OVERVIEW_URLS,
  app: [
    'fund-management/navigation',
    'fund-management/preferences',
    '/page/mixed-bulk-add',
    'page',
    'fund-management/app-content',
  ],
};

export const DEFAULT_TAB_URL: Record<DisplayedTabName, string> = {
  employers: '/employer/employer-management',
  participants: '/member',
  admin: '/access-management/account',
  processing: '/processing',
  individualParticipant: '',
  searchedParticipant: '',
  app: '/fund-management/navigation',
};

export const TAB_PERMISSION: Record<DisplayedTabName, string> = {
  employers: 'EMPLOYERS',
  participants: 'PARTICIPANT',
  admin: 'ADMIN',
  processing: '',
  individualParticipant: 'PARTICIPANT',
  searchedParticipant: 'PARTICIPANT',
  app: 'APP_CONTENT',
};

export enum ItemMenuType {
  NextPayment,
  PaymentHistory,
  MunicipalityServiceRecord,
  Info1099,
}

export const STRING_QUERY_PARAM = {
  SEARCH: 'search=true',
};

export enum NAVIGATION_CONST {
  memberNavigation = 'member/navigation/',
  employerParametersNavigation = 'employer/parameters/',
}
