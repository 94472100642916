<div class="input-file">
  <div style="display: inline-block; position: relative;">
    <input #inputFile type="file" (change)="getFile($event)" style="display: none;" [accept]="accept">
    <ptg-button *ngIf="!controlField?.value && !fileLabel" 
      [buttonName]="imageFile ? pageHeader ? 'Upload ' + pageHeader : 'Upload Image' : uploadButtonName ? uploadButtonName : 'Upload File'" 
      classInput="placeholder-file" 
      (clickButton)="fnButtonRemove()"
      [isDisabled]="true"
    >
      <mat-icon svgIcon="upload-placeholder" aria-hidden="false" aria-label="upload-icon"></mat-icon>
    </ptg-button>
    <input *ngIf="!controlField?.value && fileLabel; else inputFileControl" class="file-name {{class}}" [value]="fileLabel" [title]="fileLabel" disabled>
    <ng-template #inputFileControl>
      <input class="file-name {{class}}" [formControl]="controlField" [title]="controlField?.value">
    </ng-template>
    <ptg-button *ngIf="controlField?.value || fileLabel" buttonName="Remove" classInput="remove-file" (clickButton)="fnButtonRemove()">
      <mat-icon class="ignore-row">delete_forever</mat-icon>
    </ptg-button>
  </div>
  <button mat-raised-button type="button" (click)="selectFile()" [disabled]="isDisabled"
    style="background-color: #408bf9; color: #fff; width: 92px;">
    Browse
  </button>
  <button *ngIf="!noUpload" mat-raised-button type="button" (click)="uploadFile()"
    style="background-color: #408bf9; color: #fff;; width: 89px">
    Upload
  </button>
  <p class="type-upload">
    {{typeFile}}
  </p>
  <p class="error-msg" *ngIf="hasError">
    {{errMsg}}
  </p>
  <p class="error-msg" *ngIf="isRequired && !fileSelected && !controlField.value && !firstTouch">
    {{errRequired}}
  </p>
  <p class="error-msg" *ngIf="!hasError && errLength && controlField.value && controlField.value.length > 50">
    {{errLength}}
  </p>
  <p class="error-msg" *ngIf="!hasError && errDuplicated && controlField.value && isDuplicated">
    {{errDuplicated}}
  </p>
</div>

