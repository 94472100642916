import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { Option } from '@ptg-shared/controls/select/select.component';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AbstractControlStatus } from '@ptg-shared/models/common.model';

import * as LookupTableActions from '../../actions/lookup-table.actions';
import * as MetadataSectionActions from '../../actions/metadata-detail.actions';
import * as PropertyActions from '../../actions/property.actions';
import { PropertyType } from '../../constance/metadata.const';
import {
  LookupTable,
  MetadataPropertyType,
  PropertyEditData,
  PropertyRequest,
  PropertyTypeNavigation,
} from '../../types/models';
import * as fromMember from '../../reducers';
import { MetadataSectionService } from '../../services/metadata-detail.service';

@Component({
  selector: 'ptg-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
})
export class AddPropertyComponent implements OnInit, OnDestroy {
  editForm: FormGroup = this.fb.group({
    propertyName: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.metadataSectionService.checkPropertyExits,
        'Name'
      ),
    }),
    propertyType: this.fb.control('', [Validators.required]),
    unique: this.fb.control(false),
    encrypted: this.fb.control(false),
    lookupTableId: this.fb.control(''),
  });
  properties!: MetadataPropertyType[];
  @Input() data!: PropertyEditData;
  formSubmit$ = new Subject<boolean>();
  isContinue = false;
  @Output() closeDialog: EventEmitter<PropertyEditData> = new EventEmitter();
  @Output() changeDialogType: EventEmitter<boolean> = new EventEmitter();
  propertiesUnique = [
    PropertyType.Currency,
    PropertyType.Text,
    PropertyType.Decimal,
    PropertyType.Email,
    PropertyType['Whole Number'],
    PropertyType.Phone,
    PropertyType.Date,
    PropertyType.Percentage,
    PropertyType.SSN,
  ];
  propertiesEncrypted = [
    PropertyType.Text,
    PropertyType.SSN,
    PropertyType.Phone,
    PropertyType.Email,
    PropertyType.Address,
    PropertyType['Person Name'],
  ];
  showUnique = false;
  showEncrypted = false;
  lookupTableOptions: Option[] = [];
  lookupTables: LookupTable[] | undefined = [];
  unsubscribe$ = new Subject<void>();
  PROPERTY_TYPE = PropertyType;

  constructor(
    public fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public metadataSectionService: MetadataSectionService,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialogRef: MatDialogRef<AddPropertyComponent>
  ) {}

  ngOnInit(): void {
    this.getLookupTableOptions();
    this.memberStore.dispatch(
      PropertyActions.getMetadataPropertyTypes({
        navType: PropertyTypeNavigation.Metadata,
      })
    );
    this.memberStore
      .pipe(select(fromMember.selectPropertyTypeCollectionState))
      .subscribe((res) => {
        this.properties = [...res].sort((a: any, b: any) => {
          if (a?.displayValue > b?.displayValue) {
            return 1;
          }
          return -1;
        });
      });

    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
          this.editForm.get('propertyName')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });

    this.editForm.get('propertyType')?.valueChanges.subscribe((value) => {
      if (value <= 0) {
        return;
      }
      this.memberStore.dispatch(
        PropertyActions.selectPropertyType({ selectedPropTypeValue: value })
      );
      this.editForm.get('unique')?.setValue(false);
      this.editForm.get('encrypted')?.setValue(false);
      this.showUnique = this.propertiesUnique.includes(value);
      this.showEncrypted = this.propertiesEncrypted.includes(value);
      this.editForm.get('unique')?.enable({ emitEvent: false });
      this.editForm.get('encrypted')?.enable({ emitEvent: false });
      if (value === PropertyType.SSN) {
        this.editForm.get('unique')?.setValue(true);
        this.editForm.get('encrypted')?.setValue(true);
        this.editForm.get('encrypted')?.disable({ emitEvent: false });
      }
      if (value !== PropertyType.List) {
        this.editForm.get('lookupTableId')?.setValue('');
        this.editForm.get('lookupTableId')?.clearValidators();
        this.editForm.get('lookupTableId')?.setErrors(null);
      }
    });
    this.editForm
      .get('propertyName')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe((value) => {
        if (value) {
          this.memberStore.dispatch(
            PropertyActions.inputPropertyName({ name: value })
          );
        }
      });
  }

  onSubmit() {
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const body: PropertyRequest = {
      Name: this.editForm.value.propertyName,
      Type: this.editForm.value.propertyType?.toString(),
      lookupTableId:
        this.editForm.value.propertyType === PropertyType.List
          ? this.editForm.getRawValue().lookupTableId
          : undefined,
    };
    if (this.showUnique) {
      body.Unique = this.editForm.getRawValue().unique;
    }
    if (this.showEncrypted) {
      body.Encrypted = this.editForm.getRawValue().encrypted;
    }
    this.memberStore.dispatch(
      MetadataSectionActions.addProperty({ body, isContinue: this.isContinue })
    );
    if (!this.isContinue) {
      this.editForm.reset();
      this.showUnique = false;
      this.showEncrypted = false;
    }
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  getLookupTableOptions() {
    this.memberStore.dispatch(
      LookupTableActions.getLookupTableList({
        query: { SortNames: 'Name', SortType: 0 },
      })
    );
    this.memberStore
      .select(fromMember.selectLookupTable)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.lookupTables = state.lookupTableList;
        this.lookupTableOptions = (this.lookupTables || [])
          .reduce((result, current) => {
            if (current.active) {
              result.push({
                value: current.id,
                displayValue: current.name,
              });
            }
            return result;
          }, [] as Option[])
          .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
