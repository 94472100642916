import { createAction, props } from '@ngrx/store';
import { MetadataPropertyType, PropertyTypeNavigation } from '../types/models';

export const MetadataPropertyTypesRequest =
  '[MetadataPropertyTypes/API] send request';
export const MetadataPropertyTypesSuccess =
  '[MetadataPropertyTypes/API] Success';
export const SelectPropertyType = '[MetadataPropertyType] Select';
export const InputPropertyName = '[MetadataPropertyName] Input';

export const getMetadataPropertyTypes = createAction(
  MetadataPropertyTypesRequest,
  props<{ navType: PropertyTypeNavigation }>()
);
export const getMetadataPropertyTypesSuccess = createAction(
  MetadataPropertyTypesSuccess,
  props<{ propertyTypes: MetadataPropertyType[] }>()
);

export const selectPropertyType = createAction(
  SelectPropertyType,
  props<{ selectedPropTypeValue: number }>()
);

export const inputPropertyName = createAction(
  InputPropertyName,
  props<{ name: string }>()
);
