import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { deepClone } from '@ptg-shared/utils/common.util';

import { MemberListActions } from '../actions';
import {
  FilterByPropertyConfig,
  FilterInformation,
  MemberFilter,
  MemberList,
} from '../types/models';

export const memberListFeatureKey = 'memberList';
export interface State {
  isLoading: boolean;
  isExporting: boolean;
  memberList: MemberList;
  tmp?: any;
  removeState?: string;
  unLockMemberState?: string;
  lockMemberState?: string;
  memberKey: string;
  listSectionProperty: FilterByPropertyConfig[];
  filters?: MemberFilter[];
  createMemberFilterState?: string;
  listFilterInfo?: FilterInformation[];
  removeMemberFilterState?: string;
  updateFilterNameState?: string;
  updateFilterState?: string;
  isError?: boolean;
}

const initialState: State = {
  isLoading: true,
  isExporting: false,
  memberList: {
    total: 0,
    members: [],
    columns: [],
    message: '',
  },
  memberKey: '',
  listSectionProperty: [],
};

export const reducer = createReducer(
  initialState,
  on(MemberListActions.getMemberList, (state, {}) => ({
    ...state,
    isLoading: true,
    memberList: {
      total: 0,
      members: [],
      columns: [],
      message: '',
    },
  })),
  on(MemberListActions.getMemberListSuccess, (state, { memberList }) => ({
    ...state,
    isLoading: false,
    isError: false,
    memberList,
    success: true,
    error: undefined,
  })),
  on(MemberListActions.getMemberListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isError: true,
    memberList: {
      total: 0,
      members: [],
      columns: [],
      message: '',
      success: false,
      error: error,
    },
  })),
  on(MemberListActions.removeMemberSuccess, (state, { memberKey }) => {
    return {
      ...state,
      memberKey: memberKey,
      removeState: STATE.SUCCESS,
      tmp: {
        ...state.tmp,
        memberList: null,
      },
    };
  }),
  on(MemberListActions.removeMemberClear, (state) => {
    return {
      ...state,
      removeState: '',
      unLockMemberState: '',
      lockMemberState: '',
      createMemberFilterState: '',
      removeMemberFilterState: '',
      updateFilterNameState: '',
      updateFilterState: '',
    };
  }),
  on(MemberListActions.removeMemberFailure, (state, { id, errorMsg }) => {
    return {
      ...state,
      removeState: STATE.FAIL,
    };
  }),
  on(MemberListActions.lockMemberFailure, (state, { id, errorMsg }) => {
    return {
      ...state,
      lockMemberState: STATE.FAIL,
    };
  }),
  on(MemberListActions.lockMemberSuccess, (state, { id }) => {
    const index = state.memberList.members.findIndex(
      (member) => member._memberId === id
    );
    const memberList = deepClone(state.memberList);
    if (index > -1) {
      memberList.members[index]._isLocked =
        !memberList.members[index]._isLocked;
    }
    return {
      ...state,
      memberList,
      lockMemberState: STATE.SUCCESS,
      reloadMetadataDetail: true,
    };
  }),
  on(MemberListActions.unlockMemberFailure, (state, { id, errorMsg }) => {
    return {
      ...state,
      unLockMemberState: STATE.FAIL,
    };
  }),
  on(MemberListActions.unlockMemberSuccess, (state, { id }) => {
    const index = state.memberList.members.findIndex(
      (member) => member._memberId === id
    );
    const memberList = deepClone(state.memberList);
    if (index > -1) {
      memberList.members[index]._isLocked =
        !memberList.members[index]._isLocked;
    }
    return {
      ...state,
      memberList,
      unLockMemberState: STATE.SUCCESS,
      reloadMetadataDetail: true,
    };
  }),
  on(
    MemberListActions.getFilterByPropertyListSuccess,
    (state, { listSectionProperty }) => ({
      ...state,
      listSectionProperty,
    })
  ),
  on(MemberListActions.setMemberFilter, (state, { filters }) => ({
    ...state,
    filters,
  })),
  on(MemberListActions.createMemberFilterSuccess, (state) => {
    return {
      ...state,
      createMemberFilterState: STATE.SUCCESS,
    };
  }),
  on(MemberListActions.createMemberFilterFailure, (state) => {
    return {
      ...state,
      createMemberFilterState: STATE.FAIL,
    };
  }),
  on(MemberListActions.getMemberFilterSuccess, (state, { listFilterInfo }) => {
    return {
      ...state,
      listFilterInfo,
    };
  }),
  on(MemberListActions.getMemberFilterFailure, (state) => {
    return {
      ...state,
    };
  }),
  on(MemberListActions.removeMemberFilterSuccess, (state, { id }) => {
    return {
      ...state,
      removeMemberFilterState: id,
    };
  }),
  on(
    MemberListActions.updateFilterNameSuccess,
    (state, { id, listFilterInfo }) => {
      return {
        ...state,
        updateFilterNameState: id,
        updateFilterState: listFilterInfo.length > 0 ? STATE.SUCCESS : '',
      };
    }
  ),
  on(
    MemberListActions.updateFilterNameFailure,
    (state, { id, listFilterInfo }) => {
      return {
        ...state,
        updateFilterState: listFilterInfo.length > 0 ? STATE.FAIL : '',
      };
    }
  ),
  on(MemberListActions.exportMembersRequest, (state, { request }) => {
    return {
      ...state,
      isExporting: true,
    };
  }),
  on(MemberListActions.downloadMembersRequestSuccess, (state, {}) => {
    return {
      ...state,
      isExporting: false,
    };
  })
);
