import { createAction, props } from '@ngrx/store';

import {
  EditMunicipalityServiceHistoryRequest,
  GetListStatusHistoryQuery,
  Municipality,
  MunicipalityServiceRecordList,
} from '../types/models';

export const MunicipalityServiceHistoryListRequest =
  '[MunicipalityServiceHistoryList/API] Send Request';
export const MunicipalityServiceHistoryListSuccess =
  '[MunicipalityServiceHistoryList/API] Success';
export const MunicipalityServiceHistoryListFailure =
  '[MunicipalityServiceHistoryList/API] Failure';

export const MunicipalityListRequest = '[MunicipalityList/API] Send Request';
export const MunicipalityListSuccess = '[MunicipalityList/API] Success';
export const MunicipalityListFailure = '[MunicipalityList/API] Failure';

export const CreateMemberMunicipalityServiceHistoryRequest =
  '[CreateMemberMunicipalityServiceHistory/API] Send Request';
export const CreateMemberMunicipalityServiceHistorySuccess =
  '[CreateMemberMunicipalityServiceHistory/API] Success';
export const CreateMemberMunicipalityServiceHistoryFailure =
  '[CreateMemberMunicipalityServiceHistory/API] Failure';
export const EditMemberMunicipalityServiceHistoryRequest =
  '[EditMemberMunicipalityServiceHistory/API] Send Request';
export const EditMemberMunicipalityServiceHistorySuccess =
  '[EditMemberMunicipalityServiceHistory/API] Success';
export const EditMemberMunicipalityServiceHistoryFailure =
  '[EditMemberMunicipalityServiceHistory/API] Failure';
export const ClearMunicipalityServiceHistoryState =
  '[ClearMunicipalityServiceHistoryState]';

export const getMunicipalityServiceHistoryList = createAction(
  MunicipalityServiceHistoryListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const municipalityServiceHistoryListSuccess = createAction(
  MunicipalityServiceHistoryListSuccess,
  props<{ serviceRecords: MunicipalityServiceRecordList }>()
);
export const municipalityServiceHistoryListFailure = createAction(
  MunicipalityServiceHistoryListFailure
);
export const getMunicipalityList = createAction(MunicipalityListRequest);
export const getMunicipalityListSuccess = createAction(
  MunicipalityListSuccess,
  props<{ listMunicipality: Municipality[] }>()
);
export const getMunicipalityListFailure = createAction(MunicipalityListFailure);

export const createMemberMunicipalityServiceHistoryRequest = createAction(
  CreateMemberMunicipalityServiceHistoryRequest,
  props<{ body: EditMunicipalityServiceHistoryRequest }>()
);
export const createMemberMunicipalityServiceHistorySuccess = createAction(
  CreateMemberMunicipalityServiceHistorySuccess
);
export const createMemberMunicipalityServiceHistoryFailure = createAction(
  CreateMemberMunicipalityServiceHistoryFailure
);
export const editMemberMunicipalityServiceHistoryRequest = createAction(
  EditMemberMunicipalityServiceHistoryRequest,
  props<{ body: EditMunicipalityServiceHistoryRequest }>()
);
export const editMemberMunicipalityServiceHistorySuccess = createAction(
  EditMemberMunicipalityServiceHistorySuccess
);
export const editMemberMunicipalityServiceHistoryFailure = createAction(
  EditMemberMunicipalityServiceHistoryFailure
);
export const clearMunicipalityServiceHistoryState = createAction(
  ClearMunicipalityServiceHistoryState
);
