import { DisplayValueLocationType, DisplayValueType } from '../enums';
import { PropertyType } from '@ptg-member/constance/metadata.const';

export interface Info1099RecordQuery {
  pageIndex: number;
  totalPerPage?: number;
  sortField?: any;
  sortType?: number;
}


export interface Info1099ConfigData {
  id: string;
  columnName: string;
  displayName: string;
  subDisplayName?: string | null;
  dataType: number;
  displayType: DisplayValueType;
  showInList: boolean;
  orderInList: number;
  detailColumn: number;
  detailColumnOrder: number;
  displayLocation: DisplayValueLocationType;
}

export interface Info1099RecordResponse {
  total: number;
  items: any[];
}