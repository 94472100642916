import { createAction, props } from '@ngrx/store';

import {
  LumpSumPaymentHistoryList,
  LumpSumPaymentHistoryQuery,
  LumpSumPaymentInformation,
} from '../types/models';

export const GetLumpSumPaymentRequest = '[GetLumpSumPayment/API] Send Request';
export const GetLumpSumPaymentSuccess = '[GetLumpSumPayment/API] Success';
export const GetLumpSumPaymentFailure = '[GetLumpSumPayment/API] Failure';

export const GetLumpSumPaymentHistoryRequest =
  '[GetLumpSumPaymentHistory/API] Send Request';
export const GetLumpSumPaymentHistorySuccess =
  '[GetLumpSumPaymentHistory/API] Success';
export const GetLumpSumPaymentHistoryFailure =
  '[GetLumpSumPaymentHistory/API] Failure';

export const getLumpSumPayment = createAction(GetLumpSumPaymentRequest);
export const getLumpSumPaymentSuccess = createAction(
  GetLumpSumPaymentSuccess,
  props<{ lumpSumPaymentList: LumpSumPaymentInformation[] }>()
);
export const getLumpSumPaymentFailure = createAction(
  GetLumpSumPaymentFailure,
  props<{ error?: any }>()
);

export const getLumpSumPaymentHistory = createAction(
  GetLumpSumPaymentHistoryRequest,
  props<{ query: LumpSumPaymentHistoryQuery }>()
);
export const getLumpSumPaymentHistorySuccess = createAction(
  GetLumpSumPaymentHistorySuccess,
  props<{ lumpSumPaymentHistoryList: LumpSumPaymentHistoryList }>()
);
export const getLumpSumPaymentHistoryFailure = createAction(
  GetLumpSumPaymentHistoryFailure,
  props<{ errorMsg: string }>()
);
