import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { UploadComponent } from 'src/app/shared/controls/upload/upload.component';
import * as fromReducer from '../../../reducers/index';
import { ConfirmType } from '../../../shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE, DISCARD_CONFIRM_MESSAGE } from '../../../shared/constance/value.const';
import { ConfirmPopupComponent } from '../../../shared/controls/confirm-popup/confirm-popup.component';
import { MemberDetailActions } from '../../actions';
import { MemberDetailService } from '../../services/member-detail.service';

interface MemberAttachmentData {
  memberId: string;
  itemKey: string;
  attachment: any;
  rowId: string;
}

@Component({
  selector: 'ptg-member-attachment-modal',
  templateUrl: './member-attachment-modal.component.html',
  styleUrls: ['./member-attachment-modal.component.scss']
})
export class MemberAttachmentModalComponent implements OnInit, AfterViewInit {
  formGroup!: FormGroup;
  file: any;
  acceptDocument = 'application/pdf';
  typeFileDocument = 'pdf file format';
  checkPatternDocument = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  errDuplicated?: string;
  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  constructor(
    private store: Store<fromReducer.State>,
    private memberDetailService: MemberDetailService,
    public dialogRef: MatDialogRef<MemberAttachmentModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MemberAttachmentData) {
  }

  ngOnInit(): void {
    this.onInitForm();
  }

  ngAfterViewInit(): void {
    if (!this.data?.attachment?.id) {
      this.fileDocument.controlField.setValue(this.data?.attachment?.attachment);
    }
  }

  onInitForm() {
    this.formGroup = new FormGroup({
      attachmentId: new FormControl(this.data?.attachment?.id),
      documentName: new FormControl(this.data?.attachment?.documentName, Validators.required),
      description: new FormControl(this.data?.attachment?.description)
    });
  }

  onSubmit() {
    if (!this.data?.attachment?.id) {
      this.fileDocument.firstTouch = false;
      this.fileDocument.controlField.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
    if (this.formGroup.pending) {
      let sub = this.formGroup.statusChanges.subscribe(() => {
        if (this.formGroup.valid && (this.fileDocument?.fileSelected || this.data?.attachment?.id)) {
          this.onSave();
        }
        sub.unsubscribe();
      });
    } else if (this.formGroup.valid && (this.fileDocument?.fileSelected || this.data?.attachment?.id)) {
      this.onSave();
    }
  }

  onSave() {
    if (!this.data.attachment?.id && this.file) {
      const attachmentFile: any = {
        ...this.formGroup.getRawValue(),
        memberId: this.data.memberId,
        itemKey: this.data.itemKey,
        file: this.file,
        rowId: this.data.rowId
      }

      this.memberDetailService.checkMetaDataAttachmentExists({
        memberId: this.data.memberId,
        itemKey: this.data.itemKey,
        fileName: this.file.name,
        rowId: this.data.rowId,
      }).subscribe(
        (res: any) => {
          if (res && res.exists) {
            this.fileDocument.isDuplicated = true;
            this.errDuplicated = `${ this.file.name } (${ this.file.size } bytes) already exists. Please select another file.`;
          } else {
            this.fileDocument.isDuplicated = false;
            this.store.dispatch(MemberDetailActions.createMemberUploadAttachments({ body: [attachmentFile] }));
            this.dialogRef.close();
          }
        },
        (error) => {
          this.fileDocument.isDuplicated = false;
          this.dialogRef.close();
        }
      );
    } else {
      const attachmentFile = {
        ...this.formGroup.getRawValue(),
        memberId: this.data.memberId,
        itemKey: this.data.itemKey,
        rowId: this.data.rowId
      }
      this.store.dispatch(MemberDetailActions.updateMemberUploadAttachments({ body: attachmentFile }));
      this.dialogRef.close();
    }
  }

  uploadFile(event: any) {
    if (event) {
      this.file = event;
      this.memberDetailService.checkMetaDataAttachmentExists({
        memberId: this.data.memberId,
        itemKey: this.data.itemKey,
        fileName: event.name,
        rowId: this.data.rowId,
      }).subscribe(
        (res: any) => {
          if (res && res.exists) {
            this.fileDocument.isDuplicated = true;
            this.errDuplicated = `${ event.name } (${ event.size } bytes) already exists. Please select another file.`;
          } else {
            this.fileDocument.isDuplicated = false;
          }
        },
        (error) => {
          this.fileDocument.isDuplicated = false;
        }
      );
    }
  }

  removeFile(event: any) {
    this.file = event;
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
