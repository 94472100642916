import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HeaderActions } from '../actions';
import { SearchResult } from '../models/search.models';
import { SearchMemberService } from '../services/search-member.service';

@Injectable()
export class SearchMemberListEffects {
  getSearchMemberList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getSearchRequest),
      switchMap(({ searchValue, idClient, searchMemberType, benefitTypeId }) => {
        return this.searchMemberService.getSearchMember(searchValue, idClient, searchMemberType, benefitTypeId).pipe(
          map((searchResult: SearchResult) => {
            return HeaderActions.getSearchRequestSuccess({ searchResult });
          }),
          catchError((err) => {
            return of(HeaderActions.getSearchRequestFailure({ errorMsg: err.message }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private searchMemberService: SearchMemberService) {}
}
