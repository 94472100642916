import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { DateTime } from 'luxon';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import * as ParticipantSearchConfigurationActions from '@ptg-member/actions/participant-search-configuration.actions';
import { PropertyTypeLabel } from '@ptg-member/constance/metadata.const';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import * as fromMember from '@ptg-member/reducers';
import { PropertyDisplayConfiguration } from '@ptg-member/types/models';
import { RegisterActions } from '@ptg-processing/actions';
import { ProcessingState } from '@ptg-processing/reducers';
import { MyMonitoringService } from '@ptg-shared/services/azure-insights.service';
import { environment } from 'src/environments/environment';

import * as FundListActions from '../../../../fund-list/actions/fund-list.actions';
import * as fromReducer from '../../../../reducers';
import { Auth0Service } from '../../../auth/services/auth0.service';
import {
  doubleArrowDownIcon,
  doubleArrowUpIcon,
  personSearch,
} from '../../../constance/listIcons.const';
import { PERMISSION_KEY } from '../../../constance/permission.const';
import { ADMIN_FUND, ADMIN_SYSTEM } from '../../../constance/value.const';
import { CheckPermissionService } from '../../../services/check-permission.service';
import { HistoryService } from '../../../services/history.service';
import { deepClone, getCurrentFundData } from '../../../utils/common.util';
import { HeaderActions, LayoutActions } from '../../actions';
import {
  DisplayedTabName,
  STRING_QUERY_PARAM,
} from '../../constance/layout.const';
import { dispatchGetMemberNavigationList } from '../../helpers';
import { SearchResult } from '../../models/search.models';
import * as fromLayout from '../../reducers';

@Component({
  selector: 'ptg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('participantSearchTrigger')
  participantSearchTrigger!: MatAutocompleteTrigger;

  showHeader = true;
  valueSearch: string = '';
  username: string = '';
  tenant: string = '';
  logoUrl: string = '';
  listFund: FundModel[] = [];
  search$ = new BehaviorSubject('');
  isLoading = true;
  ADMIN_FUND = ADMIN_FUND;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  activeFund: any;
  PERMISSION_KEY = PERMISSION_KEY;
  searchResultColumns: string[] = [];
  searchResultColumnTypes: string[] = [];
  searchResultList: Record<string, any>[] = [];
  unsubscribe$ = new Subject<void>();
  hasConfig: boolean = false;
  isError: boolean = false;

  constructor(
    private store: Store<fromReducer.State>,
    private searchStore: Store<fromLayout.State>,
    public authService: Auth0Service,
    public router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public checkPermissionService: CheckPermissionService,
    private historyService: HistoryService,
    private memberStore: Store<fromMember.MemberState>,
    private processingStore: Store<ProcessingState>,
    private renderer: Renderer2,
    private myMonitoringService: MyMonitoringService,
  ) {
    iconRegistry.addSvgIconLiteral(
      'personSearch',
      sanitizer.bypassSecurityTrustHtml(personSearch),
    );
    iconRegistry.addSvgIconLiteral(
      'doubleArrowDownIcon',
      sanitizer.bypassSecurityTrustHtml(doubleArrowDownIcon),
    );
    iconRegistry.addSvgIconLiteral(
      'doubleArrowUpIcon',
      sanitizer.bypassSecurityTrustHtml(doubleArrowUpIcon),
    );
  }

  ngOnInit(): void {
    this.checkParticipantSearchConfig();
    this.username = this.authService.name.split(' ')[0];

    this.store.pipe(select(fromReducer.selectFundListState)).subscribe((el) => {
      this.tenant = el.currentFund?.name;
      this.listFund = el.allFund || [];
      if (this.activeFund?.id !== el.currentFund?.id) {
        this.store.dispatch(
          FundListActions.updateFundLayout({
            updateLayout: { brandRefreshed: new Date().toUTCString() },
          }),
        );
      }
      this.activeFund = el.currentFund;
      // this.activeFund.currentFund = el.currentFund;
      // this.activeFund.Id = el.currentFund.id;
      // this.activeFund.Key = el.currentFund.key;
      // this.activeFund.refreshBrand();
    });

    this.store
      .pipe(select(fromReducer.selectBrandRefreshed))
      .subscribe((el) => {
        this.logoUrl = `${environment.blobUrl}${
          this.activeFund?.key
        }/icons/logo_64x64.png?${new Date().getTime()}`;

        let cssLink: HTMLLinkElement = document.getElementById(
          'corporateBrand',
        ) as HTMLLinkElement;
        cssLink.href = `${environment.blobUrl}${
          this.activeFund?.key
        }/styles/admin_app.css?${new Date().getTime()}`;
      });

    this.searchStore
      .pipe(select(fromLayout.selectSearchState))
      .subscribe((searchState) => {
        let searchData: SearchResult = deepClone(
          searchState.searchResult || {},
        );
        this.searchResultColumns = (searchData.columns || []).map(
          (item) => item.columnName,
        );
        this.searchResultColumnTypes = (searchData.columns || []).map(
          (item) => item.type,
        );
        this.searchResultList = (searchData.members || []).map(
          (member, index) => {
            return {
              ...this.getColumnValue(searchData, index),
              _memberId: member._memberId,
            };
          },
        );
        this.isLoading = searchState.isLoading;
        this.isError = searchState.isError;
      });

    this.search$.pipe(debounceTime(500)).subscribe((el) => {
      this.searchStore.dispatch(HeaderActions.clearSearchList());
      if (el && el.length > 1) {
        this.searchStore.dispatch(
          HeaderActions.getSearchRequest({
            searchValue: el,
            idClient: this.activeFund?.id,
            searchMemberType: 0,
            benefitTypeId: null,
          }),
        );
      }
    });

    this.router.events.subscribe((el) => {
      if (
        el instanceof NavigationEnd &&
        el.url === '/fund-list' &&
        this.valueSearch
      ) {
        this.searchStore.dispatch(HeaderActions.clearSearchList());
        this.valueSearch = '';
      }
      if (el instanceof NavigationStart) {
        this.myMonitoringService.startNavigationEvent(el.url);
      } else if (el instanceof NavigationEnd) {
        this.myMonitoringService.endNavigationEvent(el.url);
        this.myMonitoringService.logPageView();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked() {
    this.participantSearchTrigger?.updatePosition();
  }

  searchMember(value: string) {
    this.search$.next(value);
  }

  selectMember(option: any) {
    this.participantSearchTrigger.closePanel();

    this.store.dispatch(
      LayoutActions.setHistory({
        history: { urlBeforeSearch: this.router.url },
      }),
    );
    this.store.dispatch(
      LayoutActions.selectTab({
        tab: DisplayedTabName.SearchedParticipant,
        url:
          '/member/detail/' +
          option._memberId +
          `?${STRING_QUERY_PARAM.SEARCH}`,
      }),
    );

    dispatchGetMemberNavigationList(this.store, option._memberId);
  }

  changeTenant(tenant: string) {
    this.memberStore.dispatch(
      ParticipantSearchConfigurationActions.getParticipantSearchConfiguration(),
    );
    this.searchStore.dispatch(HeaderActions.clearSearchList());
    this.valueSearch = '';

    // Just only need update fundKey once
    //this.activeFund.updateMetadata();
    //this.activeFund.updateNavigation();
    this.store.dispatch(
      FundListActions.updateFundLayout({
        updateLayout: { metadataUpdated: new Date().toUTCString() },
      }),
    );
    this.store.dispatch(
      FundListActions.updateFundLayout({
        updateLayout: { navigationUpdated: new Date().toUTCString() },
      }),
    );
    const currentFund = this.listFund.find(
      (el: any) => el.name === tenant,
    ) as FundModel;
    this.store.dispatch(
      FundListActions.selectFund(getCurrentFundData(currentFund)),
    );
    this.redirectAfterChangeFund();

    //clear Transaction Register Filter
    this.processingStore.dispatch(
      RegisterActions.setRegisterFilter({ filters: [] }),
    );
  }

  private redirectAfterChangeFund() {
    const url = this.router.url;
    let absPath = url.split('/')[1];
    switch (absPath) {
      case 'access-management':
        const redirectUrl = this.authService.isAdminPortal$.value
          ? '/access-management/account'
          : '/access-management/roles';
        this.navigateByUrlCustom(redirectUrl);
        break;

      case 'page':
        this.navigateByUrlCustom('/fund-management/navigation');
        break;

      case 'fund-management': {
        let page = url.split('/')[2];
        switch (page) {
          case 'navigation':
          case 'preferences':
          case 'app-content':
            this.navigateByUrlCustom('/fund-management/navigation');
            break;

          case 'resource-center':
            this.navigateByUrlCustom('/fund-management/resource-center');
            break;

          case 'historical_data':
            this.navigateByUrlCustom(
              '/fund-management/historical-data/payroll-service-record',
            );
            break;

          case 'notification':
            this.navigateByUrlCustom('/fund-management/notification');
            break;

          case 'member-metadata':
          case 'theme':
          default:
            this.navigateByUrlCustom('/fund-management/theme');
            break;
        }
        break;
      }

      case 'organization':
        this.navigateByUrlCustom('/organization/board-management');
        break;
      case 'estimator':
        this.navigateByUrlCustom('/estimator');
        break;
      case 'processing': {
        let page = url.split('/')[2];
        switch (page) {
          case 'off-cycle-payments':
          case 'lump-sum-payment-history':
            this.navigateByUrlCustom('/processing/off-cycle-payments');
            break;
          case 'report':
            this.navigateByUrlCustom('/processing/report/register-summary');
            break;
          case 'yearly-processing':
          case 'annual1099':
            this.navigateByUrlCustom('/processing/yearly-processing');
            break;

          case 'next-payroll':
          case 'pension-payroll':
          case 'next-payment':
          case 'payment-history':
          case 'register':
          case 'service-provider':
          case 'payment-deductions':
          default:
            this.navigateByUrlCustom('/processing/next-payroll');
            break;
        }
        break;
      }

      case 'member': {
        let page = url.split('/')[2];
        switch (page) {
          case 'bulk':
            this.navigateByUrlCustom('/member/bulk/mixed');
            break;

          case 'benefit':
            this.navigateByUrlCustom('/member/benefit');
            break;

          case 'report':
            this.navigateByUrlCustom('/member/report/generated');
            break;

          case 'navigation':
          case 'detail':
          default:
            this.navigateByUrlCustom('/member');
            break;
        }
        break;
      }
      case 'employer':
        let page = url.split('/')[2];
        switch (page) {
          case '':
            this.navigateByUrlCustom('tolerance/parameter');
            break;
          default:
            this.navigateByUrlCustom('/employer/employer-management');
            break;
        }
    }
  }

  private navigateByUrlCustom(urlRedirect: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlRedirect]);
    });
  }

  controlHeader() {
    this.showHeader = !this.showHeader;
    if (this.showHeader) {
      this.renderer.addClass(document.body, 'header-move-down');
      this.renderer.removeClass(document.body, 'header-move-up');
    } else {
      this.renderer.addClass(document.body, 'header-move-up');
      this.renderer.removeClass(document.body, 'header-move-down');
    }
  }

  getColumnValue(searchResult: SearchResult, index: number) {
    const currentMember = searchResult.members[index];
    return searchResult.columns.reduce((result, currentColumn) => {
      const columnKey = currentColumn.columnKey;
      let value = '';
      if (!currentColumn.listSubField.length) {
        value = currentMember[columnKey];
        if (value && currentColumn.type === PropertyType.TYPE_DATE_TIME) {
          value = DateTime.fromISO(value)
            .toLocal()
            .toFormat('MM/dd/yyyy hh:mm a');
        }
      } else {
        value = currentColumn.listSubField.reduce((fieldValue, currentItem) => {
          let currentSubItemValue = currentMember[currentItem.key];
          switch (currentColumn.type) {
            case PropertyType.TYPE_STATUS:
            case PropertyType.TYPE_PERSON_NAME: {
              if (currentItem.key === 'Fixed_status_Date') {
                currentSubItemValue =
                  DateTime.fromISO(currentSubItemValue).toFormat('MM/dd/yyyy');
              }
              if (fieldValue === null) {
                fieldValue = '';
              }
              fieldValue += `${
                !fieldValue || !currentSubItemValue ? '' : ', '
              }${currentSubItemValue || ''}`;
              break;
            }
            case PropertyType.TYPE_ADDRESS: {
              if (fieldValue === null) {
                fieldValue = {};
              }
              fieldValue[
                currentItem.option.charAt(0).toLowerCase() +
                  currentItem.option.substring(1)
              ] = currentSubItemValue;
            }
          }
          return fieldValue;
        }, null as any);
      }
      let type = deepClone(currentColumn.type);
      if (type === PropertyType.TYPE_PERSON_NAME) {
        type = PropertyTypeLabel.Text;
      }

      let configValue = deepClone(currentColumn.configValue);
      result[currentColumn.columnName] = {
        value,
        type,
        typedValue: currentMember._typedValue,
        configValue,
      };
      return result;
    }, {} as any);
  }

  checkParticipantSearchConfig() {
    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchPropertyDisplayConfigurations),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((propertyDisplayConfigurations) => {
        this.hasConfig = (
          deepClone(
            propertyDisplayConfigurations,
          ) as PropertyDisplayConfiguration[]
        ).some((config) => config.orderRow !== null);
      });

    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((currentFund) => {
        if (currentFund?.id) {
          this.memberStore.dispatch(
            ParticipantSearchConfigurationActions.getParticipantSearchConfiguration(),
          );
        }
      });
  }
}
