import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { PaymentInfoNoteType } from '@ptg-shared/constance/value.const';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import { OffCyclePayment } from '../../types/models';
import * as NextPaymentActions from '../../actions/next-payment.actions';

@Component({
  selector: 'ptg-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent implements OnInit {
  readonly PaymentInfoNoteTypeEnum = PaymentInfoNoteType;

  formGroup: FormGroup;
  offCyclePayment?: OffCyclePayment;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<AddNoteComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      offCyclePayment: OffCyclePayment;
      paymentSourceId: string;
      noteType: number;
      memoDetails: any;
      isBackNextPayroll: boolean;
    }
  ) {
    this.offCyclePayment = data.offCyclePayment;
    this.formGroup = new FormGroup({
      note: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.data?.memoDetails) {
      this.formGroup.setValue({ note: this.data.memoDetails.note });
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      let data = this.formGroup.getRawValue();
      if (this.data?.memoDetails) {
        data.id = this.data.memoDetails.id;
        this.store.dispatch(NextPaymentActions.updateMemo({ body: data }));
      } else {
        data.type = this.offCyclePayment?.paymentInfoType;
        data.noteType = this.data.noteType;
        if (this.data.paymentSourceId && this.data.paymentSourceId !== 'null') {
          data.paymentSourceId = this.data.paymentSourceId;
        }
        this.store.dispatch(
          NextPaymentActions.addNote({
            body: data,
            targetId: this.offCyclePayment?.id,
          })
        );
      }
      this.dialogRef.close(true);
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
