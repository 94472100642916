export enum DisplayValueType {
  Normal,
  Chip,
  Icon
}

export enum DisplayValueLocationType {
  Processing,
  Information,
  Deduction
}

export enum Annual1099StatusType {
  Review,
  Issued,
  Finalized
}