import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { iconUploadAttachment } from '@ptg-shared/constance/listIcons.const';
import {
  ACTION_COLUMN,
  Column,
  ColumnType,
  Row,
} from '@ptg-shared/controls/grid';

import { MemberNavigationAttachmentList } from '../../types/models';

@Component({
  selector: 'ptg-member-navigation-attachment-list',
  templateUrl: './member-navigation-attachment-list.component.html',
  styleUrls: ['./member-navigation-attachment-list.component.scss'],
})
export class MemberNavigationAttachmentListComponent {
  @Input() memberAttachmentListsData: (MemberNavigationAttachmentList & Row)[] =
    [];
  @Input() sortInfo: any = {};
  @Input() lengthPg!: number | any;
  @Input() pageSize: number = 7;
  @Input() errorMsg?: string;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() isLoading!: boolean;
  @Input() currentRowIndex: number = 0;

  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter<PageEvent>();
  @Output() upLoadFileEvent = new EventEmitter();
  @Output() editMetadataAttachmentEvent = new EventEmitter();
  @Output() removeMetaDataAttachmentEvent = new EventEmitter();
  @Output() openPDFEvent = new EventEmitter();

  columns: Column[] = [
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'dateCreated',
      header: {
        title: 'Date Posted',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      truncate: true,
      sortable: true,
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'attachment',
      header: {
        title: 'Attachment',
      },
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Actions',
      },
      width: '230px',
    },
  ];
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      'upload-attachment-icon',
      sanitizer.bypassSecurityTrustHtml(iconUploadAttachment)
    );
  }

  sortChange(event: any) {
    this.sortChangeEvent.emit(event);
  }

  changePagging(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }

  upLoadAttachment() {
    this.upLoadFileEvent.emit();
  }

  editAttachment(event: MemberNavigationAttachmentList & Row) {
    this.editMetadataAttachmentEvent.emit(event);
  }

  removeAttachment(event: MemberNavigationAttachmentList & Row) {
    this.removeMetaDataAttachmentEvent.emit(event);
  }

  openPDF(event: MemberNavigationAttachmentList & Row) {
    this.openPDFEvent.emit(event.fileSnapshot);
  }
}
