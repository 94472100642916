<div class="member-navigation-container">
  <ptg-breadcrumb
    *ngIf="
      (currentSectionLayout !== undefined &&
        currentSectionLayout !== SectionLayout.Payment &&
        currentSectionLayout !== SectionLayout.PaymentHistory) ||
      !historyService.isBackNextPayroll
    "
    [listBreadcrumbs]="listBreadcrumbs"
    [settings]="settings"
    [isDisabledGear]="isDisabledGear"
    (onSelectSetting)="onSelectSetting($event)"
  ></ptg-breadcrumb>

  <ng-container *ngIf="currentSectionLayout !== undefined; else spinner">
    <ng-container [ngSwitch]="currentSectionLayout">
      <ng-container *ngSwitchCase="SectionLayout.StatusHistory">
        <ptg-status-history></ptg-status-history>
      </ng-container>

      <ng-container *ngSwitchCase="SectionLayout.MunicipalityPayment">
        <ptg-municipality-payment
          [currentPageName]="listBreadcrumbs[0].name"
        ></ptg-municipality-payment>
      </ng-container>

      <ng-container *ngSwitchCase="SectionLayout.MunicipalityServiceRecord">
        <ptg-municipality-service-history
          [currentPageName]="listBreadcrumbs[0].name"
        ></ptg-municipality-service-history>
      </ng-container>

      <ng-container *ngSwitchCase="SectionLayout.Payment">
        <ptg-next-payment></ptg-next-payment>
      </ng-container>

      <ng-container *ngSwitchCase="SectionLayout.PaymentHistory">
        <ptg-payment-history-screen></ptg-payment-history-screen>
      </ng-container>

      <ng-container *ngSwitchCase="SectionLayout.ParticipantRelationship">
        <ptg-participant-relationship></ptg-participant-relationship>
      </ng-container>

      <ng-container *ngSwitchCase="SectionLayout.Info1099">
        <ptg-info-1099></ptg-info-1099>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ptg-default-section
          [currentSectionLayout]="currentSectionLayout"
          [memberId]="memberId"
          [menuId]="menuId"
          [itemKey]="itemKey"
          [menuItemName]="menuItemName"
          [isList]="isList"
        ></ptg-default-section>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #spinner>
    <div class="member-navigation-content">
      <ptg-spinner [isLoading]="true" class="m-auto"></ptg-spinner>
    </div>
  </ng-template>
</div>
