<div class="establish-benefit">
  <div class="title">
    <div class="title-name">
      Establish Benefit
    </div>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" style="background-color: #408bf9;color: #fff;">Create</button>
      <button mat-raised-button type="button" style="background-color: #acabab;color: #fff" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-field">
      <ptg-select
        class="w776"
        placeholder="Benefit Type"
        [controlField]="formGroup.get('benefitType')"
        [listData]="optionsBenefitType"
        [isOptionObj]="true"
        [isRequired]="true"
        (changeOptionValue)="onChangeBenefitType()"
        [errorAsync]="'Please configure Participant Search to select Parent Participant.'"
      ></ptg-select>
        <div class="search-member" *ngIf="selectedBenefitType?.parentTypes?.length">
        <mat-form-field class="example-form-field" appearance="fill" >
          <mat-label>Parent Participant</mat-label>
          <mat-icon svgIcon="personSearch" aria-hidden="false" matPrefix></mat-icon>
          <input matInput type="text" formControlName="participant" (input)="searchMember()" [matAutocomplete]="auto" #participantSearchTrigger="matAutocompleteTrigger">
          <button *ngIf="disableParticipant" (click)="removeParticipant()" mat-icon-button aria-label="close icon" class="x-button">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="formGroup.get('participant')?.errors?.required">Parent Participant is required.</mat-error>
          <mat-autocomplete #auto="matAutocomplete" class="participant-establish-search-panel" panelWidth="auto" [disableRipple]="true">
            <mat-option [disabled]="true" *ngIf="!isLoading && searchResultList?.length && formGroup.get('participant')?.value?.length > 1">
              <table mat-table [dataSource]="searchResultList">
                <ng-container [matColumnDef]="column" *ngFor="let column of searchResultColumns">
                  <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                  <td mat-cell *matCellDef="let element" ptgSearchBold>
                    <ng-container [ngTemplateOutletContext]="{ item: element[column] }"
                                  [ngTemplateOutlet]="displayValueTemplate"></ng-container>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="searchResultColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: searchResultColumns;" (click)="selectMember(row)"></tr>
              </table>
            </mat-option>
            <mat-option class="message-option" *ngIf="!searchResultList?.length && formGroup.get('participant')?.value?.length > 1">
              {{ !isLoading ? (isError ? 'Error occurs while searching.' : 'No Participants found.') : 'Searching...' }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="!!this.selectedBenefitType?.parentTypes && !this.hasConfig" class="error-message-no-search-config" >
          Please configure Participant Search to select Parent Participant.
        </mat-error>
      </div>
      <div class="line" *ngIf="lstFormControl.length > 0"></div>

    </div>
    <div class="properties">
      <ng-container *ngFor="let f of lstFormControl">
        <ng-container [ngSwitch]="f.type">

          <ng-container *ngSwitchCase="PropertyType.TYPE_CURRENCY">
            <ptg-textbox
              [controlField]="f.formControl"
              [maxLength]="f.maxLength"
              [placeholder]="f.label"
              [hasLabel]="true"
              class="w776"
              [min]="f.min"
              [max]="f.max"
              [isRequired]="f.isRequired"
              inputType="Currency"
              [errorAsync]="f.label + ' already exists.'"
            >
            </ptg-textbox>
            <br/>
          </ng-container>

          <ng-container *ngSwitchCase="[PropertyType.TYPE_TEXT, PropertyType.TYPE_EMAIL, PropertyType.TYPE_PHONE, PropertyType.TYPE_SSN].includes(f.type) ? f.type : ''">
            <ptg-textbox
              [controlField]="f.formControl"
              [maxLength]="f.maxLength"
              [placeholder]="f.label"
              [isRequired]="f.isRequired"
              [hasLabel]="true"
              [isShowIconMasked]="f?.config?.masked == 'true'"
              class="w776"
              [inputType]="f.type"
              [errorAsync]="f.label + ' already exists.'">
            </ptg-textbox>
            <br/>
          </ng-container>

          <ng-container *ngSwitchCase="[PropertyType.TYPE_WHOLE_NUMBER, PropertyType.TYPE_DECIMAL].includes(f.type) ? f.type : ''">
            <ptg-textbox
              [controlField]="f.formControl"
              [placeholder]="f.label"
              [hasLabel]="true"
              [mask]="(f.type === PropertyType.TYPE_WHOLE_NUMBER ? 'separator.0' : f.fractionalLength ? 'separator.' + f.fractionalLength : 'separator')"
              [thousandSeparator]="','"
              [min]="f.min"
              [max]="f.max"
              class="w776"
              inputType="Decimal"
              [isRequired]="f.isRequired"
              [errorAsync]="f.label + ' already exists.'">
            </ptg-textbox>
            <br/>
          </ng-container>

          <ng-container *ngSwitchCase="PropertyType.TYPE_DATE">
            <ptg-datepicker
              [controlField]="f.formControl"
              [placeholder]="f.label"
              class="w776"
              [isRequired]="f.isRequired"
              [errorAsync]="f.label + ' already exists.'">
            </ptg-datepicker>
          </ng-container>

          <ng-container *ngSwitchCase="[PropertyType.TYPE_ADDRESS, PropertyType.TYPE_PERSON_NAME].includes(f.type) ? f.type : ''">
            <ng-container *ngFor="let childForm of f.lstChildFormControl; let i = index;">
              <ng-container *ngIf="childForm.type === 'select'">
                <ptg-select
                  [controlField]="childForm.formControl"
                  [placeholder]="childForm.label"
                  [listData]="childForm.lstOption || []"
                  [isOptionObj]="true"
                  [class]="childForm.className"
                  [hasNoneValue]="!f.isRequired || !childForm.required"
                  [isRequired]="f.isRequired && childForm.required">
                </ptg-select>
              </ng-container>
              <ng-container *ngIf="childForm.type === 'text'">
                <ptg-textbox
                  [controlField]="childForm.formControl"
                  [maxLength]="childForm.maxLength"
                  [placeholder]="childForm.label"
                  [isRequired]="f.isRequired && childForm.required"
                  [hasLabel]="true"
                  [class]="childForm.className">
                </ptg-textbox>
              </ng-container>
              <ng-container *ngIf="childForm.type === 'number'">
                <ptg-textbox
                  [controlField]="childForm.formControl"
                  [maxLength]="childForm.maxLength"
                  [placeholder]="childForm.label"
                  [hasLabel]="true"
                  [mask]="'0'.repeat(childForm.maxLength)"
                  [isAutoLeadingZeros]="true"
                  [isValidation]="false"
                  [class]="childForm.className"
                  [isRequired]="f.isRequired && childForm.required"
                  inputType="Decimal">
                </ptg-textbox>
              </ng-container>
              <ng-container *ngIf="(f.type === PropertyType.TYPE_ADDRESS && (i === 1 || i === 5)) || (f.type === PropertyType.TYPE_PERSON_NAME && i === 4)">
                <br/>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="[PropertyType.TYPE_LIST, PropertyType.TYPE_EMPLOYER, PropertyType.TYPE_DEPARTMENT].includes(f.type) ? f.type : ''">
            <ptg-select
              [controlField]="f.formControl"
              [placeholder]="f.label"
              [listData]="f.lstOption"
              [isOptionObj]="true"
              class="w776"
              [isSetMaxWidthSelect]="true"
              [hasNoneValue]="!f.isRequired"
              [isRequired]="f.isRequired"
              [isMultipleLineOption]="f.type === PropertyType.TYPE_DEPARTMENT">
            </ptg-select>
            <br/>
          </ng-container>

          <ng-container *ngSwitchCase="PropertyType.TYPE_BOOLEAN">
            <ptg-checkbox
              [controlField]="f.formControl"
              [label]="f.label"
              class="w776"
            ></ptg-checkbox>
          </ng-container>

          <ng-container *ngSwitchCase="PropertyType.TYPE_PERCENTAGE">
            <ptg-textbox
              [controlField]="f.formControl"
              [placeholder]="f.label"
              [hasLabel]="true"
              [mask]="(f.type === PropertyType.TYPE_WHOLE_NUMBER ? 'separator.0' : f.fractionalLength ? 'separator.' + f.fractionalLength : 'separator')"
              [thousandSeparator]="','"
              [min]="f.min"
              [max]="f.max"
              [isPercentage]="true"
              inputType="Decimal"
              class="w776"
              [isRequired]="f.isRequired"
              [errorAsync]="f.label + ' already exists.'"
            >
            </ptg-textbox>
            <br/>
          </ng-container>

          <ng-container *ngSwitchCase="PropertyType.TYPE_RICH_TEXT">
            <ptg-editor class="w776"
                        [controlField]="f.formControl"
                        [placeholder]="f.label"
                        [isRequired]="f.isRequired"
                        [maxLength]="f.maxLength">
            </ptg-editor>
            <br/>
          </ng-container>

          <ng-container *ngSwitchCase="PropertyType.TYPE_DATE_TIME">
            <ptg-datetime-picker class="w776-datetime-picker"
                                 [controlField]="f.formControl"
                                 [isRequired]="f.isRequired">
            </ptg-datetime-picker>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ptg-textbox
              [controlField]="f.formControl"
              [maxLength]="f.maxLength"
              [placeholder]="f.label"
              [hasLabel]="true"
              class="w776"
              [isRequired]="f.isRequired"
              [inputType]="f.type"
              [errorAsync]="f.label + ' already exists.'">
            </ptg-textbox>
            <br/>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
  <ng-template let-item="item" #displayValueTemplate>
    <ng-container [ngSwitch]="item?.type">
      <span class="value" *ngSwitchCase="'Currency'">{{ (item.value | numberLocalDecimal:'$') }}</span>
      <span class="value" *ngSwitchCase="'Percentage'">{{ (item.value | numberLocalDecimal:'') }}{{ (item.value | numberLocalDecimal:'') ? '%' : '' }}</span>
      <span class="value" *ngSwitchCase="'Date'">{{ item.value | date: 'MM/dd/yyyy' }}</span>
      <span class="value" *ngSwitchCase="'Person_Name'">{{ item.value | personName : item?.typedValue }}</span>
      <span class="value" *ngSwitchCase="'Address'">{{ item.value | address }}</span>
      <span class="value" *ngSwitchCase="'Decimal'">{{ item.value | numberDecimal:{decimal: item?.typedValue?.fractionalLengthInput, isCheckCurrentDecimal: true} }}</span>
      <span class="value" *ngSwitchCase="'Whole_Number'">{{ (item.value | numberDecimal) }}</span>
      <span class="value" *ngSwitchCase="'Employer'">{{ item.value }}</span>
      <span class="status-container" *ngSwitchCase="'Status'">
        <div class="icon-status-container">
          <mat-icon *ngIf="item?.value && item?.typedValue?.iconName" class="icon-status" [ngStyle]="{'color': item?.typedValue?.color}">{{ item?.typedValue?.iconName }}</mat-icon>
          <div class="value">{{ item.value }}</div>
        </div>
      </span>
      <span class="value" *ngSwitchCase="'SSN'">{{ (item.value | ssnNumber) }}</span>
      <ng-container *ngSwitchCase="'RichText'">
        <ptg-view-rich-text [title]="item?.columnName" [content]="item.value" [defaultContent]="'-'"></ptg-view-rich-text>
      </ng-container>
      <span class="value" *ngSwitchCase="'DateTime'">{{ item?.value | date: 'MM/dd/yyyy hh:mm a' }}</span>
      <span class="value" *ngSwitchCase="'Phone'">{{ item?.value | phoneNumber }}</span>
      <span class="value" *ngSwitchDefault>{{ item.value }}</span>
    </ng-container>
  </ng-template>
</div>
