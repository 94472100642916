import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/models';
import { STATE } from '@ptg-shared/constance/value.const';

import {
  ProfileOverviewDetail,
  ProfileOverviewList,
  SectionMetadata,
  Status,
} from '../types/models';
import * as ProfileOverviewConfigurationAction from '../actions/profile-overview-configuration.actions';

export const profileOverviewConfigurationFeatureKey =
  'profileOverviewConfiguration';

export interface State {
  isLoading: boolean;
  getSectionMetadatas?: BaseActionState<SectionMetadata[]>;
  getProfileOverviewDetail?: BaseActionState<ProfileOverviewDetail>;
  setProfileOverview?: BaseActionState<string>;
  profileOverviewList: BaseActionState<ProfileOverviewList>;
  getNotUsedStatuses?: BaseActionState<Status[]>;
  deleteState: string;
  id: string;
  success?: boolean;
  error?: any;
}

const initialState: State = {
  isLoading: true,
  profileOverviewList: {
    isLoading: true,
  },
  deleteState: '',
  id: '',
};

export const reducer = createReducer(
  initialState,
  on(
    ProfileOverviewConfigurationAction.clearProfileOverviewConfigurationState,
    (state) => ({
      ...state,
      setProfileOverview: undefined,
      deleteState: '',
      id: '',
    })
  ),

  on(ProfileOverviewConfigurationAction.getSectionMetadatas, (state) => ({
    ...state,
    getSectionMetadatas: {
      isLoading: true,
    },
  })),
  on(
    ProfileOverviewConfigurationAction.getSectionMetadatasSuccess,
    (state, { response }) => ({
      ...state,
      getSectionMetadatas: {
        isLoading: false,
        success: true,
        payload: response.sectionMetadata,
      },
    })
  ),
  on(
    ProfileOverviewConfigurationAction.getSectionMetadatasFailure,
    (state, { error }) => ({
      ...state,
      getSectionMetadatas: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),

  on(
    ProfileOverviewConfigurationAction.getProfileOverviewConfiguration,
    (state) => ({
      ...state,
      isLoading: false,
      getProfileOverviewDetail: {
        isLoading: true,
      },
    })
  ),
  on(
    ProfileOverviewConfigurationAction.getProfileOverviewConfigurationSuccess,
    (state, { response }) => ({
      ...state,
      isLoading: false,
      getProfileOverviewDetail: {
        isLoading: false,
        success: true,
        payload: response,
      },
    })
  ),
  on(
    ProfileOverviewConfigurationAction.getProfileOverviewConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      getProfileOverviewDetail: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),

  on(ProfileOverviewConfigurationAction.setProfileOverview, (state) => ({
    ...state,
    setProfileOverview: {
      isLoading: true,
    },
  })),
  on(
    ProfileOverviewConfigurationAction.setProfileOverviewSuccess,
    (state, { id }) => ({
      ...state,
      setProfileOverview: {
        isLoading: false,
        success: true,
        payload: id,
      },
    })
  ),
  on(
    ProfileOverviewConfigurationAction.ssetProfileOverviewFailure,
    (state, { error }) => ({
      ...state,
      setProfileOverview: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),

  on(
    ProfileOverviewConfigurationAction.getListProfileOverviewConfiguration,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    ProfileOverviewConfigurationAction.getListProfileOverviewConfigurationSuccess,
    (state, { profileOverviewList }) => ({
      ...state,
      isLoading: false,
      profileOverviewList: {
        isLoading: false,
        success: true,
        payload: profileOverviewList,
        error: undefined,
      },
      success: true,
      error: undefined,
    })
  ),
  on(
    ProfileOverviewConfigurationAction.getListProfileOverviewConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      profileOverviewList: {
        isLoading: false,
        success: false,
        error: error,
      },
      success: false,
      error: error,
    })
  ),
  on(
    ProfileOverviewConfigurationAction.clearProfileOverviewConfigurationDetail,
    (state) => ({
      ...state,
      getProfileOverviewDetail: undefined,
    })
  ),

  on(ProfileOverviewConfigurationAction.getNotUsedStatuses, (state) => ({
    ...state,
    getNotUsedStatuses: {
      isLoading: true,
    },
  })),
  on(
    ProfileOverviewConfigurationAction.getNotUsedStatusesSuccess,
    (state, { response }) => ({
      ...state,
      getNotUsedStatuses: {
        isLoading: false,
        success: true,
        payload: response.listStatusUsing,
      },
    })
  ),
  on(
    ProfileOverviewConfigurationAction.getNotUsedStatusesFailure,
    (state, { error }) => ({
      ...state,
      getNotUsedStatuses: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),

  on(
    ProfileOverviewConfigurationAction.deleteProfileOverviewConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      deleteState: STATE.SUCCESS,
    })
  ),
  on(
    ProfileOverviewConfigurationAction.deleteProfileOverviewConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      getProfileOverviewDetail: undefined,
      deleteState: STATE.FAIL,
    })
  )
);
