import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import * as fromRoot from '@ptg-reducers';

import * as fromMemberList from './member-list.reducer';
import * as fromMemberBenefitList from './member-benefit.reducer';
import * as fromMetadata from './metadata.reducer';
import * as fromMetadataSection from './metadata-detail.reducer';
import * as fromNewProperty from './property.reducer';
import * as fromNexPayment from './next-payment.reducer';
import * as fromLookupTable from './lookup-table.reducer';
import * as fromStatus from './status.reducer';
import * as fromProfileOverviewConfiguration from './profile-overview-configuration.reducer';
import * as fromMemberNavigation from './member-navigation.reducer';
import * as fromMemberListConfiguration from './member-list-configuration.reducer';
import * as fromProfileNavigationConfiguration from './profile-navigation-configuration.reducer';
import * as fromAggregationConfig from './aggregation-config.reducer';
import * as fromStatusHistory from './status-history.reducer';
import * as fromMunicipalityPayment from './municipality-payment.reducer';
import * as fromMunicipalityServiceRecord from './municipality-service-history.reducer';
import * as fromAggregationGroupFeatureKey from './aggregation-group.reducer';
import * as fromMemberPropertyFeatureKey from './member-property.reducer';
import * as fromParticipantSearchConfiguration from './participant-search-configuration.reducer';
import * as fromParticipantRelationship from './participant-relationship.reducer';
import * as fromMemberNotificationOptIn from './member-notification-opt-in.reducer';
import * as fromNonListConfigurations from './non-list-configuration.reducer';
import * as fromDefinedBenefits from './defined-benefits.reducer';
import * as fromReport from './report.reducer';
import * as fromPaymentListConfigurations from './payment-list-configuration.reducer';
import * as fromTierConfigurations from './tier-configuration.reducer';
import * as fromCalculations from './calculation.reducer';
import * as fromParticipantReport from './participant-report.reducer';
import * as fromDropPlan from './drop-plan.reducer';
import {
  ProfileHeaderConfigState,
  profileHeaderConfigSReducer,
} from './profile-header-configuration.reducer';
import {
  MemberDetailState,
  memberDetailReducer,
} from './member-detail.reducer';
import {
  AggregationGroupState,
  aggregationGroupReducer,
} from './aggregation-group.reducer';
import * as fromInfo1099 from './info-1099.reducer'; 

export const memberModuleFeatureKey = 'memberModule';

export interface MemberState {
  [fromMemberList.memberListFeatureKey]: fromMemberList.State;
  [fromMemberBenefitList.memberBenefitListFeatureKey]: fromMemberBenefitList.State;
  [fromDefinedBenefits.definedBenefitsFeatureKey]: fromDefinedBenefits.State;
  [fromReport.reportFeatureKey]: fromReport.State;
  memberDetail: MemberDetailState;
  [fromMetadata.metadataFeatureKey]: fromMetadata.State;
  [fromMetadataSection.metadataSectionFeatureKey]: fromMetadataSection.State;
  [fromNewProperty.AddPropertyFeatureKey]: fromNewProperty.State;
  [fromNexPayment.nextPaymentFeatureKey]: fromNexPayment.State;
  [fromLookupTable.lookupTableFeatureKey]: fromLookupTable.State;
  [fromStatus.memberStatusFeatureKey]: fromStatus.State;
  [fromProfileOverviewConfiguration.profileOverviewConfigurationFeatureKey]: fromProfileOverviewConfiguration.State;
  profileHeaderConfig: ProfileHeaderConfigState;
  [fromMemberNavigation.memberNavigationFeatureKey]: fromMemberNavigation.State;
  [fromMemberListConfiguration.memberListConfigurationFeatureKey]: fromMemberListConfiguration.State;
  [fromProfileNavigationConfiguration.profileNavigationConfigurationFeatureKey]: fromProfileNavigationConfiguration.State;
  [fromAggregationConfig.aggregationConfigFeatureKey]: fromAggregationConfig.State;
  [fromStatusHistory.StatusHistoryFeatureKey]: fromStatusHistory.State;
  [fromMunicipalityPayment.MunicipalityPaymentFeatureKey]: fromMunicipalityPayment.State;
  [fromMunicipalityServiceRecord.MunicipalityServiceHistoryFeatureKey]: fromMunicipalityServiceRecord.State;
  aggregationGroup: AggregationGroupState;
  [fromMemberPropertyFeatureKey.memberPropertyFeatureKey]: fromMemberPropertyFeatureKey.State;
  [fromMemberPropertyFeatureKey.memberPropertyFeatureKey]: fromMemberPropertyFeatureKey.State;
  [fromParticipantSearchConfiguration.participantSearchConfigurationFeatureKey]: fromParticipantSearchConfiguration.State;
  [fromParticipantRelationship.participantRelationshipFeatureKey]: fromParticipantRelationship.State;
  [fromMemberNotificationOptIn.memberNotificationOptInFeatureKey]: fromMemberNotificationOptIn.State;
  [fromPaymentListConfigurations.paymentListConfigurationFeatureKey]: fromPaymentListConfigurations.State;
  [fromNonListConfigurations.nonListConfigurationFeatureKey]: fromNonListConfigurations.State;
  [fromTierConfigurations.tierConfigurationFeatureKey]: fromTierConfigurations.State;
  [fromCalculations.calculationFeatureKey]: fromCalculations.State;
  [fromParticipantReport.participantReportFeatureKey]: fromParticipantReport.State;
  [fromDropPlan.dropPlanFeatureKey]: fromDropPlan.State;
  [fromInfo1099.info1099FeatureKey]: fromInfo1099.State;
}

export interface State extends fromRoot.State {
  [memberModuleFeatureKey]: MemberState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: MemberState | undefined, action: Action) {
  return combineReducers({
    [fromMemberList.memberListFeatureKey]: fromMemberList.reducer,
    [fromMemberBenefitList.memberBenefitListFeatureKey]:
      fromMemberBenefitList.reducer,
    [fromDefinedBenefits.definedBenefitsFeatureKey]:
      fromDefinedBenefits.reducer,
    [fromReport.reportFeatureKey]: fromReport.reducer,
    memberDetail: memberDetailReducer,
    [fromMetadata.metadataFeatureKey]: fromMetadata.reducer,
    [fromMetadataSection.metadataSectionFeatureKey]:
      fromMetadataSection.reducer,
    [fromNewProperty.AddPropertyFeatureKey]: fromNewProperty.reducer,
    [fromNexPayment.nextPaymentFeatureKey]: fromNexPayment.reducer,
    [fromLookupTable.lookupTableFeatureKey]: fromLookupTable.reducer,
    [fromStatus.memberStatusFeatureKey]: fromStatus.reducer,
    [fromProfileOverviewConfiguration.profileOverviewConfigurationFeatureKey]:
      fromProfileOverviewConfiguration.reducer,
    profileHeaderConfig: profileHeaderConfigSReducer,
    [fromMemberNavigation.memberNavigationFeatureKey]:
      fromMemberNavigation.reducer,
    [fromMemberListConfiguration.memberListConfigurationFeatureKey]:
      fromMemberListConfiguration.reducer,
    [fromProfileNavigationConfiguration.profileNavigationConfigurationFeatureKey]:
      fromProfileNavigationConfiguration.reducer,
    [fromAggregationConfig.aggregationConfigFeatureKey]:
      fromAggregationConfig.reducer,
    [fromStatusHistory.StatusHistoryFeatureKey]: fromStatusHistory.reducer,
    [fromMunicipalityPayment.MunicipalityPaymentFeatureKey]:
      fromMunicipalityPayment.reducer,
    [fromMunicipalityServiceRecord.MunicipalityServiceHistoryFeatureKey]:
      fromMunicipalityServiceRecord.reducer,
    aggregationGroup: aggregationGroupReducer,
    [fromMemberPropertyFeatureKey.memberPropertyFeatureKey]:
      fromMemberPropertyFeatureKey.reducer,
    [fromParticipantSearchConfiguration.participantSearchConfigurationFeatureKey]:
      fromParticipantSearchConfiguration.reducer,
    [fromParticipantRelationship.participantRelationshipFeatureKey]:
      fromParticipantRelationship.reducer,
    [fromMemberNotificationOptIn.memberNotificationOptInFeatureKey]:
      fromMemberNotificationOptIn.reducer,
    [fromPaymentListConfigurations.paymentListConfigurationFeatureKey]:
      fromPaymentListConfigurations.reducer,
    [fromNonListConfigurations.nonListConfigurationFeatureKey]:
      fromNonListConfigurations.reducer,
    [fromTierConfigurations.tierConfigurationFeatureKey]:
      fromTierConfigurations.reducer,
    [fromCalculations.calculationFeatureKey]: fromCalculations.reducer,
    [fromParticipantReport.participantReportFeatureKey]:
      fromParticipantReport.reducer,
    [fromDropPlan.dropPlanFeatureKey]: fromDropPlan.reducer,
    [fromInfo1099.info1099FeatureKey]: fromInfo1099.reducer,
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectMemberModuleState = createFeatureSelector<MemberState>(
  memberModuleFeatureKey
);

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */
export const selectMemberListState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberList.memberListFeatureKey]
);

export const selectMemberBenefitListState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberBenefitList.memberBenefitListFeatureKey]
);
export const selectMemberEstablishBenefitState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromMemberBenefitList.memberBenefitListFeatureKey].establishBenefit
);

//#region Defined Benefits
export const selectDefinedBenefitsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey]
);
export const selectGetAuditTrailsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromStatusHistory.StatusHistoryFeatureKey].getAuditTrails
);
export const selectGetBenefitTypesState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getBenefitTypes
);
export const selectGetAllBenefitTypesState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getAllBenefitTypes
);
export const selectGetBenefitState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getBenefit
);
export const selectGetStatusAndEventsState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getStatusAndEvents
);
export const selectGetBenefitRecordsState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getBenefitRecords
);
export const selecGetLookupTableOptionsState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getLookupTableOptions
);
export const selecGetLookupTablesState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getLookupTables
);
export const selectGetQDROBenefitTypesState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getQDROBenefitTypes
);
export const selectGetQDROMetadatasState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getQDROMetadatas
);
export const selectGetLookupTablesState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].getLookupTables
);
export const selectAddBenefitTypeState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].addBenefitType
);
export const selectUpdateBenefitTypeState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].updateBenefitType
);
export const selectUpdateBenefitState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].updateBenefit
);
export const selectRemoveBenefitTypeState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromDefinedBenefits.definedBenefitsFeatureKey].removeBenefitType
);
//#endregion
//#region Report
export const selectGetReportCategoriesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromReport.reportFeatureKey].getReportCategories
);
export const selectUpdateReportCategoriesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromReport.reportFeatureKey].updateReportCategories
);

//#endregion
export const selectMemberDetailState = createSelector(
  selectMemberModuleState,
  (state) => state.memberDetail
);

export const getMemberDetailWithPagingSelector = createSelector(
  selectMemberDetailState,
  (state) => state.getMemberDetailWithPaging
);
export const getMemberAttachmentsSelector = createSelector(
  selectMemberDetailState,
  (state) => state.getMemberAttachments
);

export const selectFiscalDetailState = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetail?.fiscal
);

export const selectFiscalHistoryDetailState = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetail?.fiscalHistory
);

export const selectBenefitDetailState = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetail?.benefit
);

export const selectDROPDetailState = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetail?.drop
);

export const selectBeneficiaryDetailState = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetail?.beneficiary
);

export const selectDemographicDetailState = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetail?.demographics
);

export const selectAddSectionState = createSelector(
  selectMemberDetailState,
  (state) => state.addSectionState
);

export const selectPayrollConfigurationInfoState = createSelector(
  selectMemberDetailState,
  (state) => state.payrollConfigurationInfo
);

export const selectMetadataState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMetadata.metadataFeatureKey]
);

export const selectMetadataSectionState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMetadataSection.metadataSectionFeatureKey]
);

export const selectPropertyType = createSelector(
  selectMemberModuleState,
  (state) => state[fromNewProperty.AddPropertyFeatureKey]
);

export const selectPropertyTypeCollectionState = createSelector(
  selectMemberModuleState,
  (state) => state[fromNewProperty.AddPropertyFeatureKey]?.propertyTypes
);

export const selectSelectedPropertyTypeState = createSelector(
  selectMemberModuleState,
  (state) => state[fromNewProperty.AddPropertyFeatureKey]?.selectedPropTypeValue
);

export const selectMemberInfo = createSelector(
  selectMemberDetailState,
  (state) => state.memberInfo
);

export const selectMemberData = createSelector(
  selectMemberDetailState,
  (state) => {
    return {
      isLoading: state.isLoading,
      isLoadingMemberInfo: state.isLoadingMemberInfo,
      isLoadingMetadataOverview: state.isLoadingMetadataOverview,
      isDeleting: state.isDeleting,
      memberDetail: state.memberDetail,
      memberInfo: state.memberInfo,
      metadataOverview: state.metadataOverview,
      profileOverviewName: state.profileOverviewName,
      profileOverviewConfig: state.profileOverviewConfig,
    };
  }
);

export const selectMemberDataUnMasked = createSelector(
  selectMemberDetailState,
  (state) => state.memberDetailUnMasked
);

export const selectMetaDataState = createSelector(
  selectMemberModuleState,
  (state) => state.memberDetail
);

export const getMetadataSectionsSelector = createSelector(
  selectMemberDetailState,
  (state) => state.getMetadataSections
);

export const getMetadataNonListSectionsSelector = createSelector(
  selectMetaDataState,
  (state) => state.getMetadataNonListSections
);

export const selectMetadataOverview = createSelector(
  selectMemberDetailState,
  (state) => state.metadataOverview
);

export const selectIsLocked = createSelector(
  selectMemberDetailState,
  (state) => state.memberInfo?.isLocked
);

export const selectMemberMetadataAttachmentState = createSelector(
  selectMemberDetailState,
  (state) => state.memberMetadataAttachmentState
);

export const selectNextPayment = createSelector(
  selectMemberModuleState,
  (state) => state[fromNexPayment.nextPaymentFeatureKey]
);

export const selectNextPaymentHistory = createSelector(
  selectNextPayment,
  (state) => state.paymentHistoryList
);

export const selectNextPaymentDeduction = createSelector(
  selectNextPayment,
  (state) => state.deductions
);

export const selectNextPaymentNotes = createSelector(
  selectNextPayment,
  (state) => state.paymentInfoNotes
);

export const selectNextPaymentEarnings = createSelector(
  selectNextPayment,
  (state) => state.earnings
);

export const selectNextPaymentRecalculateTotalDeductions = createSelector(
  selectNextPayment,
  (state) => state.recalculateTotalDeductions
);

export const selectNextPaymentSettings = createSelector(
  selectNextPayment,
  (state) => state.settings
);

export const selectNextPaymentPayment = createSelector(
  selectNextPayment,
  (state) => state.payments
);

export const selectPendingPaymentState = createSelector(
  selectNextPayment,
  (state) => state.pendingPaymentList
);

export const selectIssueOffCyclePaymentState = createSelector(
  selectNextPayment,
  (state) => state.issueOffCyclePayment
);

export const selectMemberAddressItemsState = createSelector(
  selectNextPayment,
  (state) => state.memberAddressItems
);

export const selectRepresentativePayeesState = createSelector(
  selectNextPayment,
  (state) => state.representativePayees
);

export const selectPaymentDeductionsState = createSelector(
  selectNextPayment,
  (state) => state.paymentDeductions
);

export const selectCreateOffCycleState = createSelector(
  selectNextPayment,
  (state) => state.createOffCycleState
);

export const selectReloadPendingPaymentsStateState = createSelector(
  selectNextPayment,
  (state) => state.reloadPendingPaymentsState
);

export const selectParticipantNextPaymentSetting = createSelector(
  selectNextPayment,
  (state) => state.participantNextPaymentSetting
);

export const selectWithdrawOffCyclePayment = createSelector(
  selectNextPayment,
  (state) => state.withdrawOffCyclePayment
);

export const selectRemoveOffCyclePaymentState = createSelector(
  selectNextPayment,
  (state) => state.removeOffCyclePaymentState
);

export const selectLookupTable = createSelector(
  selectMemberModuleState,
  (state) => state[fromLookupTable.lookupTableFeatureKey]
);

export const selectLookupTableList = createSelector(
  selectLookupTable,
  (state) => state.lookupTableList
);

export const selectLookupTableDetailState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState
);

export const selectCreateLookupTableState = createSelector(
  selectLookupTable,
  (state) => state.createState
);

export const selectEditLookupTableState = createSelector(
  selectLookupTable,
  (state) => state.updateState
);

export const selectCreateLookupTableValueState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState.createState
);

export const selectEditLookupTableValueState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState.updateState
);

export const selectRemoveLookupTableValueState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState.removeState
);

export const selectSessionDetailState = createSelector(
  selectLookupTable,
  (state) => state.importState
);
export const selectMemberStatus = createSelector(
  selectMemberModuleState,
  (state) => state[fromStatus.memberStatusFeatureKey]
);

export const selectMemberStatusList = createSelector(
  selectMemberStatus,
  (state) => state.statusList
);

export const selectStatusDetail = createSelector(
  selectMemberStatus,
  (state) => state.statusDetailState
);

export const selectEditStatusState = createSelector(
  selectMemberStatus,
  (state) => state.editStatusState
);

export const selectRemoveStatusState = createSelector(
  selectMemberStatus,
  (state) => state.removeStatusState
);

export const selectCreateEventState = createSelector(
  selectMemberStatus,
  (state) => state.createEventState
);

export const selectEditEventState = createSelector(
  selectMemberStatus,
  (state) => state.editEventState
);

export const selectRemoveEventState = createSelector(
  selectMemberStatus,
  (state) => state.removeEventState
);

export const selectListStatus = createSelector(
  selectMemberDetailState,
  (state) => state.listStatus
);
export const selectMemberListConfiguration = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromMemberListConfiguration.memberListConfigurationFeatureKey]
);

export const selectPropertyConfigs = createSelector(
  selectMemberListConfiguration,
  (state) => state?.propertyConfigs
);

export const selectPropertyDisplayConfigurations = createSelector(
  selectMemberListConfiguration,
  (state) => state?.propertyDisplayConfigurations
);

export const selectUpdateState = createSelector(
  selectMemberListConfiguration,
  (state) => state?.updateState
);

export const selectListSectionProperty = createSelector(
  selectMemberListState,
  (state) => state?.listSectionProperty
);
export const selectMemberNavigationFeatureState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberNavigation.memberNavigationFeatureKey]
);
export const selectMemberNavigationState = createSelector(
  selectMemberNavigationFeatureState,
  (state) => state.memberNavigateState
);
export const selectFiltersState = createSelector(
  selectMemberListState,
  (state) => state.filters
);
export const selectListFilterInfo = createSelector(
  selectMemberListState,
  (state) => state.listFilterInfo
);
export const selectRemoveMemberFilterState = createSelector(
  selectMemberListState,
  (state) => state.removeMemberFilterState
);
export const selectUpdateFilterNameState = createSelector(
  selectMemberListState,
  (state) => state.updateFilterNameState
);

export const selectProfileOverviewConfigurationState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[
      fromProfileOverviewConfiguration.profileOverviewConfigurationFeatureKey
    ]
);

export const selectLoadingState = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.isLoading
);

export const selectProfileOverviewList = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.profileOverviewList
);

export const selectGetSectionMetadatas = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.getSectionMetadatas
);

export const selectProfileOverviewDetail = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.getProfileOverviewDetail
);

export const selectStatusNotUsedList = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.getNotUsedStatuses
);

export const selectSetProfileOverviewState = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.setProfileOverview
);

export const selectProfileOverviewConfigurationId = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.id
);

export const selectProfileOverviewConfigurationsDeleteState = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.deleteState
);

export const profileHeaderConfigSelector = createSelector(
  selectMemberModuleState,
  (state) => state.profileHeaderConfig
);
export const selectProfileHeaderList = createSelector(
  profileHeaderConfigSelector,
  (state) => state.getProfileHeaderConfigList
);
export const selectProfileHeaderConfigurationsDeleteState = createSelector(
  profileHeaderConfigSelector,
  (state) => state.deleteState
);

export const selectMembersStatusNotUsedList = createSelector(
  profileHeaderConfigSelector,
  (state) => state.statusNotUsedList
);

export const selectProfileHeaderPropertyConfigs = createSelector(
  profileHeaderConfigSelector,
  (state) => state?.propertyConfigs
);

export const selectProfileHeaderPropertyDisplayConfigurations = createSelector(
  profileHeaderConfigSelector,
  (state) => state?.profileHeader
);

export const selectProfileHeaderUpdateState = createSelector(
  profileHeaderConfigSelector,
  (state) => state?.updateState
);

export const getProfileHeadersSelector = createSelector(
  profileHeaderConfigSelector,
  (state) => state?.getProfileHeaders
);

export const selectProfileHeaderConfigurationId = createSelector(
  profileHeaderConfigSelector,
  (state) => state.id
);

export const selectProfileNavigationConfigurationState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[
      fromProfileNavigationConfiguration
        .profileNavigationConfigurationFeatureKey
    ]
);

export const selectMemberNavigationsState = createSelector(
  selectProfileNavigationConfigurationState,
  (state) => state.memberNavigations
);

export const selectMemberNavigationDetailState = createSelector(
  selectProfileNavigationConfigurationState,
  (state) => state.memberNavigationDetailState
);

export const selectMemberNavigationSectionListState = createSelector(
  selectProfileNavigationConfigurationState,
  (state) => state.listSection
);

//#region Non List Configuration
const selectNonListConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromNonListConfigurations.nonListConfigurationFeatureKey]
);

export const selectGetNonListPropertiesState = createSelector(
  selectNonListConfigurationState,
  (state) => state.getNonListProperties
);

export const selectGetNonListItemConfigsState = createSelector(
  selectNonListConfigurationState,
  (state) => state.getNonListItemConfigs
);

export const selectSaveNonListItemConfigsState = createSelector(
  selectNonListConfigurationState,
  (state) => state.saveNonListItemConfigs
);
//#endregion

// Aggregation Configuration
export const selectAggregationConfigState = createSelector(
  selectMemberModuleState,
  (state) => state[fromAggregationConfig.aggregationConfigFeatureKey]
);

export const selectMunicipalityPaymentState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMunicipalityPayment.MunicipalityPaymentFeatureKey]
);

export const selectMunicipalityListState = createSelector(
  selectMunicipalityPaymentState,
  (state) => state.payments
);

export const selectStatusHistoryState = createSelector(
  selectMemberModuleState,
  (state) => state[fromStatusHistory.StatusHistoryFeatureKey]
);

export const selectStatusHistoryListState = createSelector(
  selectStatusHistoryState,
  (state) => state.getStatusHistoryList
);

export const createStatusHistorySelector = createSelector(
  selectStatusHistoryState,
  (state) => state.createStatusHistory
);

export const updateStatusHistorySelector = createSelector(
  selectStatusHistoryState,
  (state) => state.updateStatusHistory
);

export const selectListMemberStatusState = createSelector(
  selectStatusHistoryState,
  (state) => state.listMemberStatus
);

export const selectAggregationGroupState = createSelector(
  selectMemberModuleState,
  (state) => state.aggregationGroup
);

export const getAggregationGroupDetailsSelector = createSelector(
  selectAggregationGroupState,
  (state) => state.getAggregationGroupDetails
);

export const selectMemberPropertyDataByMaskedConfigState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberPropertyFeatureKey.memberPropertyFeatureKey]
);

export const selectParticipantSearchConfiguration = createSelector(
  selectMemberModuleState,
  (state) =>
    state[
      fromParticipantSearchConfiguration
        .participantSearchConfigurationFeatureKey
    ]
);

export const selectParticipantSearchPropertyConfigs = createSelector(
  selectParticipantSearchConfiguration,
  (state) => state?.propertyConfigs
);

export const selectParticipantSearchPropertyDisplayConfigurations =
  createSelector(
    selectParticipantSearchConfiguration,
    (state) => state?.propertyDisplayConfigurations
  );

export const selectParticipantSearchConfigurationUpdateState = createSelector(
  selectParticipantSearchConfiguration,
  (state) => state?.updateState
);

export const selectParticipantRelationshipState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromParticipantRelationship.participantRelationshipFeatureKey]
);

export const selectParticipantRelationships = createSelector(
  selectParticipantRelationshipState,
  (state) => state.participantRelationships
);

export const selectParticipantRelationshipLoadingState = createSelector(
  selectParticipantRelationshipState,
  (state) => state.isLoading
);

export const selectSavePaymentInforConfigState = createSelector(
  selectNextPayment,
  (state) => state.savePaymentInforConfigState
);

export const selectPaymentInforConfigState = createSelector(
  selectNextPayment,
  (state) => state.paymentInforConfig
);

export const selectMemberNotificationOptInState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromMemberNotificationOptIn.memberNotificationOptInFeatureKey]
);

export const selectMemberNotificationOptInListState = createSelector(
  selectMemberNotificationOptInState,
  (state) => state.memberNotificationOptIns
);

export const selectEditMemberNotificationOptInState = createSelector(
  selectMemberNotificationOptInState,
  (state) => state.editNotificationOptInState
);

export const selectMunicipalityServiceRecordState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromMunicipalityServiceRecord.MunicipalityServiceHistoryFeatureKey]
);
export const selectMunicipalityServiceRecordListState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.serviceRecords
);
export const selectMunicipalityNameListState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.listMunicipality
);
export const selectMunicipalityLoadingState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.isFormEditLoading
);
export const selectSetPaymentListConfigurationState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromPaymentListConfigurations.paymentListConfigurationFeatureKey]
      .setPaymentListConfiguration
);
export const selectGetPaymentListConfigurationState = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromPaymentListConfigurations.paymentListConfigurationFeatureKey]
      .getPaymentListConfiguration
);

//Tier Configuration
export const selectTierConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey]
);

export const selectTierConfigurationPlan = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].plans
);

export const selectTierConfigurationMetadata = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].metadata
);

export const selectTierConfigurationOperator = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].operator
);

export const selectTierConfigurationLookup = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].result
);

export const selectTierConfigurationBinary = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromTierConfigurations.tierConfigurationFeatureKey].resultBinary
);

//End Tier Configuration

// calculation region
export const selectCalculationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromCalculations.calculationFeatureKey]
);
export const selectGetCalculationsState = createSelector(
  selectCalculationState,
  (state) => state.getCalculations
);
export const selectCalculationPropertiesState = createSelector(
  selectCalculationState,
  (state) => state.calculationProperties
);
export const selectAddCalculationState = createSelector(
  selectCalculationState,
  (state) => state.addCalculation
);
export const selectRemoveCalculationState = createSelector(
  selectCalculationState,
  (state) => state.removeCalculation
);
export const selectCalculationDetailState = createSelector(
  selectCalculationState,
  (state) => state.calculationDetail
);
export const selectUpdateCalculationState = createSelector(
  selectCalculationState,
  (state) => state.updateCalculation
);
// end calculation region

export const selectParticipantReportState = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantReport.participantReportFeatureKey]
);
export const selectParticipantReportManageReport = createSelector(
  selectParticipantReportState,
  (state) => state.getManageParticipantState
);
export const selectParticipantReportCategorySidebar = createSelector(
  selectParticipantReportState,
  (state) => state.getReportCategorySidebarState
);
export const selectParticipantReportCategoryName = createSelector(
  selectParticipantReportState,
  (state) => state.getReportCategoryNameState
);
export const selectCreateReport = createSelector(
  selectParticipantReportState,
  (state) => state.createReportState
);
export const selectDeleteReport = createSelector(
  selectParticipantReportState,
  (state) => state.deleteReportState
);
export const selectEditReport = createSelector(
  selectParticipantReportState,
  (state) => state.editReportState
);
export const selectGetGeneratedReport = createSelector(
  selectParticipantReportState,
  (state) => state.getGeneratedReportState
);
export const selectDeleteGeneratedReport = createSelector(
  selectParticipantReportState,
  (state) => state.deleteGeneratedReportState
);

//drop plan
export const selectDropPlan = createSelector(
  selectMemberModuleState,
  (state) => state[fromDropPlan.dropPlanFeatureKey]
);
// end drop plan


// Info 1099
export const selectInfo1099 = createSelector(
  selectMemberModuleState,
  (state) => state[fromInfo1099.info1099FeatureKey]
);

export const selectInfo1099Record = createSelector(
  selectInfo1099,
  (state) => state.info1099Record
);

export const selectInfo1099ConfigState = createSelector(
  selectInfo1099,
  (state) => state.info1099Config
);

// End Info 1099