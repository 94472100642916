import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchMemberService {
  searchValue: string = '';
  constructor(private httpClient: HttpClient) { }

  getSearchMember(searchValue: string, idClient: string, searchMemberType: number, benefitTypeId: any) {
    this.searchValue = searchValue;
    let body = {
      clientId: idClient,
      searchValue: searchValue,
      timeZoneOffset: new Date().getTimezoneOffset(),
      searchMemberType: searchMemberType,
      benefitTypeId: benefitTypeId
    };
    return this.httpClient.post<any>(`${environment.apiUrl}/search`, body);
  }
}
