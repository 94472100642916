import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/models/base-action-state';

import * as ReportActions from '../actions/report.actions';
import {  
  ReportCategory
} from '../types/models';

export const reportFeatureKey = 'report';

export interface State {
  getReportCategories?: BaseActionState<ReportCategory[]>;
  updateReportCategories?: BaseActionState;
}

const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(ReportActions.getReportCategories, (state, {}) => ({
    ...state,
    getReportCategories: {
      isLoading: true,
    },
  })),
  on(ReportActions.getReportCategoriesSuccess, (state: State, { response }) => ({
    ...state,
    getReportCategories: {
      isLoading: false,
      success: true,
      payload: response.reportCategories || [],
    },
  })),
  on(ReportActions.getReportCategoriesFailure, (state: State, { error }) => ({
    ...state,
    getReportCategories: createFailureState(error),
  })),
  on(ReportActions.updateReportCategories, (state) => ({
    ...state,
    updateReportCategories: {
      isLoading: true,
    },
  })),
  on(ReportActions.updateReportCategoriesSuccess, (state) => ({
    ...state,
    updateReportCategories: {
      isLoading: false,
      success: true,
    },
  })),
  on(ReportActions.updateReportCategoriesFailure, (state, { error }) => ({
    ...state,
    updateReportCategories: createFailureState(error),
  })),
  on(ReportActions.clearReportCategoriesState, (state) => ({
    ...initialState,
    getReportCategories: undefined,
  })),

);

const createFailureState = (error: any) => {
  return {
    isLoading: false,
    success: false,
    error: error,
  };
};
