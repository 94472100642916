import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import {
  columnLayout,
  tableLayout,
} from '@ptg-shared/constance/listIcons.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION } from '@ptg-shared/constance/value.const';
import { ACTION_COLUMN, Column, ReorderInfo } from '@ptg-shared/controls/grid';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import * as fromReducer from '@ptg-reducers';
import * as FundListActions from '@ptg-fund-list/actions/fund-list.actions';

import * as ParticipantSearchConfigurationActions from '../../actions/participant-search-configuration.actions';
import * as MetadataSectionActions from '../../actions/metadata-detail.actions';
import { SectionLayout } from '../../constance/metadata.const';
import {
  MetadataProperty,
  MetadataSection,
  PropertyEditData,
} from '../../types/models';
import * as fromMember from '../../reducers';
import { MetadataSectionService } from '../../services/metadata-detail.service';
import { ModifyPropertyContainerComponent } from '../../components/modify-property-container/modify-property-container.component';
import { MemberListActions } from '../../actions';

@Component({
  selector: 'ptg-edit-metadata',
  templateUrl: './edit-metadata.component.html',
  styleUrls: ['./edit-metadata.component.scss'],
})
export class EditMetadataComponent extends BaseComponent {
  readonly ListLayout = SectionLayout.List;

  sectionName = new FormControl('');
  importLabel = new FormControl('');
  includeInBulkUpdate = new FormControl(false);
  sectionLayout = new FormControl(SectionLayout.Table);
  includeAttachment = new FormControl(false);
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Name',
      },
      truncate: true,
    },
    {
      name: 'importKey',
      header: {
        title: 'Import Label',
      },
      truncate: true,
    },
    {
      name: 'type',
      header: {
        title: 'Property Type',
      },
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  metaDatasData: MetadataProperty[] = [];
  valuesTemp: any[] = [];
  importLabelValue = '';
  isEditBulk = false;
  editData: MetadataSection = {} as MetadataSection;
  errorMsg?: string;
  isLoading = true;
  sectionkey = '';
  newPropertyName = '';
  message = '';
  propertyName = '';
  bannerType: BannerType = BannerType.Hidden;
  layoutOptions = [
    {
      displayValue: 'Column',
      value: SectionLayout.Column,
      iconConfig: {
        icon: 'column-layout',
        isSvg: true,
        iconFirst: true,
      },
    },
    {
      displayValue: 'Table',
      value: SectionLayout.Table,
      iconConfig: {
        icon: 'table-layout',
        isSvg: true,
        iconFirst: true,
      },
    },
  ];
  activeFund: any;
  lstFillter: any[] = [];
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Participant Metadata',
      url: '/member/member-metadata',
    },
    {
      name: '',
      canEdit: true,
      inputTextConfig: {
        placeholder: 'Section Name',
        hasLabel: true,
        errorRequire: 'Section Name is required.',
        errorAsync: 'Section Name already exists.',
      },
    },
  ];
  editStateField = '';
  functionButtons = [
    {
      buttonName: 'Aggregations',
      icon: 'list',
      classInput: 'custom-function',
    },
  ];

  private dialogRef!: MatDialogRef<ModifyPropertyContainerComponent>;

  constructor(
    public dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
    private route: ActivatedRoute,
    private metadataSectionService: MetadataSectionService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private store: Store<fromReducer.State>,
    private router: Router
  ) {
    super();
    iconRegistry.addSvgIconLiteral(
      'table-layout',
      sanitizer.bypassSecurityTrustHtml(tableLayout)
    );
    iconRegistry.addSvgIconLiteral(
      'column-layout',
      sanitizer.bypassSecurityTrustHtml(columnLayout)
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => (this.activeFund = el));
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.route.params.subscribe((params) => {
      this.memberStore.dispatch(
        MetadataSectionActions.getMetadataSection({ key: params.key })
      );
      this.metadataSectionService.sectionKey = params.key;
      this.sectionkey = params.key;
      this.memberStore
        .pipe(
          select(fromMember.selectMetadataSectionState),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state) => {
          this.editData = state.metadataSection || ({} as MetadataSection);
          this.isLoading = state.isLoading;

          // Get an error message (if any)
          if (state.error) {
            this.errorMsg = state.error.statusText;
          }

          this.fillValue();
          if (this.newPropertyName && !state.newPropertyName) {
            const property = this.metaDatasData.find(
              (item: MetadataProperty) => item.name === this.newPropertyName
            ) as PropertyEditData;
            this.editProperty(property);
            this.newPropertyName = '';
          }

          if (state.removeState === 'Success') {
            this.memberStore.dispatch(MetadataSectionActions.metadataClear());
            this.memberStore.dispatch(
              ParticipantSearchConfigurationActions.getParticipantSearchConfiguration()
            );
            showBanner.call(
              this,
              state.removeState,
              this.propertyName,
              ACTION.REMOVE
            );
          }

          if (state.removeState === 'Fail') {
            this.memberStore.dispatch(MetadataSectionActions.metadataClear());
            showBanner.call(
              this,
              state.removeState,
              this.propertyName,
              ACTION.REMOVE
            );
          }

          if (state.reloadMetadataDetail) {
            this.newPropertyName = state.newPropertyName;
            this.memberStore.dispatch(
              MetadataSectionActions.getMetadataSection({ key: params.key })
            );
          }

          if (state.editPropertyState === 'Success') {
            this.memberStore.dispatch(MetadataSectionActions.metadataClear());
            this.memberStore.dispatch(
              MetadataSectionActions.getMetadataSection({
                key: this.metadataSectionService.sectionKey,
              })
            );
            if (this.propertyName) {
              showBanner.call(
                this,
                state.editPropertyState,
                this.propertyName,
                ACTION.EDIT
              );
            }
          }

          if (state.editPropertyState === 'Fail') {
            this.memberStore.dispatch(MetadataSectionActions.metadataClear());
            this.memberStore.dispatch(
              MetadataSectionActions.getMetadataSection({
                key: this.metadataSectionService.sectionKey,
              })
            );
            if (this.propertyName) {
              showBanner.call(
                this,
                state.editPropertyState,
                this.propertyName,
                ACTION.EDIT
              );
            }
          }

          if (
            state.editState === 'Success' &&
            this.editStateField === 'importKey'
          ) {
            this.editStateField = '';
            this.memberStore.dispatch(MetadataSectionActions.metadataClear());
            this.store.dispatch(
              FundListActions.updateFundLayout({
                updateLayout: { metadataUpdated: new Date().toUTCString() },
              })
            );
          }
        });
    });

    this.memberStore
      .pipe(select(fromMember.selectFiltersState))
      .subscribe((filters) => {
        if (filters) {
          this.lstFillter = filters;
        }
      });
  }

  changeToggleBulkUpdate() {
    if (!this.includeInBulkUpdate.value) {
      if (this.importLabelValue) {
        this.storeDB('importKey', '');
      }
      this.importLabelValue = '';
    } else {
      this.isEditBulk = true;
      this.importLabel = new FormControl('', {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.metadataSectionService.checkExits,
          'sectionImportKey'
        ),
      });
    }
  }

  fillValue() {
    if (this.includeInBulkUpdate.pristine) {
      this.includeInBulkUpdate = new FormControl({
        value: !!this.editData?.importKey,
        disabled: false,
      });
      this.importLabel = new FormControl(this.editData?.importKey, {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.metadataSectionService.checkExits,
          'sectionImportKey',
          this.importLabelValue
        ),
      });
    }
    this.sectionLayout = new FormControl(this.editData?.type);
    this.includeAttachment = new FormControl({
      value: this.editData?.hasAttachment,
      disabled: false,
    });

    this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name =
      this.editData?.name;
    this.importLabelValue = this.editData?.importKey || '';
    this.metaDatasData = this.editData?.properties;

    if (this.sectionName.pristine) {
      this.sectionName = new FormControl(this.editData?.name, {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.metadataSectionService.checkExits,
          'sectionName',
          this.editData?.name
        ),
      });
    }

    if (this.editData?.key === 'demographics' && this.editData?.properties) {
      this.metadataSectionService.ssnLabel = this.editData?.properties?.find(
        (el: MetadataProperty) => el.key === 'last4Ssn'
      )?.name;
    }
  }

  storeDB(field: string, value: string) {
    let bodyEdit: any = {};
    bodyEdit[field] = value;
    this.editStateField = field;
    // call Action/ api to save data Edit to DB
    this.memberStore.dispatch(
      MetadataSectionActions.editSectionMetadata({ bodyEdit })
    );
  }

  editSectionName(isSave: boolean) {
    if (!isSave) {
      this.listBreadcrumbs[this.listBreadcrumbs.length - 1].isEditing = false;
      this.sectionName.setValue(
        this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name
      );
    } else
      setTimeout(() => {
        if (this.sectionName.valid) {
          this.metadataSectionService
            .checkExits({ sectionName: this.sectionName.value.trim() })
            .subscribe((el: any) => {
              if (!el.exists) {
                this.listBreadcrumbs[
                  this.listBreadcrumbs.length - 1
                ].isEditing = false;
                this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name =
                  this.sectionName.value.trim();
                this.storeDB('name', this.sectionName.value.trim());
                this.sectionName = new FormControl(
                  this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name,
                  {
                    validators: [Validators.required],
                    asyncValidators: checkApiValidator(
                      this.metadataSectionService.checkExits,
                      'sectionName',
                      this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name
                    ),
                  }
                );
              } else if (
                this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name ===
                this.sectionName.value.trim()
              ) {
                this.listBreadcrumbs[
                  this.listBreadcrumbs.length - 1
                ].isEditing = false;
                this.sectionName.setValue(
                  this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name
                );
              } else if (el.exists) {
                this.sectionName.setErrors({ inValidAsync: true });
              }
            });
        }
      }, 1000);
  }

  editBulkUpdate(action: string) {
    this.importLabel.markAllAsTouched();
    if (action === 'close') {
      this.isEditBulk = false;
      if (this.importLabelValue) {
        this.importLabel.setValue(this.importLabelValue);
      } else {
        this.includeInBulkUpdate.setValue(false);
      }
    } else
      setTimeout(() => {
        if (this.importLabel.valid) {
          this.metadataSectionService
            .checkExits({ sectionImportKey: this.importLabel.value.trim() })
            .subscribe((el: any) => {
              if (!el.exists) {
                this.isEditBulk = false;
                this.importLabelValue = this.importLabel.value.trim();
                this.storeDB('importKey', this.importLabel.value.trim());
                this.importLabel = new FormControl(this.importLabelValue, {
                  validators: [Validators.required],
                  asyncValidators: checkApiValidator(
                    this.metadataSectionService.checkExits,
                    'sectionImportKey',
                    this.importLabelValue
                  ),
                });
              } else if (
                this.importLabelValue === this.importLabel.value.trim()
              ) {
                this.isEditBulk = false;
                this.importLabel.setValue(this.importLabelValue);
              } else if (el.exists) {
                this.importLabel.setErrors({ inValidAsync: true });
              }
            });
        }
      }, 1000);
  }

  editProperty(event: PropertyEditData) {
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.data = {
        property: event,
        isEdit: true,
        lookupTables: this.editData.lookupTables || [],
      };
      this.postHandleEditProperty();
      return;
    }
    this.dialogRef = this.dialog.open(ModifyPropertyContainerComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      minWidth: '900px',
      height: 'auto',
      maxHeight: '90vh',
      data: {
        property: event,
        isEdit: true,
        lookupTables: this.editData.lookupTables || [],
        itemKey: this.editData?.key
      },
    });
    this.postHandleEditProperty();
  }

  onClickBreadcrumb(url: string) {
    if (url === '/member') {
      this.store.dispatch(LayoutActions.closeSideMenu());
    }
  }

  postHandleEditProperty() {
    this.dialogRef.afterClosed().subscribe((result: PropertyEditData) => {
      if (result?.isEdit && result?.config) {
        this.propertyName = result.name || '';
        const newConfig = Object.keys(result.config).map((key: any) => {
          return {
            key: key,
            value: (result.config as any)?.[key],
          };
        });
        const properties: any[] = [
          {
            key: result.key,
            config: newConfig,
            name: result.name,
            importKey: result.importKey,
            options: result.options,
          },
        ];
        this.memberStore.dispatch(
          MetadataSectionActions.editPropertyDetail({ properties })
        );
        let dataProperties = JSON.parse(JSON.stringify(this.metaDatasData));
        let idx = dataProperties.findIndex(
          (el: MetadataProperty) => el.key === properties[0].key
        );
        let config: any = {};
        for (let i = 0; i < properties[0].config.length; i += 1) {
          config[properties[0].config[i]?.key] = properties[0].config[i]?.value;
        }
        dataProperties[idx] = {
          ...this.metaDatasData[idx],
          ...properties[0],
          config: { ...this.metaDatasData[idx].config, ...config },
        };
        this.metaDatasData = dataProperties;
        if (properties[0].key === 'last4Ssn' && properties[0]?.name?.trim()) {
          this.metadataSectionService.ssnLabel = properties[0]?.name?.trim();
        }
      }
    });
  }

  changeItemIndex(event: ReorderInfo) {
    this.memberStore.dispatch(
      MetadataSectionActions.orderTable({
        sectionKey: this.metadataSectionService.sectionKey,
        body: {
          upperAdjacentKey: event.upperAdjacentKey,
          reorderPropertyKey: event.reorderItemKey,
        },
      })
    );
  }

  addProperty() {
    this.metadataSectionService.sectionKey = this.sectionkey;
    this.dialogRef = this.dialog.open(ModifyPropertyContainerComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '848px',
      data: {
        isEdit: false,
      },
    });
  }

  onRemoveClick(row: MetadataProperty) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Removing this property will remove all of its related member data and all filters using it.<br> Are you sure you want to proceed?`,
        type: ConfirmType.Destruct,
        title: 'THIS IS A DESTRUCTIVE ACTION',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.propertyName = row.name;
        this.memberStore.dispatch(
          MetadataSectionActions.removePropertyMetadata({
            propertyKey: row.key,
          })
        );

        if (this.lstFillter && this.lstFillter.length > 0) {
          const filter = this.lstFillter.filter(
            (o) => o.propertyKey?.toLowerCase() != row.key?.toLowerCase()
          );
          if (filter.length != this.lstFillter.length) {
            this.memberStore.dispatch(
              MemberListActions.setMemberFilter({ filters: filter })
            );
          }
        }
      }
    });
  }

  comeAggregation() {
    this.router.navigateByUrl(
      `/member/member-metadata/${this.sectionkey}/aggregation`
    );
  }
}
