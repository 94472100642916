import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { FIRST_PAGE, PageEvent, PaginationComponent } from '@ptg-shared/controls/pagination';
import { NextPayrollService } from '@ptg-processing/services/next-payroll.service';
import { HistoryService } from '@ptg-shared/services/history.service';
import { PaymentInfoNoteType } from '@ptg-shared/constance/value.const';

import * as fromNextPayment from '../../reducers';
import * as NextPaymentActions from '../../actions/next-payment.actions';
import { takeUntil } from 'rxjs/operators';
import * as fromReducer from '@ptg-reducers';
import { Subject } from 'rxjs';

const PAGE_SIZE_CONST = '-ptg-note-memo-detail-pageSize'
@Component({
  selector: 'ptg-note-memo-detail',
  templateUrl: './note-memo-detail.component.html',
  styleUrls: ['./note-memo-detail.component.scss']
})
export class NoteMemoDetailComponent implements OnInit, OnChanges {
  @ViewChild(PaginationComponent) paginator!: PaginationComponent;
  dataNotes: any = [];
  PaymentInfoNoteType = PaymentInfoNoteType;
  unsubscribe$ = new Subject<void>();
  lengthPg: number | any;
  pageSize: number = 2;
  pageNumber: number = FIRST_PAGE;
  allowAddMemo: boolean = false;
  currentFund: any = {};

  @Input() isHistory?: boolean;
  @Input() depositDate?: string;
  @Input() isBackNextPayroll?: boolean;
  @Input() offCyclePaymentId?: string;
  @Input() paymentSourceId?: string;

  @Output() onAddAction = new EventEmitter();
  @Output() onRemoveMemo = new EventEmitter();

  constructor(
    private store: Store<fromNextPayment.State>,
    private nextPayrollService: NextPayrollService,
    private historyService: HistoryService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offCyclePaymentId) {
      this.getData();
    }
    if (changes.paymentSourceId) {
      this.getData();
    }
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.currentFund = el;
      });
    
    this.getData();
    this.isHistory = this.historyService.isNextPayroll;
    this.store.select(fromNextPayment.selectNextPaymentNotes).subscribe(el => {
      if (el?.paymentInfoNotes) {
        this.dataNotes = el.paymentInfoNotes.paymentInfoNotes?.map((ele: any) => {
          return {
            ...ele,
            postedDate: !ele.postedDate || ele.postedDate.includes('+') || ele.postedDate.includes('Z') ? ele.postedDate : ele.postedDate + 'Z',
            createdBy: `${ele.firstName ? `${ele.firstName[0]}.`: ''}  ${ele.lastName}`,
            isTruncate: true
          }
        });
        this.lengthPg = el.paymentInfoNotes.total;
        this.allowAddMemo = el.paymentInfoNotes.isPayeeAssignedPayroll && el.paymentInfoNotes.isPayrollValid || el.paymentInfoNotes.isOffCycleValid;
      }
    });
  }

  getData() {
    this.store.dispatch(NextPaymentActions.getNotes({
      query: { paymentSourceId: this.paymentSourceId && this.paymentSourceId !== 'null' ? this.paymentSourceId : this.isBackNextPayroll ? this.nextPayrollService.payrollId : undefined,  pageIndex: this.pageNumber, totalPerPage: this.pageSize },
      targetId: this.offCyclePaymentId
    }));
  }

  getDataFirstPage() {
    if (this.dataNotes?.length > 0) {
      this.paginator?.jumpToFirst();
    } else {
      this.store.dispatch(NextPaymentActions.getNotes({
        query: { paymentSourceId: this.paymentSourceId ? this.paymentSourceId : this.isBackNextPayroll ? this.nextPayrollService.payrollId : undefined,  pageIndex: 1, totalPerPage: this.pageSize },
        targetId: this.offCyclePaymentId
      }));
    }
  }

  changePagging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
     
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString()); 
    this.getData();
  }

  onClickAdd(noteType: number) {
    this.onAddAction.emit({noteType: noteType});
  }

  onClickEditNote(item: any, itemType: PaymentInfoNoteType) {
    this.onAddAction.emit({noteType: itemType, memoDetails: item })
  }

  onClickRemoveMemo(item: any) {
    this.onRemoveMemo.emit(item);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
