import { createAction, props } from '@ngrx/store';

import { FederalTablePayload, NewFederalTax } from '../types/models';

export const FederalTaxesRequest = '[FederalTaxes/API] Send Request';
export const FederalTaxesSuccess = '[FederalTaxes/API] Success';
export const FederalTaxesFailure = '[FederalTaxes/API] Failure';

export const FederalTaxesAllRequest = '[FederalTaxesAll/API] Send Request';
export const FederalTaxesAllSuccess = '[FederalTaxesAll/API] Success';
export const FederalTaxesAllFailure = '[FederalTaxesAll/API] Failure';

export const CreateFederalTaxRequest = '[CreateFederalTax/API] Send Request';
export const CreateFederalTaxFailure = '[CreateFederalTax/API] Failure';
export const CreateFederalTaxSuccess = '[CreateFederalTax/API] Success';

export const UpdateFederalTaxRequest = '[UpdateFederalTax/API] Send Request';
export const UpdateFederalTaxFailure = '[UpdateFederalTax/API] Failure';
export const UpdateFederalTaxSuccess = '[UpdateFederalTax/API] Success';

export const CreateFederalTableRequest =
  '[CreateFederalTable/API] Send Request';
export const CreateFederalTableFailure = '[CreateFederalTable/API] Failure';
export const CreateFederalTableSuccess = '[CreateFederalTable/API] Success';

export const UpdateFederalTableRequest =
  '[UpdateFederalTable/API] Send Request';
export const UpdateFederalTableFailure = '[UpdateFederalTable/API] Failure';
export const UpdateFederalTableSuccess = '[UpdateFederalTable/API] Success';

export const RemoveFederalTableRequest =
  '[RemoveFederalTable/API] Send Request';
export const RemoveFederalTableFailure = '[RemoveFederalTable/API] Failure';
export const RemoveFederalTableSuccess = '[RemoveFederalTable/API] Success';

export const FederalTaxClear = '[FederalTax] Clear';

export const getFederalTaxes = createAction(
  FederalTaxesRequest,
  props<{ query: any }>()
);
export const getFederalTaxesSuccess = createAction(
  FederalTaxesSuccess,
  props<{ federalTaxesData: any }>()
);
export const getFederalTaxesFailure = createAction(
  FederalTaxesFailure,
  props<{ error?: any }>()
);

export const getAllFederalTaxes = createAction(
  FederalTaxesAllRequest,
  props<{ query: any }>()
);
export const getAllFederalTaxesSuccess = createAction(
  FederalTaxesAllSuccess,
  props<{ federalTaxesDataAll: any }>()
);
export const getAllFederalTaxesFailure = createAction(
  FederalTaxesAllFailure,
  props<{ errorMsg: string }>()
);

export const createFederalTax = createAction(
  CreateFederalTaxRequest,
  props<{ body: NewFederalTax }>()
);
export const createtFederalTaxSuccess = createAction(CreateFederalTaxSuccess);
export const createFederalTaxFailure = createAction(
  CreateFederalTaxFailure,
  props<{ errorMsg: string }>()
);

export const updateFederalTax = createAction(
  UpdateFederalTaxRequest,
  props<{ id: string; body: NewFederalTax }>()
);
export const updateFederalTaxSuccess = createAction(UpdateFederalTaxSuccess);
export const updateFederalTaxFailure = createAction(
  UpdateFederalTaxFailure,
  props<{ error?: any }>()
);

export const createFederalTable = createAction(
  CreateFederalTableRequest,
  props<{ body: FederalTablePayload }>()
);
export const createFederalTableSuccess = createAction(
  CreateFederalTableSuccess
);
export const createFederalTableFailure = createAction(
  CreateFederalTableFailure,
  props<{ errorMsg: string }>()
);

export const updateFederalTable = createAction(
  UpdateFederalTableRequest,
  props<{ body: FederalTablePayload }>()
);
export const updateFederalTableSuccess = createAction(
  UpdateFederalTableSuccess
);
export const updateFederalTableFailure = createAction(
  UpdateFederalTableFailure,
  props<{ error?: any }>()
);

export const removeFederalTable = createAction(
  RemoveFederalTableRequest,
  props<{ id: string; body: any }>()
);
export const removeFederalTableSuccess = createAction(
  RemoveFederalTableSuccess
);
export const removeFederalTableFailure = createAction(
  RemoveFederalTableFailure,
  props<{ error?: any }>()
);

export const clearFederalTax = createAction(FederalTaxClear);
