import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/models';

import * as ProfileHeaderConfigurationAction from '../actions/profile-header-configuration.actions';
import {
  MetadataProfileHeaderConfiguration,
  ProfileHeader,
  ProfileHeaderList,
  PropertyDisplayConfig,
  Status,
} from '../types/models';

export interface ProfileHeaderConfigState {
  isLoading: boolean;
  propertyConfigs: PropertyDisplayConfig[];
  profileHeader: MetadataProfileHeaderConfiguration;
  updateState: string;
  getProfileHeaders?: BaseActionState<{
    memberId: string;
    profileHeaders: ProfileHeader[];
    memberStatusId: string;
  }>;
  getProfileHeaderConfigList: BaseActionState<ProfileHeaderList>;
  deleteState: string;
  statusNotUsedList: Status[];
  id: string;
}

const initialState: ProfileHeaderConfigState = {
  isLoading: true,
  propertyConfigs: [],
  profileHeader: {} as MetadataProfileHeaderConfiguration,
  updateState: '',
  getProfileHeaderConfigList: {
    isLoading: true,
  },
  deleteState: '',
  statusNotUsedList: [],
  id: '',
};

export const profileHeaderConfigSReducer = createReducer(
  initialState,
  on(
    ProfileHeaderConfigurationAction.clearProfileHeaderConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
      deleteState: '',
      id: '',
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderPropertyListSuccess,
    (state, { propertyConfigs }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderConfigurationSuccess,
    (state, { profileHeader }) => ({
      ...state,
      isLoading: false,
      profileHeader,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      profileHeader: {} as MetadataProfileHeaderConfiguration,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.setProfileHeaderConfigurationSuccess,
    (state, { id }) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
      id,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.setProfileHeaderConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      updateState: STATE.FAIL,
    })
  ),

  on(
    ProfileHeaderConfigurationAction.getMemberProfileHeadersDataAction,
    (state) => ({
      ...state,
      getProfileHeaders: {
        isLoading: true,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMemberProfileHeadersDataSuccessAction,
    (state, { response, memberId }) => ({
      ...state,
      getProfileHeaders: {
        isLoading: false,
        success: true,
        payload: {
          memberId,
          profileHeaders: response.profileHeaders,
          memberStatusId: response.memberStatusId,
        },
        updatedAt: DateTime.now().toISO(),
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMemberProfileHeadersDataFailureAction,
    (state, { error }) => ({
      ...state,
      getProfileHeaders: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.clearGetMemberProfileHeadersDataStateAction,
    (state) => ({
      ...state,
      getProfileHeaders: undefined,
    })
  ),

  on(
    ProfileHeaderConfigurationAction.getListProfileHeaderConfiguration,
    (state) => ({
      ...state,
      getProfileHeaderConfigList: {
        isLoading: true,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getListProfileHeaderConfigurationSuccess,
    (state, { profileHeaderList }) => ({
      ...state,
      getProfileHeaderConfigList: {
        isLoading: false,
        success: true,
        payload: profileHeaderList,
        error: undefined,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getListProfileHeaderConfigurationFailure,
    (state, { error }) => ({
      ...state,
      getProfileHeaderConfigList: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.deleteProfileHeaderConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      deleteState: STATE.SUCCESS,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.deleteProfileHeaderConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      deleteState: STATE.FAIL,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMembersStatusNotUsedSuccess,
    (state, { statusNotUsedList }) => ({
      ...state,
      statusNotUsedList,
    })
  )
);
