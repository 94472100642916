import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EmailHistoryQuery,
  EmailHistoryResponse,
} from '@ptg-fund-management/models/email-history.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private httpClient: HttpClient) {}

  sendEmail(fileObj: any, isScheduleEmail: boolean): Observable<any> {
    let urlRoute = '/Email/Send';
    if (isScheduleEmail) urlRoute = '/Email/Schedule';
    return this.httpClient.post(`${environment.apiUrl}` + urlRoute, fileObj);
  }

  getEmailHistoryList(
    query: EmailHistoryQuery,
  ): Observable<EmailHistoryResponse> {
    let params = new HttpParams();
    params = params.append('limit', 1000);
    params = params.append('PageSize', query.totalPerPage);
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append('SortNames', query.sortField.toUpperCase());
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }

    return this.httpClient.get<EmailHistoryResponse>(
      `${environment.apiUrl}/Email/History`,
      {
        params,
      },
    );
  }

  cancelEmail(messageId: string): Observable<any> {
    return this.httpClient.delete<EmailHistoryResponse>(
      `${environment.apiUrl}/Email/CancelScheduleMail?messageId=` + messageId,
    );
  }

  getEmailDetails(messageId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/Email/Detail?MessageId=` + messageId,
    );
  }
}
