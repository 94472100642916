import { createReducer, on } from '@ngrx/store';

import * as MetadataSectionActions from '../actions/metadata-detail.actions';
import { MetadataSection } from '../types/models';

export const metadataSectionFeatureKey = 'metadataSection';

export interface State {
  isLoading: boolean;
  metadataSection: MetadataSection | null;
  reloadMetadataDetail: boolean;
  newPropertyName: string;
  removeState: string;
  editState: string;
  editPropertyState: string;
  error?: any;
}

const initialState: State = {
  isLoading: true,
  metadataSection: null,
  reloadMetadataDetail: false,
  newPropertyName: '',
  removeState: '',
  editState: '',
  editPropertyState: '',
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(MetadataSectionActions.getMetadataSection, (state) => ({
    ...state,
    isLoading: true,
    metadataSection: null,
    reloadMetadataDetail: false,
    error: undefined,
  })),
  on(
    MetadataSectionActions.getMetadataSectionSuccess,
    (state, { metadataSection }) => ({
      ...state,
      isLoading: false,
      metadataSection,
      newPropertyName: '',
      error: undefined,
    })
  ),
  on(MetadataSectionActions.getMetadataSectionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    metadataSection: null,
    error: error,
  })),
  on(MetadataSectionActions.addProperty, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(MetadataSectionActions.addPropertySuccess, (state, { propertyName }) => ({
    ...state,
    isLoading: false,
    reloadMetadataDetail: true,
    newPropertyName: propertyName,
  })),
  on(MetadataSectionActions.removePropertyMetadataSuccess, (state) => ({
    ...state,
    removeState: 'Success',
    reloadMetadataDetail: true,
  })),
  on(MetadataSectionActions.removePropertyMetaFailure, (state) => ({
    ...state,
    removeState: 'Fail',
  })),
  on(MetadataSectionActions.metadataClear, (state) => ({
    ...state,
    removeState: '',
    editState: '',
    editPropertyState: '',
  })),
  on(
    MetadataSectionActions.editSectionMetadataSuccess,
    (state, { bodyEdit }) => ({
      ...state,
      metadataSection: {
        ...state.metadataSection,
        ...bodyEdit,
      },
      editState: 'Success',
    })
  ),
  on(MetadataSectionActions.editSectionMetadataFailure, (state) => ({
    ...state,
    editState: 'Fail',
  })),
  on(MetadataSectionActions.editPropertyDetailSuccess, (state) => ({
    ...state,
    editPropertyState: 'Success',
  })),
  on(MetadataSectionActions.editPropertyDetailFailure, (state) => ({
    ...state,
    editPropertyState: 'Fail',
  }))
);
