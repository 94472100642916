import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HistoryService } from '@ptg-shared/services/history.service';
import { Column, Row, Align, ColumnType } from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { SortType } from '@ptg-shared/constance/value.const';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { getColumConfig } from '@ptg-member/helpers';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import * as fromReducer from '@ptg-reducers';
import * as fromInfo1099 from '@ptg-member/reducers';
import * as Info1099Actions from '@ptg-member/actions/info-1099.actions';
import {
  Info1099RecordResponse,
  Info1099ConfigData,
} from '@ptg-member/types/models';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';
import { Info1099Service } from '@ptg-member/services/info-1099.service';
import { DeductionDetailComponent } from '@ptg-member/components/deduction-detail/deduction-detail.component';
import { FilterPropertyType } from '@ptg-member/constance/member-list.const';
import { PageEvent } from '@ptg-shared/controls/pagination';
import { PROPERTY_TYPE_CONVERSION } from '@ptg-member/constance/info-1099.const';
import { Annual1099StatusType, DisplayValueLocationType } from '@ptg-member/types/enums';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { getDateString } from '@ptg-shared/utils/string.util';
import { DatePipe } from '@angular/common';

const PAGE_SIZE_CONST = '-ptg-info-1099-pageSize';
@Component({
  selector: 'ptg-info-1099',
  templateUrl: './info-1099.component.html',
  styleUrls: ['./info-1099.component.scss'],
})
export class Info1099Component extends BaseComponent {
  readonly Annual1099StatusType = Annual1099StatusType;
  readonly FilterPropertyType = FilterPropertyType;

  LIST_MASKED = ['EIN', 'SSN'];
  listBreadcrumbs = [{ name: '1099-R Info' }];
  isLoading: boolean = true;
  memberId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 10;
  pageNumber: number = 1;
  errorMsg: string = '';
  currentRow!: any;
  currentInformation!: any;
  currentDeduction!: any;
  currentRowIndex!: number;
  info1099RecordDataResponse!: Info1099RecordResponse;
  info1099RecordData!: any[];
  info1099Config!: Info1099ConfigData[];
  columns: Column[] = [];
  maskedValues: any = {};
  isPrinting = false;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  selectedRecordId?: string;
  currentFund: any = {};

  @ViewChild(DeductionDetailComponent)
  deductionDetailComponent!: DeductionDetailComponent;

  constructor(
    private route: ActivatedRoute,
    public memberDetailService: MemberDetailService,
    private info1099Service: Info1099Service,
    private store: Store,
    public historyService: HistoryService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getRouteParams();
    this.store.dispatch(Info1099Actions.getInfo1099Config());
    this.store
      .pipe(
        select(fromInfo1099.selectInfo1099ConfigState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el && el.length > 0 && !this.info1099Config) {
          this.info1099Config = deepClone(el);
          this.info1099Config = this.info1099Config.map(i => 
            FilterPropertyType['Person Name'] === i.dataType ||
            i.columnName === 'Printed1099' ? {
            ...i,
            dataType: FilterPropertyType.Text,
          } : i);
          this.getData();
        }
      });
    this.store
      .pipe(
        select(fromInfo1099.selectInfo1099Record),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.isLoading = el?.isLoading!;
        if (!el?.success && el?.error) {
          this.errorMsg = el?.error?.statusText;
          this.info1099RecordData = [];
        }

        if (el?.success) {
          this.info1099RecordDataResponse = el?.payload!;
          this.mapData();
        }

        this.lengthPg = el?.total;
        if (this.info1099RecordData && this.info1099RecordData[0]) {
          let rowIndex = this.info1099RecordData.findIndex(i => i.Id === this.selectedRecordId);
          if (rowIndex < 0)
            rowIndex = 0;
          this.selectRow(this.info1099RecordData[rowIndex]);
          this.currentRowIndex = rowIndex;
          this.selectedRecordId = undefined;
        }
      });
  }

  getRouteParams() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      ).subscribe(el => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    combineLatest([this.route.queryParams, this.route.params])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([queryParams, params]) => {
        if (params.id) {
          this.memberId = params.id;
          this.memberDetailService.memberId = params.id;
          this.info1099Service.memberId = params.id;
        }
        if (queryParams.recordId) {
          this.selectedRecordId = queryParams.recordId;
        }
      });
  }

  getData() {
    this.selectRow();
    let sortType = 0;
    let sortField = '';

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = this.sortInfo.active;
      sortType =
        this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }

    this.store.dispatch(
      Info1099Actions.getInfo1099Record({
        query: {
          pageIndex: this.pageNumber,
          totalPerPage: this.pageSize,
          sortField,
          sortType,
        },
        memberId: this.memberDetailService.memberId,
      })
    );
  }

  getConfigColumns(columns: any[]) {
    this.columns = [];
    if (!columns.length) {
      return [];
    }
    let newColumns = deepClone(columns);
    newColumns = newColumns.filter(({ showInList }) => !!showInList)?.sort((a, b) => a.orderInList - b.orderInList);
    newColumns.forEach((column: any) => {
      const columConfig = getColumConfig(PROPERTY_TYPE_CONVERSION[column.dataType], []);
      this.columns.push({
        name: column.columnName,
        header: {
          title: column.displayName,
        },
        truncate: false,
        sortable: true,
        type:
          columConfig.type === ColumnType.Binary
            ? PropertyType.TYPE_TEXT
            : columConfig.type,
        templateArgs: columConfig.templateArgs,
        nullValue: columConfig.type === ColumnType.DateTime ? '-' : undefined,
        style:
          column.type === PropertyType.TYPE_CURRENCY
            ? {
                'padding-right': '16px',
              }
            : {},
      });
    });
    return this.columns;
  }

  private _capitalizeFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  mapData() {
    if (this.isLoading) {
      return;
    }
    this.columns = this.getConfigColumns(
      this.info1099Config
    );
    if (this.columns.length > 0) {
      this.info1099RecordData =
        this.info1099RecordDataResponse?.items.map((item: any) => {
          let newItem = deepClone(item);
          this.LIST_MASKED.forEach(columnMasked => {
            if (columnMasked && newItem[columnMasked]) {
              const str = newItem[columnMasked];
              const maskedStr = `XXX-XX-${str.substring(str.length - 4)}`;
              newItem[columnMasked] = maskedStr;
              newItem[`${columnMasked}_value`] = str;
              newItem[`${columnMasked}_masked_value`] = maskedStr;
            }
          })
          const LIST_CONVERT = ['Address'];
          LIST_CONVERT.forEach(str => {
            if (newItem[str]) {
              try {
                const oldValue = JSON.parse(newItem[str]);
                let newValue: Record<string, string> = {};
                Object.keys(oldValue).forEach(i => {
                  newValue[this._capitalizeFirstLetter(i)] = oldValue[i];
                })
                newValue.country = newValue.countryCode;
                newValue.state = newValue.stateCode;
                newItem[str] = newValue;
              } catch (error) {
                
              }
            }
          })
          const LIST_BOOLEAN = ['Corrected', 'Printed1099']
          LIST_BOOLEAN.forEach(str => {
            if (newItem[str] === true)
              newItem[str] = str === 'Printed1099' ? 'Yes' : 'Y';
            else
              newItem[str] =  str === 'Printed1099' ? 'No' : 'N';
          })
          const LIST_DATETIME = ['FinalizedDate']
          LIST_DATETIME.forEach(str => {
            if (newItem[str]) {
              const format = 'MM/dd/yyyy hh:mm a';
              let date = new Date(getDateString(newItem[str]));
              newItem[str] = new DatePipe('en-US').transform(date, format) || '';
            }
          })
          return newItem;
        });
    } else {
      this.info1099RecordData = [];
    }
  }

  selectRow(row?: Row) {
    if (!row) {
      this.currentRow = undefined;
      this.currentInformation = undefined;
      this.currentDeduction = undefined;
      return;
    }
    const currentInformation: any = [[], [], []];
    const currentDeduction: any = [[], [], []];
    this.info1099Config.forEach(i => {
      if ([1,2,3].includes(i.detailColumn)) {
        const newValue = {
          ...i,
          value: row[i.columnName],
          row,
        }
        switch (i.displayLocation) {
          case DisplayValueLocationType.Information:
            currentInformation[i.detailColumn - 1].push(newValue)
            break;
        
          default:
            currentDeduction[i.detailColumn - 1].push(newValue)
            break;
        }
      }
    })

    this.currentRow = row;
    this.currentInformation = currentInformation.map((item: any) => item.sort((a: any, b: any) => a.detailColumnOrder - b.detailColumnOrder));
    this.currentDeduction = currentDeduction.map((item: any) => item.sort((a: any, b: any) => a.detailColumnOrder - b.detailColumnOrder));
  }

  sortChange(event: any) {
    this.sortInfo = event;
  }

  changePagging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString()); 
    this.getData();
  }

  private _getMaskedValue(data: any, columnName: string) {
    return data.value === data.row[`${columnName}_value`] ? data.row[`${columnName}_masked_value`] : data.row[`${columnName}_value`];
  }

  onClickMasked(columnName: string, displayLocation: number, detailColumn: number, index: number) {
    if (displayLocation === DisplayValueLocationType.Deduction)
      this.currentDeduction[detailColumn][index].value = this._getMaskedValue(this.currentDeduction[detailColumn][index], columnName);
    else
      this.currentInformation[detailColumn][index].value = this._getMaskedValue(this.currentInformation[detailColumn][index], columnName);
  }

  printForm() {
    showBanner.call(this, BannerType.Info, '', '', {
      customMessage: 'Generating 1099-R forms. You can perform actions once completed.',
    });
    this.isPrinting = true;
    const { Annual1099Id, ParticipantId, Id } = this.currentRow;
    this.info1099Service.downloadPDF({
      annual1099Id: Annual1099Id,
      participantId: ParticipantId,
      recordId: Id,
    }).subscribe(res => {
      this.bannerType = BannerType.Hidden;
      this.isPrinting = false;
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    },
    () => {
      this.isPrinting = false;
    });
  }
}
