import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';

import * as ParticipantRelationshipActions from '../../actions/participant-relationship.actions';
import { ParticipantRelationship } from '../../types/models';
import * as fromMember from '../../reducers';

@Component({
  selector: 'ptg-participant-relationship',
  templateUrl: './participant-relationship.component.html',
  styleUrls: ['./participant-relationship.component.scss'],
})
export class ParticipantRelationshipComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Status History',
    },
  ];
  isLoading = false;
  participantRelationships: ParticipantRelationship[] = [];
  memberId: string = '';

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.memberId = params['id'];
      this.getParticipantRelationship();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onChangeMemberDetail() {}

  getParticipantRelationship() {
    this.memberStore
      .select(fromMember.selectParticipantRelationships)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((participantRelationships) => {
        this.participantRelationships = participantRelationships;
      });
    this.memberStore
      .select(fromMember.selectParticipantRelationshipLoadingState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
    this.memberStore.dispatch(
      ParticipantRelationshipActions.getParticipantRelationship({
        memberId: this.memberId,
      })
    );
  }
}
