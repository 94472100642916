<div
  class="participant-relationship-container"
  id="participant-relationship-page"
>
  <ptg-overview-header
    #overViewHeader
    (changeMemberDetailEvent)="onChangeMemberDetail()"
  ></ptg-overview-header>

  <div class="flex-1 flex flex-col p-6">
    <ptg-spinner *ngIf="isLoading" style="margin: auto"></ptg-spinner>

    <ptg-organization-chart
      *ngIf="!isLoading"
      [participantRelationships]="participantRelationships"
    ></ptg-organization-chart>
  </div>
</div>
