import { createAction, props } from '@ngrx/store';

import {
  MemberListConfigurations,
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
} from '../types/models';

export const ClearMemberListConfiguration = '[MemberListConfiguration] Clear';
export const GetPropertyListRequest = '[GetPropertyList/API] Send Request';
export const GetPropertyListSuccess = '[GetPropertyList/API] Success';
export const GetPropertyListFailure = '[GetPropertyList/API] Failure';
export const GetMemberListConfigurationRequest =
  '[GetMemberListConfiguration/API] Send Request';
export const GetMemberListConfigurationSuccess =
  '[GetMemberListConfiguration/API] Success';
export const GetMemberListConfigurationFailure =
  '[GetMemberListConfiguration/API] Failure';
export const SetMemberListConfigurationRequest =
  '[SetMemberListConfiguration/API] Send Request';
export const SetMemberListConfigurationSuccess =
  '[SetMemberListConfiguration/API] Success';
export const SetMemberListConfigurationFailure =
  '[SetMemberListConfiguration/API] Failure';

export const clearMemberListConfigurationState = createAction(
  ClearMemberListConfiguration
);
export const getPropertyList = createAction(
  GetPropertyListRequest,
  props<{ itemKey?: string }>()
);
export const getPropertyListSuccess = createAction(
  GetPropertyListSuccess,
  props<{ propertyConfigs: PropertyDisplayConfig[] }>()
);
export const getPropertyListFailure = createAction(
  GetPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const getMemberListConfiguration = createAction(
  GetMemberListConfigurationRequest,
  props<{ id: string | undefined }>()
);
export const getMemberListConfigurationSuccess = createAction(
  GetMemberListConfigurationSuccess,
  props<{ propertyDisplayConfigurations: PropertyDisplayConfiguration[] }>()
);
export const getMemberListConfigurationFailure = createAction(
  GetMemberListConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const setMemberListConfiguration = createAction(
  SetMemberListConfigurationRequest,
  props<{ body: MemberListConfigurations }>()
);
export const setMemberListConfigurationSuccess = createAction(
  SetMemberListConfigurationSuccess
);
export const setMemberListConfigurationFailure = createAction(
  SetMemberListConfigurationFailure,
  props<{ errorMsg: string }>()
);
