import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { MetadataSection } from '../../types/models';
import { SectionLayout } from '../../constance/metadata.const';
import { ColumnIndex } from '../../types/enums';

@Component({
  selector: 'ptg-member-navigation-detail-nonlist',
  templateUrl: './member-navigation-detail-nonlist.component.html',
  styleUrls: ['./member-navigation-detail-nonlist.component.scss'],
})
export class MemberNavigationDetailNonlistComponent implements OnChanges {
  readonly SectionLayout = SectionLayout;

  memberNavigationItem: any;
  value: string = '';
  allColumnData?: any[];
  column1Data?: any[];
  column2Data?: any[];
  column3Data?: any[];
  column4Data?: any[];

  @Input() currentRow: any;
  @Input() metadataSection?: MetadataSection;
  @Input() metadataNonListSection!: MetadataSection;
  @Input() menuItemName!: string;
  @Input() columns!: any[];
  @Input() memberId!: string;
  @Input() currentSectionLayout?: SectionLayout;

  @Output() editButtonEvent = new EventEmitter();
  @Output() removeButtonEvent = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentRow?.currentValue) {
      const column1Data: any[] = [];
      const column2Data: any[] = [];
      const column3Data: any[] = [];
      const column4Data: any[] = [];

      this.metadataNonListSection.properties.forEach((p: any) => {
        const data = {
          key: p.key || p.id,
          value: this.currentRow[p.key || p.id],
          title: p?.name || p?.label,
          type: p.type,
          config: p.config,
          options: p.options,
          column: p.column,
          order: p.order,
        };
        switch (p.column) {
          case ColumnIndex.Column1:
            column1Data.push(data);
            break;
          case ColumnIndex.Column2:
            column2Data.push(data);
            break;
          case ColumnIndex.Column3:
            column3Data.push(data);
            break;
          case ColumnIndex.Column4:
            column4Data.push(data);
            break;
        }
      });

      this.metadataNonListSection.aggregations.forEach((p: any) => {
        const data = {
          key: p.key || p.id,
          value: this.currentRow[p.key || p.id]?.value,
          tooltipContent: this.currentRow[p.key || p.id]?.tooltip,
          title: p?.name || p?.label,
          type: p.type,
          config: p.config,
          options: p.options,
          column: p.column,
          order: p.order,
          displayValue: p?.displayValue,
        };
        switch (p.column) {
          case ColumnIndex.Column1:
            column1Data.push(data);
            break;
          case ColumnIndex.Column2:
            column2Data.push(data);
            break;
          case ColumnIndex.Column3:
            column3Data.push(data);
            break;
          case ColumnIndex.Column4:
            column4Data.push(data);
            break;
        }
      });

      this.metadataNonListSection.calculations.forEach((p: any) => {
        const data = {
          key: p.key || p.id,
          value: this.currentRow[p.key || p.id]?.value,
          tooltipContent: this.currentRow[p.key || p.id]?.tooltip,
          title: p?.name || p?.label,
          type: p.type,
          config: p.config,
          options: p.options,
          column: p.column,
          order: p.order,
          displayValue: p?.displayValue,
        };
        switch (p.column) {
          case ColumnIndex.Column1:
            column1Data.push(data);
            break;
          case ColumnIndex.Column2:
            column2Data.push(data);
            break;
          case ColumnIndex.Column3:
            column3Data.push(data);
            break;
          case ColumnIndex.Column4:
            column4Data.push(data);
            break;
        }
      });

      this.metadataNonListSection.blankSpaces.forEach((p: any) => {
        const data = {
          key: p.key || p.id,
          value: this.currentRow[p.key || p.id],
          title: p?.name || p?.label,
          type: p.type,
          config: p.config,
          options: p.options,
          column: p.column,
          order: p.order,
        };
        switch (p.column) {
          case ColumnIndex.Column1:
            column1Data.push(data);
            break;
          case ColumnIndex.Column2:
            column2Data.push(data);
            break;
          case ColumnIndex.Column3:
            column3Data.push(data);
            break;
          case ColumnIndex.Column4:
            column4Data.push(data);
            break;
        }
      });

      this.column1Data = column1Data.sort(
        (a: any, b: any) => Number(a.order) - Number(b.order)
      );
      this.column2Data = column2Data.sort(
        (a: any, b: any) => Number(a.order) - Number(b.order)
      );
      this.column3Data = column3Data.sort(
        (a: any, b: any) => Number(a.order) - Number(b.order)
      );
      this.column4Data = column4Data.sort(
        (a: any, b: any) => Number(a.order) - Number(b.order)
      );

      this.allColumnData = this.zipColumnData(
        this.column1Data,
        this.column2Data,
        this.column3Data,
        this.column4Data
      );
    }
  }

  private zipColumnData(
    column1Data: any[],
    column2Data: any[],
    column3Data: any[],
    column4Data: any[]
  ) {
    const columnData = [];
    const maxLength = Math.max(
      column1Data.length,
      column2Data.length,
      column3Data.length,
      column4Data.length
    );
    for (let i = 0; i < maxLength; i++) {
      columnData.push([
        column1Data[i],
        column2Data[i],
        column3Data[i],
        column4Data[i],
      ]);
    }

    return columnData;
  }

  edit() {
    this.editButtonEvent.emit();
  }

  remove() {
    this.removeButtonEvent.emit();
  }
}
