export const PropertyType: any = {
    TYPE_TEXT: 'Text',
    TYPE_EMAIL: 'Email',
    TYPE_FAX: 'Fax',
    TYPE_PHONE: 'Phone',
    TYPE_WHOLE_NUMBER: 'Whole_Number',
    TYPE_CURRENCY: 'Currency',
    TYPE_DECIMAL: 'Decimal',
    TYPE_DATE: 'Date',
    TYPE_LIST: 'Lookup',
    TYPE_ADDRESS: 'Address',
    TYPE_PERSON_NAME: 'Person_Name',
    TYPE_UPLOAD_DOC: 'Upload_Document',
    TYPE_BOOLEAN: 'Binary',
    TYPE_PERCENTAGE: 'Percentage',
    TYPE_EMPLOYER: 'Employer',
    TYPE_SSN: 'SSN',
    TYPE_RICH_TEXT: 'RichText',
    TYPE_DATE_TIME: 'DateTime',
    TYPE_DEPARTMENT: 'Department',
    TYPE_STATUS: 'Status',
    TYPE_BENEFIT: 'Benefit',
    TYPE_REGISTER: 'Register',
    TYPE_MEMBER_STATUS: 'member_status',
    TYPE_ATTACHMENT: 'Attachment',
};

export const InputType: any = {
  TYPE_TEXT: 'Text',
  TYPE_FAX: 'Fax',
  TYPE_EMAIL: 'Email',
  TYPE_PHONE: 'Phone',
  TYPE_NUMBER: 'Number',
  TYPE_DECIMAL: 'Decimal',
  TYPE_PERCENTAGE: 'Percentage',
  TYPE_CURRENCY: 'Currency',
  TYPE_SSN: 'SSN',
  TYPE_RICH_TEXT: 'RichText',
  TYPE_PASS_WORD: 'Password',
  TYPE_TIN: 'TIN',
};
export const FORMAT_PHONE_NUMBER = new RegExp(/^(.* *\([0-9]{3}\) [0-9]{3}\-[0-9]{4}.* *)$/);
export const LIMIT_PHONE_NUMBER = new RegExp(/^(\d{3})(\d{3})(\d{4})$/);

export const FORMAT_SSN_NUMBER = new RegExp(/^(.* *\([0-9]{3}\) [0-9]{2}\-[0-9]{4}.* *)$/);
export const LIMIT_SSN_NUMBER = new RegExp(/^(\d{3})(\d{2})(\d{4})$/);
export const LIMIT_TIN_NUMBER = new RegExp(/^(\d{2})(\d{7})$/);
