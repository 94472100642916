import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {  
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers/index';
import { BaseComponent } from '@ptg-shared/components';
import { ACTION, DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, GridComponent, Row, getValidatorsFromColumns } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { takeUntil } from 'rxjs/operators';
import { ReportActions } from '../../../actions';
import * as fromMember from '../../../reducers';
import { ReportCategory } from '../../../types/models';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
const PAGE_SIZE_CONST = '-ptg-defined-benefits-pageSize'

@Component({
  selector: 'ptg-report-categories',
  templateUrl: './report-categories.component.html',
  styleUrls: ['./report-categories.component.scss'],
})
export class ReportCategoriesComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Report',
      url: '/member/report/generated',
    },
    {
      name: 'Manage Report Categories',
      url: '',
    },
  ];

  message = '';
  bannerType: BannerType = BannerType.Hidden;


  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Category Name'
      },
      width: '50%',
      truncate: true,  
      editable: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `Category is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(250),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `Category already exists.`,
        },
      },
      style: {
        'padding-left': '0px'
      },
      controlArgs: {
        placeholder: 'Category',
        maxLength: 250
      }
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '50%',
    },
  ];
  reportCategories: (ReportCategory & Row)[] = [];
  @ViewChild('gridDataless')
  gridDataless?: GridComponent<ReportCategory>;
  isLoading: boolean = false;
  errorMsg?: string;
  
  currentFund: any = {};
  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.currentFund = el;
      });
    // Listen for getting report categories
    this.memberStore
      .pipe(
        select(fromMember.selectGetReportCategoriesState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state.isLoading && state.success) {
          this.reportCategories = state.payload?.map(config => {        
            return this.createInlineEditFormControls(config);
          }) || [];          
        }
      });

    // Listen for updating report categories
    this.memberStore
      .pipe(
        select(fromMember.selectUpdateReportCategoriesState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.store.dispatch(LayoutActions.getReportCategorySidebar());
            this.memberStore.dispatch(ReportActions.getReportCategories());
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Report Categories Configuration', ACTION.EDIT);
          this.store.dispatch(ReportActions.clearReportCategoriesState());
        }
      });
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.memberStore.dispatch(ReportActions.getReportCategories());
  }

  private createInlineEditFormControls(obj: any): any {
    var result = {
      ...obj,
      isStatic: obj.reports?.length > 0,
      form: this.fb.group({
        name: new FormControl(          
          obj.name,
          getValidatorsFromColumns(this.columns[0].name, this.columns, obj)
        )
      })
    }
    return result;
  }

  private checkExits(obj: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {      
      const existedData = this.reportCategories?.find(item => item.name.toLowerCase() === control.value.toLowerCase() && item.id !== obj.id);      
      return existedData ? { existed: true } : null;
    };
  }
  onCancel(): void { 
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ngOnInit()
      }
    });
  }
  onSubmit(): void {
    if(this.reportCategories?.filter(x => !x.form?.valid)?.length > 0){
      return
    }
    var requestBody = this.createRequestBody();
    //save body
    this.memberStore.dispatch(ReportActions.updateReportCategories({ request: requestBody }));
  }
  createRequestBody(): any{
    var modifiedData = this.gridDataless!.dataSource;
    var requestBody = {
      clientId: this.currentFund.id,
      reportCategoriesUpdateModels: modifiedData?.map(row=>{
        var category = {
          id: row.id,
          clientId: row.clientId,
          order: modifiedData.indexOf(row) + 1,
          name: row.name,
          isDelete: row.deleted
        }
        return category
      }) || []
    }
    return requestBody
  }  
}
