import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as MemberNavigationActions from '../actions/member-navigation';
import { MemberList, MemberListQuery } from '../types/models/member-list';
import { MemberDetailService } from '../services/member-detail.service';
import { MemberListService } from '../services/member-list.service';
import { MetadataService } from '../services/metadata.service';

@Injectable()
export class MemberNavigationEffects {
  constructor(
    private actions$: Actions,
    private memberDetailService: MemberDetailService,
    private metadataService: MetadataService,
    private memberListService: MemberListService
  ) {}

  getMoreMemberList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberNavigationActions.getMoreMembers),
      switchMap(({ query, idClient, isAppend }) => {
        return this.memberListService
          .memberList(query as MemberListQuery, idClient)
          .pipe(
            map((memberList: MemberList) => {
              return MemberNavigationActions.getMoreMembersSuccess({
                memberList,
                isAppend,
              });
            })
          );
      })
    )
  );
}
