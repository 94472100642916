<div class="member-section-attachment-container" id="member-section-attachment">
  <div class="header">
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
      (onClickBreadcrumb)="onClickBreadcrumb($event)"
    >
    </ptg-breadcrumb>
    <div class="title-button flex">
      <div class="line-space"></div>
      <div class="flex">
        <div class="add-btn flex" (click)="addAttachment($event)" tabindex="0">
          <mat-icon
            svgIcon="upload-attachment-icon"
            style="width: 16px; height: 16px"
          ></mat-icon>
          <span class="button-name">Upload</span>
        </div>
      </div>
    </div>
  </div>

  <div class="list-display flex flex-col">
    <div *ngIf="bannerType" class="p-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>

    <div class="flex-grow">
      <ptg-grid
        (pageChange)="pageChange($event)"
        (sortChange)="sortChange($event)"
        [columns]="columns"
        [data]="dataTable"
        [fitToParent]="true"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [totalRecords]="lengthPg"
        keyColumn="id"
        notFoundMessage="No Attachments to Display"
      >
        <ng-template cellContent columnName="attachment" let-row>
          <a
            (click)="openPDF(row.fileSnapshot)"
            href="javascript:void(0)"
            style="color: #408bf9; cursor: pointer; text-decoration: underline"
            >{{ row.attachment }}</a
          >
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="editAttachment(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="removeAttachment(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
