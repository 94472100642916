import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import {
  AggregationGroupBody,
  AggregationGroupBodyChangeKey,
  AggregationGroup,
} from '../types/models/aggregation-group.model';

@Injectable({
  providedIn: 'root',
})
export class AggregationGroupService {
  sectionKey: string = '';
  currentAggregationGroup: any;

  constructor(private httpClient: HttpClient) {}

  getAggregationGroupList() {
    return this.httpClient.get<AggregationGroup[]>(
      `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups`
    );
  }

  orderTable(body: AggregationGroupBodyChangeKey) {
    return this.httpClient.put(
      `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/${body.reorderItemId}/Reorder`,
      { upperAdjacentId: body.upperAdjacentId }
    );
  }

  checkGroupNameExist = (bodyCheck: any) => {
    let body = {
      ...bodyCheck,
      aggregationGroupId: this.currentAggregationGroup?.id,
    };
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/Exists`,
      body
    );
  };

  addAggregationGroup(body: AggregationGroupBody) {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/Add`,
      body
    );
  }

  updateAggregationGroup(id: string, body: AggregationGroupBody) {
    return this.httpClient.put(
      `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/${id}/Edit`,
      body
    );
  }

  removeAggregationGroup(id: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/${id}`
    );
  }
  getAggregationGroupDetails(body: any) {
    return this.httpClient.get(
      `${environment.apiUrl}/Metadata/${body.itemKey}/${body.memberId}/Aggregation/Groups/Details`
    );
  }
}
