import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { getMemberProfileHeadersDataAction } from '@ptg-member/actions/profile-header-configuration.actions';
import { NextPaymentService } from '@ptg-processing/services/vendor-next-payment.service';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  removeAccountIcon,
  shieldCheckIcon,
} from '@ptg-shared/constance/listIcons.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { STYLE_COLUMN } from '@ptg-shared/controls/gridview/gridview.const';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { LayoutActions } from '@ptg-shared/layout/actions';
import {
  DisplayedTabName,
  STRING_QUERY_PARAM,
} from '@ptg-shared/layout/constance/layout.const';
import { dispatchGetMemberNavigationList } from '@ptg-shared/layout/helpers';
import {
  Breadcrumb,
  FunctionButtonConfig,
  FunctionButtonData,
} from '@ptg-shared/models/breadcrumb.model';
import { FileService } from '@ptg-shared/services/file-service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import {
  deepClone,
  deepEqual,
  showBanner,
} from '@ptg-shared/utils/common.util';

import { EmailComponent } from '@ptg-member/components/email/email.component';
import { FundType } from '@ptg-shared/types/enums';
import { environment } from 'src/environments/environment';
import * as MetadataActions from '../../../member/actions/metadata.actions';
import * as fromReducer from '../../../reducers';
import { MemberDetailActions, MemberListActions } from '../../actions';
import { AddMemberSectionDataComponent } from '../../components/add-member-section-data/add-member-section-data.component';
import { EditMemberStatusEventComponent } from '../../components/edit-member-status-event/edit-member-status-event.component';
import { EditMemberStatusHistoryComponent } from '../../components/edit-member-status-history/edit-member-status-history.component';
import { MemberSectionAttachmentComponent } from '../../components/member-section-attachment/member-section-attachment.component';
import { FixedSection, SectionLayout } from '../../constance/metadata.const';
import * as fromMember from '../../reducers';
import { MemberDetailState } from '../../reducers/member-detail.reducer';
import { MemberDetailService } from '../../services/member-detail.service';
import { SideType } from '../../types/enums';
import {
  ActionItem,
  MemberDetailWithPaging,
  MemberNavigationState,
  MemberStatus,
  MetadataSection,
  OverviewData,
} from '../../types/models';

const displayedColumns: string[] = ['year', 'pay', 'hours'];
const headerColumns = ['Year', 'Pay', 'Hours'];
const styleColums = [STYLE_COLUMN.TEXT, STYLE_COLUMN.TEXT, STYLE_COLUMN.TEXT];
const PAGE_SIZE_CONST = '-ptg-member-profile-section-pageSize';

@Component({
  selector: 'ptg-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss'],
})
export class MemberDetailsComponent implements OnInit, OnDestroy {
  readonly SideType = SideType;
  currentFund: any = {};
  pageSize: number = 10;
  memberId: string = '';
  configColums: any = { styleColums, displayedColumns, headerColumns };
  isLocked$: Observable<any> = new Observable();
  detailInfo: MemberDetailState | any = {
    isLoading: true,
    isDeleting: false,
    wasDeleted: false,
    memberDetail: {},
  };
  identityKey: string = '';
  memberKey: string = '';
  isLocked = false;
  listSection: MetadataSection[] = [];
  message = '';
  sectionName = '';
  bannerType: BannerType = BannerType.Hidden;
  overviewData!: OverviewData[];
  updateLayoutSubscription$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );
  @ViewChild('memberDetail') memberDetailRef!: ElementRef;
  unsubscribe$ = new Subject<void>();
  listStatus!: MemberStatus[];
  memberStatus: MemberStatus | undefined;
  memberNavigationState!: MemberNavigationState;
  fundId: string = '';
  statusId: any;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participants',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Overview',
      url: '',
    },
  ];
  settings: Breadcrumb[] = [
    {
      name: 'Header',
      url: '/member/profile-header-configuration-list',
    },
    {
      name: 'Overview',
      url: '/member/profile-overview-configuration',
    },
    {
      name: 'Navigation',
      url: '/member/profile-navigation-configuration/',
    },
  ];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'Send Email',
      icon: 'send-email',
      classInput: 'add-button',
      isDisabled: true,
      isSvgIcon: true,
    },
  ];
  isNoData: boolean = false;
  profileOverviewName: string = '';
  actionItems: ActionItem[] = [];
  memberDetailEmail = '';
  defaultEmail: string = `${environment.defaultSenderMail}`;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    private router: Router,
    private memberDetailService: MemberDetailService,
    private nextPaymentService: NextPaymentService,
    private store: Store<fromReducer.State>
  ) {
    iconRegistry.addSvgIconLiteral(
      'remove-account',
      sanitizer.bypassSecurityTrustHtml(removeAccountIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'thumbs-up',
      sanitizer.bypassSecurityTrustHtml(shieldCheckIcon)
    );
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit(): void {
    this.store.select(fromReducer.selectCurrentFundState).subscribe((el) => {
      this.fundId = el.id;
      this.pageSize = el.defaultPageSize ?? 10;
      this.currentFund = el;
      this.pageSize =
        Number(
          sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
        ) === 0
          ? this.pageSize
          : Number(
              sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
            );
    });
    sessionStorage.setItem('fundId', this.currentFund.fundType);
    // Remove the "Send Email" button
    if (this.currentFund.fundType !== FundType.MobileAL) {
      this.functionButtons = this.functionButtons.filter(
        (button) => button.buttonName !== 'Send Email'
      );
    }
    this.route.params.subscribe((params) => {
      this.memberId = params.id;
      this.memberDetailService.memberId = params.id;
      this.nextPaymentService.memberId = params.id;
      this.getMemberDetail();
      this.getSettings();
    });

    this.memberStore
      .pipe(select(fromMember.selectMemberData), takeUntil(this.unsubscribe$))
      .subscribe((memberData) => {
        this.detailInfo.memberInfo = memberData.memberInfo;
        if (this.detailInfo.memberInfo) {
          this.identityKey = this.detailInfo.memberInfo.identityKey;
          this.memberKey = this.detailInfo.memberInfo.memberKey;
          this.isLocked = this.detailInfo.memberInfo.isLocked;
        }
        this.detailInfo.isLoading =
          memberData.isLoading ||
          memberData.isLoadingMemberInfo ||
          memberData.isLoadingMetadataOverview;
        this.detailInfo.isDeleting = memberData.isDeleting;
        this.detailInfo.memberDetail = memberData.memberDetail;
        this.manageEmailOperation(memberData.memberDetail);
        this.detailInfo.profileOverviewConfig =
          memberData.profileOverviewConfig;

        if (this.detailInfo.memberDetail !== null) {
          this.listSection = this.listSection.map((section) => {
            const data = this.detailInfo.memberDetail[section.key];
            return {
              key: section.key,
              name: section.name,
              importKey: section.importKey,
              type: section.type,
              hasAttachment: section.hasAttachment,
              isStatic: section.isStatic,
              onSide: section.onSide,
              properties: section.properties,
              lookupTables: section.lookupTables,
              order: section.order,
              sectionTitle: section.sectionTitle,
              showSectionDataless: section.showSectionDataless,
              showPropertyDataless: section.showPropertyDataless,
              isRemoved: section.isRemoved,
              hasData: data?.total > 0,
              isDisplay:
                (!section.isRemoved &&
                  section.properties?.length &&
                  data?.total) ||
                (!data?.total && section.showSectionDataless) ||
                (section.isRemoved && section.showSectionDataless),
            };
          }) as MetadataSection[];

          // Add the "Add [Section Label]" items into the Action dropdown
          this.actionItems = this.listSection
            .filter(
              (section) =>
                !section.isDisplay &&
                section.key !== FixedSection.ParticipantRelationship &&
                section.key !== FixedSection.ServiceHistory &&
                section.key !== FixedSection.MuniTotal
            )
            .sort((a, b) => {
              if (a.onSide === SideType.Right && b.onSide === SideType.Left)
                return 1;
              if (a.onSide === SideType.Left && b.onSide === SideType.Right)
                return -1;
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return 0;
            })
            .map(
              (section) =>
                ({
                  id: section.key,
                  title: `Add ${section.sectionTitle}`,
                  icon: 'add',
                } as ActionItem)
            );
        }

        const isLoadAllSectionCompleted =
          !memberData?.metadataOverview?.length ||
          (!!memberData?.metadataOverview?.length &&
            memberData?.metadataOverview?.length ===
              Object.keys(this.detailInfo?.memberDetail || {}).length);
        this.isNoData =
          !this.detailInfo.isLoading &&
          !memberData.isDeleting &&
          isLoadAllSectionCompleted &&
          !this.listSection.length;
        this.profileOverviewName = memberData?.profileOverviewName;
        if (
          this.detailInfo.memberInfo &&
          Object.keys(this.detailInfo.memberInfo).length > 0
        ) {
          this.getOverviewData();
          if (
            this.statusId &&
            this.statusId !== this.detailInfo.memberInfo?.statusId
          ) {
            this.memberStore.dispatch(
              MemberDetailActions.getMetadataBasedOnProfileOverviewConfig({
                memberId: this.memberId,
              })
            );
            dispatchGetMemberNavigationList(this.store, this.memberId);
          }
          this.statusId = this.detailInfo.memberInfo?.statusId;
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMetadataOverview),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((metaData) => {
        if (metaData) {
          const listMetadata = (deepClone(metaData) as MetadataSection[]).sort(
            (a, b) => a.order - b.order
          );
          const listMetadataSection = listMetadata
            .filter((item) => item.order > -1)
            .map((el) => el.key);
          this.listSection = listMetadata.filter(
            (ele) => ele.properties && ele.properties.length > 0
          );
          if (listMetadataSection.length > 0) {
            this.memberStore.dispatch(
              MemberDetailActions.getMemberDetail({
                id: this.memberId,
                listSection: listMetadataSection,
                pageSize: this.pageSize,
              })
            );
          }
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberDetailState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el?.removeSectionState === STATE.SUCCESS) {
          this.memberStore.dispatch(MemberDetailActions.removeSectionReset());
          showBanner.call(
            this,
            BannerType.Success,
            this.sectionName,
            ACTION.REMOVE
          );
          this.getMemberDetail();
          this.getSettings();
        }

        if (el?.removeSectionState === STATE.FAIL) {
          this.memberStore.dispatch(MemberDetailActions.removeSectionReset());
          showBanner.call(
            this,
            BannerType.Fail,
            this.sectionName,
            ACTION.REMOVE
          );
        }

        if (el.removeMemberState === STATE.FAIL) {
          showBanner.call(this, BannerType.Fail, 'participant', ACTION.REMOVE);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
        }

        if (el.wasDeleted) {
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          this.memberStore.dispatch(
            MemberListActions.removeMemberSuccess({
              memberKey: this.memberKey,
            })
          );
          const memberIndex = this.memberNavigationState.members.findIndex(
            (member) => this.memberId === member._memberId
          );
          const query = JSON.parse(
            JSON.stringify(this.memberNavigationState.query)
          );
          if (memberIndex === 0 && query.pageIndex > 1) {
            query.pageIndex -= 1;
          }

          // Close
          this.store.dispatch(
            LayoutActions.setTabHiddenState({
              tabName: DisplayedTabName.IndividualParticipant,
              isHidden: true,
            })
          );

          let url = '/member';
          this.router.navigate([url], { state: { beforeState: query } });
        }
        if (el.addSectionState?.saveSectionDataSuccess) {
          this.getMemberDetail();
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          if (
            el.addSectionState?.sectionData?.type === SectionLayout.List &&
            !el.addSectionState?.isRemove
          ) {
            this.openOverview(
              el.addSectionState.sectionData.key,
              true,
              el.addSectionState.saveSectionDataSuccess
            );
          }
          showBanner.call(
            this,
            BannerType.Success,
            el.addSectionState?.sectionData?.type === SectionLayout.List
              ? 'Record'
              : el.addSectionState?.sectionData?.sectionTitle!,
            el.addSectionState.isRemove ? ACTION.REMOVE : ACTION.ADD
          );
        } else if (el.addSectionState.sectionData) {
          showBanner.call(
            this,
            BannerType.Fail,
            el.addSectionState?.sectionData?.type === SectionLayout.List
              ? 'Record'
              : el.addSectionState?.sectionData?.sectionTitle!,
            el.addSectionState.isRemove ? ACTION.REMOVE : ACTION.ADD
          );
        }

        if (el.addSectionState?.reloadSection) {
          if (el.addSectionState?.sectionData?.key === 'demographics') {
            this.memberStore.dispatch(
              MemberDetailActions.getMetadataBasedOnProfileOverviewConfig({
                memberId: this.memberId,
              })
            );
          } else {
            this.getMemberSection(el.addSectionState?.sectionData?.key || '');
          }
        }

        this.updateLayoutSubscription$.next();

        if (el.lockMemberState === STATE.SUCCESS) {
          showBanner.call(this, BannerType.Success, 'Participant', ACTION.LOCK);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
        }

        if (el.lockMemberState === STATE.FAIL) {
          showBanner.call(this, BannerType.Fail, 'participant', ACTION.LOCK);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
        }

        if (el.unLockMemberState === STATE.FAIL) {
          showBanner.call(this, BannerType.Fail, 'participant', ACTION.UNLOCK);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
        }

        if (el.unLockMemberState === STATE.SUCCESS) {
          showBanner.call(
            this,
            BannerType.Success,
            'Participant',
            ACTION.UNLOCK
          );
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
        }

        if (el?.setMemberEventState) {
          const query: MemberDetailWithPaging = {
            pageIndex: FIRST_PAGE,
            totalPerPage: 10,
            sortField: 'statusDate',
            sortType: 1,
          };
          this.memberStore.dispatch(
            MemberDetailActions.getSectionData({
              memberId: this.memberId,
              sectionKey: FixedSection.Status,
              query,
            })
          );
          this.memberStore.dispatch(
            MemberDetailActions.getMemberById({ id: this.memberId })
          );
          this.memberStore.dispatch(
            MemberDetailActions.clearSetMemberEventState()
          );
        }
      });

    this.memberStore
      .pipe(select(fromMember.selectListStatus), takeUntil(this.unsubscribe$))
      .subscribe((listStatus) => {
        this.listStatus = listStatus;
        this.getMemberEventStatus();
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberNavigationState),
        distinctUntilChanged((previous, current) =>
          deepEqual(previous, current)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberNavigationState) => {
        this.memberNavigationState = memberNavigationState;
      });
  }
  getOverviewData() {
    this.overviewData = [];
    this.overviewData.push(
      this.detailInfo?.memberInfo?.profileHeaderData
        ? this.detailInfo.memberInfo.profileHeaderData[0]
        : { label: '', value: '' }
    );
    this.getMemberEventStatus();
  }

  getMemberDetail() {
    this.memberStore.dispatch(
      MemberDetailActions.getMemberById({ id: this.memberId })
    );
    this.memberStore.dispatch(
      MemberDetailActions.getMetadataBasedOnProfileOverviewConfig({
        memberId: this.memberId,
      })
    );
  }

  getMemberEventStatus() {
    if (!this.overviewData?.length || !this.detailInfo.memberInfo?.statusId) {
      return;
    }
    this.listStatus = this.listStatus?.filter(
      (status) =>
        status.active || status.id === this.detailInfo.memberInfo?.statusId
    );
    this.memberStatus = this.listStatus?.find(
      (item) => item.id === this.detailInfo.memberInfo?.statusId
    );
    this.memberDetailService.isRetiree = this.memberStatus?.name === 'Retired';
    const currentEvent = this.memberStatus?.memberEvent?.find(
      (item) => item.id === this.detailInfo.memberInfo?.eventId
    );
    this.overviewData[1] = {
      label: 'Latest Event',
      value: currentEvent?.name,
      config: {
        color: this.memberStatus?.color,
        iconName: this.memberStatus?.iconName,
      },
    };
  }

  getMemberSection(sectionKey: string) {
    this.memberStore.dispatch(
      MemberDetailActions.getSectionData({
        memberId: this.memberId,
        sectionKey,
      })
    );
  }

  showAttachments(configSection: MetadataSection) {
    if (configSection.hasAttachment) {
      this.dialog.open(MemberSectionAttachmentComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        data: {
          memberId: this.memberId,
          rowId: configSection.rowId,
          configSection,
        },
      });
    }
  }

  downloadPdf(statement: any): void {
    const pdfUrl = this.signatoryService.getUrl(statement, (fileKey) => {
      this.fileService.signFile(fileKey).subscribe((sig) => {
        this.downloadPdf({
          ...statement,
          signature: sig,
        });
      });
    });

    if (pdfUrl) window.open(pdfUrl, '_blank');
  }

  lockAccount(isLock: boolean): void {
    if (isLock) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: 'Are you sure you want to lock this account?',
          title: 'Lock Participant',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.memberStore.dispatch(
            MemberDetailActions.lockMember({ id: this.memberId })
          );
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: 'Are you sure you want to unlock this account?',
          title: 'Unlock Participant',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.memberStore.dispatch(
            MemberDetailActions.unlockMember({ id: this.memberId })
          );
        }
      });
    }
  }

  removeMember(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'THIS IS A DESTRUCTIVE ACTION',
        type: ConfirmType.Destruct,
        text: `Removing this Participant will remove all traces of them, including all data and files, from the system.<br> Are you sure you want to proceed? This action is not reversible.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          MemberDetailActions.removeMember({ id: this.memberId })
        );
      }
    });
  }

  deleteSection(configSection: any): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove the ${configSection.sectionTitle}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sectionName = configSection.sectionTitle;
        this.memberStore.dispatch(
          MemberDetailActions.removeMemberSection({
            id: this.memberId,
            sectionKey: configSection.key,
          })
        );
      }
    });
  }

  editSection(configSection: MetadataSection) {
    let isListWithoutData =
      Object.entries(this.detailInfo.memberDetail).find(
        ([key, value]) => key === configSection.key
      )?.[1] === null;
    if (configSection.type === SectionLayout.List && !isListWithoutData) {
      this.openOverview(configSection.key);
      return;
    }

    if (configSection.type === SectionLayout.List && isListWithoutData) {
      this.addOrEditSectionRecord(configSection, {
        addToTop: true,
        isAddNew: true,
        notShowFile: true,
      });
      return;
    }

    this.addOrEditSectionRecord(configSection, {
      addToTop: false,
      isAddNew: false,
      notShowFile: false,
    });
  }

  addOrEditSectionRecord(configSection: MetadataSection, options: any): void {
    this.openOrAddEditDialog(options, configSection);
  }

  openOrAddEditDialog(options: any, configSection: MetadataSection) {
    const sectionData = JSON.parse(JSON.stringify(configSection));
    const dialogRef = this.dialog.open(AddMemberSectionDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        section: sectionData,
        data: this.detailInfo.memberDetail,
        memberId: this.memberId,
        metadataItem: sectionData.key,
        memberStatus: this.memberStatus,
        ...options,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        setTimeout(() => {
          this.memberStore.dispatch(
            getMemberProfileHeadersDataAction({ memberId: this.memberId })
          );
        }, 2000);
        this.getSettings();
      }
    });
  }

  onAddMemberStatusHistory(section: any) {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: section?.sectionTitle,
        statusHistory: undefined,
        isRedirectFromOverview: true,
        sectionData: section,
      },
    });
  }

  openOverview(
    sectionKey: string,
    isShowBanner?: boolean,
    saveSectionDataSuccess?: boolean
  ) {
    this.router.navigateByUrl(`/member/detail/${sectionKey}/${this.memberId}`, {
      state: {
        isShowBanner,
        saveSectionDataSuccess,
      },
    });
  }

  ngOnDestroy() {
    this.memberStore.dispatch(MemberDetailActions.clearMemberDetail());
    this.memberStore.dispatch(MetadataActions.clearMetadata());
    this.updateLayoutSubscription$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editMemberStatusEvent() {
    this.dialog.open(EditMemberStatusEventComponent, {
      panelClass: ['edit-popup', 'edit-member-status-event-dialog'],
      disableClose: true,
      height: 'auto',
      autoFocus: false,
      data: {
        memberId: this.memberId,
        eventId: this.detailInfo.memberInfo?.eventId,
        statusId: this.detailInfo.memberInfo?.statusId,
        memberStatusList: this.listStatus,
      },
    });
  }

  getSettings() {
    const queryParam = document.location.search.includes(
      STRING_QUERY_PARAM.SEARCH
    )
      ? `?${STRING_QUERY_PARAM.SEARCH}`
      : '';
    this.settings = [
      {
        ...this.settings[0],
        url: `/member/profile-header-configuration/${this.memberId}${queryParam}`,
      },
      {
        ...this.settings[1],
        url: `/member/profile-overview-configuration/${this.memberId}${queryParam}`,
      },
      {
        ...this.settings[2],
        url: `/member/profile-navigation-configuration/${this.memberId}${queryParam}`,
      },
    ];
  }

  backToMemberList(event: string) {
    let url = '/member';
    this.router.navigate([url], {
      state: { beforeState: this.memberNavigationState.query },
    });
  }

  onClickActionItem(sectionKey: string): void {
    const section = this.listSection.find((item) => item.key === sectionKey);
    if (section) this.editSection(section);
  }

  emitFunction(event: FunctionButtonData) {
    switch (event.buttonName) {
      case 'Send Email':
        const dialogRef = this.dialog.open(EmailComponent, {
          panelClass: '',
          disableClose: false,
          data: {
            title: 'Send Email',
            mail: {
              recipients: this.defaultEmail.split(' '),
              recipientsTo: this.memberDetailEmail.split(' '),
            },
            createdBy: 'System',
            isSingleRecipient: false,
          },
          height: 'auto',
          width: 'auto',
        });
        break;
    }
  }

  manageEmailOperation(memberDetail: any) {
    const contactInformationKey =
      sessionStorage.getItem('keyContactInformation') || 'demographics';
    if (memberDetail && memberDetail[contactInformationKey]) {
      let emailButtonIndex = this.functionButtons.findIndex(
        (x) => x.buttonName === 'Send Email'
      );

      const emailKeys = Object.keys(memberDetail[contactInformationKey]).filter(
        (key) => key.startsWith('email')
      );
      const emailValues = emailKeys.map(
        (key) => memberDetail[contactInformationKey][key]
      );
      const finalEmail = emailValues[0];
      if (finalEmail) {
        this.memberDetailEmail = finalEmail?.toString();
        this.functionButtons[emailButtonIndex].isDisabled = false;
      } else {
        this.functionButtons[emailButtonIndex].isDisabled = true;
      }
    }
  }
}
