import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getDateFormatISO, getTimeZone } from '@ptg-shared/utils/string.util';

import { environment } from 'src/environments/environment';
import {
  NewDeduction,
  DeductionListQuery,
  DeductionList,
} from '../types/models';
@Injectable({
  providedIn: 'root',
})
export class PaymentDeductionsService {
  get Endpoint(): string {
    return `${environment.apiUrl}/Deductions`;
  }
  currentDeduction: any;
  searchValue: string = '';

  constructor(private httpClient: HttpClient) {}

  deductionsList(
    query: DeductionListQuery,
    clientId: string
  ): Observable<DeductionList> {
    let params = new HttpParams();
    params = params.append('ClientId', clientId);
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    if (query.vendorId) {
      params = params.append('VendorId', query.vendorId);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }

    return this.httpClient.get<DeductionList>(this.Endpoint, { params });
  }

  getActiveDeduction() {
    return this.httpClient.get<DeductionList>(`${this.Endpoint}/Active`);
  }

  checkExists = (bodyCheck: any) => {
    let body = {
      ...bodyCheck,
      deductionId: this.currentDeduction?.id,
    };
    if (this.currentDeduction?.effectiveStartDate) {
      body = {
        ...body,
        effectiveStartDate:
          getDateFormatISO(this.currentDeduction?.effectiveStartDate) +
          'T' +
          new Date().toLocaleTimeString([], { hour12: false }) +
          getTimeZone(),
        effectiveEndDate: this.currentDeduction?.effectiveEndDate
          ? getDateFormatISO(this.currentDeduction?.effectiveEndDate) +
            'T' +
            new Date().toLocaleTimeString([], { hour12: false }) +
            getTimeZone()
          : null,
      };
    } else {
      delete body.effectiveStartDate;
      delete body.effectiveEndDate;
    }
    if (this.currentDeduction?.deductionType === 0) {
      body = {
        ...body,
        deductionType: this.currentDeduction?.deductionType,
        deductionSetting: this.currentDeduction?.deductionSetting,
      };
    } else {
      delete body.deductionType;
      delete body.deductionSetting;
    }
    return this.httpClient.post(`${this.Endpoint}/Exists`, body);
  };

  createDeduction(body: NewDeduction) {
    let payload = {
      ...body,
      effectiveStartDate:
        getDateFormatISO(body.effectiveStartDate) +
        'T' +
        new Date().toLocaleTimeString([], { hour12: false }) +
        getTimeZone(),
      effectiveEndDate: body.effectiveEndDate
        ? getDateFormatISO(body.effectiveEndDate) +
          'T' +
          new Date().toLocaleTimeString([], { hour12: false }) +
          getTimeZone()
        : null,
    };
    return this.httpClient.post(this.Endpoint, payload);
  }

  updateDeduction(id: string, body: NewDeduction) {
    let payload = {
      ...body,
      effectiveStartDate:
        getDateFormatISO(body.effectiveStartDate) +
        'T' +
        new Date().toLocaleTimeString([], { hour12: false }) +
        getTimeZone(),
      effectiveEndDate: body.effectiveEndDate
        ? getDateFormatISO(body.effectiveEndDate) +
          'T' +
          new Date().toLocaleTimeString([], { hour12: false }) +
          getTimeZone()
        : null,
    };
    return this.httpClient.put(`${this.Endpoint}/${id}`, payload);
  }

  removeDeduction(deductionId: string) {
    return this.httpClient.delete(`${this.Endpoint}/${deductionId}`);
  }

  getSearchPayee(searchValue: string) {
    this.searchValue = searchValue;
    return this.httpClient
      .post<any>(`${this.Endpoint}/SearchPayee`, { searchValue: searchValue })
      .pipe(map((el) => el.payeeResult));
  }

  getSearchEstablishedMembers(searchValue: string) {
    this.searchValue = searchValue;
    let params = new HttpParams();
    params = params.append('SearchValue', searchValue);
    return this.httpClient
      .get<any>(`${environment.apiUrl}/Members/SearchEstablishedMembers`, {
        params: params,
      })
      .pipe(map((el) => el.establishedMembers));
  }
}
