import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { ActiveFundService } from '@ptg-fund-list/services/active-fund.service';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';

import { BaseComponent } from '../../../components';
import {
  ItemMenuType,
  NAVIGATION_CONST,
  SidebarOpenedStatus,
} from '../../../layout/constance/layout.const';
import { MenuGroup, MenuItem } from '../../../layout/models/layout.model';
import { Auth0Service } from '../../../auth/services/auth0.service';
import { CheckPermissionService } from '../../../services/check-permission.service';
import { HistoryService } from '../../../services/history.service';
import { deepClone } from '../../../utils/common.util';

@Component({
  selector: 'ptg-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent {
  readonly SIDE_BAR_OPENED_STATUS_ENUM = SidebarOpenedStatus;
  readonly NAVIGATION_CONST = NAVIGATION_CONST;

  currentMenuGroups: MenuGroup[] = [];
  currentMenuGroupIndex: number = 0;

  @Input() menuGroups: MenuGroup[] = [];
  @Input() isDisabled: boolean = false;

  @Input() sidebarOpenedStatus: SidebarOpenedStatus =
    SidebarOpenedStatus.Opened;
  @Output() sidebarOpenedStatusChange: EventEmitter<SidebarOpenedStatus> =
    new EventEmitter<SidebarOpenedStatus>();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    public authService: Auth0Service,
    private activeFundService: ActiveFundService,
    public checkPermissionService: CheckPermissionService,
    private historyService: HistoryService,
    public memberDetailService: MemberDetailService,
    public route: ActivatedRoute
  ) {
    super();

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.menuGroups) {
      this.getCurrentMenuState();
    }
    if (
      changes.sidebarOpenedStatus &&
      this.sidebarOpenedStatus === SidebarOpenedStatus.Closed
    ) {
      this.getCurrentMenuState();
    }
  }

  getCurrentMenuState() {
    this.currentMenuGroups = deepClone(this.menuGroups);
    this.currentMenuGroups.forEach((menuGroup) => {
      if (menuGroup.isSVG) {
        this.iconRegistry.addSvgIconLiteral(
          menuGroup.name,
          this.sanitizer.bypassSecurityTrustHtml(menuGroup.icon)
        );
      }
    });
    this.currentMenuGroupIndex =
      this.currentMenuGroups.findIndex((menuGroup) => menuGroup.isSelected) > -1
        ? this.currentMenuGroups.findIndex((menuGroup) => menuGroup.isSelected)
        : 0;
  }

  setSidebarOpenedStatus(sidebarOpenedStatus: SidebarOpenedStatus) {
    this.sidebarOpenedStatusChange.emit(sidebarOpenedStatus);
  }

  selectMenuGroup(index: number) {
    const menuGroup = this.currentMenuGroups[index];

    if (!menuGroup?.menu?.length) {
      this.getCurrentMenuState();
      this.setSidebarOpenedStatus(SidebarOpenedStatus.Closed);
      if (menuGroup.routerLink) {
        this.router.navigateByUrl(menuGroup.routerLink);
      }
      return;
    }
    this.currentMenuGroupIndex = index;
    const isChangeMenuGroup =
      this.currentMenuGroups.findIndex((item) => item.isSelected) !== index;
    if (isChangeMenuGroup) {
      this.currentMenuGroups = this.currentMenuGroups.map((item, itemIndex) => {
        item.isSelected = itemIndex === index;
        return item;
      });
      if (this.sidebarOpenedStatus === SidebarOpenedStatus.Closed) {
        this.setSidebarOpenedStatus(SidebarOpenedStatus.Opened);
      }
      return;
    }
    if (this.sidebarOpenedStatus === SidebarOpenedStatus.Opened) {
      this.setSidebarOpenedStatus(SidebarOpenedStatus.Closed);
      this.getCurrentMenuState();
    } else {
      this.setSidebarOpenedStatus(SidebarOpenedStatus.Opened);
    }
  }

  onClickMenuItem(menuItem: MenuItem) {
    if (
      menuItem?.type === ItemMenuType.NextPayment ||
      menuItem?.type === ItemMenuType.PaymentHistory
    ) {
      this.historyService.isBackNextPayroll = false;
      sessionStorage.setItem(
        'isBackNextPayroll',
        this.historyService.isBackNextPayroll.toString()
      );
    }
  }
}
