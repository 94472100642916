<ng-container [ngSwitch]="data?.type">
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_CURRENCY">{{ (data.value | numberLocalDecimal:'$') || '-' }}</span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_PERCENTAGE">{{ (data.value | numberLocalDecimal:'') || '-' }}{{ (data.value | numberLocalDecimal:'') ? ' %' : '' }}</span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_DATE">{{ !data.value ? '-' : data.value | date: 'MM/dd/yyyy' }}</span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_PERSON_NAME">{{ !data.value ? '-' : data.value | personName : data?.typedValue }}</span>
  <ng-container *ngSwitchCase="PROPERTY_TYPE.TYPE_ADDRESS">
    <ng-container *ngIf="!isSeparateLineAddress; else separateLineAddressTemplate">
      <span class="value" [ngClass]="{'truncate': isTruncate}" >{{ !data.value ? '-' : data.value | address }}</span>
    </ng-container>
  </ng-container>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_DECIMAL">
    {{ !data.value ? '-' : data.value | numberDecimal:{decimal: data?.typedValue?.fractionalLengthInput, isCheckCurrentDecimal: true} }}
  </span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_WHOLE_NUMBER">{{ (data.value | numberDecimal) || '-' }}</span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_STATUS">
    <div class="icon-status-container">
      <mat-icon *ngIf="data?.value && data?.typedValue?.iconName" class="icon-status" [ngStyle]="{'color': data?.typedValue?.color}">{{ data?.typedValue?.iconName }}</mat-icon>
      <div class="status-name" [ngClass]="{'truncate': isTruncate}">{{ !data.value ? '-' : data.value }}</div>
    </div>
  </span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_SSN">{{ (data.value | ssnNumber) || '-' }}</span>
  <ng-container *ngSwitchCase="PROPERTY_TYPE.TYPE_RICH_TEXT">
    <ptg-view-rich-text [title]="data?.columnName" [content]="data.value" defaultContent="-"></ptg-view-rich-text>
  </ng-container>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_DATE_TIME">{{ !data.value ? '-' : data?.value | date: 'MM/dd/yyyy hh:mm a' }}</span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_PHONE">{{ (data?.value | phoneNumber) || '-' }}</span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchCase="PROPERTY_TYPE.TYPE_BOOLEAN">
    <mat-icon class="icon-boolean icon-true" *ngIf="data?.value">check</mat-icon>
    <mat-icon class="icon-boolean icon-false" *ngIf="!data?.value">close</mat-icon>
  </span>
  <span class="value" [ngClass]="{'truncate': isTruncate}" *ngSwitchDefault>{{ data.value || '-' }}</span>
</ng-container>

<ng-template #separateLineAddressTemplate>
  <div class="value w-100">
    <ptg-view-address [value]="data?.value" [options]="data?.options"></ptg-view-address>
  </div>
</ng-template>
