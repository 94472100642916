import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GUID_EMPTY } from '@ptg-shared/constance/value.const';
import {
  getDateFormatISO,
  getTimeZone,
  isEmpty,
} from '@ptg-shared/utils/string.util';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';

import { environment } from 'src/environments/environment';
import {
  BoardBank,
  GetNextPayrollTotalDeductionQuery,
  NextPayroll,
  PayrollActionState,
  PayrollConfigurationInfo,
  SetPayrollStepBody,
  TotalDeduction,
  TotalPayees,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class NextPayrollService {
  payrollId = '';
  boardBankId: string | undefined = '';
  warrantFile: any;
  selectedPayee: any;
  msgErrorStartingCheckNumber = '';
  isArchived = false;

  constructor(private httpClient: HttpClient) {}

  getNextPayroll(filterType?: any): Observable<NextPayroll> {
    let params = new HttpParams();
    if (!isEmpty(filterType)) {
      params = params.append('FilterType', filterType);
    }
    if (this.isArchived) {
      params = params.append('PayrollId', this.payrollId);
    }
    return this.httpClient.get<NextPayroll>(
      `${environment.apiUrl}/Payroll/NextPayroll`,
      { params }
    );
  }

  setPayrollStep(body: SetPayrollStepBody): Observable<void> {
    // return of({});
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/SetStep`,
      body
    );
  }

  checkExistWarrantNumber = (body: any) => {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/WarrantNumber/Exists`,
      { warrantNumber: body.warrantNumber }
    );
  };

  validateStartingCheckNumber(body: any) {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/StartingCheckNumber/Validate`,
      body
    );
  }

  checkApiStartingCheckNumber(editValue?: string): AsyncValidatorFn {
    // Need obs to call api for check
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (
        editValue &&
        editValue.trim() === control.value.trim() &&
        +control.value < 1
      ) {
        return of(null);
      }

      return this.validateStartingCheckNumber({
        startingCheckNumber: control.value?.trim(),
        boardBankId: this.boardBankId,
      }).pipe(
        map((response: any) => {
          if (response && !response.isValid) {
            this.msgErrorStartingCheckNumber = response.message;
            return { inValidAsync: true };
          }
          return null;
        }),
        catchError((err) => {
          this.msgErrorStartingCheckNumber = err.error?.StartingCheckNumber
            ? err.error?.StartingCheckNumber[0]
            : '';
          return of({ inValidAsync: true });
        })
      );
    };
  }

  updateStartingCheckNumber(body: any) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/StartingCheckNumber`,
      body
    );
  }

  updateDepositDate(body: any) {
    let payload = {
      ...body,
      depositDate:
        getDateFormatISO(body.depositDate) +
        'T' +
        new Date().toLocaleTimeString([], { hour12: false }) +
        getTimeZone(),
    };
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/DepositDate`,
      payload
    );
  }

  updateWarrantNumber(body: any) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/WarrantNumber`,
      body
    );
  }
  getNextPayrollList(query: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('PageSize', query.totalPerPage);
    params = params.append('PageIndex', query.pageIndex);
    params = params.append('StatusType', query.statusType);
    if (query.sortField === 'firstName' || query.sortField === 'fullName') {
      params = params.append('SortNames', 'FirstName');
      params = params.append('SortNames', 'LastName');
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    } else if (query.sortField === 'checkNumber') {
      params = params.append('SortNames', 'CheckNumberValue');
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    } else if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    if (query?.filter?.changesOnly) {
      params = params.append('ChangesOnly', true);
    }
    if (query?.filter?.zacsOnly) {
      params = params.append('ZacsOnly', true);
    }
    if (query?.filter?.checksOnly) {
      params = params.append('ChecksOnly', true);
    }
    if (query?.filter?.errorsOnly) {
      params = params.append('ErrorOnly', true);
    }
    if (query?.filterType != undefined) {
      params = params.append('FilterType', query?.filterType);
    }
    if (query?.id || this.payrollId) {
      return this.httpClient.get(
        `${environment.apiUrl}/Payroll/${query?.id}/Payees`,
        { params }
      );
    } else {
      return of(null);
    }
  }

  updateTransaction(body: any) {
    const data = {
      boardBankId: body.boardBankId,
      startingCheckNumber: body.startingCheckNumber,
    };
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/UpdateTransaction`,
      data
    );
  }

  uploadWarrant() {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/UploadWarrant`,
      this.warrantFile
    );
  }

  achSent() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/AchSent`,
      null
    );
  }

  checksPrinted() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/ChecksPrinted`,
      null
    );
  }

  validateMissingInfo(sourceId: string, sourceType: number) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/Payroll/${sourceId}/ValidatePaymentInformation`,
      { sourceType }
    );
  }

  validateCheckNumber = (body: any) => {
    let dataCheck = {
      payeeId: this.selectedPayee?.id,
      checkNumber: body.checkNumber,
      orderOfPayment: this.selectedPayee?.orderOfPayment,
      oldCheckNumber: this.selectedPayee?.checkNumber,
    };
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/PayeePayments/ValidateCheckNumber`,
      dataCheck
    );
  };

  editCheckNumber(body: any) {
    let data = {
      ...body,
      payeeId: this.selectedPayee?.id,
      orderOfPayment: this.selectedPayee?.orderOfPayment,
      oldCheckNumber: this.selectedPayee?.checkNumber,
    };
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/PayeePayments/CheckNumber`,
      data
    );
  }

  downloadACHFile() {
    let context = new HttpContext().set(
      SKIP_ERRORS,
      'MissingPaymentInformaionException'
    );
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/DownloadAchFile`,
      {
        context,
        responseType: 'text',
      }
    );
  }

  editCheckMemo(checkMemo: string) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/CheckMemo`,
      { checkMemo }
    );
  }
  selectBank() {}
  finalizePayroll() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/Finalize`,
      null
    );
  }

  getTotalDeduction(query: GetNextPayrollTotalDeductionQuery) {
    let params = new HttpParams();
    if (query?.selectedStep != undefined) {
      params = params.append('SelectedStep', query?.selectedStep);
    }
    if (query?.filterType != undefined) {
      params = params.append('FilterType', query?.filterType);
    }
    return this.httpClient.get<TotalDeduction>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/TotalDeduction`,
      { params }
    );
  }

  checkBoardBankDisabled(boardBankId: string) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/BoardInformation/BoardBanks/${boardBankId}/IsDisabled`,
      null
    );
  }

  getSelectBoardBanks(): Observable<{ boardBanks: BoardBank[] }> {
    return this.httpClient.get<{ boardBanks: BoardBank[] }>(
      `${environment.apiUrl}/BoardInformation/BoardBanks`
    );
  }

  setPayrollBoardBank(boardBankId: string) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/SetBoardBank`,
      { boardBankId }
    );
  }

  reCalculateStartingCheckNumber(boardBankId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/RecalculateStartingCheckNumber/${boardBankId}`
    );
  }
  resetPayroll(body: any) {
    return this.httpClient.put<any>(
      `${environment.apiUrl}/Payroll/NextPayroll/${body.payrollId}/Reset`,
      { displayResetPopup: body.displayResetPopup }
    );
  }

  generateEDI(payrollId: any, selectedBankId: any, boardBankId: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/${payrollId}/GenerateEDI`,
      { selectedBankId: selectedBankId, boardBankId: boardBankId },
      { responseType: 'text' }
    );
  }

  reissuePayment(payrollId: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/Payroll/NextPayroll/${payrollId}/ReissuePayment`,
      null
    );
  }

  bankErrorResponse(sourceTypeId: any, body: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/Payroll/${sourceTypeId}/BankErrorResponse`,
      body
    );
  }

  getTotalPayees(): Observable<TotalPayees> {
    return this.httpClient.get<TotalPayees>(
      `${environment.apiUrl}/Payroll/NextPayroll/${
        this.payrollId || GUID_EMPTY
      }/TotalPayees`
    );
  }

  getPayrollConfigurationInfo(): Observable<any> {
    return this.httpClient.get<PayrollConfigurationInfo>(
      `${environment.apiUrl}/Members/ConfiguredIdName`
    );
  }

  getPayrollActionState(): Observable<PayrollActionState> {
    let context = new HttpContext().set(SKIP_ERRORS, '404');
    return this.httpClient.get<PayrollActionState>(
      `${environment.apiUrl}/Payroll/NextPayroll/${this.payrollId}/ConcurrentState`,
      { context }
    );
  }
}
