import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FilterPropertyType,
  INPUT_TYPE,
} from '@ptg-member/constance/member-list.const';
import { FilterInputType } from '@ptg-member/types/models';
import { SetDropStatementRequest } from '@ptg-member/types/models/drop-statement.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { DropStatementService } from '../../services/drop-statement-service.service';

@Component({
  selector: 'ptg-drop-statement-popup',
  templateUrl: './drop-statement-popup.component.html',
  styleUrls: ['./drop-statement-popup.component.scss'],
})
export class DropStatementPopupComponent implements OnInit {
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isBtnDisable = false;
  isHideBannerIcon: boolean = false;
  isHideCloseButton: boolean = false;

  formGenerateStatement = new FormGroup({
    fiscalYear: new FormControl('', [
      Validators.required,
      this.specialCharValidator(),
    ]),
    month: new FormControl('', [Validators.required]),
  });

  monthValues = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'September',
    'October',
    'November',
    'December',
  ];
  inputType: FilterInputType = INPUT_TYPE[FilterPropertyType['Whole Number']];

  constructor(
    public dialogRef: MatDialogRef<DropStatementPopupComponent>,
    private statementService: DropStatementService,
    @Inject(MAT_DIALOG_DATA) public data: {
      memberId?: string;
      title?: string;
    },
  ) {}

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.formGenerateStatement.valid) {
      const reqDropStatement: SetDropStatementRequest = {
        fiscalYear: this.formGenerateStatement.value.fiscalYear,
        fiscalMonth: this.formGenerateStatement.value.month,
      };
      if (this.data?.memberId) {
        reqDropStatement.memberId = this.data.memberId;
      }
      this.showGreenBannerForStatement();
      this.isBtnDisable = true;
      this.formGenerateStatement.disable();
      this.statementService.postDropStatement(reqDropStatement).subscribe(
        (response: any) => {
          this.isBtnDisable = false;
          this.dialogRef.close('success');
        },
        (error: any) => {
          this.formGenerateStatement.enable();
          this.isBtnDisable = false;
          const errorMessage = error?.error?.errorMessage[0] || 'Error found';
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: errorMessage,
          });
        },
      );
    }
  }

  showGreenBannerForStatement() {
    this.isHideBannerIcon = true;
    this.isHideCloseButton = true;
    showBanner.call(this, BannerType.Warning, '', '', {
      customMessage: 'Generating drop statement...',
    });
  }

  closeBanner() {
    this.bannerType = BannerType.Hidden;
  }

  public specialCharValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ctrlName = control.parent?.get('fiscalYear');
      const regEx = /^(?:19|20)\d{2}$/;
      if (!regEx.test(ctrlName?.value)) {
        return {
          fiscalError: `The input value must be in between 1900 and 2099`,
        };
      }
      return null;
    };
  }
}
