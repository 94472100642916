import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LookupTableType } from 'src/app/member/constance/metadata.const';
import { Address } from 'src/app/shared/models/common.model';
import { isEmpty } from '@ptg-shared/utils/string.util';

export interface PropertyOption {
  id: string,
  text: string,
  type?: LookupTableType,
  description?: string,
}

@Component({
  selector: 'ptg-view-address',
  templateUrl: './view-address.component.html',
  styleUrls: ['./view-address.component.scss']
})
export class ViewAddressComponent implements OnInit, OnChanges {
  @Input() value: Address | undefined;
  @Input() options!: PropertyOption[] | undefined;
  @Input() defaultValue: string = '-';
  @Input() customClass!: string | string[];
  @Input() useCode: boolean = false;

  address?: Address;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.options) {
      this.getAddressData();
    }
  }

  getAddressData(): void {
    if (!this.value) {
      this.address = this.value;
      return;
    }
    this.address = {
      ...this.value,
      additional: this.getAdditionalString(this.value?.city, this.useCode ? this.value?.stateCode || '' : this.value?.state, this.value?.zipCode)
    }
    if (this.useCode) {
      this.address.country = this.address.countryCode || '';
    }
    if (this.options) {
      const state = this.options.find(option => option?.type === LookupTableType.State && option?.id.toLowerCase() === this.value?.state?.toLowerCase())?.description || '';
      const country = this.options.find(option => option?.type === LookupTableType.Country && option?.id.toLowerCase() === this.value?.country?.toLowerCase())?.description || '';
      this.address = {
        ...this.value,
        state,
        country,
        additional: this.getAdditionalString(this.value?.city, state, this.value?.zipCode)
      }
    }
    if (Object.values(this.address || {}).every(value => isEmpty(value))) {
      this.address = undefined;
    }
  }

  getAdditionalString(city: string, state: string, zipcode: string) {
    return `${city ? `${city},` : ''}${state ? ` ${state}` : ''}${zipcode ? ` ${zipcode}` : ''}`;
  }

}
