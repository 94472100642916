import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { dispatchGetMemberNavigationList } from '@ptg-shared/layout/helpers';
import * as fromReducer from '@ptg-reducers';

import {
  ActionItem,
  MemberListQuery,
  MemberNavigationState,
  OverviewData,
  ProfileHeader,
} from '../../types/models';
import * as MemberNavigationActions from '../../actions/member-navigation';
import * as ProfileHeaderConfigurationActions from '../../actions/profile-header-configuration.actions';
import * as fromMember from '../../reducers';
import { MemberDetailService } from '../../services/member-detail.service';
import { dispatchGetMemberProfileHeaders } from '../../helpers';

@Component({
  selector: 'ptg-overview-header',
  templateUrl: './overview-header.component.html',
  styleUrls: ['./overview-header.component.scss'],
})
export class OverviewHeaderComponent implements OnInit, OnDestroy {
  @Output() editSectionEvent = new EventEmitter();
  @Output() removeMemberEvent = new EventEmitter();
  @Output() addNoteEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() lockAccountEvent = new EventEmitter();
  @Output() editMemberStatusEvent = new EventEmitter();
  @Output() changeMemberDetailEvent = new EventEmitter();
  @Output() clickActionItem = new EventEmitter<string>();

  @Input() data: any = {};
  @Input() detail: any = {};
  @Input() overviewData!: OverviewData[];
  @Input() actionItems: ActionItem[] = [];
  @Input() isProfileHeader: boolean = true;
  @Input() isLocked: boolean = false;
  @Input() identityKey: string = '';
  @Input() memberNavigationState!: MemberNavigationState;
  @Input() canRemove: boolean = true;

  unsubscribe$ = new Subject<void>();
  profileHeaders?: ProfileHeader[];
  navigationDisabled: {
    previous: boolean;
    next: boolean;
  } = {
    previous: true,
    next: true,
  };
  fundId!: string;
  memberId: string = '';
  selectUrlBeforeSearch: string = '';
  isHistoryTab: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private store: Store<fromLayoutReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private memberDetailService: MemberDetailService
  ) {}

  ngOnInit(): void {
    this.getUrlBeforeSearch();
    this.route.params.subscribe((params) => {
      this.memberId = params.id || params.memberId;
      this.memberDetailService.memberId = params.id || params.memberId;
      dispatchGetMemberProfileHeaders(this.memberStore, this.memberId);
      this.checkMemberIndex();
      this.isHistoryTab = this.router.url.includes(STRING_QUERY_PARAM.SEARCH);
    });

    this.memberStore
      .pipe(
        select(fromMember.getProfileHeadersSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (
          !state?.isLoading &&
          state?.success &&
          state?.payload?.profileHeaders
        ) {
          this.profileHeaders = state.payload.profileHeaders;
        }
      });

    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.fundId = el.id;
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberNavigationState),
        debounceTime(300),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberNavigationState) => {
        this.memberNavigationState = memberNavigationState;
        this.checkMemberIndex();
        this.checkNavigateStatus();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkMemberIndex() {
    if (
      !this.memberNavigationState ||
      (this.isProfileHeader && !this.memberId)
    ) {
      return;
    }
    const currentIndex = this.memberNavigationState.memberIndex;
    const memberIndex = this.memberNavigationState.members.findIndex(
      (member) => this.memberId === member._memberId
    );
    if (currentIndex === memberIndex) {
      return;
    }
    this.memberStore.dispatch(
      MemberNavigationActions.setMemberIndex({ memberIndex })
    );
    this.checkCurrentPage(
      memberIndex,
      this.memberNavigationState.query as MemberListQuery
    );
  }

  checkNavigateStatus() {
    if (!this.memberNavigationState?.members?.length || this.isHistoryTab) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    const previous = this.memberNavigationState?.memberIndex <= 0;
    const next =
      this.memberNavigationState?.memberIndex === -1 ||
      this.memberNavigationState?.memberIndex >=
        this.memberNavigationState?.members?.length - 1;
    this.navigationDisabled = { previous, next };
  }

  lockAccount(isLock: boolean) {
    this.lockAccountEvent.emit(isLock);
  }

  removeMember() {
    this.removeMemberEvent.emit();
  }

  close() {
    this.store.dispatch(LayoutActions.closeTab({}));
    if (!this.isHistoryTab) {
      this.memberStore.dispatch(
        MemberNavigationActions.clearMemberNavigationState()
      );
    }
    let url = '/member';
    if (
      this.router.url.includes(STRING_QUERY_PARAM.SEARCH) &&
      this.selectUrlBeforeSearch
    ) {
      url = this.selectUrlBeforeSearch;
    }
    this.store.dispatch(
      LayoutActions.setHistory({ history: { urlBeforeSearch: '' } })
    );
    this.router.navigate([url], {
      state: { beforeState: this.memberNavigationState.query },
    });
  }

  onEditMemberStatusEvent() {
    this.editMemberStatusEvent.emit();
  }

  changeMemberDetail(isNext: boolean = false) {
    this.changeMemberDetailEvent.emit();
    const memberIndex =
      this.memberNavigationState.memberIndex + (isNext ? +1 : -1);
    const query = this.memberNavigationState.query as MemberListQuery;
    this.checkCurrentPage(memberIndex, query);
    this.memberStore.dispatch(
      MemberNavigationActions.setMemberIndex({ memberIndex })
    );
    const id = this.memberNavigationState.members[memberIndex]
      ?._memberId as string;
    const path = this.router.url.substr(0, this.router.url.lastIndexOf('/'));
    if (id) {
      dispatchGetMemberNavigationList(this.store, id);
      const url = document.location.search.includes(STRING_QUERY_PARAM.SEARCH)
        ? `${path}/${id}?${STRING_QUERY_PARAM.SEARCH}`
        : `${path}/${id}`;
      this.router.navigateByUrl(url);
    }
  }

  // TODO Remove later
  getMemberProfileData() {
    if (this.memberId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberProfileHeadersDataAction({
          memberId: this.memberId,
        })
      );
    }
  }

  checkCurrentPage(memberIndex: number, memberListQuery: MemberListQuery) {
    const query = JSON.parse(JSON.stringify(memberListQuery));
    let isAppend = false;
    if (memberIndex === 0 && this.memberNavigationState?.startPage > 1) {
      query.pageIndex -= 1;
    }
    if (
      memberIndex === this.memberNavigationState?.members?.length - 1 &&
      memberIndex === this.memberNavigationState?.query?.totalPerPage &&
      this.memberNavigationState.endPage < this.memberNavigationState.totalPage
    ) {
      query.pageIndex += 1;
      isAppend = true;
    }
    if (query.pageIndex !== memberListQuery.pageIndex) {
      this.memberStore.dispatch(
        MemberNavigationActions.getMoreMembers({
          query,
          idClient: this.fundId,
          isAppend,
        })
      );
    }
  }

  getUrlBeforeSearch() {
    this.store
      .pipe(select(fromLayoutReducer.selectUrlBeforeSearchState))
      .subscribe((selectUrlBeforeSearch) => {
        this.selectUrlBeforeSearch = selectUrlBeforeSearch || '/member';
      });
  }

  onClickActionItem(id: string): void {
    this.clickActionItem.emit(id);
  }
}
