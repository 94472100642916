import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MunicipalityServiceHistoryAction from '../actions/municipality-service-history.action';
import { MunicipalityServiceHistoryService } from '../services/municipality-service-history.service';
import {
  MunicipalityNameList,
  MunicipalityServiceRecordList,
} from '../types/models';

@Injectable()
export class MunicipalityServiceHistoryEffects {
  getMunicipalityServiceHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryList
      ),
      switchMap(({ query }) => {
        return this.municipalityServiceHistoryService
          .getMunicipalityServiceHistoryList(query)
          .pipe(
            map((serviceRecords: MunicipalityServiceRecordList) => {
              return MunicipalityServiceHistoryAction.municipalityServiceHistoryListSuccess(
                { serviceRecords }
              );
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.municipalityServiceHistoryListFailure()
              );
            })
          );
      })
    )
  );

  getMunicipalityList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityServiceHistoryAction.getMunicipalityList),
      switchMap(() => {
        return this.municipalityServiceHistoryService
          .getMunicipalityList()
          .pipe(
            map((municipalitysName: MunicipalityNameList) => {
              return MunicipalityServiceHistoryAction.getMunicipalityListSuccess(
                { listMunicipality: municipalitysName.employers }
              );
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.getMunicipalityListFailure()
              );
            })
          );
      })
    )
  );

  editMemberServiceHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryRequest
      ),
      switchMap(({ body }) => {
        return this.municipalityServiceHistoryService
          .editMunicipalityServiceHistory(body)
          .pipe(
            map(() => {
              return MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistorySuccess();
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure()
              );
            })
          );
      })
    )
  );

  createMemberServiceHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryRequest
      ),
      switchMap(({ body }) => {
        return this.municipalityServiceHistoryService
          .editMunicipalityServiceHistory(body)
          .pipe(
            map(() => {
              return MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistorySuccess();
            }),
            catchError((err) => {
              return of(
                MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryFailure()
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private municipalityServiceHistoryService: MunicipalityServiceHistoryService
  ) {}
}
