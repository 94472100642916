import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { GUID_EMPTY, MAX_VALUE_NUMBER } from '@ptg-shared/constance';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { Option } from '@ptg-shared/controls/select/select.component';
import { DeductionType } from '@ptg-processing/types/enums';
import { PaymentDeductionsService } from '@ptg-processing/services/payment-deductions.service';

import { OffCyclePayment, PaymentDeduction } from '../../types/models';
import { TaxType, TAX_CODE } from '../../constance/member-list.const';

@Component({
  selector: 'ptg-edit-deductions',
  templateUrl: './edit-deductions.component.html',
  styleUrls: ['./edit-deductions.component.scss'],
})
export class EditDeductionsComponent extends BaseComponent {
  readonly MAX_VALUE_NUMBER = MAX_VALUE_NUMBER;
  readonly DeductionType = DeductionType;

  payeeIsLinked: boolean;
  deductions: any[] = [];
  offCyclePayment: OffCyclePayment;

  formGroup: FormGroup = new FormGroup({});

  federalTax?: any;
  stateTax?: any;
  activeDeductions: PaymentDeduction[] = [];
  activeFederal?: PaymentDeduction;
  activeState?: PaymentDeduction;
  isHiddenTaxes: boolean = true;
  isDisplayAddTaxes: boolean = false;

  allActiveInsurances: any[] = [];
  selectedInsuranceCtrls: FormArray = new FormArray([]);
  allActiveOthers: any[] = [];
  selectedOtherCtrls: FormArray = new FormArray([]);
  selectedGarnishmentCtrls: FormArray = new FormArray([]);

  get formValid(): boolean {
    return (
      (this.formGroup.disabled || this.formGroup.valid) &&
      (this.selectedInsuranceCtrls.disabled ||
        this.selectedInsuranceCtrls.valid) &&
      (this.selectedOtherCtrls.disabled || this.selectedOtherCtrls.valid) &&
      (this.selectedGarnishmentCtrls.disabled ||
        this.selectedGarnishmentCtrls.valid)
    );
  }
  get formPending(): boolean {
    return (
      this.formGroup.pending ||
      this.selectedInsuranceCtrls.pending ||
      this.selectedOtherCtrls.pending ||
      this.selectedGarnishmentCtrls.pending
    );
  }
  get federalCtrl(): FormControl {
    return this.formGroup.get('federal') as FormControl;
  }
  get recalculateFederalTaxCtrl(): FormControl {
    return this.formGroup.get('recalculateFederalTax') as FormControl;
  }
  get stateCtrl(): FormControl {
    return this.formGroup.get('state') as FormControl;
  }
  get recalculateStateTaxCtrl(): FormControl {
    return this.formGroup.get('recalculateStateTax') as FormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<EditDeductionsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private paymentDeductionsService: PaymentDeductionsService
  ) {
    super();

    this.payeeIsLinked = this.data.payeeIsLinked;
    this.deductions = this.data.deductions ?? [];
    this.offCyclePayment = this.data.offCyclePayment;
  }

  ngOnInit(): void {
    this.paymentDeductionsService
      .getActiveDeduction()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: any) => {
        this.activeDeductions = state?.deductions;
        this.initFormGroup();
      });
  }

  private initFormGroup(): void {
    this._createTaxControls();
    this._createDeductionControls();
  }

  private _createTaxControls(): void {
    this.federalTax = this.deductions.find(
      (item) =>
        item?.id !== GUID_EMPTY &&
        JSON.parse(item?.deductionSetting)?.TaxType === TaxType.FederalTax
    );
    this.stateTax = this.deductions.find(
      (item: any) =>
        item?.id !== GUID_EMPTY &&
        JSON.parse(item?.deductionSetting)?.TaxType === TaxType.StateTax
    );

    this.activeFederal = this.activeDeductions.find(
      (el: any) =>
        el?.id !== GUID_EMPTY &&
        JSON.parse(el?.deductionSetting)?.TaxType === TaxType.FederalTax
    );
    this.activeState = this.activeDeductions.find(
      (el: any) =>
        el?.id !== GUID_EMPTY &&
        JSON.parse(el?.deductionSetting)?.TaxType === TaxType.StateTax
    );

    // Display/Hidden Taxes
    this.isHiddenTaxes =
      !this.federalTax &&
      !this.stateTax &&
      ((!this.activeFederal && !this.activeState) || !!this.offCyclePayment);

    // Display/Hidden Add Taxes button
    this.isDisplayAddTaxes =
      !this.offCyclePayment && // Hide if it is Off-Cycle payment
      !this.payeeIsLinked &&
      ((!!this.activeFederal && !this.federalTax) ||
        (!!this.activeState && !this.stateTax));

    // Create form controls
    if (this.federalTax) {
      this.formGroup.addControl(
        'federal',
        new FormControl({
          value: this.federalTax.value,
          disabled: !this.offCyclePayment && !this.activeFederal,
        })
      );
      this.formGroup.addControl(
        'recalculateFederalTax',
        new FormControl({
          value: false,
          disabled: !this.offCyclePayment && !this.activeFederal,
        })
      );
    }

    if (this.stateTax) {
      this.formGroup.addControl(
        'state',
        new FormControl({
          value: this.stateTax.value,
          disabled: !this.offCyclePayment && !this.activeState,
        })
      );
      this.formGroup.addControl(
        'recalculateStateTax',
        new FormControl({
          value: false,
          disabled: !this.offCyclePayment && !this.activeState,
        })
      );
    }
  }

  private _createDeductionControls(): void {
    this.allActiveInsurances = this.activeDeductions.filter(
      (item) => item.deductionType === DeductionType.Insurance
    );
    this.allActiveOthers = this.activeDeductions.filter(
      (item) => item.deductionType === DeductionType.Others
    );

    for (const deduction of this.deductions) {
      if (deduction.id === GUID_EMPTY) return;

      if (deduction.deductionType === DeductionType.Insurance) {
        this._pushInsuranceControls(deduction);
      } else if (deduction.deductionType === DeductionType.Others) {
        this._pushOtherControls(deduction);
      } else if (deduction.deductionType === DeductionType.Garnishment) {
        this._pushGarnishmentControls(deduction);
      }
    }

    this._refreshDeductionOptions(DeductionType.Insurance);
    this._refreshDeductionOptions(DeductionType.Others);
  }

  private _pushInsuranceControls(deduction: any): void {
    const isDisabled =
      !this.offCyclePayment &&
      !this.activeDeductions.some((x) => x.id === deduction.deductionId);

    this.selectedInsuranceCtrls.push(
      new FormGroup({
        deduction: new FormControl({
          value: deduction.deductionId,
          disabled: isDisabled,
        }),
        amount: new FormControl({
          value: deduction.value,
          disabled: isDisabled,
        }),
        deductionOptions: new FormControl([]),
      })
    );
  }

  private _pushOtherControls(deduction: any): void {
    const isDisabled =
      !this.offCyclePayment &&
      !this.activeDeductions.some((x) => x.id === deduction.deductionId);

    this.selectedOtherCtrls.push(
      new FormGroup({
        deduction: new FormControl({
          value: deduction.deductionId,
          disabled: isDisabled,
        }),
        amount: new FormControl({
          value: deduction.value,
          disabled: isDisabled,
        }),
        deductionOptions: new FormControl([]),
      })
    );
  }

  private _pushGarnishmentControls(deduction: any): void {
    const isDisabled =
      !this.offCyclePayment &&
      !this.activeDeductions.some((x) => x.id === deduction.deductionId);

    this.selectedGarnishmentCtrls.push(
      new FormGroup({
        deduction: new FormControl({
          value: deduction.deductionId,
          disabled: isDisabled,
        }),
        amount: new FormControl({
          value: deduction.value,
          disabled: isDisabled,
        }),
        name: new FormControl(
          `${deduction.deductionCode} - ${deduction.deductionDescription}`
        ),
        value: new FormControl(deduction.value),
      })
    );
  }

  private _getAvailableOptions(
    type: DeductionType,
    selectedDeductionId?: string
  ): Option[] {
    let options: Option[] = [];
    if (type === DeductionType.Insurance) {
      const selectedInsuranceIds = this.selectedInsuranceCtrls.controls.map(
        (item) => item.get('deduction')?.value
      );
      options = this.allActiveInsurances
        .filter(
          (item) =>
            item.id === selectedDeductionId ||
            !selectedInsuranceIds.some((y) => y === item.id)
        )
        .map(
          (item) =>
            ({
              displayValue: item.deductionCode + ' - ' + item.description,
              value: item.id,
            } as Option)
        );
    } else if (type === DeductionType.Others) {
      const selectedOtherIds = this.selectedOtherCtrls.controls.map(
        (item) => item.get('deduction')?.value
      );
      options = this.allActiveOthers
        .filter(
          (item) =>
            item.id === selectedDeductionId ||
            !selectedOtherIds.some((y) => y === item.id)
        )
        .map(
          (item) =>
            ({
              displayValue: item.deductionCode + ' - ' + item.description,
              value: item.id,
            } as Option)
        );
    }

    // Append disabled deduction into dropdown
    if (selectedDeductionId) {
      const isDisabled =
        !this.offCyclePayment &&
        !this.activeDeductions.some((x) => x.id === selectedDeductionId);
      if (isDisabled) {
        const selectedDeduction = this.deductions.find(
          (item) => item.deductionId === selectedDeductionId
        );
        if (selectedDeduction) {
          options.push({
            displayValue:
              selectedDeduction.deductionCode +
              ' - ' +
              selectedDeduction.deductionDescription,
            value: selectedDeduction.deductionId,
          } as Option);
        }
      }
    }

    return options;
  }

  showAddButton(type: DeductionType): boolean {
    if (type === DeductionType.Insurance) {
      return (
        this.selectedInsuranceCtrls.valid &&
        this.allActiveInsurances.length !==
          this.selectedInsuranceCtrls.controls.filter(
            (item) => !item.get('deduction')?.disabled
          ).length
      );
    }

    if (type === DeductionType.Others) {
      return (
        this.selectedOtherCtrls.valid &&
        this.allActiveOthers.length !==
          this.selectedOtherCtrls.controls.filter(
            (item) => !item.get('deduction')?.disabled
          ).length
      );
    }

    return false;
  }

  onClickAddTaxes(): void {
    if (this.activeFederal && !this.federalTax) {
      // Add federal tax to list of Tax deductions
      this.deductions = [
        {
          deductionId: this.activeFederal.id,
          deductionCode: this.activeFederal.deductionCode,
          deductionDescription: this.activeFederal.description,
          deductionSetting: this.activeFederal.deductionSetting,
          value: this.activeFederal.currentAmount,
        },
        ...this.deductions,
      ];
    }

    if (this.activeState && !this.stateTax) {
      // Add state tax to list of Tax deductions
      this.deductions = [
        {
          deductionId: this.activeState.id,
          deductionCode: this.activeState.deductionCode,
          deductionDescription: this.activeState.description,
          deductionSetting: this.activeState.deductionSetting,
          value: this.activeState.currentAmount,
        },
        ...this.deductions,
      ];
    }

    this._createTaxControls();
  }

  onClickAddRow(type: DeductionType): void {
    const newRow = new FormGroup({
      deduction: new FormControl(''),
      amount: new FormControl(null),
      deductionOptions: new FormControl(this._getAvailableOptions(type)),
    });

    if (type === DeductionType.Insurance) {
      this.selectedInsuranceCtrls.push(newRow);
    } else if (type === DeductionType.Others) {
      this.selectedOtherCtrls.push(newRow);
    }
  }

  onClickRemoveRow(type: DeductionType, index: number): void {
    if (type === DeductionType.Insurance) {
      this.selectedInsuranceCtrls.removeAt(index);
    } else if (type === DeductionType.Others) {
      this.selectedOtherCtrls.removeAt(index);
    } else if (type === DeductionType.Garnishment) {
      this.selectedGarnishmentCtrls.removeAt(index);
    }

    this._refreshDeductionOptions(type);
  }

  onChangeDeduction(type: DeductionType, row: AbstractControl): void {
    const deductionId = row.get('deduction')?.value;
    if (!deductionId) return;

    const currentAmount = this.activeDeductions.find(
      (item) => item.id === deductionId
    )?.currentAmount;
    row.get('amount')?.setValue(currentAmount);

    // Update deduction options in the list of selected deductions
    this._refreshDeductionOptions(type);
  }

  private _refreshDeductionOptions(type: DeductionType): void {
    let selectedDeductionCtrls: FormArray | null = null;
    if (type === DeductionType.Insurance) {
      selectedDeductionCtrls = this.selectedInsuranceCtrls;
    } else if (type === DeductionType.Others) {
      selectedDeductionCtrls = this.selectedOtherCtrls;
    }

    selectedDeductionCtrls?.controls.forEach((item) => {
      item
        .get('deductionOptions')
        ?.patchValue(
          this._getAvailableOptions(type, item.get('deduction')?.value)
        );
    });
  }

  onSubmit(): void {
    this.formGroup.markAllAsTouched();
    this.selectedInsuranceCtrls.markAllAsTouched();
    this.selectedOtherCtrls.markAllAsTouched();
    this.selectedGarnishmentCtrls.markAllAsTouched();

    // Disable validate for the deduction amounts if toggle on
    if (this.formGroup.value.recalculateFederalTax) {
      this.federalCtrl.setErrors(null);
    }
    if (this.formGroup.value.recalculateStateTax) {
      this.stateCtrl.setErrors(null);
    }

    if (this.formValid) {
      if (this.formPending) {
        const sub = this.formGroup.statusChanges.subscribe(() => {
          if (this.formValid) {
            this.saveTable();
          }
          sub.unsubscribe();
        });
      } else {
        this.saveTable();
      }
    }
  }

  saveTable(): void {
    const data: any = {
      recalculateFederalTax: this.recalculateFederalTaxCtrl?.value,
      recalculateStateTax: this.recalculateStateTaxCtrl?.value,
      deductions: [],
    };

    const deductions = [];
    if (this.federalTax) {
      deductions.push({
        ...this.federalTax,
        deductionCode: TAX_CODE.TAX01,
        value: this.federalCtrl?.value || 0,
        order: 0,
      });
    }
    if (this.stateTax) {
      deductions.push({
        ...this.stateTax,
        deductionCode: TAX_CODE.TAX02,
        value: this.stateCtrl?.value || 0,
        order: 1,
      });
    }

    this.selectedInsuranceCtrls.getRawValue()?.forEach((row, i) => {
      let deduction;
      let res: any = {};
      if (this.selectedInsuranceCtrls.controls[i].get('deduction')?.disabled) {
        deduction = this.deductions.find(
          (item) => item.deductionId === row.deduction
        );
        res.deductionDescription = deduction.deductionDescription;
        res.deductionId = deduction.deductionId;
      } else {
        deduction = this.activeDeductions.find(
          (item) => item.id === row.deduction
        );
        res.deductionDescription = deduction?.description;
        res.deductionId = deduction?.id;
      }
      res = {
        ...res,
        deductionCode: deduction.deductionCode,
        deductionSetting: deduction?.deductionSetting,
        deductionType: deduction?.deductionType,
        value: row.amount,
        order: deductions.length,
      };
      deductions.push(res);
    });

    this.selectedOtherCtrls.getRawValue()?.forEach((el, i) => {
      let deduction;
      let res: any = {};
      if (this.selectedOtherCtrls.controls[i].get('deduction')?.disabled) {
        deduction = this.deductions.find(
          (item) => item.deductionId === el.deduction
        );
        res.deductionDescription = deduction.deductionDescription;
        res.deductionId = deduction.deductionId;
      } else {
        deduction = this.activeDeductions.find(
          (item) => item.id === el.deduction
        );
        res.deductionDescription = deduction?.description;
        res.deductionId = deduction?.id;
      }
      res = {
        ...res,
        deductionCode: deduction.deductionCode,
        deductionSetting: deduction?.deductionSetting,
        deductionType: deduction?.deductionType,
        value: el.amount,
        order: deductions.length,
      };
      deductions.push(res);
    });

    this.selectedGarnishmentCtrls.getRawValue()?.forEach((elem: any) => {
      const deduction = this.deductions.find(
        (item) => item.deductionId === elem.deduction
      );
      deductions.push({
        ...deduction,
        value: elem.amount,
        order: deductions.length,
      });
    });

    data.deductions = deductions;
    this.dialogRef.close(data);
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
