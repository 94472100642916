import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptg-person-name-column',
  templateUrl: './person-name-column.component.html',
  styleUrls: ['./person-name-column.component.scss'],
})
export class PersonNameColumnComponent {
  @Input() value!: { prefix: string, first: string, middle: string, last: string, suffix: string };
}
