import { Store, select } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DateTime } from 'luxon';

import * as fromReducer from '@ptg-reducers';

import { getMemberNavigationListSelector } from './reducers';
import { getMemberNavigationListAction } from './actions/layout.actions';

const EXPIRE_TIME = 60 * 60;

export function dispatchGetMemberNavigationList(
  store: Store<fromReducer.State>,
  memberId: string,
  forceLoad: boolean = false
) {
  store
    .pipe(
      select(getMemberNavigationListSelector),
      take(1),
      filter(
        (state) =>
          !state?.updatedAt ||
          DateTime.now().diff(DateTime.fromISO(state.updatedAt), 'seconds')
            .seconds > EXPIRE_TIME ||
          forceLoad ||
          state?.payload?.memberId !== memberId
      )
    )
    .subscribe(() =>
      store.dispatch(getMemberNavigationListAction({ memberId }))
    );
}
