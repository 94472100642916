import {
  pensionDropPlanIcon,
  pensionEstimatorIcon,
} from '@ptg-shared/constance/listIcons.const';
import {
  PERMISSION_KEY,
  SUBMODULE_KEY,
} from '@ptg-shared/constance/permission.const';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Menu, MenuGroup } from '@ptg-shared/layout/models/layout.model';

export const BULK_UPDATE_MENU_LIST: Menu[] = [
  {
    title: 'Bulk Content Update',
    menuItems: [
      // added dynamically within the SidebarComponent
      {
        name: 'Mixed Bulk Update',
        routerLink: '/member/bulk/mixed',
        className: '',
      },
    ],
  },
];

export const HISTORICAL_MENU_LIST: Menu[] = [
  {
    title: 'Historical Data',
    menuItems: [
      {
        name: 'Payroll Service Record',
        routerLink: '/fund-management/historical-data/payroll-service-record',
      },
      {
        name: 'Archived Contributions',
        routerLink: '/fund-management/historical-data/archived-contributions',
      },
    ],
  },
];

export const FUND_MENU_LIST: Menu[] = [
  {
    title: 'PAS Management',
    menuItems: [{ name: 'Theme', routerLink: '/fund-management/theme' }],
  },
];

export const CONTENT_MENU_LIST: Menu[] = [
  {
    title: 'App Content Management',
    menuItems: [
      { name: 'Navigation', routerLink: '/fund-management/navigation' },
      { name: 'Preferences', routerLink: '/fund-management/preferences' },
      { name: 'Mixed Bulk Add', routerLink: '/page/mixed-bulk-add' },
    ],
  },
  {
    title: 'Content',
    menuItems: [
      // added dynamically within the SidebarComponent
    ],
  },
];

export const MEMBER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [{ name: 'Overview', routerLink: '/member/detail/' }],
  },
];

export const RESOURCE_CENTER_MENU_LIST: Menu[] = [
  {
    title: 'Resource Center',
    menuItems: [
      {
        name: 'File Upload/Transfer',
        routerLink: '/fund-management/resource-center',
      },
    ],
  },
  {
    title: 'Configuration',
    menuItems: [
      {
        name: 'Notifications',
        routerLink: '/fund-management/resource-center/notification',
      },
      {
        name: 'Categories',
        routerLink: '/fund-management/resource-center/manage-category',
      },
      {
        name: 'DROP Interest Rate',
        routerLink: '/fund-management/resource-center/interest-rate',
      },
    ],
  },
];

export const NOTIFICATION_MENU_LIST: Menu[] = [
  {
    title: 'Communications',
    menuItems: [
      { name: 'SMS Messaging', routerLink: '/fund-management/notification' },
      {
        name: 'Email Messaging',
        routerLink: '/fund-management/email-messaging',
      },
    ],
  },
  {
    title: 'Setting',
    icon: 'tune',
    style:
      'padding-top: 16px; border-top: 1px solid #124462; margin-right: 15px;',
    menuItems: [
      {
        name: 'Opt-In Messaging',
        routerLink: '/fund-management/notification/opt-in-message',
      },
    ],
  },
];

export const PENSION_ESTIMATOR_LIST: Menu[] = [
  {
    title: 'Pension Estimator',
    menuItems: [
      {
        name: 'Pension Estimator',
        routerLink: '/estimator',
        routerLinkExpand: '/estimator/estimator-config',
      },
    ],
  },
];

export const PENSION_DROP_PLAN_LIST: Menu[] = [
  {
    title: 'DROP Plan',
    menuItems: [
      {
        name: 'DROP Plan',
        routerLink: '/member/drop-plan',
        routerLinkExpand: '/member/drop-plan',
      },
    ],
  },
];

export const IAM_MENU_LIST: Menu[] = [
  {
    title: 'Access Management',
    menuItems: [
      { name: 'User Management', routerLink: '/access-management/account' },
      { name: 'Role Management', routerLink: '/access-management/roles' },
    ],
  },
];

export const PAYROLL_MENU_LIST: Menu[] = [
  {
    title: 'Retirees',
    menuItems: [
      { name: 'Next Payroll', routerLink: '/processing/next-payroll' },
      {
        name: 'Payroll Register',
        routerLink: '/processing/pension-payroll',
        routerLinkExpand: 'processing/payment-information/',
      },
    ],
  },
  {
    title: 'Service Providers',
    menuItems: [
      {
        name: 'Next Payment',
        routerLink: '/processing/next-payment',
        routerLinkExpand: '/processing/next-payment',
      },
      {
        name: 'SP Register',
        routerLink: '/processing/payment-history',
        routerLinkExpand: 'processing/payment-history',
      },
    ],
  },
  {
    title: 'Payroll Settings',
    menuItems: [
      { name: 'Service Providers', routerLink: '/processing/service-provider' },
      { name: ' Deductions', routerLink: '/processing/payment-deductions' },
    ],
  },
];

export const PAYMENT_MENU_LIST: Menu[] = [
  {
    title: 'Lump Sum Payments',
    menuItems: [
      {
        name: 'Off-Cycle Payments',
        routerLink: '/processing/off-cycle-payments',
        routerLinkExpand: 'prevScreen=1',
      },
      {
        name: 'Payment History',
        routerLink: '/processing/lump-sum-payment-history',
        routerLinkExpand: 'prevScreen=2',
      },
    ],
  },
];

export const YEARLY_PROCESSING_MENU_LIST: Menu[] = [
  {
    title: 'Yearly',
    menuItems: [
      {
        name: '1099-R',
        routerLink: '/processing/yearly-processing',
        routerLinkExpand: '/processing/annual1099',
      },
    ],
  },
];

export const TRANSACTION_REGISTER_MENU_LIST: Menu[] = [
  {
    title: 'Transaction Register',
    menuItems: [
      { name: 'Transaction Register', routerLink: '/processing/register' },
    ],
  },
];

export const REPORT_MENU_LIST: Menu[] = [
  {
    title: 'Reports',
    menuItems: [
      {
        name: 'Register Summary',
        routerLink: '/processing/report/register-summary',
        routerLinkExpand: 'processing/report/register-summary',
      },
      {
        name: 'Register Detail',
        routerLink: '/processing/report/register-detail',
        routerLinkExpand: 'processing/report/register-detail',
      },
      {
        name: 'Change Report',
        routerLink: '/processing/report/change-report',
        routerLinkExpand: 'processing/report/change-report',
      },
      {
        name: 'Reversal Report',
        routerLink: '/processing/report/reveral-report',
        routerLinkExpand: 'processing/report/reveral-report',
      },
      {
        name: 'Direct Deposit Change',
        routerLink: '/processing/report/direct-deposit-change',
        routerLinkExpand: 'processing/report/direct-deposit-change',
      },
      {
        name: 'Insurance Deduction Report',
        routerLink: '/processing/report/insurance-deduction-report',
        routerLinkExpand: 'processing/report/insurance-deduction-report',
      },
      {
        name: 'M. Deduction Report',
        routerLink: '/processing/report/m-deduction-report',
        routerLinkExpand: 'processing/report/m-deduction-report',
      },
    ],
  },
];

export const ACCESS_MANAGEMENT_MENU_LIST: MenuGroup = {
  moduleKey: SUBMODULE_KEY.ACCESS_MANAGEMENT,
  name: 'access_management',
  menu: IAM_MENU_LIST,
  icon: 'vpn_key',
  permissionKey: PERMISSION_KEY.ACCESS_CONTROL,
};

export const EMPLOYER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [
      { name: 'Overview', routerLink: '/employer/detail' },
      { name: 'Departments', routerLink: '/employer/department-management' },
      // hidden for release 2608
      // { name: 'Wage Processing', routerLink: '/employer/wage-processing' }
    ],
  },
  {
    title: 'Employer Settings',
    icon: 'tune',
    style:
      'padding-top: 16px; border-top: 1px solid #124462; margin-right: 15px;',
    menuItems: [
      { name: 'Parameters', routerLink: '/employer/parameters' },
      {
        name: 'Reporting Calendar',
        routerLink: '/employer/reporting-calendar',
      },
    ],
  },
];

export const TOLERANCE_MENU: Menu[] = [
  {
    title: 'Settings',
    menuItems: [
      {
        name: 'Tolerance Parameter',
        routerLink: '/employer/tolerance/parameters',
      },
    ],
  },
];

export const BVFF_EMPLOYER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [{ name: 'Profile', routerLink: '/employer/detail' }],
  },
  {
    title: 'Participants',
    menuItems: [
      {
        name: 'Annual Certification',
        routerLink: '/employer/annual-certification',
        routerLinkExpand: '/employer/annual-certification/',
      },
      {
        name: 'Annual Cert.History',
        routerLink: '/employer/annual-certification-history',
        routerLinkExpand: '/employer/annual-certification-history/',
      },
      {
        name: 'Remittance',
        routerLink: '/employer/remittance-submission-history',
        routerLinkExpand: '/employer/remittance-submission-history/',
      },
    ],
  },
];

export const BVFF_EMPLOYER_SETTING_MENU: Menu[] = [
  {
    title: 'Settings',
    menuItems: [{ name: 'Fee Amounts', routerLink: '/employer/fee-amounts' }],
  },
];

export const PARTICIPANT_REPORT_MENU: Menu[] = [
  // added dynamically within the SidebarComponent
  {
    title: 'Generated Report',
    menuItems: [
      {
        name: 'Generated List',
        routerLink: '/member/report/generated',
        routerLinkExpand: '/member/report/generated',
      },
    ],
  },
  {
    title: 'DROP Statements',
    menuItems: [
      {
        name: 'Generated Statement List',
        routerLink: '/member/report/drop-statements',
        routerLinkExpand: '/member/report/drop-statements',
      },
    ],
  },
];

export const RESOURCE_CENTER_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.RESOURCE_CENTER,
  name: 'RC',
  menu: RESOURCE_CENTER_MENU_LIST,
  icon: 'attach_file',
  permissionKey: PERMISSION_KEY.RESOURCE_CENTER,
};

export const APP_CONTENT_MANAGEMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.APP_CONTENT_MANAGEMENT,
  name: 'ACM',
  menu: CONTENT_MENU_LIST,
  icon: 'phonelink_setup',
  permissionKey: PERMISSION_KEY.APP_CONTENT,
};

export const THEME_MANAGEMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PAS_MANAGEMENT,
  name: 'theme',
  menu: FUND_MENU_LIST,
  icon: 'handyman',
  permissionKey: PERMISSION_KEY.PAS_MANAGEMENT,
};

export const HISTORY_MANAGEMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.HISTORICAL_DATA,
  // moduleKey: SUBMODULE_KEY.RESOURCE_CENTER,
  name: 'history',
  menu: HISTORICAL_MENU_LIST,
  icon: 'account_tree',
  permissionKey: PERMISSION_KEY.HISTORICAL_DATA,
  //permissionKey: PERMISSION_KEY.RESOURCE_CENTER,
};

export const menuOrganization: Menu[] = [
  {
    title: 'Organization Management',
    menuItems: [
      {
        name: 'Fund Management',
        routerLink: '/organization/board-management',
      },
      {
        name: 'Board Member',
        routerLink: '/organization/board-member',
      },
      {
        name: 'Banks Account',
        routerLink: '/organization/banks-account',
      },
    ],
  },
];

export const ORGANIZATION_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.ORGANIZATION_MANAGEMENT,
  name: 'organization_management',
  menu: menuOrganization,
  icon: 'account_tree',
  permissionKey: PERMISSION_KEY.ORGANIZATION_MANAGEMENT,
};

export const MEMBER_MANAGEMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'member_list',
  icon: 'list',
  permissionKey: PERMISSION_KEY.PARTICIPANTS,
  routerLink: '/member',
};

export const PENSION_ESTIMATOR_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PENSION_ESTIMATOR,
  name: 'estimator',
  icon: pensionEstimatorIcon,
  isSVG: true,
  menu: PENSION_ESTIMATOR_LIST,
  permissionKey: PERMISSION_KEY.PENSION_ESTIMATOR,
};

export const PENSION_DROP_PLAN_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.DROP_PLAN,
  name: 'drop_plan',
  icon: pensionDropPlanIcon,
  isSVG: true,
  menu: PENSION_DROP_PLAN_LIST,
  permissionKey: PERMISSION_KEY.DROP_PLAN,
};

export const MEMBER_BENEFIT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.ESTABLISH_BENEFIT,
  name: 'member_benefit',
  icon: 'calculate',
  permissionKey: PERMISSION_KEY.ESTABLISH_BENEFIT,
  routerLink: '/member/benefit',
};

export const NOTIFICATION_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.COMMUNICATIONS,
  name: 'comunication',
  icon: 'textsms',
  menu: NOTIFICATION_MENU_LIST,
  permissionKey: PERMISSION_KEY.COMMUNICATIONS,
};
export const PARTICIPANT_REPORT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_REPORT,
  name: 'participant_report',
  icon: 'stacked_bar_chart',
  menu: PARTICIPANT_REPORT_MENU,
  permissionKey: PERMISSION_KEY.PARTICIPANT_REPORT,
};

export const BULK_UPDATE_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.BULK_CONTENT_UPDATES,
  name: 'bulk_update',
  icon: 'drive_folder_upload',
  menu: BULK_UPDATE_MENU_LIST,
  permissionKey: PERMISSION_KEY.BULK_CONTENT_UPDATES,
};

export const MEMBER_PROFILE_OVERVIEW_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'demographics',
  icon: 'account_circle',
  menu: MEMBER_OVERVIEW_MENU_LIST,
  permissionKey: PERMISSION_KEY.PARTICIPANTS,
};

export const COMMUNICATIONS: Menu[] = [
  {
    title: 'Communication',
    menuItems: [
      {
        name: 'Opt-In Management',
        routerLink: '/member/opt-in',
        routerLinkExpand: 'member/opt-in',
      },
    ],
  },
];

export const MEMBER_COMMUNICATION_OVERVIEW_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'opt-in',
  icon: 'message',
  menu: COMMUNICATIONS,
  permissionKey: PERMISSION_KEY.PARTICIPANTS,
};

export const PAYROLL_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PERIODIC_PAYMENTS,
  name: 'payroll',
  icon: 'payments',
  menu: PAYROLL_MENU_LIST,
};

export const PAYMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.LUMP_SUM_PAYMENTS,
  name: 'payment',
  icon: 'paid',
  menu: PAYMENT_MENU_LIST,
};

export const YEARLY_PROCESSING_MENU_GROUP = {
  moduleKey: SUBMODULE_KEY.YEARLY_PROCESSING,
  name: 'yearly_processing',
  icon: 'edit_calendar',
  menu: YEARLY_PROCESSING_MENU_LIST,
};

export const TRANSACTION_REGISTER_MENU_GROUP = {
  moduleKey: SUBMODULE_KEY.TRANSACTION_REGISTER,
  name: 'transaction_register',
  icon: 'content_paste_go',
  menu: TRANSACTION_REGISTER_MENU_LIST,
};

export const REPORT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.REPORTS,
  name: 'report',
  icon: 'equalizer',
  menu: REPORT_MENU_LIST,
};

export const EMPLOYERS_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'apartment',
  routerLink: '/employer/employer-management',
  permissionKey: PERMISSION_KEY.EMPLOYERS,
};

export const BVFF_EMPLOYERS_SETTING_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'tune',
  permissionKey: PERMISSION_KEY.EMPLOYER_LIST,
  menu: BVFF_EMPLOYER_SETTING_MENU,
};

export const TOLERANCE_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'tune',
  permissionKey: PERMISSION_KEY.EMPLOYER_LIST,
  menu: TOLERANCE_MENU,
};

export const MENU_TYPE = {
  PROFILE_NAVIGATION: 'profile-navigation',
};

export const PARTICIPANTS_MENU_GROUPS = [
  MEMBER_MANAGEMENT_MENU_GROUP,
  MEMBER_BENEFIT_MENU_GROUP,
  PENSION_ESTIMATOR_MENU_GROUP,
  PENSION_DROP_PLAN_MENU_GROUP,
  NOTIFICATION_MENU_GROUP,
  BULK_UPDATE_MENU_GROUP,
  PARTICIPANT_REPORT_MENU_GROUP,
];

export const PARTICIPANT_OVERVIEW_MENU_GROUPS = [
  MEMBER_PROFILE_OVERVIEW_MENU_GROUP,
  MEMBER_COMMUNICATION_OVERVIEW_MENU_GROUP,
];

export const ADMIN_MENU_GROUPS = [
  ACCESS_MANAGEMENT_MENU_LIST,
  RESOURCE_CENTER_MENU_GROUP,
  ORGANIZATION_MENU_GROUP,
  THEME_MANAGEMENT_MENU_GROUP,
  HISTORY_MANAGEMENT_MENU_GROUP,
];
export const PROCESSING_MENU_GROUPS = [
  PAYROLL_MENU_GROUP,
  PAYMENT_MENU_GROUP,
  TRANSACTION_REGISTER_MENU_GROUP,
  REPORT_MENU_GROUP,
  YEARLY_PROCESSING_MENU_GROUP,
];
export const APP_CONTENT_MENU_GROUPS = [APP_CONTENT_MANAGEMENT_MENU_GROUP];

export const EMPLOYERS_MENU_GROUPS = [
  EMPLOYERS_MENU_GROUP,
  TOLERANCE_MENU_GROUP,
];

export const DEFAULT_TAB_MENU_GROUPS: Record<DisplayedTabName, MenuGroup[]> = {
  employers: EMPLOYERS_MENU_GROUPS,
  participants: PARTICIPANTS_MENU_GROUPS,
  admin: ADMIN_MENU_GROUPS,
  processing: PROCESSING_MENU_GROUPS,
  individualParticipant: PARTICIPANT_OVERVIEW_MENU_GROUPS,
  searchedParticipant: PARTICIPANT_OVERVIEW_MENU_GROUPS,
  app: APP_CONTENT_MENU_GROUPS,
};

export const DROP_REPORT_MENU: Menu[] = [
  // added dynamically within the SidebarComponent
  {
    title: 'DROP Statements',
    menuItems: [
      {
        name: 'Generate Statements',
        routerLink: '/member/report/generated',
        routerLinkExpand: '/member/report/generated',
      },
    ],
  },
];
