import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ProfileOverviewConfigurationActions from '../actions/profile-overview-configuration.actions';
import { ProfileOverviewDetail, ProfileOverviewList } from '../types/models';
import { ProfileOverviewConfigurationService } from '../services/profile-overview-configuration.service';

@Injectable()
export class ProfileOverviewConfigurationEffects {
  getSectionMetadataList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileOverviewConfigurationActions.getSectionMetadatas),
      switchMap(() => {
        return this.profileOverviewConfigurationService
          .getSectionMetadataList()
          .pipe(
            map((response) => {
              return ProfileOverviewConfigurationActions.getSectionMetadatasSuccess(
                { response }
              );
            }),
            catchError((error) => {
              return of(
                ProfileOverviewConfigurationActions.getSectionMetadatasFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );
  getProfileOverviewConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileOverviewConfigurationActions.getProfileOverviewConfiguration
      ),
      switchMap(({ profileOverviewId }) => {
        return this.profileOverviewConfigurationService
          .getProfileOverviewConfiguration(profileOverviewId)
          .pipe(
            map((response: ProfileOverviewDetail) => {
              return ProfileOverviewConfigurationActions.getProfileOverviewConfigurationSuccess(
                { response }
              );
            }),
            catchError((error) => {
              return of(
                ProfileOverviewConfigurationActions.getProfileOverviewConfigurationFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  setProfileOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileOverviewConfigurationActions.setProfileOverview),
      switchMap(({ request }) => {
        return this.profileOverviewConfigurationService
          .setProfileOverview(request)
          .pipe(
            map((id) => {
              return ProfileOverviewConfigurationActions.setProfileOverviewSuccess(
                { id }
              );
            }),
            catchError((error) => {
              return of(
                ProfileOverviewConfigurationActions.ssetProfileOverviewFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  getListProfileOverviewConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileOverviewConfigurationActions.getListProfileOverviewConfiguration
      ),
      switchMap(({ query }) => {
        return this.profileOverviewConfigurationService
          .getListProfileOverviewConfiguration(query)
          .pipe(
            map((profileOverviewList: ProfileOverviewList) => {
              return ProfileOverviewConfigurationActions.getListProfileOverviewConfigurationSuccess(
                { profileOverviewList }
              );
            }),
            catchError((error) => {
              return of(
                ProfileOverviewConfigurationActions.getListProfileOverviewConfigurationFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  getStatusNotUsedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileOverviewConfigurationActions.getNotUsedStatuses),
      switchMap(() => {
        return this.profileOverviewConfigurationService.getStatusNotUsed().pipe(
          map((response) => {
            return ProfileOverviewConfigurationActions.getNotUsedStatusesSuccess(
              { response }
            );
          }),
          catchError((error) => {
            return of(
              ProfileOverviewConfigurationActions.getNotUsedStatusesFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  deleteProfileOverviewConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileOverviewConfigurationActions.deleteProfileOverviewConfiguration
      ),
      switchMap(({ id }) => {
        return this.profileOverviewConfigurationService
          .deleteProfileOverviewConfiguration(id)
          .pipe(
            map(() => {
              return ProfileOverviewConfigurationActions.deleteProfileOverviewConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                ProfileOverviewConfigurationActions.deleteProfileOverviewConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private profileOverviewConfigurationService: ProfileOverviewConfigurationService
  ) {}
}
