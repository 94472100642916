import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';

import './dependencies/initExtDeps';
import { ReportViewer } from './dependencies/telerikReportViewer';

@Component({
    selector: 'ptg-report-viewer',
    template: '<div [ngStyle]="containerStyle" [attr.id]="reportViewerID" #container>Loading Report Viewer...</div>',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class PtgReportViewerComponent implements AfterViewInit, OnDestroy {
    containerId?: string;

    @Input() id?: string;
    @Input() serviceUrl?: string;
    @Input() reportServer: any;
    @Input() templateUrl?: string;
    @Input() initialPageAreaImageUrl?: string;
    @Input() reportSource: any;
    @Input() sendEmail: any;
    @Input() parameters: any;
    @Input() scale?: number;
    @Input() scaleMode?: string;
    @Input() viewMode?: string;
    @Input() pageMode?: string;
    @Input() persistSession?: boolean;
    @Input() parameterEditors?: Array<any>;
    @Input() authenticationToken?: string;
    @Input() printMode?: string;
    @Input() selector?: string;
    @Input() disabledButtonClass?: string;
    @Input() checkedButtonClass?: string;
    @Input() enableAccessibility?: boolean;
    @Input() searchMetadataOnDemand?: boolean;
    @Input() parametersAreaVisible?: boolean;
    @Input() documentMapVisible?: boolean;
    @Input() ready?: Function;
    @Input() error?: Function;
    @Input() parametersAreaPosition?: string;
    @Input() documentMapAreaPosition?: string;
    @Input() exportBegin?: Function;
    @Input() exportEnd?: Function;
    @Input() sendEmailBegin?: Function;
    @Input() sendEmailEnd?: Function;
    @Input() pageReady?: Function;
    @Input() printBegin?: Function;
    @Input() printEnd?: Function;
    @Input() renderingBegin?: Function;
    @Input() renderingEnd?: Function;
    @Input() updateUi?: Function;
    @Input() interactiveActionExecuting?: Function;
    @Input() interactiveActionEnter?: Function;
    @Input() interactiveActionLeave?: Function;
    @Input() viewerToolTipOpening?: Function;
    @Input() keepClientAlive?: boolean;

    commands: any;

    @ViewChild('container') container: any;
    @Input() containerStyle: any;
    viewerObject: any;
    reportViewerID?: string;

    ngOnInit(): void {
        this.reportViewerID = this.id || "reportViewer1";
        this.keepClientAlive = this.keepClientAlive === undefined ? true : this.keepClientAlive;
    }

    ngAfterViewInit(): void {
        this.viewerObject = new ReportViewer(this.container.nativeElement, {
            id: this.reportViewerID,
            serviceUrl: this.serviceUrl,
            reportServer: this.reportServer,
            trvTemplateUrl: this.templateUrl,
            initialPageAreaImageUrl: this.initialPageAreaImageUrl,
            reportSource: this.reportSource,
            sendEmail: this.sendEmail,
            scale: this.scale,
            scaleMode: this.scaleMode,
            viewMode: this.viewMode,
            pageMode: this.pageMode,
            parameters: this.parameters,
            persistSession: this.persistSession,
            parameterEditors: this.parameterEditors,
            authenticationToken: this.authenticationToken,
            ready: this.ready,
            printMode: this.printMode,
            selector: this.selector,
            disabledButtonClass: this.disabledButtonClass,
            checkedButtonClass: this.checkedButtonClass,
            exportBegin: this.exportBegin,
            exportEnd: this.exportEnd,
            printBegin: this.printBegin,
            printEnd: this.printEnd,
            renderingBegin: this.renderingBegin,
            renderingEnd: this.renderingEnd,
            sendEmailBegin: this.sendEmailBegin,
            sendEmailEnd: this.sendEmailEnd,
            updateUi: this.updateUi,
            pageReady: this.pageReady,
            error: this.error,
            interactiveActionExecuting: this.interactiveActionExecuting,
            interactiveActionEnter: this.interactiveActionEnter,
            interactiveActionLeave: this.interactiveActionLeave,
            viewerToolTipOpening: this.viewerToolTipOpening,
            enableAccessibility: this.enableAccessibility,
            searchMetadataOnDemand: this.searchMetadataOnDemand,
            parametersAreaVisible: this.parametersAreaVisible,
            documentMapVisible: this.documentMapVisible,
            documentMapAreaPosition: this.documentMapAreaPosition,
            parametersAreaPosition: this.parametersAreaPosition,
            keepClientAlive: this.keepClientAlive
        });

        this.commands = this.viewerObject.commands;
    }

    ngOnDestroy(): void {
        this.viewerObject.dispose();
    }

    refreshReport(): any { return this.viewerObject.refreshReport(); }
    getReportSource(): any { return this.viewerObject.reportSource(); }
    setReportSource(rs: any): any { return this.viewerObject.reportSource(rs); }
    getViewMode(): string { return this.viewerObject.viewMode(); }
    setViewMode(vm: string): any { return this.viewerObject.viewMode(vm); }
    getScale(): any { return this.viewerObject.scale(); }
    setScale(scale: any): any { return this.viewerObject.scale(scale); }
    pageCount(): number { return this.viewerObject.pageCount(); }
    currentPage(): number { return this.viewerObject.currentPage(); }
    setAuthenticationToken(token: string): any { return this.viewerObject.authenticationToken(token); }
    getAccessibilityKeyMap(): any { return this.viewerObject.accessibilityKeyMap(); }
    setAccessibilityKeyMap(keyMap: any) { return this.viewerObject.accessibilityKeyMap(keyMap); }
    bind(eventName: string, eventHandler: Function) { this.viewerObject.bind(eventName, eventHandler); }
    unbind(eventName: string, eventHandler: Function) { this.viewerObject.unbind(eventName, eventHandler); }
    unbindAll(eventName: string) { this.viewerObject.unbind(eventName); }
    getPageMode(): string { return this.viewerObject.pageMode(); }
    setPageMode(psm: string): any { return this.viewerObject.pageMode(psm); }
    clearReportSource(): any { return this.viewerObject.clearReportSource(); }
    dispose(): void { this.viewerObject.dispose(); }
    getReportParameters(): any { return this.viewerObject.getReportParameters(); }
}