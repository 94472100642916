<div class="status-history-container" id="status-history-container">
  <ptg-title-bar
    [name]="data.serviceHistory ? 'Edit' : 'Add New'"
    [viewName]="data.viewName"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <div *ngIf="!isLoading">
    <form [formGroup]="editForm">
      <div class="wrap-btn">
        <button mat-raised-button type="submit" class="submit-button" (click)="onSubmit()">Save</button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
      <div class="form-control-container">
        <div class="form-control-date">
          <ptg-datepicker
            placeholder="Begin Date"
            class="input-field"
            [controlField]="editForm.get('beginDate')"
            [maxDate]="maxBeginDate"
            [errorMaxDate]="'Begin Date must not be a future date'"
            [errorAsync]="'Begin Date must be earlier than End Date.'"
            (changeValue)="onChangeBeginValue($event)"
          ></ptg-datepicker>
          <ptg-datepicker
            placeholder="End Date"
            class="input-field"
            [controlField]="editForm.get('endDate')"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [errorMaxDate]="'End Date must not be a future date'"
            [errorMinDate]="'Begin Date must be earlier than End Date.'"
            [errorAsync]="'Municipality had an overlap service record.'"
            (changeValue)="onChangeEndValue($event)"
          ></ptg-datepicker>
        </div>
        <div class="form-control-select">
          <mat-form-field class="auto-complete" appearance="fill">
            <mat-label>Municipality</mat-label>
            <input type="text" matInput [formControl]="municipality" [matAutocomplete]="auto" (blur)="validateMuni()">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.displayValue}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="!municipality?.errors?.required && municipality?.errors?.inValidAsync">
              Value must be selected from result list.
            </mat-error>
            <mat-error *ngIf="municipality?.errors?.required">
              Municipality is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>




