import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialUIModule } from './material-ui.module';
import { NgxUIModule } from './ngx-ui.module';
import { SearchBoldDirective } from './layout/directive/search-bold.directive';
import { PipeModule } from './pipes/pipe.module';
import { MatTreeModule } from '@angular/material/tree';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialUIModule,
  NgxUIModule,
  PipeModule,
  MatTreeModule,
];
const COMPONENTS: any[] = [];
const DIRECTIVES = [SearchBoldDirective];

@NgModule({
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [MaterialUIModule],
})
export class SharedModule {}
